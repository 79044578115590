import * as React from 'react';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { MenuItem, Box } from '@material-ui/core';

/** ダークモードトグルボタンの Props を表します。 */
interface Props {
    /** ダークモードかどうかを表します。 */
    isDark: boolean
    /** ダークモードON処理を表します。 */
    onClickDarkThemeOn: () => void,
    /** ダークモードOFF処理を表します。 */
    onClickDarkThemeOff: () => void,
}

/**
 * ダークモードトグルボタン
 * @param param0
 */
export const ThemeToggleButton: React.FC<Props> = (props) => {

    return (
        <>
            {props.isDark
                ? (
                    // ダークモード
                    <MenuItem onClick={props.onClickDarkThemeOff}>
                        <Brightness7Icon fontSize={"small"} />
                        <Box marginLeft={1}>ダークモード</Box>
                    </MenuItem>
                )
                : (

                    // デフォルトモード
                    <MenuItem onClick={props.onClickDarkThemeOn}>
                        <Brightness4Icon fontSize={"small"} />
                        <Box marginLeft={1}>ライトモード</Box>
                    </MenuItem>
                )}
        </>
    );
};
