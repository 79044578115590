import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

/**
 * `Date-Fns` 関連の 拡張したユーティリティ機能のを提供します。
 */
export class ExtendedDateFnsUtils extends DateFnsUtils {

    /**
     * カレンダーのヘッダー部分の表示文字列を取得します
     * @param {any} date
     */
    getCalendarHeaderText(date: Date): string {
        return format(date, "yyyy MMM", { locale: this.locale });
    }

    /**
     * 日付ピッカーのヘッダー部分の表示文字列を取得します
     * @param {any} date
     */
    getDatePickerHeaderText(date: Date): string {
        return format(date, "MMM d日", { locale: this.locale });
    }
}
