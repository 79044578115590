// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { FileManageSlice, onPutDirectoryAsync } from '../../../../../modules/slice/file-manage/file-manage-slice';
import { CreateFolderDialog, CreateFolderDialogInputItem } from './CreateFolderDialog';
import path from 'path';
import { FileManageUtility } from '../../../../../modules/adapter/file-manage/file-manage-utility';

/**
 * フォルダ作成ダイアログを表示します。
 * @param props
 */
export function CreateFolderDialogContainer(): JSX.Element {
    const dispatch = useDispatch();
    // redux store
    const isOpen = useSelector((state: RootState) => state.fileManage.item.isOpenCreateFolderDialog ?? false);
    const directoryName = useSelector((state: RootState) => FileManageUtility.toDirectoryKey(state.fileManage.item.directoryItem));

    /**
     * 作成ボタンのクリック処理を行います。
     * @param e
     * @param input
     */
    const onCreateClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, input: CreateFolderDialogInputItem) => {
        // 非同期通信
        const key = path.join(directoryName, input.name);
        await dispatch(onPutDirectoryAsync({ files: [{ key }] }));
    };

    /** ダイアログを非表示にします。 */
    const closeDialog = () => {
        dispatch(FileManageSlice.actions.updateItemPartial({ isOpenCreateFolderDialog: false }));
    };

    return (
        <CreateFolderDialog
            isOpen={isOpen}
            onCreateClick={onCreateClick}
            handleClose={closeDialog}
        />
    )
}

