// react
import * as React from 'react';
import { useEffect } from 'react';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMasterPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { MachineMasterSlice, onExcuteMountedAsync } from '../../../modules/slice/machine/machine-slice';
// material-ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// component
import { MachineResultTableContainer } from './components/TableContent/MachineResultTableContainer';
import { MachineSearchConditionContainer } from './components/SearchContent/MachineSearchConditionContainer';
import { MachineDetailConditionContainer } from './components/DetailContent/MachineDetailConditionContainer';
import { ConfirmDialogContainer } from './components/ConfirmDialog/ConfirmDialogContainer';
import MasterLayout from '../Shared/MasterLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';

/**
 * 施工機マスタ画面を提供します。
 * */
const MachinePage: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeMasterPageHeader(AppTitle.masterMachine.title));

        // 初回マウント時にデータを取得する
        dispatch(onExcuteMountedAsync());

    }, [dispatch]);

    // redux store
    const isLoading = useSelector((state: RootState) => state.machineMaster.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.machineMaster.isShowMessage);
    const message = useSelector((state: RootState) => state.machineMaster.message);
    const isCreating = useSelector((state: RootState) => state.machineMaster.isCreating);
    const isSelected = useSelector((state: RootState) => state.machineMaster.isSelected);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(MachineMasterSlice.actions.hideMessage());
    };

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ダイアログ */}
            <ConfirmDialogContainer />

            <MasterLayout
                search={<MachineSearchConditionContainer />}
                table={<MachineResultTableContainer />}
                detail={<MachineDetailConditionContainer />}
                isCreating={isCreating}
                isSelected={isSelected}
            />
        </>
    );
};

export default MachinePage;
