// react
import * as React from 'react';
// material-ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

/** ヘッダーページの Props を提供します。 */
interface Props {
    /** ヘッダーコンテンツを表します。 */
    header: React.ReactNode,
}

/**
 * ヘッダーページのレイアウトを表示します。
 * @param param0
 */
export const Layout: React.FC<Props> = (props) => {

    return (
        <>
            {/* ヘッダー */}
            <>
                {props.header}
            </>

            {/* メイン */}
            <Container
                maxWidth={false}
                style={{ paddingTop: 16, paddingBottom: 16, fontSize: "1rem" }}
            >
                <>
                    {props.children}
                </>
            </Container>

        </>
    );
};
