import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import MaterialTable, { Column } from 'material-table';
import { Box } from '@material-ui/core';
import { MachineMaintenanceSettingResetHistoryListItem } from '../../_types'
import { MuiTableIcons, MuiTableOptions, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
import { useStyles } from '../../../Shared/ConstructionMchineMasterTableStyles';
import indigo from '@material-ui/core/colors/indigo';

/** リセット履歴テーブル項目の State を提供します。 */
interface State {
    /** テーブルのカラムを表します。 */
    columns: Column<MachineMaintenanceSettingResetHistoryListItem>[]
}

/** リセット履歴テーブルのカラムを表します。 */
const columnData: Column<MachineMaintenanceSettingResetHistoryListItem>[] = [
    {
        title: "日時",
        field: "resetAt",
        width: 300,
    },
    {
        title: "更新値",
        field: "resetValue",
        width: 300,
    },
];


/** ステータス編集ダイアログの Props を表示します。 */
interface Props {
    /** リセット履歴を表します。*/
    dataSource: MachineMaintenanceSettingResetHistoryListItem[],
}

/**
 * リセット履歴ダイアログを表示します。
 * @param props
 */
const ResetHistoryTable: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** カラム情報を表します。 */
    const [values] = useState<State>({
        columns: columnData,
    });

    return (
        <Box className={classes.sortHeader}>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={{
                    ...MuiTableOptions,
                    paging: false,
                    maxBodyHeight: "60vh", // 固定
                    minBodyHeight: "60vh", // 固定
                    headerStyle: {
                        // ヘッダー 背景色
                        backgroundColor: indigo[300],
                    },
                }}
                columns={values.columns}
                data={props.dataSource}
            />
        </Box>
    );
}

export default ResetHistoryTable;
