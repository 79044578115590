// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MachineMasterSlice, onExcuteAsync } from '../../../../../modules/slice/machine/machine-slice';
import { MachineAdapter } from '../../../../../modules/adapter/machine/machine-adapter'
// type
import { MachineInputItem } from '../../_types';
// component
import MachineSearchCondition from './MachineSearchCondition';

/**
 * 施工機マスタ　検索エリアを提供します。
 * */
export const MachineSearchConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const typeList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.MachineType);
    const modelList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.Model);
    const manufacturerList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.Manufacturer);
    const ctuIdList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.CtuId);
    const maintenanceCtuIdList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.MaintenanceCtuId);

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: MachineInputItem) => {

        // 非同期通信
        const promise = MachineAdapter.instance.getAsync(
            {
                // セレクトボックスの 0:未選択 を void 0 に変換
                item: {
                    ...inputItem,
                    type: inputItem.type === 0 ? void 0 : inputItem.type,
                    model: inputItem.model === 0 ? void 0 : inputItem.model,
                    manufacturer: inputItem.manufacturer === 0 ? void 0 : inputItem.manufacturer,
                    ctuId: void 0,
                    maintenanceCtuId: void 0,
                }
            },
            {
                MachineType: typeList,
                Model: modelList,
                Manufacturer: manufacturerList,
                CtuId: ctuIdList,
                MaintenanceCtuId: maintenanceCtuIdList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickAdd = () => {
        dispatch(MachineMasterSlice.actions.chageCreating(true))
    }

    // 表示
    return (
        <MachineSearchCondition
            onClickSearch={onClickSearch}
            onClickAdd={onClickAdd}
            typeList={typeList}
            modelList={modelList}
            manufacturerList={manufacturerList}
        />

    );
};
