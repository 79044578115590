//react
import * as React from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MachineStatusMonitoringSlice, onGetReloadAsync } from '../../../../../modules/slice/machine-status-monitoring/machine-status-monitoring-slice';
// components
import { MachineStatusMonitoringItem } from '../../_types';
import MachineStatusMonitoringMain from './MachineStausMonitoringMain';

/**
 * 施工機ステータス画面
 */
export const MachineStatusMonitoringMainContainer: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const data = useSelector((state: RootState) => state.machineStatusMonitoring.result.item);
    const machineStatus = useSelector((state: RootState) => state.machineStatusMonitoring.result.machineStatus);
    const ipAddress = useSelector((state: RootState) => state.machineStatusMonitoring.result.ipAddress ?? "");
    const lastUpdatedAt = useSelector((state: RootState) => state.machineStatusMonitoring.result.lastUpdatedAt ?? "");
    const machine = useSelector((state: RootState) => state.globalHeader.machine)

    /** 更新ボタンのクリック処理を行います。 */
    const onUpdateClick = async () => {

        if (!machine) {
            dispatch(MachineStatusMonitoringSlice.actions.showErrorMessage(`施工機情報がありません。`));
            return;
        }

        await dispatch(onGetReloadAsync({ id: machine.item.id }));
    }

    return (
        <MachineStatusMonitoringMain
            data={data}
            machineStatus={machineStatus}
            ipAddress={ipAddress}
            lastUpdatedAt={lastUpdatedAt}
            onUpdateClick={onUpdateClick}
        />
    );
};
