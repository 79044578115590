// react
import * as React from 'react';
// router
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

// 各画面ルート
import { TabList } from './components/TabList/TabList';
import { AppUrl } from '../../Shared/app-url';

/** Params を提供します。 */
interface Params {
    tab: string,
}

/**
 * マスタ管理画面 を提供します。
 * */
export function MasterTab() {

    /** Hisotry を表します。*/
    const history = useHistory();

    /** ルート情報を表します。 */
    const match = useRouteMatch<Params>(`${AppUrl.master.path}/:tab`);

    /** URLパラメータのタブを表します。 */
    const { tab } = useParams<Params>();

    /**
     * タブ選択時の処理を行います。
     * @param event
     * @param selectedTab
     */
    const handleChange = (event: React.ChangeEvent<{}>, selectedTab: string) => {
        match && history.push(`${AppUrl.master.path}/${selectedTab}`);
    };

    return (
        <>
            <TabList
                selectedTab={tab}
                onTabClick={handleChange}
            />
        </>

    );

};
