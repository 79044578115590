import * as React from "react";
import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import { MuiDatePickerUtility } from './mui-date-picker-utility';

/**
 *  日付ピッカーを表示します。
 * @param props
 */
export const MuiKeybordDatePicker: React.FC<KeyboardDatePickerProps> = (props) => {


    return (
        <KeyboardDatePicker
            color="secondary"
            size="small"
            autoOk={true}
            variant="inline"
            inputVariant="outlined"
            invalidDateMessage={MuiDatePickerUtility.invalidDateMessage}
            invalidLabel={MuiDatePickerUtility.invalidLabel}
            maxDateMessage={MuiDatePickerUtility.maxDateMessage}
            minDateMessage={MuiDatePickerUtility.minDateMessage}
            {...props}
        />
    );
}

export default MuiKeybordDatePicker;
