// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeReportPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { OtherReportSlice, onGetAsync } from '../../../modules/slice/other-report/other-report-slice';
// 共通component
import { AreaToggleButtonContainer } from '../../../components/AreaToggleButton/AreaToggleButtonContainer';
import TopAndBottomLayout from '../../../components/Layout/TopAndBottomLayout';
import TwoColumnLayout from '../../../components/Layout/TwoColumnLayout';
import SinglePageLayout from '../../../components/Layout/SinglePageLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
// component
import { OtherReportTableContainer } from './conponents/TableContent/OtherReportTableContainer';
import { OtherReportSearchContainer } from './conponents/SearchContent/OtherReportSearchContainer';
import { ReportDetailDialogContainer } from './conponents/ReportDetailDialog/ReportDetailDialogContainer';
import { OtherReportListItem, OtherReportInputItem, OtherReportSearchType, OtherReportImageSearchType } from './_types';
import { AppTitle } from '../../Shared/app-title';


/** その他報告画面 を表示します。*/
export const OtherReportPage: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.otherReport.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.otherReport.isShowMessage);
    const message = useSelector((state: RootState) => state.otherReport.message);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(OtherReportSlice.actions.hideMessage());
    };

    // Mount, Unmount時
    useEffect(() => {
        dispatch(changeReportPageHeader(AppTitle.otherReport.title));

        // 初回マウント時に直近30日分のデータ取得を行う。
        /** 初期表示時に入力値に入れる値は SearchContent で設定しているので注意する */
        const beginAt = new Date();
        beginAt.setMonth(beginAt.getMonth() - 1);
        beginAt.setHours(0, 0, 0, 0);

        const endAt = new Date();
        endAt.setHours(0, 0, 0, 0);

        dispatch(onGetAsync({
            item: {
                keyword: "",
                searchType: OtherReportSearchType.None,
                beginAt: beginAt,
                endAt: endAt,
                imageSearchType: OtherReportImageSearchType.None,
            }
        }))

    }, [dispatch]);
    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* その他報告 */}
            <SinglePageLayout>
                <TwoColumnLayout
                    // 検索エリア
                    side={
                        <TopAndBottomLayout
                            topContent={<OtherReportSearchContainer />}
                            bottomContent={<AreaToggleButtonContainer />}
                        />
                    }
                    // 検査結果
                    main={<OtherReportTableContainer />}
                />
            </SinglePageLayout>

            {/* ダイアログ */}
            <ReportDetailDialogContainer />
        </>

    )
};
