import { MachineMonitoringClient, GetMachineMonitoringInput, UnlockMachineMonitoringInput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { MachineMonitoringGetRequest, MachineMonitoringResult, MachineMonitoringUnlockRequest } from './types';
import { MachineMonitoringUtility } from './machine-monitoring-utility';

/**
 * `MachineMonitoring` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class MachineMonitoringAdapter extends WebApiAdapter<MachineMonitoringClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new MachineMonitoringAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: MachineMonitoringGetRequest): Promise<MachineMonitoringResult> {
        function createErrorResult(message?: string): MachineMonitoringResult {
            return {
                items: [],
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetMachineMonitoringInput();
        input.init({
            searchWord: parameter.item.machineKeyword,
            searchType: MachineMonitoringUtility.toSearchType(parameter.item.type),
            searchSite: parameter.item.siteKeyword,
            searchReportItem: parameter.item.reportKeyword,
        });
        const output = await this.client.get(input);
        if (output == null) {
            return createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineMonitoringResult = {
            isError: false,
            items: MachineMonitoringUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * ロック解除します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async unlockAsync(parameter: MachineMonitoringUnlockRequest): Promise<MachineMonitoringResult> {
        function createErrorResult(message?: string): MachineMonitoringResult {
            return {
                items: [],
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new UnlockMachineMonitoringInput();
        input.init({
            tabletId: parameter.tabletId,
        });
        const output = await this.client.unlock(input);
        if (output == null) {
            return createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineMonitoringResult = {
            isError: false,
            items: MachineMonitoringUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new MachineMonitoringClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
