// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { CompletionInspectionRecordSlice, onUpdateCompletionInspectedStampAsync } from '../../../../../modules/slice/completion-inspection-record/completion-inspection-record-slice';
import { RootState } from '../../../../../rootReducer';
import InspectionStampForm from './InspectionStampForm';
import { ConstructionMacineMasterUtility } from '../../../Shared/ConstructionMacineMasterUtility';
import { UpdateInspectedStampListItem } from '../../../_types';

/** 検印エリアを表示します。 */
export const StampContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine)
    const inspector = useSelector((state: RootState) => state.authentication?.user?.inspectorName ?? "");
    const inspectionStampItem = useSelector((state: RootState) => state.completionInspectionRecord?.report?.stampItem);
    const selectedItem = useSelector((state: RootState) => state.completionInspectionRecord?.selectedItem);
    const isUpdated: boolean = useSelector((state: RootState) => state.completionInspectionRecord?.isUpdated ?? false);

    /** 検印ボタンのクリック処理を行います。 */
    const onUpdateClick = async (inputItems: UpdateInspectedStampListItem<number>[]) => {

        // 更新用パラメータ成型
        const updateItem = ConstructionMacineMasterUtility.toUpdateCompletionInspectedStampItem(inputItems);
        if (!machine) {
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage("施工機情報がありません。"));
        } else if (!inspector) {
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage("検印者情報がありません。"));
        } else if (!selectedItem) {
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage("選択項目の情報がありません。"));
        } else if (!inspectionStampItem) {
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage("帳票情報がありません。"));
        } else if (!updateItem) {
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage("検印更新情報がありません。"));
        } else {
            // 検印更新
            dispatch(onUpdateCompletionInspectedStampAsync(
                {
                    item: updateItem,
                },
                {
                    machineId
                        : machine.item.id,
                    reportAt: selectedItem.completionDate
                }
            ))
        }
    };

    /** 戻るボタンのクリック処理を行います。 */
    const onBackClick = () => {
        // ビューワを閉じる
        dispatch(CompletionInspectionRecordSlice.actions.toggleViewre(false));
    };

    return (
        <>
            {inspectionStampItem &&
                <InspectionStampForm
                    inspector={inspector}
                    stampData={inspectionStampItem}
                    onUpdateClick={onUpdateClick}
                    onBackClick={onBackClick}
                    isUpdated={isUpdated}
                />

            }
        </>
    );

};
