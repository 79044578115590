/**
 * `@material-ui/pickers` 関連の ユーティリティ機能のを提供します。
 */
export class MuiDatePickerUtility {

    /** 日本語のテキストメッセージを表します。 */
    /** 無効な日付が指定されたときのメッセージを表します。 */
    static readonly invalidDateMessage = "無効な日付形式です"
    /** 無効な日付が指定されたときのメッセージを表します。 */
    static readonly invalidLabel = "無効な日付です"
    /** 入力域の日付情報より後の日付が指定されたときのメッセージを表します。 */
    static readonly maxDateMessage = "入力域の日付情報より後の日付は指定できません"
    /** 入力域の日付情報より前日付が指定されたときのメッセージを表します。 */
    static readonly minDateMessage = "入力域の日付情報より前の日付は指定できません"

    /** 日付選択時のフォーマットを表します*/
    static readonly dateFormat = "yyyy 年 MM 月 dd 日"
    /** 年月選択時のフォーマットを表します*/
    static readonly yearAndMonthFormat = "yyyy 年 MM 月"
}
