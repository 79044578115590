// react
import * as React from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MachineOperationHistorySlice } from '../../../../../modules/slice/machine-operation-history/machine-operation-history-slice';
// component
import { GraphType } from '../../_types';
import { ConstructionRecordCardList } from './ConstructionRecordCardList';

/**
 * 施工記録画面 カードリストを提供します。
 * */
export const ConstructionRecordCardListContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux state
    const activeCardList = useSelector((state: RootState) => state.machineOperationHistory.item.dataList);

    /**
     * 表示するグラフの変更処理を行います。
     * @param item
     */
    const onChangeCardState = (item: GraphType) => {

        dispatch(MachineOperationHistorySlice.actions.onChangeDispayGraph(item));

    }

    return (
        <ConstructionRecordCardList
            activeCardList={activeCardList}
            onClickCard={onChangeCardState}

        />
    );
};
