// redux
import { Dispatch } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
// shared
import { ExtendGenericState, createExtendGenericSlice } from '../shared/create-extend-generic-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { changeError } from '../global-header/global-header-slice';
// type
import { MachineOperationHistoryAdapter } from '../../adapter/machine-operation-history/machine-operation-history-adapter';
import { MachineOperationHistoryGetResult, MachineOperationHistoryGetRequest, MachineOperationHistoryGetCsvResult, MachineOperationHistoryGetCsvRequest } from '../../adapter/machine-operation-history/types';
import { GraphType } from '../../../pages/ConstructionMachineMaster/ConstructionRecord/_types'
import { MachineOperationHistorySliceUtility } from './machine-operation-history-slice-utility';

/** 施工記録 結果情報の State を表します。 */
interface MachineOperationHistoryResultState {
    /** 一覧取得結果情報を表します。 */
    getResult?: MachineOperationHistoryGetResult,
    /** ドキュメント取得結果情報を表します。 */
    getCsvResult?: MachineOperationHistoryGetCsvResult,
}

/** 施工記録 アイテム情報の State を表します。 */
interface MachineOperationHistoryItemState {
    dataList: GraphType[];
}


// State の初期値を設定します。
const initialState: ExtendGenericState<MachineOperationHistoryResultState, MachineOperationHistoryItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        getResult: {
            items: [],
            isError: false,
        },
        getCsvResult: {
            url: "",
            isError: false,
        }
    },
    item: {
        dataList: [GraphType.engineOperatingTime, GraphType.liftingDistance],
    }
}

/** 施工記録 Slice を 提供します。*/
export const MachineOperationHistorySlice = createExtendGenericSlice({
    // State Reducer Action を生成
    name: 'MachineOperationHistory',
    initialState: initialState,
    reducers: {
        /**
        * 通信終了時の処理を行います。
        * @param state
        * @param action
        */
        fetchEndExtension(state: ExtendGenericState<MachineOperationHistoryResultState, MachineOperationHistoryItemState>, action: PayloadAction<MachineOperationHistoryResultState>) {
            state.isLoading = false;

            if (action.payload.getResult) {
                state.result.getResult = action.payload.getResult;
            }
            if (action.payload.getCsvResult) {
                state.result.getCsvResult = action.payload.getCsvResult;
            }
        },

        /**
         * 表示するグラフを変更します。
         * @param state
         * @param action
         */
        onChangeDispayGraph(state: ExtendGenericState<MachineOperationHistoryResultState, MachineOperationHistoryItemState>, action: PayloadAction<GraphType>) {

            if (state.item.dataList.includes(action.payload)) {
                // 配列にある場合
                if (state.item.dataList.length > 1)
                    // 要素の削除
                    state.item.dataList = MachineOperationHistorySliceUtility.removeItem(state.item.dataList, action.payload);

            } else {
                // 配列に無い場合
                // 末尾に追加
                state.item.dataList = MachineOperationHistorySliceUtility.addItem(state.item.dataList, action.payload);
                if (state.item.dataList.length > MachineOperationHistorySliceUtility.MaxDisplayCount) {
                    // 最大表示数は 2
                    // 先頭削除
                    state.item.dataList = MachineOperationHistorySliceUtility.shiftArray(state.item.dataList);

                }

            }
        }
    },
})

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetAsync = (parameter: MachineOperationHistoryGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineOperationHistorySlice.actions.fetchStart());

        // 通信処理
        const getResultWaiter = MachineOperationHistoryAdapter.instance.getAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.item.machineId });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, machineErrorResultWaiter]);
        const getResult = promiseResult[0];
        const machineErrorResult = promiseResult[1];

        // 通信終了
        dispatch(MachineOperationHistorySlice.actions.fetchEndExtension({ getResult: getResult }));


        if (getResult.isError) {
            // 異常
            // メッセージ
            dispatch(MachineOperationHistorySlice.actions.showErrorMessage(getResult.errorMessage ?? ""));

        } else if (machineErrorResult.isError) {
            // 異常
            // メッセージ
            dispatch(MachineOperationHistorySlice.actions.showErrorMessage(machineErrorResult.errorMessage ?? ""));

        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 正常
            // メッセージ
            dispatch(MachineOperationHistorySlice.actions.showSuccessMessage());

        }

    } catch (error) {

        //例外
        dispatch(MachineOperationHistorySlice.actions.fetchEndExtension({
            getResult: {
                items: [],
                isError: true,
                errorMessage: `${error}`,
            }
        }));

        // メッセージ
        dispatch(MachineOperationHistorySlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MachineOperationHistorySlice.actions.fetchEnd());
    }
}

/**
 * CSV取得処理を行います。
 * @param parameter
 */
export const onGetCsvAsync = (parameter: MachineOperationHistoryGetCsvRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineOperationHistorySlice.actions.fetchStart());

        // 通信処理
        const result: MachineOperationHistoryGetCsvResult = {
            url: "",
            isError: false,
        }

        // 通信終了
        dispatch(MachineOperationHistorySlice.actions.fetchEndExtension({ getCsvResult: result }));

        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(MachineOperationHistorySlice.actions.showErrorMessage(result.errorMessage ?? ""));
        } else {
            // 正常
            // メッセージ
            dispatch(MachineOperationHistorySlice.actions.showSuccessMessage());
        }

    } catch (error) {

        //例外
        dispatch(MachineOperationHistorySlice.actions.fetchEndExtension({
            getCsvResult: {
                url: "",
                isError: true,
                errorMessage: `${error}`,
            }
        }));

        // メッセージ
        dispatch(MachineOperationHistorySlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MachineOperationHistorySlice.actions.fetchEnd());
    }
}
