import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { ControlButtons, ButtonType } from './ControlButtons';
import { FileManageSlice } from '../../../../../modules/slice/file-manage/file-manage-slice';
import { HomeDirectoryType } from '../../_types';

/**
 * 操作ボタンコンポーネントを表示します。
 * @param props
 */
export function ControlButtonsContainer(): JSX.Element {

    const dispatch = useDispatch();
    const directoryItem = useSelector((state: RootState) => state.fileManage.item.directoryItem);
    const selectedItem = useSelector((state: RootState) => state.fileManage.item.selectedRowItems);

    /** ボタンのクリックイベントを処理します。 */
    const onClick = (type: ButtonType ) => {
        dispatch(FileManageSlice.actions.updateItemPartial({
            isUpdated: type === "update" ? true : void 0,
            isOpenUploadDialog: type === "upload" ? true : void 0,
            isOpenCreateFolderDialog: type === "create" ? true : void 0,
            isOpenDeleteDialog: type === "delete" ? true : void 0,
        }));
    };

    return (
        <ControlButtons
            isDisabledDelete={!(selectedItem.length) || (directoryItem.home === HomeDirectoryType.OnlineHelp && directoryItem.sub.length < 1)}
            isDisabledCreate={(directoryItem.home === HomeDirectoryType.OnlineHelp || directoryItem.sub.length > 0)}
            isDisabledUpload={(directoryItem.home === HomeDirectoryType.OnlineHelp && directoryItem.sub.length < 1)}
            onClick={onClick}
        />
    );
}
