// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// router
import { useHistory } from 'react-router-dom';
// type
import { MachineMonitoringListItem, MachineMonitoringSearchType } from '../../_types';
// component
import ConstructionMachineTable from './ConstructionMachineMonitorTable';
import { MachineType, MachineItem, Machine, AreaType } from '../../../../../_types';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { changeMachine } from '../../../../../modules/slice/global-header/global-header-slice';
import { MachineMonitoringSlice, onGetAsync, onRowUnlockClick } from '../../../../../modules/slice/machine-monitoring/machine-monitoring-slice';
import { HeaderMachineState } from '../../../../Shared/GlobalHeader/_types';
import { AppUrl } from '../../../../Shared/app-url';

/**
 * 施工機モニター テーブルを提供します。
 * */
export const ConstructionMachineMonitorTableContainer: React.FC<{}> = () => {

    /** historyを提供します。 */
    const history = useHistory();
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const data = useSelector((state: RootState) => state.machineMonitoring.result.items);
    const areaType = useSelector((state: RootState) => state.areaType.area);

    /**
     * テーブル行クリック時のイベント処理を行います。
     * @param event
     * @param rowData
     */
    const onRowClick = (event: React.MouseEvent<Element, MouseEvent>, rowData: MachineMonitoringListItem) => {

        if (event.ctrlKey && rowData.isLock) {
            // ctrl + Click の場合

            //選択された行データを保持し、ダイアログ表示
            dispatch(onRowUnlockClick(rowData));
        }
    }

    /**
     * 施工機管理画面(整備記録etc)へ遷移処理を行います。
     * @param number
     * @param machineType
     */
    const navigateMachineMaster = (selectedMachine: HeaderMachineState) => {

        // 画面遷移
        history.push({
            // ベースマシンが選択されたときは施工機ステータスを、それ以外の場合は整備記録を初期表示とする
            pathname: selectedMachine.type == MachineType.BaseMachine && selectedMachine.isLock ? AppUrl.recordMachineStatus.path : AppUrl.recordMaintenanceRecord.path,
            state: selectedMachine,
        });
    }

    /**
    * ノートボタンクリック時のイベント処理を行います。
    * @param number
    * @param machineType
    */
    const onNoteClick = (event: React.MouseEvent<Element, MouseEvent>, selectedMachine: HeaderMachineState) => {

        if (event.ctrlKey) {
            event.preventDefault();
            return;
        }

        // ヘッダー施工機種別・社番変更
        dispatch(changeMachine(selectedMachine));

        // 画面遷移
        navigateMachineMaster(selectedMachine);
    };

    /**
     * エリア種別のフィルター処理を行います。
     * @param source テーブルデータを指定します。
     * @param areaType エリア種別を指定します。
     * @returns フィルタ後のテーブルデータを返します。
     */
    const areaTypeFilter = (source: MachineMonitoringListItem[], areaType: AreaType) => {

        // エリアが確定していない施工機は全国でしか表示しない。
        // material-tableにデータをそのまま渡すとErrorになるので複製・参照を切る
        return source.filter(item => (areaType === AreaType.None ? true : item.areaType === areaType)).map(item => ({ ...item }));

    }

    return (
        <>
            {/* テーブル */}
            <ConstructionMachineTable
                dataSource={areaTypeFilter(data, areaType)}
                onNoteClick={onNoteClick}
                onRowClick={onRowClick}
            />

        </>
    );

}
