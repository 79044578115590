import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { useLocation, useHistory } from 'react-router-dom';
import IconMenu from './IconMenu';
import DrawerMenu from './DrawerManu';
import { AppUrl } from '../../../app-url';
import { onSignOutAsync } from '../../../../../modules/slice/authentication/authentication-slice';


/** メニューを表示します。 */
export const MenuContainer: React.FC<{}> = () => {

    /** Location を表します。*/
    const location = useLocation();

    /** History を表します。 */
    const history = useHistory();

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const userName = useSelector((state: RootState) => state.authentication.user?.name ?? "");

    /**
     * 画面遷移処理を行います。
     * @param path
     */
    const onNavigate = (path: string) => {
        // 遷移
        history.push(path);
    }

    /** ホーム クリック時のイベント処理を行います。 */
    const onHomeClick = () => {
        onNavigate(AppUrl.machineMonitoring.path);
    }

    /** レポート クリック時のイベント処理を行います。 */
    const onReportClick = () => {
        onNavigate(AppUrl.otherReport.path);
    }

    /** アップロード クリック時のイベント処理を行います。 */
    const onUploadClick = () => {
        onNavigate(AppUrl.fileManage.path);
    }

    /** マスタ系各項目のクリック時のイベント処理を行います。 */
    const onMasterItemClick = (path: string) => {
        onNavigate(path);
    }

    /** サインアウト クリック時のイベント処理を行います。 */
    const onSingoutClickAsync = () => {
        //サインアウト処理
        dispatch(onSignOutAsync());
    }

    return (
        <>
            <IconMenu
                locatedPath={location.pathname}
                onHomeClick={onHomeClick}
                onReportClick={onReportClick}
                onUploadClick={onUploadClick}
                onMasterItemClick={onMasterItemClick}
                onSingoutClick={onSingoutClickAsync}
                userName={userName}
            />

            <DrawerMenu
                locatedPath={location.pathname}
                onHomeClick={onHomeClick}
                onReportClick={onReportClick}
                onUploadClick={onUploadClick}
                onMasterItemClick={onMasterItemClick}
                onSingoutClick={onSingoutClickAsync}
                userName={userName}
            />

        </>

    );
}
