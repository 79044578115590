import { MachineStatusMonitoringItem, MachineStatusMonitoringBoolState, MachineStatusMonitoringUpDownState, MachineDataType, MachineStatusControlMeterState } from '../../../pages/ConstructionMachineMaster/MachineStatus/_types';
import { MachineStatusMonitoringRequest, MachineMaintenanceSettingRequest, MachineMaintenanceSettingRestHistoryRequest } from './types';
import { GetMachineStatusMonitoringInput, MachineStatusMonitoringItem as DtoItem, MachineDataType as DtoMachineDataType, AddOrUpdateClientRequestInput, GetClientRequestInput, RequestType, ResetRequestItem, ResetSubRequestType, MachineStatus as DtoMachineStatus, GetResetHistroyInput, ResetHistoryItem, KeyValueItemOfDateTimeAndString } from '../../swagger-clients';
import { MachineMaintenanceSettingItem, EditItem, MachineMaintenanceSettingInputItem, MachineMaintenanceSettingResetHistoryListItem, MachineMaintenanceSettingResetHistoryItem } from '../../../pages/ConstructionMachineMaster/StatusEdit/_types';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `施工機ステータス` 関連の ユーティリティ機能を提供します。
 */
export class MachineStatusMonitoringUtility {

    //#region フィールド

    /** 自動制御接続状態OFF時の文字列を表します。 */
    static readonly NotAvailable = "---";
    /** 昇降状態停止時の文字列を表します。 */
    static readonly Stop = "停止";
    /** 昇降状態上昇時の文字列を表します。 */
    static readonly Up = "上昇";
    /** 昇降状態下降時の文字列を表します。 */
    static readonly Down = "下降";
    /** ステータスがON時の文字列を表します。 */
    static readonly On = "ON";
    /** ステータスOFF時の文字列を表します。 */
    static readonly Off = "OFF";
    /** 更新時間の空文字列を表します。 */
    static readonly lastUpdatedAtNull = "----/--/--";


    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toItem(items?: DtoItem[]): MachineStatusMonitoringItem | undefined {
        if (items == null) {
            return void 0;
        }
        const result: MachineStatusMonitoringItem = {
            augerTotalTime1: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AugerTotalTime1).value ?? "",
            augerTotalTime2: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AugerTotalTime2).value ?? "",
            batteryVoltage: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.BatteryVoltage).value ?? "",
            bodyAngleX: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.BodyAngleX).value ?? "",
            bodyAngleY: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.BodyAngleY).value ?? "",
            craneTotalDistance: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.CraneTotalDistance).value ?? "",
            current1: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.Current1).value ?? "",
            current2: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.Current2).value ?? "",
            currentDepth: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.CurrentDepth).value ?? "",
            currentSpeed: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.CurrentSpeed).value ?? "",
            engineRotateSpeed: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EngineRotateSpeed).value ?? "",
            engineTotalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EngineTotalTime).value ?? "",
            flowRate1: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.FlowRate1).value ?? "",
            flowRate2: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.FlowRate2).value ?? "",
            frontLeftJackPressure: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.FrontLeftJackPressure).value ?? "",
            frontRightJackPressure: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.FrontRightJackPressure).value ?? "",
            leaderAngleX: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.LeaderAngleX).value ?? "",
            leaderAngleY: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.LeaderAngleY).value ?? "",
            leftStayStrokeLength: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.LeftStayStrokeLength).value ?? "",
            loadCell: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.LoadCell).value ?? "",
            mainPumpTotalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.MainPumpTotalTime).value ?? "",
            gearPumpTotalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.GearPumpTotalTime).value ?? "",
            rearLeftJackPressure: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.RearLeftJackPressure).value ?? "",
            rearRightJackPressure: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.RearRightJackPressure).value ?? "",
            rightStayStrokeLength: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.RightStayStrokeLength).value ?? "",
            rotate1: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.Rotate1).value ?? "",
            rotate2: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.Rotate2).value ?? "",
            totalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.TotalTime).value ?? "",
            upDownPressure: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.UpDownPressure).value ?? "",
            upDownSpeedVolume: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.UpDownSpeedVolume).value ?? "",
            version: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.Version).value ?? "",
            workingOilTemp: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.WorkingOilTemp).value ?? "",
            batteryVoltageAvg: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.BatteryVoltageAvg).value ?? "",
            engineRotationTimeAvg: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EngineRotationTimeAvg).value ?? "",
            liftingPressureMax: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.LiftingPressureMax).value ?? "",
            oilTemperatureAvg: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.OilTemperatureAvg).value ?? "",
            autoControlConnection: MachineStatusMonitoringUtility.toBoolean(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AutoControlConnection)),
            ecuError: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EcuError)),
            emergencyStopState: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EmergencyStopState)),
            engineCoolingWaterOverHeat: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EngineCoolingWaterOverHeat)),
            faultLamp: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.FaultLamp)),
            hookOverHoisting: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.HookOverHoisting)),
            pilotPressureWarning: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.PilotPressureWarning)),
            workingOilTempWarning: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.WorkingOilTempWarning)),
            alarmBatteryVoltage: MachineStatusMonitoringUtility.toBoolState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AlarmBatteryVoltage)),
            upDownState: MachineStatusMonitoringUtility.toUpDownState(MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.UpDownState)),
        };
        return result;
    }

    /**
     * 編集項目を変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toMaintenanceSettingItemFromStatusItem(items?: DtoItem[]): MachineMaintenanceSettingItem | undefined {
        if (items == null) {
            return void 0;
        }
        const result: MachineMaintenanceSettingItem = {
            /** 本体稼働時間[Hr] を表します。*/
            totalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.TotalTime).value ?? "",
            /** エンジン稼働時間[Hr] を表します。*/
            engineTotalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EngineTotalTime).value ?? "",
            /** クレーン昇降距離[m] を表します。*/
            craneTotalDistance: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.CraneTotalDistance).value ?? "",
            /** メインポンプ稼働時間1[Hr] を表します。*/
            mainPumpTotalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.MainPumpTotalTime).value ?? "",
            /** ギアポンプ稼働時間2[Hr] を表します。*/
            gearPumpTotalTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.GearPumpTotalTime).value ?? "",
            /** オーガー1稼働時間[Hr] を表します。*/
            augerTotalTime1: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AugerTotalTime1).value ?? "",
            /** オーガー2稼働時間[Hr] を表します。*/
            augerTotalTime2: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AugerTotalTime2).value ?? "",
            /** 作動油交換後時間[Hr] を表します。*/
            workingOilTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.WorkingOilTime).value ?? "",
            /** エンジンオイル交換後時間[Hr] を表します。*/
            engineOilTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.EngineOilTime).value ?? "",
            /** オイルエレメント交換後時間[Hr] を表します。*/
            oilElementTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.OilElementTime).value ?? "",
            /** エアーエレメント交換後時間[Hr] を表します。*/
            airElementTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AirElementTime).value ?? "",
            /** 燃料エレメント交換後時間[Hr] を表します。*/
            fuelElementTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.FuelElementTime).value ?? "",
            /** 作動油エレメント交換後時間[Hr] を表します。*/
            workingOilElementTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.WorkingOilElementTime).value ?? "",
            /** バッテリー交換後日数[日] を表します。*/
            batteryTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.BatteryTime).value ?? "",
            /** クーラント交換後日数[日] を表します。*/
            coolantTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.CoolantTime).value ?? "",
            /** ファンベルト交換後時間[Hr] を表します。*/
            fanBeltTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.FanBeltTime).value ?? "",
            /** オルタネーター交換後時間[Hr] を表します。*/
            alternatorTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.AlternatorTime).value ?? "",
            /** セルモーター交換後時間[Hr] を表します。*/
            starterMotorTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.StarterMotorTime).value ?? "",
            /** 油圧ポンプカップリング交換後時間[Hr] を表します。*/
            oilPressurePumpCouplingTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.OilPressurePumpCouplingTime).value ?? "",
            /** 油圧ポンプ減速機オイル交換後時間[Hr] を表します。*/
            oilPressurePumpDeceleratorOilTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.OilPressurePumpDeceleratorOilTime).value ?? "",
            /** ウインチ減速機オイル交換後時間[Hr] を表します。*/
            winchDeceleratorOilTime: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.WinchDeceleratorOilTime).value ?? "",
            /** 主巻ワイヤ交換後時間[日] を表します。*/
            primaryHoistingWire: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.PrimaryHoistingWire).value ?? "",
            /** 補巻ワイヤ交換後時間[Hr] を表します。*/
            subHoistingWire: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.SubHoistingWire).value ?? "",
            /** 起伏ワイヤ交換後時間[Hr] を表します。*/
            upsAndDownsWire: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.UpsAndDownsWire).value ?? "",
            /** ペンダントロープ交換後時間[日] を表します。*/
            pendantRope: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.PendantRope).value ?? "",
            /** 特定自主検査後日数[日] を表します。*/
            selfInspection: MachineStatusMonitoringUtility.toFindItem(items, DtoMachineDataType.SelfInspection).value ?? "",
        };
        return result;
    }


    /**
    * 制御要求リセット項目を変換します。
    *
    * @param items 変換元の値を指定します。
    * @returns 変換結果を返します。
    */
    public static toMaintenanceSettingItemFromResetRequestItem(items?: ResetRequestItem[]): MachineMaintenanceSettingItem | undefined {
        if (items == null) {
            return void 0;
        }
        const result: MachineMaintenanceSettingItem = {
            /** 本体稼働時間[Hr] を表します。*/
            totalTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.TotalTime)?.parameter ?? "",
            /** エンジン稼働時間[Hr] を表します。*/
            engineTotalTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.EngineTotalTime)?.parameter ?? "",
            /** クレーン昇降距離[m] を表します。*/
            craneTotalDistance: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.CraneTotalDistance)?.parameter ?? "",
            /** メインポンプ稼働時間1[Hr] を表します。*/
            mainPumpTotalTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.MainPumpTotalTime)?.parameter ?? "",
            /** ギアポンプ稼働時間2[Hr] を表します。*/
            gearPumpTotalTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.GearPumpTotalTime)?.parameter ?? "",
            /** オーガー1稼働時間[Hr] を表します。*/
            augerTotalTime1: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.AugerTotalTime1)?.parameter ?? "",
            /** オーガー2稼働時間[Hr] を表します。*/
            augerTotalTime2: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.AugerTotalTime2)?.parameter ?? "",
            /** 作動油交換後時間[Hr] を表します。*/
            workingOilTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.WorkingOilTime)?.parameter ?? "",
            /** エンジンオイル交換後時間[Hr] を表します。*/
            engineOilTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.EngineOilTime)?.parameter ?? "",
            /** オイルエレメント交換後時間[Hr] を表します。*/
            oilElementTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.OilElementTime)?.parameter ?? "",
            /** エアーエレメント交換後時間[Hr] を表します。*/
            airElementTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.AirElementTime)?.parameter ?? "",
            /** 燃料エレメント交換後時間[Hr] を表します。*/
            fuelElementTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.FuelElementTime)?.parameter ?? "",
            /** 作動油エレメント交換後時間[Hr] を表します。*/
            workingOilElementTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.WorkingOilElementTime)?.parameter ?? "",
            /** バッテリー交換後日数[日] を表します。*/
            batteryTime: MachineStatusMonitoringUtility.toResetRequestDateParameter(MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.BatteryTime)?.parameter ?? ""),
            /** クーラント交換後日数[日] を表します。*/
            coolantTime: MachineStatusMonitoringUtility.toResetRequestDateParameter(MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.CoolantTime)?.parameter ?? ""),
            /** ファンベルト交換後時間[Hr] を表します。*/
            fanBeltTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.FanBeltTime)?.parameter ?? "",
            /** オルタネーター交換後時間[Hr] を表します。*/
            alternatorTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.AlternatorTime)?.parameter ?? "",
            /** セルモーター交換後時間[Hr] を表します。*/
            starterMotorTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.StarterMotorTime)?.parameter ?? "",
            /** 油圧ポンプカップリング交換後時間[Hr] を表します。*/
            oilPressurePumpCouplingTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.OilPressurePumpCouplingTime)?.parameter ?? "",
            /** 油圧ポンプ減速機オイル交換後時間[Hr] を表します。*/
            oilPressurePumpDeceleratorOilTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.OilPressurePumpDeceleratorOilTime)?.parameter ?? "",
            /** ウインチ減速機オイル交換後時間[Hr] を表します。*/
            winchDeceleratorOilTime: MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.WinchDeceleratorOilTime)?.parameter ?? "",
            /** 主巻ワイヤ交換後日数[日] を表します。*/
            primaryHoistingWire: MachineStatusMonitoringUtility.toResetRequestDateParameter(MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.PrimaryHoistingWire)?.parameter ?? ""),
            /** 補巻ワイヤ交換後日数[日] を表します。*/
            subHoistingWire: MachineStatusMonitoringUtility.toResetRequestDateParameter(MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.SubHoistingWire)?.parameter ?? ""),
            /** 起伏ワイヤ交換後日数[日] を表します。*/
            upsAndDownsWire: MachineStatusMonitoringUtility.toResetRequestDateParameter(MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.UpsAndDownsWire)?.parameter ?? ""),
            /** ペンダントロープ交換後日数[日] を表します。*/
            pendantRope: MachineStatusMonitoringUtility.toResetRequestDateParameter(MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.PendantRope)?.parameter ?? ""),
            /** 特定自主検査後日数[日] を表します。*/
            selfInspection: MachineStatusMonitoringUtility.toResetRequestDateParameter(MachineStatusMonitoringUtility.toFindResetRequestItem(items, ResetSubRequestType.SelfInspection)?.parameter ?? ""),
        };
        return result;

    }


    /**
     * リセット履歴項目を変換します。
     * @param items
     */
    public static toMachineMaintenanceSettingResetHistoryItem(items?: ResetHistoryItem[]): MachineMaintenanceSettingResetHistoryItem[] {
        const result: MachineMaintenanceSettingResetHistoryItem[] = [];
        if (items == null) return result;

        items.forEach(items => {
            result.push({
                machineDataType: MachineStatusMonitoringUtility.toMachineDataType(items.type),
                resetHistories: MachineStatusMonitoringUtility.toMachineMaintenanceSettingResetHistoryListItem(items.resetHistories),
            });
        });

        return result;
    }

    /**
     *  施工機モニタ―情報一覧から指定種別の情報を取得します。
     * @param source　施工機モニタ―情報一覧を指定します。
     * @param type 種別を指定します。
     * @returns 見つかった情報を返します。
     */
    private static toFindItem(source: DtoItem[], type: DtoMachineDataType): DtoItem {
        const result = source.find(item => item.type === type);
        if (result == null) {
            throw new Error("toFindItem");
        }
        return result;
    }

    /**
     *  制御要求情報一覧から指定種別の情報を取得します。
     * @param source　制御要求情報一覧を指定します。
     * @param type 種別を指定します。
     * @returns 見つかった情報を返します。
     */
    private static toFindResetRequestItem(source: ResetRequestItem[], type: ResetSubRequestType): ResetRequestItem | undefined {
        const result = source.find(item => item.type === type);
        return result;
    }

    /**
     * 施工機ステータスの昇降状態を変換します。
     * @param source 施工機モニタ―情報を指定します。
     */
    private static toUpDownState(item: DtoItem): MachineStatusMonitoringUpDownState | undefined {
        const result: MachineStatusMonitoringUpDownState | undefined =
            item.value === MachineStatusMonitoringUtility.NotAvailable ? MachineStatusMonitoringUpDownState.NotAvailable :
            item.value === MachineStatusMonitoringUtility.Stop ? MachineStatusMonitoringUpDownState.Stop :
            item.value === MachineStatusMonitoringUtility.Up ? MachineStatusMonitoringUpDownState.Up :
            item.value === MachineStatusMonitoringUtility.Down ? MachineStatusMonitoringUpDownState.Down :
            void 0;
        return result;
    }

    /**
    * 施工機ステータスの Bool 値を変換します。
     * @param source 施工機モニタ―情報を指定します。
    */
    private static toBoolState(item: DtoItem): MachineStatusMonitoringBoolState | undefined {
        const result: MachineStatusMonitoringBoolState | undefined =
            item.value === MachineStatusMonitoringUtility.NotAvailable ? MachineStatusMonitoringBoolState.NotAvailable :
            item.value === MachineStatusMonitoringUtility.On ? MachineStatusMonitoringBoolState.On :
            item.value === MachineStatusMonitoringUtility.Off ? MachineStatusMonitoringBoolState.Off :
            void 0;
        return result;
    }

    /**
     * 施工機ステータスの Bool 値を変換します。
     * @param source 施工機モニタ―情報を指定します
     */
    private static toBoolean(item: DtoItem): boolean | undefined {
        const result: boolean | undefined =
            item.value === MachineStatusMonitoringUtility.On ? true :
            item.value === MachineStatusMonitoringUtility.Off ? false :
            void 0;
        return result;
    }


    /**
     * 管理計器ステータスを変換します。
     * @param item
     */
    public static toMachineStatus(item?: DtoMachineStatus): MachineStatusControlMeterState | undefined {
        const result: MachineStatusControlMeterState | undefined =
            item === DtoMachineStatus.Penetration ? MachineStatusControlMeterState.Penetration :
            item === DtoMachineStatus.Depth ? MachineStatusControlMeterState.Depth :
            item === DtoMachineStatus.TipPullOut ? MachineStatusControlMeterState.TipPullOut :
            item === DtoMachineStatus.TipHitBack ? MachineStatusControlMeterState.TipHitBack :
            item === DtoMachineStatus.TipStir ? MachineStatusControlMeterState.TipStir :
            item === DtoMachineStatus.PullOut ? MachineStatusControlMeterState.PullOut :
            item === DtoMachineStatus.RePenePullOut ? MachineStatusControlMeterState.RePenePullOut :
            item === DtoMachineStatus.RePeneHitBack ? MachineStatusControlMeterState.RePeneHitBack :
            item === DtoMachineStatus.Stop ? MachineStatusControlMeterState.Stop :
            item === DtoMachineStatus.Other ? MachineStatusControlMeterState.Other :
            void 0;
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: MachineStatusMonitoringRequest): GetMachineStatusMonitoringInput {
        const result = new GetMachineStatusMonitoringInput();
        result.init({
            machineId: source.id,
        });
        return result;
    }

    /**
    * 入力情報に変換します。
    *
    * @param source 変換元の値を指定します。
    * @returns 変換結果を返します。
    */
    public static toClientRequestInput(source: MachineMaintenanceSettingRequest): AddOrUpdateClientRequestInput {
        if (source.reset == null) {
            throw new Error("toClientRequestInput");
        }
        const result = new AddOrUpdateClientRequestInput();
        result.init({
            machineId: source.id,
            type: RequestType.Reset,
            reset: MachineStatusMonitoringUtility.toResetRequestItem(source.reset),
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGetClientRequestInput(source: MachineMaintenanceSettingRequest): GetClientRequestInput {
        const result = new GetClientRequestInput();
        result.init({
            machineId: source.id,
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGetResetHistroyInput(source: MachineMaintenanceSettingRestHistoryRequest): GetResetHistroyInput {
        const result = new GetResetHistroyInput();
        result.init({
            machineId: source.id,
            subRequestId: source.machineDataType ? MachineStatusMonitoringUtility.toResetSubRequestType(source.machineDataType) : void 0,
        });
        return result;
    }

    /**
    * 入力項目に変換します。
    *
    * @param source 変換元の値を指定します。
    * @returns 変換結果を返します。
    */
    public static toResetRequestItem(source: MachineMaintenanceSettingInputItem): ResetRequestItem {
        const result = new ResetRequestItem();
        result.init({
            type: MachineStatusMonitoringUtility.toResetSubRequestType(source.machineDataType),
            parameter: source.value,
            hrMeter: source.hrMeter?.toString(),
        });
        return result;

    }

    /**
     * ステータス項目種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toResetSubRequestType(source: MachineDataType): ResetSubRequestType {
        const result: ResetSubRequestType | undefined =
            source === MachineDataType.EngineTotalTime ? ResetSubRequestType.EngineTotalTime :
            source === MachineDataType.CraneTotalDistance ? ResetSubRequestType.CraneTotalDistance :
            source === MachineDataType.MainPumpTotalTime ? ResetSubRequestType.MainPumpTotalTime :
            source === MachineDataType.GearPumpTotalTime ? ResetSubRequestType.GearPumpTotalTime :
            source === MachineDataType.AugerTotalTime1 ? ResetSubRequestType.AugerTotalTime1 :
            source === MachineDataType.AugerTotalTime2 ? ResetSubRequestType.AugerTotalTime2 :
            source === MachineDataType.WorkingOilTime ? ResetSubRequestType.WorkingOilTime :
            source === MachineDataType.EngineOilTime ? ResetSubRequestType.EngineOilTime :
            source === MachineDataType.OilElementTime ? ResetSubRequestType.OilElementTime :
            source === MachineDataType.AirElementTime ? ResetSubRequestType.AirElementTime :
            source === MachineDataType.FuelElementTime ? ResetSubRequestType.FuelElementTime :
            source === MachineDataType.WorkingOilElementTime ? ResetSubRequestType.WorkingOilElementTime :
            source === MachineDataType.BatteryTime ? ResetSubRequestType.BatteryTime :
            source === MachineDataType.CoolantTime ? ResetSubRequestType.CoolantTime :
            source === MachineDataType.FanBeltTime ? ResetSubRequestType.FanBeltTime :
            source === MachineDataType.AlternatorTime ? ResetSubRequestType.AlternatorTime :
            source === MachineDataType.StarterMotorTime ? ResetSubRequestType.StarterMotorTime :
            source === MachineDataType.OilPressurePumpCouplingTime ? ResetSubRequestType.OilPressurePumpCouplingTime :
            source === MachineDataType.OilPressurePumpDeceleratorOilTime ? ResetSubRequestType.OilPressurePumpDeceleratorOilTime :
            source === MachineDataType.WinchDeceleratorOilTime ? ResetSubRequestType.WinchDeceleratorOilTime :
            source === MachineDataType.PrimaryHoistingWire ? ResetSubRequestType.PrimaryHoistingWire :
            source === MachineDataType.SubHoistingWire ? ResetSubRequestType.SubHoistingWire :
            source === MachineDataType.UpsAndDownsWire ? ResetSubRequestType.UpsAndDownsWire :
            source === MachineDataType.PendantRope ? ResetSubRequestType.PendantRope :
            source === MachineDataType.SelfInspection ? ResetSubRequestType.SelfInspection :
            source === MachineDataType.TotalTime ? ResetSubRequestType.TotalTime :
            void 0;
        if (result == null) {
            throw new Error("toResetSubRequestType");
        }
        return result;
    }


    /**
     * ステータス項目種別に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toMachineDataType(source: ResetSubRequestType): MachineDataType {
        const result: MachineDataType | undefined =
            source === ResetSubRequestType.EngineTotalTime ? MachineDataType.EngineTotalTime :
            source === ResetSubRequestType.CraneTotalDistance ? MachineDataType.CraneTotalDistance :
            source === ResetSubRequestType.MainPumpTotalTime ? MachineDataType.MainPumpTotalTime :
            source === ResetSubRequestType.GearPumpTotalTime ? MachineDataType.GearPumpTotalTime :
            source === ResetSubRequestType.AugerTotalTime1 ? MachineDataType.AugerTotalTime1 :
            source === ResetSubRequestType.AugerTotalTime2 ? MachineDataType.AugerTotalTime2 :
            source === ResetSubRequestType.WorkingOilTime ? MachineDataType.WorkingOilTime :
            source === ResetSubRequestType.EngineOilTime ? MachineDataType.EngineOilTime :
            source === ResetSubRequestType.OilElementTime ? MachineDataType.OilElementTime :
            source === ResetSubRequestType.AirElementTime ? MachineDataType.AirElementTime :
            source === ResetSubRequestType.FuelElementTime ? MachineDataType.FuelElementTime :
            source === ResetSubRequestType.WorkingOilElementTime ? MachineDataType.WorkingOilElementTime :
            source === ResetSubRequestType.BatteryTime ? MachineDataType.BatteryTime :
            source === ResetSubRequestType.CoolantTime ? MachineDataType.CoolantTime :
            source === ResetSubRequestType.FanBeltTime ? MachineDataType.FanBeltTime :
            source === ResetSubRequestType.AlternatorTime ? MachineDataType.AlternatorTime :
            source === ResetSubRequestType.StarterMotorTime ? MachineDataType.StarterMotorTime :
            source === ResetSubRequestType.OilPressurePumpCouplingTime ? MachineDataType.OilPressurePumpCouplingTime :
            source === ResetSubRequestType.OilPressurePumpDeceleratorOilTime ? MachineDataType.OilPressurePumpDeceleratorOilTime :
            source === ResetSubRequestType.WinchDeceleratorOilTime ? MachineDataType.WinchDeceleratorOilTime :
            source === ResetSubRequestType.PrimaryHoistingWire ? MachineDataType.PrimaryHoistingWire :
            source === ResetSubRequestType.SubHoistingWire ? MachineDataType.SubHoistingWire :
            source === ResetSubRequestType.UpsAndDownsWire ? MachineDataType.UpsAndDownsWire :
            source === ResetSubRequestType.PendantRope ? MachineDataType.PendantRope :
            source === ResetSubRequestType.SelfInspection ? MachineDataType.SelfInspection :
            source === ResetSubRequestType.TotalTime ? MachineDataType.TotalTime :
            void 0;
        if (result == null) {
            throw new Error("toMachineDataType");
        }
        return result;
    }


    /**
     * リセット履歴テーブル項目に変換します。
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toMachineMaintenanceSettingResetHistoryListItem(items?: KeyValueItemOfDateTimeAndString[]): MachineMaintenanceSettingResetHistoryListItem[] {
        const result: MachineMaintenanceSettingResetHistoryListItem[] = []
        if (items == null) return result;

        items.forEach(source => {
            if (!source.key || !source.value) return;

            result.push({
                resetAt: SharedUtility.toDateTimeString(new Date(source.key)),
                resetValue: source.value,
            });
        })

        return result;
    }


    /**
     * カレンダー形式項目のリセット日付に変換します。
     * @param parameter 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toResetRequestDateParameter(parameter: string): string {
        return !parameter ? "" : SharedUtility.toDateString(new Date(parameter));
    }

    //#endregion メソッド
}
