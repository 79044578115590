import { MethodListItem, MethodInputItem } from '../../../pages/Master/Method/_types';
import { MethodRequest } from './types';
import { MethodInput, MethodItem } from '../../swagger-clients';

/**
 * `工法マスタ` 関連の ユーティリティ機能を提供します。
 */
export class MethodUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: MethodItem[]): MethodListItem[] {
        const result: MethodListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.id == null || source.lockVersion == null) {
                continue;
            }
            const item: MethodListItem = {
                id: source.id,
                method: source.name ?? "",
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: MethodRequest): MethodInput {
        const result = new MethodInput();
        result.init({
            item: MethodUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: MethodInputItem): MethodItem {
        const result = new MethodItem();
        result.init({
            id: source.id,
            name: source.method,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
