// react
import * as React from 'react';
// material-ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.primary.dark
            },
            "&:disabled": {
                backgroundColor: theme.palette.primary.light
            }
        }
    }),
);

/**
 *  新規作成ボタンを表示します。
 * @param props
 */
const MuiCreateButton: React.FC<ButtonProps> = (props) => {
    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (

        <Button
            variant="contained"
            classes={{ root: classes.root }}
            startIcon={<AddIcon />}
            onClick={props.onClick}
            {...props}
        >
            新規作成
        </Button>
    )
}
export default MuiCreateButton;
