// S3
import { S3Client, ListObjectsCommand, GetObjectCommand, PutObjectCommand, DeleteObjectCommand, DeleteObjectsCommand, DeleteObjectsCommandOutput, ObjectIdentifier, DeleteObjectCommandOutput } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { FileManageUtility } from "./file-manage-utility";
import { S3GetRequest, S3ErrorOutput, S3DeleteResult, S3GetListFilesResult, S3GetFileResult, S3PutResult, S3PutRequest, S3DeleteRequest } from "./types";
import { FileInfo, PutedFileItem } from "../../../pages/FileManager/FileManage/_types";
import path from 'path';

/** コマンド種別 */
type SendCommandType = ListObjectsCommand | GetObjectCommand | PutObjectCommand | DeleteObjectCommand;

/**
 * `ファイル管理` 関連の `Amazon S3` と 通信する機能を提供します。
 */
export class FileManageAdapter {

    //#region フィールド

    /** Amazon S3　クライアントを表します。 */
    private _client: S3Client;

    /** リージョン を表します。*/
    private readonly REGION = "ap-northeast-1";

    /** バケット名 を表します。*/
    private readonly IDENTITY_POOL_ID = "ap-northeast-1:3c4dbdd4-61cd-4ace-bd11-1a9f0226deb4";

    ///** AWS Cognito の　IDプール を表します。*/
    private readonly BUCKET_NAME = "fudotetra";

    //private readonly BUCKET_NAME = "uedev";
    //　private readonly IDENTITY_POOL_ID = "ap-northeast-1:452a5086-357d-49e6-9652-7a78fd7eed1b";

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new FileManageAdapter();
    
    //#endregion フィールド

    //#region メソッド

    /**
     * パラメーター内容を検証します。
     * @param parameter
     * @throws {string} エラーメッセージをスローします。
     */
    private validateS3GetRequest(parameter: S3GetRequest): void {
        if (!parameter.key) throw new Error("key が指定されていません。");
        return;
    }

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createErrorResult(message?: string): S3ErrorOutput {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
     * 結果情報を作成します。
     * @param output 項目一覧を指定します。
     * @returns 結果情報を返します。
     */
    private createDeleteResult(output: DeleteObjectsCommandOutput, directoryName: string): S3DeleteResult {
        const isResultError = (output.Errors?.length ?? 0) > 0;
        let result: S3DeleteResult = {
            isError: isResultError,
            errorMessage: isResultError ? "削除に失敗した項目があります。" : "",
            successItems: FileManageUtility.toSuccessFileItem(directoryName, output.Deleted),
            errorItems: FileManageUtility.toErrorFileItem(directoryName, output.Errors),
        };
        return result;
    }

    /**
     * ファイルリストを取得します。
     * @param parameter　パラメーターを指定します。
     */
    public async getListFilesAsync(parameter: S3GetRequest): Promise<S3GetListFilesResult> {
        // チェック
        this.validateS3GetRequest(parameter);
        // コマンド生成
        const command = new ListObjectsCommand({
            Bucket: this.BUCKET_NAME,
            Prefix: FileManageUtility.addTrailingSlash((parameter.key)),
            Delimiter: "/",
        });

        // 通信処理
        let result: S3GetListFilesResult;
        try {
            const res = await this._client.send(command);

            // success
            result = {
                items: FileManageUtility.toListItem(res),
                isError: false,
            };
        } catch (error) {
            console.error("getListFilesAsync", error);
            // error
            result = {
                items: [],
                ...this.createErrorResult(error.message)
            };
        };
        return result;
    }

    /**
     * ファイルオブジェクトを取得します。
     * @param parameter　パラメーターを指定します。
     */
    public async getFileAsync(parameter: S3GetRequest): Promise<S3GetFileResult> {
        // チェック
        this.validateS3GetRequest(parameter);
        // コマンド生成
        const command = new GetObjectCommand({
            Bucket: this.BUCKET_NAME,
            Key: parameter.key,
        });

        // 通信処理
        let result: S3GetFileResult;
        try {
            const res = await this._client.send(command);
            if (res.Body == null) throw new Error("ファイル情報がありません。");
            // success
            result = {
                file: await FileManageUtility.asBlob(res),
                isError: false,
            };

        } catch (error) {
            console.error("getFileAsync", error);
            // error
            result = {                
                ...this.createErrorResult(error.message)
            };
        };

        return result;
    }

    /**
     * コマンドを送信します。
     * @param command　送信コマンドを指定します。
     */
    private async sendPutObjectAsync(command: PutObjectCommand): Promise<S3PutResult> {
        // 通信処理
        let result: S3PutResult;
        try {
            const res = await this._client.send(command);
            // success
            result = {
                isError: false,
            };
        } catch (error) {
            // error
            result = this.createErrorResult(error.message);
        };
        return result;
    }

    /**
     * ファイルオブジェクトを送信します。
     * @param parameter　パラメーターを指定します。
     */
    private async putFileAsync(parameter: FileInfo): Promise<PutedFileItem> {
        let result: PutedFileItem;
        try {
            // チェック
            const errorItem = !parameter.key ? "キー"
                            : !parameter.file ? "ファイル情報"
                            : "";
            if (errorItem !== "") {
                // 上位側にエラーはスローせず、結果として返す。
                throw new Error(`${errorItem} が指定されていません`);
            }

            // コマンド生成
            const command = new PutObjectCommand({
                Bucket: this.BUCKET_NAME,
                Key: parameter.key,
                Body: parameter.file,
                ContentType: parameter.file?.type,
            });

            // 通信処理
            const res = await this._client.send(command);
            // success
            result = {
                key: parameter.file?.name ?? parameter.key,
                isError: false,
            };
        } catch (error) {
            // error
            result =
            {
                key: parameter.file?.name ?? parameter.key,
                isError: true,
                message: error.message ?? "通信に失敗しました。",
            };
        }
        return result;
    }

    /**
     * ファイルオブジェクトを送信します。
     * @param parameter　パラメーターを指定します。
     */
    public async putFilesAsync(parameter: S3PutRequest): Promise<S3PutResult> {

        // １ファイルずつ処理
        const results = await Promise.all(parameter.files.map((fileInfo) => this.putFileAsync(fileInfo)));

        // 結果
        const successItems: PutedFileItem[] = [];
        const errorItems: PutedFileItem[] = [];
        results.forEach(r => {
            if (r.isError) {
                errorItems.push(r);
            } else {
                successItems.push(r);
            };
        });
        const result: S3PutResult = {
            isError: false,
            successItems: successItems.slice(),
            errorItems: errorItems.slice(),
        };
        return result;
    }

    /**
     * ディレクトリを作成します。
     * @param parameter　パラメーターを指定します。
     */
    public async putDirectoryAsync(parameter: S3PutRequest): Promise<S3PutResult> {
        // 複数情報を処理することも出来るが、ここでは1つのみとする。
        if (parameter.files.length < 1) throw new Error("parameter が不正です。");
        const dirInfo = parameter.files[0];

        // チェック
        if (!dirInfo.key) throw new Error("key が指定されていません。")

        // コマンド生成
        const command = new PutObjectCommand({
            Bucket: this.BUCKET_NAME,
            Key: FileManageUtility.addTrailingSlash(dirInfo.key),
        });

        // 通信処理
        return await this.sendPutObjectAsync(command);
    }

    /**
     * ファイルオブジェクトを削除します。
     * @param parameter　パラメーターを指定します。
     */
    public async deleteObjectsAsync(parameter: S3DeleteRequest): Promise<S3DeleteResult> {
        // チェック
        if (!parameter.keys?.length) throw Error("keys　が指定されていません。")

        const deletePromises: Promise<S3DeleteResult>[] = [];
        parameter.keys.forEach(key =>
            deletePromises.push(
                this.deleteFoldersAsync(
                    key,
                    { isError: false, successItems: [], errorItems: [] },
                    parameter.directoryName,
                )
            )
        );

        let result: S3DeleteResult = {
            isError: false,
            successItems: [],
            errorItems: [],
        };

        const r = await Promise.all(deletePromises);
        r.forEach(res => {
            result = FileManageUtility.margeResult(result, res)
        });

        return result;
    }

    /**
     * 
     * @param key
     */
    private async deleteFoldersAsync(key: string, result: S3DeleteResult, directoryName: string): Promise<S3DeleteResult> {

        // フォルダ配下のファイルを全取得する
        const getRes = await this.getListFilesAsync({ key });
        if (getRes.isError) {
            const msg = getRes.errorMessage ?? `${key}のファイル情報取得に失敗しました。`
            const getListError: S3DeleteResult = {
                isError: true,
                errorMessage: msg,
                successItems: [],
                errorItems: [{
                    key: key.replace(directoryName, ""),
                    directoryName,
                    message: msg,
                }],
            } 
            return FileManageUtility.margeResult(result, getListError); 
        }

        // 配下にオブジェクトが無ければ、指定フォルダ削除可能
        if (!getRes.items.length) {
            // 指定したフォルダを削除する。
            const res = await this.deleteFilesAsync({ keys: [key], directoryName });
            return FileManageUtility.margeResult(result, res);
        };

        // フォルダとファイルを分けて、再リクエスト
        const files: string[] = [];
        const folders: string[] = [];
        getRes.items.forEach(item => {
            if (item.key.endsWith("/")) {
                folders.push(item.key);
            } else {
                files.push(item.key);
            }
        });

        if (folders.length > 0) {
            // サブフォルダの削除を行う
            const deletePromises: Promise<S3DeleteResult>[] = [];
            folders.forEach((folder) => deletePromises.push(this.deleteFoldersAsync(folder, result, directoryName)));

            const responses = await Promise.all(deletePromises);
            responses.forEach(res => {
                result = FileManageUtility.margeResult(result, res);
            })

        } else if (files.length > 0) {
            // 配下のファイル
            const deleteFilesRes = await this.deleteFilesAsync({ keys: files, directoryName });
            result = FileManageUtility.margeResult(result, deleteFilesRes);
        }

        // エラー時
        if (result.isError || result.errorItems.length > 0) return result;

        // 再帰的に呼び出し
        const res = await this.deleteFoldersAsync(key, result, directoryName);
        return FileManageUtility.margeResult(result, res);
    }

    /**
     * ファイルオブジェクトを削除します。
     * @param parameter　パラメーターを指定します。
     */
    public async deleteFilesAsync(parameter: S3DeleteRequest): Promise<S3DeleteResult> {
        // チェック
        if (!parameter.keys?.length) throw Error("keys　が指定されていません。")

        // コマンド生成
        const objects: ObjectIdentifier[] = [];

        parameter.keys.forEach(key => objects.push({ Key: key }));
        const command = new DeleteObjectsCommand({
            Bucket: this.BUCKET_NAME,
            Delete: {
                Objects: objects.slice(),
            }
        });

        // 通信処理
        let result: S3DeleteResult;
        try {
            const res = await this._client.send(command);
            // success
            result = this.createDeleteResult(res, parameter.directoryName);
        } catch (error) {
            console.error("deleteFilesAsync", error);
            // error
            result = {
                successItems: [],
                errorItems: [],
                ...this.createErrorResult(error.message),
            };
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {

        /** S3 クライアント */
        this._client = new S3Client({
            region: this.REGION,
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: this.REGION }),
                identityPoolId: this.IDENTITY_POOL_ID,
            }),
        })

        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
