import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

/** 計測結果カードの計測値のインターフェイスを提供します。 */
interface ValueUnit {
    /** 計測値を表します。 */
    value: string,
    /** 単位を表します。 */
    unit?: string,
};

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "block",
            minHeight: "136px",
        },
        // タイトル
        title: {
            //whiteSpace: "nowrap",
            //overflow: "hidden",
            //textOverflow: "ellipsis",
            fontSize: "1.25rem",

        },
        //　取得値コンテナ
        textContainer: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "baseline",
            justifyContent: "flex-end",
        },
        // 値
        value: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: theme.palette.success.main,
            textAlign: "end",
        },
        // 単位
        unit: {
            fontSize: "1.2rem",
            textAlign: "start",
            marginLeft: "0.5rem",
        },
    }),
);

/** 計測結果カードの Props を提供します。 */
type Props = {
    /** タイトルを表します。 */
    title: string,
    /** 取得値を表します。*/
    measuredvalue: ValueUnit,
};

/**
 * 計測結果カードを提供します。
 * @param props
 */
const MeasuredCard: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles(props);

    return (
        <Card className={classes.root} >
            <CardContent>
                <Typography component="p" variant="subtitle1" className={classes.title}>
                    {props.title}
                </Typography>

                <Divider />

                <Grid container className={classes.textContainer}>
                    {/* 値 */}
                    <Grid item xs className={classes.value}>
                        {!props.measuredvalue.value ? '\u00A0' : props.measuredvalue.value}
                    </Grid>
                    {/* 単位 */}
                    <Grid item xs={3} className={classes.unit}>
                        {props.measuredvalue.unit}
                    </Grid>
                </Grid>

            </CardContent>
        </Card >
    );
};

export default MeasuredCard;
