import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { AreaType } from '../../../_types';
import { AreaTypeUtility } from './area-type-slice-utility';

/** エリア種別選択の State を提供します。 */
interface State {
    /** エリアを表します。 */
    area: AreaType;
}

/** State の初期値を設定します。 */
const initialState: State = {
    area: AreaTypeUtility.getStorageAreaType(),
}


/** エリア種別 Slice を 提供します。*/
export const areaTypeSlice = createSlice({
    name: 'areaType',    // createSlice を識別するための名前
    initialState,       // State の初期値を入れる
    reducers: {         // State の変更処理

        /**
         * エリア種別を変更します。
         * @param state
         * @param action
         */
        setAreaType(state: State, action: PayloadAction<State>) {
            state.area = action.payload.area;
        },
    }
})

/**
 * エリア種別を変更します。
 * @param area エリア種別を指定します。
 */
export function changeAreaType(selectedArea: AreaType) {
    return (dispatch: Dispatch) => {
        // ストレージ更新
        AreaTypeUtility.setStorageAreaType(selectedArea);

        // store 更新
        dispatch(areaTypeSlice.actions.setAreaType({ area: selectedArea }))
    }
}
