// redux
import { Dispatch } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
// shared
import { ExtendGenericState, createExtendGenericSlice } from '../shared/create-extend-generic-slice';
import { S3GetListFilesResult, S3GetFileResult, S3PutResult, S3DeleteResult, S3GetRequest, S3PutRequest, S3DeleteRequest } from '../../adapter/file-manage/types';
import { FileManageAdapter } from '../../adapter/file-manage/file-manage-adapter';
import { HomeDirectoryType, S3FileListItem, DirectoryItem } from '../../../pages/FileManager/FileManage/_types';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { FileManageUtility } from '../../adapter/file-manage/file-manage-utility';

/** ファイル管理 アイテム情報の State を表します。 */
interface FileManageItemState {
    /** ディレクトリ情報を表します。 */
    directoryItem: DirectoryItem,
    /** 選択項目を表します。 */
    selectedRowItems: S3FileListItem[]
    /** アップロードダイアログの開閉状態を表します。 */
    isOpenUploadDialog: boolean,
    /** フォルダ作成ダイアログの開閉状態を表します。 */
    isOpenCreateFolderDialog: boolean,
    /** 削除ダイアログの開閉状態を表します。 */
    isOpenDeleteDialog: boolean,
    /** アップロード 結果ダイアログの開閉状態を表します。 */
    isOpenUploadResultDialog: boolean,
    /** 削除 結果ダイアログの開閉状態を表します。 */
    isOpenDeleteResultDialog: boolean,

    isUpdated: boolean;
}

/** ファイル管理 State を提供します。 */
interface FileManageSliceState {
    /** 一覧取得結果情報を表します。 */
    getFileListResult?: S3GetListFilesResult,
    /** ファイル取得結果情報を表します。 */
    getFileResult?: S3GetFileResult,
    /** 送信結果情報*/
    putResult?: S3PutResult,
    /** 削除送信結果情報*/
    deleteResult?: S3DeleteResult,
}

/** State の初期値を設定します。 */
const initialState: ExtendGenericState<FileManageSliceState, FileManageItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {},
    item: {
        directoryItem: { root: FileManageUtility.rootDirectory, home: HomeDirectoryType.Document, sub: [] },
        selectedRowItems: [],
        isOpenUploadDialog: false,
        isOpenCreateFolderDialog: false,
        isOpenDeleteDialog: false,
        isOpenDeleteResultDialog: false,
        isOpenUploadResultDialog: false,
        isUpdated : true,
    }
}

/** ファイル管理 Slice を 提供します。*/
export const FileManageSlice = createExtendGenericSlice({
    // State Reducer Action を生成
    name: 'FileManage',
    initialState: initialState,
    reducers: {
        /**
         * 通信終了時の処理を行います。
         * @param state
         * @param action
         */
        fetchEndExtension(state: ExtendGenericState<FileManageSliceState, FileManageItemState>, action: PayloadAction<FileManageSliceState>) {
            state.isLoading = false;

            if (action.payload.getFileListResult) {
                state.result.getFileListResult = action.payload.getFileListResult;
            }
            if (action.payload.getFileResult) {
                state.result.getFileResult = action.payload.getFileResult;
            }
            if (action.payload.putResult) {
                state.result.putResult = action.payload.putResult;
            }
            if (action.payload.deleteResult) {
                state.result.deleteResult = action.payload.deleteResult;
            }
        },

        /**
         * カレントディレクトリを変更します。
         * @param state
         * @param action
         */
        selectedRows(state, action: PayloadAction<S3FileListItem[]>) {
            state.item.selectedRowItems = action.payload.map(value => {
                const newValue: S3FileListItem = {
                    key: value.key,
                    name: value.name,
                    type: value.type,
                    size: value.size,
                    lastModified: value.lastModified,
                }
                return newValue;
            });
        },

        /**
         * ダイアログの開閉状態を変更します。
         * @param state
         * @param action
         */
        toggleUploadDialog(state, action: PayloadAction<boolean>) {
            state.item.isOpenUploadDialog = action.payload;
        },

        /**
         * ダイアログの開閉状態を変更します。
         * @param state
         * @param action
         */
        toggleUploadResultDialog(state, action: PayloadAction<boolean>) {
            state.item.isOpenUploadResultDialog = action.payload;
        },

        /**
         * ダイアログの開閉状態を変更します。
         * @param state
         * @param action
         */
        toggleCreateFolderDialog(state, action: PayloadAction<boolean>) {
            state.item.isOpenCreateFolderDialog = action.payload;
        },

        /**
         * ダイアログの開閉状態を変更します。
         * @param state
         * @param action
         */
        toggleDeleteDialog(state, action: PayloadAction<boolean>) {
            state.item.isOpenDeleteDialog = action.payload;
        },

        /**
         * ダイアログの開閉状態を変更します。
         * @param state
         * @param action
         */
        toggleDeleteResultDialog(state, action: PayloadAction<boolean>) {
            state.item.isOpenDeleteResultDialog = action.payload;
        },

    }
});

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetListFilesAsync = (parameter: S3GetRequest) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(FileManageSlice.actions.fetchStart());
        dispatch(FileManageSlice.actions.updateItemPartial({ selectedRowItems:[] }));

        const dirItem = FileManageUtility.toDirItem(parameter.key);
        if (dirItem == null) {
            dispatch(FileManageSlice.actions.showErrorMessage(`一覧取得時のキーが不正です　key:${parameter.key}`));
            return;
        };

        // 通信処理
        const result = await FileManageAdapter.instance.getListFilesAsync(parameter);

        dispatch(FileManageSlice.actions.fetchEndExtension({ getFileListResult: result }));
        dispatch(FileManageSlice.actions.updateItemPartial({ directoryItem : dirItem }));

        if (result.isError) {
            // 異常
            dispatch(FileManageSlice.actions.showErrorMessage(result.errorMessage ?? ""));
        } else {
            // 正常
            dispatch(FileManageSlice.actions.showSuccessMessage());
        }
    } catch (error) {
        // 例外
        dispatch(FileManageSlice.actions.fetchEndExtension({
            getFileListResult: {
                items: [],
                isError: true,
                errorMessage: `${error}`,
            },
        }));
        dispatch(FileManageSlice.actions.showErrorMessage(`${error}`));

    } finally {
        // 処理中 OFF
        dispatch(FileManageSlice.actions.fetchEnd());
        dispatch(FileManageSlice.actions.updateItemPartial({ isUpdated : false }));
    }
}

/**
 * ファイル取得処理を行います。
 * @param parameter
 */
export const onGetFileAsync = (parameter: S3GetRequest) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(FileManageSlice.actions.fetchStart());
        // 通信処理
        const result = await FileManageAdapter.instance.getFileAsync(parameter);

        dispatch(FileManageSlice.actions.fetchEndExtension({ getFileResult: result }));

        if (result.isError) {
            // 異常
            dispatch(FileManageSlice.actions.showErrorMessage(result.errorMessage ?? ""));
        } else {
            // 正常
            dispatch(FileManageSlice.actions.showSuccessMessage());
        }
    } catch (error) {
        // 例外
        dispatch(FileManageSlice.actions.fetchEndExtension({
            getFileResult: {
                isError: true,
                errorMessage: `${error}`,
            },
        }));
        dispatch(FileManageSlice.actions.showErrorMessage(`${error}`));

    } finally {
        // 処理中 OFF
        dispatch(FileManageSlice.actions.fetchEnd());
    }
}

/**
 * フォルダ作成処理を行います。
 * @param parameter
 */
export const onPutDirectoryAsync = (parameter: S3PutRequest) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(FileManageSlice.actions.fetchStart());
        // 通信処理
        const result = await FileManageAdapter.instance.putDirectoryAsync(parameter);

        dispatch(FileManageSlice.actions.fetchEndExtension({ putResult: result }));

        if (result.isError) {
            // 異常
            dispatch(FileManageSlice.actions.showErrorMessage(result.errorMessage ?? ""));
        } else {
            // 正常
            dispatch(FileManageSlice.actions.showSuccessMessage());
            // ダイアログを閉じる
            dispatch(FileManageSlice.actions.updateItemPartial({
                isUpdated: true,
                isOpenCreateFolderDialog: false
            }));

        }
    } catch (error) {
        // 例外
        dispatch(FileManageSlice.actions.fetchEndExtension({
            putResult: {
                isError: true,
                errorMessage: `${error}`,
            },
        }));
        dispatch(FileManageSlice.actions.showErrorMessage(`${error}`));

    } finally {
        // 処理中 OFF
        dispatch(FileManageSlice.actions.fetchEnd());
    }
}

/**
 * ファイルアップロード処理を行います。
 * @param parameter
 */
export const onPutFilesAsync = (parameter: S3PutRequest) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(FileManageSlice.actions.fetchStart());
        // 通信処理
        const result = await FileManageAdapter.instance.putFilesAsync(parameter);

        dispatch(FileManageSlice.actions.fetchEndExtension({ putResult: result }));

    //    if (result.isError) {
    //        // 異常
    //        dispatch(FileManageSlice.actions.showErrorMessage(result.errorMessage ?? ""));
    //    } else {
    //        // 正常
    //        dispatch(FileManageSlice.actions.showSuccessMessage());
    //    }

        // ダイアログ
        dispatch(FileManageSlice.actions.updateItemPartial({
            isOpenUploadDialog: false,
            isOpenUploadResultDialog: true,
        }));

    } catch (error) {
        // 例外
        dispatch(FileManageSlice.actions.fetchEndExtension({
            putResult: {
                isError: true,
                errorMessage: `${error}`,
            },
        }));
        dispatch(FileManageSlice.actions.showErrorMessage(`${error}`));

    } finally {
        // 処理中 OFF
        dispatch(FileManageSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 */
export const onDeleteFilesAsync = (parameter: S3DeleteRequest, password: string) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(FileManageSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(FileManageSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));
        } else {
            // 削除実行
            const result = await FileManageAdapter.instance.deleteObjectsAsync(parameter);

            dispatch(FileManageSlice.actions.fetchEndExtension({ deleteResult: result }));

            // ダイアログ
            dispatch(FileManageSlice.actions.updateItemPartial({
                isOpenDeleteDialog: false,
                isOpenDeleteResultDialog: true,
            }));
        }

    } catch (error) {
        // 例外
        dispatch(FileManageSlice.actions.fetchEndExtension({
            deleteResult: {
                successItems: [],
                errorItems: [],
                isError: true,
                errorMessage: `${error}`,
            },
        }));
        dispatch(FileManageSlice.actions.showErrorMessage(`${error}`));

    } finally {
        // 処理中 OFF
        dispatch(FileManageSlice.actions.fetchEnd());
    }
}



