import { ManufacturerListItem, ManufacturerInputItem } from '../../../pages/Master/Manufacturer/_types';
import { ManufacturerRequest } from './types';
import { ManufacturerInput, ManufacturerItem } from '../../swagger-clients';

/**
 * `メーカーマスタ` 関連の ユーティリティ機能を提供します。
 */
export class ManufacturerUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: ManufacturerItem[]): ManufacturerListItem[] {
        const result: ManufacturerListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.id == null || source.lockVersion == null) {
                continue;
            }
            const item: ManufacturerListItem = {
                id: source.id,
                manufacturer: source.name ?? "",
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: ManufacturerRequest): ManufacturerInput {
        const result = new ManufacturerInput();
        result.init({
            item: ManufacturerUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: ManufacturerInputItem): ManufacturerItem {
        const result = new ManufacturerItem();
        result.init({
            id: source.id,
            name: source.manufacturer,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
