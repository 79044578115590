// react
import * as React from 'react';
// material-ui
import { Box, Card } from '@material-ui/core';

/** アラート履歴画面の Props を提供します。 */
interface Props {
    /** テーブルのデータソースを表します。 */
    table: React.ReactNode,
    /** グラフのデータソースを表します。 */
    graph: React.ReactNode,
};

/**
 * アラート履歴画面を表示します。
 * */
export const AlertRecordMain: React.FC<Props> = (props) => {

    return (
        <>
            {/* テーブル */}
            {props.table}

            {/* グラフ */}
            <Box mt={2}>
                <Card>
                    {props.graph}
                </Card>
            </Box>
        </>

    );
};
