// redux
import { Dispatch } from 'redux';
// shared
import { MasterGenericState, createMasterGenericSlice } from '../shared/create-master-slice';
// type
import { InspectionDivisionAdapter } from '../../adapter/inspection-division/inspection-division-adapter';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { InspectionDivisionRequest, InspectionDivisionResult, InspectionDivisionSelectBoxItemState } from '../../adapter/inspection-division/types';
import { InspectionDivisionListItem } from '../../../pages/Master/InspectionDivision/_types';
import { ReportAdapter } from '../../adapter/report/report-adapter';
import { KeyValueItem, InspectionType } from '../../../_types';
import { ReportResult } from '../../adapter/report/type';

// State の初期値を設定します。
const initialState: MasterGenericState<InspectionDivisionResult, InspectionDivisionListItem, InspectionDivisionSelectBoxItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    isCreating: false,
    isSelected: false,
    selectedRowItem: void 0,
    selectBoxItem: {
        InspectionGroup: [],
    },
    isDialogOpen: false,

}

/** 取得 Slice を 作成します。*/
export const InspectionDivisionSlice = createMasterGenericSlice({
    // State Reducer Action を生成
    name: 'InspectionDivision',
    initialState: initialState,
    reducers: {},
})

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onExcuteAsync = (promise: Promise<InspectionDivisionResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(InspectionDivisionSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(InspectionDivisionSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(InspectionDivisionSlice.actions.showErrorMessage(result.errorMessage));
        } else {
            // 正常
            dispatch(InspectionDivisionSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(InspectionDivisionSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(InspectionDivisionSlice.actions.chageCreating(false));
        }

    } catch (error) {

        //例外
        dispatch(InspectionDivisionSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(InspectionDivisionSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(InspectionDivisionSlice.actions.fetchEnd());
    }
}

/**
 * 画面表示時の初回非同期通信処理を行います。
 * @param action
 */
export const onExcuteMountedAsync = () => async (dispatch: Dispatch) => {

    try {
        // state リセット
        dispatch(InspectionDivisionSlice.actions.resetState());

        // 処理中 ON
        dispatch(InspectionDivisionSlice.actions.fetchStart());

        // 点検グループ
        const inspectionGroupResult = await SharedAdapter.instance.getInspectionGroupAsync();

        // 異常確認
        if (inspectionGroupResult.isError) {
            // 施工機種別 異常メッセージ
            dispatch(InspectionDivisionSlice.actions.showErrorMessage(`点検グループ取得エラー：${inspectionGroupResult.errorMessage}`));

        } else {
            // 正常
            // リストの項目を store に保存
            dispatch(InspectionDivisionSlice.actions.getSelectBoxItem({
                InspectionGroup: inspectionGroupResult.items,
            }));

            // データ取得
            const result = await InspectionDivisionAdapter.instance.getAsync(
                { item: {} },
                { InspectionGroup: inspectionGroupResult.items }
            );

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(InspectionDivisionSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(InspectionDivisionSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(InspectionDivisionSlice.actions.fetchSuccess(result));

                dispatch(InspectionDivisionSlice.actions.toggleDialog(false));

                // 正常メッセージ
                dispatch(InspectionDivisionSlice.actions.showSuccessMessage());


            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(InspectionDivisionSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(InspectionDivisionSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 * @param password
 */
export const onConfirmRemoveAsync = (
    parameter: InspectionDivisionRequest,
    selectBoxItem: InspectionDivisionSelectBoxItemState,
    password: string,
) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(InspectionDivisionSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(InspectionDivisionSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));

        } else {
            // 正常
            // 削除実行
            const result = await InspectionDivisionAdapter.instance.removeAsync(parameter, selectBoxItem);

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(InspectionDivisionSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(InspectionDivisionSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(InspectionDivisionSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(InspectionDivisionSlice.actions.showSuccessMessage("削除しました。"));

                // ダイアログを閉じる
                dispatch(InspectionDivisionSlice.actions.toggleDialog(false));

                // 選択項目のクリア
                dispatch(InspectionDivisionSlice.actions.clearSelectedItem());

            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(InspectionDivisionSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(InspectionDivisionSlice.actions.fetchEnd());
    }

}

/**
 * 点検票プレビュー情報を取得します。
 * @param type 点検種別を指定します。
 */
export const getIncspectionPreviewReportAsync = (type: InspectionType) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(InspectionDivisionSlice.actions.fetchStart());
        dispatch(InspectionDivisionSlice.actions.changeReportLoadingFlag(true));
        dispatch(InspectionDivisionSlice.actions.updatePreviewReport(""));

        let result: ReportResult;

        // 通信処理
        // 日常
        if (type === InspectionType.Daily) {
            result = await ReportAdapter.instance.getDailyPreviewReport();

        }
        // 週間月例
        else if (type === InspectionType.Weekly) {
            result = await ReportAdapter.instance.getWeeklyPreviewReport();

        }
        // 現場完了
        else if (type === InspectionType.Completion) {
            result = await ReportAdapter.instance.getCompletionPreviewReport();

        } else {
            // 例外
            throw new Error("type");
        }

        // 異常確認
        if (result.isError || !result.reportUrl) {
            // 異常メッセージ
            dispatch(InspectionDivisionSlice.actions.showErrorMessage(`帳票プレビュー情報 取得エラー：${result.errorMessage}`));
            dispatch(InspectionDivisionSlice.actions.changeReportLoadingFlag(false));

        } else {
            // 正常
            // メッセージ
            dispatch(InspectionDivisionSlice.actions.showSuccessMessage());
            dispatch(InspectionDivisionSlice.actions.updatePreviewReport(result.reportUrl));

        }

    } catch (error) {
        // メッセージ
        dispatch(InspectionDivisionSlice.actions.showErrorMessage(`${error}`));
        dispatch(InspectionDivisionSlice.actions.changeReportLoadingFlag(false));

    } finally {
        // 処理中 OFF
        dispatch(InspectionDivisionSlice.actions.fetchEnd());

    }
}
