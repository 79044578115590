import * as React from 'react';
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { AppBar, Toolbar } from '@material-ui/core';

/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            flexGrow: 1,
        },
        // ヘッダーバー
        appBar: {
            color: grey[800],// ("#424242")
            backgroundColor: "#9bf9cc",
        },
        //　メニューボタン
        menuButton: {
            marginRight: theme.spacing(2),
        },
        //　タイトル
        title: {
            fontSize: "1.5rem",
            minWidth: "200px",
            textAlign: "center",
            overflowWrap: "break-word",
            marginLeft: "auto",
            marginRight: "auto",
        },
        // ロゴ
        logo: {
            height: "45px",
            margin: theme.spacing(1),
        },
        // エラー情報アリ
        error: {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,

        }
    }),
);

/** メニューバーの Props を表します。 */
interface Props {
    /** CTUエラー施工機情報を表示するか画面かどうかを表します。 */
    isShowCtuError: boolean,
    /** エラーがあるかどうかを表します。 */
    isCtuError: boolean,
    /** CTUエラー施工機があるかどうかを表します。 */
    isExistMachineError: boolean,
}

/**
 * メニューバーを表します。
 * */
const MenuBar: React.FC<Props> = (props) => {

    const classes = useStyles();

    const isError = props.isShowCtuError ? props.isExistMachineError : props.isCtuError;

    return (
        <>
            <AppBar
                position={"sticky"}
                className={clsx(
                    classes.appBar,
                    isError && classes.error
                )}
            >
                <Toolbar>

                    {props.children}

                </Toolbar>
            </AppBar>

        </>
    );
}

export default MenuBar;
