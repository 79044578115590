import { OtherReportListItem, OtherReportSearchType, OtherReportImageSearchType } from '../../../pages/Report/OtherReport/_types';
import { SharedUtility } from '../../shared/shared-utility';
import { OtherReportItem, OtherReportSearchType as DtoSearchType, MachineType as DtoMachineType } from '../../swagger-clients';

/**
 * `その他報告` 関連の ユーティリティ機能を提供します。
 */
export class OtherReportUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: OtherReportItem[]): OtherReportListItem[] {
        const result: OtherReportListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            const dateAt = source.reportAt == null ? "" : SharedUtility.toDateTimeString(new Date(source.reportAt));
            const item: OtherReportListItem = {
                no: source.reportNo ?? "",
                reportAt: dateAt,
                reporter: source.reporter ?? "",
                site: source.site ?? "",
                item: source.reportItem ?? "",
                area: SharedUtility.toAreaText(source.areaId),
                areaType: SharedUtility.toAreaType(source.areaId),
                imagePaths: source.imageFilePath ?? [],
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 検索キーワード種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toSearchType(source: OtherReportSearchType): DtoSearchType {
        const result: DtoSearchType | undefined =
            source === OtherReportSearchType.None ? DtoSearchType.None :
            source === OtherReportSearchType.Reporter ? DtoSearchType.Reporter :
            source === OtherReportSearchType.Report ? DtoSearchType.Report :
            void 0;
        if (result == null) {
            throw new Error("toSearchType");
        }
        return result;
    }


    public static toIsSavedImage(source: OtherReportImageSearchType): boolean | undefined {
        const result: boolean | undefined =
            source === OtherReportImageSearchType.With ? true :
            source === OtherReportImageSearchType.Without ? false :
            void 0;

        return result;


    }

    //#endregion メソッド
}
