// react
import * as React from 'react';
import { useEffect } from 'react';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMasterPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { RemarksMasterSlice, onExcuteMountedAsync } from '../../../modules/slice/remarks/remarks-slice';
// component
import { RemarksResultTableContainer } from './components/TableContent/RemarksResultTableContainer';
import { RemarksSearchConditionContainer } from './components/SearchContent/RemarksSearchConditionContainer';
import { RemarksDetailConditionContainer } from './components/DetailContent/RemarksDetailConditionContainer';
import { ConfirmDialogContainer } from './components/ConfirmDialog/ConfirmDialogContainer';
import MasterLayout from '../Shared/MasterLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';


/**
 * 特記事項マスタ画面を提供します。
 * */
const RemarksPage: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeMasterPageHeader(AppTitle.masterRemarks.title));

        // 初回マウント時にデータを取得する
        dispatch(onExcuteMountedAsync());
    }, [dispatch]);

    // redux store
    const isLoading = useSelector((state: RootState) => state.remarksMaster.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.remarksMaster.isShowMessage);
    const message = useSelector((state: RootState) => state.remarksMaster.message);
    const isCreating = useSelector((state: RootState) => state.remarksMaster.isCreating);
    const isSelected = useSelector((state: RootState) => state.remarksMaster.isSelected);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(RemarksMasterSlice.actions.hideMessage());
    };

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ダイアログ */}
            <ConfirmDialogContainer />

            <MasterLayout
                search={<RemarksSearchConditionContainer />}
                table={<RemarksResultTableContainer />}
                detail={<RemarksDetailConditionContainer />}
                isCreating={isCreating}
                isSelected={isSelected}
            />
        </>
    );
};

export default RemarksPage;
