import * as React from 'react';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { ListItem, ListItemText, ListItemIcon, Box } from '@material-ui/core';

/** ダークモードトグルボタンの Props を表します。 */
interface Props {
    /** ダークモードかどうかを表します。 */
    isDark: boolean
    /** ダークモードON処理を表します。 */
    onClickDarkThemeOn: () => void,
    /** ダークモードOFF処理を表します。 */
    onClickDarkThemeOff: () => void,
}

/**
 * ダークモードトグルボタン
 * @param param0
 */
export const ThemeToggleListItemButton: React.FC<Props> = (props) => {

    return (
        props.isDark
            ? (
                // ダークモード
                <ListItem
                    button
                    onClick={props.onClickDarkThemeOff}>
                    <ListItemText
                        inset
                        primary={
                            <Box display="flex" alignItems="center">
                                <Brightness7Icon fontSize={"small"} /><Box marginLeft={1}>ダークモード</Box>
                            </Box>} />
                </ListItem>
            )
            : (
                // デフォルトモード
                <ListItem
                    button
                    onClick={props.onClickDarkThemeOn}>
                    <ListItemText
                        inset
                        primary={
                            <Box display="flex" alignItems="center">
                                <Brightness4Icon fontSize={"small"} /><Box marginLeft={1}>ライトモード</Box>
                            </Box>
                        } />
                </ListItem>
            )
    );
};
