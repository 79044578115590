/**
 * アプリケーションの画面タイトルを提供します。
 *
 */
export class AppTitle {

    //#region フィールド

    /** App を表します。 */
    public static readonly app = new AppTitle("施工機保全システム");

    /** [Account] サインインを表します。 */
    public static readonly accountSignIn = new AppTitle("サインイン");

    /** [Home] 施工機管理状況一覧(Home)を表します。 */
    public static readonly machineMonitoring = new AppTitle("施工機管理状況一覧");

    /** [Other] その他報告を表します。 */
    public static readonly otherReport = new AppTitle("その他報告");

    /** [FileManage] ファイル管理を表します。 */
    public static readonly fileManage = new AppTitle("ファイル管理");

    /** [Master] 施工機マスタを表します。 */
    public static readonly masterMachine = new AppTitle("施工機マスタ");
    /** [Master] 型式マスタを表します。 */
    public static readonly masterModel = new AppTitle("型式マスタ");
    /** [Master] メーカーマスタを表します。 */
    public static readonly masterManufacturer = new AppTitle("メーカーマスタ");
    /** [Master] 工法マスタを表します。 */
    public static readonly masterMethod = new AppTitle("工法マスタ");
    /** [Master] 整備区分マスタを表します。 */
    public static readonly masterMaintenanceDivisioin = new AppTitle("整備区分マスタ");
    /** [Master] 整備項目マスタを表します。 */
    public static readonly masterMaintenanceItem = new AppTitle("整備項目マスタ");
    /** [Master] 特記事項マスタを表します。 */
    public static readonly masterRemarks = new AppTitle("特記事項マスタ");
    /** [Master] 点検区分マスタを表します。 */
    public static readonly masterInspectionDivision = new AppTitle("点検区分マスタ");
    /** [Master] 点検項目マスタを表します。 */
    public static readonly masterInspectionItem = new AppTitle("点検項目マスタ");
    /** [Master] ユーザーマスタを表します。 */
    public static readonly masterUser = new AppTitle("ユーザーマスタ");

    /** [Record] 整備記録を表します。 */
    public static readonly recordMaintenanceRecord = new AppTitle("整備記録");
    /** [Record] 日常点検記録を表します。 */
    public static readonly recordDailyInspectionRecord = new AppTitle("日常点検記録");
    /** [Record] 週間月例点検記録を表します。 */
    public static readonly recordWeeklyInspectionRecord = new AppTitle("週間月例点検記録");
    /** [Record] 現場完了時点検記録を表します。 */
    public static readonly recordCompletionInspectionRecord = new AppTitle("現場完了時点検記録");
    /** [Record] 施工記録を表します。 */
    public static readonly recordConstructionRecord = new AppTitle("施工記録");
    /** [Record] 施工機ステータスを表します。 */
    public static readonly recordMachineStatus = new AppTitle("施工機ステータス");
    /** [Record] 施工機メンテナンス設定を表します。*/
    public static readonly recordMachineMaintenanceSetting = new AppTitle("施工機メンテナンス設定");
    /** [Record] アラート記録を表します。 */
    public static readonly recordAlertRecord = new AppTitle("アラート記録");

    /** タイトルを表します。  */
    public readonly title: string = "" // UE.String.empty;

    //#endregion フィールド

    //#region メソッド

    /**
     * コンストラクター
     *
     * @constructor
     * @param title パスを指定します。
     * @throws {UE.ArgumentNullOrWhiteSpaceError} `title` が `null` (未定義) または空白の場合に発生します。
     */
    private constructor(title: string) {
        if (!title) {//if(UE.Validator.isNullOrWhitespace(title)) {
            throw new Error("title") // UE.ArgumentNullOrWhiteSpaceError("title");
        }
        this.title = title;
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
