// react
import * as React from 'react';
import { useState } from 'react';
// material-ui
import MaterialTable, { MTableBodyRow, Column } from 'material-table';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import Box from '@material-ui/core/Box';
import DescriptionIcon from '@material-ui/icons/Description';
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
// component
import OverflowTooltip from '../../../../../components/OverflowTooltip';
// type
import { OtherReportListItem } from '../../_types'


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // テーブルソートヘッダー
        sortHeader: {
            // ソートされているカラム ヘッダー
            "& .MuiTableSortLabel-active": {
                color: theme.palette.text.primary,
            },
            // ソートアイコンカラー
            "& .MuiTableSortLabel-active .MuiTableSortLabel-icon": {
                color: `${theme.palette.text.primary}!important` as any,
            },
            // material-table スクロールバーをデフォルトに上書き
            "& > div > div > div > div": {
                overflowY: "visible !important",
            },
        },
        // 帳票アイコンボタン
        icon: {
            color: theme.palette.text.secondary
        }

    })
);

/** その他報告のテーブルの Props を提供します。 */
interface Props {
    /** データソースを表します。 */
    dataSource: OtherReportListItem[],
    /** モーダル表示イベントを表します。 */
    onClickShowModal: (rowData: OtherReportListItem) => void,
}

/** その他報告のテーブルの State を提供します。 */
interface State {
    /** テーブルのカラムを表します。 */
    columns: Column<OtherReportListItem>[]
}

/** その他報告テーブルのカラムを表します。 */
const columnData: Column<OtherReportListItem>[] = [
    {
        title: "報告管理 No.",
        field: "no"
    },
    {
        title: "報告日時",
        field: "reportAt"
    },
    {
        title: "報告者",
        field: "reporter"
    },
    {
        title: "所属",
        field: "area"
    },
    {
        title: "作業場所",
        field: "site"
    },
    {
        title: "報告事項",
        field: "report",
        cellStyle: {
            width: 500,
            maxWidth: 500,
        },
        render: (rowData: OtherReportListItem) => (
            <OverflowTooltip title={rowData.item} placement="left">
                <>{rowData.item}</>
            </OverflowTooltip>
        ),
    },
]

/**
 * その他報告画面テーブルを表示します。
 * @param props
 */
const OtherReportTable: React.FC<Props> = (props) => {

    // 内部 Render の MTableBodyRowで使用する為、一度取り直す
    const onClickShowModal = props.onClickShowModal;


    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** カラム情報を提供します。 */
    const [values, setValues] = useState<State>({
        columns: columnData,
    });

    /**
    * ソート変更時のイベント処理を行います。
    * @param orderBy
    * @param orderDirection
    */
    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc"): void => {

        // 再レンダーされる時にソート順がリセットされないようにState で保持する
        setValues({
            columns: values.columns.map((column, index) => {
                if (index === orderBy) {
                    column.defaultSort = orderDirection;
                } else {
                    delete column.defaultSort
                }
                return column;
            })
        })
    };

    return (
        <Box component="span" className={classes.sortHeader}>

            <MaterialTable
                icons={MuiTableIcons}
                localization={
                    {
                        ...MuiLocalizationJapanese,
                        header: {
                            actions: ""
                        },
                    }}
                options={{
                    toolbar: false,
                    search: false,
                    sorting: true,
                    tableLayout: "auto",
                    emptyRowsWhenPaging: false,
                    pageSize: 100,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    headerStyle: {
                        // 背景色
                        backgroundColor: indigo[300],
                    },
                    maxBodyHeight: "80vh", // 固定
                    minBodyHeight: "80vh", // 固定
                }}
                columns={values.columns}
                data={props.dataSource}
                actions={[
                    {
                        icon: () => <DescriptionIcon className={classes.icon} />,
                        tooltip: "",
                        onClick: (event, rowData) => Array.isArray(rowData) ? void 0 : onClickShowModal(rowData)
                    }
                ]}
                components={{
                    Row: (props) => {
                        return (
                            <MTableBodyRow
                                onDoubleClick={() => onClickShowModal(props.data)}
                                {...props}
                            />
                        )
                    }
                }}
                onOrderChange={onOrderChange}
            />

        </Box>
    )
}
export default OtherReportTable;
