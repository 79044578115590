import * as React from 'react';
import { useState } from 'react';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import clsx from "clsx";
import { AppTitle } from '../../../app-title';
import { AppUrl } from '../../../app-url';
import { IconButton, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Box } from '@material-ui/core';
import { Menu, HomeOutlined, ListAltOutlined, SettingsOutlined, AccountCircleOutlined, ExpandLess, ExpandMore, CloudUpload } from '@material-ui/icons';
import { ThemeToggleListItemButtonContainer } from '../../../../../components/ThemeToggleButton/ThemeToggleListItemButtonContainer';


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        //ドロワーメニュー
        drawerIcon: {
            [theme.breakpoints.up('md')]: {
                display: "none",
            },
        },
        // メニューアイテム
        menuItem: {
            // ホバー時
            "&:hover": {
                backgroundColor: fade(theme.palette.primary.main, 0.3),
                color: theme.palette.text.primary,

            },
            // 選択項目
            "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: fade(theme.palette.primary.main, 0.8),
                color: theme.palette.primary.contrastText,
            },
        },
        // リスト
        list: {
            width: 250,
        },
    }),
);

/** ドロワーメニューの Props を表示します。 */
interface Props {
    /** 現在のパスを表します。*/
    locatedPath: string,
    /** ホームアイコン押下時のイベント処理を表します。 */
    onHomeClick: () => void,
    /** レポートアイコン押下時のイベント処理を表します。 */
    onReportClick: () => void,
    /** アップロードアイコン押下時のイベント処理を表します。 */
    onUploadClick: () => void,
    /**　マスタ系メニューアイテム押下時のイベント処理を表します。 */
    onMasterItemClick: (path: string) => void,
    /**　メニューアイテム押下時のイベント処理を表します。 */
    onSingoutClick: () => void,
    /** ログインユーザー名を表します。 */
    userName: string,
}

/** ドロワーメニュー State */
interface State {
    /** ドロワーを表示しているかどうかを表します。 */
    showDrawer: boolean;
    /** マスタリストを展開表示しているかどうかを表します。 */
    masterCollpase: boolean,
    /** ユーザーリストを展開表示しているかどうかを表します。 */
    userCollpase: boolean,
}

/** メニューを表示します。 */
const DrawerMenu: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    //State
    const [values, setValues] = useState<State>(
        {
            showDrawer: false,
            masterCollpase: false,
            userCollpase: false,
        });

    /* ドロワーを表示します。 */
    const openDrawer = () => {
        setValues({
            ...values,
            showDrawer: true,
        });
    }

    /** ドロワーを非表示にします。 */
    const cloaseDrawer = () => {
        setValues({
            ...values,
            showDrawer: false,
        });
    }

    /** マスターメニューリストの展開表示切り替え処理を行います。 */
    const toggleMasterCallpase = () => {
        setValues({
            ...values,
            masterCollpase: !values.masterCollpase,
        });
    };

    /** ユーザーメニューリストの展開表示切り替え処理を行います。 */
    const toggleUserCallpase = () => {
        setValues({
            ...values,
            userCollpase: !values.userCollpase,
        });
    };

    /**
     * ボタンクリック時のイベントを処理します。
     * @param event
     */
    const onButtonClickBase = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // ドロワークローズ
        cloaseDrawer();
    }


    /**
    * ホームアイコン クリック時のイベント処理を表します。
    * @param event
    */
    const onHomeClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // ドロワークローズ
        cloaseDrawer();

        // 遷移
        props.onHomeClick();
    }

    /**
    * レポートアイコン クリック時のイベント処理を表します。
    * @param event
    */
    const onReportClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // ドロワークローズ
        cloaseDrawer();

        // 遷移
        props.onReportClick();
    }

    /**
     * アップロードアイコン クリック時のイベント処理を表します。
     * @param event
     */
    const onUploadClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        // 遷移
        props.onUploadClick();
    }

    /**
     * マスタ系各項目 クリック時のイベント処理を行います。
     * @param path
     */
    const onMasterItemClick = (event: React.MouseEvent<HTMLElement>, path: string) => {
        event.preventDefault();

        // ドロワークローズ
        cloaseDrawer();

        // 遷移
        props.onMasterItemClick(path);
    }

    /**
     * サインアウト クリック時のイベント処理を行います。
     * @param event
     */
    const onSingoutClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // ドロワークローズ
        cloaseDrawer();

        // 遷移
        props.onSingoutClick();
    }

    return (
        <>
            {/* Drawerボタン */}
            <div className={classes.drawerIcon} >
                <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="drawer"
                    onClick={openDrawer}
                >
                    <Menu fontSize={"large"} />
                </IconButton>
            </div>

            {/* Drawer */}
            <Drawer
                anchor="right"
                open={values.showDrawer}
                onClose={cloaseDrawer}
            >
                {/* メニューリスト */}
                <List>
                    {/* Home */}
                    <ListItem
                        button
                        className={clsx(classes.menuItem, classes.list)}
                        selected={AppUrl.machineMonitoring.path === props.locatedPath}
                        onClick={onHomeClick}
                    >
                        <ListItemIcon>
                            <HomeOutlined />
                        </ListItemIcon>
                        <ListItemText primary={AppTitle.machineMonitoring.title} />
                    </ListItem>

                    <Divider />

                    {/* Report */}
                    <ListItem
                        button
                        className={clsx(classes.menuItem, classes.list)}
                        selected={AppUrl.otherReport.path === props.locatedPath}
                        onClick={onReportClick}
                    >
                        <ListItemIcon>
                            <ListAltOutlined />
                        </ListItemIcon>
                        <ListItemText primary={AppTitle.otherReport.title} />
                    </ListItem>

                    <Divider />

                    {/* Upload */}
                    <ListItem
                        button
                        className={clsx(classes.menuItem, classes.list)}
                        selected={AppUrl.fileManage.path === props.locatedPath}
                        onClick={onUploadClick}
                    >
                        <ListItemIcon>
                            <CloudUpload />
                        </ListItemIcon>
                        <ListItemText primary={AppTitle.fileManage.title} />
                    </ListItem>

                    <Divider />

                    {/* Master */}
                    <ListItem
                        button
                        onClick={toggleMasterCallpase}
                        className={classes.list}
                    >
                        <ListItemIcon>
                            <SettingsOutlined />
                        </ListItemIcon>
                        <ListItemText primary="マスタ管理" />
                        {values.masterCollpase ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                        in={values.masterCollpase}
                        timeout="auto"
                        unmountOnExit>
                        <List
                            component="div"
                            disablePadding
                        >

                            {/* 施工機マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterMachine.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterMachine.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterMachine.title} />
                            </ListItem>
                            {/* 型式マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterModel.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterModel.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterModel.title} />
                            </ListItem>
                            {/* メーカーマスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterManufacturer.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterManufacturer.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterManufacturer.title} />
                            </ListItem>
                            {/* 工法マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterMethod.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterMethod.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterMethod.title} />
                            </ListItem>
                            {/* 整備区分マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterMaintenanceDivisioin.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterMaintenanceDivisioin.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterMaintenanceDivisioin.title} />
                            </ListItem>
                            {/* 整備項目マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterMaintenanceItem.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterMaintenanceItem.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterMaintenanceItem.title} />
                            </ListItem>
                            {/* 特記事項マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterRemarks.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterRemarks.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterRemarks.title} />
                            </ListItem>
                            {/* 点検区分マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterInspectionDivision.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterInspectionDivision.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterInspectionDivision.title} />
                            </ListItem>
                            {/* 点検項目マスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterInspectionItem.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterInspectionItem.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterInspectionItem.title} />
                            </ListItem>
                            {/* ユーザーマスタ */}
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                selected={AppUrl.masterUser.path === props.locatedPath}
                                onClick={(e) => onMasterItemClick(e, AppUrl.masterUser.path)}
                            >
                                <ListItemText inset primary={AppTitle.masterUser.title} />
                            </ListItem>

                        </List>
                    </Collapse>

                    <Divider />

                    {/* User */}
                    <ListItem
                        button
                        onClick={toggleUserCallpase}
                        className={classes.list}
                    >
                        <ListItemIcon>
                            <AccountCircleOutlined />
                        </ListItemIcon>
                        <ListItemText primary={props.userName} />
                        {values.userCollpase ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                        in={values.userCollpase}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List
                            component="div"
                            disablePadding
                        >
                            <ListItem
                                button
                                className={(classes.menuItem)}
                                key="drawer-user"
                                onClick={onSingoutClick}
                            >
                                <ListItemText
                                    inset
                                    primary="サインアウト"
                                />
                            </ListItem>

                            {/* テーマカラー */}
                            <Box className={classes.menuItem}>
                                <ThemeToggleListItemButtonContainer />
                            </Box>

                        </List>
                    </Collapse>
                    <Divider />
                </List>


            </Drawer>
        </>
    );

}
export default DrawerMenu;
