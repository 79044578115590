// react
import * as React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// type
import { MachineInputItem, MachineValidateInputItem } from '../../_types';
import { ConnectedCtuType } from '../../../../../_types';
// component
import MasterDetailButtonGroup from '../../../Shared/MasterDetailButtonGroup';
import { MachineType } from '../../../../../modules/swagger-clients';
import MuiKeybordDatePicker from '../../../../../components/MuiPickers/MuiKeybordDatePicker';
import { MuiDatePickerUtility } from '../../../../../components/MuiPickers/mui-date-picker-utility';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ラジオボタン入力
        inputRadio: {
            width: "250px",
            margin: theme.spacing(1),
        },

    }),
);

/** 施工機マスタ　検索項目の Props を提供します。 */
interface Props {
    /** 初期値を表します。 */
    initialValues: MachineInputItem,
    /** 更新ボタンのクリック処理を行います。 */
    onClickAdd: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 更新ボタンのクリック処理を行います。 */
    onClickUpdate: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 削除ボタンのクリック処理を行います。 */
    onClickRemove: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** キャンセルボタンのクリック処理を行います。 */
    onClickCancel: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 施工機種別一覧を表します。*/
    typeList: { key: number, value: string }[],
    /** 型式一覧を表します。 */
    manufacturerList: { key: number, value: string }[],
    /** 型式一覧を表します。 */
    modelList: { key: number, value: string }[],
    /** CTU 番号一覧を表します。 */
    ctuIdList: { key: number, value: string }[],
    /** CTU 番号一覧を表します。 */
    maintenanceCtuIdList: { key: number, value: string }[],
    /** 新規作成状態かどうかを表します。 */
    isCreating: boolean,
    /** 入力値の変更処理を表します。 */
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MachineInputItem) => void,
    /** 日付入力値の変更処理を表します。 */
    onChangeDate: (newValue: Date | null, name: keyof MachineInputItem) => void,
    /** バリデーション結果を表します。 */
    validateError: MachineValidateInputItem,
};

/**
 * 施工機マスタ　詳細エリアを提供します。
 * */
const MachineDetailCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    // 表示
    return (
        <>
            <Box padding={1}>
                {/* 施工機種別 */}
                <TextField
                    select
                    SelectProps={{
                        MenuProps: {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }
                    }}
                    label="施工機種別"
                    placeholder="施工機種別"
                    value={props.initialValues.type ?? ""}
                    onChange={(event) => props.onChange(event, "type")}
                    disabled={!props.isCreating}
                    required
                    error={!props.validateError.type === false}
                    helperText={props.validateError.type}
                >
                    {/*　選択肢 */}
                    {props.typeList.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    )
                    )}

                </TextField >

                {/* 社番 */}
                <TextField
                    label="社番"
                    placeholder="社番"
                    value={props.initialValues.id ?? ""}
                    onChange={(event) => props.onChange(event, "id")}
                    disabled={!props.isCreating}
                    required
                    error={!props.validateError.id === false}
                    helperText={props.validateError.id}
                />

                {/* 型式 */}
                <TextField
                    select
                    SelectProps={{
                        MenuProps: {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }
                    }}
                    label="型式"
                    placeholder="型式"
                    value={props.initialValues.model ?? ""}
                    onChange={(event) => props.onChange(event, "model")}
                    disabled={!props.isCreating}
                    required
                    error={!props.validateError.model === false}
                    helperText={props.validateError.model}
                >

                    {/*　選択肢 */}
                    {props.modelList.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    )
                    )}

                </TextField >

                {/* メーカー */}
                <TextField
                    select
                    SelectProps={{
                        MenuProps: {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }
                    }}
                    label="メーカー"
                    placeholder="メーカー"
                    value={props.initialValues.manufacturer ?? ""}
                    onChange={(event) => props.onChange(event, "manufacturer")}
                    disabled={!props.isCreating}
                    required
                    error={!props.validateError.manufacturer === false}
                    helperText={props.validateError.manufacturer}
                >

                    {/*　選択肢 */}
                    {props.manufacturerList.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    )
                    )}

                </TextField >

                {/* 年式 */}
                <MuiKeybordDatePicker
                    label="年式"
                    inputVariant="outlined"
                    views={["year", "month"]}
                    openTo="month"
                    format={MuiDatePickerUtility.yearAndMonthFormat}
                    placeholder={MuiDatePickerUtility.yearAndMonthFormat}
                    value={props.initialValues.modelYear ?? null} // 年式カレンダー：undefined -> 今の日付が挿入される為、null代入
                    onChange={(data) => props.onChangeDate(data, "modelYear")}
                    disabled={!props.isCreating}
                    required
                    error={!props.validateError.modelYear === false}
                    helperText={props.validateError.modelYear}
                />

                {/* CTUあり/なし */}
                <FormControlLabel
                    className={classes.inputRadio}
                    disabled={props.initialValues.type !== MachineType.BaseMachine} // ベースマシンのみ選択可能
                    control={
                        <Checkbox
                            // checked = ベースマシン　かつ　CTUあり
                            checked={(props.initialValues.type === MachineType.BaseMachine && props.initialValues.connectedCtu === ConnectedCtuType.Connected)}
                            onChange={(event) => props.onChange(event, "connectedCtu")}
                        />}
                    label="CTUあり (ベースマシン)"
                />

                {/* CTU番号 */}
                <TextField
                    select
                    SelectProps={{
                        MenuProps: {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }
                    }}
                    label="自動CTU番号"
                    placeholder="自動CTU番号"
                    value={props.initialValues.ctuId ?? ""}
                    onChange={(event) => props.onChange(event, "ctuId")}
                    disabled={props.initialValues.type !== MachineType.BaseMachine} // ベースマシンのみ選択可能
                >
                    {/* Default */}
                    <MenuItem key={0} value={""}> {"未設定"}</MenuItem>
                    {/*　選択肢 */}
                    {props.ctuIdList.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                            {option.value}
                        </MenuItem>
                    )
                    )}

                </TextField >

                {/* CTU番号 */}
                <TextField
                    select
                    SelectProps={{
                        MenuProps: {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }
                    }}
                    label="保全CTU番号"
                    placeholder="保全CTU番号"
                    value={props.initialValues.maintenanceCtuId ?? ""}
                    onChange={(event) => props.onChange(event, "maintenanceCtuId")}
                    disabled={props.initialValues.type !== MachineType.BaseMachine} // ベースマシンのみ選択可能
                >
                    {/* Default */}
                    <MenuItem key={0} value={""}> {"未設定"}</MenuItem>
                    {/*　選択肢 */}
                    {props.maintenanceCtuIdList.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                            {option.value}
                        </MenuItem>
                    )
                    )}

                </TextField >

                {/* ボタングループ */}
                <MasterDetailButtonGroup
                    isNonEditabled={props.initialValues.type !== MachineType.BaseMachine}
                    isCreating={props.isCreating}
                    onClickAdd={props.onClickAdd}
                    onClickCancel={props.onClickCancel}
                    onClickUpdate={props.onClickUpdate}
                    onClickRemove={props.onClickRemove}
                />

            </Box>
        </>
    );
};

export default MachineDetailCondition;
