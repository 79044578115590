// redux
import { Dispatch } from 'redux';
// shared
import { RecordsSliceState, createReportListSlice } from '../shared/create-records-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { changeError } from '../global-header/global-header-slice';
// type
import { WeeklyInspectionRecordAdapter } from '../../adapter/weekly-inspection-record/weekly-inspection-record-adapter';
import { WeeklyInspectionRecordGetRequest, WeeklyInspectionRecordGetResult, WeeklyInspectionRecordGetDocumentRequest, WeeklyInspectionRecordGetDocumentResult } from '../../adapter/weekly-inspection-record/types';
import { WeeklyInspectionRecordListItem, WeeklyInspectionReportItem } from '../../../pages/ConstructionMachineMaster/WeeklyInspectionRecord/_types';
import { WeeklyReportRequest, UpdateWeeklyReportRequest } from '../../adapter/report/type';
import { ReportAdapter } from '../../adapter/report/report-adapter';

// State の初期値を設定します。
const initialState: RecordsSliceState<WeeklyInspectionRecordListItem, WeeklyInspectionReportItem> = {
    isLoading: false,
    isShowMessage: false,
    isOpenViewer: false,
    isUpdated: false,
    isReportLoading: false,
}

/** 週間月例点検記録 Slice を 提供します。*/
export const WeeklyInspectionRecordSlice = createReportListSlice({
    // State Reducer Action を生成
    name: ' WeeklyInspectionRecord',
    initialState: initialState,
    reducers: {},
})

/**
 * 一覧取得処理を行います。
 * @param parameter 要求情報を指定します。
 */
export const onGetAsync = (parameter: WeeklyInspectionRecordGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(WeeklyInspectionRecordSlice.actions.fetchStart());

        // 通信処理
        const getResultWaiter = WeeklyInspectionRecordAdapter.instance.getAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.item.machineId });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, machineErrorResultWaiter]);
        const getResult = promiseResult[0];
        const machineErrorResult = promiseResult[1];

        // 通信終了
        if (getResult.isError) {
            // 異常
            dispatch(WeeklyInspectionRecordSlice.actions.fetchFailure());
            // メッセージ
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));

        } else if (machineErrorResult.isError) {
            // 異常
            // メッセージ
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(machineErrorResult.errorMessage ?? ""));

        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 正常
            dispatch(WeeklyInspectionRecordSlice.actions.setItems(getResult.items));
            // メッセージ
            dispatch(WeeklyInspectionRecordSlice.actions.showSuccessMessage());

        }

    } catch (error) {

        //例外
        dispatch(WeeklyInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(WeeklyInspectionRecordSlice.actions.fetchEnd());
    }
}

/**
 * 帳票取得処理を行います。
 * @param parameter パラメーターを指定します。
 */
export const onGetWeeklyReportAsync = (parameter: WeeklyReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(WeeklyInspectionRecordSlice.actions.fetchStart());
        dispatch(WeeklyInspectionRecordSlice.actions.changeReportLoadingFlag(true));

        // 通信処理
        const result = await ReportAdapter.instance.getWeeklyReport(parameter);

        // 通信終了
        if (result.isError || result.stampItems == null) {
            // 異常
            // メッセージ
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            dispatch(WeeklyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // メッセージ
            dispatch(WeeklyInspectionRecordSlice.actions.showSuccessMessage());

            // 正常
            dispatch(WeeklyInspectionRecordSlice.actions.setReport(
                {
                    reportUrl: result.reportUrl,
                    stampItems: result.stampItems,
                }
            ));
            dispatch(WeeklyInspectionRecordSlice.actions.toggleViewre(true));
        }

    } catch (error) {

        //例外
        dispatch(WeeklyInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(`${error}`));
        dispatch(WeeklyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(WeeklyInspectionRecordSlice.actions.fetchEnd());
    }
}

/**
 * 検印更新処理を行います。
 * @param updateParam 更新パラメーターを指定します。
 * @param getParam 取得パラメーターを指定します。
 */
export const onUpdateWeeklyInspectedStampAsync = (updateParam: UpdateWeeklyReportRequest, getParam: WeeklyReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(WeeklyInspectionRecordSlice.actions.fetchStart());
        dispatch(WeeklyInspectionRecordSlice.actions.changeReportLoadingFlag(true));

        // 更新フラグ OFF
        dispatch(WeeklyInspectionRecordSlice.actions.changeUpdatedFlag(false));

        // URL クリア
        dispatch(WeeklyInspectionRecordSlice.actions.clearReport());

        // 通信処理
        const result = await ReportAdapter.instance.updateWeeklyInspectedStamp(updateParam);

        // 通信終了
        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            dispatch(WeeklyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // 更新完了

            // 通信処理
            const getResult = await ReportAdapter.instance.getWeeklyReport(getParam);

            // 通信終了
            if (getResult.isError || getResult.stampItems == null) {
                // 異常
                // メッセージ
                dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));
                dispatch(WeeklyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

            } else {
                // メッセージ
                dispatch(WeeklyInspectionRecordSlice.actions.showSuccessMessage());

                // 正常
                dispatch(WeeklyInspectionRecordSlice.actions.setReport(
                    {
                        reportUrl: getResult.reportUrl,
                        stampItems: getResult.stampItems,
                    }
                ));
                dispatch(WeeklyInspectionRecordSlice.actions.changeUpdatedFlag(true));
            }
        }
    } catch (error) {

        //例外
        dispatch(WeeklyInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage(`${error}`));
        dispatch(WeeklyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(WeeklyInspectionRecordSlice.actions.fetchEnd());
    }
}
