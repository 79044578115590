import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { EditItem } from '../../_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // カードコンテンツ
        cardContent: {
            paddingTop: 0,
        },
        //　取得値コンテナ
        textContainer: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "baseline",
            justifyContent: "flex-end",
        },
        // 値
        value: {
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "end",
        },
        // 値正常
        valueNormally: {
            color: theme.palette.success.main,
        },
        // 値超過
        valueExcess: {
            color: theme.palette.error.main,
        },
        // 単位
        unit: {
            fontSize: "1.2rem",
            textAlign: "start",
            marginLeft: "0.5rem",
        },
        // 接頭辞
        prefix: {
            fontSize: "1.2rem",
            textAlign: "end",
            marginRight: "0.5rem",
        },
    }),
);

/** ステータス編集カードコンテンツの Props のインターフェイスを提供します。 */
interface Props {
    /** 編集項目を表します。 */
    item: EditItem,
};

/**
 * ステータス編集カードの コンテンツを表示します。
 * @param props
 */
const EditCardRemainsContent: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles(props);

    /**
     *  数値に変換します。
     * @param value 変換元を指定します。
     * @returns 数値を返します。変換できない場合は null を返します。
     */
    const convertToNumber = (value: string): number | null => {
        return Number.isFinite(parseInt(value)) ? parseInt(value) : null;
    }

    /**
     *  ステータス項目の値を表示します。
     * @param value 値を指定します。
     */
    const renderValue = (source: string) => {

        //数値チェック
        let value = convertToNumber(source);

        // レンダー
        if (value && value < 0) {
            // 超過表示
            return renderExcessValue(`${Math.abs(value)}`);
        } else {
            // 正常表示
            return renderNomallyValue(source);
        }
    }

    /**
     * 超過値を表示します。
     * @param value 値を指定します。
     */
    const renderExcessValue = (value: string) => {
        return (
            <Box className={clsx(classes.value, classes.valueExcess)}>
                {/* Prefix */}
                <span className={classes.prefix}>超過</span>

                {/* Value */}
                <span>{!value ? '\u00A0' : value}</span>
            </Box>
        );
    }

    /**
     *　正常値を表示します。
     * @param value 値を指定します。
     */
    const renderNomallyValue = (value: string) => {
        return (
            <Box className={clsx(classes.value, classes.valueNormally)}>
                {/* Prefix */}
                <span className={classes.prefix}>あと</span>

                {/* Value */}
                <span>{!value ? '\u00A0' : value}</span>
            </Box>
        );

    }

    return (
        <CardContent
            className={classes.cardContent}
        >
            <Grid container className={classes.textContainer}>
                {/* 値 */}
                <Grid item xs>
                    {renderValue(props.item.value)}
                </Grid>
                {/* 単位 */}
                <Grid item xs={3} className={classes.unit}>
                    {props.item.unit}
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default EditCardRemainsContent;
