// react
import * as React from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            minWidth: "300px",
            minHeight: "136px",

        },
        // ボディ
        body: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        // タイトル
        title: {
            fontSize: "1.25rem",
        },
        // テキスト
        text: {
            fontSize: "1.5rem",

        },
        // アイコン
        icon: {
            '& svg': {
                fontSize: "4rem",
                marginRight: "0.5rem",
            },
        },
        iconGreen: {
            color: theme.palette.success.main,
        },
        iconRed: {
            color: theme.palette.error.main,
        },
        iconGray: {
            color: theme.palette.text.disabled,
        },
    }),
);

/** 自動制御接続状態カードの Props を提供します。 */
interface Props {
    /** 接続状態を表します。 */
    status?: boolean,
};

/** 自動制御接続状態カードを提供します。 */
const ConnectionStatusCard: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** 自動制御接続状態を表示します。 */
    function renderStatusIcon(status: boolean): JSX.Element {

        let icon: JSX.Element | null = null;
        let text: string = "";

        if (status) {
            icon = <LinkIcon className={classes.iconGreen} />;
            text = "接続中";
        } else {
            icon = <LinkOffIcon className={classes.iconRed} />;
            text = "切断中";
        }

        return (
            <>
                <span className={classes.icon} >
                    {icon}
                </span>
                <div className={classes.text}>
                    <div>{text}</div>
                </div>
            </>
        );

    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography component="p" variant="subtitle1" className={classes.title}>
                    自動制御接続
                    </Typography>

                <div className={classes.body}>
                    {/* 状態表示 */}
                    {
                        // CTU接続状態を確認
                        props.status === void 0
                            ? (
                                //CTU未接続の場合
                                <>
                                    <span className={classes.icon} >
                                        <RemoveCircleIcon className={classes.iconGray} />

                                    </span>
                                    <div className={classes.text}>
                                        <div>CTU未接続</div>
                                    </div>
                                </>
                            )
                            : (
                                // CTU接続中の場合
                                renderStatusIcon(props.status)
                            )
                    }
                </div>
            </CardContent>
        </Card >
    );
};

export default ConnectionStatusCard;
