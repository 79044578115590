import * as React from 'react';
import { Component } from 'react';
import "@grapecity/ar-viewer-ja/dist/jsViewer.min.js";
import "@grapecity/ar-viewer-ja/dist/jsViewer.min.css";
import './style/style.css';

// `グローバル変数の使用` について、
// @types で型宣言ファイルを用意したが、Micorsoft の構文エラーが消えない。(ESLintはエラー無しで実行は出来る状態)
// その為、モジュール内でグローバル変数を拡張し、エラー回避
// 参考URL
// https://create-react-app.dev/docs/using-global-variables/
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html#global-augmentation
// https://dev.classmethod.jp/articles/typings-of-window-object/

/**
 * グローバル変数
 */
declare global {
    /**
     * Window のインターフェイスを提供します。
     * 既存の Window を拡張します。。
     * */
    interface Window {
        /** GrapeCity を表します。
         * e.g. window.GrapeCity.ActiveReports.JSViewer.create("#id")
         */
        GrapeCity: any; // 型定義なし
    }
}


/**
 *　ActiveReportViewer の Props を提供します。
 */
interface ActiveReportViewerProps {
    /** レポートのURLを表します。  */
    reportUrl: string,
    /** レポート読み込み終了時の処理を行います。 */
    loadedReport?: (() => void),
}

/**
 * ActiveReport の JS Viewer コンポーネントを提供します。
 */
export class ActiveReportViewer extends Component<ActiveReportViewerProps> {

    // `Javascript で JSViewer の操作`
    // https://docs.grapecity.com/help/activereports-14/using-js-viewer.html#
    //
    /** ビューワを表します。 */
    private viewer: any;

    /** ビューワのID属性を表します。 */
    private readonly viewerId = "viewer";


    /**  カスタムボタン活性状態のツールバーレイアウトを表します。 */
    private readonly exportEnabledLayout = [
        '$navigation',      // ページ移動
        '$split',
        '$mousemode',       // 移動
        '$zoom',            // ズーム
        '$fullscreen',      // フルスクリーン
        '$split',
        '$singlepagemode',  // 単一ページ
        '$continuousmode',  // 連続ページ
        '$split',
        '$print',
        '$exportEnabled',   // カスタムボタン　エクスポート
    ];

    /**  カスタムボタン非活性状態のツールバーレイアウトを表します。 */
    private readonly exportDisabledLayout = [
        '$navigation',      // ページ移動
        '$split',
        '$mousemode',       // 移動
        '$zoom',            // ズーム
        '$fullscreen',      // フルスクリーン
        '$split',
        '$singlepagemode',  // 単一ページ
        '$continuousmode',  // 連続ページ
        '$split',
        '$print',
        '$exportDisabled',  // カスタムボタン　エクスポート
    ];


    /**
     * コンストラクタ
     * @param props Propsを指定します。
     */
    constructor(props: ActiveReportViewerProps) {
        super(props);

    }

    /**
     * コンポーネントのマウント後の処理を行います。
     */
    componentDidMount() {
        try {
            // Do something that could throw

            // ビューワ初期化
            this.viewer = window.GrapeCity.ActiveReports.JSViewer.create({
                element: `#${this.viewerId}`,
                reportID: this.props.reportUrl,
                documentLoaded: (reportInfo: any) => {
                    if (this.props.loadedReport != null) {
                        // 親コンポーネントのロード後処理実施
                        this.props.loadedReport();
                    }
                    // 活性ボタンを表示
                    this.viewer.toolbar.desktop.layout(this.exportEnabledLayout);
                    this.viewer.toolbar.fullscreen.layout(this.exportEnabledLayout);
                    this.viewer.toolbar.mobile.layout(this.exportEnabledLayout);
                },
                reportLoaded: () => {
                },
                error: (error: any) => {
                    const viewer = this.viewer
                    console.log("viewer_error", { error }, { viewer });
                    if (this.props.loadedReport != null) {
                        // 親コンポーネントのロード後処理実施
                        this.props.loadedReport();
                    }
                }
            });

            // サイドバー非表示
            this.viewer.sidebar.toggle(false);

            // 非活性ボタン
            const exportDisabledButton = {
                key: '$exportDisabled',
                title: "ダウンロード",
                iconCssClass: 'custom-print-disabled',
                enabled: false,
            }
            // 活性ボタン
            const viewer = this.viewer
            const reportName = this.props.reportUrl.match(/([^/]*)\./)
            const exportEnabledButton = {
                key: '$exportEnabled',
                iconCssClass: "custom-print",
                title: "ダウンロード",
                action: function (item: any) {
                    if (reportName != null && reportName.length > 0) {
                        viewer.export('Pdf', null, true, { FileName: reportName[1] + ".pdf" }) // Reports/XXXXX.rdlx -> XXXXX.pdf
                    }
                }
            }

            this.viewer.toolbar.desktop.removeItem('$exportDisabled')
            this.viewer.toolbar.fullscreen.removeItem('$exportDisabled')
            this.viewer.toolbar.mobile.removeItem('$exportDisabled')
            this.viewer.toolbar.desktop.addItem(exportDisabledButton);
            this.viewer.toolbar.fullscreen.addItem(exportDisabledButton);
            this.viewer.toolbar.mobile.addItem(exportDisabledButton);

            this.viewer.toolbar.desktop.removeItem('$exportEnabled')
            this.viewer.toolbar.fullscreen.removeItem('$exportEnabled')
            this.viewer.toolbar.mobile.removeItem('$exportEnabled')
            this.viewer.toolbar.desktop.addItem(exportEnabledButton)
            this.viewer.toolbar.fullscreen.addItem(exportEnabledButton)
            this.viewer.toolbar.mobile.addItem(exportEnabledButton)

            // 非活性ボタンを表示
            this.viewer.toolbar.desktop.layout(this.exportDisabledLayout);
            this.viewer.toolbar.fullscreen.layout(this.exportDisabledLayout);
            this.viewer.toolbar.mobile.layout(this.exportDisabledLayout);

            // ビューモードを「連続ページ」に設定　single = 0 or continuous = 1  `ActiveReportsJS` 参考まで https://demo.grapecity.com/activereportsjs/api/classes/reportviewer.viewer.html#toolbar
            this.viewer._viewer.viewMode = 1;

        } catch (error) {
            console.log("componentDidMount_catch", { error });
            // throw new Error(error.message ?? "ActiveReportViewer componentDidMount Error");
            if (this.props.loadedReport != null) {
                // 親コンポーネントのロード後処理実施
                this.props.loadedReport();
            }
        }
    }

    /**
     * DOM 更新後の処理を行います。
     * @param prevProps 更新前の Props を表します。
     * @param prevState 更新前の State を表します。
     */
    componentDidUpdate(prevProps: ActiveReportViewerProps) {

        try {
            if (!!prevProps.reportUrl && this.props.reportUrl !== prevProps.reportUrl) {
                // カスタムボタン
                const viewer = this.viewer
                const reportName = this.props.reportUrl.match(/([^/]*)\./)
                const coustomButtonExport = {
                    key: '$exportEnabled',
                    iconCssClass: "custom-print",
                    title: "ダウンロード",
                    action: function (item: any) {
                        if (reportName != null && reportName.length > 0) {
                            viewer.export('Pdf', null, true, { FileName: reportName[1] + ".pdf" }) // Reports/XXXXX.rdlx -> XXXXX.pdf
                        }
                    }
                }
                this.viewer.toolbar.desktop.removeItem('$exportEnabled')
                this.viewer.toolbar.fullscreen.removeItem('$exportEnabled')
                this.viewer.toolbar.mobile.removeItem('$exportEnabled')
                this.viewer.toolbar.desktop.addItem(coustomButtonExport)
                this.viewer.toolbar.fullscreen.addItem(coustomButtonExport)
                this.viewer.toolbar.mobile.addItem(coustomButtonExport)

                // 非活性ボタンを表示
                this.viewer.toolbar.desktop.layout(this.exportDisabledLayout);
                this.viewer.toolbar.fullscreen.layout(this.exportDisabledLayout);
                this.viewer.toolbar.mobile.layout(this.exportDisabledLayout);

                // レポート更新
                this.viewer.openReport(this.props.reportUrl);

            } else {

            }

        } catch (error) {
            console.log("componentDidUpdate_catch", { error });
            if (this.props.loadedReport != null) {
                // 親コンポーネントのロード後処理実施
                this.props.loadedReport();
            }
        }

    }

    /**
     * コンポーネントのアンマウント前の処理を行います。
     * */
    componentWillUnmount() {
        // 処理なし
    }

    /**
     * レンダリング処理を行います。
     */
    render() {
        return (
            <div id={this.viewerId} />
        );
    }
}

// #region Functional component

// error : 指定されたHTMLが見つかりません
// ビューワ生成時 の element id が指定出来ない。
// Class Component でコーディング。

//import * as React from 'react';
//import { useEffect } from 'react';
//import "@grapecity/ar-viewer-ja/dist/jsViewer.min.js";
//import "@grapecity/ar-viewer-ja/dist/jsViewer.min.css";
//import './style.css';

//declare global {
//    /** Window のインターフェイスを提供します。 */
//    interface Window {
//        /** GrapeCity を表します。
//         * e.g. window.GrapeCity.ActiveReports.JSViewer.create("#id")
//         */
//        GrapeCity: any;
//    }
//}

///**
// *　JS Viewer コンポーネント の Props を提供します。
// */
//interface ActiveReportViewerProps {
//    /** レポートのURLを表します。  */
//    reportUrl: string,
//}


///**
// * ActiveReport の JS Viewer コンポーネントを提供します。
// */
//export function ActiveReportViewer(props: ActiveReportViewerProps) {

//    /** ビューワのID属性を表します。 */
//    const viewerId = "viewer";

//    useEffect(() => {

//        const viewer = window.GrapeCity.ActiveReports.JSViewer.create({
//            element: "#viewerContainer",
//        });
//        //レポート更新
//        viewer.openReport(props.reportUrl);

//    }, [props.reportUrl])

//    return (
//        <div id="viewer" />
//    );
//}
// #endregion Functional component
