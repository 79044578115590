import * as React from 'react';
import { UploadDialog } from './UploadDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { FileManageSlice, onPutFilesAsync } from '../../../../../modules/slice/file-manage/file-manage-slice';
import { FileInfo, S3FileListItem } from '../../_types';
import path from 'path';
import { FileManageUtility } from '../../../../../modules/adapter/file-manage/file-manage-utility';

/**
 * アップロードダイアログを表示します。
 * @param props
 */
export function UploadDialogContainer(): JSX.Element {

    const dispatch = useDispatch();

    const isOpen = useSelector((state: RootState) => state.fileManage.item.isOpenUploadDialog ?? false);
    const directoryName = useSelector((state: RootState) => FileManageUtility.toDirectoryKey(state.fileManage.item.directoryItem));
    const fileList: string[] = useSelector((state: RootState) =>
        state.fileManage.result.getFileListResult?.items
            .filter(file => file.type === "pdf")
            .map((file) => file.name.toLowerCase()) // 小文字比較で、新規・更新の判定を行う。
        ?? []);
    
    /**
     * アップロードボタンクリックイベントを処理します。
     * @param event
     * @param files
     */
    const onClickUpload = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, uploadFiles: File[]) => {
        // 非同期通信
        await dispatch(onPutFilesAsync({
            files: [...uploadFiles.map(f => ({ key: path.join(directoryName, f.name), file:f } as FileInfo))]
        }));
    };

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        dispatch(FileManageSlice.actions.toggleUploadDialog(false));
    };

    return (<>
        {isOpen && <UploadDialog
            isOpen={isOpen}
            handleClose={closeDialog}
            onClickUpload={onClickUpload}
            fileList={fileList}
        />}
    </>);
}
