// react
import * as React from 'react';
// mateial-ui
import Grid from '@material-ui/core/Grid';
// icons
import { MdAccessTime } from 'react-icons/md'
import { MdImportExport } from 'react-icons/md'
import { FaCogs } from 'react-icons/fa'
import { BsBatteryCharging } from 'react-icons/bs'
import { FaTachometerAlt } from 'react-icons/fa'
import { FaThermometerHalf } from 'react-icons/fa'
// component
import GraphSelectCard from './GraphSelectCard';
import { GraphType } from '../../_types';
import { GraphContentUtility } from '../GraphContent/GraphContetntUtility';

/** 施工記録画面 カードリストの Props を提供します。*/
interface Props {
    /** グラフ描画する項目を表します。*/
    activeCardList: GraphType[],
    /** カードボタンクリック時の処理を行います。*/
    onClickCard: (item: GraphType) => void,
}

/**
 * 施工記録画面 カードリストを提供します。
 * */
export const ConstructionRecordCardList: React.FC<Props> = (props) => {

    /**
     * 文字列を指定した位置で分割します
     * @param str　文字列を指定します。
     * @param length　分割する位置をしていします。
     * @returns 文字配列を返します。
     */
    const sliceByLength = (str: string, length: number): string[] => {
        let result: string[] = [];

        // 入力値が不正
        if (!str || !length || length < 1) return result;

        // 分割
        result.push(str.slice(0, length));
        result.push(str.slice(length));

        return result;
    }

    return (
        <div>
            <Grid container spacing={2}>
                {/* Hrメーター */}
                <Grid item>
                    <GraphSelectCard
                        title={[(GraphContentUtility.constantString.engineOperatingTime.name)]}
                        isActive={props.activeCardList.includes(GraphType.engineOperatingTime)}
                        isLoading={false}
                        icon={<MdAccessTime />}
                        titleColor={GraphContentUtility.constantString.engineOperatingTime.contrastColor}
                        backgroundColor={GraphContentUtility.constantString.engineOperatingTime.color}
                        onClick={() => props.onClickCard(GraphType.engineOperatingTime)}

                    />

                </Grid >

                {/* 昇降距離 */}
                <Grid item>
                    <GraphSelectCard
                        title={[(GraphContentUtility.constantString.liftingDistance.name)]}
                        isActive={props.activeCardList.includes(GraphType.liftingDistance)}
                        isLoading={false}
                        icon={<MdImportExport />}
                        titleColor={GraphContentUtility.constantString.liftingDistance.contrastColor}
                        backgroundColor={GraphContentUtility.constantString.liftingDistance.color}
                        onClick={() => props.onClickCard(GraphType.liftingDistance)}
                    />
                </Grid >

                {/* 平均エンジン回転数 */}
                <Grid item>
                    <GraphSelectCard
                        title={sliceByLength(GraphContentUtility.constantString.engineRotationTimeAvg.name, 2)}
                        isActive={props.activeCardList.includes(GraphType.engineRotationTimeAvg)}
                        isLoading={false}
                        icon={<FaCogs />}
                        titleColor={GraphContentUtility.constantString.engineRotationTimeAvg.contrastColor}
                        backgroundColor={GraphContentUtility.constantString.engineRotationTimeAvg.color}
                        onClick={() => props.onClickCard(GraphType.engineRotationTimeAvg)}
                    />
                </Grid >

                {/* 平均バッテリー電圧離 */}
                <Grid item>
                    <GraphSelectCard
                        title={sliceByLength(GraphContentUtility.constantString.batteryVoltageAvg.name, 2)}
                        isActive={props.activeCardList.includes(GraphType.batteryVoltageAvg)}
                        isLoading={false}
                        icon={<BsBatteryCharging />}
                        titleColor={GraphContentUtility.constantString.batteryVoltageAvg.contrastColor}
                        backgroundColor={GraphContentUtility.constantString.batteryVoltageAvg.color}
                        onClick={() => props.onClickCard(GraphType.batteryVoltageAvg)}
                    />
                </Grid >

                {/* 最大昇降圧力 */}
                <Grid item>
                    <GraphSelectCard
                        title={[GraphContentUtility.constantString.liftingPressureMax.name]}
                        isActive={props.activeCardList.includes(GraphType.liftingPressureMax)}
                        isLoading={false}
                        icon={<FaTachometerAlt />}
                        titleColor={GraphContentUtility.constantString.liftingPressureMax.contrastColor}
                        backgroundColor={GraphContentUtility.constantString.liftingPressureMax.color}
                        onClick={() => props.onClickCard(GraphType.liftingPressureMax)}
                    />
                </Grid >

                {/* 平均作動油温度 */}
                <Grid item>
                    <GraphSelectCard
                        title={[GraphContentUtility.constantString.oilTemperatureAvg.name]}
                        isActive={props.activeCardList.includes(GraphType.oilTemperatureAvg)}
                        isLoading={false}
                        icon={<FaThermometerHalf />}
                        titleColor={GraphContentUtility.constantString.oilTemperatureAvg.contrastColor}
                        backgroundColor={GraphContentUtility.constantString.oilTemperatureAvg.color}
                        onClick={() => props.onClickCard(GraphType.oilTemperatureAvg)}
                    />
                </Grid >
            </Grid >
        </div>

    );
};
