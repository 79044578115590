//react
import * as React from 'react';
import { useState } from 'react';
// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
// router
import { Route, useHistory, useRouteMatch, useParams } from 'react-router-dom';

import { MachineType, ConnectedCtuType } from '../../../_types';
import { TabList } from './component/TabList/TabList';
import { AppUrl } from '../../Shared/app-url';
import { HeaderMachineState } from '../../Shared/GlobalHeader/_types';

/** Params を提供します。 */
interface Params {
    /** タブを表します。 */
    tab: string,
}

/**
 * 施工機管理画面 を提供します。
 * */
export function ConstructionMachineMasterTab() {

    /** Hisotry を表します。*/
    const history = useHistory<HeaderMachineState>();

    /** ルート情報を表します。 */
    const match = useRouteMatch<Params>(`${AppUrl.record.path}/:tab`);

    /** URLパラメータのタブを表します。 */
    const { tab } = useParams<Params>();

    /** 施工機種別 を表します。*/
    const machine = useSelector((state: RootState) => state.globalHeader.machine);

    /**
     * タブ選択時の処理を行います。
     * @param event
     * @param selectedTab
     */
    const handleChange = (event: React.ChangeEvent<{}>, selectedTab: string) => {
        match && history.push({
            pathname: `${AppUrl.record.path}/${selectedTab}`,
            state: machine,
        });
    };

    /**
     *　施工機モニター画面へ戻る処理を行います。
     * */
    const onBackLink = () => {
        history.push(AppUrl.machineMonitoring.path);
    };

    return (
        <>
            {machine &&
                <TabList
                    isBaseMachine={machine?.type === MachineType.BaseMachine}
                    isLock={machine?.isLock === true}
                    onBackLinkClick={onBackLink}
                    onTabClick={handleChange}
                    selectedTab={tab}
                />}
        </>

    );

};
