import { WebApiAdapter } from '../../web-api-adapter';
import { ReportClient, GetDailyReportInput, GetMaintenanceReportInput, GetWeeklyReportInput, GetCompletionReportInput, UpdateDailyInspectedStampInput, GetMaintenancePreviewReportInput, UpdateMaintenanceInspectedStampInput, UpdateWeeklyInspectedStampInput, UpdateCompletionInspectedStampInput } from '../../swagger-clients';
import { DailyReportRequest, DailyReportResult, MaintenanceReportRequest, MaintenanceReportResult, WeeklyReportRequest, WeeklyReportResult, CompletionReportRequest, CompletionReportResult, UpdateDailyReportRequest, UpdateWeeklyReportRequest, UpdateCompletionReportRequest, UpdateMaintenanceReportRequest, UpdateReportResult, } from './type';
import { ReportResult } from './type';
import { ReportUtility } from './report-utility';
import { ErrorResult } from '../_type';

/**
 * `帳票` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class ReportAdapter extends WebApiAdapter<ReportClient> {
    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new ReportAdapter();

    //#endregion フィールド


    //#region メソッド

    //#region エラー情報作成

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createErrorResult(message?: string): ErrorResult {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createGetErrorResult(message?: string): ReportResult {
        return {
            reportUrl: "",
            ...this.createErrorResult(message)
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createUpdateErrorResult(message?: string): UpdateReportResult {
        return {
            ...this.createErrorResult(message)
        };
    }

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createMaintenanceReportErrorResult(message?: string): MaintenanceReportResult {
        return {
            ...this.createGetErrorResult(message)
        };
    }

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createDailyReportErrorResult(message?: string): DailyReportResult {
        return {
            ...this.createGetErrorResult(message)
        };
    }

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createWeeklyReportErrorResult(message?: string): WeeklyReportResult {
        return {
            ...this.createGetErrorResult(message)
        };
    }

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createCompletionReportErrorResult(message?: string): WeeklyReportResult {
        return {
            ...this.createGetErrorResult(message)
        };
    }

    //#endregion エラー情報作成

    //#region 帳票データ取得


    /**
     * 整備点検票のデータを取得します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getMaintenanceReport(parameter: MaintenanceReportRequest): Promise<MaintenanceReportResult> {

        // 実行
        const input = new GetMaintenanceReportInput();
        input.init(ReportUtility.toGetMaintenanceReportInput(parameter));

        const output = await this.client.getMaintenanceReport(input);
        if (output == null) {
            return this.createMaintenanceReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createMaintenanceReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: MaintenanceReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
            stampItems: ReportUtility.toMaintenaceReportInspectedStampItem(output.stampItem),
        };
        return result;
    }


    /**
     * 日常点検票のデータを取得します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getDailyReport(parameter: DailyReportRequest): Promise<DailyReportResult> {

        // 実行
        const input = new GetDailyReportInput();
        input.init(ReportUtility.toGetDailyReportInput(parameter));

        const output = await this.client.getDailyReport(input);
        if (output == null) {
            return this.createDailyReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createDailyReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: DailyReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
            stampItems: ReportUtility.toDailyReportInspectedStampItem(output.stampItems),
        };
        return result;
    }

    /**
     * 週間月例点検票のデータを取得します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getWeeklyReport(parameter: WeeklyReportRequest): Promise<WeeklyReportResult> {

        // 実行
        const input = new GetWeeklyReportInput();
        input.init(ReportUtility.toGetWeeklyReportInput(parameter));


        const output = await this.client.getWeeklyReport(input);
        if (output == null) {
            return this.createWeeklyReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createWeeklyReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: WeeklyReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
            stampItems: ReportUtility.toWeeklyReportInspectedStampItem(output.stampItems),
        };
        return result;
    }

    /**
     * 現場完了時点検票のデータを取得します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getCompletionReport(parameter: CompletionReportRequest): Promise<CompletionReportResult> {

        // 実行
        const input = new GetCompletionReportInput();
        input.init(ReportUtility.toGetCompletionReportInput(parameter));

        const output = await this.client.getCompletionReport(input);
        if (output == null) {
            return this.createCompletionReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createCompletionReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: CompletionReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
            stampItem: output.stampItem != null ? ReportUtility.toInspectedStampItem(output.stampItem) : void 0,
        };
        return result;
    }


    /**
     * 整備記録のプレビューを取得します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getMaintenancePreviewReport(parameter: MaintenanceReportRequest): Promise<ReportResult> {

        // 実行
        const input = new GetMaintenancePreviewReportInput();
        input.init(ReportUtility.toGetMaintenancePreviewReportInput(parameter));

        const output = await this.client.getMaintenancePreviewReport(input);
        if (output == null) {
            return this.createMaintenanceReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createMaintenanceReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: ReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
        };
        return result;
    }

    /**
      * 日常点検記録のプレビューを取得します。
      * @param parameter パラメーターを指定します。
      * @returns 結果を戻す非同期操作を返します。
      */
    public async getDailyPreviewReport(): Promise<ReportResult> {

        // 実行
        const output = await this.client.getDailyPreviewReport();
        if (output == null) {
            return this.createDailyReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createDailyReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: ReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
        };
        return result;
    }

    /**
     * 週間月例点検記録のプレビューを取得します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getWeeklyPreviewReport(): Promise<ReportResult> {

        // 実行
        const output = await this.client.getWeeklyPreviewReport();
        if (output == null) {
            return this.createWeeklyReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createWeeklyReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: ReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
        };
        return result;
    }

    /**
     * 現場完了時点検記録のプレビューを取得します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getCompletionPreviewReport(): Promise<ReportResult> {

        // 実行
        const output = await this.client.getCompletionPreviewReport();
        if (output == null) {
            return this.createCompletionReportErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createCompletionReportErrorResult(output.errorMessage);
        }

        // 正常
        const result: ReportResult = {
            isError: false,
            reportUrl: output.reportUrl ?? "",
        };
        return result;
    }

    //#endregion 帳票データ取得


    //#region 帳票データ更新

    /**
     * 整備記録票の検印情報を更新します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async updateMaintenanceInspectedStamp(parameter: UpdateMaintenanceReportRequest): Promise<UpdateReportResult> {

        // 実行
        const input = new UpdateMaintenanceInspectedStampInput();
        input.init(ReportUtility.toUpdateMaintenanceInspectedStampInput(parameter));

        const output = await this.client.updateMaintenanceInspectedStamp(input);
        if (output == null) {
            return this.createUpdateErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createUpdateErrorResult(output.errorMessage);
        }

        // 正常
        const result: UpdateReportResult = {
            isError: false,
        };
        return result;
    }

    /**
     * 日常点検票の検印情報を更新します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async updateDailyInspectedStamp(parameter: UpdateDailyReportRequest): Promise<UpdateReportResult> {

        // 実行
        const input = new UpdateDailyInspectedStampInput();
        input.init(ReportUtility.toUpdateDailyInspectedStampInput(parameter));

        const output = await this.client.updateDailyInspectedStamp(input);
        if (output == null) {
            return this.createUpdateErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createUpdateErrorResult(output.errorMessage);
        }

        // 正常
        const result: UpdateReportResult = {
            isError: false,
        };
        return result;
    }

    /**
     * 週間月例点検票の検印情報を更新します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async updateWeeklyInspectedStamp(parameter: UpdateWeeklyReportRequest): Promise<UpdateReportResult> {

        // 実行
        const input = new UpdateWeeklyInspectedStampInput();
        input.init(ReportUtility.toUpdateWeeklyInspectedStampInput(parameter));

        const output = await this.client.updateWeeklyInspectedStamp(input);
        if (output == null) {
            return this.createUpdateErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createUpdateErrorResult(output.errorMessage);
        }

        // 正常
        const result: UpdateReportResult = {
            isError: false,
        };
        return result;
    }

    /**
     * 現場完了時点検票の検印情報を更新します。
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async updateCompletionInspectedStamp(parameter: UpdateCompletionReportRequest): Promise<UpdateReportResult> {

        // 実行
        const input = new UpdateCompletionInspectedStampInput();
        input.init(ReportUtility.toUpdateCompletionInspectedStampInput(parameter));

        const output = await this.client.updateCompletionInspectedStamp(input);
        if (output == null) {
            return this.createUpdateErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createUpdateErrorResult(output.errorMessage);
        }

        // 正常
        const result: UpdateReportResult = {
            isError: false,
        };
        return result;
    }

    //#endregion 帳票データ更新

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new ReportClient());
        // 変更不可
        Object.seal(this);
    }
    //#endregion メソッド
}
