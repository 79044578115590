// redux
import { Dispatch } from 'redux';
// shared
import { RecordsSliceState, createReportListSlice } from '../shared/create-records-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
// type
import { MaintenanceRecordAdapter } from '../../adapter/maintenance-record/maintenance-record-adapter';
import { MaintenanceRecordGetRequest, MaintenanceRecordGetResult, MaintenanceRecordGetDocumentRequest, MaintenanceRecordGetDocumentResult } from '../../adapter/maintenance-record/types';
import { changeError } from '../global-header/global-header-slice';
import { MaintenanceRecordListItem, MaintenanceReportItem } from '../../../pages/ConstructionMachineMaster/MaintenanceRecord/_types';
import { ReportAdapter } from '../../adapter/report/report-adapter';
import { MaintenanceReportRequest, UpdateMaintenanceReportRequest } from '../../adapter/report/type';

// State の初期値を設定します。
const initialState: RecordsSliceState<MaintenanceRecordListItem, MaintenanceReportItem> = {
    isLoading: false,
    isShowMessage: false,
    isOpenViewer: false,
    isUpdated: false,
    isReportLoading: false,
}

/** 整備記録 Slice を 提供します。*/
export const MaintenanceRecordSlice = createReportListSlice({
    // State Reducer Action を生成
    name: 'MaintenanceRecord',
    initialState: initialState,
    reducers: {},
})

/**
 * 一覧取得処理を行います。
 * @param parameter 要求情報を指定します。
 */
export const onGetAsync = (parameter: MaintenanceRecordGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MaintenanceRecordSlice.actions.fetchStart());

        // 通信処理
        const getResultWaiter = MaintenanceRecordAdapter.instance.getAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.item.machineId });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, machineErrorResultWaiter]);
        const getResult = promiseResult[0];
        const machineErrorResult = promiseResult[1];

        // 通信終了
        if (getResult.isError) {
            // 異常
            dispatch(MaintenanceRecordSlice.actions.fetchFailure());
            // メッセージ
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));

        } else if (machineErrorResult.isError) {
            // 異常
            // メッセージ
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage(machineErrorResult.errorMessage ?? ""));

        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 正常
            dispatch(MaintenanceRecordSlice.actions.setItems(getResult.items));
            // メッセージ
            dispatch(MaintenanceRecordSlice.actions.showSuccessMessage());

        }

    } catch (error) {

        //例外
        dispatch(MaintenanceRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(MaintenanceRecordSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MaintenanceRecordSlice.actions.fetchEnd());
    }
}

/**
 * 帳票取得処理を行います。
 * @param parameter パラメーターを指定します。
 */
export const onGetMaintenanceReportAsync = (parameter: MaintenanceReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MaintenanceRecordSlice.actions.fetchStart());
        dispatch(MaintenanceRecordSlice.actions.changeReportLoadingFlag(true));

        // 通信処理
        const result = await ReportAdapter.instance.getMaintenanceReport(parameter);

        // 通信終了
        if (result.isError || result.stampItems == null) {
            // 異常
            // メッセージ
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            dispatch(MaintenanceRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // メッセージ
            dispatch(MaintenanceRecordSlice.actions.showSuccessMessage());

            // 正常
            dispatch(MaintenanceRecordSlice.actions.setReport(
                {
                    reportUrl: result.reportUrl,
                    stampItems: result.stampItems,
                }
            ));

            dispatch(MaintenanceRecordSlice.actions.toggleViewre(true));
        }

    } catch (error) {

        //例外
        dispatch(MaintenanceRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(MaintenanceRecordSlice.actions.showErrorMessage(`${error}`));
        dispatch(MaintenanceRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(MaintenanceRecordSlice.actions.fetchEnd());
    }
}

/**
 * 検印更新処理を行います。
 * @param updateParam 更新パラメーターを指定します。
 * @param getParam 取得パラメーターを指定します。
 */
export const onUpdateMaintenanceInspectedStampAsync = (updateParam: UpdateMaintenanceReportRequest, getParam: MaintenanceReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MaintenanceRecordSlice.actions.fetchStart());
        dispatch(MaintenanceRecordSlice.actions.changeReportLoadingFlag(true));

        // 更新フラグ OFF
        dispatch(MaintenanceRecordSlice.actions.changeUpdatedFlag(false));

        // URL クリア
        dispatch(MaintenanceRecordSlice.actions.clearReport());

        // 通信処理
        const result = await ReportAdapter.instance.updateMaintenanceInspectedStamp(updateParam);

        // 通信終了
        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            dispatch(MaintenanceRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // 更新完了

            // 通信処理
            const getResult = await ReportAdapter.instance.getMaintenanceReport(getParam);

            // 通信終了
            if (getResult.isError || getResult.stampItems == null) {
                // 異常
                // メッセージ
                dispatch(MaintenanceRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));
                dispatch(MaintenanceRecordSlice.actions.changeReportLoadingFlag(false));

            } else {
                // メッセージ
                dispatch(MaintenanceRecordSlice.actions.showSuccessMessage());

                // 正常
                dispatch(MaintenanceRecordSlice.actions.setReport(
                    {
                        reportUrl: getResult.reportUrl,
                        stampItems: getResult.stampItems,
                    }
                ));

                dispatch(MaintenanceRecordSlice.actions.changeUpdatedFlag(true));
            }
        }

    } catch (error) {

        //例外
        dispatch(MaintenanceRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(MaintenanceRecordSlice.actions.showErrorMessage(`${error}`));

        dispatch(MaintenanceRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(MaintenanceRecordSlice.actions.fetchEnd());
    }
}
