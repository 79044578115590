import * as React from 'react';
import { Box, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { MachineOperationHistoryGraphItem } from '../../_types';
import { CSVLink } from 'react-csv';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 入力項目
        csvLink: {
            "&:hover": {
                textDecoration: "none",

            }
        },
    }),
);

/** ダウンロードボタンの Props を提供します。 */
interface Props {
    /** グラフデータを表します。 */
    graphDataSource: MachineOperationHistoryGraphItem[],
    /** ボタンが有効かどうかを表します。 */
    isDisabled: boolean,
}

/** CSV ダウンロードボタンを表示します */
const DownloadButton: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** CSV ファイルの ヘッダーを表します。 */
    const headers: { label: string, key: keyof MachineOperationHistoryGraphItem }[] = [
        { label: "日付", key: "dateAt" },
        { label: "エンジン稼働時間[Hr]", key: "engineOperatingTime" },
        { label: "クレーン昇降距離[m]", key: "liftingDistance" },
        { label: "平均エンジン回転数[rpm]", key: "engineRotationTimeAvg" },
        { label: "平均バッテリー電圧[V]", key: "batteryVoltageAvg" },
        { label: "最大昇降圧力[Mpa]", key: "liftingPressureMax" },
        { label: "平均作動油温度[℃]", key: "oilTemperatureAvg" },
        { label: "CTUエラー", key: "errors" },
    ];

    /** CSV ファイルのファイル名を表します。 */
    const fileName = "施工記録.csv";

    return (

        <Box display="flex" justifyContent="flex-end" m={1} >
            {props.isDisabled ? (
                // Disabled ボタン
                <Button
                    disabled
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                >
                    CSVダウンロード
                </Button>
            ) : (
                    //CSV ダウンロード
                    <CSVLink
                        className={classes.csvLink}
                        filename={fileName}
                        aria-disabled={props.isDisabled}
                        headers={headers}
                        data={props.graphDataSource}
                        target="_blank"
                    >
                        <Button
                            disabled={props.isDisabled}
                            variant="contained"
                            color="primary"
                            startIcon={<GetAppIcon />}
                        >
                            CSVダウンロード
                        </Button>
                    </CSVLink>
                )
            }

        </Box >
    );
}

export default DownloadButton
