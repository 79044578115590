import { Options } from 'material-table';
import { MuiTableOptions } from '../../../components/MuiTable';
import indigo from '@material-ui/core/colors/indigo';

/** マスタ系の material-table のオプションを提供します。 */
export const MasterTableOptions: Options<any> = {
    ...MuiTableOptions,
    headerStyle: {
        // ヘッダー 背景色
        backgroundColor: indigo[300],
    },
    // 最大高さ
    maxBodyHeight: "calc(100vh - 536px)", // 固定
    // 最小高さ
    minBodyHeight: "400px", // 固定
}
