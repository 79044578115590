// redux
import { Dispatch } from 'redux';
// shared
import { GenericState, createGenericSlice } from '../shared/create-generic-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { changeError } from '../global-header/global-header-slice';
// type
import { MachineErrorHistoryAdapter } from '../../adapter/machine-error-history/machine-error-history-adapter';
import { MachineErrorHistoryResult, MachineErrorHistoryRequest } from '../../adapter/machine-error-history/types';

// State の初期値を設定します。
const initialState: GenericState<MachineErrorHistoryResult> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        listItems: [],
        graphItems: [],
        isError: false,
    }
}

/** アラート履歴 Slice を 提供します。*/
export const MachineErrorHistorySlice = createGenericSlice({
    // State Reducer Action を生成
    name: 'MachineErrorHistory',
    initialState: initialState,
    reducers: {},
})

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetAsync = (parameter: MachineErrorHistoryRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineErrorHistorySlice.actions.fetchStart());

        // 通信処理
        const getResultWaiter = MachineErrorHistoryAdapter.instance.getAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.item.machineId });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, machineErrorResultWaiter]);
        const getResult = promiseResult[0];
        const machineErrorResult = promiseResult[1];

        // 通信終了
        dispatch(MachineErrorHistorySlice.actions.fetchSuccess(getResult));


        if (getResult.isError) {
            // 異常
            // メッセージ
            dispatch(MachineErrorHistorySlice.actions.showErrorMessage(getResult.errorMessage ?? ""));

        } else if (machineErrorResult.isError) {
            // 異常
            // メッセージ
            dispatch(MachineErrorHistorySlice.actions.showErrorMessage(machineErrorResult.errorMessage ?? ""));

        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 正常
            // メッセージ
            dispatch(MachineErrorHistorySlice.actions.showSuccessMessage());

        }

    } catch (error) {

        // メッセージ
        dispatch(MachineErrorHistorySlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MachineErrorHistorySlice.actions.fetchEnd());
    }
}
