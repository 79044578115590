// react
import * as React from 'react';
import { useState } from 'react';
// material-table
import MaterialTable from 'material-table';
import { Column } from 'material-table';
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
// mateiral-ui
import { createStyles, makeStyles, Theme, useTheme, fade } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import indigo from '@material-ui/core/colors/indigo';
// type
import { MachineType, MachineItem, Machine, AreaType } from '../../../../../_types'
import { MachineMonitoringListItem } from '../../_types';
// component
import NoteIconButton from './NoteIconButton';
import OverflowTooltip from '../../../../../components/OverflowTooltip';
import { HeaderMachineState } from '../../../../Shared/GlobalHeader/_types';
import ConnectedTabletTag from './ConnectedTabletTag';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // テーブルソートヘッダー
        sortHeader: {
            // ソートされているカラム ヘッダー
            "& .MuiTableSortLabel-active": {
                color: theme.palette.text.primary,
            },
            // ソートアイコンカラー
            "& .MuiTableSortLabel-active .MuiTableSortLabel-icon": {
                color: `${theme.palette.text.primary}!important` as any,
            },
            // material-table スクロールバーをデフォルトに上書き
            "& > div > div > div > div": {
                overflowY: "visible !important",
            },
        }

    })
);

/** 施工機モニター テーブル の Props のインターフェイスを提供します。*/
interface Props {
    /** データソースを表します。 */
    dataSource: MachineMonitoringListItem[],
    /** テーブル行クリックイベントを表します。 */
    onRowClick: (event: React.MouseEvent<Element, MouseEvent>, rowData: MachineMonitoringListItem) => void,
    /** ノートアイコンのクリックイベントを表します。 */
    onNoteClick: (event: React.MouseEvent<Element, MouseEvent>, machine: HeaderMachineState) => void,
}

/** 施工機モニター テーブル の State のインターフェイスを提供します。 */
interface State {
    /** 施工機モニタテーブルのカラムを表します。 */
    columns: Column<MachineMonitoringListItem>[]
}

/**
 * 施工機モニター テーブルを提供します。
 * */
const ConstructionMachineTable: React.FC<Props> = (props) => {
    /** 施工機モニタテーブルのカラムを表します。 */
    const columnData: Column<MachineMonitoringListItem>[] = [
        {
            title: "オンライン",
            field: "isConnectedTablet",
            width: 100,
            render: (rowData: MachineMonitoringListItem) => (
                rowData.isConnectedTablet && <ConnectedTabletTag />
            ),
        },
        {
            title: "ベースマシン",
            field: "baseMachine",
            cellStyle: {
                width: 200,
                minWidth: 200,
                padding: "0px 5px",
            },
            customSort: (a: MachineMonitoringListItem, b: MachineMonitoringListItem) => (a.baseMachine.id > b.baseMachine.id ? 1 : -1),
            render: (rowData: MachineMonitoringListItem) => (
                <NoteIconButton
                    item={rowData.baseMachine}
                    machineType={MachineType.BaseMachine}
                    isLock={rowData.isLock}
                    isError={!rowData.ctuError === false}
                    onNoteClick={props.onNoteClick}

                />
            ),
        },
        {
            title: "オーガー",
            field: "auger",
            cellStyle: {
                width: 200,
                minWidth: 200,
                padding: "0px 5px",

            },
            customSort: (a: MachineMonitoringListItem, b: MachineMonitoringListItem) => (a.auger.id > b.auger.id ? 1 : -1),
            render: (rowData: MachineMonitoringListItem) => (
                <NoteIconButton
                    item={rowData.auger}
                    machineType={MachineType.Auger}
                    isLock={rowData.isLock}
                    isError={!rowData.ctuError === false}
                    onNoteClick={props.onNoteClick}
                />
            ),
        },
        {

            title: "昇降装置",
            field: "lift",
            cellStyle: {
                width: 200,
                minWidth: 200,
                padding: "0px 5px",
            },
            customSort: (a: MachineMonitoringListItem, b: MachineMonitoringListItem) => (a.lift.id > b.lift.id ? 1 : -1),
            render: (rowData: MachineMonitoringListItem) => (
                <NoteIconButton
                    item={rowData.lift}
                    machineType={MachineType.Lift}
                    isLock={rowData.isLock}
                    isError={!rowData.ctuError === false}
                    onNoteClick={props.onNoteClick}
                />
            ),
        },
        {
            title: "作業場所",
            field: "site",
        },
        {
            title: "CTUエラー",
            field: "ctuError",
            cellStyle: {
                width: 200,
                maxWidth: 200,
            },
            headerStyle: {
                width: 200,
                maxWidth: 200,
            },
            render: (rowData: MachineMonitoringListItem) => (
                <OverflowTooltip title={rowData.ctuError}>
                    <>{rowData.ctuError}</>
                </OverflowTooltip>
            ),
        },

        {
            title: "報告事項",
            field: "reportItem",
            cellStyle: {
                width: 200,
                maxWidth: 200,
            },
            headerStyle: {
                width: 200,
                maxWidth: 200,
            },
            render: (rowData: MachineMonitoringListItem) => (
                <OverflowTooltip title={rowData.reportItem}>
                    <>{rowData.reportItem}</>
                </OverflowTooltip>
            ),
        },

    ];

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    const theme = useTheme();

    const [values, setValues] = useState<State>({
        columns: columnData,
    });

    /**
     * ソート変更時のイベント処理を行います。
     * @param orderBy
     * @param orderDirection
     */
    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc"): void => {

        // 再レンダーされる時にソート順がリセットされないようにState で保持する
        setValues({
            columns: values.columns.map((column, index) => {
                if (index === orderBy) {
                    column.defaultSort = orderDirection;
                } else {
                    delete column.defaultSort
                }
                return column;
            })
        })
    };

    /**
     * 行クリックイベントをハンドルします。
     * @param event
     * @param rowData
     */
    const hundleRowClick = (event?: React.MouseEvent<Element, MouseEvent>, rowData?: MachineMonitoringListItem) => {

        if (event && rowData) {
            // 行クリックイベント実行
            props.onRowClick(event, rowData);
        }
    }

    /**
     * 行毎のスタイルをレンダリングします。
     * @param rowData
     */
    const renderRowStyle = (rowData: MachineMonitoringListItem): React.CSSProperties => {
        let result: React.CSSProperties = {};
        if (rowData.ctuError) {
            // エラー情報があるものは背景を赤くする
            result = {
                color: theme.palette.error.contrastText,
                backgroundColor: fade(theme.palette.error.main, 1),
            }
        } else {
            // スタイル指定なし
        }

        return result;

    }

    return (
        <Box component="span" className={classes.sortHeader}>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={{
                    filtering: false,
                    toolbar: false,
                    search: false,
                    sorting: true,
                    //tableLayout: "auto",
                    emptyRowsWhenPaging: false,
                    pageSize: 100, // デフォルト 100 だが、データが空の場合の最低行確保
                    pageSizeOptions: [10, 25, 50, 100],
                    headerStyle: {
                        // 背景色
                        backgroundColor: indigo[300],
                    },
                    maxBodyHeight: "80vh", // 固定
                    minBodyHeight: "80vh", // 固定
                    rowStyle: (rowData: MachineMonitoringListItem) => renderRowStyle(rowData),
                }}
                columns={values.columns}
                data={props.dataSource}
                onRowClick={(event, rowData) => hundleRowClick(event, rowData)}
                onOrderChange={onOrderChange}
            />
        </Box>
    );

}

export default ConstructionMachineTable;
