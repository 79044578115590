import { ThemeOptions } from '@material-ui/core/styles';

/** 共通のテーマを提供します。 */
export const commonTheme: ThemeOptions = {
    // デフォルト設定
    props: {
        // 入力フィールド
        MuiTextField: {
            color: "secondary",
            variant: "outlined",
            margin: "dense",
        },
        // ボタン
        MuiButton: {
            variant: "contained",
        },
    },

    typography: {
        // ボタン
        button: {
            // 大文字表示しない
            textTransform: "none"
        }
    },
    // オーバーライド
    overrides: {
        //　テーブル　ヘッダー
        MuiTableHead: {
            root: {
                whiteSpace: "nowrap",
            }
        },
        //　テーブル　行
        MuiTableRow: {
            root: {
                whiteSpace: "nowrap",
            }
        },
        // テーブル セル
        MuiTableCell: {
            root: {
                // 行間隔
                padding: "8px",
            },
            head: {

                fontWeight: "bold",
            }
        },

        //　カード
        MuiCardContent: {
            root: {
                //　最終要素に自動付与される
                "&:last-child": {
                    paddingBottom: "16px"
                }
            }
        },
        // ボタン
        MuiButton: {
            root: {
                minWidth: "200px",
            }
        },
        // フォームラベル *
        MuiFormLabel: {
            asterisk: {
                color: "#f44336",
                "&$error": {
                    color: "#f44336"
                },
            }
        },
    }
};
