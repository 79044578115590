// S3
import { GetObjectCommandOutput, ListObjectsOutput, _Error, DeletedObject } from "@aws-sdk/client-s3";
import { FileListType, S3FileListItem, DeletedFileItem, DirectoryItem, HomeDirectoryType } from "../../../pages/FileManager/FileManage/_types";
import path from 'path';
import { SharedUtility } from "../../shared/shared-utility";
import { S3DeleteResult } from "./types";

/**
 * `ファイル管理` 関連の ユーティリティ機能を提供します。
 */
export class FileManageUtility {

    /** ルートディレクトリ名を表します。 */
    public static readonly rootDirectory: string = "bms";

    /** 操作許可するファイル拡張子を表します。*/
    // 小文字指定
    public static readonly allowExts: string[] = ["pdf"];

    /** オンラインヘルプのディレクトリ名一覧を表します。*/
    // 以下の並び順でソートする。リストに含まれないフォルダは表示しない。
    public static onlineHelpList: ReadonlyArray<string> = [
            "メインメニュー",
            "日常点検",
            "週間・月例点検",
            "完了時点検",
            "整備(ベースマシン)",
            "整備(オーガー)",
            "整備(昇降装置)",
            "その他報告",
            "施工機モニタ―",
            "USB出力",
            "バージョン情報",
            "アプリケーション設定",
            "管理者設定",
        ];

    /**
     * 末尾のスラッシュを削除します。
     * @param url URLを指定します。
     */
    public static removeTrailingSlash(url: string) {
        return url.endsWith("/") ? url.substr(0, url.length - 1) : url
    }

    /**
     * 末尾にスラッシュを付与します。
     * @param url　URLを指定します。
     */
    public static addTrailingSlash(url: string) {
        return url.endsWith("/") ? url : url + "/";
    }

    /**
     * ディレクトリのKey情報に変換します。
     * @param item ディレクトリ情報を指定します。
     */
    public static toDirectoryKey(directoryItem: DirectoryItem): string {
        return path.join(directoryItem.root, directoryItem.home, ...directoryItem.sub);
    } 

    /**
     * ディレクトリ情報に変換します。
     * @param url URLを指定します。
     */
    public static toDirItem(url: string): DirectoryItem | null {
        const directoryNames = url.replace(/\/$/, "").split("/");

        // 先頭 = ルートディレクトリの取得
        const root = directoryNames.shift(); 
        if (root !== this.rootDirectory) return null; 

        const ret = Object.entries(HomeDirectoryType).find(([key, value]) => value.localeCompare(directoryNames[0], undefined, { sensitivity: "accent" }) === 0);
        if (ret == null) return null;

        directoryNames.shift();

        return {
            root,
            home: ret[1],
            sub: directoryNames,
        };
    }

    /**
     * ファイル拡張子を取得します。
     * @param filename ファイル名を指定します。
     * @returns 拡張子を返します。
     */
    public static getExt(filename: string) {
        var pos = filename.lastIndexOf(".");
        if (pos === -1) return "";
            return filename.slice(pos + 1);
    }

    /**
     * 許可された拡張子かどうかを判定します。
     * @param filename ファイル名を指定します。
     * @param allowExts　許可する拡張子の一覧を指定します。
     */
    public static checkExt(filename: string, allowExts: string[]) {
        var ext = FileManageUtility.getExt(filename).toLowerCase();
        return !(allowExts.indexOf(ext) === -1);
    }

    /**
     * byte 単位のファイルサイズの表記を変換します。
     * @param size　サイズを指定します。
     * @param stdByte 基準バイトサイズを指定します。
     * @param decimal 有効小数点を指定します。
     * @returns 変換結果を返します。変換に失敗した場合、 null　を返します
     */
    public static ag2fileSizeOpt(fileSize: string, isStdByte: boolean = false, decimal: number = 1): string | null {
        let result: string;

        let thisSize = Number(fileSize);
        if (!Number.isInteger(thisSize)) return null;
        if (!Number.isInteger(decimal) || decimal < 0) return null;

        //基準のバイト数と単位の配列を設定
        const stdByte = isStdByte ? 1024 : 1000;
        const fileUnit = isStdByte
            ? ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
            : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let unitIndex = 0;
        if (thisSize >= stdByte) {
            let sizeTemp = (thisSize / stdByte);
            for (let i = 0; sizeTemp >= 1 && i < fileUnit.length; i++) {
                unitIndex = i;
                thisSize = sizeTemp;
                sizeTemp /= stdByte;
            }
            result = (Math.round(thisSize * (10 ** decimal)) / (10 ** decimal)) + ' ' + fileUnit[unitIndex];
        } else {
            result = thisSize + ' ' + (thisSize === 1 ? 'byte' : 'bytes');
        }

        //変換した表記の文字列を返す
        return result;
    }

    /**
     * 読み込み可能なストリームを取得します。
     * @param response
     */
    public static asStream(response: GetObjectCommandOutput): ReadableStream<any> {
        return response.Body as ReadableStream;
    };

    /**
     * Blob を取得します。
     * @param response
     */
    public static async asBlob(response: GetObjectCommandOutput): Promise<Blob> {
        return await new Response(FileManageUtility.asStream(response)).blob();
    };

    /**
     * Blob オブジェクトをダウンロードします。
     * @param blob
     * @param filename
     */
    public static downloadBlobObject(blob: Blob, filename: string) {
        const objectUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = objectUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(objectUrl);
    }

    /**
     * ファイル　タイプを取得します。
     * @param fileName　ファイル名を指定します。
     */
    public static getFileType(fileName: string): FileListType {
        // 拡張子の取得
        const extension = fileName.split('.').pop();
        let result: FileListType = FileListType.Other;

        if (extension != null) {
            result = Object.values(FileListType).find((value) => {
                return extension.localeCompare(value, undefined, { sensitivity: "accent" }) === 0
            }) ?? FileListType.Other;
        };
        return result;
    }

     /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: ListObjectsOutput): S3FileListItem[] {
        let result: S3FileListItem[] = [];
        if (items?.Contents == null) {
            return result;
        };

        // フォルダ
        if (items.CommonPrefixes != null) {
            for (const source of items.CommonPrefixes) {
                // 異常値はスキップ
                if (source.Prefix == null) {
                    continue;
                };
                const item: S3FileListItem = {
                    key: source.Prefix,
                    name: items.Prefix ? source.Prefix.replace(items.Prefix, "") : "",
                    type: "フォルダ",
                    lastModified: "--",
                    size: "--",
                };
                result.push(item);
            };
        };
        // ソート
        if (items.Prefix && FileManageUtility.isOnelineHelpRoot(items.Prefix)) {
            const temp = FileManageUtility.SortingListFiles(result);
            result.length = 0;
            result = temp.concat();

        } else {
            // ファイル(オンラインヘルプのルートフォルダ以外)
            for (const source of items.Contents) {
                // 異常値, フォルダ, 未許可拡張子はスキップ
                if (source.Key == null
                    || source.Key === items.Prefix
                    || !FileManageUtility.checkExt(source.Key, FileManageUtility.allowExts)) {
                    continue;
                };

                const item: S3FileListItem = {
                    key: source.Key,
                    name: items.Prefix ? source.Key.replace(items.Prefix, "") : "",
                    type: FileManageUtility.getFileType(source.Key),
                    lastModified: source.LastModified ? SharedUtility.toDateTimeString(source.LastModified) : "",
                    size: FileManageUtility.ag2fileSizeOpt(source.Size?.toString() ?? "") ?? "",
                };
                result.push(item);
            };
            // 名前降順ソート
            result.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1);
        }

        return result;
    }

    /**
     * 正常ファイル情報に変換します。
     * @param directoryName ディレクトリ名を指定します。
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toSuccessFileItem(directoryName: string, source?: DeletedObject[]): DeletedFileItem[] {
        const result: DeletedFileItem[] = [];
        if (source == null) return result;
        source.forEach(item => {
            if (item.Key) {
                result.push({
                    key: item.Key.replace(directoryName, ""),
                    directoryName,
                });
            };
        });
        return result;
    }

    /**
     * エラーファイル情報に変換します。
     * @param directoryName ディレクトリ名を指定します。
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toErrorFileItem(directoryName: string, source?: _Error[]): DeletedFileItem[] {
        const result: DeletedFileItem[] = [];
        if (source == null) return result;
        source.forEach(item => {
            if (item.Key) {
                result.push({
                    key: item.Key.replace(directoryName, ""),
                    directoryName,
                    code: item.Code ?? "",
                    message: item.Message ?? "",
                    versionId: item.VersionId ?? ""
                });
            };
        });
        return result;
    }

    /**
     * 結果情報を結合します。
     * @param target 結合先情報を指定します。
     * @param source　結合する情報を指定します。
     */
    public static margeResult(target: S3DeleteResult, source: S3DeleteResult): S3DeleteResult {
        return {
            isError: target.isError || source.isError,
            errorMessage: source.errorMessage,
            successItems: FileManageUtility.margeResultItems(target.successItems, source.successItems),
            errorItems: FileManageUtility.margeResultItems(target.errorItems, source.errorItems),
        };
    }

    /**
     * 結果情報を結合します。
     * @param target 結合先情報を指定します。
     * @param source　結合する情報を指定します。
     */
    private static margeResultItems(target: DeletedFileItem[], source: DeletedFileItem[]): DeletedFileItem[] {
        return Array.from(new Map([...target, ...source].map((item) => [item.key, item])).values())
            .sort((a, b) => { return a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1 });
    }

    /**
     * オンラインヘルプルートフォルダかどうか判定します。
     * @param prefix ディレクトリ名を指定します。
     * @returns オンラインヘルプルートフォルダの場合は、true を、それ以外の場合は false を返します。
     */
    private static isOnelineHelpRoot(prefix: string): boolean {
        return (FileManageUtility.removeTrailingSlash(prefix) === path.join(FileManageUtility.rootDirectory, HomeDirectoryType.OnlineHelp))
    }

    /**
     * オンラインヘルプフォルダをソートします。
     * @param source ソート元の情報を指定します。
     * @returns ソート結果を返します。
     */
    private static SortingListFiles(source: S3FileListItem[]): S3FileListItem[] {
    const result = FileManageUtility.onlineHelpList
        .map((value) => source.find(item => FileManageUtility.removeTrailingSlash(item.name) === value))
        .filter((item): item is Exclude<typeof item, undefined> => Boolean(item));
    return result;
}
}
