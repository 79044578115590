// redux
import { Dispatch } from 'redux';
// shared
import { MasterGenericState, createMasterGenericSlice } from '../shared/create-master-slice';
// type
import { RemarksAdapter } from '../../adapter/remarks/remarks-adapter';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { RemarksRequest, RemarksResult, RemarksSelectBoxItemState } from '../../adapter/remarks/types';
import { RemarksListItem } from '../../../pages/Master/Remarks/_types';

// State の初期値を設定します。
const initialState: MasterGenericState<RemarksResult, RemarksListItem, RemarksSelectBoxItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    isCreating: false,
    isSelected: false,
    selectedRowItem: void 0,
    selectBoxItem: {
        MachineType: [],
    },
    isDialogOpen: false,

}

/** 特記事項マスタの Slice を 作成します。*/
export const RemarksMasterSlice = createMasterGenericSlice({
    // State Reducer Action を生成
    name: 'Remarks',
    initialState: initialState,
    reducers: {},
})

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onExcuteAsync = (promise: Promise<RemarksResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(RemarksMasterSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(RemarksMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(RemarksMasterSlice.actions.showErrorMessage(result.errorMessage));
        } else {
            // 正常
            dispatch(RemarksMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(RemarksMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(RemarksMasterSlice.actions.chageCreating(false));
        }

    } catch (error) {

        //例外
        dispatch(RemarksMasterSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(RemarksMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(RemarksMasterSlice.actions.fetchEnd());
    }
}

/**
 * 画面表示時の初回非同期通信処理を行います。
 * @param action
 */
export const onExcuteMountedAsync = () => async (dispatch: Dispatch) => {

    try {
        // state リセット
        dispatch(RemarksMasterSlice.actions.resetState());


        // 処理中 ON
        dispatch(RemarksMasterSlice.actions.fetchStart());

        // 施工機種別
        const machineTypeResult = await SharedAdapter.instance.getMachineTypeAsync();

        // 異常確認
        if (machineTypeResult.isError) {
            // 施工機種別 異常メッセージ
            dispatch(RemarksMasterSlice.actions.showErrorMessage(`施工機種別取得エラー：${machineTypeResult.errorMessage}`));

        } else {
            // 正常
            // リストの項目を store に保存
            dispatch(RemarksMasterSlice.actions.getSelectBoxItem({
                MachineType: machineTypeResult.items,
            }));

            // データ取得
            const result = await RemarksAdapter.instance.getAsync(
                { item: {} },
                { MachineType: machineTypeResult.items }
            );

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(RemarksMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(RemarksMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(RemarksMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(RemarksMasterSlice.actions.showSuccessMessage());
            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(RemarksMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(RemarksMasterSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 * @param password
 */
export const onConfirmRemoveAsync = (
    parameter: RemarksRequest,
    selectBoxItem: RemarksSelectBoxItemState,
    password: string,
) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(RemarksMasterSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(RemarksMasterSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));

        } else {
            // 正常
            // 削除実行
            const result = await RemarksAdapter.instance.removeAsync(parameter, selectBoxItem);

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(RemarksMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(RemarksMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(RemarksMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(RemarksMasterSlice.actions.showSuccessMessage("削除しました。"));

                // ダイアログを閉じる
                dispatch(RemarksMasterSlice.actions.toggleDialog(false));

                // 選択項目のクリア
                dispatch(RemarksMasterSlice.actions.clearSelectedItem());
            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(RemarksMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(RemarksMasterSlice.actions.fetchEnd());
    }

}
