// react
import * as React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';

import ConfirmDialog from '../../../Shared/components/ConfirmDialog/ConfirmDialog';
import { MaintenanceDivisionAdapter } from '../../../../../modules/adapter/maintenance-division/maintenance-division-adapter';
import { MaintenanceDivisionSlice, onExcuteAsync, onConfirmRemoveAsync } from '../../../../../modules/slice/maintenance-division/maintenance-division-slice';
import { MaintenanceDivisionRequest } from '../../../../../modules/adapter/maintenance-division/types';


/**
 * 削除確認ダイアログを表示します。
 * @param props
 */
export const ConfirmDialogContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isOpen = useSelector((state: RootState) => state.maintenanceDivisionMaster.isDialogOpen ?? false);
    const selectedItem = useSelector((state: RootState) => state.maintenanceDivisionMaster.selectedRowItem ?? null);
    const selectBoxItem = useSelector((state: RootState) => state.maintenanceDivisionMaster.selectBoxItem);

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, pwassword: string) => {

        // 入力項目を作成
        const parameter: MaintenanceDivisionRequest = {
            item: {
                id: selectedItem?.id,
                machineTypeId: selectedItem?.machineTypeId,
                lockVersion: selectedItem?.lockVersion,
            }
        }

        // 非同期通信
        await dispatch(onConfirmRemoveAsync(parameter, selectBoxItem, pwassword))
    }

    /**
　　 * ダイアログを非表示にします。
　　 * @param isOpne
　　 */
    const closeDialog = () => {
        dispatch(MaintenanceDivisionSlice.actions.toggleDialog(false));
    };

    return (
        <>
            {selectedItem &&
                <ConfirmDialog
                    isOpen={isOpen}
                    onRemoveClick={onClickRemove}
                    handleClose={closeDialog}
                />
            }
        </>
    );
}
