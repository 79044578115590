import { MaintenanceRecordListItem, MaintenanceRecordSearchType, StampSearchType } from '../../../pages/ConstructionMachineMaster/MaintenanceRecord/_types';
import { SharedUtility } from '../../shared/shared-utility';
import { MaintenanceRecordItem, MaintenanceRecordSearchType as DtoSearchType, MachineType as DtoMachineType } from '../../swagger-clients';

/**
 * `整備記録` 関連の ユーティリティ機能を提供します。
 */
export class MaintenanceRecordUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: MaintenanceRecordItem[]): MaintenanceRecordListItem[] {
        const result: MaintenanceRecordListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            const dateAt = source.maintenanceAt == null ? "" : SharedUtility.toDateString(new Date(source.maintenanceAt));
            const item: MaintenanceRecordListItem = {
                no: source.reportNo ?? "",
                completionDate: dateAt,
                constructionMethod: source.method ?? "",
                worker: source.worker ?? "",
                site: source.site ?? "",
                report: source.report ?? "",
                area: SharedUtility.toAreaText(source.areaId),
                areaType: SharedUtility.toAreaType(source.areaId),
                isStamped: source.isInspected,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 検索キーワード種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toSearchType(source: MaintenanceRecordSearchType): DtoSearchType {
        const result: DtoSearchType | undefined =
            source === MaintenanceRecordSearchType.None ? DtoSearchType.None :
            source === MaintenanceRecordSearchType.Place ? DtoSearchType.Site :
            source === MaintenanceRecordSearchType.Worker ? DtoSearchType.Worker :
            source === MaintenanceRecordSearchType.Report ? DtoSearchType.Report :
            void 0;
        if (result == null) {
            throw new Error("toSearchType");
        }
        return result;
    }

    /**
     *  検印の検索種別を変換します。
     * @param source　変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toisInspected(source: StampSearchType): boolean | undefined {
        const result: boolean | undefined =
            source === StampSearchType.Done ? true :
            source === StampSearchType.NotYet ? false :
            void 0;
        return result;
    }

    //#endregion メソッド
}
