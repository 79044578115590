import * as React from 'react';
import { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, TextField, MenuItem, Divider, FormGroup, FormControlLabel, FormLabel, Checkbox, FormHelperText, Box } from '@material-ui/core';
import { KeyValueItem, DayOfWeek } from '../../../../../_types';
import { InspectedStampItem, UpdateInspectedStampListItem } from '../../../_types';
import MuiBackToListButton from '../../../../../components/Buttons/MuiBackToListButton';
import MuiUpdateButton from '../../../../../components/Buttons/MuiUpdateButton';
import InspectionConfirmDialog from '../../../Shared/components/ComfirmDialog/InspectionStampConfirmDialog';
import { ConstructionMacineMasterUtility } from '../../../Shared/ConstructionMacineMasterUtility';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // ボタン
        button: {
            marginTop: theme.spacing(2),
        },
        // 区切り線
        divider: {
            marginBottom: theme.spacing(4),
        },
        // 検印者
        inspector: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.25rem',
        },
        // 検印者名
        name: {
            margin: 'auto',
        }
    }),
);

/** 日常点検の検印エリアの Props を表示します。 */
interface Props {
    /** 検印者名を表します。 */
    inspector: string;
    /** 検印された日付を表します。 */
    stampDatas: KeyValueItem<DayOfWeek, InspectedStampItem>[],
    /** 更新ボタンのクリック処理を表します。 */
    onUpdateClick: (inputItems: UpdateInspectedStampListItem<DayOfWeek>[]) => void,
    /** 戻るボタンのクリック処理を表します。 */
    onBackClick: () => void
    /** 帳票が更新されたかどうかを表します。 */
    isUpdated: boolean,
}

/**
 * State の初期化を行います。
 * @param source 検印情報を指定します。
 * @returns 配列を返します。
 */
const initState = (source: KeyValueItem<DayOfWeek, InspectedStampItem>[]): DayOfWeek[] => {
    return source.reduce((result: DayOfWeek[], item) => {
        if (item.value.exist && item.value.isStamped) {
            result.push(item.key);
        }
        return result;
    }, []);
}

/** 検印選択エリアを表示します。*/
const InspectionStampForm: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** ダイアログの開閉状態を表します。 */
    const [open, setOpen] = React.useState(false);

    /** 検印選択情報を表します。 */
    const [checkedValues, setCheckedValues] = React.useState<DayOfWeek[]>(initState(props.stampDatas));

    useEffect(() => {
        if (props.isUpdated) {
            setOpen(false);
        }
    }, [props.isUpdated])

    useEffect(() => {
        setCheckedValues(initState(props.stampDatas));
    }, [props.stampDatas])

    /**
     * チェックボックスの選択処理を行います。
     * @param checkedId
     */
    const handleSelect = (checkedId: DayOfWeek) => {
        const newIds = checkedValues?.includes(checkedId)
            ? checkedValues?.filter(val => val !== checkedId)
            : [...(checkedValues ?? []), checkedId];

        setCheckedValues(newIds);
    }

    /** ダイアログを開きます。 */
    const handleClickOpen = () => {
        setOpen(true);
    };

    /** ダイアログを閉じます。 */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * 更新処理を行います。
     */
    const onUpdateExecute = () => {
        props.onUpdateClick(ConstructionMacineMasterUtility.convertDailyListItem(props.stampDatas, checkedValues, props.inspector));
    }

    return (
        <>
            <FormControl fullWidth>

                {/* 戻るボタン */}
                <MuiBackToListButton
                    fullWidth
                    className={classes.inputItem}
                    onClick={props.onBackClick}
                />

                <Divider variant="middle" className={classes.divider} />

                {/* 検印者 */}
                <Box
                    fontWeight="fontWeightBold"
                >
                    検印者
                </Box>
                <div className={clsx(classes.inputItem, classes.inspector)}>
                    <AccountBoxIcon fontSize={"large"} color={"secondary"} />
                    <span className={clsx(classes.inputItem, classes.name)}>
                        {props.inspector}
                    </span>
                </div>

                {/* 検印選択 */}
                <FormControl component="fieldset">
                    <Box fontWeight="fontWeightBold">
                        検印選択
                    </Box>
                    <FormGroup>
                        {props.stampDatas.map((data, index) =>
                            <FormControlLabel
                                label={ConstructionMacineMasterUtility.toDailyInspectionRadioLableString(data)}
                                key={data.key}
                                control={
                                    <Checkbox
                                        disabled={!data.value.exist}
                                        checked={checkedValues.includes(data.key)}
                                        onChange={(e) => handleSelect(data.key)}
                                    />
                                }
                            />
                        )}
                    </FormGroup>
                </FormControl>

                {/* 更新ボタン */}
                <MuiUpdateButton
                    fullWidth
                    className={classes.button}
                    onClick={handleClickOpen}
                />

            </FormControl>


            {/* 確認ダイアログ */}
            <InspectionConfirmDialog
                isOpen={open}
                inspectionName={props.inspector}
                dataSource={ConstructionMacineMasterUtility.convertDailyListItem(props.stampDatas, checkedValues, props.inspector)}
                onUpdateClick={onUpdateExecute}
                handleClose={handleClose}
            />

        </>
    );
}

export default InspectionStampForm;
