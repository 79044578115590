import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import jaLocale from 'date-fns/locale/ja';
import { ExtendedDateFnsUtils } from './components/MuiPickers/date-fns-utility';

import { ConstructionMachineMonitorPage, ReportPage, ConstructionMachineMasterPage, MasterPage, AccountPage, UploadPage } from './pages';
import { ThemeFrame } from './pages/Shared/ThemeFrame/ThemeFrame';
import { AuthorizeRouter } from './pages/Shared/AuthorizeRouter/AuthorizeRouter';
import './custom.css';
import { AppUrl } from './pages/Shared/app-url';


/** App画面を表します。*/
const App = () => {

    return (
        <ThemeFrame>
            <MuiPickersUtilsProvider utils={ExtendedDateFnsUtils} locale={jaLocale}>
                <Switch>
                    <Redirect exact from={AppUrl.root.path} to={AppUrl.accountSignIn.path} />

                    <Route exact path={AppUrl.accountSignIn.path} component={AccountPage} />

                    <AuthorizeRouter>
                        <Route path={AppUrl.machineMonitoring.path} component={ConstructionMachineMonitorPage} />
                        <Route path={`${AppUrl.record.path}/:tab`} component={ConstructionMachineMasterPage} />
                        <Route path={`${AppUrl.master.path}/:tab`} component={MasterPage} />
                        <Route path={AppUrl.otherReport.path} component={ReportPage} />
                        <Route path={AppUrl.fileManage.path} component={UploadPage} />
                    </AuthorizeRouter>

                </Switch>
            </MuiPickersUtilsProvider>
        </ThemeFrame>
    );

}

export default App;
