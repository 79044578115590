// react
import { useState } from 'react';
import * as React from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    TextField,
    FormControlLabel,
    Box,
    RadioGroup,
    Radio,
    FormControl,
} from '@material-ui/core';
// component
import { OtherReportInputItem, OtherReportSearchType, OtherReportImageSearchType } from '../../_types'
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import { MuiDatePickerUtility } from '../../../../../components/MuiPickers/mui-date-picker-utility';
import MuiKeybordDatePicker from '../../../../../components/MuiPickers/MuiKeybordDatePicker';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ラジオボタン
        radioGroup: {
            display: "inline-flex",
        },
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // ボタン
        button: {
            marginTop: theme.spacing(2),
        },
        // 日付の間の '～'
        toFrom: {
            display: "flex",
            writingMode: "vertical-lr",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        // グループ化 枠
        groupContainer: {
            position: "relative",
            padding: theme.spacing(2),
            marginTop: theme.spacing(1),
            borderStyle: "solid",
            borderRadius: 4,
            borderColor: theme.palette.text.disabled,
            borderWidth: 1,

        },
        // グループ化　タイトル
        groupTitle: {
            position: "absolute",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            top: "-12px",
            left: "-8px",
            fontWeight: "bold",
            backgroundColor: theme.palette.background.paper,
        }

    }),
);

/** その他報告画面　検索エリアのPropsを表示します。 */
interface Props {
    /** 検索ボタンのクリック処理を表します。 */
    onSearchClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: OtherReportInputItem) => void
}

/**
 * Stateの初期値を設定します
 * */
const initState = (): OtherReportInputItem => {

    const beginAt = new Date();
    beginAt.setMonth(beginAt.getMonth() - 1);
    beginAt.setHours(0, 0, 0, 0);

    const endAt = new Date();
    endAt.setHours(0, 0, 0, 0);

    const result: OtherReportInputItem = {
        keyword: "",
        searchType: OtherReportSearchType.Report,
        beginAt: beginAt,
        endAt: endAt,
        imageSearchType: OtherReportImageSearchType.None,
    }

    return result;
}

/** その他報告画面　検索エリアを表示します。 */
const OtherReportSearch: React.FC<Props> = (props) => {

    /** ラジオボタンリスト */
    const radioButtons = [
        { key: OtherReportSearchType.Reporter, label: "報告者" },
        { key: OtherReportSearchType.Report, label: "報告事項" },
    ];

    const imageTypeRadioButton = [
        { key: OtherReportImageSearchType.None, label: "未指定" },
        { key: OtherReportImageSearchType.With, label: "画像あり" },
        { key: OtherReportImageSearchType.Without, label: "画像なし" },
    ]

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** 入力項目を提供します。*/
    const [values, setValues] = useState<OtherReportInputItem>(initState());
    /**
     * 入力値の変更処理を行います。
     * @param name　入力項目を指定します。
     */
    const onChange = (name: keyof OtherReportInputItem) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "radio" ? parseInt(target.value) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /**
     *  開始日の変更時の処理を行います。
     * @param newValue
     */
    const onChangeBeginAt = (newValue: Date | null) => {
        // 時間を ゼロ(00:00:000) に変換
        if (newValue != null) newValue.setHours(0, 0, 0, 0);

        const compEndAt = values?.endAt?.getTime() ?? 0
        const compBeginAt = newValue?.getTime() ?? 0

        setValues(preValues => (
            {
                ...preValues,
                beginAt: newValue,
                endAt: (compBeginAt > compEndAt) ? null : values.endAt, // 入力済の終了日より後の場合は、終了日を null に設定
            }
        ))
    };

    /**
     *  終了日の変更時の処理を行います。
     * @param newValue
     */
    const onChangeEndAt = (newValue: Date | null) => {
        // 時間を ゼロ(00:00:000) に変換
        if (newValue != null) newValue.setHours(0, 0, 0, 0);

        const compBeginAt = values?.beginAt?.getTime() ?? 0
        const compEndAt = newValue?.getTime() ?? 0

        setValues(preValues => (
            {
                ...preValues,
                beginAt: (compEndAt !== 0 && compBeginAt > compEndAt) ? null : values.beginAt, // 入力済みの開始日より前の場合は、開始日を null に設定
                endAt: newValue,
            }
        ))
    };

    return (
        <>
            <FormControl component="fieldset">

                <div className={clsx(classes.groupContainer, classes.inputItem)}>
                    <Box className={classes.groupTitle}>
                        検索範囲
                    </Box>

                    <TextField
                        fullWidth
                        label="キーワード"
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={values.keyword}
                        className={classes.inputItem}
                        onChange={onChange("keyword")}
                    />


                    <RadioGroup
                        name="contents"
                        aria-label="contens"
                        value={values.searchType}
                        onChange={onChange("searchType")}
                        className={clsx(classes.radioGroup)}

                    >

                        {radioButtons.map((value) => (
                            <FormControlLabel
                                key={value.key}
                                value={value.key}
                                control={<Radio color="secondary" />}
                                label={value.label.toString()}
                            />
                        ))}

                    </RadioGroup>
                </div>

                <Box fontWeight="fontWeightBold">
                    日付
                </Box>

                {/* 開始日 */}
                <MuiKeybordDatePicker
                    className={classes.inputItem}
                    label="開始日"
                    disableToolbar
                    format={MuiDatePickerUtility.dateFormat}
                    placeholder={MuiDatePickerUtility.dateFormat}
                    value={values.beginAt}
                    onChange={(data) => onChangeBeginAt(data)}
                />

                <div className={classes.toFrom}>～</div>

                {/* 終了日 */}
                <MuiKeybordDatePicker
                    className={classes.inputItem}
                    label="終了日"
                    disableToolbar
                    format={MuiDatePickerUtility.dateFormat}
                    placeholder={MuiDatePickerUtility.dateFormat}
                    value={values.endAt}
                    onChange={(data) => onChangeEndAt(data)}
                />

                <Box fontWeight="fontWeightBold" marginTop={1}>
                    画像
                </Box>

                <RadioGroup
                    name="contents"
                    aria-label="contens"
                    value={values.imageSearchType}
                    onChange={onChange("imageSearchType")}
                    className={clsx(classes.radioGroup, classes.inputItem)}
                >

                    {imageTypeRadioButton.map((value) => (
                        <FormControlLabel
                            key={value.key}
                            value={value.key}
                            control={<Radio color="secondary" />}
                            label={value.label.toString()}
                        />
                    ))}

                </RadioGroup>


                {/* 検索ボタン */}
                <MuiSearchButton
                    className={classes.button}
                    fullWidth
                    onClick={(event) => props.onSearchClick(event, values)}
                />
            </FormControl>
        </>
    );


};

export default OtherReportSearch;
