// react
import * as React from 'react';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
// type
import { ManufacturerInputItem } from '../../_types';
// component
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import MuiCreateButton from '../../../../../components/Buttons/MuiCreateButton';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },

    }),
);

/** メーカーマスタ　検索項目の Props を提供します。 */
interface Props {
    /** 検索ボタンのクリック処理を行います。 */
    onClickSearch: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: ManufacturerInputItem) => void),
    ///** 新規作成ボタンのクリック処理を行います。 */
    onClickCreate: (() => void),
};

/**
 * メーカーマスタ　検索エリアを提供します。
 * */
const ManufacturerSearchCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<ManufacturerInputItem>({});

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (name: keyof ManufacturerInputItem) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? Number.parseInt(target.value, 10) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    // 表示
    return (

        <Box padding={1}>
            {/* メーカー */}
            <TextField
                label="メーカー"
                placeholder="メーカー"
                value={values.manufacturer ?? ""}
                onChange={onChange("manufacturer")}
            />

            {/* ボタングループ */}
            <Box>
                {/* 検索ボタン */}
                <MuiSearchButton
                    className={classes.button}

                    onClick={(event) => props.onClickSearch(event, values)}
                />
                {/* 新規登録ボタン */}
                <MuiCreateButton
                    className={classes.button}
                    onClick={(event) => props.onClickCreate()}
                />
            </Box>

        </Box>

    );
};

export default ManufacturerSearchCondition;
