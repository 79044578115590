// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme, fade } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
// 共通component
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
import { MasterTableOptions } from '../../../Shared/MasterTableOptions';
import MasterTableFrame from '../../../Shared/MasterTableFrame';
// type
import { InspectionItemListItem } from '../../_types';

/** 点検項目マスタテーブルの Props を提供します。*/
interface Props {
    /** データソースを表します。 */
    dataSource: InspectionItemListItem[],
    /** 行選択のクリックイベントを表します。 */
    onRowClick: (rowData: InspectionItemListItem) => void,
    /** 選択中の行データを表します。 */
    selectedRow?: InspectionItemListItem,
}

/** 点検項目マスタテーブルの State を提供します。*/
interface State {
    /** 選択中の行データを表します。 */
    selectedRow?: InspectionItemListItem,
}

/** 点検項目マスタテーブルのカラムを表します。 */
const columns = [
    { title: "点検グループ", field: "inspectionGroupName" },
    { title: "点検区分", field: "inspectionDivisionName" },
    { title: "No.", field: "weight" },
    { title: "点検項目", field: "name" },
    { title: "単位", field: "unit" },
    { title: "入力値下限", field: "inputLowerLimit" },
    { title: "入力値上限", field: "inputHigherLimit" },
    { title: "軽アラーム下限", field: "lightAlarmLowerLimit" },
    { title: "軽アラーム上限", field: "lightAlarmHigherLimit" },
    { title: "重アラーム下限", field: "heavyAlarmLowerLimit" },
    { title: "重アラーム上限", field: "heavyAlarmHigherLimit" },
    { title: "備考", field: "remarks" },
];

/** 点検項目マスタテーブルを提供します */
const InspectionItemResultTable: React.FC<Props> = (props) => {

    /** Theme を提供します。*/
    const theme = useTheme();

    /** State を提供します。 */
    const [values, setValues] = useState<State>({
        selectedRow: undefined,
    });

    useEffect(() => {
        setValues({ selectedRow: props.selectedRow });
    }, [props.selectedRow]);

    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const handleRowClick = (rowData: InspectionItemListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを保持
        setValues({ ...values, selectedRow: rowData });

        // Prop 実行
        props.onRowClick(rowData);

    };

    return (
        <MasterTableFrame>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={{
                    ...MasterTableOptions,
                    rowStyle: (rowData, index) => ({
                        whiteSpace: "nowrap",
                        backgroundColor: (values.selectedRow?.id === rowData.id) ? fade(theme.palette.secondary.main, 0.5) : "", // 選択された行の背景色
                    }),
                }}
                columns={columns}
                data={props.dataSource}
                onRowClick={(event, selectedRow) => {
                    selectedRow && handleRowClick(selectedRow, event)
                }}
            />
        </MasterTableFrame>

    );

};

export default InspectionItemResultTable;
