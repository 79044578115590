// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { InspectionDivisionSlice, onExcuteAsync, getIncspectionPreviewReportAsync } from '../../../../../modules/slice/inspection-division/inspection-division-slice';
import { InspectionDivisionAdapter } from '../../../../../modules/adapter/inspection-division/inspection-division-adapter'
// type
import { InspectionDivisionInputItem } from '../../_types';
import { KeyValueItem, InspectionType } from '../../../../../_types';
// component
import InspectionDivisionSearchCondition from './InspectionDivisionSearchCondition';

/**
 * 点検区分マスタ　検索エリアを提供します。
 * */
export const InspectionDivisionSearchConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const groupList = useSelector((state: RootState) => state.inspectionDivisionMaster.selectBoxItem.InspectionGroup);
    const reportUrl: string = useSelector((state: RootState) => state.inspectionDivisionMaster.reportUrl ?? "");

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: InspectionDivisionInputItem) => {

        // 非同期通信
        const promise = InspectionDivisionAdapter.instance.getAsync(
            {
                // セレクトボックスの 0:未選択 を void 0 に変換
                item: {
                    ...inputItem,
                    groupId: inputItem.groupId === 0 ? void 0 : inputItem.groupId,
                }
            },
            {
                InspectionGroup: groupList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(InspectionDivisionSlice.actions.chageCreating(true))
    }

    /** 帳票ボタンのクリック処理を行います。 */
    const onClickPreview = (type: InspectionType) => {
        dispatch(getIncspectionPreviewReportAsync(type));
    }

    /** レポート読み込み完了時の処理を行います。 */
    const loadedReport = () => {
        dispatch(InspectionDivisionSlice.actions.changeReportLoadingFlag(false));
    }

    // 表示
    return (
        <InspectionDivisionSearchCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
            groupList={groupList}
            onClickPreview={onClickPreview}
            reportUrl={reportUrl}
            loadedReport={loadedReport}
        />
    );
};
