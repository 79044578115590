// react
import * as React from 'react';
// redux
import { useDispatch } from 'react-redux';
import { ManufacturerMasterSlice, onExcuteAsync } from '../../../../../modules/slice/manufacturer/manufacturer-slice';
import { ManufacturerAdapter } from '../../../../../modules/adapter/manufacturer/manufacturer-adapter';
// type
import { ManufacturerInputItem } from '../../_types';
// component
import ManufacturerDetailCondition from './ManufacturerSearchCondition';

/**
 * メーカーマスタ　検索エリアを提供します。
 * */
export const ManufacturerSearchConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: ManufacturerInputItem) => {

        // 非同期通信
        const promise = ManufacturerAdapter.instance.getAsync(
            { item: inputItem },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(ManufacturerMasterSlice.actions.chageCreating(true))
    }

    // 表示
    return (
        <ManufacturerDetailCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
        />
    );
};
