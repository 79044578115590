import * as React from 'react';
import { ConstructionMachineMonitoringPage } from './MainMonitor';
import { HeaderPage } from '../Shared/HeaderPage/HeaderPage';

/**
 *  施工機モニター(Home)画面を表示します。
 */
export const ConstructionMachineMonitorPage: React.FC<{}> = () => {
    return (
        <HeaderPage children={<ConstructionMachineMonitoringPage />} />
    );
}
