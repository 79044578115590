// react
import * as React from 'react';
import { useEffect } from 'react';
// router
import { useHistory, RouteComponentProps } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import SigninForm, { SinginFormInputItem } from './SigninForm';
import { onSigninAsync } from '../../../../../modules/slice/signin/signin-slice';
import { AppUrl } from '../../../../Shared/app-url'
import { onGetAuthenticatedUserAsync } from '../../../../../modules/slice/authentication/authentication-slice';
/**
 * サインイン画面を提供します。
 * */
export const SigninFormContainer: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    /** サインインボタン　クリック時の処理を行います。 */
    const onSigninClickAsync = async (inputItem: SinginFormInputItem) => {
        // 認証
        await dispatch(onSigninAsync({
            userId: inputItem.id,
            password: inputItem.password,
        }));
    };

    return (
        <SigninForm
            onSigninClick={onSigninClickAsync}
        />
    );
}
