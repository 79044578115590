import * as React from 'react';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Drawer, Backdrop, IconButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { InspectionType } from '../../../../../_types';
import MuiPreviewButton from '../../../../../components/Buttons/MuiPreviewButton';
import { ActiveReportViewer } from '../../../../../components/ActiveReportViewer/ActiveReportViewer';

/** 点検票プレビュードロワーの Prsop を提供します。 */
interface Props {
    /** レポートの url を表します。 */
    reportUrl: string,
    /** レポート読み込み終了時の処理を行います。 */
    loadedReport: () => void,
    /** プレビューボタンクリック時の処理を表します。 */
    onClickPreview: (type: InspectionType) => void,
}

/** ラジオボタンリスト */
const radioButtons = [
    { key: InspectionType.Daily, label: "日常" },
    { key: InspectionType.Weekly, label: "週間月例" },
    { key: InspectionType.Completion, label: "現場完了" },
];

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: 150,
            margin: "1rem",
        },
        // バックドロップ
        backdrop: {
            zIndex: theme.zIndex.drawer - 1,
            color: '#fff',
        },
        // ビューワ
        viewer: {
            width: "65vw",
            height: "100%",
        },
        // ドロワーのコンテナ
        drawerPaper: {
            overflowY: "unset",
        },
        // ドロワー閉じるボタン
        closeButton: {
            position: "absolute",
            left: -50,
        },
        // ラジオボタングループ
        radioGroup: {
            margin: theme.spacing(1, 1, 0, 1),
        },
        // ラジオボタングループラベル
        radioGroupLabel: {
            marginBottom: 0,
        },
        // ラジオボタングループ
        radioGroupButton: {
            flexDirection: "row"
        },
    }),
);

/**
 * 帳票プレビュードロワー を表示します。
 */
export function InspectionPreviewDrawer(props: Props) {



    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    const [selectedType, setSelectedType] = useState<InspectionType>(InspectionType.Daily);
    const [isOpen, setIsOpen] = useState(false);

    /** ドロワーを開きます。 */
    const openDrawer = () => {
        props.onClickPreview(selectedType);
        setIsOpen(true)
        if (document.body) document.body.classList.add("body-scrolllock");
    }

    /** ドロワーを閉じます。 */
    const cloaseDrawer = () => {
        setIsOpen(false)
        if (document.body) document.body.classList.remove("body-scrolllock");
    }

    /**
     * ラジオボタンの選択処理を行います。
     * @param event
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedType(parseInt((event.target as HTMLInputElement).value));
    };

    return (
        <>
            {/* ラジオボタン */}
            <FormControl className={classes.radioGroup}>
                <FormLabel component="legend" className={classes.radioGroupLabel}>点検表</FormLabel>
                <RadioGroup
                    className={classes.radioGroupButton}
                    aria-label="InspectionType"
                    name="InspectionType"
                    value={selectedType}
                    onChange={handleChange}
                >
                    {radioButtons.map((value) => (
                        <FormControlLabel
                            key={value.key}
                            value={value.key}
                            control={<Radio color="secondary" />}
                            label={value.label}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <MuiPreviewButton
                className={classes.button}
                onClick={openDrawer}>
                プレビュー
            </MuiPreviewButton>


            {/* Drawer の variant="temporary" だと、JSViewerの印刷モーダルが表示されないので、"persistent" にして、背面の無効化を手動で設置 */}
            <Backdrop
                className={classes.backdrop}
                open={isOpen}
                onClick={cloaseDrawer}
            />

            {/* Drawer */}
            <Drawer
                variant="persistent"
                anchor="right"
                open={isOpen}
                onClose={cloaseDrawer}
                classes={{ paper: classes.drawerPaper }}

            >
                {/* close button  */}
                <IconButton
                    className={classes.closeButton}
                    onClick={cloaseDrawer}
                >
                    <CloseIcon />
                </IconButton>

                {/* JSビューワ*/}
                <div className={classes.viewer}>
                    {!!props.reportUrl && (
                        <ActiveReportViewer
                            reportUrl={props.reportUrl}
                            loadedReport={props.loadedReport}
                        />
                    )}
                </div>
            </Drawer>

        </>
    );
}
