import { ModelListItem, ModelInputItem } from '../../../pages/Master/MachineModel/_types';
import { ModelRequest } from './types';
import { ModelInput, ModelItem } from '../../swagger-clients';

/**
 * `型式マスタ` 関連の ユーティリティ機能を提供します。
 */
export class ModelUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: ModelItem[]): ModelListItem[] {
        const result: ModelListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.id == null || source.lockVersion == null) {
                continue;
            }
            const item: ModelListItem = {
                id: source.id,
                model: source.name ?? "",
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: ModelRequest): ModelInput {
        const result = new ModelInput();
        result.init({
            item: ModelUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: ModelInputItem): ModelItem {
        const result = new ModelItem();
        result.init({
            id: source.id,
            name: source.model,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
