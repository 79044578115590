import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GenericState, createGenericSlice } from '../shared/create-generic-slice';
import { AccountAdapter } from '../../adapter/account/account-adapter'
import { SignInRequest } from '../../adapter/account/types';
import { SigninUtility } from './signin-slice-utility';
import { AppUrl } from '../../../pages/Shared/app-url';

/** サインイン画面 State を提供します。 */
interface SigninSliceState {
    isSignedIn: boolean;
}

// State の初期値を設定します。
const initialState: GenericState<SigninSliceState> = {
    isLoading: false,
    isShowMessage: false,
    result: { isSignedIn: SigninUtility.getStorageIsSignedIn() },
}

/** サインイン画面 Slice を 提供します。*/
export const SigninSlice = createGenericSlice({
    // State Reducer Action を生成
    name: 'Signin',
    initialState: initialState,
    reducers: {

        /**
        * サインイン情報を変更します。
        * @param state
        * @param action
        */
        setIsSignedIn(state: GenericState<SigninSliceState>, action: PayloadAction<boolean>) {
            state.result.isSignedIn = action.payload;
        },
    },
})

/**
 * サインイン処理を行います。
 * @param parameter
 */
export const onSigninAsync = (parameter: SignInRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(SigninSlice.actions.fetchStart());

        // 通信処理
        const result = await await AccountAdapter.instance.signInAsync(parameter);

        if (result.errorMessage) {
            // 異常
            // メッセージ
            dispatch(SigninSlice.actions.showErrorMessage(result.errorMessage ?? ""));
        } else {
            // 正常
            // ストレージ更新
            SigninUtility.setStorageIsSignedIn();

            // state 更新
            dispatch(SigninSlice.actions.setIsSignedIn(true));
        }

    } catch (error) {
        // 例外
        // メッセージ
        dispatch(SigninSlice.actions.showErrorMessage(`${error}`));

    } finally {
        // 処理中 OFF
        dispatch(SigninSlice.actions.fetchEnd());
    }
}
