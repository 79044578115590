/**
 * `CreateSlice` 関連の ユーティリティ機能を提供します。
 */
export class CreateSliceUtility {
    //#region フィールド

    /** 通信失敗時のデフォルトのメッセージを表します。 */
    public static readonly DefualtErrorMessage = "通信エラーが発生しました";

    /** 通信成功時のデフォルトメッセージを表します。*/
    public static readonly DefualtSuccessMessage = "通信が完了しました";

    //#endregion フィールド

}
