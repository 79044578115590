// react
import * as React from 'react';
// clsx
import clsx from "clsx";
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import {
    IconButton,
    Box,
    Chip
} from '@material-ui/core';
import CtuTagMachineId from './CtuTagMachineId';
// type
import { Machine, MachineType, MachineItem, ConnectedCtuType } from '../../../../../_types';
import { HeaderMachineState } from '../../../../Shared/GlobalHeader/_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ノートアイコン
        noteIconBox: {
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            color: theme.palette.text.secondary,
        },
        //  ロック状態
        lockIcon: {
            color: theme.palette.success.main,
        },
        // 空の場合
        emptyNumber: {
            marginLeft: theme.spacing(3),
        },
        // 社番コンテナ
        machineIdContainer: {
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "baseline",
        },
        // 社番テキスト
        machineIdColor: {
            color: theme.palette.text.primary,
        },
        // エラー情報アリ
        machineIdColorError: {
            color: theme.palette.error.contrastText,

        },
        // ベースマシンCTU接続無し
        disconnected: {
            paddingLeft: 6, // パディング4 + ボーダー2

        },
        // Noteアイコン月ボタン
        noteButton: {
            display: "flex",
            justifyContent: "flex-start",
        },
    })
);

/** Props のインターフェイスを提供します。 */
interface Props {
    /** 施工機情報を表します。 */
    item: MachineItem,
    /** 施工機種別を表します。 */
    machineType: MachineType,
    /** ロック状態かどうかを表します。 */
    isLock: boolean,
    /** エラー情報かどうかを表します。 */
    isError: boolean,
    /** クリックイベントを表します。 */
    onNoteClick: (event: React.MouseEvent<Element, MouseEvent>, machine: HeaderMachineState) => void,
}

/**
 * ノートアイコンボタンを付与した社番を表示します。
 * @param props
 */
const NoteIconButton: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <>
            {
                props.item.id ?
                    (
                        // ロック状態のアイコンは緑色
                        <Box className={clsx(classes.noteIconBox, props.isLock && classes.lockIcon)}>
                            {/* Note アイコン 付きボタン*/}
                            <Button
                                className={classes.noteButton}
                                aria-label="note"
                                color="inherit"
                                variant="text"
                                startIcon={<DescriptionIcon />}
                                onClick={(event) => props.onNoteClick(
                                    event,
                                    {
                                        item: props.item,
                                        type: props.machineType,
                                        isLock: props.isLock,

                                    })}>

                                <Box
                                    display="flex"
                                    className={clsx(props.isError ? classes.machineIdColorError : classes.machineIdColor)}>
                                    {props.machineType === MachineType.BaseMachine
                                        // ベースマシン
                                        ? (
                                            props.item.ctu === ConnectedCtuType.Connected
                                                // CTUあり
                                                ? <CtuTagMachineId machineId={props.item.id} />
                                                // CTUなし
                                                : <Box className={classes.disconnected}>{props.item.id}</Box>
                                        )

                                        // オーガー・昇降装置
                                        : (<Box>{props.item.id}</Box>)
                                    }

                                </Box>
                            </Button>

                        </Box>
                    )
                    : (
                        // 無い場合、ハイフン表示
                        <div className={classes.emptyNumber}>----</div>
                    )
            }
        </>
    );
}

export default NoteIconButton;
