// react
import * as React from 'react';
// redux
import { useDispatch } from 'react-redux';
import { ModelMasterSlice, onExcuteAsync } from '../../../../../modules/slice/model/model-slice';
import { ModelAdapter } from '../../../../../modules/adapter/model/model-adapter'
// type
import { ModelInputItem } from '../../_types';
// component
import MachineModelSearchCondition from './MachineModelSearchCondition';

/**
 * 型式マスタ　検索エリアを提供します。
 * */
export const MachineModelSearchConditionConTainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: ModelInputItem) => {

        // 非同期通信
        const promise = ModelAdapter.instance.getAsync(
            { item: inputItem },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(ModelMasterSlice.actions.chageCreating(true))
    }

    // 表示
    return (
        <MachineModelSearchCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
        />
    );
};
