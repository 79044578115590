// react
import * as React from 'react';
import { useEffect } from 'react';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMasterPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { InspectionDivisionSlice, onExcuteMountedAsync } from '../../../modules/slice/inspection-division/inspection-division-slice';
// component
import { InspectionDivisionResultTableContainer } from './components/TableContent/InspectionDivisionResultTableContainer';
import { InspectionDivisionSearchConditionContainer } from './components/SearchContent/InspectionDivisionSearchConditionContainer';
import { InspectionDivisionDetailConditionContainer } from './components/DetailContent/InspectionDivisionDetailConditionContainer';
import { ConfirmDialogContainer } from './components/ConfirmDialog/ConfirmDialogContainer';

import MasterLayout from '../Shared/MasterLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';

/**
 * 点検区分マスタ画面を提供します。
 * */
const InspectionDivisionPage: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeMasterPageHeader(AppTitle.masterInspectionDivision.title));

        // 初回マウント時にデータを取得する
        dispatch(onExcuteMountedAsync());
    }, [dispatch]);

    // redux store
    const isLoading = useSelector((state: RootState) => state.inspectionDivisionMaster.isLoading);
    const isReportLoading = useSelector((state: RootState) => state.inspectionDivisionMaster.isReportLoading ?? false);
    const isShowMessage = useSelector((state: RootState) => state.inspectionDivisionMaster.isShowMessage);
    const message = useSelector((state: RootState) => state.inspectionDivisionMaster.message);
    const isCreating = useSelector((state: RootState) => state.inspectionDivisionMaster.isCreating);
    const isSelected = useSelector((state: RootState) => state.inspectionDivisionMaster.isSelected);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(InspectionDivisionSlice.actions.hideMessage());
    };

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading || isReportLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ダイアログ */}
            <ConfirmDialogContainer />

            <MasterLayout
                search={<InspectionDivisionSearchConditionContainer />}
                table={<InspectionDivisionResultTableContainer />}
                detail={<InspectionDivisionDetailConditionContainer />}
                isCreating={isCreating}
                isSelected={isSelected}
            />
        </>
    );
};

export default InspectionDivisionPage;
