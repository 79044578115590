// react
import * as React from 'react';
import { useState, useEffect, useCallback, useMemo, useRef} from 'react';
import { Box, Button, Breadcrumbs, Typography, Link} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import path from 'path';
import { HomeDirectoryType } from '../../_types';


/** パンくずリストの Props を提供します。 */
interface S3BreadcrumbsProps {
    /** ディレクトリ名を表します。 */
    directoryName: string,
    /** クリックイベントを表します。 */
    onClickBreadcrumb: (e: React.MouseEvent, key: string) => void,
}
/**
 * パンくずリストを表示します。
 * @param props
 */
export const S3Breadcrumbs = React.memo(
    function S3BreadcrumbsRN(props: S3BreadcrumbsProps): React.ReactElement {
        const directoryNames: string[] = props.directoryName.replace(/\/$/, '').split("/");
        const root = directoryNames.shift() ?? "";
        return (
            <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
                {directoryNames.length &&
                    directoryNames.map((name, index) => {
                        if (!name) return null;
                        if (index > 0 && directoryNames.length - 1 === index) {
                            return (<Typography key={index} variant="h5" color="textPrimary">{name}</Typography>);
                        } else {
                            const fileName = name === HomeDirectoryType.Document ? "ドキュメント"
                                    : name === HomeDirectoryType.OnlineHelp ? "オンラインヘルプ"
                                    : name;
                            return (<Link key={index} variant="h5" component="button" onClick={(e: React.MouseEvent) => props.onClickBreadcrumb(e, path.join(root, ...directoryNames.slice(0, index + 1)))}>{fileName}</Link>);
                        }
                    })}
            </Breadcrumbs>
        );
    }
);
