import { RemarksListItem, RemarksInputItem } from '../../../pages/Master/Remarks/_types';
import { RemarksRequest, RemarksSelectBoxItemState } from './types';
import { RemarksInput, RemarksItem } from '../../swagger-clients';

/**
 * `整備区分マスタ` 関連の ユーティリティ機能を提供します。
 */
export class RemarksUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(
        selectBoxItem: RemarksSelectBoxItemState,
        items?: RemarksItem[]
    ): RemarksListItem[] {
        const result: RemarksListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.remarksId == null
                || source.machineType == null
                || source.title == null
                || source.item == null
                || source.weight == null
                || source.lockVersion == null) {
                continue;
            }

            // セレクトボックスの名称を取得
            const typeItem = selectBoxItem.MachineType.find(_ => _.key === source.machineType);

            const item: RemarksListItem = {
                id: source.remarksId,
                machineType: typeItem?.value ?? "",
                machineTypeId: source.machineType,
                title: source.title,
                item: source.item,
                weight: source.weight,
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: RemarksRequest): RemarksInput {
        const result = new RemarksInput();
        result.init({
            item: RemarksUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: RemarksInputItem): RemarksItem {
        const result = new RemarksItem();
        result.init({
            remarksId: source.id,
            machineType: source.machineTypeId,
            title: source.title,
            item: source.item,
            weight: source.weight,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
