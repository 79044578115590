import { OtherReportClient, GetOtherReportInput, GetDocumentInput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { OtherReportGetRequest, OtherReportGetResult, OtherReportGetDocumentRequest, OtherReportGetDocumentResult } from './types';
import { OtherReportUtility } from './other-report-utility';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `その他報告` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class OtherReportAdapter extends WebApiAdapter<OtherReportClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new OtherReportAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: OtherReportGetRequest): Promise<OtherReportGetResult> {
        function createErrorResult(message?: string): OtherReportGetResult {
            return {
                items: [],
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetOtherReportInput();
        input.init({
            searchWord: parameter.item.keyword,
            searchType: OtherReportUtility.toSearchType(parameter.item.searchType),
            searchBeginAt: parameter.item.beginAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.beginAt),
            searchEndAt: parameter.item.endAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.endAt),
            isSavedImage: OtherReportUtility.toIsSavedImage(parameter.item.imageSearchType),
        });
        const output = await this.client.get(input);
        if (output == null) {
            return createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResult(output.errorMessage);
        }
        // 正常
        const result: OtherReportGetResult = {
            isError: false,
            items: OtherReportUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * ドキュメントの取得を行います。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getLinkAsync(parameter: OtherReportGetDocumentRequest): Promise<OtherReportGetDocumentResult> {
        function createErrorResult(message?: string): OtherReportGetDocumentResult {
            return {
                url: "",
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetDocumentInput();
        input.init({
            no: parameter.no,
        });
        // memo kura: 実装 -> 関数不使用
        //const output = await this.client.getDocument(name);
        //if (output == null) {
        //    return createErrorResult(WebApiAdapter.messageFailedCommunication);
        //}
        //else if (output.isError === true) {
        //    return createErrorResult(output.errorMessage);
        //}

        // 正常
        const result: OtherReportGetDocumentResult = {
            isError: false,
            url: "", //output.url ?? "",
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new OtherReportClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
