// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { UserMasterSlice, onExcuteAsync, onUpdateAsync } from '../../../../../modules/slice/user/user-slice';
import { UserAdapter } from '../../../../../modules/adapter/user/user-adapter';
// type
import { UserInputItem, UserValidateInputItem, UserListItem } from '../../_types';
// component
import UserDetailCondition from './UserDetailCondition';
import { User } from 'oidc-client/dist/oidc-client';
import { UserItem } from '../../../../../_types';

/**
 * ユーザーマスタ　詳細エリアを提供します。
 * */
export const UserDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.userMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.userMaster.selectedRowItem);
    const user = useSelector((state: RootState) => state.authentication.user);


    const initialValues: UserInputItem = {
        id: selectedItem?.id,
        user: selectedItem?.user,
        password: "",
        confirmPassword: "",
        lockVersion: selectedItem?.lockVersion,
    }

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<UserValidateInputItem>({});

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<UserInputItem>(initialValues);


    // 行選択時の入力項目更新
    useEffect(() => {
        setValues(preValue => ({
            ...preValue,
            id: selectedItem?.id,
            user: selectedItem?.user,
            inspectorName: selectedItem?.inspectorName,
            password: selectedItem?.password,
            confirmPassword: selectedItem?.password,
            lockVersion: selectedItem?.lockVersion,
        }))

        // バリデーション情報クリア
        clearValidate();

    }, [selectedItem]);

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof UserInputItem) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? Number.parseInt(target.value, 10) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /** 追加ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = UserAdapter.instance.addAsync(
            { item: values },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = UserAdapter.instance.updateAsync(
            {
                item: values,
                oldId: selectedItem?.id,
            },

        );
        await dispatch(onUpdateAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        // ダイアログ表示
        dispatch(UserMasterSlice.actions.toggleDialog(true));

    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(UserMasterSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }

    /**
    * 入力内容のバリデーションを行います。
    * @returns 検証結果を返します。
    * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let id = "";
        let user = "";
        let inspectorName = "";
        let password = "";
        let confirmPassword = "";

        // ユーザー id
        if (!values.id) {
            id = "ユーザー id をしてください";
            result = true;
        }

        // ユーザー名
        if (!values.user) {
            user = "ユーザー名を入力してください";
            result = true;
        }

        // 検印文字
        if (!values.inspectorName) {
            inspectorName = "検印文字を入力してください";
            result = true;
        }

        // パスワード
        if (!values.password) {
            password = "パスワードを入力してください";
            result = true;
        }

        // 確認用パスワード
        if (!values.confirmPassword) {
            confirmPassword = "確認用パスワードを入力してください";
            result = true;
        } else if (values.password !== values.confirmPassword) {
            confirmPassword = "パスワードが一致しません";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            id,
            user,
            inspectorName,
            password,
            confirmPassword,
        });

        // 結果
        return result;

    }

    /** */
    const checkSignInUser = (signinUser?: UserItem, selectedItem?: UserListItem): boolean => {

        if (!signinUser || !selectedItem) return false;

        return signinUser.id === selectedItem.id && signinUser.name === selectedItem.user;
    }

    // 表示
    return (
        <UserDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            onChange={onChange}
            validateError={validate}
            isSigninUser={checkSignInUser(user, selectedItem)}
        />
    );
};
