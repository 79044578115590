// react
import * as React from 'react';
import { useEffect } from 'react';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMasterPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { InspectionItemMasterSlice, onExcuteMountedAsync } from '../../../modules/slice/inspection-item/inspection-item-slice';
// component
import { InspectionItemResultTableContainer } from './components/TableContent/InspectionItemResultTableContainer';
import { InspectionItemSearchConditionContainer } from './components/SearchContent/InspectionItemSearchConditionContainer';
import { InspectionItemDetailConditionContainer } from './components/DetailContent/InspectionItemDetailConditionContainer';
import { ConfirmDialogContainer } from './components/ConfirmDialog/ConfirmDialogContainer';

import MasterLayout from '../Shared/MasterLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';
/**
 * 点検項目マスタ画面を提供します。
 * */
const InspectionItemPage: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeMasterPageHeader(AppTitle.masterInspectionItem.title));

        // 初回マウント時にデータを取得する
        dispatch(onExcuteMountedAsync());
    }, [dispatch]);

    // redux store
    const isLoading = useSelector((state: RootState) => state.inspectionItemMaster.isLoading);
    const isReportLoading = useSelector((state: RootState) => state.inspectionItemMaster.isReportLoading ?? false);
    const isShowMessage = useSelector((state: RootState) => state.inspectionItemMaster.isShowMessage);
    const message = useSelector((state: RootState) => state.inspectionItemMaster.message);
    const isCreating = useSelector((state: RootState) => state.inspectionItemMaster.isCreating);
    const isSelected = useSelector((state: RootState) => state.inspectionItemMaster.isSelected);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(InspectionItemMasterSlice.actions.hideMessage());
    };

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading || isReportLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ダイアログ */}
            <ConfirmDialogContainer />

            <MasterLayout
                search={<InspectionItemSearchConditionContainer />}
                table={<InspectionItemResultTableContainer />}
                detail={<InspectionItemDetailConditionContainer />}
                isCreating={isCreating}
                isSelected={isSelected}
            />
        </>
    );
};

export default InspectionItemPage;
