import { InspectionDivisionListItem, InspectionDivisionInputItem } from '../../../pages/Master/InspectionDivision/_types';
import { InspectionDivisionRequest, InspectionDivisionSelectBoxItemState } from './types';
import { InspectionDivisionInput, InspectionDivisionItem } from '../../swagger-clients';

/**
 * `点検区分マスタ` 関連の ユーティリティ機能を提供します。
 */
export class InspectionDivisionUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(
        selectBoxItem: InspectionDivisionSelectBoxItemState,
        items?: InspectionDivisionItem[]
    ): InspectionDivisionListItem[] {
        const result: InspectionDivisionListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.id == null
                || source.groupId == null
                || source.weight == null
                || source.lockVersion == null) {
                continue;
            }

            // セレクトボックスの名称を取得
            const groupItem = selectBoxItem.InspectionGroup.find(_ => _.key === source.groupId);

            const item: InspectionDivisionListItem = {
                id: source.id,
                name: source.name ?? "",
                groupId: source.groupId,
                groupName: groupItem?.value ?? "",
                weight: source.weight,
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: InspectionDivisionRequest): InspectionDivisionInput {
        const result = new InspectionDivisionInput();
        result.init({
            item: InspectionDivisionUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: InspectionDivisionInputItem): InspectionDivisionItem {
        const result = new InspectionDivisionItem();
        result.init({
            id: source.id,
            name: source.name,
            groupId: source.groupId,
            weight: source.weight,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
