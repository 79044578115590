// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeRecordPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { MaintenanceRecordSlice, onGetAsync } from '../../../modules/slice/maintenance-record/maintenance-record-slice';
// component
import MaintenanceRecordTableContainer from './components/TableContent/MaintenanceRecordTableContainer';
import MaintenanceSearchContainer from './components/SearchContent/MaintenanceRecordSearchContainer';
import { StampContainer } from './components/StampContent/StampContainer';
import { ViewerContainer } from './components/ViewerContent/ViewerContainer';
// 共通component
import TwoColumnLayout from '../../../components/Layout/TwoColumnLayout';
import CollapseLayout from '../../../components/Layout/CollapseLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';
import { MaintenanceRecordSearchType, StampSearchType } from './_types';
import TopAndBottomLayout from '../../../components/Layout/TopAndBottomLayout';
import { AreaToggleButtonContainer } from '../../../components/AreaToggleButton/AreaToggleButtonContainer';

/**
 * 整備記録画面を表示します。
 * */
export const MaintenanceRecord: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.maintenanceRecord.isLoading);
    const isReportLoading = useSelector((state: RootState) => state.maintenanceRecord.isReportLoading);
    const isShowMessage = useSelector((state: RootState) => state.maintenanceRecord.isShowMessage);
    const message = useSelector((state: RootState) => state.maintenanceRecord.message);
    const machine = useSelector((state: RootState) => state.globalHeader.machine)
    const isOpenViewer = useSelector((state: RootState) => state.maintenanceRecord.isOpenViewer ?? true);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(MaintenanceRecordSlice.actions.hideMessage());
    };

    useEffect(() => {
        // タイトル変更
        dispatch(changeRecordPageHeader((AppTitle.recordMaintenanceRecord.title)));

        // 初回マウント時に直近30日分のデータ取得を行う。
        /** 初期表示時に入力値に入れる値は SearchContent で設定しているので注意する */
        const beginAt = new Date();
        beginAt.setMonth(beginAt.getMonth() - 1);
        beginAt.setHours(0, 0, 0, 0);

        const endAt = new Date();
        endAt.setHours(0, 0, 0, 0);

        if (!machine) {
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage("施工機情報が指定されていません"));
        } else {
            dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    machineType: machine.type,
                    keyword: "",
                    searchType: MaintenanceRecordSearchType.None,
                    searchStampType: StampSearchType.None,
                    beginAt: beginAt,
                    endAt: endAt,
                }
            }))
        }

    }, [dispatch, machine]);


    useEffect(() => {

        return () => {
            //　unmout 時にビューワを非表示にする
            dispatch(MaintenanceRecordSlice.actions.toggleViewre(false));
        }

    }, [dispatch])


    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading || isReportLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* 整備記録 */}
            <CollapseLayout
                isExpanded={isOpenViewer}
                defaultContent={
                    <TwoColumnLayout
                        // 検索エリア
                        side={
                            <TopAndBottomLayout
                                topContent={<MaintenanceSearchContainer />}
                                bottomContent={<AreaToggleButtonContainer />}
                            />
                        }
                        // テーブル
                        main={<MaintenanceRecordTableContainer />}
                    />
                }
                expandContent={isOpenViewer
                    ? <TwoColumnLayout
                        // 検印エリア
                        side={<StampContainer />}
                        // ビューワ
                        main={<ViewerContainer />}
                    />
                    : <></>
                }
            />

        </>
    );
};
