// react
import * as React from 'react';
// material-ui
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// type
import { Message } from './types';

/** スナックバーのPropsのインターフェイスを提供します。 */
interface Props {
    /** 表示するかどうかを表します。 */
    isOpen: boolean,
    /** メッセージを表します。 */
    message?: Message,
    /** 閉じる処理を提供します。 */
    onClose: (event?: React.SyntheticEvent, reason?: string) => void,
}

/**
 * スナックバーを表示します。
 * @param props
 */
const MuiSnackbar: React.FC<Props> = (props) => {

    /** 閉じる処理を行います。 */
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.onClose();
    };

    // メッセージがない場合
    if (!props.message) return (null);

    return (
        <Snackbar
            onClose={handleClose}
            open={props.isOpen}
            autoHideDuration={props.message.type === "success" ? 2000 : null}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                severity={props.message.type}
                onClose={handleClose}
            >
                {props.message.text}
            </MuiAlert>
        </Snackbar>
    )
};

export default MuiSnackbar;
