import * as React from 'react';
import { MasterTab } from './MasterTab';
import { HeaderPage } from '../Shared/HeaderPage/HeaderPage';

/**
 *  系マスタ画面を表示します。
 */
export const MasterPage: React.FC<{}> = () => {
    return (
        <HeaderPage children={<MasterTab />} />
    );
}
