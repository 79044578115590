import { MachineMonitoringListItem, MachineMonitoringSearchType } from '../../../pages/ConstructionMachineMonitor/MainMonitor/_types';
import {
    MachineMonitoringItem,
    GetMachineMonitoringInput,
    KeyValueItemOfMachineTypeAndMachineItem,
    MachineMonitoringSearchType as DtoSearchType,
    MachineType as DtoMachineType,
    ConnectedCtuType as DtoConnectedCtuType,
} from '../../swagger-clients';
import { SharedUtility } from '../../shared/shared-utility';
import { MachineItem, ConnectedCtuType } from '../../../_types'

/**
 * `MachineMonitoring` 関連の ユーティリティ機能を提供します。
 */
export class MachineMonitoringUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: MachineMonitoringItem[]): MachineMonitoringListItem[] {
        const result: MachineMonitoringListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            const baseMachineItem = MachineMonitoringUtility.toMachineData(source.machines, DtoMachineType.BaseMachine);
            const augerItem = MachineMonitoringUtility.toMachineData(source.machines, DtoMachineType.Auger);
            const liftItem = MachineMonitoringUtility.toMachineData(source.machines, DtoMachineType.Lift);
            const item: MachineMonitoringListItem = {
                baseMachine: baseMachineItem,
                auger: augerItem,
                lift: liftItem,
                isLock: source.isLock,
                site: source.site ?? "",
                tabletId: source.tabletId ?? "",
                areaType: source.areaId == null ? void 0 : SharedUtility.toAreaType(source.areaId),
                ctuError: source.ctuError ?? "",
                reportItem: source.reportItem ?? "",
                isConnectedTablet: source.isConectedTablet,
                selfInspection: source.selfInspection ?? "",
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 機器情報一覧から指定種別の情報を取得します。
     *
     * @param source 機器情報一覧を指定します。
     * @param type 種別を指定します。
     * @returns 見つかった情報を返します。
     */
    private static toMachineData(source: KeyValueItemOfMachineTypeAndMachineItem[] | undefined, type: DtoMachineType): MachineItem {
        if (source == null) {
            return {
                id: "",
                manufacturer: "",
                model: "",
                ctu: ConnectedCtuType.NotApplicable,
            };
        }

        const machineItem = source.find(item => item.key == type);
        const result = {
            id: machineItem?.value?.machineId ?? "",
            manufacturer: machineItem?.value?.manufacturer ?? "",
            model: machineItem?.value?.model ?? "",
            ctu: machineItem?.value?.connectedCtu == null ? ConnectedCtuType.NotApplicable : SharedUtility.toConectedCtu(machineItem.value.connectedCtu),
        };
        return result;
    }

    /**
     * 検索キーワード種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toSearchType(source: MachineMonitoringSearchType): DtoSearchType {
        const result: DtoSearchType | undefined =
            source === MachineMonitoringSearchType.None ? DtoSearchType.None :
                source === MachineMonitoringSearchType.BaseMachine ? DtoSearchType.BaseMachineId :
                    source === MachineMonitoringSearchType.Auger ? DtoSearchType.AugerMachineId :
                        source === MachineMonitoringSearchType.Lift ? DtoSearchType.LiftMachineId :
                            void 0;

        if (result == null) {
            throw new Error("toSearchType");
        }
        return result;
    }

    //#endregion メソッド
}
