import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { EditItem } from '../../_types';
import EditCardContent from './EditCardContent';
import EditCardHeader from '../EditCardHeader/EditCardHeader';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "block",
            minHeight: "136px",
        },
    }),
);

/** ステータス編集カードの Props を提供します。 */
interface Props {
    /** 編集項目を表します。 */
    item: EditItem,
    /** 編集ボタンクリック時のイベントを表します。 */
    onEditClick: (item: EditItem) => void,
    /** 履歴ボタンクリック時のイベントを表します。 */
    onHistoryClick: (item: EditItem) => void,
};

/**
 * ステータス編集カードを提供します。
 * @param props
 */
const EditCard: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles(props);

    return (
        <Card className={classes.root} >

            <EditCardHeader
                item={props.item}
                onEditClick={props.onEditClick}
                onHistoryClick={props.onHistoryClick}
            />

            <Divider variant="middle" />

            <EditCardContent
                item={props.item}
            />

        </Card>
    );
};

export default EditCard;
