// react
import * as React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// type
import { MaintenanceItemInputItem, MaintenanceValidateItemInputItem } from '../../_types';
import { KeyValueItem } from '../../../../../_types';
// component
import MasterDetailButtonGroup from '../../../Shared/MasterDetailButtonGroup';
import { MuiTextFieldUtility } from '../../../../../components/MuiTextField/mui-text-field-utility';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },
        // 削除
        deleteButton: {
            color: theme.palette.warning.main,
        },

        // 更新
        updateButton: {
            color: theme.palette.warning.main,
        },
        // 行コンテナ
        rowContainer: {
            display: "flex",
        },
        // テキストエリア
        textarea: {
            width: "100% !important",
        },
        // テキストエリア
        inputItem: {
            width: `calc((250px + ${theme.spacing(1)}px) * 2)!important`,
        },

    }),
);

/** 整備項目マスタ　検索項目の Props を提供します。 */
interface Props {
    /** 初期値を表します。 */
    initialValues: MaintenanceItemInputItem,
    /** 追加ボタンのクリック処理を行います。 */
    onClickAdd: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 更新ボタンのクリック処理を行います。 */
    onClickUpdate: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 削除ボタンのクリック処理を行います。 */
    onClickRemove: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** キャンセルボタンのクリック処理を行います。 */
    onClickCancel: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 整備グループ一覧を表します。*/
    machineTypeList: KeyValueItem<number, string>[],
    /** 整備区分一覧を表します。 */
    maintenanceDivisionList: KeyValueItem<number, string>[],
    /** 新規作成状態かどうかを表します。 */
    isCreating: boolean,
    /** 入力値の変更処理を表します。 */
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MaintenanceItemInputItem) => void,
    /** 連動セレクトボックスの入力値の変更処理を表します。 */
    onChangeSelectBox: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MaintenanceItemInputItem) => void,
    /** バリデーション結果を表します。 */
    validateError: MaintenanceValidateItemInputItem,
};

/**
 * 整備項目マスタ　詳細エリアを提供します。
 * */
const MaintenanceItemDetailCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    // 表示
    return (
        <>
            <Box padding={1}>
                <div>

                    {/* 施工機種別 */}
                    <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        SelectProps={{
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }
                        }}
                        className=""
                        label="施工機種別"
                        placeholder="施工機種別"
                        value={props.initialValues.machineTypeId ?? ""}
                        onChange={(event) => props.onChangeSelectBox(event, "machineTypeId")}
                        disabled={!props.isCreating}
                        required
                        error={!props.validateError.machineType === false}
                        helperText={props.validateError.machineType}
                    >
                        {/*　選択肢 */}
                        {props.machineTypeList.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                                {option.value}
                            </MenuItem>
                        )
                        )}

                    </TextField >

                    {/* 整備区分 */}
                    <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        SelectProps={{
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }
                        }}
                        className=""
                        label="整備区分"
                        value={props.initialValues.maintenanceDivisionId ?? ""}
                        onChange={(event) => props.onChange(event, "maintenanceDivisionId")}
                        disabled={!props.isCreating}
                        required
                        error={!props.validateError.maintenanceDivision === false}
                        helperText={props.validateError.maintenanceDivision}
                    >
                        {/*　選択肢 */}
                        {props.maintenanceDivisionList.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                                {option.value}
                            </MenuItem>
                        )
                        )}

                    </TextField >

                    {/* 重み */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        type="number"
                        label="No."
                        value={props.initialValues.weight ?? ""}
                        onChange={(event) => props.onChange(event, "weight")}
                        InputProps={{
                            inputProps: {
                                min: MuiTextFieldUtility.NumericInputMinimum,
                                max: MuiTextFieldUtility.NumericInputMaximum,
                            }
                        }}
                        required
                        error={!props.validateError.weight === false}
                        helperText={props.validateError.weight}

                    />

                    {/* 整備項目名 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        label="整備項目名"
                        value={props.initialValues.name ?? ""}
                        onChange={(event) => props.onChange(event, "name")}
                        className={classes.inputItem}
                        required
                        error={!props.validateError.maintenanceItem === false}
                        helperText={props.validateError.maintenanceItem}

                    />

                    {/* 単位 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        label="単位"
                        value={props.initialValues.unit ?? ""}
                        onChange={(event) => props.onChange(event, "unit")}
                    />
                </div>
                <div>
                    {/* 入力値下限 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        type="number"
                        label="入力値下限"
                        value={props.initialValues.inputLowerLimit ?? ""}
                        onChange={(event) => props.onChange(event, "inputLowerLimit")}
                        InputProps={{
                            inputProps: {
                                min: MuiTextFieldUtility.NumericInputMinimum,
                                max: MuiTextFieldUtility.NumericInputMaximum,
                            }
                        }}
                    />

                    {/* 入力値上限 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        type="number"
                        label="入力値上限"
                        value={props.initialValues.inputHigherLimit ?? ""}
                        onChange={(event) => props.onChange(event, "inputHigherLimit")}
                        InputProps={{
                            inputProps: {
                                min: MuiTextFieldUtility.NumericInputMinimum,
                                max: MuiTextFieldUtility.NumericInputMaximum,
                            }
                        }}
                    />

                    {/* 基準値軽アラーム下限 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        type="number"
                        label="軽アラーム下限"
                        value={props.initialValues.lightAlarmLowerLimit ?? ""}
                        onChange={(event) => props.onChange(event, "lightAlarmLowerLimit")}
                        InputProps={{
                            inputProps: {
                                min: MuiTextFieldUtility.NumericInputMinimum,
                                max: MuiTextFieldUtility.NumericInputMaximum,
                            }
                        }}
                    />

                    {/* 基準値軽アラーム上限 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        type="number"
                        label="軽アラーム上限"
                        value={props.initialValues.lightAlarmHigherLimit ?? ""}
                        onChange={(event) => props.onChange(event, "lightAlarmHigherLimit")}
                        InputProps={{
                            inputProps: {
                                min: MuiTextFieldUtility.NumericInputMinimum,
                                max: MuiTextFieldUtility.NumericInputMaximum,
                            }
                        }}
                    />

                    {/* 基準値重アラーム下限 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        type="number"
                        label="重アラーム下限"
                        value={props.initialValues.heavyAlarmLowerLimit ?? ""}
                        onChange={(event) => props.onChange(event, "heavyAlarmLowerLimit")}
                        InputProps={{
                            inputProps: {
                                min: MuiTextFieldUtility.NumericInputMinimum,
                                max: MuiTextFieldUtility.NumericInputMaximum,
                            }
                        }}
                    />

                    {/* 基準値重アラーム上限 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        type="number"
                        label="重アラーム上限"
                        value={props.initialValues.heavyAlarmHigherLimit ?? ""}
                        onChange={(event) => props.onChange(event, "heavyAlarmHigherLimit")}
                        InputProps={{
                            inputProps: {
                                min: MuiTextFieldUtility.NumericInputMinimum,
                                max: MuiTextFieldUtility.NumericInputMaximum,
                            }
                        }}
                    />
                </div>
                <div className={classes.rowContainer}>
                    {/* 備考 */}
                    <TextField
                        variant="outlined"
                        margin="dense"
                        color="secondary"
                        multiline
                        rows={2}
                        className={classes.textarea}
                        label="備考"
                        value={props.initialValues.remarks ?? ""}
                        onChange={(event) => props.onChange(event, "remarks")} />
                </div>

                {/* ボタングループ */}
                <MasterDetailButtonGroup
                    isCreating={props.isCreating}
                    onClickAdd={props.onClickAdd}
                    onClickCancel={props.onClickCancel}
                    onClickUpdate={props.onClickUpdate}
                    onClickRemove={props.onClickRemove}
                />

            </Box>
        </>
    );
};

export default MaintenanceItemDetailCondition;
