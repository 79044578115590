// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MethodMasterSlice, onExcuteAsync } from '../../../../../modules/slice/method/method-slice';
import { MethodAdapter } from '../../../../../modules/adapter/method/method-adapter';
// type
import { MethodInputItem, MethodValidateInputItem } from '../../_types';
// component
import MethodDetailCondition from './MethodDetailCondition';

/**
 * 工法マスタ　詳細エリアを提供します。
 * */
export const MethodDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.methodMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.methodMaster.selectedRowItem);

    const initialValues: MethodInputItem = {
        id: selectedItem?.id,
        method: selectedItem?.method,
        lockVersion: selectedItem?.lockVersion,
    }

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<MethodValidateInputItem>({});

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<MethodInputItem>(initialValues);

    // 行選択時の入力項目更新
    useEffect(() => {
        setValues(preValue => ({
            ...preValue,
            id: selectedItem?.id,
            method: selectedItem?.method,
            lockVersion: selectedItem?.lockVersion,
        }))

        // バリデーション情報クリア
        clearValidate();

    }, [selectedItem]);

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MethodInputItem) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? Number.parseInt(target.value, 10) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /** 追加ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = MethodAdapter.instance.addAsync(
            { item: values },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = MethodAdapter.instance.updateAsync(
            { item: values },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        // ダイアログ表示
        dispatch(MethodMasterSlice.actions.toggleDialog(true));

    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(MethodMasterSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }

    /**
    * 入力内容のバリデーションを行います。
    * @returns 検証結果を返します。
    * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let method = "";

        // 型式
        if (!values.method) {
            method = "工法を入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            method,
        });

        // 結果
        return result;
    }

    // 表示
    return (
        <MethodDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            onChange={onChange}
            validateError={validate}
        />
    );
};
