import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { PageType } from '../../../../../modules/slice/global-header/global-header-slice';
import Title from './Title';

/** タイトルを表示します。*/
export const TitleContainer: React.FC<{}> = () => {

    // Global State
    const title = useSelector((state: RootState) => state.globalHeader.title);
    const machine = useSelector((state: RootState) => state.globalHeader.machine);
    const pageType: PageType = useSelector((state: RootState) => state.globalHeader.pageType);

    /**
     * 施工機情報を表示する画面かどうかを判定します。
     * @param type
     */
    const isShowMachine = (type: PageType) => {
        let result = false;
        switch (type) {
            case PageType.Home:
            case PageType.Master:
            case PageType.Report:
                result = false;
                break;
            case PageType.Record:
                result = true;
                break;
            default:
                result = false;
        }
        return result;
    }

    return (
        <Title
            title={title}
            machine={machine}
            isShowMachine={isShowMachine(pageType)}
        />
    );
}
