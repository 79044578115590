import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
    AreaChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    ResponsiveContainer,
    Brush,
    Label,
} from 'recharts';

// redux
import { GraphContentUtility } from './GraphContetntUtility'
import { MachineOperationHistoryGraphItem, MachineOperationHistoryGraphDisplayData, GraphOptionString } from '../../_types';

/** 施工記録画面グラフの Props を提供します。 */
interface ConstructionRecordGraphProps {
    dataSource: MachineOperationHistoryGraphItem[]
    /** 左軸で描画する項目を表します。*/
    leftY?: GraphOptionString,
    /** 右軸で描画する項目を表します。*/
    rightY?: GraphOptionString,
};

export const ConstructionRecordGraph: React.FC<ConstructionRecordGraphProps> = (props) => {

    /** テーマを提供します。 */
    const theme = useTheme();

    const [graphData, setGraphData] = useState<MachineOperationHistoryGraphDisplayData[]>([]);

    useEffect(() => {

        setGraphData((GraphContentUtility.toGraphData(props.dataSource)))

    }, [props.dataSource])

    return (
        <ResponsiveContainer height={550} width={"99%"}>
            {
                props.dataSource == null
                    ? (<>表示するデータがありません</>)
                    : (
                        <AreaChart
                            style={{ minWidth: 200 }}
                            data={graphData}
                            margin={{ top: 50, right: 50, left: 50, bottom: 50 }}
                        >
                            {/* 格子 */}
                            <CartesianGrid vertical={false} strokeDasharray="3 3" />


                            {/* X軸 */}
                            <XAxis
                                dataKey="dateAt"
                                type="number"
                                scale="time"
                                domain={["dataMin", "dataMax"]}
                                tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                                stroke={theme.palette.text.primary}
                                interval={0}
                                tick={{
                                    fontSize: "0.75rem",
                                    wordWrap: "break-word",
                                    fill: theme.palette.text.secondary,
                                }}
                                angle={-35}
                                textAnchor="end"
                            />

                            {/* ツールチップ */}
                            <Tooltip
                                labelStyle={{
                                    color: theme.palette.grey[900]
                                }}
                                labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                                formatter={(value, name, entry) => {

                                    // エラー情報は改行して箇条書き表示
                                    if (entry.dataKey === "errors" && typeof value === "string") {
                                        return (
                                            <>{
                                                value.split(/\r\n|\n|\r/).map((text, index) => (
                                                    <div key={index}>・{text}</div>
                                                ))
                                            }</>
                                        )
                                    } else {
                                        return <>{value}</>
                                    }
                                }
                                }
                            />

                            {/* ブラシ(下のバー) */}
                            {/* データが0の場合エラーになる */}
                            {props.dataSource.length > 0 &&
                                <Brush
                                    dataKey={"dateAt"}
                                    height={30}
                                    y={500}
                                    tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                                />
                            }

                            {/* エリアのグラデーション */}
                            {GraphContentUtility.GradientLine(GraphContentUtility.constantString.engineOperatingTime)}
                            {GraphContentUtility.GradientLine(GraphContentUtility.constantString.liftingDistance)}
                            {GraphContentUtility.GradientLine(GraphContentUtility.constantString.engineRotationTimeAvg)}
                            {GraphContentUtility.GradientLine(GraphContentUtility.constantString.batteryVoltageAvg)}
                            {GraphContentUtility.GradientLine(GraphContentUtility.constantString.liftingPressureMax)}
                            {GraphContentUtility.GradientLine(GraphContentUtility.constantString.oilTemperatureAvg)}


                            {/* 左Y軸 */}
                            <YAxis
                                domain={['auto', 'auto']}
                                yAxisId="left"
                                tick={{
                                    fill: props.leftY ? props.leftY.color : "",
                                    stroke: props.leftY ? props.leftY.color : "",
                                }}
                                stroke={props.leftY ? props.leftY.color : ""}
                            >
                                <Label
                                    value={props.leftY ? `${props.leftY.name} [${props.leftY.unit}]` : ""}
                                    fill={props.leftY ? props.leftY.color : ""}
                                    position={"left"}
                                    angle={-90}
                                    dx={-30}
                                    //textAnchor={"middle"} 無視されるので style で上書き
                                    style={{ textAnchor: "middle" }}
                                />
                            </YAxis>

                            {/* 右Y軸 */}
                            <YAxis
                                orientation="right"
                                domain={['auto', 'auto']}
                                yAxisId="right"
                                tick={{
                                    fill: props.rightY ? props.rightY.color : "",
                                    stroke: props.rightY ? props.rightY.color : "",
                                }}
                                stroke={props.rightY ? props.rightY.color : ""}
                            >
                                <Label
                                    value={props.rightY ? `${props.rightY.name} [${props.rightY.unit}]` : ""}
                                    fill={props.rightY ? props.rightY.color : ""}
                                    position={"right"}
                                    angle={-90}
                                    dx={30}
                                    //textAnchor={"middle"} 無視されるので style で上書き
                                    style={{ textAnchor: "middle" }}
                                />
                            </YAxis>

                            {/* 左軸で描画する項目 */}
                            {/* エリア */}
                            {props.leftY &&
                                <Area
                                    key={"leftY"}
                                    yAxisId="left" // このIDを持つ YAxis が必要
                                    type="linear"
                                    animationEasing="ease"
                                    animationDuration={1000}
                                    strokeWidth={3}
                                    dataKey={props.leftY.dataKey}
                                    name={props.leftY.name}
                                    unit={props.leftY.unit}
                                    stroke={props.leftY.color}
                                    fill={`url(#${props.leftY.htmlId})`}
                                    dot={GraphContentUtility.CustomizedDot}
                                    activeDot={GraphContentUtility.CustomizedActiveDot}
                                />
                            }


                            {/* 右軸で描画する項目 */}
                            {/* エリア */}
                            {props.rightY &&
                                <Area
                                    key={"rightY"}
                                    dataKey={props.rightY.dataKey}
                                    name={props.rightY.name}
                                    unit={props.rightY.unit}
                                    stroke={props.rightY.color}
                                    fill={`url(#${props.rightY.htmlId})`}
                                    yAxisId="right"
                                    type="linear"
                                    animationEasing="ease"
                                    animationDuration={1000}
                                    strokeWidth={3}
                                    dot={GraphContentUtility.CustomizedDot}
                                    activeDot={GraphContentUtility.CustomizedActiveDot}
                                />
                            }


                            {/* エラー情報 */}
                            <Area
                                id="error"
                                key={"error"}
                                yAxisId="left"
                                type="linear"
                                dataKey="errors"
                                name="エラー情報"
                                stroke={"red"}
                            />

                        </AreaChart>
                    )
            }
        </ResponsiveContainer >
    );
}
