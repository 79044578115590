import * as React from 'react';
import { MachineType, Machine } from '../../../../../_types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        //　タイトル
        title: {
            fontSize: "1.5rem",
            minWidth: "200px",
            textAlign: "center",
            overflowWrap: "break-word",
        },
    }),
);

/** タイトルの Props を表します。 */
interface Props {
    /** 画面タイトルを表します。*/
    title: string
    /** 施工機情報を表します。 */
    machine?: Machine
    /** 施工機情報を表示するかどうかを表します。 */
    isShowMachine: boolean
}

/** タイトルを表示します。 */
const Title: React.FC<Props> = (props) => {

    const classes = useStyles();

    /**
     * 施工機情報を表示します。
     * @param machine
     */
    const renderMachine = (machine: Machine, title: string) => {
        let typeName = "";

        switch (machine.type) {
            case MachineType.BaseMachine:
                typeName = "ベースマシン";
                break;
            case MachineType.Auger:
                typeName = "オーガー";
                break;
            case MachineType.Lift:
                typeName = "昇降装置";
                break;
        }
        // レンダー
        return (
            <>
                {/* 施工機種別 */}
                <Box component="span" ml={1}>
                    {typeName}
                </Box>
                {/* 画面タイトル */}
                <Box component="span" ml={1}>
                    {title}
                </Box>
                {/* 社番 */}
                <Box component="span" ml={1}>
                    【{machine.item.id}】
                    </Box>
                {/* メーカー / 型式 */}
                <Box component="span" ml={1}>
                    {`[${machine.item.manufacturer} / ${machine.item.model}]`}
                </Box>
            </>
        )
    }

    return (
        <Typography className={classes.title}>
            {(props.machine && props.isShowMachine) // 施工機情報を表示するかどうか
                ? (
                    <>
                        {/* 画面タイトル & 施工機情報を表示 */}
                        {renderMachine(props.machine, props.title)}
                    </>
                )
                : (
                    <>
                        {/* 画面タイトルのみ表示 */}
                        <Box component="span" ml={1}>
                            {props.title}
                        </Box>

                    </>
                )
            }
        </Typography>
    );
}

export default Title;
