// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MachineMasterSlice, onExcuteAsync } from '../../../../../modules/slice/machine/machine-slice';
import { MachineAdapter } from '../../../../../modules/adapter/machine/machine-adapter'
// type
import { MachineInputItem, MachineValidateInputItem } from '../../_types';
// component
import MachineDetailCondition from './MachineDetailCondition'
import { ConnectedCtuType } from '../../../../../_types';


/** ステータス編集ダイアログの State を表示します。 */
export interface EditDialogState {
    /** 施工機種別を表します */
    施工機種別: string,
    /** パスワードを表します */
    password?: string,
}

/**
 * 施工機マスタ　詳細エリアを提供します。
 * */
export const MachineDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.machineMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.machineMaster.selectedRowItem);
    // redux store
    const typeList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.MachineType);
    const modelList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.Model);
    const manufacturerList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.Manufacturer);
    const ctuIdList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.CtuId);
    const maintenanceCtuIdList = useSelector((state: RootState) => state.machineMaster.selectBoxItem.MaintenanceCtuId);

    const initialValues: MachineInputItem = {
        id: selectedItem?.id,
        ctuId: selectedItem?.ctuId,
        maintenanceCtuId: selectedItem?.maintenanceCtuId,
        type: selectedItem?.typeId,
        model: selectedItem?.modelId,
        manufacturer: selectedItem?.manufacturerId,
        modelYear: selectedItem?.modelYear ? new Date(selectedItem?.modelYear) : void 0,
        lockVersion: selectedItem?.lockVersion,
    }

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<MachineValidateInputItem>({});


    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<MachineInputItem>(initialValues);

    // 行選択時の入力項目更新
    useEffect(() => {
        setValues(preValue => ({
            ...preValue,
            id: selectedItem?.id,
            ctuId: selectedItem?.ctuId,
            maintenanceCtuId: selectedItem?.maintenanceCtuId,
            type: selectedItem?.typeId,
            model: selectedItem?.modelId,
            manufacturer: selectedItem?.manufacturerId,
            modelYear: selectedItem?.modelYear ? new Date(selectedItem?.modelYear) : void 0,
            connectedCtu: selectedItem?.connectedCtu,
            lockVersion: selectedItem?.lockVersion,
        }))

        // バリデーション情報クリア
        clearValidate();

    }, [selectedItem]);

    /**
     * 入力値の変更処理を行います。
     * @param name　入力項目を指定します。
     */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MachineInputItem) => {
        // 値を選別
        // 型
        const input = event as React.ChangeEvent<HTMLInputElement>

        const target = event.target;

        const value = target.type === "number" ? Number.parseInt(target.value, 10)
            : target.type === "checkbox" && name === "connectedCtu" ? toConnectedCtu(input.target.checked)
                : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /**
     * CTUありなし情報に変換します。
     * @param value
     */
    const toConnectedCtu = (value: boolean): ConnectedCtuType => value ? ConnectedCtuType.Connected : ConnectedCtuType.Disconnected;


    /**
    *  日付の変更時の処理を行います。
    * @param newValue
    */
    const onChangeDate = (newValue: Date | null, name: keyof MachineInputItem) => {

        if (newValue != null) {
            newValue.setDate(1); // 日付を 1 に設定
            newValue.setHours(0, 0, 0, 0); // 時間を ゼロ(00:00:000) に設定
        }

        setValues(preValues => (
            {
                ...preValues,
                modelYear: newValue,
            }
        ))
    };

    /** 追加ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = MachineAdapter.instance.addAsync(
            { item: values },
            {
                MachineType: typeList,
                Model: modelList,
                Manufacturer: manufacturerList,
                CtuId: ctuIdList,
                MaintenanceCtuId: maintenanceCtuIdList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = MachineAdapter.instance.updateAsync(
            { item: values },
            {
                MachineType: typeList,
                Model: modelList,
                Manufacturer: manufacturerList,
                CtuId: ctuIdList,
                MaintenanceCtuId: maintenanceCtuIdList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        //　ダイアログ表示
        dispatch(MachineMasterSlice.actions.toggleDialog(true));
    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(MachineMasterSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }

    /**
     * 入力内容のバリデーションを行います。
     * @returns 検証結果を返します。
     * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let id = "";
        let type = "";
        let model = "";
        let manufacturer = "";
        let modelYear = "";

        // 施工機種別
        if (!values.type) {
            type = "施工機種別を選択してください";
            result = true;
        }

        // 社番
        if (!values.id) {
            id = "社番を入力してください";
            result = true;
        }

        // 型式
        if (!values.model) {
            model = "型式を入力してください";
            result = true;
        }

        // メーカー
        if (!values.manufacturer) {
            manufacturer = "メーカーを選択してください";
            result = true;
        }

        // 年式
        if (!values.modelYear) {
            modelYear = "年式を入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            type,
            id,
            model,
            manufacturer,
            modelYear,
        });

        // 結果
        return result;
    }



    // 表示
    return (
        <MachineDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            typeList={typeList}
            modelList={modelList}
            manufacturerList={manufacturerList}
            ctuIdList={ctuIdList}
            maintenanceCtuIdList={maintenanceCtuIdList}
            onChange={onChange}
            onChangeDate={onChangeDate}
            validateError={validate}
        />
    );
};
