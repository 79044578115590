// react
import * as React from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            justifyContent: "flex-end",
        },
        // バージョン情報
        lastUpdatedAt: {
            fontSize: "1.25rem",
            margin: "0 0.5rem",

        },
        // 更新ボタン
        button: {
            minWidth: 0
        }
    }),
);

/** 更新エリア の Props を提供します。 */
interface Props extends ButtonProps {
    /** 最終更新日時を表します。 */
    lastUpdatedAt: string,
};

/** 更新エリアを表示します。 */
const UpdateItem: React.FC<Props> = (props) => {
    const { lastUpdatedAt, ...buttonProps } = props;

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography>
                最終更新日時：
            </Typography>

            <Box className={classes.lastUpdatedAt}>
                {lastUpdatedAt}
            </Box>

            {/* 更新ボタン */}
            <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.button}
                onClick={buttonProps.onClick}
                {...buttonProps}
            >
                <SyncIcon />
            </Button>
        </Box>
    );

};

export default UpdateItem;
