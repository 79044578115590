import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { onOpenDialog } from '../../../../../modules/slice/global-header/global-header-slice';
import { PageType } from '../../../../../modules/slice/global-header/global-header-slice';
import ErrorPopover from './ErrorPopover';

/** エラーポップボタンを表示します。 */
export const ErrorPopoverContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // Global State
    const machineIds = useSelector((state: RootState) => state.globalHeader.ctuErrorMachine.errorMachineIds);
    const isExistMachineError = useSelector((state: RootState) => state.globalHeader.ctuErrorMachine.isExistMachineError);
    const pageType: PageType = useSelector((state: RootState) => state.globalHeader.pageType);

    /**
     * エラーポップアップボタンを表示する画面かどうかを判定します。
     * @param type
     */
    const isShowCtuError = (type: PageType) => {
        let result = false;
        switch (type) {
            case PageType.Home:
            case PageType.Master:
            case PageType.Report:
                result = true;
                break;
            case PageType.Record:
                result = false;
                break;
            default:
                result = false;
        }
        return result;
    }


    /** 表示リセットボタンクリック時の処理を行います。 */
    const onResetClick = () => {

        dispatch(onOpenDialog());
    }

    return (
        <ErrorPopover
            isExistMachineError={isExistMachineError}
            machineIds={machineIds}
            onRestClick={onResetClick}
            isShowCtuError={isShowCtuError(pageType)}
        />
    )

}
export default ErrorPopover;
