// react
import * as React from 'react';
// react-image-gallery
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.css";


/** その他報告画像一覧の Props を提供します。*/
interface Props {
    /** 画像のパス一覧を表します。 */
    imagePaths: string[],
}

/**
 * その他報告画像一覧を表示します。
 * */
export const ReportImageGallery = (props: Props) => {

    /**
     * 画像表示用データに変換します。
     * @param imagePaths
     */
    const ConvertToReactImageGalleryItem = (imagePaths: string[]) => {

        const result = imagePaths.map<ReactImageGalleryItem>((path) => {
            return {
                original: path,
                thumbnail: path,
            }
        })
        return result;
    }

    return (
        <ImageGallery
            items={ConvertToReactImageGalleryItem(props.imagePaths)}
            showBullets={true}
            showPlayButton={false}
            showIndex={true}
            thumbnailPosition={"right"}
            useBrowserFullscreen={false}
            disableThumbnailScroll={true}
        />
    )
}

export default ReportImageGallery
