// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeRecordPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { MachineOperationHistorySlice, onGetAsync } from '../../../modules/slice/machine-operation-history/machine-operation-history-slice'
// component
import { ConstructionRecordMain } from './components/MainContent/ConstructionRecordMain';
import { ConstructionRecordSerchContainer } from './components/SearchContent/ConstructionRecordSearchContainer';
import { ConstructionRecordCardListContainer } from './components/CardContent/ConstructionRecordCardListContainer';
import { ConstructionRecordGraphContainer } from './components/GraphContent/ConstructionRecordGraphContainer';
import { DownloadButtonContainer } from './components/DownloadContent/DownloadButtonContainer';

// 共通component
import TwoColumnLayout from '../../../components/Layout/TwoColumnLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';


/**
 * 施工記録画面を提供します。
 * */
export const ConstructionRecord: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.machineOperationHistory.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.machineOperationHistory.isShowMessage);
    const message = useSelector((state: RootState) => state.machineOperationHistory.message);
    const machine = useSelector((state: RootState) => state.globalHeader.machine)

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(MachineOperationHistorySlice.actions.hideMessage());
    };

    useEffect(() => {
        // タイトル変更
        dispatch(changeRecordPageHeader((AppTitle.recordConstructionRecord.title)));

        // 初回マウント時に直近30日分のデータ取得を行う。
        /** 初期表示時に入力値に入れる値は SearchContent で設定しているので注意する */
        const beginAt = new Date();
        beginAt.setMonth(beginAt.getMonth() - 1);
        beginAt.setHours(0, 0, 0, 0);

        const endAt = new Date();
        endAt.setHours(0, 0, 0, 0);

        if (!machine) {
            dispatch(MachineOperationHistorySlice.actions.showErrorMessage("施工機情報が指定されていません"));
        } else {
            dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    beginAt: beginAt,
                    endAt: endAt,
                }
            }))
        }

    }, [dispatch, machine]);

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* 施工記録 */}
            < TwoColumnLayout
                // 検索エリア
                side={<ConstructionRecordSerchContainer />}
                // 検査結果
                main={<ConstructionRecordMain
                    csvButton={<DownloadButtonContainer />}
                    cards={<ConstructionRecordCardListContainer />}
                    graph={<ConstructionRecordGraphContainer />}
                />}
            />


        </>


    );
};
