//react
import * as React from 'react';
import { useState, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { StatusEditSlice, onGetReloadAsync, onOpenEditDialog, onOpenHistoryDialog } from '../../../../../modules/slice/status-edit/status-edit-slice';

// type
import { MachineMaintenanceSettingItem, EditItem } from '../../_types';
// component
import StatusEditMain from './StatusEditMain';

/**
 * ステータス編集画面を表示します。
 */
export const StatusEditMainContainer: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine);
    const lastUpdatedAt = useSelector((state: RootState) => state.statusEdit.result.getStatusResult?.lastUpdatedAt ?? "");
    const statusData = useSelector((state: RootState) => state.statusEdit.result.getStatusResult?.item);
    const updateData = useSelector((state: RootState) => state.statusEdit.result.getClientRequestResult?.item);


    /** 更新ボタンのクリック処理を行います。 */
    const onUpdateClick = async () => {

        if (!machine) {
            dispatch(StatusEditSlice.actions.showErrorMessage(`施工機情報がありません。`));
            return;
        }

        await dispatch(onGetReloadAsync({ id: machine.item.id }))
    }

    /** 編集ボタンのクリック処理を行います。 */
    const onEditClick = (item: EditItem) => {
        dispatch(onOpenEditDialog(item));
    }

    /** 履歴ボタンのクリック処理を行います。 */
    const onHistoryClick = (item: EditItem) => {
        dispatch(onOpenHistoryDialog(item));
    }

    return (
        <StatusEditMain
            statusData={statusData}
            updateData={updateData}
            lastUpdatedAt={lastUpdatedAt}
            onUpdateClick={onUpdateClick}
            onEditClick={onEditClick}
            onHisrotyClick={onHistoryClick}
        />

    );
}
