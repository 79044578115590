import * as React from 'react';
import { Layout } from './components/Layout/Layout';
import { GlobalHeader } from '../GlobalHeader/GlobalHeader';

/**
 * 共通ヘッダーのあるページを表します。
 * @param param0
 */
export const HeaderPage: React.FC<{}> = ({ children }) => {

    return (
        <Layout
            header={<GlobalHeader />}
            children={children}
        />
    );
}
