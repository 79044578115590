// redux
import { Dispatch } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
// shared
import { ExtendGenericState, createExtendGenericSlice } from '../shared/create-extend-generic-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { changeError } from '../global-header/global-header-slice';
// type
import { EditItem } from '../../../pages/ConstructionMachineMaster/StatusEdit/_types';
import { MachineMaintenanceSettingResult, MachineStatusMonitoringRequest, MachineMaintenanceSettingRequest, MachineMaintenanceSettingRestHistoryRequest, MachineMaintenanceSettingResetHistoryResult } from '../../adapter/machine-status-monitoring/types';
import { MachineStatusMonitoringAdapter } from '../../adapter/machine-status-monitoring/machine-status-monitoring-adapter';

/** ステータス編集 結果情報の State を表します。 */
interface StatusEditResultState {
    /** ステータス取得結果情報を表します。 */
    getStatusResult: MachineMaintenanceSettingResult,
    /** 制御要求一覧取得結果情報を表します。 */
    getClientRequestResult: MachineMaintenanceSettingResult,
    /** 選択されたリセット項目のリセット履歴情報を表します。 */
    getResetHistoryResult: MachineMaintenanceSettingResetHistoryResult,
}

/**ステータス編集 アイテム情報の State を表します。 */
interface StatusEditItemState {
    /** 編集ダイアログが開いているかどうか表します。 */
    isOpenEdit: boolean;
    /** 履歴ダイアログが開いているかどうか表します。 */
    isOpenHsitory: boolean;
    /** 編集する項目を表します。 */
    editItem?: EditItem,
    /** タイマーIDを表します。 */
    timerId: number;
}

/** リロードのインターバル時間[ms]を表します。 */
const ReloadTimerInterval = 60000;

// State の初期値を設定します。
const initialState: ExtendGenericState<StatusEditResultState, StatusEditItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        getStatusResult: {
            isError: false,
        },
        getClientRequestResult: {
            isError: false,
        },
        getResetHistoryResult: {
            isError: false,
        },
    },
    item: {
        isOpenEdit: false,
        isOpenHsitory: false,
        timerId: 0,
    }
}

/** ステータス編集 Slice を 提供します。*/
export const StatusEditSlice = createExtendGenericSlice({
    // State Reducer Action を生成
    name: 'StatusEditSlice',
    initialState: initialState,
    reducers: {

        /**
        * 通信終了時の処理を行います。
        * @param state
        * @param action
        */
        fetchEndStatusResult(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>, action: PayloadAction<MachineMaintenanceSettingResult>) {
            state.isLoading = false;
            state.result.getStatusResult = action.payload;
        },

        /**
        * 通信終了時の処理を行います。
        * @param state
        * @param action
        */
        fetchEndClientRequestResult(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>, action: PayloadAction<MachineMaintenanceSettingResult>) {
            state.isLoading = false;
            state.result.getClientRequestResult = action.payload;
        },

        /**
        * 通信終了時の処理を行います。
        * @param state
        * @param action
        */
        fetchEndResetHistoryResult(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>, action: PayloadAction<MachineMaintenanceSettingResetHistoryResult>) {
            state.isLoading = false;
            state.result.getResetHistoryResult = action.payload;
        },

        /**
         * 編集ダイアログを開きます。
         * @param state
         * @param action
        */
        openEditDialog(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>) {
            state.item.isOpenEdit = true;
        },

        /**
         * 履歴ダイアログを開きます。
         * @param state
         * @param action
        */
        openHistoryDialog(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>) {
            state.item.isOpenHsitory = true;
        },

        /**
         * ダイアログを閉じます。
         * @param state
         * @param action
        */
        closeDialog(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>) {
            state.item.isOpenEdit = false;
            state.item.isOpenHsitory = false;
        },

        /**
         * 編集ボタンクリック処理を行います。
         * @param state
         * @param action
        */
        changeEditItem(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>, action: PayloadAction<EditItem>) {
            state.item.editItem = action.payload;
        },

        /**
         * 更新タイマーを停止します。
         * @param state
         */
        stopReloadTimer(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>) {
            clearTimeout(state.item.timerId);
            state.item.timerId = 0;
        },
        /**
         * 更新タイマーを開始します。
         * @param state
         */
        startReloadTimer(state: ExtendGenericState<StatusEditResultState, StatusEditItemState>, action: PayloadAction<number>) {
            clearTimeout(state.item.timerId);
            state.item.timerId = action.payload;
        }
    },
})

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetAsync = (parameter: MachineStatusMonitoringRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(StatusEditSlice.actions.fetchStart());

        // 通信処理
        const getResultWaiter = MachineStatusMonitoringAdapter.instance.getMachineMaintenanceSettingItemAsync(parameter);
        const getClientRequestResultWaiter = MachineStatusMonitoringAdapter.instance.getClientRequestAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.id });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, getClientRequestResultWaiter, machineErrorResultWaiter]);
        const statusResult = promiseResult[0];
        const clientRequestResult = promiseResult[1];
        const machineErrorResult = promiseResult[2];

        if (statusResult.isError) {
            // ステータス取得エラー
            // メッセージ
            dispatch(StatusEditSlice.actions.showErrorMessage(statusResult.errorMessage ? `ステータス取得エラー: ${statusResult.errorMessage}` : ""));

        } else if (clientRequestResult.isError) {
            // 制御要求取得エラー
            // メッセージ
            dispatch(StatusEditSlice.actions.showErrorMessage(clientRequestResult.errorMessage ? `制御要求リスト取得エラー: ${clientRequestResult.errorMessage}` : ""));

        } else if (machineErrorResult.isError) {
            // CTUエラー取得エラー
            // メッセージ
            dispatch(StatusEditSlice.actions.showErrorMessage(machineErrorResult.errorMessage ? `CTUエラー取得エラー: ${machineErrorResult.errorMessage}` : ""));

        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 通信終了
            dispatch(StatusEditSlice.actions.fetchEndStatusResult(statusResult));
            dispatch(StatusEditSlice.actions.fetchEndClientRequestResult(clientRequestResult));

            // 正常
            // メッセージ
            dispatch(StatusEditSlice.actions.showSuccessMessage());

        }

    } catch (error) {
        // メッセージ
        dispatch(StatusEditSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(StatusEditSlice.actions.fetchEnd());
    }
}

/**
 * リセット履歴取得処理を行います。
 * @param parameter
 */
export const onGetRestHistoryAsync = (parameter: MachineMaintenanceSettingRestHistoryRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(StatusEditSlice.actions.fetchStart());

        // 通信処理
        const result = await MachineStatusMonitoringAdapter.instance.getResetHistoryAsync(parameter);

        if (result.isError) {
            // 取得エラー
            // メッセージ
            dispatch(StatusEditSlice.actions.showErrorMessage(result.errorMessage ? `リセット履歴取得エラー: ${result.errorMessage}` : ""));

        } else {

            // 通信終了
            dispatch(StatusEditSlice.actions.fetchEndResetHistoryResult(result));

            // 正常
            // メッセージ
            dispatch(StatusEditSlice.actions.showSuccessMessage());

        }

    } catch (error) {
        // メッセージ
        dispatch(StatusEditSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(StatusEditSlice.actions.fetchEnd());
    }
}

/**
 * 更新情報送信処理を行います。
 * @param parameter
 * @param password
 */
export const onUpdateAsync = (parameter: MachineMaintenanceSettingRequest, password: string) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(StatusEditSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(StatusEditSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));

        } else {
            // 正常
            // 要求情報送信実行
            const result = await MachineStatusMonitoringAdapter.instance.addOrUpdateClientRequestAsync(parameter);

            if (result.isError) {
                // 異常
                // メッセージ
                dispatch(StatusEditSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            } else {
                // 正常
                // メッセージ
                dispatch(StatusEditSlice.actions.showSuccessMessage());

                // 取得処理
                await dispatch<any>(onGetAsync(parameter));

                // ダイアログを閉じる
                dispatch<any>(onCloseDialog({ id: parameter.id }));
            }
        }

    } catch (error) {

        // メッセージ
        dispatch(StatusEditSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(StatusEditSlice.actions.fetchEnd());
    }

}

/**
 * 更新ボタンクリック処理を行います。
 * @param parameter
 * @param password
 */
export const onUpdateClickAsync = (parameter: MachineMaintenanceSettingRequest, password: string) => async (dispatch: Dispatch) => {

    // 制御要求処理
    await dispatch<any>(onUpdateAsync(parameter, password));

}


/** ダイアログクローズ処理を行います。 */
export const onCloseDialog = (parameter: MachineMaintenanceSettingRequest) => (dispatch: Dispatch) => {

    // ダイアログを閉じる
    dispatch(StatusEditSlice.actions.closeDialog());

    // タイマー開始
    dispatch<any>(startReloadTimer(parameter));
}


/** 編集ダイアログのオープン処理を行います。 */
export const onOpenEditDialog = (item: EditItem) => (dispatch: Dispatch) => {
    // タイマー停止
    dispatch<any>(stopReloadTimer());
    // 選択項目取得
    dispatch(StatusEditSlice.actions.changeEditItem(item));
    // ダイアログを開く
    dispatch(StatusEditSlice.actions.openEditDialog());
}


/** 履歴ダイアログのオープン処理を行います。 */
export const onOpenHistoryDialog = (item: EditItem) => (dispatch: Dispatch) => {
    // タイマー停止
    dispatch<any>(stopReloadTimer());
    // 選択項目取得
    dispatch(StatusEditSlice.actions.changeEditItem(item));
    // ダイアログを開く
    dispatch(StatusEditSlice.actions.openHistoryDialog());
}


/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetReloadAsync = (parameter: MachineStatusMonitoringRequest) => async (dispatch: Dispatch) => {

    try {
        // タイマー停止
        dispatch<any>(stopReloadTimer());
        // 実行
        await dispatch<any>(onGetAsync(parameter));

    } catch (e) {
        //何もしない

    } finally {
        // タイマー開始
        dispatch<any>(startReloadTimer(parameter));
    }

}

/** リロードタイマーを停止します。 */
const stopReloadTimer = () => (dispatch: Dispatch) => {

    // タイマー停止
    dispatch(StatusEditSlice.actions.stopReloadTimer());
}


/** リロードタイマーを開始します。  */
const startReloadTimer = (parameter: MachineStatusMonitoringRequest) => (dispatch: Dispatch) => {

    // タイマー開始
    const timer = window.setTimeout(() => dispatch<any>(onGetReloadAsync(parameter)), ReloadTimerInterval)
    dispatch(StatusEditSlice.actions.startReloadTimer(timer));
}
