import { DailyInspectionRecordListItem, DailyInspectionRecordSearchType, StampSearchType } from '../../../pages/ConstructionMachineMaster/DailyInspectionRecord/_types';
import { SharedUtility } from '../../shared/shared-utility';
import { DailyInspectionRecordItem, DailyInspectionRecordSearchType as DtoSearchType, MachineType as DtoMachineType } from '../../swagger-clients';

/**
 * `日常点検記録` 関連の ユーティリティ機能を提供します。
 */
export class DailyInspectionRecordUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: DailyInspectionRecordItem[]): DailyInspectionRecordListItem[] {
        const result: DailyInspectionRecordListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            const dateAt = source.inspectionAt == null ? "" : SharedUtility.toDateString(new Date(source.inspectionAt));
            const item: DailyInspectionRecordListItem = {
                no: source.reportNo ?? "",
                completionDate: dateAt,
                constructionMethod: source.method ?? "",
                worker: source.worker ?? "",
                site: source.site ?? "",
                report: source.report ?? "",
                area: SharedUtility.toAreaText(source.areaId),
                areaType: SharedUtility.toAreaType(source.areaId),
                isStamped: source.isInspected
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 検索キーワード種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toSearchType(source: DailyInspectionRecordSearchType): DtoSearchType {
        const result: DtoSearchType | undefined =
            source === DailyInspectionRecordSearchType.None ? DtoSearchType.None :
            source === DailyInspectionRecordSearchType.Worker ? DtoSearchType.Worker :
            source === DailyInspectionRecordSearchType.Place ? DtoSearchType.Site :
            source === DailyInspectionRecordSearchType.Report ? DtoSearchType.Report :
            void 0;
        if (result == null) {
            throw new Error("toSearchType");
        }
        return result;
    }

    /**
     *  検印の検索種別を変換します。
     * @param source　変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toisInspected(source: StampSearchType): boolean | undefined {
        const result: boolean | undefined =
            source === StampSearchType.Done ? true :
            source === StampSearchType.NotYet ? false :
            void 0;
        return result;
    }

    //#endregion メソッド
}
