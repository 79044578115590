import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { StatusEditSlice, onCloseDialog, onGetRestHistoryAsync } from '../../../../../modules/slice/status-edit/status-edit-slice';
import { ResetHistoryTableContainer } from '../ResetHistoryTable/ResetHistoryTableContainer';
import HistoryDialog from './HistoryDialog';
/**
 * リセット履歴ダイアログを表示します。
 * @param props
 */
export const HistoryDialogContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isOpen = useSelector((state: RootState) => state.statusEdit.item.isOpenHsitory);
    const editItem = useSelector((state: RootState) => state.statusEdit.item.editItem);
    const machine = useSelector((state: RootState) => state.globalHeader.machine);

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        if (machine?.item?.id == null) {
            dispatch(StatusEditSlice.actions.showErrorMessage("施工機情報がありません。"));
            return;
        }

        dispatch(onCloseDialog({ id: machine.item.id }));
    };

    useEffect(() => {

        if (isOpen) {
            if (!machine) {
                dispatch(StatusEditSlice.actions.showErrorMessage("施工機情報が指定されていません"));
            } else if (!editItem) {
                dispatch(StatusEditSlice.actions.showErrorMessage("リセット項目が指定されていません"));
            } else {

                dispatch(onGetRestHistoryAsync({
                    id: machine.item.id,
                    machineDataType: editItem.type
                }))
            }
        }
    }, [dispatch, machine, editItem, isOpen]);


    return (
        <>
            {editItem &&
                <HistoryDialog
                    isOpen={isOpen}
                    editItem={editItem}
                    handleClose={closeDialog}
                    table={<ResetHistoryTableContainer />}

                />
            }
        </>
    );
}
