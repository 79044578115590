import * as React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import amber from '@material-ui/core/colors/amber';
 
/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // primary
        primary: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.primary.dark
            },
        //    "&:disabled": {
        //        // opacity: 0.4,
        //        backgroundColor: theme.palette.primary.light
        //    }
        },
        // secondary
        secondary: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.secondary.dark
            },
            //"&:disabled": {
            //    backgroundColor: theme.palette.secondary.light
            //}
        },
        // error
        error: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.error.dark
            },
            //"&:disabled": {
            //    backgroundColor: theme.palette.action.disabledBackground,
            //    //backgroundColor: theme.palette.error.light
            //}
        },
        // warning
        warning: {
            backgroundColor: amber[500],
            color: '#fff',
            "&:hover": {
                backgroundColor: '#b28704',
            },
            //"&:disabled": {
            //    backgroundColor: '#ffcd38',
            //}
        }


    }),
);

/**
 *  上書きカラーの タイプ を 提供します。
 */
type OverrideColor = | "primary" | "secondary" | "error" | "warning";


/** 拡張ボタン Props を提供します。*/
interface ExtendsButtonProps extends ButtonProps {
    /** 上書きするカラーを表します。*/
    overrideColor?: OverrideColor;
}

/**
 * カラー上書き ボタンを表示します。
 * @param props
 */
//  独自に追加したProps は 分割代入する `styled-components` https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
const MuiColorButton: React.FC<ExtendsButtonProps> = ({ overrideColor, ...props }) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (

        <Button
            {...props}
            classes={{
                root:
                    overrideColor === "primary" ? classes.primary :
                    overrideColor === "secondary" ? classes.secondary :
                    overrideColor === "error" ? classes.error :
                    overrideColor === "warning" ? classes.warning :
                    void 0
            }}

        />
    )
}

export default MuiColorButton;
