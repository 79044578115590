import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { AppUrl } from '../../../app-url';
import { AuthorizeLoading } from '../AuthorizeLoading/AuthorizeLoading';
import AuthorizeError from '../AuthorizeError/AuthorizeError';


/**ユーザー認証 の Props のインターフェースを提供します。*/
interface Props {
    /** サインイン済みがどうかを表します。 */
    isSigednin: boolean;
    /** 認証済みがどうかを表します。 */
    isAuthorized: boolean;
    /** 認証中かどうかを表します。 */
    isLoaded: boolean;
    /** 認証失敗かどうかを表します。 */
    isError: boolean;
    /** メッセージを表します。 */
    message: string;
    /**  戻るボタンクリック処理を表します。*/
    onReturnClick: () => void,
}

/**
 * ユーザー認証 コンポーネントを表示します。
 * @param props
 */
export const AuthorizePage: React.FC<Props> = (props) => {

    if (!props.isSigednin) {
        // サインインが未処理
        return <Redirect to={AppUrl.accountSignIn.path} />
    }

    if (!props.isLoaded) {
        // 認証中はローディング画面表示
        return <AuthorizeLoading />
    }

    if (props.isError) {
        // 認証失敗時はエラー画面表示
        return <AuthorizeError
            message={props.message}
            onReturnClick={props.onReturnClick} />
    }

    if (!props.isAuthorized) {
        // ユーザー認証が未処理
        return <Redirect to={AppUrl.accountSignIn.path} />
    }


    return (
        //　認証完了 →　各画面コンポーネントの表示
        <>{props.children}</>
    );
}
