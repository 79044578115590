import { Localization } from 'material-table';

export const MuiLocalizationJapanese: Localization = {
    error: "エラー",
    body: {
        emptyDataSourceMessage: "表示するレコードがありません。",
        filterRow: {
            //filterPlaceHolder: "", Ver1.69用
            filterTooltip: "フィルター",
        },
        editRow: {
            saveTooltip: "保存",
            cancelTooltip: "キャンセル",
            deleteText: "この行を削除しますか？",
        },
        addTooltip: "追加",
        deleteTooltip: "削除",
        editTooltip: "編集",
    },
    header: {
        actions: "",
    },
    grouping: {
        groupedBy: "グループ化:",
        placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
        firstTooltip: "最初のページ",
        firstAriaLabel: "最初のページ",
        previousTooltip: "前のページ",
        previousAriaLabel: "前のページ",
        nextTooltip: "次のページ",
        nextAriaLabel: "次のページ",
        labelDisplayedRows: "{from}-{to} 全{count}項目",
        labelRowsPerPage: "ページあたりの行数:",
        lastTooltip: "最後のページ",
        lastAriaLabel: "最後のページ",
        labelRowsSelect: "行",
    },
    toolbar: {
        addRemoveColumns: "列の追加、削除",
        nRowsSelected: "{0} 行選択",
        showColumnsTitle: "列の表示",
        showColumnsAriaLabel: "列の表示",
        exportTitle: "出力",
        exportAriaLabel: "出力",
        exportName: "出力",
        //exportCSVName: "CSV出力", Ver1.69用
        //exportPDFName: "PDF出力", Ver1.69用
        searchTooltip: "絞り込み",
        searchPlaceholder: "絞り込み",
        //clearSearchAriaLabel: "クリア",  Ver1.69用
    },
};
