import { Options } from 'material-table';

/** material-table の 共通オプションを提供します。 */
export const MuiTableOptions: Options<any> = {
    toolbar: false,
    search: false,
    sorting: true,
    tableLayout: "auto",
    emptyRowsWhenPaging: false,
    pageSize: 100,
    pageSizeOptions: [10, 25, 50, 100],
}
