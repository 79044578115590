// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { onGetAsync } from '../../../../../modules/slice/maintenance-record/maintenance-record-slice';
import { RootState } from '../../../../../rootReducer';
// component
import MaintenanceRecordSearch, { MaintenanceRecordSearchState } from './MaintenanceRecordSearch';
import { MaintenanceRecordSearchType, StampSearchType } from '../../_types';

/**
 * Stateの初期値を設定します
 */
const initState = (): MaintenanceRecordSearchState => {
    const beginAt = new Date();
    beginAt.setMonth(beginAt.getMonth() - 1);
    beginAt.setHours(0, 0, 0, 0);

    const endAt = new Date();
    endAt.setHours(0, 0, 0, 0);

    const result: MaintenanceRecordSearchState = {
        keyword: "",
        searchType: MaintenanceRecordSearchType.Report,
        searchStampType: StampSearchType.None,
        beginAt: beginAt,
        endAt: endAt,
    }

    return result;
}

/** 整備記録画面　検索エリアを表示します。 */
const MaintenanceSearchContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine);
    const isUpdated: boolean = useSelector((state: RootState) => state.maintenanceRecord?.isUpdated ?? false);
    const isOpenViewer = useSelector((state: RootState) => state.maintenanceRecord.isOpenViewer ?? true);

    const [value, setValue] = useState<MaintenanceRecordSearchState>(initState);

    useEffect(() => {

        if (isUpdated && !isOpenViewer && machine) {
            onSearchClickAsync(value);
        }

    }, [machine, isUpdated, isOpenViewer, value, dispatch]);

    /** 検索ボタンのクリック処理を行います。 */
    const onSearchClickAsync = async (inputItem: MaintenanceRecordSearchState) => {
        setValue(inputItem);

        if (machine) {

            // 実行
            await dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    machineType: machine.type,
                    keyword: inputItem.keyword,
                    searchType: inputItem.searchType,
                    searchStampType: inputItem.searchStampType,
                    beginAt: inputItem.beginAt,
                    endAt: inputItem.endAt,
                }
            }));
        }
    };

    return (
        <MaintenanceRecordSearch
            initInputItem={value}
            onSearchClick={onSearchClickAsync} />
    );
};

export default MaintenanceSearchContainer;
