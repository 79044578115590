// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Divider,
    Box,
    Grid,
    Paper,
    Button,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';
import MuiRemoveButton from '../../../../../components/Buttons/MuiRemoveButton';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            // アイコン
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
        },
        // サブタイトル
        subtitle: {
            marginBottom: theme.spacing(2),
        },
        // 名称
        name: {
            fontSize: "1.25rem",
            marginRight: theme.spacing(1),
        },
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // ボタン
        button: {
            minWidth: 120,
        },
        // ダイアログコンテンツ(ダイアログ内のTextfiledのlabelが全角4文字以上でスクロールが発生するで、無効にする)
        dialogContent: {
            overflowY: "visible"
        }
    }),
);

/** 削除確認ダイアログの入力項目を表します。 */
export interface RemoveConfirmDialogInputItem {
    /** パスワードを表します */
    password: string,
}

/** 削除確認ダイアログの入力項目のバリデーション情報を表します。 */
export interface RemoveConfirmDialogValidateInputItem {
    /** パスワードを表します。 */
    readonly password: string;
}


/** マスタ項目削除確認ダイアログの Props を表示します。 */
interface Props {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** 削除ボタンクリック時の処理を表します。 */
    onRemoveClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, pwassword: string) => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
    /** タイトル */
    title?: string
}

/**
 * ステータス編集ダイアログを表示します。
 * @param props
 */
const ConfirmDialog: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** State */
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])

    /** 入力内容 */
    const [inputItem, setInputItem] = useState<RemoveConfirmDialogInputItem>({ password: "" });

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<RemoveConfirmDialogValidateInputItem>({ password: "" });

    /** パスワードの表示状態を表します。 */
    const [isShowPassword, setIsShowPassword] = useState(false);

    /**
     * 入力項目　内容変更時のイベント処理を行います。
     * */
    const onChange = (name: keyof RemoveConfirmDialogInputItem) => (event: React.ChangeEvent<HTMLInputElement>) => {

        const value = event.target.value;

        // 入力項目更新
        setInputItem({ ...inputItem, [name]: value });
    };


    /** パスワード表示非表示ボタン　クリック時のイベント処理を行います。*/
    const handleClickShowPassword = () => {
        setIsShowPassword(preValue => !preValue);
    };

    /**
     * パスワード表示非表示ボタン　マウスダウン時のイベント処理を行います。
     * @param event
     */
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    /** ダイアログを閉じるときの処理を行います。 */
    const onClose = () => {

        setInputItem({ password: "" });
        setValidate({ password: "" });

        props.handleClose();
    }

    /**
     * 削除ボタンのクリック処理を行います。
     * @param event
     */
    const onRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        // バリデーション
        const isError = onValidateInputItem()

        if (isError === false) {
            setInputItem({ password: "" });
            setValidate({ password: "" });
            props.onRemoveClick(event, inputItem.password);
        }
    }

    /** 入力内容のバリデーションを行います。 */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let passError = "";

        // パスワード
        if (!inputItem.password) {
            passError = "パスワードを入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({ password: passError });

        // 結果
        return result;

    }

    /**
     * form 送信イベント処理を行います
     * @param e
     */
    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        // Enter key による submit を無効にする
        e.preventDefault();
    }

    return (
        <Dialog
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="remove-dialog-title"
            aria-describedby="remove-dialog-description"
            open={open}
            onClose={onClose}
        >

            {/* タイトル */}
            <DialogTitle id="form-dialog-title">
                <Box className={classes.title}>
                    <InfoIcon fontSize="large" />{props.title ?? "マスタ項目 削除確認"} 
                    </Box>
            </DialogTitle>


            <DialogContent classes={{ root: classes.dialogContent }}>
                {/* message */}
                <Typography className={classes.subtitle}>
                    選択した項目を削除します。
                </Typography>
                <Typography className={classes.subtitle}>
                    パスワードを入力してください。
                </Typography>

                <form onSubmit={e => submitHandler(e)}>
                    {/* パスワード */}
                    <TextField
                        fullWidth
                        type={isShowPassword ? 'text' : 'password'}
                        autoComplete="off"
                        className={classes.inputItem}
                        label="パスワード"
                        value={inputItem.password}
                        onChange={onChange("password")}

                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        required
                        error={!validate.password === false}
                        helperText={validate.password}
                    />

                </form>

            </DialogContent>

            {/* ボタンアクション */}
            <DialogActions>
                <MuiCancelButton
                    className={classes.button}
                    onClick={onClose}
                />
                <MuiRemoveButton
                    className={classes.button}
                    onClick={(event) => onRemove(event)}
                />
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
