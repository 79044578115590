import { MachineOperationHistoryGraphItem } from '../../../pages/ConstructionMachineMaster/ConstructionRecord/_types';
import { MachineOperationHistoryItem } from '../../swagger-clients';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `整備記録` 関連の ユーティリティ機能を提供します。
 */
export class MachineOperationHistoryUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGraphItem(items?: MachineOperationHistoryItem[]): MachineOperationHistoryGraphItem[] {
        const result: MachineOperationHistoryGraphItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 不正値チェック
            if (source.registerAt == null) {
                continue;
            }

            // 日付文字変換
            const registerAt = new Date(source.registerAt);
            registerAt.setHours(0, 0, 0, 0);
            const dateAt = source.registerAt == null ? "" : SharedUtility.toDateString(registerAt);

            const item: MachineOperationHistoryGraphItem = {
                engineOperatingTime: source.engineOperatingTime ?? "",
                liftingDistance: source.liftingDistance ?? "",
                engineRotationTimeAvg: source.engineRotationTimeAvg ?? "",
                batteryVoltageAvg: source.batteryVoltageAvg ?? "",
                liftingPressureMax: source.liftingPressureMax ?? "",
                oilTemperatureAvg: source.oilTemperatureAvg ?? "",
                dateAt: dateAt,
                errors: source.errors ?? [],
            };
            result.push(item);
        }
        return result;
    }

    //#endregion メソッド
}
