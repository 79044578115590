// react
import * as React from 'react';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import {
    TextField,
    Box,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// type
import { UserInputItem, UserValidateInputItem } from '../../_types';
// component
import MasterDetailButtonGroup from '../../../Shared/MasterDetailButtonGroup';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },
        // 削除
        deleteButton: {
            color: theme.palette.warning.main,
        },

        // 更新
        updateButton: {
            color: theme.palette.warning.main,
        },

        // 入力項目(パスワード)
        inputItem: {
            width: "250px",
            margin: "8px",
        },

        // テキストエリア
        textarea: {
            width: `calc((250px + ${theme.spacing(1)}px) * 2)!important`,
        },

    }),
);

/** ユーザーマスタ　詳細エリアの Props を提供します。 */
interface Props {
    /** 初期値を表します。 */
    initialValues: UserInputItem,
    /** 追加ボタンのクリック処理を行います。 */
    onClickAdd: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 更新ボタンのクリック処理を行います。 */
    onClickUpdate: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 削除ボタンのクリック処理を行います。 */
    onClickRemove: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** キャンセルボタンのクリック処理を行います。 */
    onClickCancel: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 新規作成状態かどうかを表します。 */
    isCreating: boolean,
    /** 入力値の変更処理を表します。 */
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof UserInputItem) => void,
    /** バリデーション結果を表します。 */
    validateError: UserValidateInputItem,
    /** サインインユーザー項目かどうかを表します。 */
    isSigninUser: boolean,
};

/**
 * ユーザーマスタ　詳細エリアを提供します。
 * */
const UserDetailCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** パスワードの表示状態を表します。 */
    const [isShowPassword, setIsShowPassword] = useState(false);

    /** パスワード表示非表示ボタン　クリック時のイベント処理を行います。*/
    const handleClickShowPassword = () => {
        setIsShowPassword(preValue => !preValue);
    };

    /**
     * パスワード表示非表示ボタン　マウスダウン時のイベント処理を行います。
     * @param event
     */
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    // 表示
    return (
        <>
            <Box padding={1}>

                {/* ユーザー id */}
                <TextField
                    label="ユーザー id"
                    placeholder="ユーザー id"
                    value={props.initialValues.id ?? ""}
                    onChange={(event) => props.onChange(event, "id")}
                    disabled={!(props.isSigninUser || props.isCreating)}
                    required
                    error={!props.validateError.id === false}
                    helperText={props.validateError.id}
                />

                {/* ユーザー名 */}
                <TextField
                    label="ユーザー名"
                    placeholder="ユーザー名"
                    value={props.initialValues.user ?? ""}
                    onChange={(event) => props.onChange(event, "user")}
                    disabled={!(props.isSigninUser || props.isCreating)}
                    required
                    error={!props.validateError.user === false}
                    helperText={props.validateError.user}
                />

                {/* 検印文字 */}
                <TextField
                    label="検印文字"
                    placeholder="検印文字"
                    value={props.initialValues.inspectorName ?? ""}
                    onChange={(event) => props.onChange(event, "inspectorName")}
                    disabled={!(props.isSigninUser || props.isCreating)}
                    required
                    error={!props.validateError.inspectorName === false}
                    helperText={props.validateError.inspectorName}
                />


                {/* パスワード */}
                {(props.isSigninUser || props.isCreating) &&
                    <TextField
                        fullWidth
                        type={isShowPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        className={classes.inputItem}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        label="パスワード"
                        value={props.initialValues.password ?? ""}
                        onChange={(event) => props.onChange(event, "password")}
                        required
                        error={!props.validateError.password === false}
                        helperText={props.validateError.password}
                    />
                }


                {/* 確認用パスワード */}
                {(props.isSigninUser || props.isCreating) &&
                    <TextField
                        fullWidth
                        type={isShowPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        className={classes.inputItem}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        label="確認用パスワード"
                        value={props.initialValues.confirmPassword ?? ""}
                        onChange={(event) => props.onChange(event, "confirmPassword")}
                        required
                        error={!props.validateError.confirmPassword === false}
                        helperText={props.validateError.confirmPassword}
                    />
                }

                {/* ボタングループ */}
                <MasterDetailButtonGroup
                    isNonEditabled={!props.isSigninUser}
                    isCreating={props.isCreating}
                    onClickAdd={props.onClickAdd}
                    onClickCancel={props.onClickCancel}
                    onClickUpdate={props.onClickUpdate}
                    onClickRemove={props.onClickRemove}
                />

            </Box>
        </>
    );
};

export default UserDetailCondition;
