import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { MachineStatusControlMeterState } from '../_types';


/** 貫入の文字列を表します。 */
const Penetration = "貫入";
/** 打深の文字列を表します。 */
const Depth = "打深";
/** 先端処理引抜の文字列を表します。 */
const TipPullOut = "先端処理引抜";
/** 先端処理打戻の文字列を表します。 */
const TipHitBack = "先端処理打戻";
/** 先端処理定置撹拌の文字列を表します。 */
const TipStir = "先端処理定置撹拌";
/** 引抜の文字列を表します。 */
const PullOut = "引抜";
/** 再貫引抜の文字列を表します。 */
const RePenePullOut = "再貫引抜";
/** 再貫打戻の文字列を表します。 */
const RePeneHitBack = "再貫打戻";
/** 一時停止の文字列を表します。 */
const Stop = "一時停止";
/** 施工外の文字列を表します。 */
const Other = "施工外";
/** CTU未接続時の文字列を表します。 */
const NotAvailable = "---";


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "block",
            minHeight: "136px",
        },
        // タイトル
        title: {
            fontSize: "1.25rem",

        },
        // 値
        value: {
            fontSize: "2.5rem",
            textAlign: "center",
            marginTop: theme.spacing(1.5),
        },
    }),
);

/** 管理計器ステータスカードの Props を提供します。 */
interface Props {
    /** 取得値を表します。*/
    status?: MachineStatusControlMeterState,
};

/**
 * 管理計器ステータスカードを提供します。
 * @param props
 */
const ControlMeterStatusCard: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles(props);


    /** 昇降状態を表示します。 */
    function renderStatusIcon(status: MachineStatusControlMeterState | undefined): string {

        const result: string =
            status === MachineStatusControlMeterState.Penetration ? Penetration :
            status === MachineStatusControlMeterState.Depth ? Depth :
            status === MachineStatusControlMeterState.TipPullOut ? TipPullOut :
            status === MachineStatusControlMeterState.TipHitBack ? TipHitBack :
            status === MachineStatusControlMeterState.TipStir ? TipStir :
            status === MachineStatusControlMeterState.PullOut ? PullOut :
            status === MachineStatusControlMeterState.RePenePullOut ? RePenePullOut :
            status === MachineStatusControlMeterState.RePeneHitBack ? RePeneHitBack :
            status === MachineStatusControlMeterState.Stop ? Stop :
            status === MachineStatusControlMeterState.Other ? Other :
            NotAvailable;
        return result;
    };

    return (
        <Card className={classes.root} >
            <CardContent>
                <Typography component="p" variant="subtitle1" className={classes.title}>
                    管理計器ステータス
                </Typography>

                {/* ステータス */}
                <Box className={classes.value}>
                    {renderStatusIcon(props.status)}
                </Box >
            </CardContent>
        </Card >
    );
};

export default ControlMeterStatusCard;
