import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MachineType, MachineItem, Machine } from '../../../_types';
import { HeaderMachineState } from '../../../pages/Shared/GlobalHeader/_types';
import { startReloadTimer, stopReloadTimer } from '../machine-monitoring/machine-monitoring-slice';
import { MachineMonitoringGetRequest } from '../../adapter/machine-monitoring/types';

/**
 * ページ種別の列挙値を提供します。
 * */
export enum PageType {
    /** Home画面(施工機管理状況一覧)を表します。  */
    Home = 1,
    /** Record画面(整備記録など)を表します。  */
    Record = 2,
    /** Master画面を表します。  */
    Master = 3,
    /** Report画面(その他報告)を表します。  */
    Report = 4,
}

/**
 * CTUエラー施工機情報のインターフェースを提供します。
 * */
interface CtuErrorMachine {
    /** CTUエラー施工機があるかどうかを表します。 */
    isExistMachineError: boolean;
    /** エラー施工機の社番を表します。 */
    errorMachineIds: string[];
}

// ヘッダーの State を提供します。
interface State {
    /** タイトルを表します。 */
    title: string;
    /** 施工機情報を表します。 */
    machine?: HeaderMachineState;
    /** 表示画面種別を表します。 */
    pageType: PageType;
    /** エラー情報があるかどうかを表します。 */
    isError: boolean;
    /** CTUエラー施工機を表します。 */
    ctuErrorMachine: CtuErrorMachine;
    /** ダイアログを表示するかどうかを表します。 */
    isOpenDialog: boolean;
}

/** State の初期値を設定します。 */
const initialState: State = {
    title: "施工機保全システム",
    pageType: PageType.Home,
    isError: false,
    ctuErrorMachine: {
        isExistMachineError: false,
        errorMachineIds: [],
    },
    isOpenDialog: false,
}

/** State Reducer Action を生成します。 */
export const GlobalHeaderSlice = createSlice({
    name: 'globalHeader',    // createSlice を識別するための名前
    initialState,       // State の初期値を入れる
    reducers: {         // State の変更処理

        /**
         * ヘッダーを変更します。
         * @param state
         * @param action
         */
        changeHeader(state: State, action: PayloadAction<State>) {
            state = action.payload;
        },

        /**
         * タイトルを変更します。
         * @param state
         * @param action
         */
        changeTitle(state: State, action: PayloadAction<string>) {
            state.title = action.payload;
        },

        /**
         * ページ種別を変更します。
         * @param state
         * @param action
         */
        changePageType(state: State, action: PayloadAction<PageType>) {
            state.pageType = action.payload;
        },

        /**
         * 施工機情報を変更します。
         * @param state
         * @param action
         */
        changeMachine(state: State, action: PayloadAction<HeaderMachineState>) {
            state.machine = action.payload;
        },

        /**
         * エラー状態を変更します。
         * @param state
         * @param action
         */
        changeError(state: State, action: PayloadAction<boolean>) {
            state.isError = action.payload;
        },

        /**
         * CTUエラー施工機存在ありエラーを表示します。
         * @param state
         * @param action
         */
        setCtuMachineErrorOn(state: State) {
            state.ctuErrorMachine.isExistMachineError = true;
        },

        /**
         * CTUエラー施工機一覧の情報を更新します。
         * @param state
         * @param action
         */
        updateCtuMachineError(state: State, action: PayloadAction<string[]>) {
            const machineIds = [...state.ctuErrorMachine.errorMachineIds, ...action.payload];
            state.ctuErrorMachine.errorMachineIds = machineIds.filter((machineId, index, self) => self.indexOf(machineId) === index);
        },

        /**
         * CTUエラー施工機一覧の情報をクリアします。
         * @param state
         * @param action
         */
        resetCtuMachineError(state: State) {
            state.ctuErrorMachine.isExistMachineError = false;
            state.ctuErrorMachine.errorMachineIds = [];
        },

        /**
         * ダイアログの開閉処理を行います。
         * @param state
         * @param action
        */
        toggleDialog(state: State, action: PayloadAction<boolean>) {
            state.isOpenDialog = action.payload;
        },

    }
})

export const { changeMachine } = GlobalHeaderSlice.actions;
export const { changeError } = GlobalHeaderSlice.actions;
export const { setCtuMachineErrorOn } = GlobalHeaderSlice.actions;
export const { updateCtuMachineError } = GlobalHeaderSlice.actions;
export const { resetCtuMachineError } = GlobalHeaderSlice.actions;

/**
 * 施工機管理状況一覧のヘッダーを変更します。
 * @param title タイトル文字を指定します。
 */
export function changeMachineMonitoringHeader(title: string) {
    return (dispatch: Dispatch) => {
        // store 更新
        dispatch(GlobalHeaderSlice.actions.changeTitle(title));                 // タイトル
        dispatch(GlobalHeaderSlice.actions.changePageType(PageType.Home));      // ページ種別　Home
    }
}

/**
 * 記録系画面のヘッダーを変更します。
 * @param title タイトル文字を指定します。
 */
export function changeRecordPageHeader(title: string) {
    return (dispatch: Dispatch) => {
        // store 更新
        dispatch(GlobalHeaderSlice.actions.changeTitle(title));                 // タイトル
        dispatch(GlobalHeaderSlice.actions.changePageType(PageType.Record));    // ページ種別　Record
    }
}


/**
 * マスタ系画面のヘッダーを変更します。
 * @param title タイトル文字を指定します。
 */
export function changeMasterPageHeader(title: string) {
    return (dispatch: Dispatch) => {
        // store 更新
        dispatch(GlobalHeaderSlice.actions.changeTitle(title));                 // タイトル
        dispatch(GlobalHeaderSlice.actions.changePageType(PageType.Master));    // ページ種別　Master
    }
}

/**
 * その他報告画面のヘッダーを変更します。
 * @param title タイトル文字を指定します。
 */
export function changeReportPageHeader(title: string) {
    return (dispatch: Dispatch) => {
        // store 更新
        dispatch(GlobalHeaderSlice.actions.changeTitle(title));                 // タイトル
        dispatch(GlobalHeaderSlice.actions.changePageType(PageType.Report));    // ページ種別　Report
    }
}

/** ダイアログのクローズ処理を行います。 */
export function onCloseDialog(pageType: PageType, parameter: MachineMonitoringGetRequest) {
    return (dispatch: Dispatch) => {

        // ダイアログを閉じる
        dispatch(GlobalHeaderSlice.actions.toggleDialog(false));

        // 施工機管理状況一覧画面はタイマー再開
        if (pageType === PageType.Home) {
            // タイマー開始
            dispatch<any>(startReloadTimer(parameter));
        }
    }
}

/** ダイアログのオープン処理を行います。 */
export function onOpenDialog() {
    return (dispatch: Dispatch) => {

        // タイマー停止
        dispatch<any>(stopReloadTimer());

        // ダイアログを開く
        dispatch(GlobalHeaderSlice.actions.toggleDialog(true));
    }
}

/** エラーリセット処理を行います。 */
export function onReset(pageType: PageType, parameter: MachineMonitoringGetRequest) {
    return (dispatch: Dispatch) => {

        // エラー表示をOFF
        dispatch(resetCtuMachineError());

        // ダイアログクローズ
        dispatch<any>(onCloseDialog(pageType, parameter));
    }
}
