// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { RemarksMasterSlice, onExcuteAsync } from '../../../../../modules/slice/remarks/remarks-slice';
import { RemarksAdapter } from '../../../../../modules/adapter/remarks/remarks-adapter'
// type
import { RemarksInputItem, RemarksValidateInputItem } from '../../_types';
// component
import RemarksDetailCondition from './RemarksDetailCondition'
import { MuiTextFieldUtility } from '../../../../../components/MuiTextField/mui-text-field-utility';

/**
 * 特記事項マスタ　詳細エリアを提供します。
 * */
export const RemarksDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.remarksMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.remarksMaster.selectedRowItem);

    const machineTypeList = useSelector((state: RootState) => state.remarksMaster.selectBoxItem.MachineType);

    const initialValues: RemarksInputItem = {
        id: selectedItem?.id,
        machineTypeId: selectedItem?.machineTypeId,
        title: selectedItem?.title,
        item: selectedItem?.item,
        weight: selectedItem?.weight,
        lockVersion: selectedItem?.lockVersion,
    }

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<RemarksValidateInputItem>({});

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<RemarksInputItem>(initialValues);

    // 行選択時の入力項目更新
    useEffect(() => {
        setValues(preValue => ({
            ...preValue,
            id: selectedItem?.id,
            machineTypeId: selectedItem?.machineTypeId,
            title: selectedItem?.title,
            item: selectedItem?.item,
            weight: selectedItem?.weight,
            lockVersion: selectedItem?.lockVersion,
        }))

        // バリデーション情報クリア
        clearValidate();

    }, [selectedItem]);

    /**
     * 入力値の変更処理を行います。
     * @param name　入力項目を指定します。
     */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof RemarksInputItem) => {

        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? MuiTextFieldUtility.LimitationNumber(Number.parseInt(target.value, 10)) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = RemarksAdapter.instance.addAsync(
            { item: values },
            { MachineType: machineTypeList }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = RemarksAdapter.instance.updateAsync(
            { item: values },
            { MachineType: machineTypeList }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        // ダイアログ表示
        dispatch(RemarksMasterSlice.actions.toggleDialog(true));
    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(RemarksMasterSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }

    /**
    * 入力内容のバリデーションを行います。
    * @returns 検証結果を返します。
    * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let machineType = "";
        let weight = "";
        let title = "";
        let item = "";

        // 施工機種別
        if (!values.machineTypeId) {
            machineType = "施工機種別を選択してください";
            result = true;
        }

        // 重み
        if (values.weight == null) {
            weight = "No.を入力してください";
            result = true;
        }

        // タイトル
        if (!values.title) {
            title = "タイトルを入力してください";
            result = true;
        }

        // 項目
        if (!values.item) {
            item = "項目を入力してください";
            result = true;
        }


        // エラー文字をセット
        setValidate({
            machineType,
            weight,
            title,
            item,
        });

        // 結果
        return result;
    }

    // 表示
    return (
        <RemarksDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            machineTypeList={machineTypeList}
            onChange={onChange}
            validateError={validate}
        />
    );
};
