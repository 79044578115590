import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import cyan from '@material-ui/core/colors/cyan';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // チップ
        chip: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: cyan[700],
        },
        // アイコン
        icon: {
            color: theme.palette.secondary.contrastText,
        }
    })
);


/**
 * 検印実行ステータスのアイコンを表示します。
 */
const StampTag: React.FC<{}> = () => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Chip
            size="small"
            className={classes.chip}
            label={"検印"}
            icon={
                <CheckCircleOutlineIcon className={classes.icon} />
            }
        />
    )
}

export default StampTag;
