import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { HeaderMachineState } from './_types';
import { changeMachine } from '../../../modules/slice/global-header/global-header-slice';
import Layout from './components/Layout/Layout';
import { MenuBarContainer } from './components/MenuBar/MenuBarContainer';
import Logo from './components/Logo/Logo';
import { TitleContainer } from './components/Title/TitleContainer';
import { MenuContainer } from './components/Menu/MenuContainer';
import { ErrorPopoverContainer } from './components/ErrorPopover/ErrorPopoverContainer';
import { ResetDialogContainer } from './components/ResetDialog/ResetDialogContainer';

/**
 * ヘッダーを表示します。
 * */
export const GlobalHeader: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();
    /** Hisotry を表します。*/
    const history = useHistory<HeaderMachineState>();
    /** 施工機種別 を表します。*/
    const machine = history.location.state;

    // 副作用
    useEffect(() => {

        if (machine) {
            dispatch(changeMachine(machine));
        }

    }, [dispatch, machine]);

    return (
        <>
            <MenuBarContainer>
                <Layout
                    logo={<Logo />}
                    title={<TitleContainer />}
                    errorPopover={<ErrorPopoverContainer />}
                    menu={<MenuContainer />}
                />
            </MenuBarContainer>

            {/* リセットダイアログ */}
            <ResetDialogContainer />

        </>

    );
}
