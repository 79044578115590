import { GetMaintenanceReportInput, GetDailyReportInput, GetWeeklyReportInput, GetCompletionReportInput, InspectedStampItem as DtoInspectedStampItem, KeyValueItemOfDayOfWeekAndInspectedStampItem, KeyValueItemOfWeekOfMonthAndInspectedStampItem, GetMaintenancePreviewReportInput, UpdateMaintenanceInspectedStampInput, UpdateDailyInspectedStampInput, UpdateWeeklyInspectedStampInput, UpdateCompletionInspectedStampInput } from "../../swagger-clients";
import { MaintenanceReportRequest, DailyReportRequest, WeeklyReportRequest, CompletionReportRequest, UpdateMaintenanceReportRequest, UpdateDailyReportRequest, UpdateWeeklyReportRequest, UpdateCompletionReportRequest } from "./type";
import { SharedUtility } from "../../shared/shared-utility";
import { KeyValueItem, DayOfWeek, WeekOfMonth } from "../../../_types";
import { InspectedStampItem, InspecterType } from "../../../pages/ConstructionMachineMaster/_types";


/**
 * `帳票` 関連の ユーティリティ機能を提供します。
 */
export class ReportUtility {

    //#region メソッド

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGetMaintenanceReportInput(source: MaintenanceReportRequest): GetMaintenanceReportInput {
        const result = new GetMaintenanceReportInput();
        result.init({
            machineType: SharedUtility.fromMachineType(source.machineType),
            machineId: source.machineId,
            reportNo: source.reportNo,
            reportAt: source.reportAt ? SharedUtility.toFormatDateString(new Date(source.reportAt)) : void 0,
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGetDailyReportInput(source: DailyReportRequest): GetDailyReportInput {
        const result = new GetDailyReportInput();
        result.init({
            machineId: source.machineId,
            reportAt: source.reportAt ? SharedUtility.toFormatDateString(new Date(source.reportAt)) : void 0,
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGetWeeklyReportInput(source: WeeklyReportRequest): GetWeeklyReportInput {
        const result = new GetWeeklyReportInput();
        result.init({
            machineId: source.machineId,
            reportAt: source.reportAt ? SharedUtility.toFormatDateString(new Date(source.reportAt)) : void 0,
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGetCompletionReportInput(source: CompletionReportRequest): GetCompletionReportInput {
        const result = new GetCompletionReportInput();
        result.init({
            machineId: source.machineId,
            reportAt: source.reportAt ? SharedUtility.toFormatDateString(new Date(source.reportAt)) : void 0,
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGetMaintenancePreviewReportInput(source: MaintenanceReportRequest): GetMaintenancePreviewReportInput {
        const result = new GetMaintenancePreviewReportInput();
        result.init({
            machineType: SharedUtility.fromMachineType(source.machineType),
        });
        return result;
    }


    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    private static toDtoInspectedStampItem(source: InspectedStampItem): DtoInspectedStampItem {
        if (source.reportNo == null) {
            throw new Error("toDtoInspectedStampItem");
        }
        const result = new DtoInspectedStampItem();
        result.init({
            reportNo: source.reportNo,
            // reportAt: source.reportAt, // BE:不要
            inspector: source.inspector, // BE:検印する場合は　null or empty。 差し戻す場合は null。
            firstTeamLeader: source.firstTeamLeader,
            completeTeamLeader: source.completeTeamLeader,
            firstManager: source.firstManager,
            completeManager: source.completeManager,
            isChecked: source.isStamped ?? false, // BE:未使用
            exist: source.exist,// BE:未使用
        });
        return result;

    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    private static toDtoInspectedStampItems(items: InspectedStampItem[]): DtoInspectedStampItem[] {
        const result: DtoInspectedStampItem[] = [];

        for (const item of items) {
            const source: DtoInspectedStampItem = ReportUtility.toDtoInspectedStampItem(item);
            result.push(source);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toUpdateMaintenanceInspectedStampInput(source: UpdateMaintenanceReportRequest): UpdateMaintenanceInspectedStampInput {
        const result = new UpdateMaintenanceInspectedStampInput();
        result.init({
            machineType: SharedUtility.fromMachineType(source.machineType),
            stampItem: ReportUtility.toDtoInspectedStampItem(source.item),
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toUpdateDailyInspectedStampInput(source: UpdateDailyReportRequest): UpdateDailyInspectedStampInput {
        const result = new UpdateDailyInspectedStampInput();
        result.init({
            stampItems: ReportUtility.toDtoInspectedStampItems(source.items),
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toUpdateWeeklyInspectedStampInput(source: UpdateWeeklyReportRequest): UpdateWeeklyInspectedStampInput {
        const result = new UpdateWeeklyInspectedStampInput();
        result.init({
            stampItems: ReportUtility.toDtoInspectedStampItems(source.items),
        });
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toUpdateCompletionInspectedStampInput(source: UpdateCompletionReportRequest): UpdateCompletionInspectedStampInput {
        const result = new UpdateCompletionInspectedStampInput();
        result.init({
            stampItem: ReportUtility.toDtoInspectedStampItem(source.item),
        });
        return result;
    }

    /**
     * 項目を表示用の項目に変換します。
     * @param item 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInspectedStampItem(item?: DtoInspectedStampItem): InspectedStampItem {
        if (item?.exist == null) {
            throw new Error("toInspectedStampItem");
        }
        return {
            exist: item?.exist,
            reportNo: item?.reportNo,
            reportAt: item?.reportAt,
            inspector: item?.inspector,
            isStamped: item?.isChecked,
        };
    }

    /**
     * 項目を表示用の項目に変換します。
     * @param key 検印者種別を指定します。
     * @param item 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    private static toMaintenanceInspectedStampItem(key: InspecterType, item: DtoInspectedStampItem): InspectedStampItem {
        if (item?.exist == null) {
            throw new Error("toMaintenanceInspectedStampItem");
        }
        const inspector = ReportUtility.getInspecterName(key, item);

        const result = {
            exist: item?.exist,
            reportNo: item?.reportNo,
            reportAt: item?.reportAt,
            inspector: inspector,
            firstTeamLeader: key === InspecterType.FirstTeamLeader ? inspector : void 0,
            completeTeamLeader: key === InspecterType.CompleteTeamLeader ? inspector : void 0,
            firstManager: key === InspecterType.FirstManager ? inspector : void 0,
            completeManager: key === InspecterType.CompleteManager ? inspector : void 0,
            isStamped: !!inspector, // 各検印者名が Null で未検印 、それ以外は 検印済
        };
        return result;
    }

    /**
     * 検印者名を取得します。
     * @param key 検印者種別を指定します。
     * @param item 検印情報を指定します。
     * @retuns 検印者名を返します。
     */
    private static getInspecterName(key: InspecterType, item: DtoInspectedStampItem): string | undefined {
        const result: string | undefined =
            key === InspecterType.FirstTeamLeader ? item.firstTeamLeader
                : key === InspecterType.CompleteTeamLeader ? item.completeTeamLeader
                    : key === InspecterType.FirstManager ? item.firstManager
                        : key === InspecterType.CompleteManager ? item.completeManager
                            : void 0;
        return result;
    }



    /**
     * 項目を表示用の項目に変換します。
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toMaintenaceReportInspectedStampItem(item?: DtoInspectedStampItem): KeyValueItem<InspecterType, InspectedStampItem>[] {
        const result: KeyValueItem<InspecterType, InspectedStampItem>[] = []

        if (item == null) return result;

        // see:https://qiita.com/wildmouse/items/96ec2a5892e88ea4aec6
        Object.entries(InspecterType).forEach(([key, value]) => {
            const inspecterType = parseInt(key);

            if (Number.isNaN(inspecterType)) return;
            const source: KeyValueItem<InspecterType, InspectedStampItem> = {
                key: inspecterType,
                value: ReportUtility.toMaintenanceInspectedStampItem(inspecterType, item),
            }
            result.push(source);
        });

        return result;
    }

    /**
     * 項目を表示用の項目に変換します。
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toDailyReportInspectedStampItem(items?: KeyValueItemOfDayOfWeekAndInspectedStampItem[]): KeyValueItem<DayOfWeek, InspectedStampItem>[] {
        const result: KeyValueItem<DayOfWeek, InspectedStampItem>[] = []

        if (items == null) return result;

        for (const item of items) {
            const source: KeyValueItem<DayOfWeek, InspectedStampItem> = {
                key: SharedUtility.toDayOfWeek(item.key),
                value: ReportUtility.toInspectedStampItem(item.value),
            }
            result.push(source);
        }
        return result;
    }

    /**
     * 項目を表示用の項目に変換します。
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toWeeklyReportInspectedStampItem(items?: KeyValueItemOfWeekOfMonthAndInspectedStampItem[]): KeyValueItem<WeekOfMonth, InspectedStampItem>[] {
        const result: KeyValueItem<WeekOfMonth, InspectedStampItem>[] = []

        if (items == null) return result;

        for (const item of items) {
            const source: KeyValueItem<WeekOfMonth, InspectedStampItem> = {
                key: SharedUtility.toWeekOfMonth(item.key),
                value: ReportUtility.toInspectedStampItem(item.value),
            }
            result.push(source);
        }
        return result;
    }
    //#endregion メソッド



}
