import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Divider } from '@material-ui/core';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ヘッダー
        header: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },

        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            // アイコン
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
        },
        // コンテナ
        container: {
            backgroundColor: fade(theme.palette.secondary.main, 0.3),
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },

        // 施工機IdsBox
        machineBox: {
            overflowY: "auto",
            maxHeight: "500px",
            margin: theme.spacing(1),
        },
        // ボタン
        button: {
            minWidth: 120,
        },

    }),
);

/** リセットダイアログの Props を提供します。 */
interface Props {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** CTUエラー施工機社番を表します。 */
    machineIds: string[],
    /** リセットボタンクリック時の処理を表します。 */
    onResetClick: () => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
}


/**
 * リセットダイアログを表示します。
 * @param props
 */
const ResetDialog: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** State */
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])

    return (
        <>
            <Dialog
                keepMounted
                fullWidth
                maxWidth="sm"
                aria-labelledby="reset-dialog-title"
                aria-describedby="reset-dialog-description"
                open={open}
                onClose={() => props.handleClose()}
            >

                {/* タイトル */}
                <DialogTitle disableTypography id="alert-dialog-title">
                    <Typography className={classes.title}>
                        <InfoIcon fontSize="large" />CTUエラー施工機表示リセット
                    </Typography>
                </DialogTitle >

                <DialogContent>
                    {/* message */}
                    <Typography id="alert-dialog-description">
                        ヘッダーエラー表示をリセットします。よろしいですか？
                    </Typography>

                    <Box className={classes.container}>
                        <Typography>
                            CTUエラー発生施工機　社番
                        </Typography>
                        <Divider />
                        <Box className={classes.machineBox}>
                            {props.machineIds.map((id, index) => (
                                <Typography key={index}>{id}</Typography>
                            ))}
                        </Box>

                    </Box>

                </DialogContent>

                <DialogActions>
                    <MuiCancelButton
                        className={classes.button}
                        onClick={() => props.handleClose()}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<NotificationsOffIcon />}
                        className={classes.button}
                        onClick={props.onResetClick}
                    >
                        表示リセット
                    </Button>

                </DialogActions>

            </Dialog>


        </>
    );
}
export default ResetDialog;
