import { combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit'
import { themeSlice } from './modules/slice/theme/theme-slice';
import { GlobalHeaderSlice } from './modules/slice/global-header/global-header-slice';
import { areaTypeSlice } from './modules/slice/area-type/area-type-slice';
import { AuthenticationSlice } from './modules/slice/authentication/authentication-slice';

// 各Page
import { MachineMonitoringSlice } from './modules/slice/machine-monitoring/machine-monitoring-slice'
import { MachineStatusMonitoringSlice } from './modules/slice/machine-status-monitoring/machine-status-monitoring-slice';
import { MaintenanceRecordSlice } from './modules/slice/maintenance-record/maintenance-record-slice'
import { MachineErrorHistorySlice } from './modules/slice/machine-error-history/machine-error-history-slice';
import { DailyInspectionRecordSlice } from './modules/slice/daily-inspection-record/daily-inspection-record-slice';
import { WeeklyInspectionRecordSlice } from './modules/slice/weekly-inspection-record/weekly-inspection-record-slice';
import { CompletionInspectionRecordSlice } from './modules/slice/completion-inspection-record/completion-inspection-record-slice'
import { MachineOperationHistorySlice } from './modules/slice/machine-operation-history/machine-operation-history-slice';
import { StatusEditSlice } from './modules/slice/status-edit/status-edit-slice';
import { OtherReportSlice } from './modules/slice/other-report/other-report-slice';
import { MachineMasterSlice } from './modules/slice/machine/machine-slice';
import { ModelMasterSlice } from './modules/slice/model/model-slice';
import { ManufacturerMasterSlice } from './modules/slice/manufacturer/manufacturer-slice';
import { MethodMasterSlice } from './modules/slice/method/method-slice';
import { MaintenanceDivisionSlice } from './modules/slice/maintenance-division/maintenance-division-slice';
import { MaintenanceItemMasterSlice } from './modules/slice/maintenance-item/maintenance-item-slice';
import { RemarksMasterSlice } from './modules/slice/remarks/remarks-slice';
import { InspectionDivisionSlice } from './modules/slice/inspection-division/inspection-division-slice';
import { InspectionItemMasterSlice } from './modules/slice/inspection-item/inspection-item-slice';
import { UserMasterSlice } from './modules/slice/user/user-slice';
import { SigninSlice } from './modules/slice/signin/signin-slice';
import { FileManageSlice } from './modules/slice/file-manage/file-manage-slice';

/** 各reducer を組合わせます。 */
const combinedReducer = combineReducers({
    // 認証済みユーザー情報
    authentication: AuthenticationSlice.reducer,
    // テーマ
    theme: themeSlice.reducer,
    //ヘッダー
    globalHeader: GlobalHeaderSlice.reducer,
    // エリア種別
    areaType: areaTypeSlice.reducer,

    // 各画面
    // サインイン画面
    signin: SigninSlice.reducer,
    // 施工機モニター(メイン画面)
    machineMonitoring: MachineMonitoringSlice.reducer,
    // 整備記録
    maintenanceRecord: MaintenanceRecordSlice.reducer,
    // 日常点検記録
    dailyInspectionRecord: DailyInspectionRecordSlice.reducer,
    // 週間月例点検記録
    weeklyInspectionRecord: WeeklyInspectionRecordSlice.reducer,
    // 現場完了時点検記録
    completionInspectionRecord: CompletionInspectionRecordSlice.reducer,
    // 施工機ステータス
    machineStatusMonitoring: MachineStatusMonitoringSlice.reducer,
    // 施工記録
    machineOperationHistory: MachineOperationHistorySlice.reducer,
    // ステータス編集
    statusEdit: StatusEditSlice.reducer,
    // アラート履歴
    machineErrorHistory: MachineErrorHistorySlice.reducer,
    // その他報告
    otherReport: OtherReportSlice.reducer,
    // ファイル管理
    fileManage: FileManageSlice.reducer,
    // ** マスタ系 **
    // 施工機マスタ
    machineMaster: MachineMasterSlice.reducer,
    //　型式マスタ
    modelMaster: ModelMasterSlice.reducer,
    // メーカーマスタ
    manufacturerMaster: ManufacturerMasterSlice.reducer,
    // 工法マスタ
    methodMaster: MethodMasterSlice.reducer,
    // 整備区分マスタ
    maintenanceDivisionMaster: MaintenanceDivisionSlice.reducer,
    // 整備項目マスタ
    maintenanceItemMaster: MaintenanceItemMasterSlice.reducer,
    // 特記事項マスタ
    remarksMaster: RemarksMasterSlice.reducer,
    // 点検区分マスタ
    inspectionDivisionMaster: InspectionDivisionSlice.reducer,
    // 点検項目マスタ
    inspectionItemMaster: InspectionItemMasterSlice.reducer,
    // ユーザーマスタ
    userMaster: UserMasterSlice.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>

/**
 * アプリの Reducer を表します。
 * @param state Stateを指定します。
 * @param action アクションを指定します。
 */
export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {

    // サインアウト アクション時に Store の情報を全てリセットします。
    // 参考：https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
    if (action.type === "Authentication/userSignOut") {
        state = {} as RootState;
    }

    return combinedReducer(state, action);
};
