import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, Typography, TextField, Box, DialogContentText, Button, useTheme, makeStyles, Theme, createStyles, Divider } from '@material-ui/core';
import MaterialTable, { Column, Options } from 'material-table';
import { MuiTableIcons, MuiLocalizationJapanese, MuiTableOptions, useSortHeaderStyles } from '../../../../../components/MuiTable';
import { indigo, green, red, grey } from '@material-ui/core/colors';
import { DeletedFileItem } from '../../_types';
import { Alert, AlertTitle } from '@material-ui/lab';

/** 結果ダイアログの Props を表示します。 */
export interface DeleteResultDialogProps {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
    /** エラー有無を表します。 */
    isError: boolean,
    /** エラーメッセージを表します。 */
    errorMessage?: string,
    /**　処理に成功したファイルの一覧を表します。 */
    successedDataSource: DeletedFileItem[],
    /**　処理に失敗したファイルの一覧を表します。 */
    failedDataSource: DeletedFileItem[],
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // アラート
        alert: {
            alignItems: "center",
            "& .MuiAlert-icon": {
                fontSize: 40
            }
        }

    })
);

/** material-table の 共通オプションを提供します。 */
const TableOptions: Options<any> = {
    ...MuiTableOptions,
    paging: false,
    maxBodyHeight: "25vh", // 固定
    rowStyle: { padding: 4 },
    headerStyle: { padding: 4 }, // theme.spacing(0.5)
}

/** テーブルのカラムを表します。 */
const successedTableColumn: Column<DeletedFileItem>[] = [
    {
        title: "名前",
        field: "key",
    },
];

/** テーブルのカラムを表します。 */
const failedTableColumn: Column<DeletedFileItem>[] = [
    {
        title: "名前",
        field: "key",
        headerStyle: {
            minWidth: 400,
        },
    },
    {
        title: "エラー内容",
        field: "message",
        headerStyle: {
            width: 300,
            maxWidth: 300,
            whiteSpace: 'normal',
            wordBreak: 'break-word',
        },
    },
];


/** 結果ダイアログを表示します。 */
export function DeleteResultDialog(props: DeleteResultDialogProps): JSX.Element {
    /** Theme を提供します。*/
    const theme = useTheme();
    /** スタイルクラスを提供します。 */
    const tableStyle = useSortHeaderStyles();
    const classes = useStyles();

    /** タイトルアラートを表示します。 */
    const renderAlert = () => {
        if (props.isError) {
            // エラー
            return (
                <Alert severity="error" variant="outlined" className={classes.alert}>
                    <AlertTitle>Error</AlertTitle>
                    ファイル削除に失敗しました。<br />
                    {props.errorMessage}
                </Alert>
            )
        } else if (!(props.failedDataSource.length) && !(props.successedDataSource.length)) {
            // 正常だが、結果情報が空
            return <Alert severity="error" variant="outlined" className={classes.alert}>結果情報がありません。</Alert>

        } else {
            // 正常
            return (!(props.failedDataSource.length)
                ? <Alert severity="success" variant="outlined" className={classes.alert}>ファイル削除に成功しました。</Alert>
                : <Alert severity="error" variant="outlined" className={classes.alert}>ファイル削除に失敗したファイルがあります。</Alert>
            );
        }
    };

    return (
        <Dialog
            disableBackdropClick
            keepMounted
            fullWidth
            maxWidth="md"
            aria-labelledby="result-dialog-title"
            aria-describedby="result-dialog-description"
            open={props.isOpen}>

            <DialogTitle id="result-dialog-title">ファイル削除結果</DialogTitle>

            <DialogContent>
                {/* メッセージ */}
                <Box>{renderAlert}</Box>

                {/* 正常終了 ファイル */}
                <Box my={4} className={tableStyle.sortHeader}>
                    <Typography variant="h6">成功　{props.successedDataSource.length} 個</Typography>
                    <MaterialTable
                        icons={MuiTableIcons}
                        localization={MuiLocalizationJapanese}
                        options={{
                            ...TableOptions,
                            headerStyle: {
                                ...TableOptions.headerStyle,
                                backgroundColor: !(props.successedDataSource.length) ? "inherit" : theme.palette.success.main,
                            },
                        }}
                        columns={successedTableColumn}
                        data={props.successedDataSource.map(item => ({ ...item }))}
                    />
                </Box>

                {/* エラー ファイル */}
                <Box mb={4} className={tableStyle.sortHeader}>
                    <Typography variant="h6">エラー　{props.failedDataSource.length} 個</Typography>
                    <MaterialTable
                        icons={MuiTableIcons}
                        localization={MuiLocalizationJapanese}
                        options={{
                            ...TableOptions,
                            headerStyle: {
                                ...TableOptions.headerStyle,
                                backgroundColor: !(props.failedDataSource.length) ? "inherit" : theme.palette.error.light,
                            },
                        }}
                        columns={failedTableColumn}
                        data={props.failedDataSource.map(item => ({ ...item }))}
                    />
                </Box>


            </DialogContent>

            {/* ボタンアクション */}
            <DialogActions>
                <Button
                    // className={classes.button}
                    onClick={props.handleClose}
                >
                    閉じる
                </Button>
            </DialogActions>

        </Dialog>
    );
}
