import { MachineListItem, MachineInputItem } from '../../../pages/Master/Machine/_types';
import { MachineRequest, MachineMasterSelectBoxItemState } from './types';
import { MachineInput, MachineItem, ConnectedCtuType } from '../../swagger-clients';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `施工機マスタ` 関連の ユーティリティ機能を提供します。
 */
export class MachineUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(
        selectBoxItem: MachineMasterSelectBoxItemState,
        items?: MachineItem[],
    ): MachineListItem[] {
        const result: MachineListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.machineId == null
                || source.manufacturerId == null
                || source.modelId == null
                || source.modelYear == null
                || source.typeId == null
                || source.lockVersion == null) {
                continue;
            }

            // セレクトボックスの名称を取得
            const typeItem = selectBoxItem.MachineType.find(_ => _.key === source.typeId);
            const modelItem = selectBoxItem.Model.find(_ => _.key === source.modelId);
            const manufacturerItem = selectBoxItem.Manufacturer.find(_ => _.key === source.manufacturerId);
            const ctuItem = selectBoxItem.CtuId.find(_ => _.value === source.ctuId);
            const mainteCtuItem = selectBoxItem.MaintenanceCtuId.find(_ => _.value === source.maintenanceCtuId);
            const modelYear = source.modelYear == null ? "" : SharedUtility.toYearMonthString(new Date(source.modelYear));

            const item: MachineListItem = {
                id: source.machineId,
                type: typeItem?.value ?? "",
                typeId: source.typeId,
                ctuId: ctuItem?.value ?? "",
                maintenanceCtuId: mainteCtuItem?.value ?? "",
                model: modelItem?.value ?? "",
                modelId: source.modelId,
                modelYear: modelYear,
                manufacturer: manufacturerItem?.value ?? "",
                manufacturerId: source.manufacturerId,
                connectedCtu: SharedUtility.toConectedCtu(source.connectedCtu),
                connectedCtuText: SharedUtility.toConectedCtuText(source.connectedCtu),
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: MachineRequest): MachineInput {
        const result = new MachineInput();
        result.init({
            item: MachineUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: MachineInputItem): MachineItem {
        const result = new MachineItem();

        result.init({
            machineId: source.id,
            ctuId: source.ctuId,
            maintenanceCtuId: source.maintenanceCtuId,
            typeId: source.type,
            modelId: source.model,
            modelYear: source.modelYear == null ? void 0 : SharedUtility.toFormatDateString(source.modelYear),
            manufacturerId: source.manufacturer,
            connectedCtu: source.connectedCtu == null ? ConnectedCtuType.NotApplicable : SharedUtility.fromConectedCtu(source.connectedCtu),
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
