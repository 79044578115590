// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

/** スタイルを提供します。 */
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // テーブルソートヘッダー
        sortHeader: {
            // ソートされているカラム ヘッダー
            "& .MuiTableSortLabel-active": {
                color: theme.palette.text.primary,
            },
            // ソートアイコンカラー
            "& .MuiTableSortLabel-active .MuiTableSortLabel-icon": {
                color: `${theme.palette.text.primary}!important` as string,
            },
            // material-table スクロールバーをデフォルトに上書き
            "& > div > div > div > div": {
                overflowY: "visible !important",
            },
        },
        // 帳票アイコンボタン
        icon: {
            color: theme.palette.text.secondary
        },
        // 検印済み
        iconStamped: {
            color: theme.palette.success.main

        }

    })
);
