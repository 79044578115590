// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MaintenanceDivisionSlice } from '../../../../../modules/slice/maintenance-division/maintenance-division-slice';
// type
import { MaintenanceDivisionListItem } from '../../_types';
// component
import MaintenanceDivisionResultTable from './MaintenanceDivisionResultTable';

/** 整備区分マスタテーブルを提供します */
export const MaintenanceDivisionResultTableContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const data: MaintenanceDivisionListItem[] = useSelector((state: RootState) => state.maintenanceDivisionMaster.result.items ?? []);
    const selectedRow = useSelector((state: RootState) => state.maintenanceDivisionMaster.selectedRowItem);

    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const onRowClick = (rowData: MaintenanceDivisionListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを store に保持
        dispatch(MaintenanceDivisionSlice.actions.onSelectedItem({ ...rowData }))
    };

    return (
        <MaintenanceDivisionResultTable
            dataSource={data.map(item => ({ ...item }))} // storeにあるデータをそのまま渡すとErrorになるので複製・参照を切る
            onRowClick={onRowClick}
            selectedRow={selectedRow}
        />

    );

};
