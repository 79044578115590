import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import FudoLog from '../../../../../assets/FudoLogo.png';
import STLogo from '../../../../../assets/STLogo.png';

/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ロゴ
        logo: {
            height: "45px",
            margin: theme.spacing(1),
        },
    }),
);

/** ロゴを表示します。 */
const Logo: React.FC<{}> = () => {

    const classes = useStyles();

    return (
        < Box display="flex" >
            <img alt="LogoFudo" src={FudoLog} className={classes.logo} />
            <img alt="LogoST" src={STLogo} className={classes.logo} />
        </Box >
    );
}

export default Logo;
