import { MethodClient, MethodInput, MethodOutput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { MethodRequest, MethodResult } from './types';
import { MethodUtility } from './method-utility';

/**
 * `工法マスタ` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class MethodAdapter extends WebApiAdapter<MethodClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new MethodAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createErrorResult(message?: string): MethodResult {
        return {
            items: [],
            isError: true,
            errorMessage: message,
        };
    }

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: MethodRequest): Promise<MethodResult> {

        // 実行
        const input = new MethodInput();
        input.init(MethodUtility.toInput(parameter));
        const output = await this.client.get(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MethodResult = {
            isError: false,
            items: MethodUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * 追加を行います。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async addAsync(parameter: MethodRequest): Promise<MethodResult> {

        // 実行
        const input = new MethodInput();
        input.init(MethodUtility.toInput(parameter));
        const output = await this.client.add(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MethodResult = {
            isError: false,
            items: MethodUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * 更新を行います。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async updateAsync(parameter: MethodRequest): Promise<MethodResult> {

        // チェック
        const errorItem = parameter.item.id == null ? "ID"
            : parameter.item.lockVersion == null ? "LockVersion"
            : "";
        if (errorItem !== "") {
            throw new Error(`${errorItem} が指定されていません`);
        }

        // 実行
        const input = new MethodInput();
        input.init(MethodUtility.toInput(parameter));
        const output = await this.client.update(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MethodResult = {
            isError: false,
            items: MethodUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * 削除を行います。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async removeAsync(parameter: MethodRequest): Promise<MethodResult> {

        // チェック
        const errorItem = parameter.item.id == null ? "ID"
            : parameter.item.lockVersion == null ? "LockVersion"
                : "";
        if (errorItem !== "") {
            throw new Error(`${errorItem} が指定されていません`);
        }

        // 実行
        const input = new MethodInput();
        input.init(MethodUtility.toInput(parameter));
        const output = await this.client.remove(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MethodResult = {
            isError: false,
            items: MethodUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new MethodClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
