// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMachineMonitoringHeader } from '../../../modules/slice/global-header/global-header-slice';
import { MachineMonitoringSlice, onGetAsync } from '../../../modules/slice/machine-monitoring/machine-monitoring-slice';
// component
import TopAndBottomLayout from '../../../components/Layout/TopAndBottomLayout';
import SinglePageLayout from '../../../components/Layout/SinglePageLayout';
import TwoColumnLayout from '../../../components/Layout/TwoColumnLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { ConstructionMachineMonitorTableContainer } from './components/TableContent/ConstructionMachineMonitorTableContainer';
import { ConstructionMachineMonitorSearchContainer } from './components/SerachContent/ConstructionMachineMonitorSearchContainer';
import { UnlockDialogContainer } from './components/UnlockDialog/UnlockDialogContainer';
import { MachineMonitoringSearchType } from './_types';
import { AppTitle } from '../../Shared/app-title';
import { AreaToggleButtonContainer } from '../../../components/AreaToggleButton/AreaToggleButtonContainer';


/** 施工機モニター画面を提供します。 */
export const ConstructionMachineMonitoringPage: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();


    // redux store
    const isLoading = useSelector((state: RootState) => state.machineMonitoring.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.machineMonitoring.isShowMessage);
    const message = useSelector((state: RootState) => state.machineMonitoring.message);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(MachineMonitoringSlice.actions.hideMessage());
    };

    useEffect(() => {

        dispatch(changeMachineMonitoringHeader(AppTitle.machineMonitoring.title));

        // 初回マウント時に施工機の一覧取得を行う。
        dispatch(
            onGetAsync({
                item: {
                    type: MachineMonitoringSearchType.None,
                    machineKeyword: "",
                    siteKeyword: "",
                    reportKeyword: "",
                }
            })
        );

        // unmout 時
        return () => {
            // タイマークリア
            dispatch(MachineMonitoringSlice.actions.stopReloadTimer());
            // 入力内容クリア
            dispatch(MachineMonitoringSlice.actions.clearInputItem());
        }

    }, [dispatch]);

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* メイン画面 */}
            <SinglePageLayout>
                <TwoColumnLayout
                    // 検索エリア
                    side={
                        <TopAndBottomLayout
                            topContent={<ConstructionMachineMonitorSearchContainer />}
                            bottomContent={<AreaToggleButtonContainer />}
                        />
                    }
                    // 検査結果
                    main={<ConstructionMachineMonitorTableContainer />}
                />
            </SinglePageLayout>


            {/* ダイアログ */}
            <UnlockDialogContainer />
        </>
    );
};
