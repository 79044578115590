// react
import * as React from 'react';
import { useState } from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    TextField,
    FormControlLabel,
    Box,
    RadioGroup,
    Radio,
    FormControl,
} from '@material-ui/core';
// component
import { DailyInspectionRecordSearchType, StampSearchType } from '../../_types';
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import MuiKeybordDatePicker from '../../../../../components/MuiPickers/MuiKeybordDatePicker';
import { MuiDatePickerUtility } from '../../../../../components/MuiPickers/mui-date-picker-utility';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ラジオボタン
        radioGroup: {
            display: "inline-flex",
        },
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // 日付の間の '～'
        toFrom: {
            display: "flex",
            writingMode: "vertical-lr",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        // ボタン
        button: {
            marginTop: theme.spacing(2),
        },
        // グループ化 枠
        groupContainer: {
            position: "relative",
            padding: theme.spacing(2),
            marginTop: theme.spacing(1),
            borderStyle: "solid",
            borderRadius: 4,
            borderColor: theme.palette.text.disabled,
            borderWidth: 1,

        },
        // グループ化　タイトル
        groupTitle: {
            position: "absolute",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            top: "-12px",
            left: "-8px",
            fontWeight: "bold",
            backgroundColor: theme.palette.background.paper,
        }
    }),
);

/** 日常点検記録画面　検索エリアの State を表示します。 */
export interface DailyInspectionRecordSearchState {
    /**キーワードを表します。 */
    keyword: string,
    /** 検索条件を表します。 */
    searchType: DailyInspectionRecordSearchType,
    /** 検索条件を表します。 */
    searchStampType: StampSearchType,
    /** 開始日を表します。 */
    beginAt: Date | null,
    /** 終了日を表します。 */
    endAt: Date | null,
}

/** 日常点検記録画面　検索エリアのPropsを表示します。 */
interface Props {
    /** 入力初期値を表します。 */
    initInputItem: DailyInspectionRecordSearchState,
    /** 検索ボタンのクリック処理を表します。 */
    onSearchClick: (inputItem: DailyInspectionRecordSearchState) => void
}

/** 日常点検記録画面　検索エリアを表示します。 */
const DailyInspectionSearch: React.FC<Props> = (props) => {

    /** ラジオボタンリスト */
    const radioButtons = [
        { key: DailyInspectionRecordSearchType.Place, label: "作業場所" },
        { key: DailyInspectionRecordSearchType.Worker, label: "作業者" },
        { key: DailyInspectionRecordSearchType.Report, label: "報告事項" },
    ];

    const stampSearchTypeRadioButton = [
        { key: StampSearchType.None, label: "未指定" },
        { key: StampSearchType.Done, label: "検印済み" },
        { key: StampSearchType.NotYet, label: "検印なし" },
    ]


    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** 入力項目を提供します。*/
    const [values, setValues] = useState<DailyInspectionRecordSearchState>(props.initInputItem);

    /**
     * 入力値の変更処理を行います。
     * @param name　入力項目を指定します。
     */
    const onChange = (name: keyof DailyInspectionRecordSearchState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "radio" ? parseInt(target.value) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /**
     *  開始日の変更時の処理を行います。
     * @param newValue
     */
    const onChangeBeginAt = (newValue: Date | null) => {
        // 時間を ゼロ(00:00:000) に変換
        if (newValue != null) newValue.setHours(0, 0, 0, 0);

        const compEndAt = values?.endAt?.getTime() ?? 0
        const compBeginAt = newValue?.getTime() ?? 0

        setValues(preValues => (
            {
                ...preValues,
                beginAt: newValue,
                endAt: (compBeginAt > compEndAt) ? null : values.endAt, // 入力済の終了日より後の場合は、終了日を null に設定
            }
        ))
    };

    /**
     *  終了日の変更時の処理を行います。
     * @param newValue
     */
    const onChangeEndAt = (newValue: Date | null) => {
        // 時間を ゼロ(00:00:000) に変換
        if (newValue != null) newValue.setHours(0, 0, 0, 0);

        const compBeginAt = values?.beginAt?.getTime() ?? 0
        const compEndAt = newValue?.getTime() ?? 0

        setValues(preValues => (
            {
                ...preValues,
                beginAt: (compEndAt !== 0 && compBeginAt > compEndAt) ? null : values.beginAt, // 入力済みの開始日より前の場合は、開始日を null に設定
                endAt: newValue,
            }
        ))
    };

    return (
        <FormControl component="fieldset">

            <div className={clsx(classes.groupContainer, classes.inputItem)}>
                <Box className={classes.groupTitle}>
                    検索範囲
                    </Box>

                <TextField
                    fullWidth
                    label="キーワード"
                    id="outlined-size-small"
                    value={values.keyword}
                    className={classes.inputItem}
                    onChange={onChange("keyword")}
                />

                <RadioGroup
                    name="contents"
                    aria-label="contens"
                    value={values.searchType}
                    onChange={onChange("searchType")}
                    className={classes.radioGroup}
                >

                    {radioButtons.map((value) => (
                        <FormControlLabel
                            key={value.key}
                            value={value.key}
                            control={<Radio color="secondary" />}
                            label={value.label.toString()}
                        />
                    ))}

                </RadioGroup>
            </div>

            <Box fontWeight="fontWeightBold">
                日付
                </Box>

            {/* 開始日 */}
            <MuiKeybordDatePicker
                className={classes.inputItem}
                label="開始日"
                disableToolbar
                format={MuiDatePickerUtility.dateFormat}
                placeholder={MuiDatePickerUtility.dateFormat}
                value={values.beginAt}
                onChange={(data) => onChangeBeginAt(data)}
            />

            <div className={classes.toFrom}>～</div>

            {/* 終了日 */}
            <MuiKeybordDatePicker
                className={classes.inputItem}
                label="終了日"
                disableToolbar
                format={MuiDatePickerUtility.dateFormat}
                placeholder={MuiDatePickerUtility.dateFormat}
                value={values.endAt}
                onChange={(data) => onChangeEndAt(data)}
            />

            <Box fontWeight="fontWeightBold" marginTop={1}>
                検印
            </Box>

            <RadioGroup
                value={values.searchStampType}
                onChange={onChange("searchStampType")}
                className={clsx(classes.radioGroup)}
            >

                {stampSearchTypeRadioButton.map((value) => (
                    <FormControlLabel
                        key={`stamp-${value.key}`}
                        value={value.key}
                        control={<Radio color="secondary" />}
                        label={value.label.toString()}
                    />
                ))}

            </RadioGroup>

            {/* 検索ボタン */}
            <MuiSearchButton
                fullWidth
                className={classes.button}
                onClick={(event) => props.onSearchClick(values)}
            />

        </FormControl>
    );
};

export default DailyInspectionSearch;
