// react
import * as React from 'react';
// redux
import { useDispatch } from 'react-redux';
import { MethodMasterSlice, onExcuteAsync } from '../../../../../modules/slice/method/method-slice';
import { MethodAdapter } from '../../../../../modules/adapter/method/method-adapter';
// type
import { MethodInputItem } from '../../_types';
// component
import MethodSearchCondition from './MethodSearchCondition';

/**
 * メーカーマスタ　検索エリアを提供します。
 * */
export const MethodSearchConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: MethodInputItem) => {

        // 非同期通信
        const promise = MethodAdapter.instance.getAsync(
            { item: inputItem },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(MethodMasterSlice.actions.chageCreating(true))
    }

    // 表示
    return (
        <MethodSearchCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
        />
    );
};
