import { KeyValueItem, DayOfWeek, WeekOfMonth } from "../../../_types";
import { InspectedStampItem, StampUpdateStaus, UpdateInspectedStampListItem, InspecterType } from "../_types";
import { SharedUtility } from "../../../modules/shared/shared-utility";

/*
 * 施工機記録表関連のユーティリティ機能を提供します。
 */
export class ConstructionMacineMasterUtility {

    //#region フィールド

    /** 整備検印者種別文字配列を表します。 */
    static readonly InspecterTypeStrings: KeyValueItem<InspecterType, string>[] = [
        { key: InspecterType.FirstTeamLeader, value: "初検ー班長" },
        { key: InspecterType.CompleteTeamLeader, value: "完検ー班長" },
        { key: InspecterType.FirstManager, value: "初検ー課長" },
        { key: InspecterType.CompleteManager, value: "完検ー課長" },
    ];

    //#endregion　フィールド

    //#region メソッド

    /**
     * 整備検印者種別を文字列に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInspecterTypeText(source: InspecterType): string {
        const result: string | undefined = ConstructionMacineMasterUtility.InspecterTypeStrings.find(item => item.key === source)?.value;

        if (result == null) {
            throw new Error("toInspecterTypeText");
        }

        return result;
    }

    /**
     * 検印情報文字列を返します。
     * @param data 検印情報を指定します。
     * @returns 変換結果を返します。
     */
    public static toInspectedStampString(data: InspectedStampItem): string {
        const result = data.exist
            ? `${data.reportAt}`
            : "未検査"
        return result;
    }

    /**
     * 検印情報文字列を返します。
     * @param data 検印情報を指定します。
     * @returns 変換結果を返します。
     */
    public static toInspecterString(data: InspectedStampItem): string {
        const result = data.exist && !!data.inspector
            ? `${data.inspector}`
            : "未検印"
        return result;
    }

    /**
     * 整備記録表の検印情報のラジオボタンラベル文字列を返します。
     *
     * @param data 項目を指定します。
     * @returns 変換結果を返します。
     */
    public static toMaintenanceInspectionRadioLableString(data: KeyValueItem<InspecterType, InspectedStampItem>): string {
        // e.g. [初検ー班長]　山田
        return `［${ConstructionMacineMasterUtility.toInspecterTypeText(data.key)}］ ${ConstructionMacineMasterUtility.toInspecterString(data.value)}`
    }

    /**
     * 現場完了時点検記録表の検印情報のラジオボタンラベル文字列を返します。
     *
     * @param data 項目を指定します。
     * @returns 変換結果を返します。
     */
    public static toCompletionInspectionRadioLableString(data: InspectedStampItem): string {
        // e.g. E-現場完了-202004-0001
        return `${data.reportNo ?? ""}`
    }

    /**
     * 日常点検記録表の検印情報のラジオボタンラベル文字列を返します。
     *
     * @param data 項目を指定します。
     * @returns 変換結果を返します。
     */
    public static toDailyInspectionRadioLableString(data: KeyValueItem<DayOfWeek, InspectedStampItem>): string {
        // e.g. [日] 2020-04-01
        //      [月] 未検査
        return `［${SharedUtility.toDayOfWeekText(data.key)}］ ${ConstructionMacineMasterUtility.toInspectedStampString(data.value)}`
    }

    /**
     * 週間月例記録表の検印情報のラジオボタンラベル文字列を返します。
     *
     * @param data 項目を指定します。
     * @returns 変換結果を返します。
     */
    public static toWeeklyInspectionRadioLableString(data: KeyValueItem<WeekOfMonth, InspectedStampItem>): string {
        // e.g. [第1週] 2020-04-01
        //      [第2週] 未検査
        return `［${SharedUtility.toWeekOfMonthText(data.key)}］ ${ConstructionMacineMasterUtility.toInspectedStampString(data.value)}`
    }

    /**
     * 更新ステータスを取得します。
     * @param before 更新前の検印状態を指定します。
     * @param after　更新後の検印状態を指定します。
     * @returns 更新ステータスを返します。
     */
    public static getUpdateStatus(before?: boolean, after?: boolean): StampUpdateStaus {
        //  undefined を考慮して　`===` で厳密チェック
        const result: StampUpdateStaus =
            before === false && after === true ? StampUpdateStaus.Stamp
                : before === true && after === false ? StampUpdateStaus.SendBack
                    : StampUpdateStaus.None
        return result;
    }

    /**
     * 検印更新情報リストに変換します。
     * @param source　検印情報を指定します。
     * @param checkedId　入力情報を指定します。
     * @param inspectorName 検印者名を指定します。
     * @returns 検印更新情報リストを返します。
     */
    public static convertDailyListItem(source: KeyValueItem<DayOfWeek, InspectedStampItem>[], checkedId: DayOfWeek[], inspectorName: string): UpdateInspectedStampListItem<DayOfWeek>[] {
        const result: UpdateInspectedStampListItem<DayOfWeek>[] = [];
        source.map((data, index) => {
            const isStampBefore = data.value.exist && data.value.isStamped != null ? data.value.isStamped : void 0;
            const isStampAfter = data.value.exist && data.value.isStamped != null ? checkedId.includes(data.key) : void 0;

            const item: UpdateInspectedStampListItem<DayOfWeek> = {
                key: data.key,
                reportNo: data.value.reportNo,
                reportAt: data.value.reportAt,
                data: ConstructionMacineMasterUtility.toDailyInspectionRadioLableString(data),
                exist: data.value.exist,
                isStampBefore: isStampBefore,
                isStampAfter: isStampAfter,
                inspector: isStampAfter ? inspectorName : data.value.inspector,
                status: ConstructionMacineMasterUtility.getUpdateStatus(isStampBefore, isStampAfter),
            }
            result.push(item);
        });
        return result;
    }

    /**
     * 検印更新情報リストに変換します。
     * @param source　検印情報を指定します。
     * @param checkedId　入力情報を指定します。
     * @param inspectorName 検印者名を指定します。
     * @returns 検印更新情報リストを返します。
     */
    public static convertWeeklyListItem(source: KeyValueItem<WeekOfMonth, InspectedStampItem>[], checkedId: WeekOfMonth[], inspectorName: string): UpdateInspectedStampListItem<WeekOfMonth>[] {
        const result: UpdateInspectedStampListItem<WeekOfMonth>[] = [];
        source.map((data, index) => {
            const isStampBefore = data.value.exist && data.value.isStamped != null ? data.value.isStamped : void 0;
            const isStampAfter = data.value.exist && data.value.isStamped != null ? checkedId.includes(data.key) : void 0;

            const item: UpdateInspectedStampListItem<WeekOfMonth> = {
                key: data.key,
                reportNo: data.value.reportNo,
                reportAt: data.value.reportAt,
                data: ConstructionMacineMasterUtility.toWeeklyInspectionRadioLableString(data),
                exist: data.value.exist,
                isStampBefore: isStampBefore,
                isStampAfter: isStampAfter,
                inspector: isStampAfter ? inspectorName : data.value.inspector,
                status: ConstructionMacineMasterUtility.getUpdateStatus(isStampBefore, isStampAfter),
            }
            result.push(item);
        });
        return result;
    }
    /**
     * 検印更新情報リストに変換します。
     * @param isChecked　入力情報を指定します。
     * @param inspectorName 検印者名を指定します。
     * @param source　検印情報を指定します。
     * @returns 検印更新情報リストを返します。
     */
    public static convertCompletionInspectionListItem(isChecked: boolean, inspectorName: string, source?: InspectedStampItem): UpdateInspectedStampListItem<number>[] {
        const result: UpdateInspectedStampListItem<number>[] = [];
        if (source == null) return result;

        const isStampBefore = source.exist && source.isStamped != null ? source.isStamped : void 0;
        const isStampAfter = source.exist && source.isStamped != null ? isChecked : void 0;
        const status = ConstructionMacineMasterUtility.getUpdateStatus(isStampBefore, isStampAfter);

        const item: UpdateInspectedStampListItem<number> = {
            key: 0, // 固定(不使用)
            reportNo: source.reportNo,
            reportAt: source.reportAt,
            data: ConstructionMacineMasterUtility.toCompletionInspectionRadioLableString(source),
            exist: source.exist,
            isStampBefore: isStampBefore,
            isStampAfter: isStampAfter,
            status: status,
                inspector: status ===
                    StampUpdateStaus.None ? source.inspector // 変更無し：既存データのまま
                    : StampUpdateStaus.Stamp ? inspectorName // 検印：検印者名
                        : StampUpdateStaus.SendBack ? void 0 // 差し戻し：null
                            : void 0
        }
        result.push(item);

        return result;
    }

    /**
     * 検印更新情報リストに変換します。
     * @param source　検印情報を指定します。
     * @param checkedId　入力情報を指定します。
     * @param inspectorName 検印者名を指定します。
     * @returns 検印更新情報リストを返します。
     */
    public static convertMaintenanceListItem(source: KeyValueItem<InspecterType, InspectedStampItem>[], checkedId: InspecterType[], inspectorName: string): UpdateInspectedStampListItem<InspecterType>[] {
        const result: UpdateInspectedStampListItem<InspecterType>[] = [];
        if (source == null) return result;
        source.map((data, index) => {
            const isStampBefore = data.value.exist && data.value.isStamped != null ? data.value.isStamped : void 0;
            const isStampAfter = data.value.exist && data.value.isStamped != null ? checkedId.includes(data.key) : void 0;
            const status = ConstructionMacineMasterUtility.getUpdateStatus(isStampBefore, isStampAfter);

            const item: UpdateInspectedStampListItem<InspecterType> = {
                key: data.key,
                reportNo: data.value.reportNo,
                reportAt: data.value.reportAt,
                data: ConstructionMacineMasterUtility.toMaintenanceInspectionRadioLableString(data),
                exist: data.value.exist,
                isStampBefore: isStampBefore,
                isStampAfter: isStampAfter,
                status: status,
                inspector: status ===
                    StampUpdateStaus.None ? data.value.inspector // 変更無し：既存データのまま
                    : StampUpdateStaus.Stamp ? inspectorName // 検印：検印者名
                        : StampUpdateStaus.SendBack ? void 0 // 差し戻し：null
                            : void 0
            }
            result.push(item);
        });
        return result;
    }

    /**
     * 更新用の検印情報に変換します。
     * @param listItem　検印更新情報リストを指定します。
     * @param inspector　検印者を指定します。
     * @returns 検印情報を返します。
     */
    public static toUpdateDailyInspectedStampItem(listItem: UpdateInspectedStampListItem<DayOfWeek>[]): InspectedStampItem[] {
        const result: InspectedStampItem[] = [];

        listItem.forEach((item) => {
            if (item.status !== StampUpdateStaus.None) {
                const updateItem: InspectedStampItem = {
                    reportNo: item.reportNo,
                    reportAt: item.reportAt, // BE 不要
                    inspector: item.status === StampUpdateStaus.Stamp ? item.inspector : void 0,　// BE 検印する場合は　null or empty。 差し戻す場合は null。
                    exist: item.exist, // BE 不使用
                    isStamped: item.isStampAfter,　// BE 不使用
                }
                result.push(updateItem);
            }
        })

        return result;

    }

    /**
     * 更新用の検印情報に変換します。
     * @param listItem　検印更新情報リストを指定します。
     * @param inspector　検印者を指定します。
     * @returns 検印情報を返します。
     */
    public static toUpdateWeeklyInspectedStampItem(listItem: UpdateInspectedStampListItem<WeekOfMonth>[]): InspectedStampItem[] {
        const result: InspectedStampItem[] = [];

        listItem.forEach((item) => {
            if (item.status !== StampUpdateStaus.None) {
                const updateItem: InspectedStampItem = {
                    reportNo: item.reportNo,
                    reportAt: item.reportAt, // BE 不要
                    inspector: item.status === StampUpdateStaus.Stamp ? item.inspector : void 0,　// BE 検印する場合は　null or empty。 差し戻す場合は null。
                    exist: item.exist, // BE 不使用
                    isStamped: item.isStampAfter,　// BE 不使用
                }
                result.push(updateItem);
            }
        })

        return result;

    }

    /**
     * 更新用の検印情報に変換します。
     * @param listItem　検印更新情報リストを指定します。
     * @returns 検印情報を返します。
     */
    public static toUpdateCompletionInspectedStampItem(listItem: UpdateInspectedStampListItem<number>[]): InspectedStampItem | null {
        if (listItem.length !== 1) {
            return null;
        } else {
            const item = listItem[0];
            const result: InspectedStampItem = {
                reportNo: item.reportNo,
                reportAt: item.reportAt, // BE 不要
                inspector: item.status === StampUpdateStaus.Stamp ? item.inspector : void 0,　// BE 検印する場合は　null or empty。 差し戻す場合は null。
                exist: item.exist, // BE 不使用
                isStamped: item.isStampAfter,　// BE 不使用
            }
            return result
        }
    }

    /**
     * 更新用の検印情報に変換します。
     * @param listItem　検印更新情報リストを指定します。
     * @returns 検印情報を返します。
     */
    public static toUpdateMaintenanceInspectedStampItem(listItem: UpdateInspectedStampListItem<InspecterType>[]): InspectedStampItem | null {
        if (!listItem.length) {
            return null;
        } else {
            const item = listItem[0];
            const result: InspectedStampItem = {
                reportNo: item.reportNo,
                reportAt: item.reportAt, // BE 不要
                inspector: item.status === StampUpdateStaus.Stamp ? item.inspector : void 0,　// BE 検印する場合は　null or empty。 差し戻す場合は null。
                firstTeamLeader: ConstructionMacineMasterUtility.getInspecterName(InspecterType.FirstTeamLeader, listItem),
                completeTeamLeader: ConstructionMacineMasterUtility.getInspecterName(InspecterType.CompleteTeamLeader, listItem),
                firstManager: ConstructionMacineMasterUtility.getInspecterName(InspecterType.FirstManager, listItem),
                completeManager: ConstructionMacineMasterUtility.getInspecterName(InspecterType.CompleteManager, listItem),
                exist: item.exist, // BE 不使用
                isStamped: item.isStampAfter,　// BE 不使用
            }
            return result
        }
    }

    /**
     * 検印者名を取得します。
     * @param inspecterType
     * @param source
     */
    private static getInspecterName(inspecterType: InspecterType, source: UpdateInspectedStampListItem<InspecterType>[]): string | undefined {
        const inspecterItem = source.find(item => item.key === inspecterType)
        // Inspecter に検印者名が格納されている
        // 差し戻しの場合は null を返す
        const result = inspecterItem?.status === StampUpdateStaus.SendBack ? void 0 : inspecterItem?.inspector
        return result;
    }


    //#endregion メソッド

}
