import * as React from 'react';
import { SigninPage } from './Signin';

/**
 * サインイン画面(Account)を表します。
 */
export const AccountPage: React.FC<{}> = () => {
    return (
        <SigninPage />
    );
};
