// react
import * as React from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
// component
import { GraphContentUtility } from './GraphContetntUtility';
import { ConstructionRecordGraph } from './ConstructionRecordGraph';

/**
 * 施工記録画面 グラフを提供します。
 * */
export const ConstructionRecordGraphContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const activeCardList = useSelector((state: RootState) => state.machineOperationHistory.item.dataList);
    const graphDataSource = useSelector((state: RootState) => state.machineOperationHistory.result.getResult?.items ?? []);

    return (
        <ConstructionRecordGraph
            dataSource={graphDataSource}
            leftY={activeCardList.length > 0 ? GraphContentUtility.graphOption(activeCardList[0]) : void 0}
            rightY={activeCardList.length > 1 ? GraphContentUtility.graphOption(activeCardList[1]) : void 0}
        />
    );
};
