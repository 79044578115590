// react
import * as React from 'react';
import { useState } from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    TextField,
    FormControlLabel,
    Box,
    RadioGroup,
    Radio,
    FormControl,
} from '@material-ui/core';
// component
import { MachineMonitoringInputItem, MachineMonitoringSearchType } from '../../_types'
// 共通component
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ラジオボタンラベル
        radioGroupLabel: {
            fontWeight: "bold",
            marginTop: theme.spacing(1),
        },
        // ラジオボタン
        radioGroup: {
            display: "inline-flex",
        },
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // ボタン
        button: {
            marginTop: theme.spacing(2),
        },
        // グループ化 枠
        groupContainer: {
            position: "relative",
            padding: theme.spacing(2),
            marginTop: theme.spacing(1),
            borderStyle: "solid",
            borderRadius: 4,
            borderColor: theme.palette.text.disabled,
            borderWidth: 1,

        },
        // グループ化　タイトル
        groupTitle: {
            position: "absolute",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            top: "-12px",
            left: "-8px",
            fontWeight: "bold",
            backgroundColor: theme.palette.background.paper,
        }

    }),
);

/** 施工機モニター画面　検索エリアのPropsを表示します。 */
interface Props {
    /** 検索ボタンのクリック処理を表します。 */
    onSearchClick: (inputItem: MachineMonitoringInputItem) => void
}

/** 施工機モニター画面　検索エリアを表示します。 */
const ConstructionMachineMonitorSearch: React.FC<Props> = (props) => {

    /** ラジオボタンリスト */
    const radioButtons = [
        { key: MachineMonitoringSearchType.None, label: "すべて" },
        { key: MachineMonitoringSearchType.BaseMachine, label: "ベースマシン" },
        { key: MachineMonitoringSearchType.Auger, label: "オーガー" },
        { key: MachineMonitoringSearchType.Lift, label: "昇降装置" },

    ];

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** 入力項目を提供します。*/
    const [values, setValues] = useState<MachineMonitoringInputItem>({
        machineKeyword: "",
        type: MachineMonitoringSearchType.None,
        siteKeyword: "",
        reportKeyword: "",
    });

    /** 入力項目の変更状態を提供します。*/
    const [isInputChange, setIsInputChange] = useState<boolean>(false);

    /**
     * 入力値の変更処理を行います。
     * @param name　入力項目を指定します。
     */
    const onInputChange = (name: keyof MachineMonitoringInputItem) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別

        const target = event.target;
        const value = target.type === "radio" ? parseInt(target.value) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
        setIsInputChange(true);

    };

    /**
     * 検索ボタンクリック時の処理を行います。
     * @param event
     */
    const onSearchClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setIsInputChange(false);
        props.onSearchClick(values)
    };

    return (
        <>
            <Box>
                <div className={clsx(classes.groupContainer, classes.inputItem)}>
                    <span className={classes.groupTitle}>
                        施工機
                    </span>

                    <TextField
                        fullWidth
                        label="社番"
                        value={values.machineKeyword}
                        onChange={onInputChange("machineKeyword")}
                    />

                    <RadioGroup
                        name="contents"
                        aria-label="contens"
                        value={values.type}
                        onChange={onInputChange("type")}
                        className={clsx(classes.radioGroup)}

                    >

                        {radioButtons.map((value, index) => (
                            <FormControlLabel
                                key={index}
                                value={value.key}
                                control={<Radio color="secondary" />}
                                label={value.label.toString()}
                            />
                        ))}

                    </RadioGroup>

                </div>


                <TextField
                    fullWidth
                    label="作業場所"
                    color="secondary"
                    value={values.siteKeyword}
                    className={classes.inputItem}
                    onChange={onInputChange("siteKeyword")}
                />

                <TextField
                    fullWidth
                    label="報告事項"
                    color="secondary"
                    value={values.reportKeyword}
                    className={classes.inputItem}
                    onChange={onInputChange("reportKeyword")}
                />


                {/* 検索ボタン */}
                <MuiSearchButton
                    color={isInputChange ? "secondary" : "default"}
                    className={classes.button}
                    fullWidth
                    onClick={(event) => onSearchClick(event)}
                />

            </Box>
        </>
    );


};

export default ConstructionMachineMonitorSearch;
