// react
import * as React from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
// component
import { MachineStatusMonitoringBoolState } from '../_types/';


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "flex",
            alignItems: "center",
        },
        //　タイトル
        title: {
            alignSelf: "center",
        },
        // ステータス
        status: {
            fontSize: "1.5rem",
        },
        // コンテナ
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        // アイコン
        icon: {
            fontSize: "3rem",
            marginRight: "0.5rem",

        },
        // アイコンOFF状態
        iconGreen: {
            color: theme.palette.success.main,
        },
        // アイコンON状態
        iconRed: {
            color: theme.palette.error.main,
        },
        // アイコン未接続状態

        iconGray: {
            color: theme.palette.text.disabled,
        },
    }),
);

/** パラメーターカードの Props を提供します。 */
interface Props {
    /** タイトルを表します。 */
    title: string,
    /** ステータスの状態を表します。*/
    status?: MachineStatusMonitoringBoolState,
};

/** パラメーターカードを提供します。 */
const ParameterCard: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();


    function renderAlertStatus(status: MachineStatusMonitoringBoolState, title: string): JSX.Element {
        let result: JSX.Element;

        switch (status) {
            case MachineStatusMonitoringBoolState.NotAvailable:
                // 未接続
                result = (
                    <Card>
                        <CardContent className={classes.root}>
                            <Grid container>
                                <Grid item xs className={classes.title}>
                                    <Typography>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Box className={classes.container}>
                                        <RemoveCircleIcon className={clsx(classes.icon, classes.iconGray)} />
                                        <Typography className={classes.status}>
                                            ---
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card >
                );
                break;
            case MachineStatusMonitoringBoolState.On:
                // On状態
                result = (
                    <Card>
                        <CardContent className={classes.root}>
                            <Grid container>
                                <Grid item xs className={classes.title}>
                                    <Typography>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Box className={classes.container}>
                                        <CheckBoxIcon className={clsx(classes.icon, classes.iconRed)} />
                                        <Typography className={classes.status}>
                                            ON
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
                break;
            case MachineStatusMonitoringBoolState.Off:
                // Off状態
                result = (
                    <Card>
                        <CardContent className={classes.root}>
                            <Grid container>
                                <Grid item xs className={classes.title}>
                                    <Typography>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Box className={classes.container}>
                                        <IndeterminateCheckBoxIcon className={clsx(classes.icon, classes.iconGreen)} />
                                        <Typography className={classes.status}>
                                            OFF
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                );
                break;
        }

        return result;
    }

    return (
        <>
            {
                // CTU接続状態を確認
                props.status == null
                    ? (
                        // CTU未接続状態の場合
                        <Card>
                            <CardContent className={classes.root}>
                                <Grid container>
                                    <Grid item xs className={classes.title}>
                                        <Typography>
                                            {props.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Box className={classes.container}>
                                            <RemoveCircleIcon className={clsx(classes.icon, classes.iconGray)} />
                                            <Typography className={classes.status}>
                                                ---
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card >
                    )
                    : (
                        // CTU接続中の場合
                        <>
                            {renderAlertStatus(props.status, props.title)}
                        </>
                    )
            }
        </>
    );

};

export default ParameterCard;
