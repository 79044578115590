import * as React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
    }),
);

/** 上端下端レイアウトの Props を提供します。 */
interface Props {
    // 上端のコンテンツを表します。
    topContent: JSX.Element,
    /** 下端のコンテンツを表します。  */
    bottomContent: JSX.Element
};

/** 上端下端レイアウトを表示します。 */
const TopAndBottomLayout: React.FC<Props> = (props) => {
    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box className={classes.root} >
            <Box component="span">
                {props.topContent}
            </Box>

            <Box component="span">
                {props.bottomContent}
            </Box >


        </Box >

    );
}

export default TopAndBottomLayout;
