import { AreaType as DtoAreaType, MachineType as DtoMachineType, ConnectedCtuType as DtoConnectedCtuType, DayOfWeek as DtoDayOfWeek, WeekOfMonth as DtoWeekOfMonth } from '../swagger-clients';
import { AreaType, MachineType, ConnectedCtuType, DayOfWeek, WeekOfMonth, KeyValueItem } from '../../_types';

/**
 * 共通のユーティリティ機能を提供します。
 */
export class SharedUtility {

    //#region フィールド

    /** 曜日文字配列列を表します。 */
    static readonly DayOfWeekStrings: KeyValueItem<DayOfWeek, string>[] = [
        { key: DayOfWeek.Sunday, value: "日" },
        { key: DayOfWeek.Monday, value: "月" },
        { key: DayOfWeek.Tuesday, value: "火" },
        { key: DayOfWeek.Wednesday, value: "水" },
        { key: DayOfWeek.Thursday, value: "木" },
        { key: DayOfWeek.Friday, value: "金" },
        { key: DayOfWeek.Saturday, value: "土" }
    ];

    /** 週間文字配列を表します。 */
    static readonly WeekOfMonthStrings: KeyValueItem<WeekOfMonth, string>[] = [
        { key: WeekOfMonth.First, value: "第１週" },
        { key: WeekOfMonth.Second, value: "第２週" },
        { key: WeekOfMonth.Third, value: "第３週" },
        { key: WeekOfMonth.Fourth, value: "第４週" },
        { key: WeekOfMonth.Fifth, value: "第５週" },
    ];

    //#endregion フィールド


    /**
     * 日付文字列を返します。
     *
     * @param source 日時を指定します。
     * @returns 変換結果を返します。
     */
    public static toDateString(source: Date): string {
        const result = `${source.getFullYear()}/${(source.getMonth() + 1).toString().padStart(2, '0')}/${source.getDate().toString().padStart(2, '0')}`;
        return result;
    }

    /**
     * 時間文字列を返します。
     *
     * @param source 日時を指定します。
     * @param isSeconds 時間に秒を含めるかどうかを指定します。
     * @returns 変換結果を返します。
     */
    public static toTimeString(source: Date, isSeconds: boolean = true): string {
        let result = `${source.getHours().toString().padStart(2, '0')}:${source.getMinutes().toString().padStart(2, '0')}`;
        if (isSeconds === true) {
            result += `:${source.getSeconds().toString().padStart(2, '0')}`;
        }
        return result;
    }

    /**
     * 日時文字列を返します。
     *
     * @param source 日時を指定します。
     * @param isSeconds 時間に秒を含めるかどうかを指定します。
     * @returns 変換結果を返します。
     */
    public static toDateTimeString(source: Date, isSeconds: boolean = true): string {
        const result = `${SharedUtility.toDateString(source)} ${SharedUtility.toTimeString(source, isSeconds)}`;
        return result;
    }

    /**
    * 年月文字列を返します。
    *
    * @param source 日時を指定します。
    * @returns 変換結果を返します。
    */
    public static toYearMonthString(source: Date): string {
        const result = `${source.getFullYear()}/${(source.getMonth() + 1).toString().padStart(2, '0')}`;
        return result;
    }


    /**
     * エリア種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toAreaType(source: DtoAreaType): AreaType {
        const result: AreaType | undefined =
            source === DtoAreaType.East ? AreaType.East :
                source === DtoAreaType.West ? AreaType.West :
                    void 0;
        if (result == null) {
            throw new Error("toAreaType");
        }

        return result;
    }

    /**
     * エリア種別を文字列に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toAreaText(source: DtoAreaType): string {
        const result: string | undefined =
            source === DtoAreaType.East ? "東地区" :
                source === DtoAreaType.West ? "西地区" :
                    void 0;
        if (result == null) {
            throw new Error("toAreaText");
        }

        return result;
    }

    /**
     * 機器種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toMachineType(source: DtoMachineType): MachineType {
        const result = source === DtoMachineType.BaseMachine ? MachineType.BaseMachine :
            source === DtoMachineType.Auger ? MachineType.Auger :
                source === DtoMachineType.Lift ? MachineType.Lift :
                    void 0;

        if (result == null) {
            throw new Error("toMachineType");
        }
        return result;
    }

    /**
     * 機器種別を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static fromMachineType(source: MachineType): DtoMachineType {
        const result = source === MachineType.BaseMachine ? DtoMachineType.BaseMachine :
            source === MachineType.Auger ? DtoMachineType.Auger :
                source === MachineType.Lift ? DtoMachineType.Lift :
                    void 0;

        if (result == null) {
            throw new Error("fromMachineType");
        }
        return result;
    }

    /**
    * CTUあり/なしを変換します。
    *
    * @param source 変換元の値を指定します。
    * @returns 変換結果を返します。
    */
    public static toConectedCtu(source: DtoConnectedCtuType): ConnectedCtuType {
        const result = source === DtoConnectedCtuType.NotApplicable ? ConnectedCtuType.NotApplicable
            : source === DtoConnectedCtuType.Connected ? ConnectedCtuType.Connected
                : source === DtoConnectedCtuType.Disconnected ? ConnectedCtuType.Disconnected
                    : void 0;

        if (result == null) {
            throw new Error("toConectedCtu");
        }
        return result;
    }

    /**
    * CTUあり/なしを変換します。
    *
    * @param source 変換元の値を指定します。
    * @returns 変換結果を返します。
    */
    public static fromConectedCtu(source: ConnectedCtuType): DtoConnectedCtuType {
        const result = source === ConnectedCtuType.NotApplicable ? DtoConnectedCtuType.NotApplicable
            : source === ConnectedCtuType.Connected ? DtoConnectedCtuType.Connected
                : source === ConnectedCtuType.Disconnected ? DtoConnectedCtuType.Disconnected
                    : void 0;

        if (result == null) {
            throw new Error("fromConectedCtu");
        }
        return result;
    }

    /**
    * CTUあり/なしを文字列に変換します。
    *
    * @param source 変換元の値を指定します。
    * @returns 変換結果を返します。
    */
    public static toConectedCtuText(source: DtoConnectedCtuType): string {
        const result = source === DtoConnectedCtuType.NotApplicable ? "---"
            : source === DtoConnectedCtuType.Connected ? "あり"
                : source === DtoConnectedCtuType.Disconnected ? "なし"
                    : void 0;

        if (result == null) {
            throw new Error("toConectedCtuText");
        }
        return result;
    }

    // TODO kura UE.Core.Typescript -> UE.DateTimeFormat を使うのが理想
    /**
    * 日付文字列に変換します
    * @param data 日付を指定します。
    * @returns 文字列を変換します。引数が Nullable の場合は Undefined を返します。
    */
    public static toFormatDateString(date: Date): string {

        const yearStr = date.getFullYear().toString();
        const monthStr = (date.getMonth() + 1).toString().padStart(2, '0');
        const dayStr = date.getDate().toString().padStart(2, '0');
        const hourStr = date.getHours().toString().padStart(2, '0');
        const minuteStr = date.getMinutes().toString().padStart(2, '0');
        const secondStr = date.getSeconds().toString().padStart(2, '0');
        const milliSecondStr = date.getMilliseconds().toString().padStart(3, '0');

        let result = 'YYYY-MM-ddThh:mm:ss:SSS';
        result = result.replace(/YYYY/g, yearStr);
        result = result.replace(/MM/g, monthStr);
        result = result.replace(/dd/g, dayStr);
        result = result.replace(/hh/g, hourStr);
        result = result.replace(/mm/g, minuteStr);
        result = result.replace(/ss/g, secondStr);
        result = result.replace(/SSS/g, milliSecondStr);

        return result;
    };

    /**
     * 曜日を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toDayOfWeek(source: DtoDayOfWeek): DayOfWeek {
        const result: DayOfWeek | undefined =
            source === DtoDayOfWeek.Sunday ? DayOfWeek.Sunday :
                source === DtoDayOfWeek.Monday ? DayOfWeek.Monday :
                    source === DtoDayOfWeek.Tuesday ? DayOfWeek.Tuesday :
                        source === DtoDayOfWeek.Wednesday ? DayOfWeek.Wednesday :
                            source === DtoDayOfWeek.Thursday ? DayOfWeek.Thursday :
                                source === DtoDayOfWeek.Friday ? DayOfWeek.Friday :
                                    source === DtoDayOfWeek.Saturday ? DayOfWeek.Saturday :
                                        void 0;
        if (result == null) {
            throw new Error("toDayOfWeek");
        }

        return result;
    }

    /**
     * 曜日を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static fromDayOfWeek(source: DayOfWeek): DtoDayOfWeek {
        const result: DtoDayOfWeek | undefined =
            source === DayOfWeek.Sunday ? DtoDayOfWeek.Sunday :
                source === DayOfWeek.Monday ? DtoDayOfWeek.Monday :
                    source === DayOfWeek.Tuesday ? DtoDayOfWeek.Tuesday :
                        source === DayOfWeek.Wednesday ? DtoDayOfWeek.Wednesday :
                            source === DayOfWeek.Thursday ? DtoDayOfWeek.Thursday :
                                source === DayOfWeek.Friday ? DtoDayOfWeek.Friday :
                                    source === DayOfWeek.Saturday ? DtoDayOfWeek.Saturday :
                                        void 0;
        if (result == null) {
            throw new Error("fromDayOfWeek");
        }

        return result;
    }

    /**
     * 曜日を文字列に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toDayOfWeekText(source: DayOfWeek): string {
        const result: string | undefined = SharedUtility.DayOfWeekStrings.find(item => item.key === source)?.value;

        if (result == null) {
            throw new Error("toDayOfWeekText");
        }

        return result;
    }

    /**
     * 月内週間を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toWeekOfMonth(source: DtoWeekOfMonth): WeekOfMonth {
        const result: WeekOfMonth | undefined =
            source === DtoWeekOfMonth.First ? WeekOfMonth.First :
                source === DtoWeekOfMonth.Second ? WeekOfMonth.Second :
                    source === DtoWeekOfMonth.Third ? WeekOfMonth.Third :
                        source === DtoWeekOfMonth.Fourth ? WeekOfMonth.Fourth :
                            source === DtoWeekOfMonth.Fifth ? WeekOfMonth.Fifth :
                                void 0;
        if (result == null) {
            throw new Error("toWeekOfMonth");
        }

        return result;
    }

    /**
     * 月内週間を変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static fromWeekOfMonth(source: WeekOfMonth): DtoWeekOfMonth {
        const result: DtoWeekOfMonth | undefined =
            source === WeekOfMonth.First ? DtoWeekOfMonth.First :
                source === WeekOfMonth.Second ? DtoWeekOfMonth.Second :
                    source === WeekOfMonth.Third ? DtoWeekOfMonth.Third :
                        source === WeekOfMonth.Fourth ? DtoWeekOfMonth.Fourth :
                            source === WeekOfMonth.Fifth ? DtoWeekOfMonth.Fifth :
                                void 0;
        if (result == null) {
            throw new Error("fromWeekOfMonth");
        }

        return result;
    }

    /**
     * 月内週間を文字列に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toWeekOfMonthText(source: WeekOfMonth): string {
        const result: string | undefined = SharedUtility.WeekOfMonthStrings.find(item => item.key === source)?.value;

        if (result == null) {
            throw new Error("toWeekOfMonthText");
        }

        return result;
    }

}
