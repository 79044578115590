// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
// component
import AlertRecordTable from './AlertRecordTable';

/**
 * アラート履歴画面 テーブルを表示します。
 * */
export const AlertRecordTableContainer: React.FC<{}> = () => {

    // redux store
    const tableDataSource = useSelector((state: RootState) => state.machineErrorHistory.result.listItems);

    return (
        <AlertRecordTable
            dataSource={tableDataSource.map(item => ({ ...item }))} // storeにあるデータをそのまま渡すとErrorになるので複製・参照を切る
        />
    );
};
