import * as React from 'react';
import { FileManagePage } from './FileManage';
import { HeaderPage } from '../Shared/HeaderPage/HeaderPage';

/**
 * ファイル管理(FileManage)画面を表します。
 */
export const UploadPage: React.FC<{}> = () => {
    return (
        <HeaderPage children={<FileManagePage />} />
    );
};
