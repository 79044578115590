// react
import * as React from 'react';
import { useState } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { OtherReportSlice, onGetAsync } from '../../../../../modules/slice/other-report/other-report-slice';
// type
import { OtherReportListItem } from '../../_types';
import { AreaType } from '../../../../../_types';
import OtherReportTable from './OtherReportTable';

/**
 * その他報告画面テーブルを表示します。
 * @param props
 */
export const OtherReportTableContainer: React.FC<{}> = (props) => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const data: OtherReportListItem[] = useSelector((state: RootState) => state.otherReport.result.getResult?.items ?? []);
    const areaType: AreaType = useSelector((state: RootState) => state.areaType.area);

    /**
    * モーダル表示時のイベント処理を行います。
    * @param record
    */
    const onClickShowModal = (record: OtherReportListItem) => {
        dispatch(OtherReportSlice.actions.onReportClick(record));
    }

    /**
    * エリア種別のフィルター処理を行います。
    * @param source テーブルデータを指定します。
    * @param areaType エリア種別を指定します。
    * @returns フィルタ後のテーブルデータを返します。
    */
    const areaTypeFilter = (source: OtherReportListItem[], areaType: AreaType) => {

        // エリアが確定していない施工機は全国でしか表示しない。
        // material-tableにデータをそのまま渡すとErrorになるので複製・参照を切る
        return source.filter(item => (areaType === AreaType.None ? true : item.areaType === areaType)).map(item => ({ ...item }));

    }

    return (
        <OtherReportTable
            dataSource={areaTypeFilter(data, areaType)}
            onClickShowModal={onClickShowModal}
        />

    )
}
