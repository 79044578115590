// redux
import { Dispatch } from 'redux';
// shared
import { MasterGenericState, createMasterGenericSlice } from '../shared/create-master-slice';
// type
import { ManufacturerAdapter } from '../../adapter/manufacturer/manufacturer-adapter';
import { ManufacturerRequest, ManufacturerResult } from '../../adapter/manufacturer/types';
import { ManufacturerListItem } from '../../../pages/Master/Manufacturer/_types';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';

// State の初期値を設定します。
const initialState: MasterGenericState<ManufacturerResult, ManufacturerListItem, null> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    isCreating: false,
    isSelected: false,
    selectedRowItem: void 0,
    selectBoxItem: null,
    isDialogOpen: false,
}

/** 取得 Slice を 作成します。*/
export const ManufacturerMasterSlice = createMasterGenericSlice({
    // State Reducer Action を生成
    name: 'Manufacturer',
    initialState: initialState,
    reducers: {},
})

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onExcuteAsync = (promise: Promise<ManufacturerResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(ManufacturerMasterSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(ManufacturerMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(ManufacturerMasterSlice.actions.showErrorMessage());
        } else {
            // 正常
            dispatch(ManufacturerMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(ManufacturerMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(ManufacturerMasterSlice.actions.chageCreating(false));
        }

    } catch (error) {

        //例外
        dispatch(ManufacturerMasterSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(ManufacturerMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(ManufacturerMasterSlice.actions.fetchEnd());
    }
}

/**
 * 画面表示時の初回非同期通信処理を行います。
 * @param action
 */
export const onExcuteMountedAsync = () => async (dispatch: Dispatch) => {

    try {
        // state リセット
        dispatch(ManufacturerMasterSlice.actions.resetState());


        // 処理中 ON
        dispatch(ManufacturerMasterSlice.actions.fetchStart());

        // データ取得
        const result = await ManufacturerAdapter.instance.getAsync({ item: {} });

        if (result.isError) {
            // 異常
            dispatch(ManufacturerMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(ManufacturerMasterSlice.actions.showErrorMessage(result.errorMessage));
        } else {
            // 正常
            dispatch(ManufacturerMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(ManufacturerMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(ManufacturerMasterSlice.actions.chageCreating(false));
        }

    } catch (error) {

        // メッセージ
        dispatch(ManufacturerMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(ManufacturerMasterSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 * @param password
 */
export const onConfirmRemoveAsync = (
    parameter: ManufacturerRequest,
    password: string,
) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(ManufacturerMasterSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(ManufacturerMasterSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));

        } else {
            // 正常
            // 削除実行
            const result = await ManufacturerAdapter.instance.removeAsync(parameter);

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(ManufacturerMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(ManufacturerMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(ManufacturerMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(ManufacturerMasterSlice.actions.showSuccessMessage("削除しました。"));

                // ダイアログを閉じる
                dispatch(ManufacturerMasterSlice.actions.toggleDialog(false));

                // 選択項目のクリア
                dispatch(ManufacturerMasterSlice.actions.clearSelectedItem());
            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(ManufacturerMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(ManufacturerMasterSlice.actions.fetchEnd());
    }

}
