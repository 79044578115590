import { MachineClient, MachineInput, MachineOutput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { MachineRequest, MachineResult, MachineMasterSelectBoxItemState } from './types';
import { MachineUtility } from './machine-utility';

/**
 * `施工機マスタ` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class MachineAdapter extends WebApiAdapter<MachineClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new MachineAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createErrorResult(message?: string): MachineResult {
        return {
            items: [],
            isError: true,
            errorMessage: message,
        };
    }

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(
        parameter: MachineRequest,
        selectItem: MachineMasterSelectBoxItemState,
    ): Promise<MachineResult> {

        // 実行
        const input = new MachineInput();
        input.init(MachineUtility.toInput(parameter));

        const output = await this.client.get(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineResult = {
            isError: false,
            items: MachineUtility.toListItem(selectItem, output.items),
        };
        return result;
    }

    /**
     * 追加を行います。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async addAsync(
        parameter: MachineRequest,
        selectItem: MachineMasterSelectBoxItemState,
    ): Promise<MachineResult> {

        // 実行
        const input = new MachineInput();
        input.init(MachineUtility.toInput(parameter));

        const output = await this.client.add(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineResult = {
            isError: false,
            items: MachineUtility.toListItem(selectItem, output.items),
        };
        return result;
    }

    /**
     * 更新を行います。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async updateAsync(
        parameter: MachineRequest,
        selectItem: MachineMasterSelectBoxItemState,
    ): Promise<MachineResult> {

        // チェック
        const errorItem = parameter.item.id == null ? "ID"
            : parameter.item.type == null ? "施工機種別 ID"
            : parameter.item.ctuId == null ? "CTU番号"
            : parameter.item.model == null ? "型式 ID"
            : parameter.item.manufacturer == null ? "メーカー ID"
            : parameter.item.modelYear == null ? "年式 ID"
            : parameter.item.lockVersion == null ? "LockVersion"
            : "";
        if (errorItem !== "") {
            throw new Error(`${errorItem} が指定されていません`);
        }

        // 実行
        const input = new MachineInput();
        input.init(MachineUtility.toInput(parameter));

        const output = await this.client.update(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineResult = {
            isError: false,
            items: MachineUtility.toListItem(selectItem, output.items),
        };
        return result;
    }

    /**
     * 削除を行います。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async removeAsync(
        parameter: MachineRequest,
        selectItem: MachineMasterSelectBoxItemState,
    ): Promise<MachineResult> {

        // チェック
        const errorItem = parameter.item.id == null ? "ID"
            : parameter.item.type == null ? "施工機種別"
            : parameter.item.lockVersion == null ? "LockVersion"
            : "";
        if (errorItem !== "") {
            throw new Error(`${errorItem} が指定されていません`);
        }

        // 実行
        const input = new MachineInput();
        input.init(MachineUtility.toInput(parameter));
        const output = await this.client.remove(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineResult = {
            isError: false,
            items: MachineUtility.toListItem(selectItem, output.items),
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new MachineClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
