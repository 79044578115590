import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { changeDarkTheme } from '../../modules/slice/theme/theme-slice';
import { ThemeToggleButton } from './ThemeToggleButton';

/**
 * ダークモードトグルボタンを表示します。
 * @param param0
 */
export const ThemeToggleButtonContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const isDark = useSelector((state: RootState) => state.theme.isDark);

    /** ダークモードON処理を行います。 */
    const onClickDarkThemeOn = () => dispatch(changeDarkTheme(true));

    /** ダークモードOFF処理を行います。 */
    const onClickDarkThemeOff = () => dispatch(changeDarkTheme(false));

    return (
        <ThemeToggleButton
            isDark={isDark}
            onClickDarkThemeOn={onClickDarkThemeOn}
            onClickDarkThemeOff={onClickDarkThemeOff}
        />
    );
};
