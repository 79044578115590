// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MachineMasterSlice } from '../../../../../modules/slice/machine/machine-slice';
// type
import { MachineListItem } from '../../_types';
// component
import MachineResultTable from './MachineResultTable'

/** 整備項目テーブルを提供します */
export const MachineResultTableContainer: React.FC<{}> = (props) => {


    const dispatch = useDispatch();

    // redux store
    const data: MachineListItem[] = useSelector((state: RootState) => state.machineMaster.result.items ?? []);
    const selectedRow = useSelector((state: RootState) => state.machineMaster.selectedRowItem);

    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const onRowClick = (rowData: MachineListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを store に保持
        dispatch(MachineMasterSlice.actions.onSelectedItem({ ...rowData }))

    };

    return (

        <MachineResultTable
            dataSource={data.map(item => ({ ...item }))} // storeにあるデータをそのまま渡すとErrorになるので複製・参照を切る
            onRowClick={onRowClick}
            selectedRow={selectedRow}
        />


    );

}
