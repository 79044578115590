// react
import * as React from 'react';
// material-ui
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// サイドの幅 文字列指定
const sideWidth = "340px";

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        searchBox: {
            width: sideWidth,
            padding: theme.spacing(2),
            display: "flex",
        },
        tableBox: {
            flexGrow: 1,
            padding: theme.spacing(1),
            width: `calc(100% - (${sideWidth} + ${theme.spacing(2) * 2}px))`, // 横 100% - (検索入力項目 + padding(左右))
        },
    }),
);

/** 2 カラム レイアウト の　Props を提供します。 */
interface Props {
    // サイドコンテンツを表します。
    side: React.ReactNode,
    // メインコンテンツを表します。
    main: React.ReactNode,
};

/** 2 カラム レイアウト を表示します。 */
const TwoColumnLayout: React.FC<Props> = (props) => {
    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box display="flex">
            {/* 検索エリア */}
            <Box className={classes.searchBox}>
                {props.side}
            </Box>

            {/* 検索結果 */}
            <Box className={classes.tableBox}>
                {props.main}
            </Box>
        </Box>
    );
}

export default TwoColumnLayout;
