import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteResultDialog } from './DeleteResultDialog';
import { FileManageSlice } from '../../../../../modules/slice/file-manage/file-manage-slice';
import { RootState } from '../../../../../rootReducer';


/** 結果ダイアログを表示します。 */
export function DeleteResultDialogContainer() {
    const dispatch = useDispatch();

    const isOpen = useSelector((state: RootState) => state.fileManage.item.isOpenDeleteResultDialog);
    const result = useSelector((state: RootState) => state.fileManage.result.deleteResult);

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        dispatch(FileManageSlice.actions.updateItemPartial({
            isUpdated: true,
            isOpenDeleteResultDialog : false,
        }));
    };

    return (
        <DeleteResultDialog
            isOpen={isOpen}
            handleClose={closeDialog}
            isError={result?.isError ?? false}
            errorMessage={result?.errorMessage}
            successedDataSource={result?.successItems ?? []}
            failedDataSource={result?.errorItems ?? []}        />
    );
}
