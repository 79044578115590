// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MaintenanceItemMasterSlice } from '../../../../../modules/slice/maintenance-item/maintenance-item-slice';
// type
import { MaintenanceItemListItem } from '../../_types';
// component
import MaintenanceItemResultTable from './MaintenanceItemResultTable'

/** 整備項目マスタテーブルを提供します */
export const MaintenanceItemResultTableContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const data: MaintenanceItemListItem[] = useSelector((state: RootState) => state.maintenanceItemMaster.result.items ?? []);
    const selectedRow = useSelector((state: RootState) => state.maintenanceItemMaster.selectedRowItem);
    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const onRowClick = (rowData: MaintenanceItemListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを store に保持
        dispatch(MaintenanceItemMasterSlice.actions.onSelectedItem({ ...rowData }))
    };

    return (
        <MaintenanceItemResultTable
            dataSource={data.map(item => ({ ...item }))} // storeにあるデータをそのまま渡すとErrorになるので複製・参照を切る
            onRowClick={onRowClick}
            selectedRow={selectedRow}
        />

    );

};
