//react
import * as React from 'react';
import { HomeDirectoryType } from '../../_types';
import { HomeDirTypeToggle } from './HomeTypeToggle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { FileManageSlice } from '../../../../../modules/slice/file-manage/file-manage-slice';

/**
 * ディレクトリタイプ トグルボタンを表示します。
 * */
export function HomeDirTypeToggleContainer(): JSX.Element {
    const dispatch = useDispatch();

    const homeType: HomeDirectoryType = useSelector((state: RootState) => state.fileManage.item.directoryItem.home);
    const root: string = useSelector((state: RootState) => state.fileManage.item.directoryItem.root);

    /**
     * 選択変更イベントを処理します。
     * @param event
     * @param selected
     */
    const onChangeType = (event: React.MouseEvent<HTMLElement>, selected: HomeDirectoryType | null) => {
        if (selected !== null) {
            dispatch(FileManageSlice.actions.updateItemPartial({
                isUpdated: true,
                directoryItem: {
                    root: root,
                    home: selected,
                    sub: []
                },
            }));
        };
    };

    return (
        <HomeDirTypeToggle
            type={homeType}
            onChange={onChangeType}
        />
    );
}
