// react
import * as React from 'react';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
// type
import { MachineInputItem } from '../../_types';
import { MachineType, ConnectedCtuType } from '../../../../../_types';
// component
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import MuiCreateButton from '../../../../../components/Buttons/MuiCreateButton';
import { SharedUtility } from '../../../../../modules/shared/shared-utility'
import { MachineType as DtoMachineType } from '../../../../../modules/swagger-clients';
import MuiKeybordDatePicker from '../../../../../components/MuiPickers/MuiKeybordDatePicker';
import { MuiDatePickerUtility } from '../../../../../components/MuiPickers/mui-date-picker-utility';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },
        radioGroup: {
            margin: theme.spacing(1, 1, 0, 1),
        },
        // ラジオボタングループラベル
        radioGroupLabel: {
            marginBottom: 0,
        },
        // ラジオボタングループ
        radioGroupButton: {
            flexDirection: "row"
        },
    }),
);

/** 施工機マスタ　検索項目の Props を提供します。 */
interface Props {
    /** 検索ボタンのクリック処理を行います。 */
    onClickSearch: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: MachineInputItem) => void,
    /** 新規作成ボタンのクリック処理を行います。 */
    onClickAdd: (() => void),
    /** 施工機種別一覧を表します。*/
    typeList: { key: number, value: string }[],
    /** 型式一覧を表します。 */
    modelList: { key: number, value: string }[],
    /** メーカー一覧を表します。 */
    manufacturerList: { key: number, value: string }[],
};

/**
 * 施工機マスタ　検索エリアを提供します。
 * */
const MachineSearchCondition: React.FC<Props> = (props) => {

    /** ラジオボタンリスト */
    const radioButtons = [
        { key: ConnectedCtuType.NotApplicable, label: "未指定" },
        { key: ConnectedCtuType.Connected, label: "CTUあり" },
        { key: ConnectedCtuType.Disconnected, label: "CTUなし" },
    ];

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<MachineInputItem>({});

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (name: keyof MachineInputItem) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "radio" ? parseInt(target.value) : target.value;

        if (name === "type") {
            const selctedTypeId = typeof value === "number" ? value : parseInt(target.value)

            if (!checkSelectedBaseMachine(selctedTypeId)) {
                // ベースマシン以外が選択された場合
                // 入力項目更新
                setValues({
                    ...values,
                    type: selctedTypeId,
                    connectedCtu: ConnectedCtuType.NotApplicable　// CTUありなしを未指定に設定
                });
            } else {
                // 入力項目更新
                setValues({ ...values, type: selctedTypeId });
            }

        } else {

            // 入力項目更新
            setValues({ ...values, [name]: value });

        }
    };

    /**
     *  日付の変更時の処理を行います。
     * @param newValue
     */
    const onChangeDate = (newValue: Date | null) => {

        if (newValue != null) {
            newValue.setDate(1); // 日付を 1 に設定
            newValue.setHours(0, 0, 0, 0); // 時間を ゼロ(00:00:000) に設定
        }

        setValues(preValues => (
            {
                ...preValues,
                modelYear: newValue,
            }
        ))
    };

    /**
     * 選択された施工機種別がベースマシンかどうかを判定します。
     * @param value
     * @returns ベースマシンの場合は true を、それ以外の場合は false を返します。
     */
    const checkSelectedBaseMachine = (value?: number): boolean => {
        // マスタ系画面では施工機種別はリストで受け取っいる。
        // リストは BE側で Enum で定義されている　1: ベースマシン 2: 昇降装置　3：オーガー 0: 未指定(この画面で定義)
        // number -> BE定義のEnum -> FE定義のEnum変換が必要

        let result = false;
        if (value != null && value in DtoMachineType) {
            result = SharedUtility.toMachineType(value) === MachineType.BaseMachine
        }

        return result;
    }

    // 表示
    return (

        <Box padding={1}>
            {/* 施工機種別 */}
            <TextField
                select
                SelectProps={{
                    MenuProps: {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }
                }}
                label="施工機種別"
                placeholder="施工機種別"
                value={values.type ?? ""}
                onChange={onChange("type")}

            >
                {/* Default */}
                <MenuItem key={0} value={0}> {"未指定"}</MenuItem>

                {/*　選択肢 */}
                {
                    props.typeList.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    )
                    )
                }

            </TextField>

            {/* 社番 */}
            <TextField
                label="社番"
                placeholder="社番"
                value={values.id ?? ""}
                onChange={onChange("id")}
            />


            {/* 型式 */}
            <TextField
                select
                SelectProps={{
                    MenuProps: {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }
                }}
                label="型式"
                placeholder="型式"
                value={values.model ?? ""}
                onChange={onChange("model")}

            >
                {/* Default */}
                <MenuItem key={0} value={0}> {"未指定"}</MenuItem>

                {/*　選択肢 */}
                {
                    props.modelList.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    ))
                }

            </TextField>

            {/* メーカー */}
            <TextField
                select
                SelectProps={{
                    MenuProps: {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }
                }}
                label="メーカー"
                placeholder="メーカー"
                value={values.manufacturer ?? ""}
                onChange={onChange("manufacturer")}

            >
                {/* Default */}
                <MenuItem key={0} value={0}> {"未指定"}</MenuItem>

                {/*　選択肢 */}
                {
                    props.manufacturerList.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    )
                    )
                }

            </TextField>

            {/* 年式 */}
            <MuiKeybordDatePicker
                label="年式"
                views={["year", "month"]}
                openTo="month"
                format={MuiDatePickerUtility.yearAndMonthFormat}
                placeholder={MuiDatePickerUtility.yearAndMonthFormat}
                value={values.modelYear == null ? null : values.modelYear} // 年式カレンダー：undefined -> 今の日付が挿入される為、null代入
                onChange={(data) => onChangeDate(data)}
            />


            {/* CTU ありなし */}
            <FormControl className={classes.radioGroup}>
                <FormLabel component="legend" className={classes.radioGroupLabel}>CTUあり/なし</FormLabel>
                <RadioGroup
                    className={classes.radioGroupButton}
                    aria-label="connectedCtu"
                    name="connectedCtu"
                    value={values.connectedCtu == null ? ConnectedCtuType.NotApplicable : values.connectedCtu}
                    onChange={onChange("connectedCtu")}
                >
                    {radioButtons.map((value) => (
                        <FormControlLabel
                            key={value.key}
                            value={value.key}
                            control={<Radio color="secondary" />}
                            label={value.label}
                            disabled={!checkSelectedBaseMachine(values.type)}
                        />
                    ))}
                </RadioGroup>
            </FormControl>


            {/* ボタングループ */}
            <Box>
                {/* 検索ボタン */}
                <MuiSearchButton
                    className={classes.button}

                    onClick={(event) => props.onClickSearch(event, values)}
                />
                {/* 新規登録ボタン */}
                <MuiCreateButton
                    className={classes.button}
                    onClick={(event) => props.onClickAdd()}
                />
            </Box>

        </Box>

    );
};

export default MachineSearchCondition;
