// react
import * as React from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
        },
        // バージョン情報
        version: {
            fontSize: "1.25rem",
            margin: "0 0.5rem",
        },
    }),
);

/** CTUバージョン情報の Props を提供します。 */
interface Props {
    /** バージョンを表します。 */
    version: string,
};

/** CTUバージョン情報を表示します。 */
const CtuVersionInformation: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography>
                CTU バージョン：
            </Typography>
            <Typography className={classes.version}>
                {props.version}
            </Typography>
        </Box>
    );

};

export default CtuVersionInformation;
