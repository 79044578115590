// redux
import { Dispatch } from 'redux';
// shared
import { MasterGenericState, createMasterGenericSlice } from '../shared/create-master-slice';
// type
import { UserAdapter } from '../../adapter/user/user-adapter';
import { UserRequest, UserResult } from '../../adapter/user/types';
import { UserListItem } from '../../../pages/Master/User/_types';
import { PayloadAction } from '@reduxjs/toolkit';

// State の初期値を設定します。
const initialState: MasterGenericState<UserResult, UserListItem, null> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    isCreating: false,
    isSelected: false,
    selectedRowItem: void 0,
    selectBoxItem: null,
    isDialogOpen: false,
    isUpdated: false,
}

/** 取得 Slice を 作成します。*/
export const UserMasterSlice = createMasterGenericSlice({
    // State Reducer Action を生成
    name: 'User',
    initialState: initialState,
    reducers: {

        toggleSingoutDialog(state, action: PayloadAction<boolean>) {
            state.isUpdated = action.payload;
        }
    },
})

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onExcuteAsync = (promise: Promise<UserResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(UserMasterSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(UserMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(UserMasterSlice.actions.showErrorMessage());
        } else {
            // 正常
            dispatch(UserMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(UserMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(UserMasterSlice.actions.chageCreating(false));
        }

    } catch (error) {

        //例外
        dispatch(UserMasterSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(UserMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(UserMasterSlice.actions.fetchEnd());
    }
}

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onUpdateAsync = (promise: Promise<UserResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(UserMasterSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(UserMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(UserMasterSlice.actions.showErrorMessage());
        } else {
            // 正常
            dispatch(UserMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(UserMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(UserMasterSlice.actions.chageCreating(false));

            // サインアウトダイアログを開く
            dispatch(UserMasterSlice.actions.toggleSingoutDialog(true));
        }

    } catch (error) {

        //例外
        dispatch(UserMasterSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(UserMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(UserMasterSlice.actions.fetchEnd());
    }
}

/**
 * 画面表示時の初回非同期通信処理を行います。
 * @param action
 */
export const onExcuteMountedAsync = () => async (dispatch: Dispatch) => {

    try {
        // state リセット
        dispatch(UserMasterSlice.actions.resetState());

        // 処理中 ON
        dispatch(UserMasterSlice.actions.fetchStart());

        // データ取得
        const result = await UserAdapter.instance.getAsync({ item: {} });

        if (result.isError) {
            // 異常
            dispatch(UserMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(UserMasterSlice.actions.showErrorMessage(result.errorMessage));
        } else {
            // 正常
            dispatch(UserMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(UserMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(UserMasterSlice.actions.chageCreating(false));
        }

    } catch (error) {

        // メッセージ
        dispatch(UserMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(UserMasterSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 * @param password
 */
export const onConfirmRemoveAsync = (
    parameter: UserRequest,
    password: string,
    isAuthorized?: boolean
) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(UserMasterSlice.actions.fetchStart());

        // パスワード確認
        if (parameter.item.password !== password) {
            // 異常メッセージ
            dispatch(UserMasterSlice.actions.showErrorMessage("パスワードが違います"));

        } else {
            // 正常
            // 削除実行
            const result = await UserAdapter.instance.removeAsync(parameter);

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(UserMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(UserMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(UserMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(UserMasterSlice.actions.showSuccessMessage("削除しました。"));

                // ダイアログを閉じる
                dispatch(UserMasterSlice.actions.toggleDialog(false));

                // 選択項目のクリア
                dispatch(UserMasterSlice.actions.clearSelectedItem());

                // サインアウトダイアログを開く
                if (isAuthorized) dispatch(UserMasterSlice.actions.toggleSingoutDialog(true));
            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(UserMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(UserMasterSlice.actions.fetchEnd());
    }

}
