import * as React from 'react';
// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import DownloadButton from './DownloadButton';
import { MachineOperationHistoryGraphItem } from '../../_types';

/** CSV ダウンロードボタンを表示します */
export const DownloadButtonContainer: React.FC<{}> = () => {

    const graphDataSource: MachineOperationHistoryGraphItem[] = useSelector((state: RootState) => state.machineOperationHistory.result.getResult?.items ?? []);

    return (
        <DownloadButton
            graphDataSource={graphDataSource}
            isDisabled={!graphDataSource.length}
        />
    );
}
