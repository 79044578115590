import { DailyInspectionRecordClient, GetDailyInspectionRecordInput, GetDocumentInput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { DailyInspectionRecordGetRequest, DailyInspectionRecordGetResult, DailyInspectionRecordGetDocumentRequest, DailyInspectionRecordGetDocumentResult } from './types';
import { DailyInspectionRecordUtility } from './daily-inspection-record-utility';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `日常点検記録` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class DailyInspectionRecordAdapter extends WebApiAdapter<DailyInspectionRecordClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new DailyInspectionRecordAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: DailyInspectionRecordGetRequest): Promise<DailyInspectionRecordGetResult> {
        function createErrorResult(message?: string): DailyInspectionRecordGetResult {
            return {
                items: [],
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetDailyInspectionRecordInput();
        input.init({
            machineId: parameter.item.machineId,
            machineType: SharedUtility.fromMachineType(parameter.item.machineType),
            searchType: DailyInspectionRecordUtility.toSearchType(parameter.item.searchType),
            searchWord: parameter.item.keyword,
            searchBeginAt: parameter.item.beginAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.beginAt),
            searchEndAt: parameter.item.endAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.endAt),
            isInspected: DailyInspectionRecordUtility.toisInspected(parameter.item.searchStampType),
        });
        const output = await this.client.get(input);
        if (output == null) {
            return createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResult(output.errorMessage);
        }

        // 正常
        const result: DailyInspectionRecordGetResult = {
            isError: false,
            items: DailyInspectionRecordUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new DailyInspectionRecordClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
