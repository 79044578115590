import 'bootstrap/dist/css/bootstrap.css';
//import "@grapecity/ar-viewer-ja/dist/jsViewer.min.js";
//import "@grapecity/ar-viewer-ja/dist/jsViewer.min.css";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'
import store from './store'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl != null ? baseUrl : ""}>
            <App />
        </BrowserRouter>
    </Provider>,
    rootElement);

registerServiceWorker();
