import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UploadResultDialog } from './UploadResultDialog';
import { FileManageSlice　} from '../../../../../modules/slice/file-manage/file-manage-slice';
import { RootState } from '../../../../../rootReducer';

/** 結果ダイアログを表示します。 */
export function UploadResultDialogContainer() {
    const dispatch = useDispatch();

    const isOpen = useSelector((state: RootState) => state.fileManage.item.isOpenUploadResultDialog);
    const result = useSelector((state: RootState) => state.fileManage.result.putResult);
    
    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = async () => {
        dispatch(FileManageSlice.actions.updateItemPartial({
            isUpdated: true,
            isOpenUploadResultDialog: false
        }));
    };

    return (
        <UploadResultDialog
            isOpen={isOpen}
            handleClose={closeDialog}
            isError={result?.isError ?? false}
            errorMessage={result?.errorMessage}
            successedDataSource={result?.successItems ?? []}
             failedDataSource={result?.errorItems ?? []}
        />
    );
}
