// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { WeeklyInspectionRecordSlice, onGetWeeklyReportAsync } from '../../../../../modules/slice/weekly-inspection-record/weekly-inspection-record-slice';
// type
import { WeeklyInspectionRecordListItem } from "../../_types";
import { AreaType } from '../../../../../_types';
// component
import WeeklyInspectionRecordTable from './WeeklyInspectionRecordTable';

/** 週間月例点検記録テーブルを提供します */
export const WeeklyInspectionRecordTableContainer: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const machineId = useSelector((state: RootState) => state.globalHeader.machine?.item.id ?? "");
    const data: WeeklyInspectionRecordListItem[] = useSelector((state: RootState) => state.weeklyInspectionRecord.resultItems ?? []);
    const areaType = useSelector((state: RootState) => state.areaType.area);

    /**
    * 帳票ボタンクリック時のイベント処理を行います。
    * @param rowData
    */
    const onDocumentClickAsync = async (rowData: WeeklyInspectionRecordListItem) => {

        if (!machineId) {
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage("施工機情報がありません"));
        }
        else {
            // 選択項目保存
            dispatch(WeeklyInspectionRecordSlice.actions.selectItem(rowData));
            // 通信
            await dispatch(onGetWeeklyReportAsync({
                machineId,
                reportAt: rowData.completionDate
            }));
        }

    }

    /**
    * エリア種別のフィルター処理を行います。
    * @param source テーブルデータを指定します。
    * @param areaType エリア種別を指定します。
    * @returns フィルタ後のテーブルデータを返します。
    */
    const areaTypeFilter = (source: WeeklyInspectionRecordListItem[], areaType: AreaType) => {

        // エリアが確定していない施工機は全国でしか表示しない。
        // material-tableにデータをそのまま渡すとErrorになるので複製・参照を切る
        return source.filter(item => (areaType === AreaType.None ? true : item.areaType === areaType)).map(item => ({ ...item }));

    }

    return (
        <WeeklyInspectionRecordTable
            dataSource={areaTypeFilter(data, areaType)}
            onDocumentClickAysnc={onDocumentClickAsync}
        />

    );

};
