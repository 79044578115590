// react
import * as React from 'react';
import { useCallback, useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { OtherReportSlice, onGetAsync } from '../../../../../modules/slice/other-report/other-report-slice';
// type
import { S3FileListItem, HomeDirectoryType, DirectoryItem } from '../../_types';
import { S3FileTable } from './S3FileTable';
import { FileManageAdapter } from '../../../../../modules/adapter/file-manage/file-manage-adapter';
import { FileManageUtility } from '../../../../../modules/adapter/file-manage/file-manage-utility';
import { onGetListFilesAsync, onGetFileAsync, FileManageSlice } from '../../../../../modules/slice/file-manage/file-manage-slice';
import { S3Breadcrumbs } from './TableTitle';
import path from 'path';

/**
 * S3ファイル画面テーブルを表示します。
 */
export function S3FileTableContainer(): JSX.Element {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const data: S3FileListItem[] = useSelector((state: RootState) => state.fileManage.result.getFileListResult?.items ?? []);
    const directoryName = useSelector((state: RootState) => FileManageUtility.toDirectoryKey(state.fileManage.item.directoryItem));
    const isOnlineHelpRoot: boolean = useSelector((state: RootState) =>
        state.fileManage.item.directoryItem.home === HomeDirectoryType.OnlineHelp && state.fileManage.item.directoryItem.sub?.length < 1
    );

    /**
     * リンククリックイベントを処理します。
     * @param key　キーを指定します。
     */
    const onClickLink = useCallback(async (e: React.MouseEvent, row: S3FileListItem) => {
        e.preventDefault();

        if (row.type === "フォルダ") {
            // フォルダ
            await dispatch(onGetListFilesAsync({ key: row.key }));
        } else if (row.type === "pdf") {
            // ファイル
            const resp = await FileManageAdapter.instance.getFileAsync({ key: row.key });
            if (resp.isError) {
                dispatch(FileManageSlice.actions.showErrorMessage(resp.errorMessage));
            } else if (resp.file == null) {
                dispatch(FileManageSlice.actions.showErrorMessage(`ファイル情報がありません　file:${resp.file}`));
            } else {
                FileManageUtility.downloadBlobObject(resp.file, row.name);
            };
        } else {
            dispatch(FileManageSlice.actions.showErrorMessage("未定義のファイルです。"));
        };

    }, [dispatch]);

    /**
     * フォルダ―クリックイベントを処理します。
     * @param key　キーを指定します。
     */
    const onClickFolderLink = useCallback(async (e: React.MouseEvent, key: string) => {
        e.preventDefault();
        await dispatch(onGetListFilesAsync({ key }));
    }, [dispatch]);

    /**
     * 選択行変更イベントを処理します。
     * @param rows　行を指定します。
     */
    const onSelectionChange = useCallback((rows: S3FileListItem[]) => {
        dispatch(FileManageSlice.actions.selectedRows(rows));
    }, [dispatch]);

    return (
        <S3FileTable
            enableSorting={!isOnlineHelpRoot} // オンラインヘルプ(ルート)時はソート無効
            dataSource={data}
            onClickLink={onClickLink}
            onSelectionChange={onSelectionChange}
            titleComponent={
                <S3Breadcrumbs
                    directoryName={directoryName}
                    onClickBreadcrumb={onClickFolderLink}
            />}
        />

    )
}
