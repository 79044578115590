// react
import * as React from 'react';
import { useState } from 'react';
// material-ui
import MaterialTable, { MTableBodyRow, Column } from 'material-table';
import Box from '@material-ui/core/Box';
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
// type
import { DailyInspectionRecordListItem } from "../../_types";
// 共通component
import OverflowTooltip from '../../../../../components/OverflowTooltip';
import { ConstructionMchineMasterOptions } from '../../../Shared/ConstructionMchineMasterTableOptions';
import { useStyles } from '../../../Shared/ConstructionMchineMasterTableStyles';
import { ReportIconButton } from '../../../Shared/components/ReportIconButton/ReportIconButton';

/** 日常点検記録テーブルの Props を提供します。*/
interface Props {
    /** データソースを表します。 */
    dataSource: DailyInspectionRecordListItem[],
    /** 帳票出力イベントを表します。 */
    onDocumentClickAysnc: (rowData: DailyInspectionRecordListItem) => void,
}

/** 日常点検記録のテーブルの State を提供します。 */
interface State {
    /** テーブルのカラムを表します。 */
    columns: Column<DailyInspectionRecordListItem>[]
}

/** 日常点検記録テーブルを提供します */
const DailyInspectionRecordTable: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** 日常点検記録テーブルのカラムを表します。 */
    const columnData: Column<DailyInspectionRecordListItem>[] = [
        {
            title: "帳票",
            field: "isStamped",
            width: 50,
            cellStyle: { padding: "0px 5px" },
            render: (rowData: DailyInspectionRecordListItem) => (
                <ReportIconButton<DailyInspectionRecordListItem>
                    rowData={rowData}
                    onClick={props.onDocumentClickAysnc}
                />
            ),
        },
        {
            title: "点検記録表 No.",
            field: "no",
        },
        {
            title: "点検完了日時",
            field: "completionDate",

        },
        {
            title: "工法",
            field: "constructionMethod",
        },
        {
            title: "所属",
            field: "area",
        },
        {
            title: "作業場所",
            field: "site",
        },
        {
            title: "作業者",
            field: "worker",
        },
        {
            title: "報告事項",
            field: "report",
            cellStyle: {
                width: 200,
                maxWidth: 200,
            },
            headerStyle: {
                width: 200,
                maxWidth: 200,
            },
            render: (rowData: DailyInspectionRecordListItem) => (
                <OverflowTooltip title={rowData.report}>
                    <>{rowData.report}</>
                </OverflowTooltip>
            ),
        },

    ];


    /** カラム情報を提供します。 */
    const [values, setValues] = useState<State>({
        columns: columnData,
    });

    /**
    * ソート変更時のイベント処理を行います。
    * @param orderBy
    * @param orderDirection
    */
    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc"): void => {

        // 再レンダーされる時にソート順がリセットされないようにState で保持する
        setValues({
            columns: values.columns.map((column, index) => {
                if (index === orderBy) {
                    column.defaultSort = orderDirection;
                } else {
                    delete column.defaultSort
                }
                return column;
            })
        })
    };

    return (
        <Box component="span" className={classes.sortHeader}>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={ConstructionMchineMasterOptions}
                columns={values.columns}
                data={props.dataSource}
                components={{
                    Row: (props) => {
                        return (
                            <MTableBodyRow
                                onDoubleClick={() => props.onDocumentClickAysnc(props.data)}
                                {...props}
                            />
                        )
                    }
                }}
                onOrderChange={onOrderChange}
            />
        </Box>
    );
};

export default DailyInspectionRecordTable;
