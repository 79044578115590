import { MachineErrorHistoryClient, GetMachineErrorHistoryInput, GetMachineErrorHistoryOutput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { MachineErrorHistoryRequest, MachineErrorHistoryResult } from './types';
import { MachineErrorHistoryUtility } from './machine-error-history-utility';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `アラート履歴` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class MachineErrorHistoryAdapter extends WebApiAdapter<MachineErrorHistoryClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new MachineErrorHistoryAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: MachineErrorHistoryRequest): Promise<MachineErrorHistoryResult> {
        function createErrorResult(message?: string): MachineErrorHistoryResult {
            return {
                listItems: [],
                graphItems: [],
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetMachineErrorHistoryInput();
        input.init({
            machineId: parameter.item.machineId ?? "",
            beginAt: parameter.item.beginAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.beginAt),
            endAt: parameter.item.endAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.endAt),
        });
        const output = await this.client.get(input);
        if (output == null) {
            return createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineErrorHistoryResult = {
            isError: false,
            listItems: MachineErrorHistoryUtility.toListItem(output.items),
            graphItems: MachineErrorHistoryUtility.toGraphItem(parameter.item.beginAt, parameter.item.endAt, output.counts),
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new MachineErrorHistoryClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
