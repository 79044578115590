// 各画面
import MaintenanceItemPage from '../../../MaintenanceItem';
import MaintenanceDivisioinPage from '../../../MaintenanceDivision';
import MachinePage from '../../../Machine';
import InspectionDivisionPage from '../../../InspectionDivision';
import InspectionItemPage from '../../../InspectionItem';
import MachineModelPage from '../../../MachineModel';
import ManufacturerPage from '../../../Manufacturer';
import MethodPage from '../../../Method';
import UserPage from '../../../User';
import RemarksPage from '../../../Remarks';

import { AppTitle } from '../../../../Shared/app-title';
import { AppUrl } from '../../../../Shared/app-url';
import { TabItem } from '../../_types';

/** マスタ系タブのアイテムを提供します。  */
export class MasterPageTabItems {
    /** 施工機マスタ */
    public static readonly masterMachine: TabItem = {
        label: AppTitle.masterMachine.title,
        path: AppUrl.masterMachine.path,
        tabValue: AppUrl.masterMachine.path.replace(`${AppUrl.master.path}/`, ""),
        component: MachinePage,
    }
    /** 型式マスタ */
    public static readonly masterModel: TabItem = {
        label: AppTitle.masterModel.title,
        path: AppUrl.masterModel.path,
        tabValue: AppUrl.masterModel.path.replace(`${AppUrl.master.path}/`, ""),
        component: MachineModelPage,
    }
    /** メーカーマスタ */
    public static readonly masterManufacturer: TabItem = {
        label: AppTitle.masterManufacturer.title,
        path: AppUrl.masterManufacturer.path,
        tabValue: AppUrl.masterManufacturer.path.replace(`${AppUrl.master.path}/`, ""),
        component: ManufacturerPage,
    }
    /** 工法マスタ */
    public static readonly masterMethod: TabItem = {
        label: AppTitle.masterMethod.title,
        path: AppUrl.masterMethod.path,
        tabValue: AppUrl.masterMethod.path.replace(`${AppUrl.master.path}/`, ""),
        component: MethodPage,
    }
    /** 整備区分マスタ */
    public static readonly masterMaintenanceDivisioin: TabItem = {
        label: AppTitle.masterMaintenanceDivisioin.title,
        path: AppUrl.masterMaintenanceDivisioin.path,
        tabValue: AppUrl.masterMaintenanceDivisioin.path.replace(`${AppUrl.master.path}/`, ""),
        component: MaintenanceDivisioinPage,
    }
    /** 整備項目マスタ */
    public static readonly masterMaintenanceItem: TabItem = {
        label: AppTitle.masterMaintenanceItem.title,
        path: AppUrl.masterMaintenanceItem.path,
        tabValue: AppUrl.masterMaintenanceItem.path.replace(`${AppUrl.master.path}/`, ""),
        component: MaintenanceItemPage,
    }
    /** 特記事項マスタ */
    public static readonly masterRemarksItem: TabItem = {
        label: AppTitle.masterRemarks.title,
        path: AppUrl.masterRemarks.path,
        tabValue: AppUrl.masterRemarks.path.replace(`${AppUrl.master.path}/`, ""),
        component: RemarksPage,
    }
    /** 点検区分マスタ */
    public static readonly masterInspectionDivision: TabItem = {
        label: AppTitle.masterInspectionDivision.title,
        path: AppUrl.masterInspectionDivision.path,
        tabValue: AppUrl.masterInspectionDivision.path.replace(`${AppUrl.master.path}/`, ""),
        component: InspectionDivisionPage,
    }
    /** 点検項目マスタ */
    public static readonly masterInspectionItem: TabItem = {
        label: AppTitle.masterInspectionItem.title,
        path: AppUrl.masterInspectionItem.path,
        tabValue: AppUrl.masterInspectionItem.path.replace(`${AppUrl.master.path}/`, ""),
        component: InspectionItemPage,
    }
    /** ユーザーマスタ */
    public static readonly masterUser: TabItem = {
        label: AppTitle.masterUser.title,
        path: AppUrl.masterUser.path,
        tabValue: AppUrl.masterUser.path.replace(`${AppUrl.master.path}/`, ""),
        component: UserPage,
    }
}
