// react
import * as React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
// type
import { MethodInputItem, MethodValidateInputItem } from '../../_types';
// component
import MasterDetailButtonGroup from '../../../Shared/MasterDetailButtonGroup';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },
        // 削除
        deleteButton: {
            color: theme.palette.warning.main,
        },

        // 更新
        updateButton: {
            color: theme.palette.warning.main,
        },


        // テキストエリア
        textarea: {
            width: `calc((250px + ${theme.spacing(1)}px) * 2)!important`,
        },

    }),
);

/** 工法マスタ　検索項目の Props を提供します。 */
interface Props {
    /** 初期値を表します。 */
    initialValues: MethodInputItem,
    /** 追加ボタンのクリック処理を行います。 */
    onClickAdd: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 更新ボタンのクリック処理を行います。 */
    onClickUpdate: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 削除ボタンのクリック処理を行います。 */
    onClickRemove: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** キャンセルボタンのクリック処理を行います。 */
    onClickCancel: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 新規作成状態かどうかを表します。 */
    isCreating: boolean,
    /** 入力値の変更処理を表します。 */
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MethodInputItem) => void,
    /** バリデーション結果を表します。 */
    validateError: MethodValidateInputItem,
};

/**
 * 工法 マスタ　詳細エリアを提供します。
 * */
const MethodDetailCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();


    // 表示
    return (
        <>
            <Box padding={1}>

                {/* 工法名 */}
                <TextField
                    label="工法"
                    placeholder="工法"
                    value={props.initialValues.method ?? ""}
                    onChange={(event) => props.onChange(event, "method")}
                    required
                    error={!props.validateError.method === false}
                    helperText={props.validateError.method}

                />

                {/* ボタングループ */}
                <MasterDetailButtonGroup
                    isCreating={props.isCreating}
                    onClickAdd={props.onClickAdd}
                    onClickCancel={props.onClickCancel}
                    onClickUpdate={props.onClickUpdate}
                    onClickRemove={props.onClickRemove}
                />

            </Box>
        </>
    );
};

export default MethodDetailCondition;
