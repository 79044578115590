// react
import * as React from 'react';
// material-ui
import Button, { ButtonProps } from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';

/**
 *  キャンセル ボタンを表示します。
 * @param props
 */
const MuiCancelButton: React.FC<ButtonProps> = (props) => {

    return (

        <Button
            variant="contained"
            color="default"
            startIcon={<ClearIcon />}
            onClick={props.onClick}
            {...props}
        >
            キャンセル
        </Button>
    )
}

export default MuiCancelButton;
