import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { EditItem } from '../../_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // カードコンテンツ
        cardContent: {
            paddingTop: 0,
        },
        //　取得値コンテナ
        textContainer: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "baseline",
            justifyContent: "flex-end",
        },
        // 値
        value: {
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "end",
            color: theme.palette.success.main,
        },
        // 単位
        unit: {
            fontSize: "1.2rem",
            textAlign: "start",
            marginLeft: "0.5rem",
        },
    }),
);

/** ステータス編集カードコンテンツの Props のインターフェイスを提供します。 */
interface Props {
    /** 編集項目を表します。 */
    item: EditItem,
};

/**
 * ステータス編集カードの コンテンツを表示します。
 * @param props
 */
const EditCardContent: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles(props);

    return (
        <CardContent
            className={classes.cardContent}
        >
            <Grid container className={classes.textContainer}>
                {/* 値 */}
                <Grid item xs className={classes.value}>
                    {!props.item.value ? '\u00A0' : props.item.value}
                </Grid>
                {/* 単位 */}
                <Grid item xs={3} className={classes.unit}>
                    {props.item.unit}
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default EditCardContent;
