// 各画面
import { MaintenanceRecord } from '../../../MaintenanceRecord';
import { ConstructionRecord } from '../../../ConstructionRecord';
import { MachineStatus } from '../../../MachineStatus';
import { AlertRecord } from '../../../AlertRecord';
import { DailyInspectionRecord } from '../../../DailyInspectionRecord';
import { WeeklyInspectionRecord } from '../../../WeeklyInspectionRecord';
import { CompletionInspectionRecord } from '../../../CompletionInspectionRecord';
import { StatusEditPage } from '../../../StatusEdit';
import { AppTitle } from '../../../../Shared/app-title';
import { AppUrl } from '../../../../Shared/app-url';
import { TabItem } from '../../_types';

/** 施工機管理系タブのアイテムを提供します。  */
export class RecordPageTabItems {
    /** 整備記録 */
    public static readonly maintenanceRecord: TabItem = {
        label: AppTitle.recordMaintenanceRecord.title,
        path: AppUrl.recordMaintenanceRecord.path,
        tabValue: AppUrl.recordMaintenanceRecord.path.replace(`${AppUrl.record.path}/`, ""),
        component: MaintenanceRecord,
    }
    /** 日常点検記録 */
    public static readonly dailyInspectionRecord: TabItem = {
        label: AppTitle.recordDailyInspectionRecord.title,
        path: AppUrl.recordDailyInspectionRecord.path,
        tabValue: AppUrl.recordDailyInspectionRecord.path.replace(`${AppUrl.record.path}/`, ""),
        component: DailyInspectionRecord,
    }
    /** 週間月例点検記録 */
    public static readonly weeklyInspectionRecord: TabItem = {
        label: AppTitle.recordWeeklyInspectionRecord.title,
        path: AppUrl.recordWeeklyInspectionRecord.path,
        tabValue: AppUrl.recordWeeklyInspectionRecord.path.replace(`${AppUrl.record.path}/`, ""),
        component: WeeklyInspectionRecord,
    }
    /** 現場完了点検記録 */
    public static readonly completionInspectionRecord: TabItem = {
        label: AppTitle.recordCompletionInspectionRecord.title,
        path: AppUrl.recordCompletionInspectionRecord.path,
        tabValue: AppUrl.recordCompletionInspectionRecord.path.replace(`${AppUrl.record.path}/`, ""),
        component: CompletionInspectionRecord,
    }
    /** 施工記録 */
    public static readonly constructionRecord: TabItem = {
        label: AppTitle.recordConstructionRecord.title,
        path: AppUrl.recordConstructionRecord.path,
        tabValue: AppUrl.recordConstructionRecord.path.replace(`${AppUrl.record.path}/`, ""),
        component: ConstructionRecord,
    }
    /** 施工機ステータス */
    public static readonly machineStatus: TabItem = {
        label: AppTitle.recordMachineStatus.title,
        path: AppUrl.recordMachineStatus.path,
        tabValue: AppUrl.recordMachineStatus.path.replace(`${AppUrl.record.path}/`, ""),
        component: MachineStatus,
    }
    /** 施工機メンテナンス設定 */
    public static readonly machineMaintenanceSetting: TabItem = {
        label: AppTitle.recordMachineMaintenanceSetting.title,
        path: AppUrl.recordMachineMaintenanceSetting.path,
        tabValue: AppUrl.recordMachineMaintenanceSetting.path.replace(`${AppUrl.record.path}/`, ""),
        component: StatusEditPage,
    }
    /** アラート記録 */
    public static readonly alertRecord: TabItem = {
        label: AppTitle.recordAlertRecord.title,
        path: AppUrl.recordAlertRecord.path,
        tabValue: AppUrl.recordAlertRecord.path.replace(`${AppUrl.record.path}/`, ""),
        component: AlertRecord,
    }

}
