// react
import * as React from 'react';
// material-ui
import { useTheme } from '@material-ui/core/styles';
// recharts
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Brush,
    ResponsiveContainer,
} from 'recharts';
// type
import { MachineErrorHistoryGraphItem, MachineErrorHistoryGraphDisplayData } from '../../_types';

/** アラート履歴グラフの Props を提供します。 */
interface Props {
    /** データソースを表します。 */
    dataSource: MachineErrorHistoryGraphDisplayData[],
}

/** アラート履歴画面 アラート件数グラフを提供します。 */
const AlertRecordGraph: React.FC<Props> = (props) => {

    /** テーマを提供します。 */
    const theme = useTheme();

    return (
        <ResponsiveContainer height={400} width={"99%"} >

            <BarChart
                data={props.dataSource}
                margin={{ top: 50, right: 100, left: 50, bottom: 50 }}
                barGap={0}
                barSize={props.dataSource.length > 1 ? void 0 : 30}
                maxBarSize={30}
            >
                {/* 格子 */}
                <CartesianGrid vertical={false} strokeDasharray="3 3" />

                {/* X軸 */}
                <XAxis
                    dataKey={"dateAt"}
                    type="number"
                    scale="time"
                    domain={["dataMin", "dataMax"]}
                    padding={{ left: 30, right: 30 }}
                    tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                    stroke={theme.palette.text.primary}
                    tick={{
                        fontSize: "0.75rem",
                        wordWrap: "break-word",
                        fill: theme.palette.text.secondary,
                    }}
                    angle={-35}
                    textAnchor="end"
                />

                {/* Y軸 */}
                <YAxis
                    dx={-25}
                    label={{
                        value: "アラート件数",
                        position: "top",
                        dy: -5,
                        fill: theme.palette.text.primary,
                    }}
                    tick={{
                        fill: theme.palette.text.primary,
                        stroke: theme.palette.text.primary,
                    }}
                    stroke={theme.palette.text.primary}
                    allowDecimals={false}
                />

                {/* ツールチップ */}
                <Tooltip
                    labelStyle={{
                        color: theme.palette.grey[900]
                    }}
                    labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                    cursor={{ width: 1, fill: theme.palette.text.secondary }}
                />

                {/* ブラシ(下のバー) */}
                {/* データが0の場合エラーになる */}
                {props.dataSource.length > 0 &&
                    <Brush
                        dataKey={"dateAt"}
                        height={30}
                        y={360}
                        tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                    />
                }

                <Bar
                    dataKey={"count"}
                    name={"アラート件数"}
                    fill={"#ff5722"}
                    unit={"件"}
                />


            </BarChart>
        </ResponsiveContainer>
    );
};

export default AlertRecordGraph;
