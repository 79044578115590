// react
import * as React from 'react';
// redux
import { useDispatch } from 'react-redux';
import { UserMasterSlice, onExcuteAsync } from '../../../../../modules/slice/user/user-slice';
import { UserAdapter } from '../../../../../modules/adapter/user/user-adapter';
// type
import { UserInputItem } from '../../_types';
// component
import UserSearchCondition from './UserSearchCondition';

/**
 * ユーザーマスタ　検索エリアを提供します。
 * */
export const UserSearchConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: UserInputItem) => {

        // 非同期通信
        const promise = UserAdapter.instance.getAsync(
            { item: inputItem },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(UserMasterSlice.actions.chageCreating(true))
    }

    // 表示
    return (
        <UserSearchCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
        />
    );
};
