// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme, fade } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
// 共通component
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
import { MasterTableOptions } from '../../../Shared/MasterTableOptions';
import MasterTableFrame from '../../../Shared/MasterTableFrame';
// type
import { ModelListItem } from "../../_types"

/** 型式マスタテーブルの Props を提供します。*/
interface Props {
    /** データソースを表します。 */
    dataSource: ModelListItem[],
    /** 行選択のクリックイベントを表します。 */
    onRowClick: (rowData: ModelListItem) => void,
    /** 選択中の行データを表します。 */
    selectedRow?: ModelListItem,
}

/** 型式マスタテーブルの State を提供します。*/
interface State {
    /** 選択中の行データを表します。 */
    selectedRow?: ModelListItem,
}

/** 型式マスタテーブルのカラムを表します。 */
const columns = [
    { title: "型式", field: "model" },
];

/** 型式マスタテーブルを提供します */
const MachineModelResultTable: React.FC<Props> = (props) => {

    /** Theme を提供します。*/
    const theme = useTheme();

    /** State を提供します。 */
    const [values, setValues] = useState<State>({
        selectedRow: void 0,
    });

    useEffect(() => {
        setValues({ selectedRow: props.selectedRow });
    }, [props.selectedRow])

    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const handleRowClick = (rowData: ModelListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを保持
        setValues({ ...values, selectedRow: rowData });

        // Prop 実行
        props.onRowClick(rowData);

    };

    return (
        <MasterTableFrame>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={{
                    ...MasterTableOptions,
                    rowStyle: (rowData, index) => ({
                        whiteSpace: "nowrap",
                        backgroundColor: (values.selectedRow?.id === rowData.id) ? fade(theme.palette.secondary.main, 0.5) : "", // 選択された行の背景色
                    }),
                }}
                columns={columns}
                data={props.dataSource}
                onRowClick={(event, selectedRow) => {
                    selectedRow && handleRowClick(selectedRow, event)
                }}
            />
        </MasterTableFrame>

    );

};

export default MachineModelResultTable;
