import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import ResetHistoryTable from './ResetHistoryTable';
import { MachineMaintenanceSettingResetHistoryListItem, MachineMaintenanceSettingResetHistoryItem } from '../../_types';
import { MachineDataType } from '../../../MachineStatus/_types';

/**
 * リセット履歴テーブルを表示します。
 * @param props
 */
export const ResetHistoryTableContainer: React.FC<{}> = (props) => {

    // redux store
    const editItem = useSelector((state: RootState) => state.statusEdit.item.editItem);
    const data = useSelector((state: RootState) => state.statusEdit.result.getResetHistoryResult?.items);

    /**
     * データソースに変換します。
     * @param data リセット履歴リストを指定します。
     */
    const toDataSource = (data?: MachineMaintenanceSettingResetHistoryItem[], type?: MachineDataType) => {

        let result: MachineMaintenanceSettingResetHistoryListItem[] = [];
        if (data == null || type == null) return result;

        result = data.find(item => item.machineDataType === type)?.resetHistories ?? []
        return result;
    }

    return (
        <ResetHistoryTable
            dataSource={toDataSource(data, editItem?.type).map(item => ({ ...item }))}
        />
    );
}
