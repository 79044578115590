//react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Box,
    Grid,
} from '@material-ui/core';
// components
import ControlMeterStatusCard from '../ControlMeterStatusCard';
import LiftingStatusCard from '../LiftingStatusCard';
import ConnectionStatusCard from '../ConnectionStatusCard';
import MeasuredCard from '../MeasuredCard';
import AlertParameterCard from '../AlertParameterCard';
import ParameterCard from '../ParameterCard';
import CtuVersionInformation from '../CtuVersionInformation';
import IpAddressInformation from '../IpAddressInformation';
import UpdateItem from '../../../Shared/UpdateItem';
import EcuErrorCard from '../EcuErrorCard';
import { MachineStatusMonitoringItem, MachineStatusControlMeterState } from '../../_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボックス
        box: {
            padding: theme.spacing(1),
        },
        // グリッドコンテナ
        gridContainer: {
            alignItems: "center",
            padding: theme.spacing(0, 1, 0, 1),
        },
        // グリッド　ヘッダー
        gridHeader: {
            alignItems: "center",

        }
    }),
);

/**
 * 施工機ステータス Props の
 */
interface Props {
    /** 施工機ステータス情報を表します。 */
    data?: MachineStatusMonitoringItem,
    /** 管理計器ステータス情報を表します。 */
    machineStatus?: MachineStatusControlMeterState
    /** IPアドレスを表します。 */
    ipAddress: string,
    /** 最終更新日時を表します。 */
    lastUpdatedAt: string,
    /** 更新ボタンクリック時のイベント処理を表します。*/
    onUpdateClick: () => void,
}

/**
 * 施工機ステータス画面
 */
const MachineStatusMonitoringMain: React.FC<Props> = (props) => {

    const classes = useStyles();

    return (
        <Box className={classes.box}>

            {/* 上部 施工機情報 */}
            <Grid container spacing={1} className={classes.gridContainer}>

                <Grid container item xs className={classes.gridHeader}>
                    {/* CTU バージョン */}
                    <Grid item xs={12} sm={6} lg={3}>
                        <CtuVersionInformation version={props.data?.version ?? ""} />
                    </Grid>

                    {/* IP アドレス */}
                    <Grid item xs={12} sm={6} lg={3}>
                        <IpAddressInformation ipAddress={props.ipAddress ?? ""} />
                    </Grid>

                    {/* 更新エリア */}
                    <Grid item xs>
                        <UpdateItem
                            lastUpdatedAt={props.lastUpdatedAt ?? ""}
                            onClick={props.onUpdateClick}
                        />
                    </Grid>
                </Grid>

                {/* データがない場合は何も表示しない */}
                {props.data ? (
                    <Grid container spacing={1} justify="center">


                        {/* 右側　状態表示 */}
                        <Grid container
                            spacing={1}
                            item xs
                            direction="column"
                            justify="flex-start"
                            alignItems="stretch"
                        >
                            {/* 管理計器ステータスカード */}
                            <Grid item>
                                <ControlMeterStatusCard
                                    status={props.machineStatus}
                                />
                            </Grid>

                            {/* 昇降状態カード */}
                            <Grid item>
                                <LiftingStatusCard
                                    status={props.data.upDownState}
                                />
                            </Grid>

                            {/* 自動制御接続カード*/}
                            <Grid item>
                                <ConnectionStatusCard
                                    status={props.data.autoControlConnection}
                                />
                            </Grid>

                            {/* ECUエラー */}
                            <Grid item>
                                <AlertParameterCard title="ECUエラー" status={props.data.ecuError} />
                            </Grid>

                            {/* 非常停止状態 */}
                            <Grid item>
                                <AlertParameterCard title="非常停止状態" status={props.data.emergencyStopState} />
                            </Grid>

                            {/* フック過巻 */}
                            <Grid item>
                                <AlertParameterCard title="フック過巻" status={props.data.hookOverHoisting} />
                            </Grid>

                            {/* エンジン冷却水オーバーヒート */}
                            <Grid item>
                                <AlertParameterCard title="エンジン冷却水オーバーヒート" status={props.data.engineCoolingWaterOverHeat} />
                            </Grid>

                            {/* FAULTランプ出力 */}
                            <Grid item>
                                <AlertParameterCard title="FAULTランプ出力" status={props.data.faultLamp} />
                            </Grid>

                            {/* 作動油温度警報 */}
                            <Grid item>
                                <AlertParameterCard title="作動油温度警報" status={props.data.workingOilTempWarning} />
                            </Grid>

                            {/* バッテリー電圧警報 */}
                            <Grid item>
                                <AlertParameterCard title="バッテリー電圧警報" status={props.data.alarmBatteryVoltage} />
                            </Grid>

                            {/* パイロット圧低下警告灯 */}
                            <Grid item>
                                <ParameterCard title="パイロット圧低下警告灯" status={props.data.pilotPressureWarning} />
                            </Grid>

                        </Grid>

                        {/* 左側　取得値 */}
                        <Grid container item sm={12} md={8} lg={9} spacing={1}>

                            {/* 現在速度 */}
                            <Grid item xs={12} sm={6} md={6}>
                                <MeasuredCard
                                    title="現在速度"
                                    measuredvalue={{ value: props.data.currentSpeed, unit: "m/min" }}
                                />
                            </Grid>

                            {/* 現在深度 */}
                            <Grid item xs={12} sm={6} md={6}>
                                <MeasuredCard
                                    title="現在深度"
                                    measuredvalue={{ value: props.data.currentDepth, unit: "m" }}
                                />
                            </Grid>

                            {/* 流量 1 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="流量 1"
                                    measuredvalue={{ value: props.data.flowRate1, unit: "ℓ/min" }}
                                />
                            </Grid>

                            {/* 流量 2 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="流量 2"
                                    measuredvalue={{ value: props.data.flowRate2, unit: "ℓ/min" }}
                                />
                            </Grid>

                            {/* 回転 1 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="回転 1"
                                    measuredvalue={{ value: props.data.rotate1, unit: "rpm" }}
                                />
                            </Grid>

                            {/* 回転 2 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="回転 2"
                                    measuredvalue={{ value: props.data.rotate2, unit: "rpm" }}
                                />
                            </Grid>

                            {/* 電流 1 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="電流 1"
                                    measuredvalue={{ value: props.data.current1, unit: "A" }}
                                />
                            </Grid>

                            {/* 電流 2 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="電流 2"
                                    measuredvalue={{ value: props.data.current2, unit: "A" }}
                                />
                            </Grid>

                            {/* 昇降圧力 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="昇降圧力"
                                    measuredvalue={{ value: props.data.upDownPressure, unit: "Mpa" }}
                                />
                            </Grid>

                            {/* 最大昇降圧力[Pa] */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="最大昇降圧力(日)"
                                    measuredvalue={{ value: props.data.liftingPressureMax, unit: "Mpa" }}
                                />
                            </Grid>

                            {/* 滑車ロードセルNo.1 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="滑車ロードセルNo.1"
                                    measuredvalue={{ value: props.data.loadCell, unit: "t" }}
                                />
                            </Grid>

                            {/* 昇降速度設定ボリューム */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="昇降速度設定ボリューム"
                                    measuredvalue={{ value: props.data.upDownSpeedVolume, unit: "m/min" }}
                                />
                            </Grid>

                            {/* エンジン回転数 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="エンジン回転数"
                                    measuredvalue={{ value: props.data.engineRotateSpeed, unit: "rpm" }}
                                />
                            </Grid>

                            {/* 平均エンジン回転数[rpm] */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="平均エンジン回転数(日)"
                                    measuredvalue={{ value: props.data.engineRotationTimeAvg, unit: "rpm" }}
                                />
                            </Grid>

                            {/* 作動油温度 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="作動油温度"
                                    measuredvalue={{ value: props.data.workingOilTemp, unit: "℃" }}
                                />
                            </Grid>

                            {/* 平均作動油温度[℃] */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="平均作動油温度(日)"
                                    measuredvalue={{ value: props.data.oilTemperatureAvg, unit: "℃" }}
                                />
                            </Grid>

                            {/*  バッテリー電圧 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="バッテリー電圧"
                                    measuredvalue={{ value: props.data.batteryVoltage, unit: "V" }}
                                />
                            </Grid>

                            {/* 平均バッテリー電圧[V] */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="平均バッテリー電圧(日)"
                                    measuredvalue={{ value: props.data.batteryVoltageAvg, unit: "V" }}
                                />
                            </Grid>

                            {/* フロントジャッキ圧力左 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="フロントジャッキ圧力 左"
                                    measuredvalue={{ value: props.data.frontLeftJackPressure, unit: "Mpa" }}
                                />
                            </Grid>

                            {/* フロントジャッキ圧力右 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="フロントジャッキ圧力 右"
                                    measuredvalue={{ value: props.data.frontRightJackPressure, unit: "Mpa" }}
                                />
                            </Grid>

                            {/* リヤジャッキ圧力左 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="リヤジャッキ圧力 左"
                                    measuredvalue={{ value: props.data.rearLeftJackPressure, unit: "Mpa" }}
                                />
                            </Grid>

                            {/* リヤジャッキ圧力右 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="リヤジャッキ圧力 右"
                                    measuredvalue={{ value: props.data.rearRightJackPressure, unit: "Mpa" }}
                                />
                            </Grid>

                            {/* 本体角度 X軸 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="本体角度 X軸"
                                    measuredvalue={{ value: props.data.bodyAngleX, unit: "度" }}
                                />
                            </Grid>

                            {/* 本体角度 Y軸 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="本体角度 Y軸"
                                    measuredvalue={{ value: props.data.bodyAngleY, unit: "度" }}
                                />
                            </Grid>

                            {/* リーダー角 X軸 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="リーダー角 X軸"
                                    measuredvalue={{ value: props.data.leaderAngleX, unit: "度" }}
                                />
                            </Grid>

                            {/* リーダー角 Y軸 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="リーダー角 Y軸"
                                    measuredvalue={{ value: props.data.leaderAngleY, unit: "度" }}
                                />
                            </Grid>

                            {/* ステーストローク長 左 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="ステーストローク長 左"
                                    measuredvalue={{ value: props.data.leftStayStrokeLength, unit: "cm" }}
                                />
                            </Grid>

                            {/* ステーストローク長 右 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="ステーストローク長 右"
                                    measuredvalue={{ value: props.data.rightStayStrokeLength, unit: "cm" }}
                                />
                            </Grid>

                            {/* メインポンプ稼働時間 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="メインポンプ稼働時間"
                                    measuredvalue={{ value: props.data.mainPumpTotalTime, unit: "Hr" }}
                                />
                            </Grid>

                            {/* ギアポンプ稼働時間 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="ギアポンプ稼働時間"
                                    measuredvalue={{ value: props.data.gearPumpTotalTime, unit: "Hr" }}
                                />
                            </Grid>

                            {/* オーガー1稼働時間1 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="オーガー1稼働時間"
                                    measuredvalue={{ value: props.data.augerTotalTime1, unit: "Hr" }}
                                />
                            </Grid>

                            {/* オーガー2稼働時間2 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="オーガー2稼働時間"
                                    measuredvalue={{ value: props.data.augerTotalTime2, unit: "Hr" }}
                                />
                            </Grid>

                            {/* 本体稼働時間 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="本体稼働時間"
                                    measuredvalue={{ value: props.data.totalTime, unit: "Hr" }}
                                />
                            </Grid>

                            {/* エンジン稼働時間 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="エンジン稼働時間"
                                    measuredvalue={{ value: props.data.engineTotalTime, unit: "Hr" }}
                                />
                            </Grid>

                            {/* クレーン昇降距離 */}
                            <Grid item xs={12} sm={6} lg={3}>
                                <MeasuredCard
                                    title="クレーン昇降距離"
                                    measuredvalue={{ value: props.data.craneTotalDistance, unit: "m" }}
                                />
                            </Grid>

                        </Grid>

                    </Grid>
                )
                    : (<></>)
                }
            </Grid>
        </Box>
    );
};
export default MachineStatusMonitoringMain;
