/** エリアの列挙値を提供します。 */
export enum AreaType {
    /** 指定無し(全国)を表します。 */
    None = 0,
    /** 東地区を表します。 */
    East = 1,
    /** 西地区を表します。 */
    West = 2,
}

/** 施工機種別を表す列挙値を提供します。 */
export enum MachineType {
    /** ベースマシンを表します。 */
    BaseMachine = 0,
    /** オーガーを表します。 */
    Auger = 1,
    /** 昇降装置を表します。 */
    Lift = 2,
}

/** 点検種別を表す列挙値を提供します。 */
export enum InspectionType {
    /** 日常点検を表します。 */
    Daily = 0,
    /** 週間月例点検を表します。 */
    Weekly = 1,
    /** 現場完了時点検を表します。 */
    Completion = 2,
}

/** CTUあり/なしを表す列挙値を提供します。 */
export enum ConnectedCtuType {
    /** 指定なし(対象外)を表します。 */
    NotApplicable = 0,
    /** CTUありを表します。 */
    Connected = 1,
    /** CTUなしを表します。 */
    Disconnected = 2,
}

/** 施工機情報を表すインターフェイスを提供します。 */
export interface MachineItem {
    /** 社番を表します。*/
    id: string,
    /** メーカーを表します。 */
    manufacturer: string,
    /** 型式を表します。 */
    model: string,
    /** CTUあり/なしを表します。 */
    ctu: ConnectedCtuType,
}

/** 施工機を表すインターフェイスを提供します。 */
export interface Machine {
    /** 施工機種別を表します。 */
    type: MachineType,
    /** 施工機情報を表します。 */
    item: MachineItem
}

/** キーと値を表すインターフェイスを提供します。 */
export interface KeyValueItem<TKey, TValue> {
    /** キーを表します。 */
    key: TKey;
    /** 値を表します。 */
    value: TValue;
}

/** 認証済みのユーザー情報を表すインターフェイスを提供します。 */
export interface UserItem {
    /** ユーザー id を表します。 */
    id: string,
    /** ユーザー名を表します。 */
    name: string,
    /** 検印者名を表します。 */
    inspectorName: string,
}

/**
 * 曜日を表す列挙値を提供します。
 */
export enum DayOfWeek {
    /** 日曜日 を表します。 */
    Sunday = 0,
    /** 月曜日 を表します。 */
    Monday = 1,
    /** 火曜日 を表します。 */
    Tuesday = 2,
    /** 水曜日 を表します。 */
    Wednesday = 3,
    /** 木曜日 を表します。 */
    Thursday = 4,
    /** 金曜日 を表します。 */
    Friday = 5,
    /** 土曜日 を表します。 */
    Saturday = 6,
}

/**
 * 週を表す列挙値を提供します。
 */
export enum WeekOfMonth {
    /** 第一週を表します。 */
    First = 1,
    /** 第二週を表します。 */
    Second = 2,
    /** 第三週を表します。 */
    Third = 3,
    /** 第四週を表します。 */
    Fourth = 4,
    /** 第五週を表します。 */
    Fifth = 5,
}
