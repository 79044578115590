// react
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
// material-ui
import MaterialTable, { MTableBodyRow, Column, MTableToolbar } from 'material-table';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import Box from '@material-ui/core/Box';
import { FolderOutlined, Image, PictureAsPdf, InsertDriveFileOutlined, Search, ArrowForwardIos, NavigateNext, Save } from '@material-ui/icons';
//import { GrDocumentPdf } from 'react-icons/gr';
//import DescriptionIcon from '@material-ui/icons/Description';
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
// component
import OverflowTooltip from '../../../../../components/OverflowTooltip';
// type
import { S3FileListItem, FileListType, S3ItemListType } from '../../_types';
import { Chip, Button, Paper, TextField, InputAdornment, Breadcrumbs, Typography, Link, IconButton } from '@material-ui/core';
import MuiRemoveButton from '../../../../../components/Buttons/MuiRemoveButton';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // テーブルソートヘッダー
        sortHeader: {
            // ソートされているカラム ヘッダー
            "& .MuiTableSortLabel-active": {
                color: theme.palette.text.primary,
            },
            // ソートアイコンカラー
            "& .MuiTableSortLabel-active .MuiTableSortLabel-icon": {
                color: `${theme.palette.text.primary}!important` as any,
            },
            // material-table スクロールバーをデフォルトに上書き
            "& > div > div > div > div": {
                overflowY: "visible !important",
            },
        },
        // 帳票アイコンボタン
        icon: {
            color: theme.palette.text.secondary
        }

    })
);

/** S3ファイルのテーブルの Props を提供します。 */
interface Props {
    /** ソートの可否をを表します。 */
    enableSorting : boolean,
    /** データソースを表します。 */
    dataSource: S3FileListItem[],
    /** ファイルリンク　クリックイベントを表します。 */
    onClickLink: (e: React.MouseEvent, row: S3FileListItem) => void,
    /** タイトルコンポーネントを表します。 */
    titleComponent: JSX.Element,
    /** 選択項目変更イベント処理します。 */
    onSelectionChange: (rows: S3FileListItem[]) => void;
}

/** S3ファイルのテーブルの State を提供します。 */
interface State {
    /** テーブルのカラムを表します。 */
    columns: Column<S3FileListItem>[]
}

/**
 * ファイルアイコンを表示します。
 * @param type ファイル種別を指定します。
 */
function FileTypeIcon(type?: S3ItemListType): JSX.Element {
    switch (type) {
        case "フォルダ":
            // フォルダ
            return <FolderOutlined />;
        case FileListType.Pdf:
            // PDF
            return <PictureAsPdf />;
        //case FileListType.Png:
        //case FileListType.Jpeg:
        //case FileListType.Jpg:
        //    // 画像
        //    return <Image />;
        default:
            // その他ファイル
            return <InsertDriveFileOutlined/>;
    };
}

/**
 * S3ファイル画面テーブルを表示します。
 * @param props
 */
export function S3FileTable(props: Props): JSX.Element {

    const data = React.useMemo(() => props.dataSource.map(item => ({ ...item })), [props.dataSource])

    const tableRef = React.useRef();

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /**S3ファイルテーブルのカラムを表します。 */
    const columnData: Column<S3FileListItem>[] = [
        {
            title: "名前",
            field: "name",
            width: "50%",
            render: (rowData: S3FileListItem) => (
                <>
                    {FileTypeIcon(rowData.type)}
                    <Box component="span" ml={2}>
                        <Link component="button" onClick={(e: React.MouseEvent) => props.onClickLink(e, rowData)}>{rowData.name}</Link>
                    </Box>
                </>
            ),
        },
        {
            title: "タイプ",
            field: "type",
        },
        {
            title: "最終更新",
            field: "lastModified",
        },
        {
            title: "サイズ",
            field: "size",
            align: "right",
            headerStyle: {
                textAlign: "left",
            },
        },
    ]

    return (
        <Box component="span" className={classes.sortHeader}>
            <MaterialTable
                tableRef={tableRef}
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                title={props.titleComponent}
                options={{
                    // toolbar: false,
                    // search: false,
                    showTitle: true,
                    sorting: props.enableSorting,
                    tableLayout: "auto",
                    emptyRowsWhenPaging: false,
                    pageSize: 100,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    rowStyle: { padding: 0 },
                    headerStyle: {
                        padding: 1,
                        backgroundColor: indigo[300],
                    },
                    maxBodyHeight: "60vh", // 固定
                    minBodyHeight: "60vh", // 固定
                    selection: true,
                    selectionProps: (rowData: S3FileListItem) => ({
                        color: "primary"
                    }),
                    toolbarButtonAlignment: "left",
                    // actionsColumnIndex: -1
                }}
                columns={columnData}
                data={data}
                // actions={[
                //     (rowData: S3FileListItem) => ({
                //         icon: 'save',
                //         tooltip: 'ダウンロード',
                //         onClick: (event, rows) => props.onClickLink(event, rowData),
                //         //hidden: rowData.type === "",
                //     }),
                // ]}
                onSelectionChange={(rows) => props.onSelectionChange(rows)}
                // onOrderChange={onOrderChange}
                // actions={[
                //     {
                //         tooltip: 'Remove All Selected Users',
                //         icon: "delete",
                //         onClickDir: (evt, data) => alert('You want to delete. ')
                //     }
                // ]}
                components={{
                    //Toolbar: props => (
                    //    <div>
                    //        <MTableToolbar {...props} />
                    //        <div style={{ padding: '0px 10px' }}>
                    //            <Button>アップロード</Button>
                    //            <Button>フォルダの作成</Button>
                    //        </div>
                    //    </div>
                    //),
                    //Action: props => (
                    //    <MuiRemoveButton
                    //        onClickDir={(event) => props.action.onClickDir(event, props.data)}
                    //        style={{ textTransform: 'none', marginLeft: 2 }}
                    //    >
                    //        削除
                    //    </MuiRemoveButton>
                    //),
                }}
            />

        </Box>
    )
}
