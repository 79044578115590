// cookies
import * as Cookies from "js-cookie";

/**
 * サインイン Slice 関連の ユーティリティ機能を提供します。
 * */
export class SigninUtility {

    /** ストレージのエリア種別の Key を表します。*/
    static readonly KeyIsSignedIn = "lasted";

    /**
     * ストレージ にある認証情報を取得します。
     * @returns 認証情報を返します。
     * */
    public static getStorageIsSignedIn(): boolean {

        const data = Cookies.get(SigninUtility.KeyIsSignedIn);

        return !!data;
    }

    /**
     *  ストレージに認証情報を設定します。
     */
    public static setStorageIsSignedIn(): void {
        // Cookie セット
        Cookies.set(SigninUtility.KeyIsSignedIn, (new Date().getTime()).toString());
    }

    /**
     *  ストレージに認証情報を設定します。
     */
    public static extendExpiresIsSignedIn(): void {
        // Cookie セット
        Cookies.set(SigninUtility.KeyIsSignedIn, (new Date().getTime()).toString());
    }

    /**
     * ストレージにある認証情報を削除します。
     * */
    public static removeStorageIsSignedIn(): void {
        // Cookie 削除
        Cookies.remove(SigninUtility.KeyIsSignedIn);
    }

}
