/**
 * `@material-ui/TextField` 関連の ユーティリティ機能のを提供します。
 */
export class MuiTextFieldUtility {

    //#region フィールド

    /** 数値入力の最小入力値を表します*/
    static readonly NumericInputMinimum = 0
    /** 数値入力の最大入力値を表します*/
    static readonly NumericInputMaximum = 99999

    /** マイナス許容の数値入力の最小入力値を表します*/
    static readonly MinusNumericInputMinimum = -99999
    /** マイナス許容の数値入力の最大入力値を表します*/
    static readonly MinusNumericInputMaximum = 99999

    //#endregion フィールド

    //#region メソッド

    /**
     * 数値入力値の制限を設定します。
     * @param value 入力値を指定します。
     */
    public static LimitationNumber(value: number): number | undefined {
        return MuiTextFieldUtility.LimitationInputNumber(value, false);
    }

    /**
    * 数値入力値の制限を設定します。
    * @param value 入力値を指定します。
    */
    public static LimitationNumberAllowMinus(value: number): number | undefined {
        return MuiTextFieldUtility.LimitationInputNumber(value, true);
    }

    /**
    * 数値入力値の制限を設定します。
    * @param value 入力値を指定します。
    * @param allowMinus マイナス値を許可するかどうかを指定します。
    */
    // Chorme で キーボードから上限を超えた数値が入力が出来てしまうので制限を設ける。
    private static LimitationInputNumber(value: number, allowMinus: boolean): number | undefined {
        let result: number | undefined = void 0;
        if (isFinite(value)) {
            const Maximun = allowMinus ? MuiTextFieldUtility.MinusNumericInputMaximum : MuiTextFieldUtility.NumericInputMaximum;
            const Minimum = allowMinus ? MuiTextFieldUtility.MinusNumericInputMinimum : MuiTextFieldUtility.NumericInputMinimum;
            // 入力制限値を超えた場合
            if (value > Maximun) result = Maximun;
            else if (value < Minimum) result = Minimum;
            else result = value;
        }
        // 正または負の Infinity か NaN か undefined の場合, undefined
        return result;
    }

    //#endregion メソッド

}
