import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import { EditItem } from '../../_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // カードヘッダー
        cardHeader: {
            paddingBottom: 0,
        },
        // タイトル
        header: {
            display: "flex",
            flexFlow: "wrap",

        },
        // タイトル
        title: {
            fontSize: "1.25rem",

        },
        // 書き込み値
        reset: {
            color: theme.palette.error.main,
            fontSize: "1.25rem",
            marginLeft: "auto",
        },
        // アイコン
        iconRed: {
            color: theme.palette.error.main,
        }
    }),
);

/** ステータス編集カードヘッダーの Props のインターフェイスを提供します。 */
interface Props {
    /** 編集項目を表します。 */
    item: EditItem,
    /** 編集ボタンクリック時のイベントを表します。 */
    onEditClick: (item: EditItem) => void,
    /** 履歴ボタンクリック時のイベントを表します。 */
    onHistoryClick: (item: EditItem) => void,
};

/**
 * ステータス編集カードを提供します。
 * @param props
 */
const EditCardHeader: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles(props);

    return (
        <CardHeader
            className={classes.cardHeader}
            title={
                <Box className={classes.header}>
                    <Typography component="span" variant="subtitle1" className={classes.title}>
                        {props.item.name}
                    </Typography>
                    {props.item.updateValue &&
                        <Typography component="span" variant="subtitle1" className={classes.reset}>
                            {`[${props.item.updateValue}]`}
                        </Typography>
                    }
                </Box>
            }
            action={
                <>
                    {/* 履歴 */}
                    <IconButton
                        className={clsx(props.item.updateValue && classes.iconRed)}
                        aria-label="history"
                        onClick={() => props.onHistoryClick(props.item)}
                    >
                        <HistoryIcon fontSize="inherit" />
                    </IconButton>

                    {/* 編集 */}
                    <IconButton
                        className={clsx(props.item.updateValue && classes.iconRed)}
                        aria-label="edit"
                        onClick={() => props.onEditClick(props.item)}
                    >
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </>
            }
        />
    );
};

export default EditCardHeader;
