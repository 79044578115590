// react
import * as React from 'react';
import { useEffect } from 'react';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMasterPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { UserMasterSlice, onExcuteMountedAsync } from '../../../modules/slice/user/user-slice';
// component
import { UserResultTableContainer } from './components/TableContent/UserResultTableContainer';
import { UserSearchConditionContainer } from './components/SearchContent/UserSearchConditionContainer';
import { UserDetailConditionContainer } from './components/DetailContent/UserDetailConditionContainer';
import { ConfirmDialogContainer } from './components/ConfirmDialog/ConfirmDialogContainer';
import MasterLayout from '../Shared/MasterLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';
import { SingoutDialogContainer } from './components/UpdateDialog/SignoutDialogContainer';

/**
 * ユーザーマスタ画面を提供します。
 * */
const UserPage: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeMasterPageHeader(AppTitle.masterUser.title));

        // 初回マウント時にデータを取得する
        dispatch(onExcuteMountedAsync());
    }, [dispatch]);

    // redux store
    const isLoading = useSelector((state: RootState) => state.userMaster.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.userMaster.isShowMessage);
    const message = useSelector((state: RootState) => state.userMaster.message);
    const isCreating = useSelector((state: RootState) => state.userMaster.isCreating);
    const isSelected = useSelector((state: RootState) => state.userMaster.isSelected);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(UserMasterSlice.actions.hideMessage());
    };

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ダイアログ */}
            <ConfirmDialogContainer />
            <SingoutDialogContainer />

            <MasterLayout
                search={<UserSearchConditionContainer />}
                table={<UserResultTableContainer />}
                detail={<UserDetailConditionContainer />}
                isCreating={isCreating}
                isSelected={isSelected}
            />
        </>
    );
};

export default UserPage;
