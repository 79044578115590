//export * from './other-report-list-item';
//export * from './other-report-input-item';
//export * from './other-report-search-type';


import { AreaType } from "../../../../_types";

/** 検索種別を表す列挙値を提供します。 */
export enum OtherReportSearchType {
    /** 未指定を表します。 */
    None = 0,
    /** 報告者を表します。 */
    Reporter = 1,
    /** 報告事項を表します。 */
    Report = 2,
}

/** 画像検索条件を表す列挙値を提供します。 */
export enum OtherReportImageSearchType {
    /** 未指定を表します。 */
    None = 0,
    /** 画像ありを表します。 */
    With = 1,
    /** 画像なしを表します。 */
    Without = 2,
}

/**
 * その他報告のテーブル項目のインターフェイスを提供します。
 */
export interface OtherReportListItem {
    /** 管理No. を表します。 */
    readonly no: string;
    /** 報告事項を表します。 */
    readonly item: string;
    /** 報告日時を表します。 */
    readonly reportAt: string;
    /** エリアを表します。 */
    readonly area: string;
    /** エリア種別を表します。*/
    readonly areaType: AreaType;
    /** 報告者を表します。 */
    readonly reporter: string;
    /** 作業場所を表します。 */
    readonly site: string;
    /** 画像保存パスを表します。 */
    readonly imagePaths: string[];
}


/**
 *  その他報告画面の入力項目のインターフェイスを提供します。
 * */
export interface OtherReportInputItem {
    /** キーワードを表します。 */
    keyword: string;
    /** 検索種別を表します。 */
    searchType: OtherReportSearchType;
    /** 開始日を表します。 */
    beginAt: Date | null;
    /** 終了日を表します。 */
    endAt: Date | null;
    /** 画像有無を表します。*/
    imageSearchType: OtherReportImageSearchType;
}
