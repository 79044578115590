// react
import * as React from 'react';
import { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { onGetAsync } from '../../../../../modules/slice/weekly-inspection-record/weekly-inspection-record-slice';
import { RootState } from '../../../../../rootReducer';
// component
import WeeklyInspectionSearch, { WeeklyInspectionRecordSearchState } from './WeeklyInspectionSearch';
import { WeeklyInspectionRecordSearchType, StampSearchType } from '../../_types';

/**
 * Stateの初期値を設定します
 * */
const initState = (): WeeklyInspectionRecordSearchState => {

    const beginAt = new Date();
    beginAt.setMonth(beginAt.getMonth() - 1);
    beginAt.setHours(0, 0, 0, 0);

    const endAt = new Date();
    endAt.setHours(0, 0, 0, 0);

    const result: WeeklyInspectionRecordSearchState = {
        keyword: "",
        searchType: WeeklyInspectionRecordSearchType.Report,
        searchStampType: StampSearchType.None,
        beginAt: beginAt,
        endAt: endAt,
    }

    return result;
}

/** 週間月例点検記録画面　検索エリアを表示します。 */
export const WeeklyInspectionSearchContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine);
    const isUpdated: boolean = useSelector((state: RootState) => state.weeklyInspectionRecord?.isUpdated ?? false);
    const isOpenViewer = useSelector((state: RootState) => state.weeklyInspectionRecord.isOpenViewer ?? true);

    const [value, setValue] = useState<WeeklyInspectionRecordSearchState>(initState);

    useEffect(() => {

        if (isUpdated && !isOpenViewer && machine) {
            onSearchClickAsync(value);
        }

    }, [machine, isUpdated, isOpenViewer, value, dispatch]);

    /** 検索ボタンのクリック処理を行います。 */
    const onSearchClickAsync = async (inputItem: WeeklyInspectionRecordSearchState) => {
        setValue(inputItem);

        if (machine) {
            // 実行
            await dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    machineType: machine.type,
                    keyword: inputItem.keyword,
                    searchType: inputItem.searchType,
                    searchStampType: inputItem.searchStampType,
                    beginAt: inputItem.beginAt,
                    endAt: inputItem.endAt,
                }
            }));
        }
    };

    return (
        <WeeklyInspectionSearch
            initInputItem={value}
            onSearchClick={onSearchClickAsync}
        />
    );


};
