/** 施工機ステータスの昇降状態の列挙値を提供します。 */
export enum MachineStatusMonitoringUpDownState {
    /** 利用不可 を表します。*/
    NotAvailable = 0,
    /** 停止状態 を表します。 */
    Stop = 1,
    /** 上昇状態 を表します。 */
    Up = 2,
    /** 下降状態 を表します。 */
    Down = 3,
}

/** 施工機ステータスのON/OFF状態の列挙値を提供します。 */
export enum MachineStatusMonitoringBoolState {
    /** 利用不可 を表します。*/
    NotAvailable = 0,
    /** ON状態 を表します。*/
    On = 1,
    /** OFF状態 を表します。*/
    Off = 2,
}

/** 施工機データ種別を表す列挙値を提供します。 */
export enum MachineDataType {
    /** 自動制御接続を表します。*/
    AutoControlConnection = 1,
    /** 現在深度を表します。*/
    CurrentDepth = 2,
    /** 現在速度を表します。*/
    CurrentSpeed = 3,
    /** 昇降状態を表します。*/
    UpDownState = 4,
    /** 昇降圧力を表します。*/
    UpDownPressure = 5,
    /** 昇降速度設定ボリュームを表します。*/
    UpDownSpeedVolume = 6,
    /** 滑車ロードセル No1 を表します。*/
    LoadCell = 7,
    /** 非常停止状態を表します。*/
    EmergencyStopState = 8,
    /** エンジン回転数を表します。*/
    EngineRotateSpeed = 9,
    /** ECUエラーを表します。*/
    EcuError = 10,
    /** フロントジャッキ右圧力を表します。*/
    FrontRightJackPressure = 12,
    /** フロントジャッキ左圧力を表します。*/
    FrontLeftJackPressure = 13,
    /** リヤジャッキ右圧力を表します。*/
    RearRightJackPressure = 14,
    /** リヤジャッキ左圧力を表します。*/
    RearLeftJackPressure = 15,
    /** フック過巻を表します。*/
    HookOverHoisting = 16,
    /** 作動油温度を表します。*/
    WorkingOilTemp = 17,
    /** パイロット圧低下警告灯を表します。*/
    PilotPressureWarning = 18,
    /** エンジン冷却水オーバーヒートを表します。*/
    EngineCoolingWaterOverHeat = 19,
    /** FAULT ランプ出力を表します。*/
    FaultLamp = 20,
    /** 本体角度 X 軸を表します。*/
    BodyAngleX = 21,
    /** 本体角度 Y 軸を表します。*/
    BodyAngleY = 22,
    /** リーダー角度 X 軸を表します。*/
    LeaderAngleX = 23,
    /** リーダー角度 Y 軸を表します。*/
    LeaderAngleY = 24,
    /** ステーストローク長左を表します。*/
    LeftStayStrokeLength = 25,
    /** ステーストローク長右を表します。*/
    RightStayStrokeLength = 26,
    /** 作動油温度警報を表します。*/
    WorkingOilTempWarning = 27,
    /** 流量1 を設定または取得します。*/
    FlowRate1 = 28,
    /** 流量2 を設定または取得します。 */
    FlowRate2 = 29,
    /** 回転1 を設定または取得します。*/
    Rotate1 = 30,
    /** 回転2 を設定または取得します。*/
    Rotate2 = 31,
    /** 電流1 を設定または取得します。*/
    Current1 = 32,
    /** 電流2 を設定または取得します。*/
    Current2 = 33,
    /** バージョン情報を表します。*/
    Version = 34,
    /** バッテリー電圧を表します。*/
    BatteryVoltage = 35,
    /** 本体稼働時間を表します。*/
    TotalTime = 36,
    /** エンジン稼働時間を表します。*/
    EngineTotalTime = 37,
    /** メインポンプ稼働時間を表します。*/
    MainPumpTotalTime = 38,
    /** ギアポンプ稼働時間を表します。*/
    GearPumpTotalTime = 39,
    /** オーガー稼働時間1を表します。*/
    AugerTotalTime1 = 40,
    /** オーガー稼働時間2を表します。*/
    AugerTotalTime2 = 41,
    /** クレーン昇降距離を表します。*/
    CraneTotalDistance = 44,
    /** エンジン回転数の平均値を表します。*/
    EngineRotationTimeAvg = 45,
    /** バッテリー電圧の平均値を表します。*/
    BatteryVoltageAvg = 46,
    /** 昇降圧力の最大値を表します。*/
    LiftingPressureMax = 47,
    /** 作業油温の平均値を表します。*/
    OilTemperatureAvg = 48,
    /** 作動油交換後時間を表します。*/
    WorkingOilTime = 49,
    /** エンジンオイル交換後時間を表します。*/
    EngineOilTime = 50,
    /** オイルエレメント交換後時間を表します。*/
    OilElementTime = 51,
    /** エアーエレメント交換後時間を表します。*/
    AirElementTime = 52,
    /** 燃料エレメント交換後時間を表します。*/
    FuelElementTime = 53,
    /** 作動油エレメント交換後時間を表します。*/
    WorkingOilElementTime = 54,
    /** バッテリー交換後日数を表します。*/
    BatteryTime = 55,
    /** クーラント交換後日数を表します。*/
    CoolantTime = 56,
    /** ファンベルト交換後時間を表します。*/
    FanBeltTime = 57,
    /** オルタネーター交換後時間を表します。*/
    AlternatorTime = 58,
    /** セルモーター交換後時間を表します。*/
    StarterMotorTime = 59,
    /** 油圧ポンプカップリング交換後時間を表します。*/
    OilPressurePumpCouplingTime = 60,
    /** 油圧ポンプ減速機オイル交換後時間を表します。*/
    OilPressurePumpDeceleratorOilTime = 61,
    /** ウインチ減速機オイル交換後時間を表します。*/
    WinchDeceleratorOilTime = 62,
    /** 主巻ワイヤ交換後時間を表します。*/
    PrimaryHoistingWire = 63,
    /** 補巻ワイヤ交換後時間を表します。*/
    SubHoistingWire = 64,
    /** 起伏ワイヤ交換後時間を表します。*/
    UpsAndDownsWire = 65,
    /** ペンダントロープ交換後時間を表します。*/
    PendantRope = 66,
    /** 特定自主検査後日数を表します。*/
    SelfInspection = 67,
    /** バッテリー電圧警報を表します。*/
    AlarmBatteryVoltage = 68,
}

/** 施工機ステータスの管理計器ステータスの列挙値を提供します。 */
export enum MachineStatusControlMeterState {
    /**貫入を表します。*/
    Penetration = 1,
    /**打深を表します。*/
    Depth = 2,
    /**先端処理引抜を表します。*/
    TipPullOut = 3,
    /**先端処理打戻を表します。*/
    TipHitBack = 4,
    /**先端処理定置撹拌を表します。*/
    TipStir = 5,
    /**引抜を表します。*/
    PullOut = 6,
    /**再貫引抜を表します。*/
    RePenePullOut = 7,
    /**再貫打戻を表します。*/
    RePeneHitBack = 8,
    /**一時停止を表します。*/
    Stop = 9,
    /** 施工外を表します。 */
    Other = 50,
}


/** 施工機ステータスのインターフェイスを提供します。 */
export interface MachineStatusMonitoringItem {
    /** 自動制御接続 を表します。*/
    readonly autoControlConnection?: boolean;
    /** 現在深度[m] を表します。*/
    readonly currentDepth: string;
    /** 現在速度[m/min] を表します。*/
    readonly currentSpeed: string;
    /** 昇降状態 を表します。*/
    readonly upDownState?: MachineStatusMonitoringUpDownState;
    /** 昇降圧力[Mpa] を表します。*/
    readonly upDownPressure: string;
    /** 昇降速度設定ボリューム を表します。*/
    readonly upDownSpeedVolume: string;
    /** 滑車ロードセル No1 [t] を表します。*/
    readonly loadCell: string;
    /** 非常停止状態 を表します。*/
    readonly emergencyStopState?: MachineStatusMonitoringBoolState;
    /** エンジン回転数[rpm] を表します。*/
    readonly engineRotateSpeed: string;
    /** ECUエラー を表します。*/
    readonly ecuError?: MachineStatusMonitoringBoolState;
    /** フロントジャッキ右圧力[Mpa] を表します。*/
    readonly frontRightJackPressure: string;
    /** フロントジャッキ左圧力[Mpa] を表します。*/
    readonly frontLeftJackPressure: string;
    /** リヤジャッキ右圧力[Mpa] を表します。*/
    readonly rearRightJackPressure: string;
    /** リヤジャッキ左圧力[Mpa] を表します。*/
    readonly rearLeftJackPressure: string;
    /** フック過巻 を表します。*/
    readonly hookOverHoisting?: MachineStatusMonitoringBoolState;
    /** 作動油温度[度] を表します。*/
    readonly workingOilTemp: string;
    /** パイロット圧低下警告灯 を表します。*/
    readonly pilotPressureWarning?: MachineStatusMonitoringBoolState;
    /** エンジン冷却水オーバーヒート を表します。*/
    readonly engineCoolingWaterOverHeat?: MachineStatusMonitoringBoolState;
    /** FAULT ランプ出力 を表します。*/
    readonly faultLamp?: MachineStatusMonitoringBoolState;
    /** 本体角度 X 軸 [度] を表します。*/
    readonly bodyAngleX: string;
    /** 本体角度 Y 軸 [度] を表します。*/
    readonly bodyAngleY: string;
    /** リーダー角度 X 軸 [度] を表します。*/
    readonly leaderAngleX: string;
    /** リーダー角度 Y 軸 [度] を表します。*/
    readonly leaderAngleY: string;
    /** ステーストローク長左 [cm] を表します。*/
    readonly leftStayStrokeLength: string;
    /** ステーストローク長右 [cm] を表します。*/
    readonly rightStayStrokeLength: string;
    /** 作動油温度警報 を表します。*/
    readonly workingOilTempWarning?: MachineStatusMonitoringBoolState;
    /** CTUバージョン情報 を表します。*/
    readonly version: string;
    /** バッテリー電圧[V] を表します。*/
    readonly batteryVoltage: string;
    /** 流量1[ℓ/min] を表します。*/
    readonly flowRate1: string;
    /** 流量2[ℓ/min] を表します。*/
    readonly flowRate2: string;
    /** 回転1[rpm] を表します。*/
    readonly rotate1: string;
    /** 回転2[rpm] を表します。*/
    readonly rotate2: string;
    /** 電流1[A] を表します。*/
    readonly current1: string;
    /** 電流2[A] を表します。*/
    readonly current2: string;
    /** 本体稼働時間[Hr] を表します。*/
    readonly totalTime: string;
    /** エンジン稼働時間[Hr] を表します。*/
    readonly engineTotalTime: string;
    /** メインポンプ稼働時間[Hr] を表します。*/
    readonly mainPumpTotalTime: string;
    /** ギアポンプ稼働時間[Hr] を表します。*/
    readonly gearPumpTotalTime: string;
    /** オーガー稼働時間1 [Hr] を表します。*/
    readonly augerTotalTime1: string;
    /** オーガー稼働時間2 [Hr] を表します。*/
    readonly augerTotalTime2: string;
    /** クレーン昇降距離[m] を表します。*/
    readonly craneTotalDistance: string;
    /** 平均エンジン回転数[rpm]を表します。 */
    readonly engineRotationTimeAvg: string,
    /** 平均バッテリー電圧[V]を表します。 */
    readonly batteryVoltageAvg: string,
    /** 最大昇降圧力[Pa]を表します。 */
    readonly liftingPressureMax: string,
    /** 平均作動油温度[℃]を表します。 */
    readonly oilTemperatureAvg: string,
    /** バッテリー電圧警報を表します。*/
    readonly alarmBatteryVoltage?: MachineStatusMonitoringBoolState,
}
