/** 施工記録の入力情報のインターフェイスを提供します。 */
export interface MachineOperationHistoryInputItem {
    /** 社番 を表します。 */
    machineId: string;
    /** 開始日 を表します。 */
    readonly beginAt: Date | null;
    /** 終了日 を表します。 */
    readonly endAt: Date | null;
}

/** 施工記録のグラフ項目のインターフェイスを提供します。 */
export interface MachineOperationHistoryGraphItem {
    /** エンジン稼働時間を表します。 */
    readonly engineOperatingTime: string;
    /** 昇降距離を表します。 */
    readonly liftingDistance: string;
    /** 平均エンジン回転数を表します。 */
    readonly engineRotationTimeAvg: string;
    /** 平均バッテリー電圧を表します。 */
    readonly batteryVoltageAvg: string;
    /** 最大昇降圧力を表します。 */
    readonly liftingPressureMax: string;
    /** 平均作動油温度を表します。 */
    readonly oilTemperatureAvg: string;
    /** エラー一覧を表します。*/
    readonly errors: string[];
    /** 対象日を表します。 */
    readonly dateAt: string;
}

/** 施工記録のグラフ表示用項目のインターフェイスを提供します。 */
export interface MachineOperationHistoryGraphDisplayData {
    /** エンジン稼働時間を表します。 */
    readonly engineOperatingTime?: number;
    /** 昇降距離を表します。 */
    readonly liftingDistance?: number;
    /** 平均エンジン回転数を表します。 */
    readonly engineRotationTimeAvg?: number;
    /** 平均バッテリー電圧を表します。 */
    readonly batteryVoltageAvg?: number;
    /** 最大昇降圧力を表します。 */
    readonly liftingPressureMax?: number;
    /** 平均作動油温度を表します。 */
    readonly oilTemperatureAvg?: number;
    /** エラー一覧を表します。*/
    readonly errors?: string;
    /** 対象日を表します。 */
    readonly dateAt: number;
}

/** グラフ項目の列挙値を表します。 */
export enum GraphType {
    /** Hr メーターを表します。 */
    engineOperatingTime = 1,
    /** 昇降距離を表します。 */
    liftingDistance = 2,
    /** 平均エンジン回転数を表します。 */
    engineRotationTimeAvg = 3,
    /** 平均バッテリー電圧を表します。 */
    batteryVoltageAvg = 4,
    /** 最大昇降圧力を表します。 */
    liftingPressureMax = 5,
    /** 平均作動油温度を表します。 */
    oilTemperatureAvg = 6,
}

/** グラフ オプション のインターフェイスを提供します。 */
export interface GraphOptionString {
    /** データキーを表します。*/
    readonly dataKey: keyof MachineOperationHistoryGraphDisplayData,
    /** 名称を表します。*/
    readonly name: string,
    /** 単位を表します。 */
    readonly unit: string,
    /** グラデーションhtmlタグの Id を表します。 */
    readonly htmlId: keyof MachineOperationHistoryGraphDisplayData, // ユニークである文字列が必要。
    /** カラーを表します。 */
    readonly color: string,
    /** コントラストカラーを表します。 */
    readonly contrastColor: string,
}

/** 施工記録項目一覧のインターフェイスを提供します。*/
export interface MachineOperationHistoryList {
    /** Hr メーターを表します。 */
    readonly engineOperatingTime: GraphOptionString,
    /** 昇降距離を表します。 */
    readonly liftingDistance: GraphOptionString,
    /** 平均エンジン回転数を表します。 */
    readonly engineRotationTimeAvg: GraphOptionString,
    /** 平均バッテリー電圧を表します。 */
    readonly batteryVoltageAvg: GraphOptionString,
    /** 最大昇降圧力を表します。 */
    readonly liftingPressureMax: GraphOptionString,
    /** 平均作動油温度を表します。 */
    readonly oilTemperatureAvg: GraphOptionString,

}
