// react
import * as React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// type
import { InspectionDivisionInputItem, InspectionDivisionValidateInputItem } from '../../_types';
import { KeyValueItem } from '../../../../../_types';
// component
import MasterDetailButtonGroup from '../../../Shared/MasterDetailButtonGroup';
import { MuiTextFieldUtility } from '../../../../../components/MuiTextField/mui-text-field-utility';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },
        // 削除
        deleteButton: {
            color: theme.palette.warning.main,
        },

        // 更新
        updateButton: {
            color: theme.palette.warning.main,
        },

        // テキストエリア
        textarea: {
            width: `calc((250px + ${theme.spacing(1)}px) * 2)!important`,
        },

    }),
);

/** 点検区分マスタ　検索項目の Props を提供します。 */
interface Props {
    /** 初期値を表します。 */
    initialValues: InspectionDivisionInputItem,
    /** 更新ボタンのクリック処理を行います。 */
    onClickAdd: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 更新ボタンのクリック処理を行います。 */
    onClickUpdate: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 削除ボタンのクリック処理を行います。 */
    onClickRemove: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** キャンセルボタンのクリック処理を行います。 */
    onClickCancel: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 点検グループ一覧を表します。*/
    groupList: KeyValueItem<number, string>[],
    /** 新規作成状態かどうかを表します。 */
    isCreating: boolean,
    /** 入力値の変更処理を表します。 */
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof InspectionDivisionInputItem) => void,
    /** バリデーション結果を表します。 */
    validateError: InspectionDivisionValidateInputItem,

};

/**
 * 点検区分マスタ　詳細エリアを提供します。
 * */
const InspectionDivisionDetailCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    // 表示
    return (
        <>
            <Box padding={1}>
                {/* 点検グループ */}
                <TextField
                    select
                    SelectProps={{
                        MenuProps: {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }
                    }}
                    label="点検グループ"
                    value={props.initialValues.groupId ?? ""}
                    onChange={(event) => props.onChange(event, "groupId")}
                    disabled={!props.isCreating}
                    required
                    error={!props.validateError.inspectionGroup === false}
                    helperText={props.validateError.inspectionGroup}
                >

                    {/*　選択肢 */}
                    {props.groupList.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    )
                    )}

                </TextField >

                {/* 重み */}
                <TextField
                    type="number"
                    label="No."
                    value={props.initialValues.weight ?? ""}
                    onChange={(event) => props.onChange(event, "weight")}
                    InputProps={{
                        inputProps: {
                            min: MuiTextFieldUtility.NumericInputMinimum,
                            max: MuiTextFieldUtility.NumericInputMaximum,
                        }
                    }}
                    required
                    error={!props.validateError.weight === false}
                    helperText={props.validateError.weight}
                />

                {/* 点検区分名 */}
                <TextField
                    label="点検区分"
                    placeholder="点検区分"
                    value={props.initialValues.name ?? ""}
                    onChange={(event) => props.onChange(event, "name")}
                    required
                    error={!props.validateError.inspectionDivision === false}
                    helperText={props.validateError.inspectionDivision}
                />

                {/* ボタングループ */}
                <MasterDetailButtonGroup
                    isCreating={props.isCreating}
                    onClickAdd={props.onClickAdd}
                    onClickCancel={props.onClickCancel}
                    onClickUpdate={props.onClickUpdate}
                    onClickRemove={props.onClickRemove}
                />

            </Box>
        </>
    );
};

export default InspectionDivisionDetailCondition;
