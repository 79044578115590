import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ツールチップ
        tooltip: {
            backgroundColor: theme.palette.primary.main,
        },
        // ツールチップ タイトル
        title: {
            color: theme.palette.primary.contrastText,
        },
        // テキスト
        text: {
            backgroundColor: "unset",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }

    })
);

/**
 * オーバーフローしたテキストの Tooltip を表示します。
 * @param props
 */
const OverflowTooltip = (props: TooltipProps) => {
    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    const textElementRef = useRef<HTMLInputElement | null>(null);

    const compareSize = () => {

        if (textElementRef && textElementRef.current) {
            const compare =
                textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            setHover(compare);
        }
    };

    // remove resize listener again on "componentWillUnmount"
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    // remove resize listener again on "componentWillUnmount"
    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);

    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip
            interactive
            disableHoverListener={!hoverStatus}
            title={(
                < Typography className={classes.title}>{props.title}</Typography>
            )
            }
            classes={{ tooltip: classes.tooltip }}
        >
            <div
                ref={textElementRef}
                className={classes.text}
            >
                {props.children}
            </div>
        </Tooltip >
    );
};

export default OverflowTooltip;
