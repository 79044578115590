// redux
import { createSlice, SliceCaseReducers, ValidateSliceCaseReducers, PayloadAction } from '@reduxjs/toolkit';
// component
import { Message } from '../../../components/MuiSnackbar/types';
import { CreateSliceUtility } from './create-slice-utility'

/** ジェネリック型 State のインターフェースを提供します。 */
export interface ExtendGenericState<T, U> {
    // 結果情報を表します。
    result: T,
    // アイテム情報を表します。
    item: U,
    // ロード中かどうかを表します。
    isLoading: boolean,
    // メッセージを表示するかどうかを表します。
    isShowMessage: boolean,
    // メッセージを表します。
    message?: Message,
}

/**
 * slice オブジェクトを作成します。
 * @param name A string name for this slice of state. Generated action type constants will use this as a prefix.
 * @param initialState The initial state value for this slice of state.
 * @param reducers An object containing Redux "case reducer" functions (functions intended to handle a specific action type, equivalent to a single case statement in a switch).
 */
export const createExtendGenericSlice = <T, U, Reducers extends SliceCaseReducers<ExtendGenericState<T, U>>>
    ({
        name = '',
        initialState,
        reducers
    }: {
        name: string
        initialState: ExtendGenericState<T, U>
        reducers: ValidateSliceCaseReducers<ExtendGenericState<T, U>, Reducers>
    }) => {
    return createSlice({
        name,
        initialState,
        reducers: {

            /**
             * 通信開始処理を行います。
             * @param state
             * @param action
             */
            fetchStart(state) {
                state.isLoading = true;
                state.isShowMessage = false;
                state.message = void 0;
            },

            /**
             * If you want to write to values of the state that depend on the generic
             * (in this case: `state.data`, which is T), you might need to specify the
             * State type manually here, as it defaults to `Draft<GenericState<T>>`,
             * which can sometimes be problematic with yet-unresolved generics.
             * This is a general problem when working with immer's Draft type and generics.
             */
            /**
             * 通信成功時の処理を行います。
             * @param state
             * @param action
             */
            fetchSuccess(state: ExtendGenericState<T, U>, action: PayloadAction<T>) {
                state.isLoading = false;
                state.result = action.payload;
            },

            /**
             * 通信失敗処理を行います。
             * @param state
             * @param action
             */
            fetchFailure(state: ExtendGenericState<T, U>, action: PayloadAction<T>) {
                state.isLoading = false;
                state.result = action.payload;
            },

            /**
             * 通信終了時の処理を行います。
             * @param state
             */
            fetchEnd(state) {
                state.isLoading = false;
            },

            /**
             * メッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showMessage(state, action: PayloadAction<Message>) {
                state.isShowMessage = true;
                state.message = action.payload;
            },

            /**
             * 通信成功時のメッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showSuccessMessage(state, action: PayloadAction<string | undefined>) {
                state.isShowMessage = true;
                state.message = {
                    type: "success",
                    text: action.payload ?? CreateSliceUtility.DefualtSuccessMessage,
                }
            },

            /**
             * 通信失敗時のメッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showErrorMessage(state, action: PayloadAction<string | undefined>) {
                state.isShowMessage = true;
                state.message = {
                    type: "error",
                    text: `Error : ${action.payload ?? CreateSliceUtility.DefualtErrorMessage}`,
                }
            },

            /**
             * メッセージの非表示処理を行います。
             * @param state
             * @param action
             */
            hideMessage(state) {
                state.isShowMessage = false;
            },

            /**
             * アイテムの更新を行います。
             * @param state
             * @param action
             */
            updateItem(state: ExtendGenericState<T, U>, action: PayloadAction<U>) {
                state.item = action.payload;

            },

            /**
             * アイテムの更新を行います。
             * @param state
             * @param action
             */
            updateItemPartial(state: ExtendGenericState<T, U>, action: PayloadAction<Partial<U>>) {
                state.item = { ...state.item, ...action.payload };
            },

            ...reducers
        }
    })
}
