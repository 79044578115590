/** 検印リストのインターフェイスを提供します。 */
export interface InspectedStampItem {
    /** 検印情報があるかないかを表します。 */
    readonly exist: boolean;
    /** 帳票No. を表します。 */
    readonly reportNo?: string;
    /** 検印された日付を表します。 */
    readonly reportAt?: string;
    /** 検印者を表します。 */
    readonly inspector?: string;
    /** 初検班長を表します。 */
    readonly firstTeamLeader?: string;
    /** 完検班長を表します。 */
    readonly completeTeamLeader?: string;
    /** 初検課長を表します。 */
    readonly firstManager?: string;
    /** 完検課長を表します。 */
    readonly completeManager?: string;
    /** 検印済みかどうかを表します。 */
    readonly isStamped?: boolean;
}

/** 検印更新ステータスを表します。 */
export enum StampUpdateStaus {
    /** 更新無しを表します。 */
    None = 0,
    /** 検印実行を表します。 */
    Stamp = 1,
    /** 差し戻しを表します。 */
    SendBack = 2,
}

/** 検印者種別を表す列挙値を提供します。 */
export enum InspecterType {
    /** 初検班長 */
    FirstTeamLeader = 1,
    /** 完検班長 */
    CompleteTeamLeader = 2,
    /** 初検課長 */
    FirstManager = 3,
    /** 完検課長 */
    CompleteManager = 4,
}

/** 検印情報の更新テーブルの項目のインターフェイスを提供します。 */
export interface UpdateInspectedStampListItem<TKey> {
    /** キーを表します。 */
    readonly key: TKey,
    /** 帳票No.を表します。 */
    readonly reportNo?: string,
    /** 帳票日 を表します。 */
    readonly reportAt?: string,
    /** 検印者を表します。 */
    readonly inspector?: string;
    /** 項目名を表します。 */
    readonly data: string,
    /** 検印情報があるかどうかを表します。 */
    readonly exist: boolean,
    /** 更新前の検印状態を表します。 */
    readonly isStampBefore?: boolean,
    /** 更新後の検印状態を表します。 */
    readonly isStampAfter?: boolean,
    /** ステータスを表します。 */
    readonly status: StampUpdateStaus,
}

/** */
export interface ReportItem {
    /** 帳票のURLを表します。 */
    reportUrl: string,
}
