import * as React from 'react';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Divider,
    Box,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';
import MuiUpdateButton from '../../../../../components/Buttons/MuiUpdateButton';

import { EditItem } from '../../_types'
import { MuiTextFieldUtility } from '../../../../../components/MuiTextField/mui-text-field-utility';
import MuiKeybordDatePicker from '../../../../../components/MuiPickers/MuiKeybordDatePicker';
import { MuiDatePickerUtility } from '../../../../../components/MuiPickers/mui-date-picker-utility';
import { MachineDataType } from '../../../MachineStatus/_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            // アイコン
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
        },
        // サブタイトル
        subtitle: {
            marginBottom: theme.spacing(2),
        },
        // 名称
        name: {
            fontSize: "1.25rem",
            marginRight: theme.spacing(1),
        },
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // ボタン
        button: {
            minWidth: 120,
        },
        // divider
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        // 現在値
        currentValue: {
            "&$disabled": {
                color: theme.palette.text.primary,
            }
        },
        // 無効状態 (disabled状態の入力値の色をデフォルトで表示する)
        disabled: {},
        // ダイアログコンテンツ(ダイアログ内のTextfiledのlabelが全角4文字以上でスクロールが発生するで、無効にする)
        dialogContent: {
            overflowY: "visible"
        }
    }),
);

/** ステータス編集ダイアログの State を表示します。 */
export interface EditDialogState {
    /** 入力値を表します */
    value: number | null,
    /** カレンダー形式の入力値を表します */
    date: Date | null,
    /** アワメーターを表します。 */
    hrMeter: number | null,
    /** パスワードを表します */
    password: string | null,
}

/** バリデーションのエラー情報インターフェイスを提供します。 */
export interface ValidateInputItem {
    /** 変更後の値を表します。 */
    readonly value: string,
    /** アワメーターを表します。 */
    readonly hrMeter: string,
    /** 社番 を表します。 */
    readonly password: string;
}


/** ステータス編集ダイアログの Props を表示します。 */
interface Props {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** カレンダー入力かどうかを表します。 */
    isCalendar: boolean,
    /** アワメータの入力が必要かどうかを表します。 */
    isRequiredHrMeter: boolean,
    /** 更新するアイテムを表します。 */
    editItem: EditItem,
    /** 更新ボタンクリック時の処理を表します。 */
    onUpdateClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, input: EditDialogState,) => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
}

/**
 * ステータス編集ダイアログを表示します。
 * @param props
 */
const EditDialog: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** State */
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.isOpen)

        if (props.isOpen === false) {
            setValues(preValues => ({
                value: null,
                date: null,
                hrMeter: null,
                password: null,
            }));
        }
    }, [props.isOpen])


    /** 入力内容を表します。 */
    const [values, setValues] = useState<EditDialogState>({
        value: null,
        date: null,
        hrMeter: null,
        password: null,
    });

    //const [inputDate, setInputDate] = useState<Date | null>(null);

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<ValidateInputItem>({
        value: "",
        hrMeter: "",
        password: "",
    })

    /** パスワードの表示状態を表します。 */
    const [isShowPassword, setIsShowPassword] = useState(false);

    /**
     * 入力項目　内容変更時のイベント処理を行います。
     * @param prop
     */
    const onChange = (name: keyof EditDialogState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        // 値を選別
        const target = event.target;

        let value;
        if (name === "hrMeter") {
            //　アワメーターはマイナスを許容する
            value = MuiTextFieldUtility.LimitationNumberAllowMinus(Number.parseInt(target.value, 10));
        } else {
            value = target.type === "number" ? MuiTextFieldUtility.LimitationNumber(Number.parseInt(target.value, 10)) : target.value;
        }

        // State の入力値は string 型で保持する
        value = !value ? value : value.toString();

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /**
     * 入力項目　内容変更時のイベント処理を行います。
     * @param newValue
     */
    const onChangeDate = (newValue: Date | null) => {
        // 時間を ゼロ(00:00:000) に変換, 経過日数算出
        if (newValue != null) newValue.setHours(0, 0, 0, 0);
        // 入力項目更新
        setValues({ ...values, date: newValue });
    };


    /** パスワード表示非表示ボタン　クリック時のイベント処理を行います。*/
    const handleClickShowPassword = () => {
        setIsShowPassword(preValue => !preValue);
    };

    /**
     * パスワード表示非表示ボタン　マウスダウン時のイベント処理を行います。
     * @param event
     */
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    /** ダイアログの閉じる処理を行います。 */
    const onClose = () => {

        setValues({
            value: null,
            date: null,
            hrMeter: null,
            password: "",
        });

        setValidate({
            value: "",
            hrMeter: "",
            password: "",
        });

        props.handleClose();
    }

    /**
     * 更新ボタンのクリック処理を行います。
     * @param event
     */
    const onUpdate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        // バリデーション
        const isError = onValidateInputItem()

        if (isError === false) {
            props.onUpdateClick(event, values);
        }
    }

    /** 入力内容のバリデーションを行います。 */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let valueError = "";
        let hourMeterError = "";
        let passError = "";

        // 更新値
        if (!props.isCalendar && values.value == null) {
            valueError = "値を入力してください";
            result = true;
        }
        // 更新値(カレンダー形式)
        if (props.isCalendar && values.date == null) {
            valueError = "日付を選択してください"
            result = true;
        }

        // アワメーター
        if (props.isRequiredHrMeter && values.hrMeter == null) {
            hourMeterError = "アワメーターを入力してください";
            result = true;
        }

        // パスワード
        if (!values.password) {
            passError = "パスワードを入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            value: valueError,
            hrMeter: hourMeterError,
            password: passError,
        });

        // 結果
        return result;

    }

    /**
     * form 送信イベント処理を行います
     * @param e
     */
    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        // Enter key による submit を無効にする
        e.preventDefault();
    }


    return (
        <Dialog
            keepMounted
            maxWidth="sm"
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
        >
            {/* タイトル */}
            <DialogTitle id="form-dialog-title">
                <Box className={classes.title}>
                    <InfoIcon fontSize="large" />施工機メンテナンス設定
                </Box>
            </DialogTitle>

            <DialogContent classes={{ root: classes.dialogContent }}>
                {/* message */}
                <Typography className={classes.subtitle}>
                    <span className={classes.name}>
                        {`${props.editItem.name} [${props.editItem.unit}]`}
                    </span>
                    <span>の更新を行います。</span>
                </Typography>

                <form onSubmit={e => submitHandler(e)}>
                    {/* 現在値 */}
                    <TextField
                        fullWidth
                        type="number"
                        autoComplete="off"
                        className={classes.inputItem}
                        value={props.editItem.value ?? ""}
                        disabled
                        label="現在の値"
                        InputProps={{
                            classes: {
                                root: classes.currentValue,
                                disabled: classes.disabled

                            }
                        }}
                    />

                    <Divider className={classes.divider} />

                    {/* 更新値 */}
                    {/* カスタムエラーメッセージ上書き
                      * https://github.com/mui-org/material-ui-pickers/issues/574#issuecomment-433581882
                      */}
                    {props.isCalendar
                        // カレンダー
                        ? <MuiKeybordDatePicker
                            fullWidth
                            autoComplete="off"
                            className={classes.inputItem}
                            label="実施日"
                            format={MuiDatePickerUtility.dateFormat}
                            placeholder={MuiDatePickerUtility.dateFormat}
                            disableFuture
                            value={values.date}
                            onChange={(data) => onChangeDate(data)}
                            required
                            error={!validate.value === false}
                            helperText={validate.value}
                        />
                        // 数値入力
                        : <TextField
                            fullWidth
                            type="number"
                            autoComplete="off"
                            className={classes.inputItem}
                            label={props.editItem.type === MachineDataType.CraneTotalDistance ? "変更後の距離[m]" : "変更後の時間[hr]"}
                            value={values.value ?? ""}
                            onChange={onChange("value")}
                            InputProps={{
                                inputProps: {
                                    min: MuiTextFieldUtility.NumericInputMinimum,
                                    max: MuiTextFieldUtility.NumericInputMaximum,
                                }
                            }}
                            required
                            error={!validate.value === false}
                            helperText={validate.value}
                        />
                    }

                    {/* アワーメーター CTUなしかつ特定の項目のみ*/}
                    {props.isRequiredHrMeter &&
                        <TextField
                            fullWidth
                            type="number"
                            autoComplete="off"
                            className={classes.inputItem}
                            label="現在のアワーメーター[hr]"
                            value={values.hrMeter ?? ""}
                            onChange={onChange("hrMeter")}
                            InputProps={{
                                inputProps: {
                                    min: MuiTextFieldUtility.MinusNumericInputMinimum,
                                    max: MuiTextFieldUtility.MinusNumericInputMaximum,
                                }
                            }}
                            required
                            error={!validate.hrMeter === false}
                            helperText={validate.hrMeter}
                        />}

                    {/* パスワード */}
                    <TextField
                        fullWidth
                        type={isShowPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        className={classes.inputItem}
                        label="パスワード"
                        value={values.password ?? ""}
                        onChange={onChange("password")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        required
                        error={!validate.password === false}
                        helperText={validate.password}
                    />
                </form>
            </DialogContent>

            {/* ボタンアクション */}
            <DialogActions>
                <MuiCancelButton
                    className={classes.button}
                    onClick={onClose}
                />
                <MuiUpdateButton
                    className={classes.button}
                    onClick={(event) => onUpdate(event)}
                />
            </DialogActions>
        </Dialog>
    );
}

export default EditDialog;
