import { MaintenanceDivisionListItem, MaintenanceDivisionInputItem } from '../../../pages/Master/MaintenanceDivision/_types';
import { MaintenanceDivisionRequest, MaintenanceDivisionSelectBoxItemState } from './types';
import { MaintenanceDivisionInput, MaintenanceDivisionItem } from '../../swagger-clients';

/**
 * `整備区分マスタ` 関連の ユーティリティ機能を提供します。
 */
export class MaintenanceDivisionUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(
        selectBoxItem: MaintenanceDivisionSelectBoxItemState,
        items?: MaintenanceDivisionItem[]
    ): MaintenanceDivisionListItem[] {
        const result: MaintenanceDivisionListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.id == null
                || source.machineTypeId == null
                || source.weight == null
                || source.lockVersion == null) {
                continue;
            }

            // セレクトボックスの名称を取得
            const typeItem = selectBoxItem.MachineType.find(_ => _.key === source.machineTypeId);

            const item: MaintenanceDivisionListItem = {
                id: source.id,
                name: source.name ?? "",
                machineType: typeItem?.value ?? "",
                machineTypeId: source.machineTypeId,
                weight: source.weight,
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: MaintenanceDivisionRequest): MaintenanceDivisionInput {
        const result = new MaintenanceDivisionInput();
        result.init({
            item: MaintenanceDivisionUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: MaintenanceDivisionInputItem): MaintenanceDivisionItem {
        const result = new MaintenanceDivisionItem();
        result.init({
            id: source.id,
            name: source.name,
            machineTypeId: source.machineTypeId,
            weight: source.weight,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
