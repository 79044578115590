import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 帳票アイコンボタン
        icon: {
            color: theme.palette.text.secondary
        },
        // 検印済み
        iconStamped: {
            color: theme.palette.success.main
        }
    })
);

/** 帳票記録テーブル項目のインターフェイスを提供します。 */
interface ReportListItem {
    /** 検印済みかどうかを表します。 */
    isStamped?: boolean,
}

/** 帳票アイコン ボタン の Props を提供します。 */
interface Props<T> {
    /** 行データを表します。 */
    rowData: T & ReportListItem,
    /** クリック時の処理を表します。 */
    onClick: (rowData: T) => void,
}

/**
 * 帳票アイコンボタンを表示します。
 * @param props Props を指定します。
 */
export function ReportIconButton<T>(props: Props<T>): JSX.Element {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <IconButton
            className={props.rowData.isStamped ? classes.iconStamped : classes.icon}
            onClick={() => props.onClick(props.rowData)}
        >
            <DescriptionIcon />
        </IconButton>
    );
};
