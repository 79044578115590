import * as React from 'react';
import { Box, Collapse } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        collapse: {
            width: "100%",
        },
    }),
);

/** 折りたたみ拡張レイアウトの Props を提供します。 */
interface Props {
    /** 開いているかどうかを表します。 */
    isExpanded: boolean
    /** デフォルトコンテンツを表します。 */
    defaultContent?: JSX.Element,
    /** 拡張コンテンツを表します。 */
    expandContent?: JSX.Element,
};

/**  折りたたみ拡張レイアウトを表示します。 */
const CollapseLayout: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box className={classes.root}>

            {/* デフォルトコンテンツ  isExpanded が false なら 非表示 */}
            <Collapse in={!props.isExpanded} className={classes.collapse}>
                {props.defaultContent}
            </Collapse>

            {/* 拡張コンテンツ  isExpanded が True なら 表示 */}
            <Collapse in={props.isExpanded} className={classes.collapse}>
                {props.expandContent}
            </Collapse>

        </Box>

    );
}

export default CollapseLayout;
