// react
import * as React from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // バックドロップ
        backdrop: {
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        },
    }),
);

/** ローディング画面の Props のインターフェイスを提供します。 */
interface Props {
    /** ローディング画面を表示するかどうかを表します。 */
    isLoading: boolean,
}

/**
 *  ローディング画面を表示します。
 * @param props
 */
const MuiBackDropProgress: React.FC<Props> = (props) => {

    /** スタイルを提供します。 */
    const classes = useStyles();

    return (
        <Backdrop
            className={classes.backdrop}
            open={props.isLoading}
        >
            <CircularProgress
                color="primary"
                size={60}
            />
        </Backdrop >
    );
};

export default MuiBackDropProgress;
