// react
import * as React from 'react';
// material-ui
import { ButtonProps } from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';
import MuiColorButton from './ColorButton/MuiColorButton';

/**
 *  プレビューボタンを表示します。
 * @param props
 */
const MuiPreviewButton: React.FC<ButtonProps> = (props) => {

    return (

        <MuiColorButton
            {...props}
            overrideColor="secondary"
            variant="contained"
            startIcon={<PageviewIcon />}
        >
            プレビュー
        </MuiColorButton>
    )
}

export default MuiPreviewButton;
