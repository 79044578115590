// react
import * as React from 'react';
import { useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { onGetAsync } from '../../../../../modules/slice/machine-error-history/machine-error-history-slice';
import { RootState } from '../../../../../rootReducer';

// compoent
import AlertRecordSearch, { AlertRecordSerachState } from './AlertRecordSearch'
// type
import { MachineErrorHistoryInputItem } from '../../_types';


/** アラート履歴画面 検索エリアを提供します。*/
export const AlertRecordSearchContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine);

    /** 検索ボタンのクリック処理を行います。 */
    const onSearchClickAsync = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: AlertRecordSerachState) => {

        if (machine) {

            // 実行
            await dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    beginAt: inputItem.beginAt,
                    endAt: inputItem.endAt,
                }
            }));
        }
    };

    return (
        <AlertRecordSearch
            onSearchClick={onSearchClickAsync}
        />
    );

};
