import { Theme } from '@material-ui/core/styles';
import { defaultTheme, darkTheme } from '../theme';

/**
 * テーマ 関連の ユーティリティ機能を提供します。
 * */
export class ThemeUtility {

    //#region フィールド

    //#endregion フィールド


    //#region メソッド

    /**
     * Theme を返却します。
     * @param isDark
     */
    public static selectTheme(isDark: boolean): Theme {

        return isDark ? darkTheme : defaultTheme;

    };


    //#endregion メソッド

}
