import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { ThemeSliceUtility } from './theme-slice-utility';

/** テーマ選択の State を提供します。 */
interface State {
    /** ダークモードかどうかを表します。 */
    isDark: boolean,
}

/** State の初期値を設定します。 */
const initialState: State = {
    isDark: ThemeSliceUtility.getStorageTheme(),
}

/** テーマ Slice を 提供します。*/
export const themeSlice = createSlice({
    name: 'theme',　 // createSlice を識別するための名前
    initialState,       // State の初期値を入れる
    reducers: {         // State の変更処理

        /**
         * テーマを変更します。
         *  @param state
         *  @param action
         */
        setTheme: (state: State, action: PayloadAction<boolean>) => {
            state.isDark = action.payload;
        },
    },
})

/**
 * テーマを変更します。
 * @param isDark
 */
export function changeDarkTheme(isDark: boolean) {
    return (dispatch: Dispatch) => {
        // ストレージ更新
        ThemeSliceUtility.setStorageTheme(isDark);

        // store 更新
        dispatch(themeSlice.actions.setTheme(isDark));
    }
}
