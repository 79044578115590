//react
import * as React from 'react';
// material-ui
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import {
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/lab';
// type
import { AreaType } from '../../_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: 'flex',
            border: `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
            justifyContent: "center",
        },
        root: {
            "&.Mui-selected": {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            },
            "&.Mui-selected:hover": {
                backgroundColor: theme.palette.primary.main,
            }
        },
        grouped: {
            margin: theme.spacing(0.5),
            border: 'none',
            '&:not(:first-child)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-child': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }),
);

/** エリア選択のトグルボタングループ の Props を提供します */
interface Props {
    /** エリアを表します。*/
    area: AreaType,
    /** エリア選択時のイベント処理を表します */
    onChangeArea: (event: React.MouseEvent<HTMLElement>, selectedAera: AreaType | null) => void,
}

/**
 * エリア選択のトグルボタングループを表示します。
 * */
export default function AreaToggleButton(props: Props) {

    const classes = useStyles();

    return (
        <div>

            <Box fontWeight="fontWeightBold">
                所属
            </Box>

            <Paper elevation={0} className={classes.paper}>
                <ToggleButtonGroup
                    classes={{ grouped: classes.grouped }}
                    size="small"
                    value={props.area}
                    exclusive
                    onChange={(event, value) => props.onChangeArea(event, value)}
                    aria-label="area-type"
                >
                    <ToggleButton
                        classes={{ root: classes.root }}
                        value={AreaType.None}
                        aria-label="nation-area">
                        <Typography>
                            全国
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        classes={{ root: classes.root }}
                        value={AreaType.West}
                        aria-label="west-area">
                        <Typography>
                            西地区
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        classes={{ root: classes.root }}
                        value={AreaType.East}
                        aria-label="east-area">
                        <Typography>
                            東地区
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Paper>
        </div>
    );
}
