// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
// component
import AlertRecordGraph from './AlertRecordGraph';
import { AlertRecordGraphUtility } from './AlertRecordGraphUtility';

/**
 * アラート履歴画面 グラフを表示します。
 * */
export const AlertRecordGraphContainer: React.FC<{}> = () => {

    // redux store
    const graphDataSource = useSelector((state: RootState) => state.machineErrorHistory.result.graphItems);

    return (
        <AlertRecordGraph
            dataSource={AlertRecordGraphUtility.toGraphData(graphDataSource.map(item => ({ ...item })))}
        />
    );
};
