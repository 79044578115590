import * as React from 'react';
import { AreaProps, YAxisProps } from 'recharts';
import { GraphType, GraphOptionString, MachineOperationHistoryList, MachineOperationHistoryGraphItem, MachineOperationHistoryGraphDisplayData } from '../../_types'

/**
 * 施工記録グラフ 関連の ユーティリティ機能を提供します。
 * */
export class GraphContentUtility {
    //#region フィールド

    /** 施工記録項目一覧を表します。 */
    static readonly constantString: MachineOperationHistoryList = {
        engineOperatingTime: {
            dataKey: "engineOperatingTime",
            name: "エンジン稼働時間",
            unit: "Hr",
            htmlId: "engineOperatingTime",
            color: "#007aff",
            contrastColor: "#FFFFFF",

        },
        liftingDistance: {
            dataKey: "liftingDistance",
            name: "クレーン昇降距離",
            unit: "m",
            htmlId: "liftingDistance",
            color: "#ff9500",
            contrastColor: "#000000",
        },
        engineRotationTimeAvg: {
            dataKey: "engineRotationTimeAvg",
            name: "平均エンジン回転数(日)",
            unit: "rpm",
            htmlId: "engineRotationTimeAvg",
            color: "#34c759",
            contrastColor: "#FFFFFF",
        },
        batteryVoltageAvg: {
            dataKey: "batteryVoltageAvg",
            name: "平均バッテリー電圧(日)",
            unit: "V",
            htmlId: "batteryVoltageAvg",
            color: "#5856d6",
            contrastColor: "#FFFFFF",
        },
        liftingPressureMax: {
            dataKey: "liftingPressureMax",
            name: "最大昇降圧力(日)",
            unit: "Mpa",
            htmlId: "liftingPressureMax",
            color: "#ff2d54",
            contrastColor: "#FFFFFF",
        },
        oilTemperatureAvg: {
            dataKey: "oilTemperatureAvg",
            name: "平均作動油温度(日)",
            unit: "℃",
            htmlId: "oilTemperatureAvg",
            color: "#af52de",
            contrastColor: "#FFFFFF",
        },
    }
    //#endregion フィールド

    //#region メソッド

    /**
     * グラフオプションに変換します。
     * @param type
     */
    public static graphOption(type: GraphType): GraphOptionString | undefined {
        const result: GraphOptionString | undefined =
            type === GraphType.engineOperatingTime ? GraphContentUtility.constantString.engineOperatingTime :
                type === GraphType.liftingDistance ? GraphContentUtility.constantString.liftingDistance :
                    type === GraphType.engineRotationTimeAvg ? GraphContentUtility.constantString.engineRotationTimeAvg :
                        type === GraphType.batteryVoltageAvg ? GraphContentUtility.constantString.batteryVoltageAvg :
                            type === GraphType.liftingPressureMax ? GraphContentUtility.constantString.liftingPressureMax :
                                type === GraphType.oilTemperatureAvg ? GraphContentUtility.constantString.oilTemperatureAvg :
                                    void 0;
        return result;
    }



    /**
    *グラフ用データを変換します。
    * @param dataSource
    */
    public static toGraphData = (dataSource: MachineOperationHistoryGraphItem[]) => {

        const result = dataSource
            .filter(item => item.dateAt != null)
            .map((item) => {
                let graphData: MachineOperationHistoryGraphDisplayData
                graphData = {
                    // 対象日 ミリ秒に変換
                    dateAt: new Date(item.dateAt).getTime(),
                    // エラー一覧
                    errors: item.errors.length ? item.errors.join("\n") : void 0,

                    /* 以下、値を　`!value` で　null & 空文字チェック */

                    // エンジン稼働時間
                    engineOperatingTime: !item.engineOperatingTime ? void 0 : parseInt(item.engineOperatingTime, 10),
                    // 昇降距離
                    liftingDistance: !item.liftingDistance ? void 0 : parseInt(item.liftingDistance, 10),
                    // 平均エンジン回転数
                    engineRotationTimeAvg: !item.engineRotationTimeAvg ? void 0 : parseInt(item.engineRotationTimeAvg, 10),
                    // 平均バッテリー電圧
                    batteryVoltageAvg: !item.batteryVoltageAvg ? void 0 : parseInt(item.batteryVoltageAvg, 10),
                    // 最大昇降圧力
                    liftingPressureMax: !item.liftingPressureMax ? void 0 : parseInt(item.liftingPressureMax, 10),
                    //  平均作動油温度
                    oilTemperatureAvg: !item.oilTemperatureAvg ? void 0 : parseInt(item.oilTemperatureAvg, 10),
                }
                return graphData;
            })
            .sort((a, b) => (a.dateAt - b.dateAt)); // 時系列順にソート

        return result;
    }

    /**
     * グラフエリアのグラデーションを表示します。
     * @param props
     */
    public static GradientLine(props: GraphOptionString): JSX.Element {
        return (
            <defs>
                {/* グラデーション */}
                <linearGradient id={props.htmlId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={props.color} stopOpacity={0.3} />
                    <stop offset="100%" stopColor={props.color} stopOpacity={0} />
                </linearGradient>
            </defs>
        )
    }

    /**
     * カスタマイズしたドットを表示します。
     * @param props
     */
    public static CustomizedDot(props: any): JSX.Element {
        // payload -> グラフデータ、
        // value   -> 配列 [1] に値 が格納されている
        const { payload, value, key, cx, cy, fill, fillOpacity, r, stroke, strokeWidth } = props;

        // 型アサーション
        const data = payload as MachineOperationHistoryGraphDisplayData;

        let result: JSX.Element;
        if (value[1] == null) {
            // データ無し(Dot無し)
            result = <div key={key}></div>;
        } else if (data.errors) {
            // エラー情報あり
            result = <circle key={key} cx={cx} cy={cy} r={6} stroke={stroke} strokeWidth={3} fill={"red"} fillOpacity={1} />
        } else {
            // エラー情報なし
            result = <circle key={key} cx={cx} cy={cy} r={r} stroke={stroke} strokeWidth={strokeWidth} fill={stroke} fillOpacity={1} />;
        }

        return result;
    }

    /**
     * アクティブ状態(マウスホバー時)のカスタマイズしたドットを表示します。
     * @param props
     */
    public static CustomizedActiveDot(props: any): JSX.Element {
        // payload -> グラフデータ、
        // value   -> 配列 [1] に値 が格納されている
        const { payload, value, key, cx, cy, fill, fillOpacity, r, stroke, strokeWidth } = props;

        // 型アサーション
        const data = payload as MachineOperationHistoryGraphDisplayData;

        let result: JSX.Element;
        if (value[1] == null) {
            // データ無し(Dot無し)
            result = <div key={key}></div>;
        } else if (data.errors) {
            // エラー情報あり
            result = <circle key={key} cx={cx} cy={cy} r={9} stroke={fill} strokeWidth={3} fill={"red"} fillOpacity={fillOpacity} />
        } else {
            // エラー情報なし
            result = <circle key={key} cx={cx} cy={cy} r={6} stroke={stroke} strokeWidth={strokeWidth} fill={fill} fillOpacity={fillOpacity} />
        }

        return result;
    }

    //#region Props 共通化

    // 共通化をトライしたが、表示グラフを選択変更した際、オプションが追従しない為、.tsx に直書き。

    ///**
    // * エリアのカラー用文字列に変換します。
    // * @param id
    // */
    //private static toGradientUrl(id: string) { return `url(#${id})` };

    ///**
    // * Y軸のタイトル文字に変換します。
    // * @param title
    // * @param unit
    // */
    //private static toYAxisLabel(title: string, unit: string) { return `${title} [${unit}]` };

    ///**
    // * エリアのオプションに変換します。
    // * @param item
    // */
    //public static toAreaProps(type: GraphType): AreaProps {
    //    let result: AreaProps = { dataKey: "" };

    //    const item = GraphContentUtility.graphOption(type);
    //    if (item == null) return result;

    //    result = {
    //        dataKey: item.dataKey,
    //        name: item.name,
    //        unit: item.unit,
    //        stroke: item.color,
    //        fill: GraphContentUtility.toGradientUrl(item.htmlId),

    //    }
    //    return result;
    //}

    ///**
    // * Y軸のオプションに変換します。
    // * @param item
    // */
    //public static toYAxisProps(item: GraphOptionString): YAxisProps {
    //    const result: YAxisProps = {
    //        label: {
    //            value: GraphContentUtility.toYAxisLabel(GraphContentUtility.constantString.engineOperatingTime.dataKey, GraphContentUtility.constantString.engineOperatingTime.unit),
    //            fill: GraphContentUtility.constantString.engineOperatingTime.color,
    //        },
    //        tick: {
    //            fill: GraphContentUtility.constantString.engineOperatingTime.color,
    //            stroke: GraphContentUtility.constantString.engineOperatingTime.color,
    //        },
    //        stroke: GraphContentUtility.constantString.engineOperatingTime.color,
    //    }
    //    return result;
    //}

    //#endregion オプション共通化

    //#endregion メソッド

}
