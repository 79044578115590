// react
import * as React from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { OtherReportSlice, onGetAsync } from '../../../../../modules/slice/other-report/other-report-slice';
import ReportDetailDialog from './ReportDetailDialog';
import * as iconv from "iconv-lite";
// import * as JSZip from 'jszip';
const JSZip = require('jszip');

/** 画像データのインターフェースを提供します。 */
interface ImageData {
    /** 画像を表します。 */
    imgBlob: Blob | null,
    /** ファイル名を表します。 */
    fileName: string,

}

/**
 * その他報告詳細ダイアログを表示します。
 * @param props
 */
export const ReportDetailDialogContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const selectedRowItem = useSelector((state: RootState) => state.otherReport.item.selectedRowItem);
    const isOpenDialog = useSelector((state: RootState) => state.otherReport.item.isOpenDialog);

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        dispatch(OtherReportSlice.actions.closeDialog());
    };

    /* 画像ダウンロード処理を行います。*/
    const onDownloadClick = async () => {
        // null チェック
        if (selectedRowItem == null) {
            dispatch(OtherReportSlice.actions.showErrorMessage("その他報告データが指定されていません"));
            return;

        } else if (!selectedRowItem.no) {
            dispatch(OtherReportSlice.actions.showErrorMessage("報告書No.が指定されていません"));
            return;

        } else if (!selectedRowItem.imagePaths.length) {
            dispatch(OtherReportSlice.actions.showErrorMessage("画像パスが指定されていません"));
            return;

        } else {
            // ダウンロード
            await downloadImagesAsync(selectedRowItem.imagePaths, selectedRowItem.no)
        }

    };

    /**
     * 画像ファイルを取得します。
     * @param source
     */
    async function getImageDataAsync(source: string[]) {
        // JSZip に追加するために非同期リクエストを Promise で wrap
        const imagePromises = source.map((src, i) => new Promise<ImageData>((resolve, reject) => {

            fetch(src, {
                method: "GET",
            })
                .then(response => response.blob())
                .then((response) => {
                    // ファイル名とデータ返却
                    console.log({ response });
                    const fileName = src.slice(src.lastIndexOf("/") + 1);
                    resolve({ imgBlob: response, fileName: fileName });
                })
                .catch((e) => {
                    // reject だと await Promise.all を抜けてしまう
                    // => resolve でデータ無し
                    console.log({ e });
                    resolve({ imgBlob: null, fileName: "" });
                })
        }));

        // すべての画像を取得
        const result = await Promise.all(imagePromises);

        return result;
    }

    /**
     * 画像をダウンロードします。
     * @param source
     * @param folderName
     */
    async function downloadImagesAsync(source: string[], folderName: string) {

        // 画像を取得
        const items = await getImageDataAsync(source);

        let nullData: ImageData[] = [];
        let imageData: ImageData[] = [];

        items.forEach(item => {
            item.imgBlob == null ? nullData.push(item) : imageData.push(item);
        });

        if (!imageData.length) {
            dispatch(OtherReportSlice.actions.showErrorMessage("画像ファイルを取得できませんでした"));
            return;
        } else if (nullData.length) {
            dispatch(OtherReportSlice.actions.showErrorMessage("取得に失敗した画像ファイルがあります"));
        }

        // Zip ファイル生成＆ダウンロード
        generateImagesZipAsync(imageData, folderName);
    }

    /**
     * Zip ファイルをで画像をダウンロードします。
     * @param images
     * @param folderName
     */
    async function generateImagesZipAsync(images: ImageData[], folderName: string) {
        const zip = new JSZip();

        // フォルダ下に画像を格納
        images.forEach(image => {
            if (image.imgBlob && image.fileName) {
                zip.file(image.fileName, image.imgBlob)
            }
        });

        // zip を生成
        await zip.generateAsync(
            {
                type: "blob",
                encodeFileName: function (fileName: string) { return iconv.encode(fileName, "CP932") },
                compression: "DEFLATE",
                compressionOptions: { level: 9 },

            }
        )
            .then((blob: Blob) => {

                // ダウンロードリンクを 生成
                const dlLink = document.createElement("a");

                // blob から URL を生成
                const dataUrl = URL.createObjectURL(blob);
                dlLink.href = dataUrl;
                dlLink.download = `${folderName}.zip`;

                // 設置/クリック/削除
                document.body.insertAdjacentElement("beforeend", dlLink);
                dlLink.click();
                dlLink.remove();

                // オブジェクト URL の開放
                setTimeout(function () {
                    window.URL.revokeObjectURL(dataUrl);
                }, 1000);
            });
    }



    return (
        <>
            {/* モーダル表示 */}
            {selectedRowItem && (
                <ReportDetailDialog
                    selectedRowItem={selectedRowItem}
                    isOpen={isOpenDialog}
                    handleClose={closeDialog}
                    onDwonloadClick={onDownloadClick}
                />
            )}
        </>
    )
}
