// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { MaintenanceRecordSlice, onUpdateMaintenanceInspectedStampAsync } from '../../../../../modules/slice/maintenance-record/maintenance-record-slice';
import { RootState } from '../../../../../rootReducer';
import InspectionStampForm from './InspectionStampForm';
import { ConstructionMacineMasterUtility } from '../../../Shared/ConstructionMacineMasterUtility';
import { UpdateInspectedStampListItem, InspecterType, InspectedStampItem } from '../../../_types';
import { KeyValueItem } from '../../../../../_types';

/** 整備記録画面　検印エリアを表示します。 */
export const StampContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine)
    const inspector = useSelector((state: RootState) => state.authentication?.user?.inspectorName ?? "");
    const inspectionStampItems: KeyValueItem<InspecterType, InspectedStampItem>[] = useSelector((state: RootState) => state.maintenanceRecord?.report?.stampItems ?? []);
    const selectedItem = useSelector((state: RootState) => state.maintenanceRecord?.selectedItem);
    const isUpdated: boolean = useSelector((state: RootState) => state.maintenanceRecord?.isUpdated ?? false);

    /** 検印ボタンのクリック処理を行います。 */
    const onUpdateClick = async (inputItems: UpdateInspectedStampListItem<InspecterType>[]) => {

        // 更新用パラメータ成型
        const updateItem = ConstructionMacineMasterUtility.toUpdateMaintenanceInspectedStampItem(inputItems);
        if (!machine) {
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage("施工機情報がありません。"));
        } else if (!inspector) {
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage("検印者情報がありません。"));
        } else if (!selectedItem) {
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage("選択項目の情報がありません。"));
        } else if (!inspectionStampItems.length) {
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage("帳票情報がありません。"));
        } else if (!updateItem) {
            dispatch(MaintenanceRecordSlice.actions.showErrorMessage("検印更新情報がありません。"));
        } else {
            // 検印更新
            dispatch(onUpdateMaintenanceInspectedStampAsync(
                {
                    machineType: machine.type,
                    item: updateItem,
                },
                {
                    machineId: machine.item.id,
                    machineType: machine.type,
                    reportAt: selectedItem.completionDate,
                    reportNo: selectedItem.no,
                }
            ))
        }
    };

    /** 戻るボタンのクリック処理を行います。 */
    const onBackClick = () => {
        // ビューワを閉じる
        dispatch(MaintenanceRecordSlice.actions.toggleViewre(false));
    };

    return (
        <>
            <InspectionStampForm
                inspector={inspector}
                stampDatas={inspectionStampItems}
                onUpdateClick={onUpdateClick}
                onBackClick={onBackClick}
                isUpdated={isUpdated}
            />

        </>
    );

};
