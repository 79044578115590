import { CompletionInspectionRecordClient, GetCompletionInspectionRecordInput, GetDocumentInput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { CompletionInspectionRecordGetRequest, CompletionInspectionRecordGetResult, CompletionInspectionRecordGetDocumentRequest, CompletionInspectionRecordGetDocumentResult } from './types';
import { CompletionInspectionRecordUtility } from './completion-inspection-record-utility';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `完了時点検記録` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class CompletionInspectionRecordAdapter extends WebApiAdapter<CompletionInspectionRecordClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new CompletionInspectionRecordAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: CompletionInspectionRecordGetRequest): Promise<CompletionInspectionRecordGetResult> {
        function createErrorResult(message?: string): CompletionInspectionRecordGetResult {
            return {
                items: [],
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetCompletionInspectionRecordInput();
        input.init({
            machineId: parameter.item.machineId,
            machineType: SharedUtility.fromMachineType(parameter.item.machineType),
            searchType: CompletionInspectionRecordUtility.toSearchType(parameter.item.searchType),
            searchWord: parameter.item.keyword,
            searchBeginAt: parameter.item.beginAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.beginAt),
            searchEndAt: parameter.item.endAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.endAt),
            isInspected: CompletionInspectionRecordUtility.toisInspected(parameter.item.searchStampType),
        });
        const output = await this.client.get(input);
        if (output == null) {
            return createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResult(output.errorMessage);
        }

        // 正常
        const result: CompletionInspectionRecordGetResult = {
            isError: false,
            items: CompletionInspectionRecordUtility.toListItem(output.items),
        };
        return result;
    }

    /**
     * ドキュメントの取得を行います。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getLinkAsync(parameter: CompletionInspectionRecordGetDocumentRequest): Promise<CompletionInspectionRecordGetDocumentResult> {
        function createErrorResult(message?: string): CompletionInspectionRecordGetDocumentResult {
            return {
                url: "",
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetDocumentInput();
        input.init({
            no: parameter.no,
        });
        // memo kura: 実装 -> 関数不使用
        //const output = await this.client.getDocument(name);
        //if (output == null) {
        //    return createErrorResult(WebApiAdapter.messageFailedCommunication);
        //}
        //else if (output.isError === true) {
        //    return createErrorResult(output.errorMessage);
        //}

        // 正常
        const result: CompletionInspectionRecordGetDocumentResult = {
            isError: false,
            url: "", // output.url ?? "",
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new CompletionInspectionRecordClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
