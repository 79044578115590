//react
import * as React from 'react';
// material-ui
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // タブ
        tab: {
            display: "flex",
            flexGrow: 1,
        },
        // 要素コンテンツ
        content: {
            minHeight: `calc(100vh - calc(64px + 48px + ${theme.spacing(5)}px))`,
            overflowX: "auto",
            padding: theme.spacing(1),
        },
    }),
);

/**  タブ有り画面レイアウトの Props を提供します。*/
interface Props {
    /** タブを表します。 */
    tab: JSX.Element,
    /** コンテンツを表します。 */
    content: JSX.Element,
}

/**
 * タブ有り画面レイアウトを表示します。
 * */
export function TabPageContentLayout(props: Props) {

    /** Style を表します。 */
    const classes = useStyles();

    return (
        <Paper square elevation={0}>

            {/* タブ */}
            <Paper square className={classes.tab}>
                {props.tab}
            </Paper>

            {/* コンテンツ */}
            <Box className={classes.content}>
                {props.content}
            </Box>

        </Paper>

    );

};
