import * as React from 'react';
import { useState } from 'react';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import { AppTitle } from '../../../app-title';
import { AppUrl } from '../../../app-url';
import { Tooltip, IconButton, Menu as MuiMenu, MenuItem, Button, Box } from '@material-ui/core';
import { HomeOutlined, ListAltOutlined, SettingsOutlined, AccountCircleOutlined, CloudUpload } from '@material-ui/icons';
import { ThemeToggleButtonContainer } from '../../../../../components/ThemeToggleButton/ThemeToggleButtonContainer';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // アイコンリスト
        menuIcon: {
            [theme.breakpoints.down('sm')]: {
                display: "none",
            },
        },
        // メニューアイテム
        menuItem: {
            // ホバー時
            "&:hover": {
                backgroundColor: fade(theme.palette.primary.main, 0.3),
                color: theme.palette.text.primary,

            },
            // 選択項目
            "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: fade(theme.palette.primary.main, 0.8),
                color: theme.palette.primary.contrastText,
            },
        },
    }),
);

/** メニューの Props を表示します。 */
interface Props {
    /** 現在のパスを表します。*/
    locatedPath: string,
    /** ホームアイコン押下時のイベント処理を表します。 */
    onHomeClick: () => void,
    /** レポートアイコン押下時のイベント処理を表します。 */
    onReportClick: () => void,
    /** アップロードアイコン押下時のイベント処理を表します。 */
    onUploadClick: () => void,
    /**　マスタ系メニューアイテム押下時のイベント処理を表します。 */
    onMasterItemClick: (path: string) => void,
    /**　メニューアイテム押下時のイベント処理を表します。 */
    onSingoutClick: () => void,
    /** ログインユーザー名を表します。 */
    userName: string,
}

/** メニューの State を表示します。 */
interface State {
    /** マスタアイコンのアンカーを表します。 */
    anchorElMaster: null | HTMLElement;
    /** ユーザーアイコンのアンカーを表します。 */
    anchorElUser: null | HTMLElement;
}

/** メニューを表示します。 */
const IconMenu: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    // State
    const [values, setValues] = useState<State>(
        {
            anchorElMaster: null,
            anchorElUser: null,
        });

    /** クローズ処理を行います。 */
    const handleClose = () => {
        setValues({
            anchorElMaster: null,
            anchorElUser: null,
        });
    };

    /**
     * ホームアイコン押下時のイベント処理を表します。
     * @param event
     */
    const onHomeClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        // 遷移
        props.onHomeClick();
    }

    /**
     * レポートアイコン押下時のイベント処理を表します。
     * @param event
     */
    const onReportClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        // 遷移
        props.onReportClick();
    }

    /**
     * アップロードアイコン押下時のイベント処理を表します。
     * @param event
     */
    const onUploadClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        // 遷移
        props.onUploadClick();
    }


    /**
    * マスタアイコン クリック時のイベント処理を行います。
    * @param event
    */
    const onMasterClick = (event: React.MouseEvent<HTMLElement>) => {
        setValues({ ...values, anchorElMaster: event.currentTarget });
    };

    /**
    * ユーザーアイコン クリック時のイベント処理を行います。
    * @param event
    */
    const onUserClick = (event: React.MouseEvent<HTMLElement>) => {
        setValues({ ...values, anchorElUser: event.currentTarget });
    };

    /**
     * マスタ系各項目 クリック時のイベント処理を行います。
     * @param path
     */
    const onMasterItemClick = (event: React.MouseEvent<HTMLElement>, path: string) => {
        event.preventDefault();

        // メニュークローズ
        handleClose();

        // 遷移
        props.onMasterItemClick(path);
    }

    /**
     * サインアウト クリック時のイベント処理を行います。
     * @param event
     */
    const onSingoutClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // メニュークローズ
        handleClose();

        // 遷移
        props.onSingoutClick();
    }

    return (
        <>
            {/* メニューアイコンボタン */}
            <div className={classes.menuIcon}>

                {/* Home アイコン (施工機モニター) */}
                <Tooltip title={AppTitle.machineMonitoring.title}>
                    <IconButton
                        aria-label="home"
                        aria-controls="menu-home"
                        aria-haspopup="true"
                        onClick={onHomeClick}
                        color="inherit"
                    >
                        <HomeOutlined fontSize={"large"} />
                    </IconButton>
                </Tooltip>

                {/* Report アイコン (その他報告) */}
                <Tooltip title={AppTitle.otherReport.title}>
                    <IconButton
                        aria-label="report"
                        aria-controls="menu-report"
                        aria-haspopup="true"
                        onClick={onReportClick}
                        color="inherit"
                    >
                        <ListAltOutlined fontSize={"large"} />
                    </IconButton>
                </Tooltip>

                {/* Upload アイコン (ファイルアップロード) */}
                <Tooltip title={AppTitle.fileManage.title}>
                    <IconButton
                        aria-label="report"
                        aria-controls="menu-report"
                        aria-haspopup="true"
                        onClick={onUploadClick}
                        color="inherit"
                    >
                        <CloudUpload fontSize={"large"} />
                    </IconButton>
                </Tooltip>

                {/* Setting アイコン (マスタ系) */}
                <Tooltip title="マスタ管理">
                    <IconButton
                        aria-label="master navigation"
                        aria-controls="menu-master"
                        aria-haspopup="true"
                        onClick={onMasterClick}
                        color="inherit"
                    >
                        <SettingsOutlined fontSize={"large"} />
                    </IconButton>
                </Tooltip>

                {/* User アイコン (サインアウト) */}
                <Tooltip title="ユーザー">
                    <Button
                        aria-label="account of current user"
                        aria-controls="menu-user"
                        aria-haspopup="true"
                        onClick={onUserClick}
                        color="inherit"
                        variant="text"
                        style={{ minWidth: 0 }}
                    >
                        <AccountCircleOutlined fontSize={"large"} />
                        <Box fontSize="h6.fontSize">{props.userName}</Box>

                    </Button>
                </Tooltip>


                {/* Master系 リスト */}
                <MuiMenu
                    id="menu-master"
                    anchorEl={values.anchorElMaster}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    open={Boolean(values.anchorElMaster)}
                    onClose={handleClose}
                >
                    {/* 施工機マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterMachine.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterMachine.path)}
                    >
                        {AppTitle.masterMachine.title}
                    </MenuItem>
                    {/* 型式マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterModel.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterModel.path)}
                    >
                        {AppTitle.masterModel.title}
                    </MenuItem>
                    {/* メーカーマスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterManufacturer.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterManufacturer.path)}
                    >
                        {AppTitle.masterManufacturer.title}
                    </MenuItem>
                    {/* 工法マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterMethod.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterMethod.path)}
                    >
                        {AppTitle.masterMethod.title}
                    </MenuItem>
                    {/* 整備区分マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterMaintenanceDivisioin.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterMaintenanceDivisioin.path)}
                    >
                        {AppTitle.masterMaintenanceDivisioin.title}
                    </MenuItem>
                    {/* 整備項目マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterMaintenanceItem.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterMaintenanceItem.path)}
                    >
                        {AppTitle.masterMaintenanceItem.title}
                    </MenuItem>
                    {/* 特記事項マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterRemarks.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterRemarks.path)}
                    >
                        {AppTitle.masterRemarks.title}
                    </MenuItem>
                    {/* 点検区分マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterInspectionDivision.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterInspectionDivision.path)}
                    >
                        {AppTitle.masterInspectionDivision.title}
                    </MenuItem>
                    {/* 点検項目マスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterInspectionItem.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterInspectionItem.path)}
                    >
                        {AppTitle.masterInspectionItem.title}
                    </MenuItem>
                    {/* ユーザーマスタ */}
                    <MenuItem
                        className={classes.menuItem}
                        selected={AppUrl.masterUser.path === props.locatedPath}
                        onClick={(e) => onMasterItemClick(e, AppUrl.masterUser.path)}
                    >
                        {AppTitle.masterUser.title}
                    </MenuItem>
                </MuiMenu>

                {/* User系 リスト */}
                <MuiMenu
                    id="menu-user"
                    anchorEl={values.anchorElUser}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    open={Boolean(values.anchorElUser)}
                    onClose={handleClose}
                >
                    <MenuItem
                        className={classes.menuItem}
                        key="user"
                        onClick={onSingoutClick}
                    >
                        サインアウト
                    </MenuItem>

                    {/* テーマカラー */}
                    <Box className={classes.menuItem}>
                        <ThemeToggleButtonContainer />
                    </Box>
                </MuiMenu>

            </div>

        </>
    );
}
export default IconMenu;
