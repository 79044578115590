import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography, Button, Container } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(4),
        },
        alert: {
            "& .MuiAlert-icon": {
                fontSize: "3rem"
            }
        },
        title: {
            fontSize: "2rem",
        },
        button: {
            marginTop: theme.spacing(2),
        }
    }),
);


/** 　*/
interface Props {
    /** メッセージを表します。*/
    message: string,
    /**  戻るボタンクリック処理を表します。*/
    onReturnClick: () => void,
}

/**
 * 認証失敗時のエラー画面を表示します
 */
const AuthorizeError: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Alert severity="error" className={classes.alert}>

                <AlertTitle className={classes.title}>認証エラー</AlertTitle>

                <Typography variant="h5">{props.message}</Typography>

                <Typography>ユーザー情報の取得に失敗しました。</Typography>
                <Typography>下の [サインイン画面に戻る] ボタンをクリックし、再度ログインしてください。</Typography>

                <Button
                    color="default"
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={props.onReturnClick}
                >
                    サインイン画面に戻る
                </Button>
            </Alert >
        </Container>
    );
}

export default AuthorizeError;
