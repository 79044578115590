// redux
import { Dispatch } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
// shared
import { ExtendGenericState, createExtendGenericSlice } from '../shared/create-extend-generic-slice';
// type
import { OtherReportAdapter } from '../../adapter/other-report/other-report-adapter';
import { OtherReportGetDocumentRequest, OtherReportGetDocumentResult, OtherReportGetRequest, OtherReportGetResult } from '../../adapter/other-report/types';
import { OtherReportListItem } from '../../../pages/Report/OtherReport/_types';

/** その他報告 アイテム情報の State を表します。 */
interface OtherReportItemState {
    /** 選択された項目を表します。  */
    selectedRowItem?: OtherReportListItem,
    /** 詳細画面ダイアログが表示されているかどうかを表します。 */
    isOpenDialog: boolean,
}

/** その他報告 State を提供します。 */
interface OtherRportSliceState {
    /** 一覧取得結果情報を表します。 */
    getResult?: OtherReportGetResult,
    /** ドキュメント取得結果情報を表します。 */
    getDocumentResult?: OtherReportGetDocumentResult,
}

// State の初期値を設定します。
const initialState: ExtendGenericState<OtherRportSliceState, OtherReportItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        getResult: {
            items: [],
            isError: false,
        },
        getDocumentResult: {
            url: "",
            isError: false,
        }
    },
    item: {
        isOpenDialog: false,
    }
}

/** その他報告 Slice を 提供します。*/
export const OtherReportSlice = createExtendGenericSlice({
    // State Reducer Action を生成
    name: 'OtherReport',
    initialState: initialState,
    reducers: {
        /**
        * 通信終了時の処理を行います。
        * @param state
        * @param action
        */
        fetchEndExtension(state: ExtendGenericState<OtherRportSliceState, OtherReportItemState>, action: PayloadAction<OtherRportSliceState>) {
            state.isLoading = false;

            if (action.payload.getResult) {
                state.result.getResult = action.payload.getResult;
            }
            if (action.payload.getDocumentResult) {
                state.result.getDocumentResult = action.payload.getDocumentResult;
            }
        },
        /**
         * ダイアログの閉じる処理を行います。
         * @param state
         * @param action
        */
        closeDialog(state: ExtendGenericState<OtherRportSliceState, OtherReportItemState>) {
            state.item.isOpenDialog = false;
        },

        /**
         * レポートボタンクリック処理を行います。
         * @param state
         * @param action
         */
        onReportClick(state: ExtendGenericState<OtherRportSliceState, OtherReportItemState>, action: PayloadAction<OtherReportListItem>) {
            state.item.isOpenDialog = true;
            state.item.selectedRowItem = action.payload;
        }

    },
})

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetAsync = (parameter: OtherReportGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(OtherReportSlice.actions.fetchStart());

        // 通信処理
        const result = await OtherReportAdapter.instance.getAsync(parameter);

        // 通信終了
        dispatch(OtherReportSlice.actions.fetchEndExtension({ getResult: result }));

        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(OtherReportSlice.actions.showErrorMessage(result.errorMessage ?? ""));
        } else {
            // 正常
            // メッセージ
            dispatch(OtherReportSlice.actions.showSuccessMessage());
        }

    } catch (error) {

        //例外
        dispatch(OtherReportSlice.actions.fetchEndExtension({
            getResult: {
                items: [],
                isError: true,
                errorMessage: `${error}`,
            }
        }));

        // メッセージ
        dispatch(OtherReportSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(OtherReportSlice.actions.fetchEnd());
    }
}

/**
 * ドキュメント取得処理を行います。
 * @param parameter
 */
export const onGetLinkAsync = (parameter: OtherReportGetDocumentRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(OtherReportSlice.actions.fetchStart());

        // 通信処理
        const result = await OtherReportAdapter.instance.getLinkAsync(parameter);

        // 通信終了
        dispatch(OtherReportSlice.actions.fetchEndExtension({ getDocumentResult: result }));

        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(OtherReportSlice.actions.showErrorMessage(result.errorMessage ?? ""));
        } else {
            // 正常
            // メッセージ
            dispatch(OtherReportSlice.actions.showSuccessMessage());
        }

    } catch (error) {

        //例外
        dispatch(OtherReportSlice.actions.fetchEndExtension({
            getDocumentResult: {
                url: "",
                isError: true,
                errorMessage: `${error}`,
            }
        }));

        // メッセージ
        dispatch(OtherReportSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(OtherReportSlice.actions.fetchEnd());
    }
}
