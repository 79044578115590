// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { onGetAsync } from '../../../../../modules/slice/daily-inspection-record/daily-inspection-record-slice';
import { RootState } from '../../../../../rootReducer';
// component
import DailyInspectionSearch, { DailyInspectionRecordSearchState } from './DailyInspectionSearch';
import { DailyInspectionRecordSearchType, StampSearchType } from '../../_types';

/**
 * Stateの初期値を設定します
 * */
const initState = (): DailyInspectionRecordSearchState => {

    const beginAt = new Date();
    beginAt.setMonth(beginAt.getMonth() - 1);
    beginAt.setHours(0, 0, 0, 0);

    const endAt = new Date();
    endAt.setHours(0, 0, 0, 0);

    const result: DailyInspectionRecordSearchState = {
        keyword: "",
        searchType: DailyInspectionRecordSearchType.Report,
        searchStampType: StampSearchType.None,
        beginAt: beginAt,
        endAt: endAt,
    }

    return result;
}

/** 日常点検記録画面　検索エリアを表示します。 */
export const DailyInspectionSearchContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine);
    const isUpdated: boolean = useSelector((state: RootState) => state.dailyInspectionRecord?.isUpdated ?? false);
    const isOpenViewer = useSelector((state: RootState) => state.dailyInspectionRecord.isOpenViewer ?? true);

    const [value, setValue] = useState<DailyInspectionRecordSearchState>(initState);

    useEffect(() => {

        if (isUpdated && !isOpenViewer && machine) {
            onSearchClickAsync(value);
        }

    }, [machine, isUpdated, isOpenViewer, value, dispatch]);

    /** 検索ボタンのクリック処理を行います。 */
    const onSearchClickAsync = async (inputItem: DailyInspectionRecordSearchState) => {
        setValue(inputItem);

        if (machine) {

            // 実行
            await dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    machineType: machine.type,
                    keyword: inputItem.keyword,
                    searchType: inputItem.searchType,
                    searchStampType: inputItem.searchStampType,
                    beginAt: inputItem.beginAt,
                    endAt: inputItem.endAt,
                }
            }));
        }
    };

    return (
        <DailyInspectionSearch
            initInputItem={value}
            onSearchClick={onSearchClickAsync}
        />
    );
};
