import { MachineOperationHistoryClient, GetMachineOperationHistoryInput, GetDocumentInput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { MachineOperationHistoryGetRequest, MachineOperationHistoryGetResult, MachineOperationHistoryGetCsvRequest, MachineOperationHistoryGetCsvResult } from './types';
import { MachineOperationHistoryUtility } from './machine-operation-history-utility';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `整備記録` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class MachineOperationHistoryAdapter extends WebApiAdapter<MachineOperationHistoryClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new MachineOperationHistoryAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: MachineOperationHistoryGetRequest): Promise<MachineOperationHistoryGetResult> {
        function createErrorResult(message?: string): MachineOperationHistoryGetResult {
            return {
                items: [],
                isError: true,
                errorMessage: message,
            };
        }
        // 取得
        const input = new GetMachineOperationHistoryInput();
        input.init({
            machineId: parameter.item.machineId,
            beginAt: parameter.item.beginAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.beginAt),
            endAt: parameter.item.endAt == null ? void 0 : SharedUtility.toFormatDateString(parameter.item.endAt),
        });
        const output = await this.client.get(input);
        if (output == null) {
            return createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineOperationHistoryGetResult = {
            isError: false,
            items: MachineOperationHistoryUtility.toGraphItem(output.items),
        };
        return result;
    }

    /**
     * CSV の取得を行います。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getCsvAsync(parameter: MachineOperationHistoryGetCsvRequest): Promise<MachineOperationHistoryGetCsvResult> {
        function createErrorResult(message?: string): MachineOperationHistoryGetCsvResult {
            return {
                url: "",
                isError: true,
                errorMessage: message,
            };
        }
        // memo kura: 実装 -> 関数不使用
        //// 取得
        //const name = new GetDocumentInput();
        //name.init({
        //    beginAt: parameter.item.beginAt ?? void 0,
        //    endAt: parameter.item.endAt ?? void 0,
        //});
        //const output = await this.client.getCsv(name);
        //if (output == null) {
        //    return createErrorResult(WebApiAdapter.messageFailedCommunication);
        //}
        //else if (output.isError === true) {
        //    return createErrorResult(output.errorMessage);
        //}

        // 正常
        const result: MachineOperationHistoryGetCsvResult = {
            isError: false,
            url: "",//output.url ?? "",
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new MachineOperationHistoryClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
