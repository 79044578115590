import * as React from 'react';
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // CTU接続あり
        connectedCtu: {
            borderStyle: "solid",
            borderRadius: "4px 4px 0 4px",
            borderColor: theme.palette.text.disabled,
            borderWidth: 2,
            padding: 4,
        },
        // CTU　タグ
        ctuTag: {
            fontSize: "0.75rem",
            padding: theme.spacing(0.25, 0.5, 0.25, 0.25),
            color: "#fff",
            backgroundColor: theme.palette.text.disabled,
            borderRadius: "0 4px 4px 0",
        }
    })
);

/** Props を提供します。 */
interface Props {
    /** 社番を表します。 */
    machineId: string,
}

/**
 * CTUありタグ付き社番を表示します。
 * @param props
 */
const CtuTagMachineId: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box
            display="flex"
            alignItems="flex-end"
        >
            <Box className={classes.connectedCtu}>
                <Box>
                    {props.machineId}
                </Box>
            </Box>
            <span className={classes.ctuTag}>CTU</span>
        </Box>
    );
}

export default CtuTagMachineId;
