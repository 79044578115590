// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MaintenanceItemMasterSlice, onExcuteAsync } from '../../../../../modules/slice/maintenance-item/maintenance-item-slice';
import { MaintenanceItemAdapter } from '../../../../../modules/adapter/maintenance-item/maintenance-item-adapter'
// type
import { MaintenanceItemInputItem, MaintenanceValidateItemInputItem } from '../../_types';
import { KeyValueItem } from '../../../../../_types';
// component
import MaintenanceItemDetailCondition from './MaintenanceItemDetailCondition';
import { MuiTextFieldUtility } from '../../../../../components/MuiTextField/mui-text-field-utility';

/**
 * 整備項目マスタ　詳細エリアを提供します。
 * */
export const MaintenanceItemDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.maintenanceItemMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.maintenanceItemMaster.selectedRowItem);
    const machineTypeList = useSelector((state: RootState) => state.maintenanceItemMaster.selectBoxItem.MachineType);
    const maintenanceDivisionList = useSelector((state: RootState) => state.maintenanceItemMaster.selectBoxItem.MaintenanceDivision);

    const initialValues: MaintenanceItemInputItem = {
        id: selectedItem?.id,
        name: selectedItem?.name,
        machineTypeId: selectedItem?.machineTypeId,
        maintenanceDivisionId: selectedItem?.maintenanceDivisionId,
        weight: selectedItem?.weight,
        unit: selectedItem?.unit,
        inputLowerLimit: selectedItem?.inputLowerLimit ? Number.parseInt(selectedItem.inputLowerLimit, 10) : void 0,
        inputHigherLimit: selectedItem?.inputHigherLimit ? Number.parseInt(selectedItem.inputHigherLimit, 10) : void 0,
        lightAlarmLowerLimit: selectedItem?.lightAlarmLowerLimit ? Number.parseInt(selectedItem.lightAlarmLowerLimit, 10) : void 0,
        lightAlarmHigherLimit: selectedItem?.lightAlarmHigherLimit ? Number.parseInt(selectedItem.lightAlarmHigherLimit, 10) : void 0,
        heavyAlarmLowerLimit: selectedItem?.heavyAlarmLowerLimit ? Number.parseInt(selectedItem.heavyAlarmLowerLimit, 10) : void 0,
        heavyAlarmHigherLimit: selectedItem?.heavyAlarmHigherLimit ? Number.parseInt(selectedItem.heavyAlarmHigherLimit, 10) : void 0,
        remarks: selectedItem?.remarks,
        lockVersion: selectedItem?.lockVersion,
    }

    /** 整備区分セレクトボックス値を表します。 */
    const [selectData, setSelectData] = useState<KeyValueItem<number, string>[]>([]);

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<MaintenanceValidateItemInputItem>({});

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<MaintenanceItemInputItem>(initialValues);

    // 行選択時の入力項目更新
    useEffect(() => {
        setValues(preValue => ({
            id: selectedItem?.id,
            name: selectedItem?.name,
            machineTypeId: selectedItem?.machineTypeId,
            maintenanceDivisionId: selectedItem?.maintenanceDivisionId,
            weight: selectedItem?.weight,
            unit: selectedItem?.unit,
            inputLowerLimit: selectedItem?.inputLowerLimit ? Number.parseInt(selectedItem.inputLowerLimit, 10) : void 0,
            inputHigherLimit: selectedItem?.inputHigherLimit ? Number.parseInt(selectedItem.inputHigherLimit, 10) : void 0,
            lightAlarmLowerLimit: selectedItem?.lightAlarmLowerLimit ? Number.parseInt(selectedItem.lightAlarmLowerLimit, 10) : void 0,
            lightAlarmHigherLimit: selectedItem?.lightAlarmHigherLimit ? Number.parseInt(selectedItem.lightAlarmHigherLimit, 10) : void 0,
            heavyAlarmLowerLimit: selectedItem?.heavyAlarmLowerLimit ? Number.parseInt(selectedItem.heavyAlarmLowerLimit, 10) : void 0,
            heavyAlarmHigherLimit: selectedItem?.heavyAlarmHigherLimit ? Number.parseInt(selectedItem.heavyAlarmHigherLimit, 10) : void 0,
            remarks: selectedItem?.remarks,
            lockVersion: selectedItem?.lockVersion,
        }))

        // バリデーション情報クリア
        clearValidate();

    }, [selectedItem]);

    //行選択時の入力項目更新
    useEffect(() => {
        const option = maintenanceDivisionList.find(item => item.key === selectedItem?.machineTypeId);

        setSelectData((preValue) => (option?.value ?? []))

    }, [selectedItem, maintenanceDivisionList]);

    /**
    * 連動リストボックス値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChangeSelectBox = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MaintenanceItemInputItem) => {

        // 値を選別
        const target = event.target;
        const value = target.value;

        const index = Number.parseInt(value, 10);
        const option = maintenanceDivisionList.find(item => item.key === index);

        // 入力項目更新
        setValues({
            ...values,
            [name]: value,
            maintenanceDivisionId: void 0,
        });

        // 連動したセレクトボックス更新
        setSelectData(option?.value ?? []);
    }

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof MaintenanceItemInputItem) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? MuiTextFieldUtility.LimitationNumber(Number.parseInt(target.value, 10)) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };


    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = MaintenanceItemAdapter.instance.addAsync(
            { item: values },
            {
                MachineType: machineTypeList,
                MaintenanceDivision: maintenanceDivisionList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = MaintenanceItemAdapter.instance.updateAsync(
            { item: values },
            {
                MachineType: machineTypeList,
                MaintenanceDivision: maintenanceDivisionList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        // ダイアログ表示
        dispatch(MaintenanceItemMasterSlice.actions.toggleDialog(true));
    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(MaintenanceItemMasterSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }


    /**
    * 入力内容のバリデーションを行います。
    * @returns 検証結果を返します。
    * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let machineType = "";
        let maintenanceDivision = "";
        let weight = "";
        let maintenanceItem = "";

        // 施工機種別
        if (!values.machineTypeId) {
            machineType = "施工機種別を選択してください";
            result = true;
        }

        // 整備区分
        if (!values.maintenanceDivisionId) {
            maintenanceDivision = "整備区分を選択してください";
            result = true;
        }

        // 重み
        if (values.weight == null) {
            weight = "No.を入力してください";
            result = true;
        }

        // 整備項目
        if (!values.name) {
            maintenanceItem = "整備項目名を入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            machineType,
            maintenanceDivision,
            weight,
            maintenanceItem,
        });

        // 結果
        return result;
    }

    // 表示
    return (
        <MaintenanceItemDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            machineTypeList={machineTypeList}
            maintenanceDivisionList={selectData}
            onChange={onChange}
            onChangeSelectBox={onChangeSelectBox}
            validateError={validate}
        />
    );
};
