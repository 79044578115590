import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import cyan from '@material-ui/core/colors/cyan';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // チップ
        chip: {
            marginLeft: theme.spacing(1),
            color: theme.palette.secondary.contrastText,
            backgroundColor: cyan[700],
        },
    })
);


/**
 * タブレットアクセス状態のアイコンを表示します。
 */
const ConnectedTabletTag: React.FC<{}> = () => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Chip
            size="small"
            className={classes.chip}
            label={<SettingsEthernetIcon />}
        />
    );
}

export default ConnectedTabletTag;
