import { KeyValueItem } from '../../../_types';
import { KeyValuePairOfIntegerAndString, KeyValuePairOfIntegerAndIEnumerableOfKeyValuePairOfIntegerAndString } from '../../swagger-clients';

/**
 * `共通` 関連の ユーティリティ機能を提供します。
 */
export class SharedUtility {

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toKeyValueItems(items?: KeyValuePairOfIntegerAndString[]): KeyValueItem<number, string>[] {
        const result: KeyValueItem<number, string>[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            const item: KeyValueItem<number, string> = {
                key: source.key,
                value: source.value ?? "",
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toKeyValueItemsList(items?: KeyValuePairOfIntegerAndIEnumerableOfKeyValuePairOfIntegerAndString[]): KeyValueItem<number, KeyValueItem<number, string>[]>[] {
        const result: KeyValueItem<number, KeyValueItem<number, string>[]>[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            var item: KeyValueItem<number, KeyValueItem<number, string>[]> = {
                key: source.key,
                value: SharedUtility.toKeyValueItems(source.value),
            }
            result.push(item);
        }
        return result;
    }

    //#endregion メソッド
}
