// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { SigninSlice } from '../../../modules/slice/signin/signin-slice';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
// component
import { SigninFormContainer } from './components/SigninForm/SigninFormContainer';
import { AppUrl } from '../../Shared/app-url';

/** sサインイン画面 を表示します。*/
export const SigninPage: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.signin.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.signin.isShowMessage);
    const message = useSelector((state: RootState) => state.signin.message);
    // redux store
    const isSignedIn: boolean = useSelector((state: RootState) => state.signin.result.isSignedIn);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(SigninSlice.actions.hideMessage());
    };

    return (

        // 既にサインイン認証済であれば施工機管理状況一覧に遷移
        isSignedIn
            ? (<Redirect to={AppUrl.machineMonitoring.path} />)
            : (
                <>
                    {/* ローディング表示 */}
                    <MuiBackDropProgress
                        isLoading={isLoading}
                    />

                    {/* Snackbar 表示 */}
                    <MuiSnackbar
                        isOpen={isShowMessage}
                        message={message}
                        onClose={onSnackbarClose}
                    />

                    {/* サインインフォーム画面 */}
                    <SigninFormContainer />

                </>
            )
    )
}
