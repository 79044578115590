// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { RemarksMasterSlice, onExcuteAsync } from '../../../../../modules/slice/remarks/remarks-slice';
import { RemarksAdapter } from '../../../../../modules/adapter/remarks/remarks-adapter'
// type
import { RemarksInputItem } from '../../_types';
// component
import RemarksSearchCondition from './RemarksSearchCondition';

/**
 * 特記事項マスタ　検索エリアを提供します。
 * */
export const RemarksSearchConditionContainer: React.FC<{}> = (props) => {
    const dispatch = useDispatch();

    // redux store
    const machineTypeList = useSelector((state: RootState) => state.remarksMaster.selectBoxItem.MachineType);

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: RemarksInputItem) => {

        // 非同期通信
        const promise = RemarksAdapter.instance.getAsync(
            {
                // セレクトボックスの 0:未選択 を void 0 に変換
                item: {
                    ...inputItem,
                    machineTypeId: inputItem.machineTypeId === 0 ? void 0 : inputItem.machineTypeId,
                }
            },
            { MachineType: machineTypeList }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(RemarksMasterSlice.actions.chageCreating(true))
    }

    // 表示
    return (
        <RemarksSearchCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
            machineTypeList={machineTypeList}
        />
    );
};
