// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { InspectionItemMasterSlice, onExcuteAsync, getIncspectionPreviewReportAsync } from '../../../../../modules/slice/inspection-item/inspection-item-slice';
import { InspectionItemAdapter } from '../../../../../modules/adapter/inspection-item/inspection-item-adapter'
// type
import { InspectionItemInputItem } from '../../_types';
import { KeyValueItem, InspectionType } from '../../../../../_types';
// component
import InspectionItemSearchCondition from './InspectionItemSearchCondition';

/**
 * 点検項目マスタ　検索エリアを提供します。
 * */
export const InspectionItemSearchConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const inspectionGroupList = useSelector((state: RootState) => state.inspectionItemMaster.selectBoxItem.InspectionGroup);
    const inspectionDivisionList = useSelector((state: RootState) => state.inspectionItemMaster.selectBoxItem.InspectionDivision);
    const reportUrl: string = useSelector((state: RootState) => state.inspectionItemMaster.reportUrl ?? "");

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: InspectionItemInputItem) => {

        // 非同期通信
        const promise = InspectionItemAdapter.instance.getAsync(
            {
                // セレクトボックスの 0:未選択 を void 0 に変換
                item: {
                    ...inputItem,
                    inspectionGroupId: inputItem.inspectionGroupId === 0 ? void 0 : inputItem.inspectionGroupId,
                    inspectionDivisionId: inputItem.inspectionDivisionId === 0 ? void 0 : inputItem.inspectionDivisionId,
                }
            },
            {
                InspectionGroup: inspectionGroupList,
                InspectionDivision: inspectionDivisionList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(InspectionItemMasterSlice.actions.chageCreating(true))
    }

    /** 帳票ボタンのクリック処理を行います。 */
    const onClickPreview = (type: InspectionType) => {
        dispatch(getIncspectionPreviewReportAsync(type));
    }

    /** レポート読み込み完了時の処理を行います。 */
    const loadedReport = () => {
        dispatch(InspectionItemMasterSlice.actions.changeReportLoadingFlag(false));
    }

    // 表示
    return (
        <InspectionItemSearchCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
            inspectionGroupList={inspectionGroupList}
            inspectionDivisionList={inspectionDivisionList}
            onClickPreview={onClickPreview}
            reportUrl={reportUrl}
            loadedReport={loadedReport}
        />
    );
};
