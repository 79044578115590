// type
import { AreaType } from '../../../_types';
// cookies
import * as Cookies from "js-cookie";


/**
 * エリア種別 関連の ユーティリティ機能を提供します。
 * */
export class AreaTypeUtility {

    /** ストレージのエリア種別の Key を表します。*/
    static readonly KeyAreaType = "AreaType";

    /**
     * エリア種別に変換します。
     * @param value 文字列指定します。
     * @returns エリア種別を返します。
     */
    public static toAreaType(value: string): AreaType {

        const parsedValue = Number.isNaN(Number.parseInt(value, 10)) ? 0 : Number.parseInt(value, 10);

        const result = parsedValue in AreaType ? (parsedValue as AreaType) : AreaType.None;

        return result;
    }

    /**
     * ストレージ にあるエリア種別を取得します。
     * @returns エリア種別を返します。
     * */
    public static getStorageAreaType(): AreaType {

        const data = Cookies.get(AreaTypeUtility.KeyAreaType);

        const result = data == null ? AreaType.None : AreaTypeUtility.toAreaType(data);

        return result;
    }

    /**
     *  ストレージにエリア種別を設定します。
     * @param value エリア種別を指定します。
     */
    public static setStorageAreaType(value: AreaType): void {
        // Cookie セット 有効期限 7 日
        Cookies.set(AreaTypeUtility.KeyAreaType, value.toString(), { expires: 7 });
    }

    /**
     * Cookieの有効期限を延長します。
     * */
    public static extendExpiresAreaType(): void {

        const areaType = AreaTypeUtility.getStorageAreaType();

        if (areaType) AreaTypeUtility.setStorageAreaType(areaType);

    }

}
