//react
import * as React from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Box,
    Grid,
} from '@material-ui/core';
// type
import { MachineMaintenanceSettingItem, EditItem } from '../../_types';
// component
import UpdateItem from '../../../Shared/UpdateItem';
import EditCard from '../EditCard/EditCard';
import EditCardRemains from '../EditCardRemains/EditCardRemains';
import { MachineDataType } from '../../../MachineStatus/_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボックス
        box: {
            padding: theme.spacing(1),
        },
        // グリッドコンテナ
        gridContainer: {
            alignItems: "center",
            padding: theme.spacing(0, 1, 0, 1)
        },
    }),
);


/**
 * ステータス編集画面の Props を提供します。
 */
interface Props {
    /** データを表します。 */
    statusData?: MachineMaintenanceSettingItem,
    /** データを表します。 */
    updateData?: MachineMaintenanceSettingItem,
    /** 最終更新日時を表します。*/
    lastUpdatedAt: string,
    /** 更新ボタンクリック時のイベント処理を表します。*/
    onUpdateClick: () => void,
    /** 編集ボタンクリック時のイベント処理を表します。*/
    onEditClick: (item: EditItem) => void,
    /** 履歴ボタンクリック時のイベントを表します。 */
    onHisrotyClick: (item: EditItem) => void,
}

/**
 * ステータス編集画面
 */
const StatusEditMain: React.FC<Props> = (props) => {

    const classes = useStyles();

    return (
        <>
            {/* メイン */}
            <Box className={classes.box}>

                <Grid container spacing={1} className={classes.gridContainer}>

                    {/* 更新エリア */}
                    <Grid item xs>
                        <UpdateItem
                            lastUpdatedAt={props.lastUpdatedAt ?? ""}
                            onClick={props.onUpdateClick}
                        />
                    </Grid>

                    {/* カード */}
                    <Grid container spacing={1}>

                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "本体稼働時間",
                                    unit: "Hr",
                                    type: MachineDataType.TotalTime,
                                    value: props.statusData?.totalTime ?? "",
                                    updateValue: props.updateData?.totalTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "エンジン稼働時間",
                                    unit: "Hr",
                                    type: MachineDataType.EngineTotalTime,
                                    value: props.statusData?.engineTotalTime ?? "",
                                    updateValue: props.updateData?.engineTotalTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "クレーン昇降距離",
                                    unit: "m",
                                    type: MachineDataType.CraneTotalDistance,
                                    value: props.statusData?.craneTotalDistance ?? "",
                                    updateValue: props.updateData?.craneTotalDistance ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCardRemains
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "特定自主検査まで",
                                    unit: "日",
                                    type: MachineDataType.SelfInspection,
                                    value: props.statusData?.selfInspection ?? "",
                                    updateValue: props.updateData?.selfInspection ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "メインポンプ稼働時間",
                                    unit: "Hr",
                                    type: MachineDataType.MainPumpTotalTime,
                                    value: props.statusData?.mainPumpTotalTime ?? "",
                                    updateValue: props.updateData?.mainPumpTotalTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "ギアポンプ稼働時間",
                                    unit: "Hr",
                                    type: MachineDataType.GearPumpTotalTime,
                                    value: props.statusData?.gearPumpTotalTime ?? "",
                                    updateValue: props.updateData?.gearPumpTotalTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "オーガー1稼働時間",
                                    unit: "Hr",
                                    type: MachineDataType.AugerTotalTime1,
                                    value: props.statusData?.augerTotalTime1 ?? "",
                                    updateValue: props.updateData?.augerTotalTime1 ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "オーガー2稼働時間",
                                    unit: "Hr",
                                    type: MachineDataType.AugerTotalTime2,
                                    value: props.statusData?.augerTotalTime2 ?? "",
                                    updateValue: props.updateData?.augerTotalTime2 ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "作動油交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.WorkingOilTime,
                                    value: props.statusData?.workingOilTime ?? "",
                                    updateValue: props.updateData?.workingOilTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "エンジンオイル交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.EngineOilTime,
                                    value: props.statusData?.engineOilTime ?? "",
                                    updateValue: props.updateData?.engineOilTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "オイルエレメント交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.OilElementTime,
                                    value: props.statusData?.oilElementTime ?? "",
                                    updateValue: props.updateData?.oilElementTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "エアーエレメント交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.AirElementTime,
                                    value: props.statusData?.airElementTime ?? "",
                                    updateValue: props.updateData?.airElementTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "燃料エレメント交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.FuelElementTime,
                                    value: props.statusData?.fuelElementTime ?? "",
                                    updateValue: props.updateData?.fuelElementTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "作業油エレメント交換時間",
                                    unit: "Hr",
                                    type: MachineDataType.WorkingOilElementTime,
                                    value: props.statusData?.workingOilElementTime ?? "",
                                    updateValue: props.updateData?.workingOilElementTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "バッテリー交換後日数",
                                    unit: "日",
                                    type: MachineDataType.BatteryTime,
                                    value: props.statusData?.batteryTime ?? "",
                                    updateValue: props.updateData?.batteryTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "クーラント交換後日数",
                                    unit: "日",
                                    type: MachineDataType.CoolantTime,
                                    value: props.statusData?.coolantTime ?? "",
                                    updateValue: props.updateData?.coolantTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "ファンベルト交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.FanBeltTime,
                                    value: props.statusData?.fanBeltTime ?? "",
                                    updateValue: props.updateData?.fanBeltTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "オルタネーター交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.AlternatorTime,
                                    value: props.statusData?.alternatorTime ?? "",
                                    updateValue: props.updateData?.alternatorTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "セルモーター交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.StarterMotorTime,
                                    value: props.statusData?.starterMotorTime ?? "",
                                    updateValue: props.updateData?.starterMotorTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "油圧ポンプカップリング交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.OilPressurePumpCouplingTime,
                                    value: props.statusData?.oilPressurePumpCouplingTime ?? "",
                                    updateValue: props.updateData?.oilPressurePumpCouplingTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "油圧ポンプ減速機オイル交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.OilPressurePumpDeceleratorOilTime,
                                    value: props.statusData?.oilPressurePumpDeceleratorOilTime ?? "",
                                    updateValue: props.updateData?.oilPressurePumpDeceleratorOilTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "ウインチ減速機オイル交換後時間",
                                    unit: "Hr",
                                    type: MachineDataType.WinchDeceleratorOilTime,
                                    value: props.statusData?.winchDeceleratorOilTime ?? "",
                                    updateValue: props.updateData?.winchDeceleratorOilTime ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "主巻ワイヤ交換後日数",
                                    unit: "日",
                                    type: MachineDataType.PrimaryHoistingWire,
                                    value: props.statusData?.primaryHoistingWire ?? "",
                                    updateValue: props.updateData?.primaryHoistingWire ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "補巻ワイヤ交換後日数",
                                    unit: "日",
                                    type: MachineDataType.SubHoistingWire,
                                    value: props.statusData?.subHoistingWire ?? "",
                                    updateValue: props.updateData?.subHoistingWire ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "起伏ワイヤ交換後日数",
                                    unit: "日",
                                    type: MachineDataType.UpsAndDownsWire,
                                    value: props.statusData?.upsAndDownsWire ?? "",
                                    updateValue: props.updateData?.upsAndDownsWire ?? "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EditCard
                                onEditClick={props.onEditClick}
                                onHistoryClick={props.onHisrotyClick}
                                item={{
                                    name: "ペンダントロープ交換後日数",
                                    unit: "日",
                                    type: MachineDataType.PendantRope,
                                    value: props.statusData?.pendantRope ?? "",
                                    updateValue: props.updateData?.pendantRope ?? "",
                                }}
                            />
                        </Grid>


                    </Grid>
                </Grid>

            </Box>
        </>

    );
}

export default StatusEditMain;
