// react
import * as React from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// component
import { MachineStatusMonitoringBoolState } from '../_types/';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "flex",
            alignItems: "center",
        },
        // アイコン
        icon: {
            fontSize: "3rem",
            marginRight: "0.5rem",

        },
        // アイコン正常状態
        iconGreen: {
            color: theme.palette.success.main,
        },
        // アイコンエラー状態

        iconRed: {
            color: theme.palette.error.main,
        },
        // アイコン未接続状態

        iconGray: {
            color: theme.palette.text.disabled,
        },
        // ブリンク
        blinkState: {
            animation: '$blinkError 1s ease-in-out infinite alternate',
            '& p': {
                fontWeight: "bold",
                fontSize: "1.5rem",
            },
            '& svg': {
                animation: '$blinkIcon 1s ease-in-out infinite alternate',
            },
        },

        '@keyframes blinkError': {
            '0%': {
                color: theme.palette.error.contrastText,
                backgroundColor: theme.palette.error.main,
            },
            '100%': {
                color: theme.palette.primary,
                backgroundColor: theme.palette.background.paper,
            },
        },

        '@keyframes blinkIcon': {
            '0%': {
                color: theme.palette.error.contrastText,
            },
            '100%': {
                color: theme.palette.error.main,
            },
        },

    }),
);

/** アラートパラメーターカードの Props を提供します。 */
interface Props {
    /** タイトルを表します。 */
    title: string,
    /** アラートステータスの状態を表します。*/
    status?: MachineStatusMonitoringBoolState,
};

/** アラートパラメーターカードを提供します。 */
const AlertParameterCard: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();


    function renderAlertStatus(status: MachineStatusMonitoringBoolState, title: string): JSX.Element {
        let result: JSX.Element;

        switch (status) {
            case MachineStatusMonitoringBoolState.NotAvailable:
                // 未接続
                result = (
                    <Card>
                        <CardContent className={classes.root}>
                            <Box>
                                <RemoveCircleIcon className={clsx(classes.icon, classes.iconGray)} />
                            </Box>
                            <Box>
                                <Typography>
                                    {title}
                                </Typography>
                            </Box>

                        </CardContent>
                    </Card>
                );
                break;
            case MachineStatusMonitoringBoolState.On:
                // On(Error)状態
                result = (
                    <Card>
                        <CardContent className={clsx(classes.root, classes.blinkState)}>
                            <Box>
                                <WarningIcon className={classes.icon} />
                            </Box>
                            <Box>
                                <Typography>
                                    {title}
                                </Typography>
                            </Box>

                        </CardContent>
                    </Card>
                );
                break;
            case MachineStatusMonitoringBoolState.Off:
                // Off(正常)状態
                result = (
                    <Card>
                        <CardContent className={classes.root}>
                            <Box>
                                <CheckCircleOutlineIcon className={clsx(classes.icon, classes.iconGreen)} />
                            </Box>
                            <Box>
                                <Typography>
                                    {props.title}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>

                );
                break;
        }

        return result;
    }

    return (
        <>
            {
                // CTU接続状態を確認
                props.status == null
                    ? (
                        // CTU未接続状態の場合
                        <Card>
                            <CardContent className={classes.root}>
                                <Box>
                                    {/* ステータスアイコン */}
                                    <RemoveCircleIcon className={clsx(classes.icon, classes.iconGray)} />
                                </Box>
                                <Box>
                                    <Typography>
                                        {props.title}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    )
                    : (
                        // CTU接続中の場合
                        <>
                            {renderAlertStatus(props.status, props.title)}
                        </>
                    )
            }
        </>
    );

};

export default AlertParameterCard;
