import { WebApiClient } from './swagger-clients';

/**
* アプリケーションの扱いやすい形式で `Web API` を公開するサーバーと `HTTP` 通信する基本機能を提供します。
*/
export abstract class WebApiAdapter<TClient extends WebApiClient> {

    //#region フィールド

    /** `Web API` を公開するサーバーと `HTTP` 通信するオブジェクトを表します。 */
    protected readonly client: TClient;

    /** サーバー通信失敗のメッセージを表します。 */
    protected static readonly messageFailedCommunication = "サーバーとの通信に失敗しました";

    //#endregion フィールド

    //#region メソッド

    /**
        * コンストラクター
        *
        * @constructor
        * @param client `Web API` を公開するサーバーと `HTTP` 通信するオブジェクトを指定します。
        */
    protected constructor(client: TClient) {
        this.client = client;
    }

    //#endregion メソッド
}
