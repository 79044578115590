import { Dispatch } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { ExtendGenericState, createExtendGenericSlice } from '../shared/create-extend-generic-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { changeError } from '../global-header/global-header-slice';
import { MachineStatusMonitoringAdapter } from '../../adapter/machine-status-monitoring/machine-status-monitoring-adapter';
import { MachineStatusMonitoringRequest, MachineStatusMonitoringResult } from '../../adapter/machine-status-monitoring/types';

/**施工機ステータス アイテム情報の State を表します。 */
interface MachineStatusMonitoringItemState {
    /** タイマーIDを表します。 */
    timerId: number;
}

/** リロードのインターバル時間[ms]を表します。 */
const ReloadTimerInterval = 60000;


// State の初期値を設定します。
const initialState: ExtendGenericState<MachineStatusMonitoringResult, MachineStatusMonitoringItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        isError: false,
    },
    item: {
        timerId: 0,
    }
}


/** 施工機ステータス Slice を 提供します。*/
export const MachineStatusMonitoringSlice = createExtendGenericSlice({
    // State Reducer Action を生成
    name: 'MachineMonitoringStatus',
    initialState: initialState,
    reducers: {

        /**
         * 更新タイマーを停止します。
         * @param state
         */
        stopReloadTimer(state: ExtendGenericState<MachineStatusMonitoringResult, MachineStatusMonitoringItemState>) {
            clearTimeout(state.item.timerId);
            state.item.timerId = 0;
        },
        /**
         * 更新タイマーを開始します。
         * @param state
         */
        startReloadTimer(state: ExtendGenericState<MachineStatusMonitoringResult, MachineStatusMonitoringItemState>, action: PayloadAction<number>) {
            clearTimeout(state.item.timerId);
            state.item.timerId = action.payload;
        }

    },
})

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetAsync = (parameter: MachineStatusMonitoringRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineStatusMonitoringSlice.actions.fetchStart());


        // 通信処理
        const getResultWaiter = await MachineStatusMonitoringAdapter.instance.getAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.id });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, machineErrorResultWaiter]);
        const getResult = promiseResult[0];
        const machineErrorResult = promiseResult[1];

        // 通信終了
        dispatch(MachineStatusMonitoringSlice.actions.fetchSuccess(getResult));

        if (getResult.isError) {
            // 異常
            // メッセージ
            dispatch(MachineStatusMonitoringSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));
        } else if (machineErrorResult.isError) {
            // 異常
            // メッセージ
            dispatch(MachineStatusMonitoringSlice.actions.showErrorMessage(machineErrorResult.errorMessage ?? ""));
        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 正常
            // メッセージ
            dispatch(MachineStatusMonitoringSlice.actions.showSuccessMessage());
        }

    } catch (error) {

        //例外
        dispatch(MachineStatusMonitoringSlice.actions.fetchFailure({
            item: void 0,
            isError: true,
        }));

        // メッセージ
        dispatch(MachineStatusMonitoringSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MachineStatusMonitoringSlice.actions.fetchEnd());
    }
}

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetReloadAsync = (parameter: MachineStatusMonitoringRequest) => async (dispatch: Dispatch) => {

    try {
        // タイマー停止
        dispatch<any>(stopReloadTimer());

        // 実行
        await dispatch<any>(onGetAsync(parameter));

    } catch (e) {
        //何もしない

    } finally {
        // タイマー開始
        dispatch<any>(startReloadTimer(parameter));
    }

}

/** リロードタイマーを停止します。 */
const stopReloadTimer = () => (dispatch: Dispatch) => {

    // タイマー停止
    dispatch(MachineStatusMonitoringSlice.actions.stopReloadTimer());
}


/** リロードタイマーを開始します。  */
const startReloadTimer = (parameter: MachineStatusMonitoringRequest) => (dispatch: Dispatch) => {

    // タイマー開始
    const timer = window.setTimeout(() => dispatch<any>(onGetReloadAsync(parameter)), ReloadTimerInterval)
    dispatch(MachineStatusMonitoringSlice.actions.startReloadTimer(timer));
}
