import { UserListItem, UserInputItem } from '../../../pages/Master/User/_types';
import { UserRequest } from './types';
import { UserInput, UserItem } from '../../swagger-clients';

/**
 * `ユーザーマスタ` 関連の ユーティリティ機能を提供します。
 */
export class UserUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(items?: UserItem[]): UserListItem[] {
        const result: UserListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.newId == null || source.lockVersion == null) {
                continue;
            }
            const item: UserListItem = {
                id: source.newId,
                user: source.name ?? "",
                inspectorName: source.inspectorName ?? "",
                password: source.password ?? "",
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: UserRequest): UserInput {
        const result = new UserInput();
        result.init({
            item: UserUtility.toInputItem(source.item, source.oldId),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: UserInputItem, oldId?: string): UserItem {
        const result = new UserItem();
        result.init({
            newId: source.id,
            oldId: oldId,
            name: source.user,
            inspectorName: source.inspectorName,
            password: source.password,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
