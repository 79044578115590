import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { PageType } from '../../../../../modules/slice/global-header/global-header-slice';
import MenuBar from './MenuBar'
/**
 * メニューバーを表します。
 * */
export const MenuBarContainer: React.FC<{}> = (props) => {

    const isError = useSelector((state: RootState) => state.globalHeader.isError);
    const isExistMachineError = useSelector((state: RootState) => state.globalHeader.ctuErrorMachine.isExistMachineError);
    const pageType: PageType = useSelector((state: RootState) => state.globalHeader.pageType);

    /**
     * エラーポップアップボタンを表示する画面かどうかを判定します。
     * @param type
     */
    const isShowCtuError = (type: PageType) => {
        let result = false;
        switch (type) {
            case PageType.Home:
            case PageType.Master:
            case PageType.Report:
                result = true;
                break;
            case PageType.Record:
                result = false;
                break;
            default:
                result = false;
        }
        return result;
    }

    return (
        <MenuBar
            isShowCtuError={isShowCtuError(pageType)}
            isCtuError={isError}
            isExistMachineError={isExistMachineError}
            children={props.children}
        />

    );
}
