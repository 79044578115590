// react
import * as React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';

import ConfirmDialog from '../../../Shared/components/ConfirmDialog/ConfirmDialog';
import { UserAdapter } from '../../../../../modules/adapter/user/user-adapter';
import { UserMasterSlice, onExcuteAsync, onConfirmRemoveAsync } from '../../../../../modules/slice/user/user-slice';
import { UserRequest } from '../../../../../modules/adapter/user/types';


/**
 * 削除確認ダイアログを表示します。
 * @param props
 */
export const ConfirmDialogContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isOpen = useSelector((state: RootState) => state.userMaster.isDialogOpen ?? false);
    const selectedItem = useSelector((state: RootState) => state.userMaster.selectedRowItem ?? null);
    const authorized = useSelector((state: RootState) => state.authentication.user);


    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, pwassword: string) => {

        // 入力項目を作成
        const parameter: UserRequest = {
            item: {
                id: selectedItem?.id,
                password: selectedItem?.password,
                lockVersion: selectedItem?.lockVersion,
            }
        }

        let isAuthirozed = false
        if (selectedItem?.id && authorized && selectedItem.id === authorized.id) isAuthirozed = true;

        // 非同期通信
        await dispatch(onConfirmRemoveAsync(parameter, pwassword, isAuthirozed));

    }

    /**
　　 * ダイアログを非表示にします。
　　 * @param isOpne
　　 */
    const closeDialog = () => {
        dispatch(UserMasterSlice.actions.toggleDialog(false));
    };

    return (
        <>
            {selectedItem &&
                <ConfirmDialog
                    isOpen={isOpen}
                    onRemoveClick={onClickRemove}
                    handleClose={closeDialog}
                />
            }
        </>
    );
}
