// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme, fade } from '@material-ui/core/styles';
import MaterialTable, { Column } from "material-table";
// 共通component
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
import { MasterTableOptions } from '../../../Shared/MasterTableOptions';
import MasterTableFrame from '../../../Shared/MasterTableFrame';

// type
import { MachineListItem } from "../../_types"

/** 施工機マスタテーブルの Props を提供します。*/
interface Props {
    /** データソースを表します。 */
    dataSource: MachineListItem[],
    /** 行選択のクリックイベントを表します。 */
    onRowClick: (rowData: MachineListItem) => void,
    /** 選択中の行データを表します。 */
    selectedRow?: MachineListItem,

}

/** 施工機マスタテーブルの State を提供します。*/
interface State {
    /** 選択中の行データを表します。 */
    selectedRow?: MachineListItem,
}

/** 施工機マスタテーブルのカラムを表します。 */
const columns: Column<MachineListItem>[] = [
    { title: "施工機種別", field: "type", type: "string" },
    { title: "社番", field: "id", type: "string" },
    { title: "型式", field: "model", type: "string" },
    { title: "メーカー", field: "manufacturer", type: "string" },
    { title: "年式", field: "modelYear", type: "date" },
    { title: "CTUあり/なし", field: "connectedCtuText", type: "string" },
    { title: "自動CTU番号", field: "ctuId", type: "string" },
    { title: "保全CTU番号", field: "maintenanceCtuId", type: "string" },
];

/** 施工機マスタテーブルを提供します */
const MachineResultTable: React.FC<Props> = (props) => {

    /** Theme を提供します。*/
    const theme = useTheme();

    /** State を提供します。 */
    const [values, setValues] = useState<State>({
        selectedRow: undefined,
    });


    useEffect(() => {
        setValues({ selectedRow: props.selectedRow });
    }, [props.selectedRow])

    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const handleRowClick = (rowData: MachineListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを保持
        setValues({ ...values, selectedRow: rowData });

        // Prop 実行
        props.onRowClick(rowData);

    };

    return (
        <MasterTableFrame>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={{
                    ...MasterTableOptions,
                    rowStyle: (rowData, index) => ({
                        whiteSpace: "nowrap",
                        backgroundColor: (values.selectedRow?.id === rowData.id && values.selectedRow?.type === rowData.type) ? fade(theme.palette.secondary.main, 0.5) : "", // 選択された行の背景色
                    }),
                }}
                columns={columns}
                data={props.dataSource}
                onRowClick={(event, selectedRow) => {
                    selectedRow && handleRowClick(selectedRow, event)
                }}
            />

        </MasterTableFrame>

    );

};
export default MachineResultTable;
