// react
import * as React from 'react';
import { useState } from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Box, Paper, Typography, TextField, Button, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff, AccountCircle, Lock } from '@material-ui/icons';
// Logo
import LogoFudo from '../../../../../assets/LogoFudo.svg';
import LogoST from '../../../../../assets/LogoST.svg';

/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            height: "100vh",
            display: "flex",
        },
        // コンテナ
        container: {
            margin: "auto",
        },

        // ロゴ
        logo: {
            width: "200px",
            height: "200px",
            margin: theme.spacing(1),
        },
        // カード
        paper: {
            margin: "auto",
            padding: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        // フォーム
        form: {
            width: "100%",
            marginTop: theme.spacing(1),
        },
        // 入力項目
        inputItem: {
            margin: theme.spacing(3, 0, 2),
        },
        // ボタン
        submit: {
            margin: theme.spacing(3, 0, 2),
        }

    }),
);


/** サインイン画面の State を提供します。*/
export interface SinginFormInputItem {
    /** id を表します。 */
    id: string;
    /** パスワードを表します。 */
    password: string;
}

/** サインイン画面の State を提供します。*/
interface SinginFormValidateInputItem {
    /** id を表します。 */
    id: string;
    /** パスワードを表します。 */
    password: string;
}

/** サインイン Props */
interface Props {
    /** 削除ボタンクリック時の処理を表します。 */
    onSigninClick: (inputItem: SinginFormInputItem) => void,
};

/**
 * サインイン画面を提供します。
 * */
const SigninForm: React.FC<Props> = props => {
    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** 入力内容 */
    const [inputItem, setInputItem] = useState<SinginFormInputItem>({ id: "", password: "" });

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<SinginFormValidateInputItem>({ id: "", password: "" });

    /** パスワードの表示状態を表します。 */
    const [isShowPassword, setIsShowPassword] = useState(false);

    /**
     * 入力項目　内容変更時のイベント処理を行います。
     * */
    const onChange = (name: keyof SinginFormInputItem) => (event: React.ChangeEvent<HTMLInputElement>) => {

        const value = event.target.value;

        // 入力項目更新
        setInputItem({ ...inputItem, [name]: value });
    };

    /** パスワード表示非表示ボタン　クリック時のイベント処理を行います。*/
    const handleClickShowPassword = () => {
        setIsShowPassword(preValue => !preValue);
    };

    /**
     * パスワード表示非表示ボタン　マウスダウン時のイベント処理を行います。
     * @param event
     */
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    /**
    *サインインボタンのクリック処理を行います。
    * @param event
    */
    const onSignin = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // バリデーション
        const isError = onValidateInputItem()

        if (isError === false) {
            props.onSigninClick(inputItem);
        }
    }

    /** 入力内容のバリデーションを行います。 */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let idError = "";
        let passError = "";

        // id
        if (!inputItem.id) {
            idError = "ユーザー id を入力してください";
            result = true;
        }

        // パスワード
        if (!inputItem.password) {
            passError = "パスワードを入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            id: idError,
            password: passError,
        });

        // 結果
        return result;

    }

    return (
        <Box className={classes.root}>

            <Container component="main" maxWidth="sm" className={classes.container}>
                <Paper className={classes.paper}>

                    {/*ロゴ*/}
                    <Box display="flex">
                        <img alt="LogoFudo" src={LogoFudo} className={classes.logo} />
                        <img alt="LogoST" src={LogoST} className={classes.logo} />
                    </Box>

                    {/* タイトル */}
                    <Typography component="h1" variant="h4">施工機保全システム</Typography>

                    {/* フォーム */}
                    <form className={classes.form} onSubmit={event => onSignin(event)} noValidate>

                        {/* ID */}
                        <TextField
                            fullWidth
                            type="text"
                            autoComplete="off"
                            className={classes.inputItem}
                            margin={"normal"}
                            label="ユーザー id"
                            value={inputItem.id}
                            onChange={onChange("id")}

                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            required
                            error={!validate.id === false}
                            helperText={validate.id}
                        />

                        {/* パスワード */}
                        <TextField
                            fullWidth
                            type={isShowPassword ? 'text' : 'password'}
                            autoComplete="off"
                            className={classes.inputItem}
                            margin={"normal"}
                            label="パスワード"
                            value={inputItem.password}
                            onChange={onChange("password")}

                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {isShowPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            required
                            error={!validate.password === false}
                            helperText={validate.password}
                        />

                        {/* サインイン */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            サインイン
                            </Button>

                    </form>
                </Paper>

            </Container>


        </Box >
    );
}

export default SigninForm;
