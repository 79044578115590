// react
import * as React from 'react';
// material-ui
import { Paper } from '@material-ui/core';

/** 単一ページ(上部タブなし) のレイアウト を表示します。 */
const SinglePageLayout: React.FC<{}> = (props) => {

    return (
        <Paper square elevation={0}>
            {props.children}
        </Paper>


    );
}

export default SinglePageLayout;
