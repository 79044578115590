// react
import * as React from 'react';
// material-ui
import { Box, Card } from '@material-ui/core';

interface Props {
    // カードリストコンテンツを表します。
    cards: React.ReactNode,
    // グラフコンテンツを表します。
    graph: React.ReactNode,
    // ダウンロードボタンを表します。
    csvButton: React.ReactNode,
}

/**
 * 施工記録 画面を表示します。
 * */
export const ConstructionRecordMain: React.FC<Props> = (props) => {

    return (
        <Box>
            {/* カードリスト(稼働時間・昇降距離) */}
            {props.cards}

            {/* グラフ */}
            <Box mt={2}>
                <Card>
                    {/* ボタン */}
                    {props.csvButton}

                    {/* グラフ描画 */}
                    {props.graph}

                </Card>
            </Box>
        </Box>


    )

}
