// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { StatusEditSlice, onUpdateClickAsync, onCloseDialog } from '../../../../../modules/slice/status-edit/status-edit-slice';

import { MachineMaintenanceSettingInputItem } from '../../_types'
import EditDialog, { EditDialogState } from './EditDialog';
import { ConnectedCtuType } from '../../../../../_types';
import { MachineDataType } from '../../../MachineStatus/_types';
import { SharedUtility } from '../../../../../modules/shared/shared-utility';

/**
 * アワメータの入力が必要かどうかを判定します。
 * @param ctuType　CTU接続種別を指定します。
 * @param itemType　データ項目種別を指定します。
 */
function requiredHrMeter(ctuType: ConnectedCtuType | undefined | null, itemType: MachineDataType | undefined | null): boolean {
    // CTUなし かつ 特定の項目のみ アワメータの入力が必要
    return ctuType === ConnectedCtuType.Disconnected
        && (itemType === MachineDataType.TotalTime                              // 本体稼働時間
            || itemType === MachineDataType.EngineTotalTime                     // エンジン稼働時間
            || itemType === MachineDataType.MainPumpTotalTime                   //　メインポンプ稼働時間
            || itemType === MachineDataType.AugerTotalTime1                     //　オーガー1稼働時間
            || itemType === MachineDataType.AugerTotalTime2                     //　オーガー2稼働時間
            || itemType === MachineDataType.GearPumpTotalTime                   // ギアポンプ稼働時間
            || itemType === MachineDataType.WorkingOilTime                      // 作動油交換後時間
            || itemType === MachineDataType.OilElementTime                      // オイルエレメント交換後時間
            || itemType === MachineDataType.FuelElementTime                     // 燃料エレメント交換後時間
            || itemType === MachineDataType.EngineOilTime                       // エンジンオイル交換後時間
            || itemType === MachineDataType.AirElementTime                      // エアーエレメント交換後時間
            || itemType === MachineDataType.WorkingOilElementTime               // 作動油エレメント交換後時間
            || itemType === MachineDataType.FanBeltTime                         // ファンベルト交換後時間
            || itemType === MachineDataType.StarterMotorTime                    // セルモーター交換後時間
            || itemType === MachineDataType.OilPressurePumpDeceleratorOilTime   // 油圧ポンプ減速機オイル交換後時間
            || itemType === MachineDataType.AlternatorTime                      // オルタネーター交換後時間
            || itemType === MachineDataType.OilPressurePumpCouplingTime         // 油圧ポンプカップリング交換後時間
            || itemType === MachineDataType.WinchDeceleratorOilTime             // ウインチ減速機オイル交換後時間
        );
}

/**
 * カレンダー入力形式化どうかを判定します。
 * @param itemType
 */
function isInputCalendar(itemType: MachineDataType | undefined | null): boolean {
    return (itemType === MachineDataType.SelfInspection         // 67: 特定自主検査
        || itemType === MachineDataType.BatteryTime             // 55: バッテリー交換後日数 
        || itemType === MachineDataType.CoolantTime             // 56: クーラント交換後日数
        || itemType === MachineDataType.PrimaryHoistingWire     // 63: 主巻ワイヤ交換後日数
        || itemType === MachineDataType.SubHoistingWire         // 64: 補巻ワイヤ交換後日数
        || itemType === MachineDataType.UpsAndDownsWire         // 65: 起伏ワイヤ交換後日数
        || itemType === MachineDataType.PendantRope             // 66: ペンダントロープ交換後日数
    );
}

/**
 * ステータス編集ダイアログを表示します。
 * @param props
 */
export const EditDialogContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isOpen = useSelector((state: RootState) => state.statusEdit.item.isOpenEdit);
    const editItem = useSelector((state: RootState) => state.statusEdit.item.editItem ?? null);
    const machine = useSelector((state: RootState) => state.globalHeader.machine ?? null);

    /** 更新ボタンのクリック処理を行います。*/
    const onUpdateClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, input: EditDialogState) => {

        // バリデーション
        const error = validateMachineInfo();
        if (error !== "") {
            // エラーメッセージ
            dispatch(StatusEditSlice.actions.showErrorMessage(error));
            return;
        }

        if (machine?.item != null && editItem?.type != null && input.password != null) { // 明示的にnullでないことを書く

            let value = "";
            if (isInputCalendar(editItem.type)) {
                value = input.date ? SharedUtility.toDateTimeString(input.date) : "";
            } else {
                value = input.value?.toString() ?? "";
            }
            // 入力情報の整形
            const inputItem: MachineMaintenanceSettingInputItem = {
                machineDataType: editItem.type,
                value: value,
                hrMeter: input.hrMeter ?? void 0,
            }

            // 非同期通信
            await dispatch(onUpdateClickAsync(
                {
                    id: machine.item.id,
                    reset: inputItem
                },
                input.password
            ));

        }
    }

    /**
     * 施工機情報のバリデーションを行います。
     * */
    const validateMachineInfo = (): string => {
        let result = "";

        // バリデーション
        if (machine?.item == null) {
            result = "施工機情報がありません。"
        } else if (editItem?.type == null) {
            result = "施工機ステータス情報がありません。"
        }
        return result;
    }

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        if (machine?.item?.id == null) {
            dispatch(StatusEditSlice.actions.showErrorMessage("施工機情報がありません。"));
            return;
        }

        dispatch(onCloseDialog({ id: machine.item.id }));
    };

    return (
        <>
            {editItem &&
                <EditDialog
                    editItem={editItem}
                    isOpen={isOpen}
                    isCalendar={isInputCalendar(editItem.type)}
                    isRequiredHrMeter={requiredHrMeter(machine?.item.ctu, editItem?.type)}
                    onUpdateClick={onUpdateClick}
                    handleClose={closeDialog} />
            }
        </>
    );
}
