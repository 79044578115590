// react
import * as React from 'react';
import { useEffect } from 'react';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMasterPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { MaintenanceItemMasterSlice, onExcuteMountedAsync } from '../../../modules/slice/maintenance-item/maintenance-item-slice';
// component
import { MaintenanceItemResultTableContainer } from './components/TableContent/MaintenanceItemResultTableContainer';
import { MaintenanceItemSearchConditionContainer } from './components/SearchContent/MaintenanceItemSearchConditionContainer';
import { MaintenanceItemDetailConditionContainer } from './components/DetailContent/MaintenanceItemDetailConditionContainer';
import { ConfirmDialogContainer } from './components/ConfirmDialog/ConfirmDialogContainer';
import MasterLayout from '../Shared/MasterLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';

/**
 * 整備項目マスタ画面を提供します。
 * */
const MaintenanceItemPage: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeMasterPageHeader(AppTitle.masterMaintenanceItem.title));

        // 初回マウント時にデータを取得する
        dispatch(onExcuteMountedAsync());
    }, [dispatch]);

    // redux store
    const isLoading = useSelector((state: RootState) => state.maintenanceItemMaster.isLoading);
    const isReportLoading = useSelector((state: RootState) => state.maintenanceItemMaster.isReportLoading ?? false);
    const isShowMessage = useSelector((state: RootState) => state.maintenanceItemMaster.isShowMessage);
    const message = useSelector((state: RootState) => state.maintenanceItemMaster.message);
    const isCreating = useSelector((state: RootState) => state.maintenanceItemMaster.isCreating);
    const isSelected = useSelector((state: RootState) => state.maintenanceItemMaster.isSelected);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(MaintenanceItemMasterSlice.actions.hideMessage());
    };

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading || isReportLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ダイアログ */}
            <ConfirmDialogContainer />

            <MasterLayout
                search={<MaintenanceItemSearchConditionContainer />}
                table={<MaintenanceItemResultTableContainer />}
                detail={<MaintenanceItemDetailConditionContainer />}
                isCreating={isCreating}
                isSelected={isSelected}
            />
        </>
    );
};

export default MaintenanceItemPage;
