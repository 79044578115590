import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import { commonTheme } from './commonTheme';

/** デフォルトのテーマを提供します。 */
export const defaultTheme = createMuiTheme({
    palette: {
        type: "light",
        secondary: {
            main: "#00796b",
        },
        background: {
            default: "#e0e0e0",
        },
    },
    // オーバーライド
    overrides: {
        //　テーブル　行
        MuiTableRow: {
            root: {
                // ホバーカラー
                ".MuiTableBody-root &:hover": {
                    backgroundColor: "rgba(63, 81, 181, 0.2)"
                },
                // 行の縞模様
                "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)"
                },
            }
        },
        // 入力フォームラベル
        MuiFormLabel: {
            root: {
                "&.Mui-focused": {
                    color: "#000"
                },
            },
            colorSecondary: {
                "&.Mui-focused": {
                    color: "#000"
                }
            },
        },
        /** カレンダー **/
        // ツールバー
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: teal["500"],
            },
        },
        // ヘッダー
        MuiPickersCalendarHeader: {
            switchHeader: {
            },
        },
        // 年選択
        MuiPickersYear: {
            root: {
                "&:focus": {
                    color: "#000",
                },
            },
            yearSelected: {
                color: "#fff",
                backgroundColor: teal["400"],

            },
            yearDisabled: {
                color: teal["100"],

            },
        },
        // 月選択
        MuiPickersMonth: {
            root: {
                "&:focus": {
                    color: "#000",
                },
            },
            monthSelected: {
                color: "#fff",
                backgroundColor: teal["400"],

            },
            monthDisabled: {
                color: teal["100"],

            },
        },
        // 日付選択
        MuiPickersDay: {
            day: {
                color: "#000",
            },
            daySelected: {
                color: "#fff",
                backgroundColor: teal["400"],
                "&:hover": {
                    backgroundColor: teal["600"],
                }
            },
            dayDisabled: {
                color: teal["100"],
            },
            current: {
                color: teal["400"],
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
        },
    }
}, commonTheme);
