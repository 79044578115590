import { MachineStatusMonitoringClient, GetMachineStatusMonitoringInput, GetMachineStatusMonitoringOutput, AddOrUpdateClientRequestInput, GetClientRequestInput, GetResetHistroyInput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { MachineStatusMonitoringRequest, MachineStatusMonitoringResult, MachineMaintenanceSettingResult, MachineMaintenanceSettingRequest, MachineMaintenanceSettingResetHistoryResult, MachineMaintenanceSettingRestHistoryRequest } from './types';
import { MachineStatusMonitoringUtility } from './machine-status-monitoring-utility';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `施工機ステータス` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class MachineStatusMonitoringAdapter extends WebApiAdapter<MachineStatusMonitoringClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new MachineStatusMonitoringAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createErrorResult(message?: string): MachineStatusMonitoringResult {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createEditItemErrorResult(message?: string): MachineMaintenanceSettingResult {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createMachineMaintenanceSettingErrorResult(message?: string): MachineMaintenanceSettingResult {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createMachineMaintenanceSettingResetHistoryErrorResult(message?: string): MachineMaintenanceSettingResetHistoryResult {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(parameter: MachineStatusMonitoringRequest): Promise<MachineStatusMonitoringResult> {

        // 実行
        const input = new GetMachineStatusMonitoringInput();
        input.init(MachineStatusMonitoringUtility.toInput(parameter));
        const output = await this.client.get(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineStatusMonitoringResult = {
            isError: false,
            item: MachineStatusMonitoringUtility.toItem(output.items),
            machineStatus: MachineStatusMonitoringUtility.toMachineStatus(output.status),
            ipAddress: output.ipAddress ?? "",
            lastUpdatedAt: output.lastUpdatedAt == null ? MachineStatusMonitoringUtility.lastUpdatedAtNull : SharedUtility.toDateTimeString(new Date(output.lastUpdatedAt)),
        };
        return result;
    }

    /**
    * 一覧を取得します。
    *
    * @param parameter パラメーターを指定します。
    * @returns 結果を戻す非同期操作を返します。
    */
    public async getMachineMaintenanceSettingItemAsync(parameter: MachineStatusMonitoringRequest): Promise<MachineMaintenanceSettingResult> {

        // 実行
        const input = new GetMachineStatusMonitoringInput();
        input.init(MachineStatusMonitoringUtility.toInput(parameter));
        const output = await this.client.get(input);
        if (output == null) {
            return this.createEditItemErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createEditItemErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineMaintenanceSettingResult = {
            isError: false,
            item: MachineStatusMonitoringUtility.toMaintenanceSettingItemFromStatusItem(output.items),
            lastUpdatedAt: output.lastUpdatedAt == null ? MachineStatusMonitoringUtility.lastUpdatedAtNull : SharedUtility.toDateTimeString(new Date(output.lastUpdatedAt)),
        };
        return result;
    }



    /**
     * 要求情報の追加または更新を行います。
     * @param parameter
     */
    public async addOrUpdateClientRequestAsync(parameter: MachineMaintenanceSettingRequest): Promise<MachineMaintenanceSettingResult> {

        // 実行
        const input = new AddOrUpdateClientRequestInput();
        input.init(MachineStatusMonitoringUtility.toClientRequestInput(parameter));
        const output = await this.client.addOrUpdateClientRequest(input);
        if (output == null) {
            return this.createMachineMaintenanceSettingErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createMachineMaintenanceSettingErrorResult(output.errorMessage);
        }
        // 正常
        const result: MachineMaintenanceSettingResult = {
            isError: false,
        };
        return result;
    }

    /**
     * 要求情報の取得を行います。
     * @param parameter
     */
    public async getClientRequestAsync(parameter: MachineMaintenanceSettingRequest): Promise<MachineMaintenanceSettingResult> {

        const input = new GetClientRequestInput();
        input.init(MachineStatusMonitoringUtility.toGetClientRequestInput(parameter))
        const output = await this.client.getClientRequest(input);
        if (output == null) {
            return this.createMachineMaintenanceSettingErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createMachineMaintenanceSettingErrorResult(output.errorMessage);
        }
        // 正常
        const result: MachineMaintenanceSettingResult = {
            isError: false,
            item: MachineStatusMonitoringUtility.toMaintenanceSettingItemFromResetRequestItem(output.resetItems),
        };
        return result;
    }

    /**
     * リセット履歴情報の取得を行います。
     * @param parameter
     */
    public async getResetHistoryAsync(parameter: MachineMaintenanceSettingRestHistoryRequest): Promise<MachineMaintenanceSettingResetHistoryResult> {

        const input = new GetResetHistroyInput();
        input.init(MachineStatusMonitoringUtility.toGetResetHistroyInput(parameter))
        const output = await this.client.getClientResetHistory(input);
        if (output == null) {
            return this.createMachineMaintenanceSettingErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createMachineMaintenanceSettingResetHistoryErrorResult(output.errorMessage);
        }
        // 正常
        const result: MachineMaintenanceSettingResetHistoryResult = {
            isError: false,
            items: MachineStatusMonitoringUtility.toMachineMaintenanceSettingResetHistoryItem(output.items),
        };
        return result;
    }


    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new MachineStatusMonitoringClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
