import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ActiveReportViewer } from '../../../../../components/ActiveReportViewer/ActiveReportViewer';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewer: {
            height: `calc(100vh - calc(64px + 48px + ${theme.spacing(8)}px))`, //ヘッダー, タブ, マージン分
        },
    }),
);

/** 帳票ビュアーの Prsop を提供します。 */
interface Props {
    /** レポートの url を表します。 */
    reportUrl: string,
    /** レポート読み込み終了時の処理を行います。 */
    loadedReport?: (() => void),
}

/**
 * 帳票ビュアー(Active Report)画面を表します。
 */
const ReportViewer: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <div className={classes.viewer}>
            {!!props.reportUrl && (
                <ActiveReportViewer
                    reportUrl={props.reportUrl ?? ""}
                    loadedReport={props.loadedReport}
                />
            )}
        </div>
    );
};

export default ReportViewer;
