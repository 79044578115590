import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dispatch } from 'redux';
import { UserItem } from '../../../_types';
import { SharedAdapter } from '../../adapter/shared/shared-adapter'
import { SigninUtility } from '../signin/signin-slice-utility';
import { AppUrl } from '../../../pages/Shared/app-url';
import { AccountAdapter } from '../../adapter/account/account-adapter';


/** 認証 Store の State を提供します。 */
interface State {
    /** ユーザーを表します。 */
    user?: UserItem,
    /** 認証通信が完了したかどうかを表します。 */
    isLoaded: boolean,
    /** エラーかどうかを表します。 */
    isError: boolean,
    /** メッセージを表します。 */
    message?: string
}

/** State の初期値を設定します。 */
const initialState: State = {
    isLoaded: false,
    isError: false,
}

/** 認証 Slice を 提供します。*/
export const AuthenticationSlice = createSlice({
    name: 'Authentication',     // createSlice を識別するための名前
    initialState,               // State の初期値を入れる
    reducers: {                 // State の変更処理

        /**
         * 認証済みユーザー情報を変更します。
         * @param state
         * @param action
         */
        setUser(state: State, action: PayloadAction<UserItem>) {
            state.user = action.payload;
        },

        /**
         * 認証済みユーザー情報を変更します。
         * @param state
         * @param action
         */
        clearUser(state: State) {
            state.user = void 0;
        },

        /**
         * 認証開始状態を表します。
         * @param state
         */
        startAuthentication(state: State) {
            state.user = void 0;
            state.isError = false;
            state.message = void 0;
        },

        /**
         * 認証失敗状態を表します。
         * @param state
         * @param action
         */
        errorAuthentication(state: State, action: PayloadAction<string>) {
            state.user = void 0;
            state.isError = true;
            state.message = `${action.payload ?? "認証済みユーザー情報の取得に失敗しました"}`
        },

        /**
         * 認証終了状態を表します。
         * @param state
         */
        endAuthentication(state: State) {
            state.isLoaded = true;
        },

        /** 【サインアウト】Redux Store をクリアします。 */
        userSignOut() {
            // ここでの処理は無し
            // rootReducer.ts にて、Authentication/userSignOut アクションで Store をクリアする。
        }

    }
})
/**
 * 認証済みユーザー情報を取得します。
 *
 */
export const onGetAuthenticatedUserAsync = () => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(AuthenticationSlice.actions.startAuthentication());

        // 通信処理
        const result = await SharedAdapter.instance.getAuthenticatedUserAsync();

        if (!result || !result.user || result.isError) {

            // 異常
            dispatch(AuthenticationSlice.actions.errorAuthentication(result.errorMessage ?? ""));

        } else {
            // 正常
            // ストレージ更新
            SigninUtility.setStorageIsSignedIn();

            // store 更新
            dispatch(AuthenticationSlice.actions.setUser(result.user))

        }

    } catch (error) {
        // 例外
        dispatch(AuthenticationSlice.actions.errorAuthentication(`${error}`));

    } finally {
        // 処理中 OFF
        dispatch(AuthenticationSlice.actions.endAuthentication());
    }

}

/**
 * サインアウト処理を行います。
 * */
export const onSignOutAsync = () => async (dispatch: Dispatch) => {

    // ストレージ更新
    SigninUtility.removeStorageIsSignedIn();

    // サインアウト処理
    await AccountAdapter.instance.signOutAsync();

    // store リセット
    //await dispatch(AuthenticationSlice.actions.userSignOut());

    window.location.href = "/signin";
}
