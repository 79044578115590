// redux
import { Dispatch } from 'redux';
// shared
import { MasterGenericState, createMasterGenericSlice } from '../shared/create-master-slice';
// type
import { MaintenanceDivisionAdapter } from '../../adapter/maintenance-division/maintenance-division-adapter';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { MaintenanceDivisionRequest, MaintenanceDivisionResult, MaintenanceDivisionSelectBoxItemState } from '../../adapter/maintenance-division/types';
import { MaintenanceDivisionListItem } from '../../../pages/Master/MaintenanceDivision/_types';
import { ReportAdapter } from '../../adapter/report/report-adapter';
import { MachineType, KeyValueItem } from '../../../_types';

// State の初期値を設定します。
const initialState: MasterGenericState<MaintenanceDivisionResult, MaintenanceDivisionListItem, MaintenanceDivisionSelectBoxItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    isCreating: false,
    isSelected: false,
    selectedRowItem: void 0,
    selectBoxItem: {
        MachineType: [],
    },
    isDialogOpen: false,

}

/** 取得 Slice を 作成します。*/
export const MaintenanceDivisionSlice = createMasterGenericSlice({
    // State Reducer Action を生成
    name: 'MaintenanceDivision',
    initialState: initialState,
    reducers: {},
})

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onExcuteAsync = (promise: Promise<MaintenanceDivisionResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MaintenanceDivisionSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(MaintenanceDivisionSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(result.errorMessage));
        } else {
            // 正常
            dispatch(MaintenanceDivisionSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(MaintenanceDivisionSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(MaintenanceDivisionSlice.actions.chageCreating(false));
        }

    } catch (error) {

        //例外
        dispatch(MaintenanceDivisionSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MaintenanceDivisionSlice.actions.fetchEnd());
    }
}

/**
 * 画面表示時の初回非同期通信処理を行います。
 * @param action
 */
export const onExcuteMountedAsync = () => async (dispatch: Dispatch) => {

    try {
        // state リセット
        dispatch(MaintenanceDivisionSlice.actions.resetState());


        // 処理中 ON
        dispatch(MaintenanceDivisionSlice.actions.fetchStart());

        // 施工機種別
        const machineTypeResult = await SharedAdapter.instance.getMachineTypeAsync();

        // 異常確認
        if (machineTypeResult.isError) {
            // 施工機種別 異常メッセージ
            dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(`施工機種別取得エラー：${machineTypeResult.errorMessage}`));

        } else {
            // 正常
            // リストの項目を store に保存
            dispatch(MaintenanceDivisionSlice.actions.getSelectBoxItem({
                MachineType: machineTypeResult.items,
            }));

            // データ取得
            const result = await MaintenanceDivisionAdapter.instance.getAsync(
                { item: {} },
                { MachineType: machineTypeResult.items }
            );

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(MaintenanceDivisionSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(MaintenanceDivisionSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(MaintenanceDivisionSlice.actions.showSuccessMessage());
            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MaintenanceDivisionSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 * @param password
 */
export const onConfirmRemoveAsync = (
    parameter: MaintenanceDivisionRequest,
    selectBoxItem: MaintenanceDivisionSelectBoxItemState,
    password: string,
) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MaintenanceDivisionSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));

        } else {
            // 正常
            // 削除実行
            const result = await MaintenanceDivisionAdapter.instance.removeAsync(parameter, selectBoxItem);

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(MaintenanceDivisionSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(MaintenanceDivisionSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(MaintenanceDivisionSlice.actions.showSuccessMessage("削除しました。"));

                // ダイアログを閉じる
                dispatch(MaintenanceDivisionSlice.actions.toggleDialog(false));

                // 選択項目のクリア
                dispatch(MaintenanceDivisionSlice.actions.clearSelectedItem());
            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MaintenanceDivisionSlice.actions.fetchEnd());
    }

}

/**
 * 帳票プレビュー情報を取得します。
 * @param type 施工機種別を指定します。
 */
export const getMaintenancePreviewReportAsync = (type: MachineType) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(MaintenanceDivisionSlice.actions.fetchStart());
        dispatch(MaintenanceDivisionSlice.actions.changeReportLoadingFlag(true));
        dispatch(MaintenanceDivisionSlice.actions.updatePreviewReport(""));

        // 通信処理
        const result = await ReportAdapter.instance.getMaintenancePreviewReport({ machineType: type });

        // 異常確認
        if (result.isError || !result.reportUrl) {
            // 異常メッセージ
            dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(`帳票プレビュー情報 取得エラー：${result.errorMessage}`));
            dispatch(MaintenanceDivisionSlice.actions.changeReportLoadingFlag(false));

        } else {
            // 正常
            // メッセージ
            dispatch(MaintenanceDivisionSlice.actions.showSuccessMessage());

            dispatch(MaintenanceDivisionSlice.actions.updatePreviewReport(result.reportUrl));
        }

    } catch (error) {
        // メッセージ
        dispatch(MaintenanceDivisionSlice.actions.showErrorMessage(`${error}`));
        dispatch(MaintenanceDivisionSlice.actions.changeReportLoadingFlag(false));

    } finally {
        // 処理中 OFF
        dispatch(MaintenanceDivisionSlice.actions.fetchEnd());
    }
}
