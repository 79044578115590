// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { ManufacturerMasterSlice, onExcuteAsync } from '../../../../../modules/slice/manufacturer/manufacturer-slice';
import { ManufacturerAdapter } from '../../../../../modules/adapter/manufacturer/manufacturer-adapter';
// type
import { ManufacturerInputItem, ManufacturerValidateInputItem } from '../../_types';
// component
import ModelDetailCondition from './ManufacturerDetailCondition';

/**
 * メーカーマスタ　詳細エリアを提供します。
 * */
export const ManufacturerDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.manufacturerMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.manufacturerMaster.selectedRowItem);

    const initialValues: ManufacturerInputItem = {
        id: selectedItem?.id,
        manufacturer: selectedItem?.manufacturer,
        lockVersion: selectedItem?.lockVersion,
    }

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<ManufacturerValidateInputItem>({});

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<ManufacturerInputItem>(initialValues);

    // 行選択時の入力項目更新
    useEffect(() => {
        setValues(preValue => ({
            ...preValue,
            id: selectedItem?.id,
            manufacturer: selectedItem?.manufacturer,
            lockVersion: selectedItem?.lockVersion,
        }))

        // バリデーション情報クリア
        clearValidate();

    }, [selectedItem]);

    /**
     * 入力値の変更処理を行います。
     * @param name　入力項目を指定します。
     */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof ManufacturerInputItem) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? Number.parseInt(target.value, 10) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /** 追加ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = ManufacturerAdapter.instance.addAsync(
            { item: values },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = ManufacturerAdapter.instance.updateAsync(
            { item: values },
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        // ダイアログ表示
        dispatch(ManufacturerMasterSlice.actions.toggleDialog(true));

    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(ManufacturerMasterSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }

    /**
    * 入力内容のバリデーションを行います。
    * @returns 検証結果を返します。
    * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let manufacturer = "";

        // 型式
        if (!values.manufacturer) {
            manufacturer = "メーカーを入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            manufacturer,
        });

        // 結果
        return result;
    }

    // 表示
    return (
        <ModelDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            onChange={onChange}
            validateError={validate}
        />
    );
};
