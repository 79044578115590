//react
import * as React from 'react';
import { useState } from 'react';
// router
import { Route } from 'react-router-dom';
// material-ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
// 各画面ルート
import { MasterPageTabItems } from './TabListUtility'

/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "flex",
            flexGrow: 1,
        },
        // タブ
        tab: {
            color: theme.palette.text.secondary,
            // アクティブタブ
            "& .Mui-selected": {
                color: theme.palette.primary.contrastText,
                backgroundColor: fade(theme.palette.primary.main, 0.8),
            },
        },
    }),
);

/**  マスタ画面タブリストの Props を提供します。*/
interface Props {
    /** 選択されているタブを表します。 */
    selectedTab: string,
    /** タブクリック時のイベントを表します。 */
    onTabClick: (event: React.ChangeEvent<{}>, selectedTab: string) => void,
}

/**
 * マスタ画面タブリストを表示します。
 * */
export function TabList(props: Props) {

    /** Style を表します。 */
    const classes = useStyles();

    /** State を表します。*/
    const [path, setPath] = useState<string>(MasterPageTabItems.masterMachine.tabValue);


    React.useEffect(() => {
        setPath(props.selectedTab);
    }, [props])


    return (
        <>
            <Paper square elevation={0}>
                {/* タブ */}
                <Paper square className={classes.root}>
                    <Tabs
                        className={classes.tab}
                        value={path}
                        onChange={props.onTabClick}
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="tabs"
                        scrollButtons="on"
                    >

                        {/* 施工機マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterMachine.label}
                            value={MasterPageTabItems.masterMachine.tabValue}
                        />
                        {/* 型式マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterModel.label}
                            value={MasterPageTabItems.masterModel.tabValue}
                        />
                        {/* メーカーマスタ */}
                        <Tab
                            label={MasterPageTabItems.masterManufacturer.label}
                            value={MasterPageTabItems.masterManufacturer.tabValue}
                        />
                        {/* 工法マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterMethod.label}
                            value={MasterPageTabItems.masterMethod.tabValue}
                        />
                        {/* 整備区分マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterMaintenanceDivisioin.label}
                            value={MasterPageTabItems.masterMaintenanceDivisioin.tabValue}
                        />
                        {/* 整備項目マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterMaintenanceItem.label}
                            value={MasterPageTabItems.masterMaintenanceItem.tabValue}
                        />
                        {/* 特記事項マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterRemarksItem.label}
                            value={MasterPageTabItems.masterRemarksItem.tabValue}
                        />
                        {/* 点検区分マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterInspectionDivision.label}
                            value={MasterPageTabItems.masterInspectionDivision.tabValue}
                        />
                        {/* 点検項目マスタ */}
                        <Tab
                            label={MasterPageTabItems.masterInspectionItem.label}
                            value={MasterPageTabItems.masterInspectionItem.tabValue}
                        />
                        {/* ユーザーマスタ */}
                        <Tab
                            label={MasterPageTabItems.masterUser.label}
                            value={MasterPageTabItems.masterUser.tabValue}
                        />
                    </Tabs>
                </Paper>
                {/* コンテンツ */}
                <Box>
                    {/* 施工機マスタ */}
                    <Route
                        path={MasterPageTabItems.masterMachine.path}
                        component={MasterPageTabItems.masterMachine.component}
                    />
                    {/* 型式マスタ */}
                    <Route
                        path={MasterPageTabItems.masterModel.path}
                        component={MasterPageTabItems.masterModel.component}
                    />
                    {/* メーカーマスタ */}
                    <Route
                        path={MasterPageTabItems.masterManufacturer.path}
                        component={MasterPageTabItems.masterManufacturer.component}
                    />
                    {/* 工法マスタ */}
                    <Route
                        path={MasterPageTabItems.masterMethod.path}
                        component={MasterPageTabItems.masterMethod.component}
                    />
                    {/* 整備区分マスタ */}
                    <Route
                        path={MasterPageTabItems.masterMaintenanceDivisioin.path}
                        component={MasterPageTabItems.masterMaintenanceDivisioin.component}
                    />
                    {/* 整備項目マスタ */}
                    <Route
                        path={MasterPageTabItems.masterMaintenanceItem.path}
                        component={MasterPageTabItems.masterMaintenanceItem.component}
                    />
                    {/* 特記事項マスタ */}
                    <Route
                        path={MasterPageTabItems.masterRemarksItem.path}
                        component={MasterPageTabItems.masterRemarksItem.component}
                    />
                    {/* 点検区分マスタ */}
                    <Route
                        path={MasterPageTabItems.masterInspectionDivision.path}
                        component={MasterPageTabItems.masterInspectionDivision.component}
                    />
                    {/* 点検項目マスタ */}
                    <Route
                        path={MasterPageTabItems.masterInspectionItem.path}
                        component={MasterPageTabItems.masterInspectionItem.component}
                    />
                    {/* ユーザーマスタ */}
                    <Route
                        path={MasterPageTabItems.masterUser.path}
                        component={MasterPageTabItems.masterUser.component}
                    />
                </Box>
            </Paper>
        </>

    );

};
