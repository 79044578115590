// react
import * as React from 'react';
// redux
import { useDispatch } from 'react-redux';
import { onGetAsync } from '../../../../../modules/slice/other-report/other-report-slice';
// component
import { OtherReportInputItem } from '../../_types';
import OtherReportSearch from './OtherReportSearch';

/** その他報告画面　検索エリアを表示します。 */
export const OtherReportSearchContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    /** 検索ボタンのクリック処理を行います。 */
    const onSearchClickAsync = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: OtherReportInputItem) => {
        // 一覧取得
        await dispatch(onGetAsync({ item: inputItem }));
    };

    return (
        <OtherReportSearch
            onSearchClick={onSearchClickAsync}
        />
    );


};
