import * as React from 'react';
import MuiColorButton from '../../../../../components/Buttons/ColorButton/MuiColorButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Sync, CloudUpload, CreateNewFolder, Delete } from '@material-ui/icons';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        // ボタン
        button: {
            minWidth: 0,
        },
    }),
);

/** ボタン種別を提供します。*/
export type ButtonType = "update" | "upload" | "create" | "delete";

/** 操作ボタン の　Props を提供します。*/
interface ControlButtonsProps {
    /** ボタンクリック処理を表します。 */
    onClick: (type: ButtonType) => void;
    /** アップロードボタンの可否を表します。 */
    isDisabledUpload: boolean;
    /** フォルダ作成ボタンの可否を表します。 */
    isDisabledCreate: boolean;
    /** 削除ボタンの可否を表します。 */
    isDisabledDelete: boolean;
}


/**
 * 操作ボタンコンポーネントを表示します。
 * @param props
 */
export function ControlButtons(props: ControlButtonsProps): JSX.Element {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MuiColorButton
                className={classes.button}
                overrideColor="secondary"
                variant="contained"
                onClick={() => props.onClick("update")}
            >
                <Sync />
            </MuiColorButton>


            <MuiColorButton
                overrideColor="warning"
                variant="contained"
                startIcon={<CloudUpload />}
                onClick={() => props.onClick("upload")}
                disabled={props.isDisabledUpload}
            >
                アップロード
            </MuiColorButton>

            <MuiColorButton
                overrideColor="primary"
                variant="contained"
                startIcon={<CreateNewFolder />}
                onClick={() => props.onClick("create")}
                disabled={props.isDisabledCreate}
            >
                フォルダ作成
            </MuiColorButton>

            <MuiColorButton
                overrideColor="error"
                variant="contained"
                startIcon={<Delete />}
                onClick={() => props.onClick("delete")}
                disabled={props.isDisabledDelete}
            >
                削除
            </MuiColorButton>
        </div>
    );
}
