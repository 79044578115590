import { AreaType, MachineItem } from "../../../../_types";

/** 施工機モニタテーブル項目のインターフェイスを提供します。 */
export interface MachineMonitoringListItem {
    /** ベースマシン 社番を表します。 */
    readonly baseMachine: MachineItem;
    /** オーガー 社番を表します。 */
    readonly auger: MachineItem;
    /** 昇降装置 社番を表します。 */
    readonly lift: MachineItem;
    /** 作業場所を表します。 */
    readonly site: string;
    /** 施工機がロックされているかどうかを表します。 */
    readonly isLock: boolean;
    /** エリア種別を表します。 */
    readonly areaType?: AreaType;
    /** タブレット ID を表します。 */
    readonly tabletId: string;
    /** エラー情報 を表します。 */
    readonly ctuError: string;
    /** 報告事項 を表します。 */
    readonly reportItem: string;
    /** タブレットがアクセスしているかどうかを表します。 */
    readonly isConnectedTablet: boolean;
    /** 特定自主検査後日数を取得または設定します。 */
    readonly selfInspection: string;
}

/** 検索種別を表す列挙値を提供します。 */
export enum MachineMonitoringSearchType {
    /** 未指定を表します。 */
    None = 0,
    /** ベースマシンを表します。 */
    BaseMachine = 1,
    /** オーガーを表します。 */
    Auger = 2,
    /** 昇降装置を表します。 */
    Lift = 3,
}

/** 入力項目のインターフェイスを提供します。 */
export interface MachineMonitoringInputItem {
    /** 施工機キーワードを表します。 */
    machineKeyword: string;
    /** 検索種別を表します。 */
    type: MachineMonitoringSearchType;
    /** 作業現場キーワードを表します。 */
    siteKeyword: string;
    /** 報告事項キーワードを表します。 */
    reportKeyword: string;
}
