// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { DailyInspectionRecordSlice, onGetDailyReportAsync } from '../../../../../modules/slice/daily-inspection-record/daily-inspection-record-slice';
// type
import { DailyInspectionRecordListItem } from "../../_types";
import { AreaType } from '../../../../../_types';
// component
import DailyInspectionRecordTable from './DailyInspectionRecordTable';

/** 日常点検記録テーブルを提供します */
export const DailyInspectionRecordTableContainer: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const machineId = useSelector((state: RootState) => state.globalHeader.machine?.item.id ?? "");
    const data: DailyInspectionRecordListItem[] = useSelector((state: RootState) => state.dailyInspectionRecord.resultItems ?? []);
    const areaType = useSelector((state: RootState) => state.areaType.area);

    /**
    * 帳票ボタンクリック時のイベント処理を行います。
    * @param rowData
    */
    const onDocumentClickAsync = async (rowData: DailyInspectionRecordListItem) => {
        if (!machineId) {
            dispatch(DailyInspectionRecordSlice.actions.showErrorMessage("施工機情報がありません"));
        }
        else {
            // 選択項目保存
            dispatch(DailyInspectionRecordSlice.actions.selectItem(rowData));
            // 通信
            await dispatch(onGetDailyReportAsync({
                machineId,
                reportAt: rowData.completionDate
            }));
        }

    }

    /**
    * エリア種別のフィルター処理を行います。
    * @param source テーブルデータを指定します。
    * @param areaType エリア種別を指定します。
    * @returns フィルタ後のテーブルデータを返します。
    */
    const areaTypeFilter = (source: DailyInspectionRecordListItem[], areaType: AreaType) => {

        // エリアが確定していない施工機は全国でしか表示しない。
        // material-tableにデータをそのまま渡すとErrorになるので複製・参照を切る
        return source.filter(item => (areaType === AreaType.None ? true : item.areaType === areaType)).map(item => ({ ...item }));

    }

    return (
        <DailyInspectionRecordTable
            dataSource={areaTypeFilter(data, areaType)}
            onDocumentClickAysnc={onDocumentClickAsync}
        />

    );

};
