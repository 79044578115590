// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import { Dialog, DialogActions, DialogTitle, DialogContent, Typography, TextField, InputAdornment, IconButton, Box, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Chip, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';
import MuiUpdateButton from '../../../../../components/Buttons/MuiUpdateButton';
import { UpdateInspectedStampListItem, StampUpdateStaus } from '../../../_types';
import MaterialTable, { Column } from 'material-table';
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
import StampTag from './StampTag';
import SendBackTag from './SendBackTag';
import AccountBoxIcon from '@material-ui/icons/AccountBox';


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            // アイコン
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
        },
        // サブタイトル
        subtitle: {
            marginBottom: theme.spacing(2),
        },

        // 検印者
        inspector: {
            fontWeight: "bold",
            marginBottom: theme.spacing(2),
        },
        // ボタン
        button: {
            minWidth: 120,
        },
        // ダイアログコンテンツ(ダイアログ内のTextfiledのlabelが全角4文字以上でスクロールが発生するで、無効にする)
        dialogContent: {
            overflowY: "visible"
        },
        // テーブル
        table: {
            marginBottom: theme.spacing(2),
            "& tbody>.MuiTableRow-root": {
                pointerEvents: "none",
            }
        },


    }),
);

/** 検印確認ダイアログの Props を表示します。 */
interface Props<TKey> {
    /** 検印者名を表します。 */
    inspectionName: string;
    /** 検印情報を表します。 */
    dataSource: UpdateInspectedStampListItem<TKey>[],
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** 更新ボタンクリック時の処理を表します。 */
    onUpdateClick: () => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
}

/**
 * 検印確認ダイアログを表示します。
 * @param props
 */
function InspectionConfirmDialog<TKey>(props: Props<TKey>): JSX.Element {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();
    /** Theme を提供します。*/
    const theme = useTheme();

    /** テーブルカラムを表します。 */
    const columns: Column<UpdateInspectedStampListItem<TKey>>[] = [
        {
            title: "検印データ",
            field: "data",
            align: "left",
            headerStyle: {
                width: 200,
                minWidth: 200,
            },
        },
        {
            title: "更新前",
            field: "isStampBefore",
            align: "center",
            headerStyle: {
                width: 75,
                maxWidth: 75,
            },
            render: (row: UpdateInspectedStampListItem<TKey>) => (
                <span>
                    {row.exist
                        ? row.isStampBefore ? "検印" : "未検印"
                        : ""}
                </span>
            )
        },
        {
            title: "",
            align: "center",
            headerStyle: {
                width: 50,
                maxWidth: 50,
            },
            render: (row: UpdateInspectedStampListItem<TKey>) => (
                row.exist
                    ? <span>⇒</span>
                    : <></>
            )
        },
        {
            title: "更新後",
            field: "isStampAfter",
            align: "center",
            headerStyle: {
                width: 75,
                maxWidth: 75,
            },
            render: (row: UpdateInspectedStampListItem<TKey>) => (
                <span>
                    {row.exist
                        ? row.isStampAfter ? "検印" : "未検印"
                        : ""}
                </span>
            )
        },
        {
            title: "",
            field: "status",
            align: "center",
            headerStyle: {
                width: 100,
                maxWidth: 100,
            },
            render: (row: UpdateInspectedStampListItem<TKey>) => (
                row.status === StampUpdateStaus.Stamp ? <StampTag />
                    : row.status === StampUpdateStaus.SendBack ? <SendBackTag />
                        : <></>
            )
        },


    ]

    /**
     * 更新が有効かどうかを判定します。
     * @param listItem
     * @returns 有効かどうかを返します。有効の場合は true を、それ以外の場合は flase を返します。
     */
    const checkEnableUpdate = (listItem: UpdateInspectedStampListItem<TKey>[]): boolean => {
        const result = listItem.some(item => item.status !== StampUpdateStaus.None)
        return result;
    }

    return (
        <Dialog
            keepMounted
            disableBackdropClick
            fullWidth={true}
            maxWidth={"md"}
            aria-labelledby="input-stamp-dialog-title"
            aria-describedby="input-stamp-dialog-description"
            open={props.isOpen}
            onClose={props.handleClose}
        >

            {/* タイトル */}
            <DialogTitle id="input-stamp-dialog-title">
                <Box className={classes.title}>
                    <InfoIcon fontSize="large" />検印更新確認
                </Box>
            </DialogTitle>

            <DialogContent classes={{ root: classes.dialogContent }}>

                {/* message */}
                <Typography className={classes.subtitle}>
                    {checkEnableUpdate(props.dataSource)
                        ? "帳票の検印状態を変更します。よろしいですか？"
                        : "検印の更新項目はありません。"
                    }
                </Typography>

                <Typography className={classes.inspector}>
                    <AccountBoxIcon fontSize={"large"} color={"secondary"} />
                    検印者：{props.inspectionName}
                </Typography>

                {/* 更新内容表示 */}
                <div className={classes.table}>
                    <MaterialTable

                        icons={MuiTableIcons}
                        localization={MuiLocalizationJapanese}
                        options={{
                            toolbar: false,
                            paging: false,
                            search: false,
                            sorting: false,
                            tableLayout: "auto",
                            //pageSize: 10,

                            // 行カラー
                            rowStyle: (rowData: UpdateInspectedStampListItem<TKey>) => {
                                if (rowData.status === StampUpdateStaus.Stamp) {
                                    return { backgroundColor: fade(theme.palette.success.main, 0.5) }
                                } else if (rowData.status === StampUpdateStaus.SendBack) {
                                    return { backgroundColor: fade(theme.palette.error.main, 0.5) }
                                } else {
                                    return {}
                                }
                            }
                        }}
                        columns={columns}
                        data={props.dataSource}
                        components={{
                            Container: (props) => {
                                return (
                                    <Paper {...props} elevation={0} />
                                )
                            },
                        }}
                    />
                </div>

            </DialogContent>

            {/* ボタンアクション */}
            <DialogActions>
                <MuiCancelButton
                    className={classes.button}
                    onClick={props.handleClose}
                />
                {checkEnableUpdate(props.dataSource) &&
                    <MuiUpdateButton
                        className={classes.button}
                        onClick={props.onUpdateClick}
                    />
                }
            </DialogActions>
        </Dialog>
    );
}

export default InspectionConfirmDialog;
