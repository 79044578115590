// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeRecordPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { DailyInspectionRecordSlice, onGetAsync } from '../../../modules/slice/daily-inspection-record/daily-inspection-record-slice';
// component
import { DailyInspectionRecordTableContainer } from './components/TableContent/DailyInspectionRecordTableContainer';
import { DailyInspectionSearchContainer } from './components/SearchContent/DailyInspectionSearchContainer';
import { StampContainer } from './components/StampContent/StampContainer';
import { ViewerContainer } from './components/ViewerContent/ViewerContainer';

// 共通component
import CollapseLayout from '../../../components/Layout/CollapseLayout';
import TopAndBottomLayout from '../../../components/Layout/TopAndBottomLayout';
import TwoColumnLayout from '../../../components/Layout/TwoColumnLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';
import { DailyInspectionRecordSearchType, StampSearchType } from './_types';
import { AreaToggleButtonContainer } from '../../../components/AreaToggleButton/AreaToggleButtonContainer';

/**
 * 日常点検記録画面を提供します。
 * */
export const DailyInspectionRecord: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.dailyInspectionRecord.isLoading);
    const isReportLoading = useSelector((state: RootState) => state.dailyInspectionRecord.isReportLoading);
    const isShowMessage = useSelector((state: RootState) => state.dailyInspectionRecord.isShowMessage);
    const message = useSelector((state: RootState) => state.dailyInspectionRecord.message);
    const machine = useSelector((state: RootState) => state.globalHeader.machine)
    const isOpenViewer = useSelector((state: RootState) => state.dailyInspectionRecord.isOpenViewer ?? true);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(DailyInspectionRecordSlice.actions.hideMessage());
    };

    useEffect(() => {
        // タイトル変更
        dispatch(changeRecordPageHeader((AppTitle.recordDailyInspectionRecord.title)));

        // 初回マウント時に直近30日分のデータ取得を行う。
        /** 初期表示時に入力値に入れる値は SearchContent で設定しているので注意する */
        const beginAt = new Date();
        beginAt.setMonth(beginAt.getMonth() - 1);
        beginAt.setHours(0, 0, 0, 0);

        const endAt = new Date();
        endAt.setHours(0, 0, 0, 0);

        if (!machine) {
            dispatch(DailyInspectionRecordSlice.actions.showErrorMessage("施工機情報が指定されていません"));
        } else {
            dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    machineType: machine.type,
                    keyword: "",
                    searchType: DailyInspectionRecordSearchType.None,
                    searchStampType: StampSearchType.None,
                    beginAt: beginAt,
                    endAt: endAt,
                }
            }))
        }

    }, [dispatch, machine]);

    useEffect(() => {

        return () => {
            //　unmout 時にビューワを非表示にする
            dispatch(DailyInspectionRecordSlice.actions.toggleViewre(false));
        }

    }, [dispatch])

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading || isReportLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* 日常点検記録 */}
            <CollapseLayout
                isExpanded={isOpenViewer}
                defaultContent={
                    <TwoColumnLayout
                        // 検索エリア
                        side={
                            <TopAndBottomLayout
                                topContent={<DailyInspectionSearchContainer />}
                                bottomContent={<AreaToggleButtonContainer />}
                            />
                        }
                        // テーブル
                        main={<DailyInspectionRecordTableContainer />}
                    />
                }
                expandContent={
                    isOpenViewer
                        ? <TwoColumnLayout
                            // 検印エリア
                            side={<StampContainer />}
                            // ビューワ
                            main={<ViewerContainer />}
                        />
                        : <></>
                }
            />

        </>
    );
};
