// react
import * as React from 'react';
import { useEffect } from 'react';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeMasterPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { ModelMasterSlice, onExcuteMountedAsync } from '../../../modules/slice/model/model-slice';
// component
import { MachineModelResultTableContainer } from './components/TableContent/MachineModelResultTableContainer';
import { MachineModelSearchConditionConTainer } from './components/SearchContent/MachineModelSearchConditionContainer';
import { ModelDetailConditionContainer } from './components/DetailContent/MachineModelDetailConditionContainer';
import { ConfirmDialogContainer } from './components/ConfirmDialog/ConfirmDialogContainer';
import MasterLayout from '../Shared/MasterLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { AppTitle } from '../../Shared/app-title';

/**
 * 型式マスタ画面を提供します。
 * */
const MachineModelPage: React.FC<{}> = () => {
    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeMasterPageHeader(AppTitle.masterModel.title));

        // 初回マウント時にデータを取得する
        dispatch(onExcuteMountedAsync());
    }, [dispatch]);

    // redux store
    const isLoading = useSelector((state: RootState) => state.modelMaster.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.modelMaster.isShowMessage);
    const message = useSelector((state: RootState) => state.modelMaster.message);
    const isCreating = useSelector((state: RootState) => state.modelMaster.isCreating);
    const isSelected = useSelector((state: RootState) => state.modelMaster.isSelected);

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(ModelMasterSlice.actions.hideMessage());
    };

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ダイアログ */}
            <ConfirmDialogContainer />

            <MasterLayout
                search={<MachineModelSearchConditionConTainer />}
                table={<MachineModelResultTableContainer />}
                detail={<ModelDetailConditionContainer />}
                isCreating={isCreating}
                isSelected={isSelected}
            />
        </>
    );
};

export default MachineModelPage;
