// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { UserMasterSlice } from '../../../../../modules/slice/user/user-slice';
// type
import { UserListItem } from '../../_types';
// component
import UserResultTable from './UserResultTable';

/** ユーザーマスタテーブルを提供します */
export const UserResultTableContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const data: UserListItem[] = useSelector((state: RootState) => state.userMaster.result.items ?? []);
    const selectedRow = useSelector((state: RootState) => state.userMaster.selectedRowItem);
    const userItem = useSelector((state: RootState) => state.authentication.user);

    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const onRowClick = (rowData: UserListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを store に保持
        dispatch(UserMasterSlice.actions.onSelectedItem({ ...rowData }))
    };

    return (
        <UserResultTable
            dataSource={data.map(item => ({ ...item }))} // storeにあるデータをそのまま渡すとErrorになるので複製・参照を切る
            onRowClick={onRowClick}
            selectedRow={selectedRow}
            userItem={userItem}
        />
    );

};
