//react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { areaTypeSlice, changeAreaType } from '../../modules/slice/area-type/area-type-slice'
import { AreaTypeUtility } from '../../modules/slice/area-type/area-type-slice-utility'

// type
import { AreaType } from '../../_types';
import AreaToggleButton from './AreaToggleButton'

/**
 * エリア選択のトグルボタングループを表示します。
 * */
export function AreaToggleButtonContainer() {

    // redux
    const dispatch = useDispatch();

    // redux store
    const areaType: AreaType = useSelector((state: RootState) => state.areaType.area);

    /**
     * エリア選択時のイベント処理を行います。
     * @param event
     * @param selectedAera
     */
    const onChangeArea = (event: React.MouseEvent<HTMLElement>, selectedAera: AreaType | null) => {
        if (selectedAera !== null) {
            dispatch(changeAreaType(selectedAera));
        }
    };

    useEffect(() => {
        // ストレージ更新
        AreaTypeUtility.extendExpiresAreaType();
    }, [])


    return (
        <AreaToggleButton
            area={areaType}
            onChangeArea={onChangeArea}
        />
    );
}
