// react
import * as React from 'react';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// type
import { InspectionDivisionInputItem } from '../../_types';
import { KeyValueItem, InspectionType } from '../../../../../_types';
// component
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import MuiCreateButton from '../../../../../components/Buttons/MuiCreateButton';
import { InspectionPreviewDrawer } from '../../../Shared/components/ViewerDrawer/InspectionPreviewDrawer';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },
        // ボタン グループ
        buttonGroup: {
            marginRight: "auto",
        },
    }),
);

/** 点検区分マスタ　検索項目の Props を提供します。 */
interface Props {
    /** 検索ボタンのクリック処理を行います。 */
    onClickSearch: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: InspectionDivisionInputItem) => void),
    ///** 新規作成ボタンのクリック処理を行います。 */
    onClickCreate: (() => void),
    /** 点検グループ一覧を表します。*/
    groupList: KeyValueItem<number, string>[],
    /** プレビューボタンのクリック処理を行います。 */
    onClickPreview: ((type: InspectionType) => void),
    /** 帳票 URL を表します。 */
    reportUrl: string,
    /** レポート読み込み終了時の処理を行います。 */
    loadedReport: () => void,
};

/**
 * 点検区分マスタ　検索エリアを提供します。
 * */
const InspectionDivisionSearchCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<InspectionDivisionInputItem>({});

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (name: keyof InspectionDivisionInputItem) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? Number.parseInt(target.value, 10) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    // 表示
    return (

        <Box padding={1}>

            {/* 点検グループ */}
            <TextField
                select
                SelectProps={{
                    MenuProps: {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }
                }}
                label="点検グループ"
                placeholder="点検グループ"
                value={values.groupId ?? ""}
                onChange={onChange("groupId")}

            >
                {/* Default */}
                <MenuItem key={0} value={0}>{"未指定"}</MenuItem>

                {/*　選択肢 */}
                {props.groupList.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                )
                )}

            </TextField >

            {/* 点検区分 */}
            <TextField
                label="点検区分"
                placeholder="点検区分"
                value={values.name ?? ""}
                onChange={onChange("name")}
            />

            {/* ボタングループ */}
            <Box display="flex" alignItems="center">

                <div className={classes.buttonGroup}>
                    {/* 検索ボタン */}
                    <MuiSearchButton
                        className={classes.button}

                        onClick={(event) => props.onClickSearch(event, values)}
                    />
                    {/* 新規登録ボタン */}
                    <MuiCreateButton
                        className={classes.button}
                        onClick={(event) => props.onClickCreate()}
                    />
                </div>

                {/* プレビュー */}
                <InspectionPreviewDrawer
                    reportUrl={props.reportUrl}
                    loadedReport={props.loadedReport}
                    onClickPreview={props.onClickPreview}
                />

            </Box>

        </Box>
    );
};

export default InspectionDivisionSearchCondition;
