import * as React from 'react';
import { MachineErrorHistoryGraphItem, MachineErrorHistoryGraphDisplayData } from '../../_types';

/**
 * アラート記録グラフ 関連の ユーティリティ機能を提供します。
 * */
export class AlertRecordGraphUtility {
    //#region フィールド


    //#endregion フィールド

    //#region メソッド

    /**
    *グラフ用データを変換します。
    * @param dataSource
    */
    public static toGraphData = (source: MachineErrorHistoryGraphItem[]): MachineErrorHistoryGraphDisplayData[] => {

        const result = source
            .filter(item => item.dateAt != null)
            .map((item) => {
                let graphData: MachineErrorHistoryGraphDisplayData
                graphData = {
                    // 対象日 ミリ秒に変換
                    dateAt: new Date(item.dateAt).getTime(),
                    // アラート件数
                    count: item.count,
                }
                return graphData;
            })
            .sort((a, b) => (a.dateAt - b.dateAt)); // 時系列順にソート

        return result;

        //#endregion メソッド



    }
}
