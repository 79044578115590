import * as React from 'react';
import { OtherReportPage } from './OtherReport';
import { HeaderPage } from '../Shared/HeaderPage/HeaderPage';

/**
 * その他報告(Report)画面を表します。
 */
export const ReportPage: React.FC<{}> = () => {
    return (
        <HeaderPage children={<OtherReportPage />} />
    );
};
