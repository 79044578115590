// redux
import { Dispatch } from 'redux';
// shared
import { MasterGenericState, createMasterGenericSlice } from '../shared/create-master-slice';
// type
import { MachineAdapter } from '../../adapter/machine/machine-adapter';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { MachineRequest, MachineResult, MachineMasterSelectBoxItemState } from '../../adapter/machine/types';
import { MachineListItem } from '../../../pages/Master/Machine/_types';

// State の初期値を設定します。
const initialState: MasterGenericState<MachineResult, MachineListItem, MachineMasterSelectBoxItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    isCreating: false,
    isSelected: false,
    selectedRowItem: void 0,
    selectBoxItem: {
        MachineType: [],
        Model: [],
        Manufacturer: [],
        CtuId: [],
        MaintenanceCtuId: [],
    },
    isDialogOpen: false,
}

/** 取得 Slice を 作成します。*/
export const MachineMasterSlice = createMasterGenericSlice({
    // State Reducer Action を生成
    name: 'Machine',
    initialState: initialState,
    reducers: {},
})

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onExcuteAsync = (promise: Promise<MachineResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineMasterSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(MachineMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(MachineMasterSlice.actions.showErrorMessage(result.errorMessage));
        } else {
            // 正常
            dispatch(MachineMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(MachineMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(MachineMasterSlice.actions.chageCreating(false));
        }

    } catch (error) {

        //例外
        dispatch(MachineMasterSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        console.log({ error });

        // メッセージ
        dispatch(MachineMasterSlice.actions.showErrorMessage(`${error.toString()}`));

    } finally {

        // 処理中 OFF
        dispatch(MachineMasterSlice.actions.fetchEnd());
    }
}

/**
 * 画面表示時の初回非同期通信処理を行います。
 * @param action
 */
export const onExcuteMountedAsync = () => async (dispatch: Dispatch) => {

    try {
        // state リセット
        dispatch(MachineMasterSlice.actions.resetState());

        // 処理中 ON
        dispatch(MachineMasterSlice.actions.fetchStart());

        // 施工機種別
        const machineTypeWaiter = SharedAdapter.instance.getMachineTypeAsync();
        // 型式
        const modelWaiter = SharedAdapter.instance.getModelAsync();
        // メーカー
        const manufacturerWaiter = SharedAdapter.instance.getManufacturerAsync();
        // CTU 番号
        const ctuWaiter = SharedAdapter.instance.getCtuAsync();

        //まとめて取得
        const selectBoxresult = await Promise.all([machineTypeWaiter, modelWaiter, manufacturerWaiter, ctuWaiter]) // 直接配列に渡したらエラーなる
        const machineTypeResult = selectBoxresult[0];
        const modelResult = selectBoxresult[1];
        const manufacturerResult = selectBoxresult[2];
        const ctuResult = selectBoxresult[3];

        // 異常確認
        if (machineTypeResult.isError) {
            // 施工機種別 異常メッセージ
            dispatch(MachineMasterSlice.actions.showErrorMessage(`施工機種別取得エラー：${machineTypeResult.errorMessage}`));

        } else if (modelResult.isError) {
            // 型式 異常メッセージ
            dispatch(MachineMasterSlice.actions.showErrorMessage(`型式取得エラー：${modelResult.errorMessage}`));

        } else if (manufacturerResult.isError) {
            // メーカー 異常メッセージ
            dispatch(MachineMasterSlice.actions.showErrorMessage(`メーカー取得エラー：${manufacturerResult.errorMessage}`));
        } else if (ctuResult.isError) {
            // CTU 番号 異常メッセージ
            dispatch(MachineMasterSlice.actions.showErrorMessage(`CTU番号取得エラー：${ctuResult.errorMessage}`));
        } else {
            // 正常
            // リストの項目を store に保存
            dispatch(MachineMasterSlice.actions.getSelectBoxItem({
                MachineType: machineTypeResult.items,
                Model: modelResult.items,
                Manufacturer: manufacturerResult.items,
                CtuId: ctuResult.items ?? [],
                MaintenanceCtuId: ctuResult.maintenanceItems ?? [],
            }));

            // データ取得
            const result = await MachineAdapter.instance.getAsync(
                { item: {} },
                {
                    MachineType: machineTypeResult.items,
                    Model: modelResult.items,
                    Manufacturer: manufacturerResult.items,
                    CtuId: ctuResult.items ?? [],
                    MaintenanceCtuId: ctuResult.maintenanceItems ?? [],
                }
            );

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(MachineMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(MachineMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(MachineMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(MachineMasterSlice.actions.showSuccessMessage());
            }
        }

    } catch (error) {

        // メッセージ
        dispatch(MachineMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MachineMasterSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 * @param password
 */
export const onConfirmRemoveAsync = (
    parameter: MachineRequest,
    selectBoxItem: MachineMasterSelectBoxItemState,
    password: string,
) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineMasterSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(MachineMasterSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));

        } else {
            // 正常
            // 削除実行
            const result = await MachineAdapter.instance.removeAsync(parameter, selectBoxItem);

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(MachineMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(MachineMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(MachineMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(MachineMasterSlice.actions.showSuccessMessage("削除しました。"));

                // ダイアログを閉じる
                dispatch(MachineMasterSlice.actions.toggleDialog(false));

                // 選択項目のクリア
                dispatch(MachineMasterSlice.actions.clearSelectedItem());

            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(MachineMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(MachineMasterSlice.actions.fetchEnd());
    }

}
