// react
import * as React from 'react';
import { useState } from 'react';
// material-ui
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタンベース
        buttonBase: {
            height: "100%",
        },
        // カード全体
        card: {
            position: "relative",
            display: "block",
            width: "240px",
            color: "#fafafa",　// デフォルトは白固定
            backgroundColor: theme.palette.text.disabled,
            height: "100%",
            minHeight: "100px",
        },
        // カード全体　アクティブ
        cardActive: (props: Props) => ({
            backgroundColor: props.backgroundColor ?? "",
        }),
        cardContent: {
            display: "flex",
            height: "100%",
        },
        // タイトル
        title: {
            display: "flex",
            alignItems: "center",
            textAlign: "start",
            fontSize: "1.3rem",
            zIndex: 2,
        },
        // タイトル　アクティブ
        titleActive: (props: Props) => ({
            color: props.titleColor ?? "#fafafa",
        }),
        // アイコン
        icon: {
            '& svg': {
                fontSize: "5rem",
            },
            opacity: 0.3,
            zIndex: 1,
            position: "absolute",
            top: "50%",
            left: "60%",
            transform: "translateY(-50%)",
        },
        // アイコン　アクティブ
        iconActive: (props: Props) => ({
            '& svg': {
                color: props.iconColor ?? "#fafafa",
            },
        }),
    }),
);

/** 計測結果カードの Props を提供します。 */
interface Props {
    /** タイトルを表します。 */
    title: string[],
    /** ローディング中かどうかを表します。 */
    isLoading: boolean,
    /** アクティブかどうかを表します。 */
    isActive: boolean,
    /** アイコンを表します。 */
    icon?: React.ReactNode,
    /** タイトルの文字の色を表します。 */
    titleColor?: string,
    /** 背景の色を表します。 */
    backgroundColor?: string,
    /** アイコンの色を表します。 */
    iconColor?: string,
    /** クリック時の処理を表します。 */
    onClick: () => void,
};

/**
 * グラフ表示項目選択カードを提供します。
 * @param props
 */
const GraphSelectCard: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles(props);

    return (
        <ButtonBase onClick={props.onClick} style={{ height: "100%" }}>
            <Card className={clsx(classes.card, props.isActive && classes.cardActive)}>
                <CardContent className={classes.cardContent}>

                    <Box className={clsx(classes.title, props.isActive && classes.titleActive)}>
                        {/* チェックボックスアイコン */}
                        {props.isActive ? (<CheckBoxIcon />) : (<CheckBoxOutlineBlankIcon />)}
                        <div style={{}}>
                            {/* タイトル */}
                            {props.title.map((text, index) => {
                                return <div key={index}>{text}</div>
                            })}
                        </div>

                    </Box>

                </CardContent >
                {/* アイコン */}
                <span className={clsx(classes.icon, props.isActive && classes.iconActive)}>
                    {props.icon}
                </span>
            </Card >
        </ButtonBase>
    );
};

export default GraphSelectCard;
