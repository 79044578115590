import * as React from 'react';
import { ConstructionMachineMasterTab } from './ConstructionMachineMasterTab';
import { HeaderPage } from '../Shared/HeaderPage/HeaderPage';

/**
 *  記録系画面を表示します。
 */
export const ConstructionMachineMasterPage: React.FC<{}> = () => {
    return (
        <HeaderPage children={<ConstructionMachineMasterTab />} />
    );
}
