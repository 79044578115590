// react
import * as React from 'react';
// material-ui
import { ButtonProps } from '@material-ui/core/Button';
import MuiColorButton from './ColorButton/MuiColorButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

/**
 *  一覧に戻るボタンを表示します。
 * @param props
 */
const MuiBackToListButton: React.FC<ButtonProps> = (props) => {

    return (

        <MuiColorButton
            {...props}
            overrideColor="primary"
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
        >
            記録表一覧に戻る
        </MuiColorButton>
    )
}

export default MuiBackToListButton;
