import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import ReportViewer from '../../../Shared/components/ReportViewer/ReportViewer';
import { CompletionInspectionRecordSlice } from '../../../../../modules/slice/completion-inspection-record/completion-inspection-record-slice';

/** 帳票ビューワを表示します。 */
export const ViewerContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const reportUrl: string = useSelector((state: RootState) => state.completionInspectionRecord?.report?.reportUrl ?? "");

    /** レポート読み込み完了時の処理を行います。 */
    const loadedReport = () => {
        dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(false));
    }

    return (
        <ReportViewer
            reportUrl={reportUrl}
            loadedReport={loadedReport}
        />
    );
};
