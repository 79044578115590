//react
import * as React from 'react';
import { useState } from 'react';
// router
import { Route } from 'react-router-dom';
// material-ui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// 各画面ルート
import { RecordPageTabItems } from './TabListUtility'
import { TabPageContentLayout } from '../../../../../components/Layout/TabPageContentLayout';



/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 縦区切り線
        divider: {
            margin: theme.spacing(0.5, 0),
        },
        // タブ
        tab: {
            color: theme.palette.text.secondary,
            // アクティブタブ
            "& .Mui-selected": {
                color: theme.palette.primary.contrastText,
                backgroundColor: fade(theme.palette.primary.main, 0.8),
            },
        },
        // 戻るボタン
        backButton: {
            margin: theme.spacing(0, 1, 0, 1),
        },
    }),
);

/**  施工機管理画面タブリストの Props を提供します。*/
interface Props {
    /** 選択されているタブを表します。 */
    selectedTab: string,
    /** ベースマシンが選択されているかどうかを表します。 */
    isBaseMachine: boolean,
    /** 施工機がロックされているかどうかを表します。 */
    isLock: boolean,
    /** タブクリック時のイベントを表します。 */
    onTabClick: (event: React.ChangeEvent<{}>, selectedTab: string) => void,
    /** 戻るボタンクリックのイベントを表します。 */
    onBackLinkClick: () => void,
}

/**
 * 施工機管理画面タブリストを表示します。
 * */
export function TabList(props: Props) {

    /** Style を表します。 */
    const classes = useStyles();

    /** State を表します。*/
    const [path, setPath] = useState<string>(RecordPageTabItems.maintenanceRecord.tabValue);


    React.useEffect(() => {
        setPath(props.selectedTab);
    }, [props])


    return (
        <TabPageContentLayout
            tab={<>

                {/* 戻るボタン */}
                <Box display="flex" alignItems="center">
                    <IconButton
                        aria-label="back"
                        className={classes.backButton}
                        onClick={props.onBackLinkClick}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>

                <Divider orientation="vertical" flexItem className={classes.divider} />

                {/* タブ */}
                <Tabs
                    className={classes.tab}
                    value={path}
                    onChange={props.onTabClick}
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs"
                    scrollButtons="on"
                >
                    {/* 施工機ステータス */}
                    {(props.isBaseMachine && props.isLock) && // ベースマシン ロック状態のみ
                        <Tab
                            label={RecordPageTabItems.machineStatus.label}
                            value={RecordPageTabItems.machineStatus.tabValue}
                        />
                    }

                    {/* 整備記録 */}
                    <Tab
                        label={RecordPageTabItems.maintenanceRecord.label}
                        value={RecordPageTabItems.maintenanceRecord.tabValue}
                    />

                    {/*　日常点検記録 */}
                    {props.isBaseMachine && // ベースマシンのみ
                        <Tab
                            label={RecordPageTabItems.dailyInspectionRecord.label}
                            value={RecordPageTabItems.dailyInspectionRecord.tabValue}
                        />
                    }

                    {/* 週間・月例点検記録 */}
                    {props.isBaseMachine && // ベースマシンのみ
                        <Tab
                            label={RecordPageTabItems.weeklyInspectionRecord.label}
                            value={RecordPageTabItems.weeklyInspectionRecord.tabValue}
                        />
                    }

                    {/* 現場完了時点検記録 */}
                    {props.isBaseMachine && // ベースマシンのみ
                        <Tab
                            label={RecordPageTabItems.completionInspectionRecord.label}
                            value={RecordPageTabItems.completionInspectionRecord.tabValue}
                        />
                    }

                    {/* 施工記録 */}
                    {props.isBaseMachine && // ベースマシンのみ
                        <Tab
                            label={RecordPageTabItems.constructionRecord.label}
                            value={RecordPageTabItems.constructionRecord.tabValue}
                        />
                    }

                    {/* 施工機メンテナンス設定 */}
                    {(props.isBaseMachine && props.isLock) && // ベースマシンのみ ロック状態のみ
                        <Tab
                            label={RecordPageTabItems.machineMaintenanceSetting.label}
                            value={RecordPageTabItems.machineMaintenanceSetting.tabValue}
                        />
                    }
                    {/* アラート記録 */}
                    {props.isBaseMachine && // ベースマシンのみ
                        <Tab
                            label={RecordPageTabItems.alertRecord.label}
                            value={RecordPageTabItems.alertRecord.tabValue}
                        />
                    }

                </Tabs>

            </>}
            content={<>
                {/* 施工機ステータス */}
                {(props.isBaseMachine && props.isLock) && // ベースマシンのみ ロック状態のみ
                    <Route
                        path={RecordPageTabItems.machineStatus.path}
                        component={RecordPageTabItems.machineStatus.component}
                    />
                }

                {/* 整備記録 */}
                <Route
                    path={RecordPageTabItems.maintenanceRecord.path}
                    component={RecordPageTabItems.maintenanceRecord.component}
                />

                {/*　日常点検記録 */}
                {props.isBaseMachine && // ベースマシンのみ
                    <Route
                        path={RecordPageTabItems.dailyInspectionRecord.path}
                        component={RecordPageTabItems.dailyInspectionRecord.component}
                    />
                }

                {/* 週間・月例点検記録 */}
                {props.isBaseMachine && // ベースマシンのみ
                    <Route
                        path={RecordPageTabItems.weeklyInspectionRecord.path}
                        component={RecordPageTabItems.weeklyInspectionRecord.component}
                    />
                }

                {/* 現場完了時点検記録 */}
                {props.isBaseMachine && // ベースマシンのみ
                    <Route
                        path={RecordPageTabItems.completionInspectionRecord.path}
                        component={RecordPageTabItems.completionInspectionRecord.component}
                    />
                }

                {/* 施工記録 */}
                {props.isBaseMachine && // ベースマシンのみ
                    <Route
                        path={RecordPageTabItems.constructionRecord.path}
                        component={RecordPageTabItems.constructionRecord.component}
                    />
                }

                {/* ステータス編集 */}
                {(props.isBaseMachine && props.isLock) && // ベースマシンのみ ロック状態のみ

                    <Route
                        path={RecordPageTabItems.machineMaintenanceSetting.path}
                        component={RecordPageTabItems.machineMaintenanceSetting.component}
                    />
                }
                {/* アラート記録 */}
                {props.isBaseMachine && // ベースマシンのみ
                    <Route
                        path={RecordPageTabItems.alertRecord.path}
                        component={RecordPageTabItems.alertRecord.component}
                    />
                }

            </>}
        />
    );

};
