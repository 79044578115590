// react
import * as React from 'react';
// material-ui
import Button, { ButtonProps } from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

/**
 *  検索ボタンを表示します。
 * @param props
 */
const MuiSearchButton: React.FC<ButtonProps> = (props) => {

    return (

        <Button
            variant="contained"
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={props.onClick}
            {...props}
        >
            検索
        </Button>
    )
}

export default MuiSearchButton;
