// react
import * as React from 'react';
import { useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { onGetAsync } from '../../../../../modules/slice/machine-operation-history/machine-operation-history-slice';
import { RootState } from '../../../../../rootReducer';

// compoent
import ConstructionRecordSearch, { ConstructionRecordSerachState } from './ConstructionRecordSearch'
// type
import { MachineOperationHistoryInputItem } from '../../_types';


/** 施工記録画面 検索エリアを提供します。*/
export const ConstructionRecordSerchContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine);

    /** 検索ボタンのクリック処理を行います。 */
    const onSearchClickAsync = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: ConstructionRecordSerachState) => {

        if (machine) {

            // 実行
            await dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    beginAt: inputItem.beginAt,
                    endAt: inputItem.endAt,
                }
            }));
        }
    };

    return (
        <ConstructionRecordSearch
            onSearchClick={onSearchClickAsync}
        />
    );

};
