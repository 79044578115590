// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// clsx
import clsx from 'clsx';
// material-ui
import { Dialog, DialogActions, DialogTitle, DialogContent, Typography, TextField, InputAdornment, IconButton, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';
import MuiUndoStampButton from '../../../../../components/Buttons/MuiUndoStampButton';
import MuiColorButton from '../../../../../components/Buttons/ColorButton/MuiColorButton';


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            // アイコン
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
        },
        // サブタイトル
        subtitle: {
            marginBottom: theme.spacing(2),
        },
        // 名称
        name: {
            fontSize: "1.25rem",
            marginRight: theme.spacing(1),
        },
        // ボタン
        button: {
            minWidth: 120,
        },
        // ダイアログコンテンツ(ダイアログ内のTextfiledのlabelが全角4文字以上でスクロールが発生するで、無効にする)
        dialogContent: {
            overflowY: "visible"
        }
    }),
);


/** 検印取り消し確認ダイアログの Props を表示します。 */
interface Props {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** 検印ボタンクリック時の処理を表します。 */
    onClick: () => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
}

/**
 * 検印取り消し確認ダイアログを表示します。
 * @param props
 */
const SignoutDialog: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Dialog
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="signout-dialog-title"
            aria-describedby="signout-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
            open={props.isOpen}
            onClose={props.handleClose}
        >

            {/* タイトル */}
            <DialogTitle id="signout-dialog-title">
                <Box className={classes.title}>
                    <InfoIcon fontSize="large" />ユーザーマスタの更新
                </Box>
            </DialogTitle>


            <DialogContent classes={{ root: classes.dialogContent }}>

                {/* message */}
                <Typography className={classes.subtitle}>
                    ユーザー情報が更新されました。<br />ユーザー情報の確認のため、サインアウトし、もう一度サインインをしてください。
                </Typography>

            </DialogContent>

            {/* ボタンアクション */}
            <DialogActions>
                <MuiColorButton
                    overrideColor="primary"
                    variant="contained"
                    //startIcon={<ReplyIcon />}
                    className={classes.button}
                    onClick={props.onClick}
                >
                    サインアウト
        </MuiColorButton>
            </DialogActions>
        </Dialog>
    );
}

export default SignoutDialog;
