/** S3 ファイルリスト種別を提供します。 */
export const FileListType = {
    //Png: "png",
    //Jpg: "jpg",
    //Jpeg: "jpeg",
    Pdf: "pdf",
    Other: "未定義",
} as const;
export type FileListType = typeof FileListType[keyof typeof FileListType];
export type S3ItemListType = FileListType | "フォルダ";

/** ホームディレクトリを提供します */
export const HomeDirectoryType = {
    Document: "document",
    OnlineHelp: "online-help"
} as const;
export type HomeDirectoryType = typeof HomeDirectoryType[keyof typeof HomeDirectoryType];

/** ディレクトリのインターフェイスを提供します。 */
export interface DirectoryItem {
    /** ルートディレクトリを表します。 */
    root: string;
    /** ホームディレクトリを表します。 */
    home: HomeDirectoryType,
    /** サブディレクトリを表します。 */
    sub: string[]
}

/**　S3 ファイルリスト　テーブル項目のインターフェイスを提供します。*/
export interface S3FileListItem {
    /** キー を表します。 */
    readonly key: string;
    /** 名前 を表します。 */
    readonly name: string;
    /** タイプ を表します。 */
    readonly type: S3ItemListType;
    /** サイズ を表します。 */
    readonly size: string;
    /** 最終更新日時 を表します。 */
    readonly lastModified: string;
}

/** ファイル情報を提供します。 */
export interface FileInfo {
    /** キー項目名を表します。 */
    key: string;
    /** ファイルを表します。 */
    file?: File
}

/** S3クライアント Put 結果ファイル情報を提供します。　*/
export interface PutedFileItem {
    /** キー を表します。 */
    key: string;
    /** エラー有無を表します。 */
    isError: boolean;
    /** メッセージ を表します。 */
    message?: string;
}

/** S3クライアント 削除成功ファイル情報を提供します。 */
export interface DeletedFileItem {
    /** キー を表します。 */
    key: string;
    /** ディレクトリ名を表します。 */
    directoryName: string;
    /** バージョンID を表します。 */
    versionId?: string;
    /** コード を表します。 */
    code?: string;
    /** メッセージ を表します。 */
    message?: string;
}


/**
 * MIME タイプを表す列挙値を提供します。
 * refer : https://developer.mozilla.org/ja/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
*/
const MineTypes = {
    ".aac": "audio/aac",
    ".abw": "application/x-abiword",
    ".arc": "application/x-freearc",
    ".avi": "video/x-msvideo",
    ".azw": "application/vnd.amazon.ebook",
    ".bin": "application/octet-stream",
    ".bmp": "image/bmp",
    ".bz": "application/x-bzip",
    ".bz2": "application/x-bzip2",
    ".csh": "application/x-csh",
    ".css": "text/css",
    ".csv": "text/csv",
    ".doc": "application/msword",
    ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".eot": "application/vnd.ms-fontobject",
    ".epub": "application/epub+zip",
    ".gz": "application/gzip",
    ".gif": "image/gif",
    ".htm": "text/html",
    ".html": "text/html",
    ".ico": "image/vnd.microsoft.icon",
    ".ics": "text/calendar",
    ".jar": "application/java-archive",
    ".jpeg": ".jpg",
    ".js": "text/javascript",
    ".json": "application/json",
    ".jsonld": "application/ld+json",
    ".mid": ".midi",
    ".mjs": "text/javascript",
    ".mp3": "audio/mpeg",
    ".mpeg": "video/mpeg",
    ".mpkg": "application/vnd.apple.installer+xml",
    ".odp": "application/vnd.oasis.opendocument.presentation",
    ".ods": "application/vnd.oasis.opendocument.spreadsheet",
    ".odt": "application/vnd.oasis.opendocument.text",
    ".oga": "audio/ogg",
    ".ogv": "video/ogg",
    ".ogx": "application/ogg",
    ".opus": "audio/opus",
    ".otf": "font/otf",
    ".png": "image/png",
    ".pdf": "application/pdf",
    ".php": "application/php",
    ".ppt": "application/vnd.ms-powerpoint",
    ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ".rar": "application/vnd.rar",
    ".rtf": "application/rtf",
    ".sh": "application/x-sh",
    ".svg": "image/svg+xml",
    ".swf": "application/x-shockwave-flash",
    ".tar": "application/x-tar",
    ".tif": "image/tiff",
    ".tiff": "image/tiff",
    ".ts": "video/mp2t",
    ".ttf": "font/ttf",
    ".txt": "text/plain",
    ".vsd": "application/vnd.visio",
    ".wav": "audio/wav",
    ".weba": "audio/webm",
    ".webm": "video/webm",
    ".webp": "image/webp",
    ".woff": "font/woff",
    ".woff2": "font/woff2",
    ".xhtml": "application/xhtml+xml",
    ".xls": "application/vnd.ms-excel",
    ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".xml": "XML",
    ".xul": "application/vnd.mozilla.xul+xml",
    ".zip": "application/zip",
    ".3gp": "video/3gpp",
    ".3g2": "video/3gpp2",
    ".7z": "application/x-7z-compressed"
} as const;
type MineTypes = typeof MineTypes[keyof typeof MineTypes];
