import { Theme, makeStyles, createStyles } from "@material-ui/core";

/** スタイルを提供します。 */
export const useSortHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        // テーブルソートヘッダー
        sortHeader: {
            // ソートされているカラム ヘッダー
            "& .MuiTableSortLabel-active": {
                color: theme.palette.text.primary,
            },
            // ソートアイコンカラー
            "& .MuiTableSortLabel-active .MuiTableSortLabel-icon": {
                color: `${theme.palette.text.primary}!important` as any,
            },
            // material-table スクロールバーをデフォルトに上書き
            "& > div > div > div > div": {
                overflowY: "visible !important",
            },
        }

    })
);
