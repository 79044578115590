import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core'


/** スタイル */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // コンテナ
        root: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
        // コンテナ
        container: {
            display: "flex",
            flexWrap: "wrap",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
        },
        //　タイトル
        title: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        // メニュー
        group: {
            display: "flex",
            alignItems: "center",
        },
        hidden: {
            width: 200,
            visibility: "hidden",
        }
    }),
);

/** ヘッダーのレイアウトを表します。 */
interface Props {
    /** ロゴを表します。 */
    logo: React.ReactNode,
    /** 画面タイトルを表します。 */
    title: React.ReactNode,
    /** エラー情報を表します。 */
    errorPopover: React.ReactNode,
    /** メニューを表します。 */
    menu: React.ReactNode,
}

/**
 * ヘッダーのレイアウトを表します。
 * @param props
 */
const Layout: React.FC<Props> = (props) => {

    const classes = useStyles();

    return (
        <>
            <div style={{ width: '100%' }}>
                <Box display="flex">
                    {/* 左 ロゴ */}
                    <Box className={classes.group}>
                        {props.logo}
                    </Box>

                    {/* 中央 */}
                    <Box className={classes.container}>
                        {/*
                        <div className={classes.hidden}></div>
                        */}
                        <Box component="span" className={classes.title}>
                            {props.title}
                        </Box>
                        <Box component="span">
                            {props.errorPopover}
                        </Box>
                    </Box>

                    {/* 右 メニュー */}
                    <Box className={classes.group}>
                        {props.menu}
                    </Box>
                </Box>
            </div>
        </>

    );
}
export default Layout;
