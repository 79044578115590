import { AreaType, MachineType } from "../../../../_types";
import { InspectedStampItem, ReportItem } from "../../_types";

/** 現場完了時点検記録テーブル項目のインターフェイスを提供します。 */
export interface CompletionInspectionRecordListItem {
    /** 記録表番号 を表します。 */
    readonly no: string;
    /** 完了日時を表します。 */
    readonly completionDate: string;
    /** 工法を表します。 */
    readonly constructionMethod: string;
    /** 作業者を表します。 */
    readonly worker: string;
    /** エリアを表します。 */
    readonly area: string;
    /** エリア種別を表します。*/
    readonly areaType: AreaType;
    /** 作業場所を表します。 */
    readonly site: string;
    /** 報告事項を表します。*/
    readonly report: string;
    /** 検印済みかどうかを表します。*/
    readonly isStamped: boolean;
}

/** 検索種別を表す列挙値を提供します。 */
export enum CompletionInspectionRecordSearchType {
    /** 未指定を表します。 */
    None = 0,
    /** 場所を表します。 */
    Place = 1,
    /** 作業者を表します。 */
    Worker = 2,
    /** 報告事項を表します。 */
    Report = 3,
}

/** 検索種別を表す列挙値を提供します。 */
export enum StampSearchType {
    /** 未指定を表します。 */
    None = 0,
    /** 検印済を表します。 */
    Done = 1,
    /** 未検印を表します。 */
    NotYet = 2,
}

/**
 *  現場完了時点検記録画面の入力項目のインターフェイスを提供します。
 * */
export interface CompletionInspectionRecordInputItem {
    /** 社番を表します。 */
    machineId: string;
    /** 機器種別を表します。 */
    machineType: MachineType;
    /** キーワードを表します。 */
    keyword: string;
    /** 検索種別を表します。 */
    searchType: CompletionInspectionRecordSearchType;
    /** 検印検索種別を表します。 */
    searchStampType: StampSearchType;
    /** 開始日を表します。 */
    beginAt: Date | null;
    /** 終了日を表します。 */
    endAt: Date | null;
}

/**
 * 帳票情報のインターフェイスを提供します。
 * */
export interface CompletionInspectionReportItem extends ReportItem {
    /** 検印情報を表します。 */
    stampItem: InspectedStampItem,
}
