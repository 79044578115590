// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { InspectionDivisionSlice, onExcuteAsync } from '../../../../../modules/slice/inspection-division/inspection-division-slice';
import { InspectionDivisionAdapter } from '../../../../../modules/adapter/inspection-division/inspection-division-adapter'
// type
import { InspectionDivisionInputItem, InspectionDivisionValidateInputItem } from '../../_types';
// component
import InspectionDivisionDetailCondition from './InspectionDivisionDetailCondition'
import { MuiTextFieldUtility } from '../../../../../components/MuiTextField/mui-text-field-utility';

/**
 * 点検区分マスタ　詳細エリアを提供します。
 * */
export const InspectionDivisionDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.inspectionDivisionMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.inspectionDivisionMaster.selectedRowItem);
    const groupList = useSelector((state: RootState) => state.inspectionDivisionMaster.selectBoxItem.InspectionGroup);

    const initialValues: InspectionDivisionInputItem = {
        id: selectedItem?.id,
        groupId: selectedItem?.groupId,
        name: selectedItem?.name,
        weight: selectedItem?.weight,
        lockVersion: selectedItem?.lockVersion,
    }

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<InspectionDivisionValidateInputItem>({});

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<InspectionDivisionInputItem>(initialValues);

    // 行選択時の入力項目更新
    useEffect(() => {
        setValues(preValue => ({
            ...preValue,
            id: selectedItem?.id,
            groupId: selectedItem?.groupId,
            name: selectedItem?.name,
            weight: selectedItem?.weight,
            lockVersion: selectedItem?.lockVersion,
        }))

        // バリデーション情報クリア
        clearValidate();

    }, [selectedItem]);

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof InspectionDivisionInputItem) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? MuiTextFieldUtility.LimitationNumber(Number.parseInt(target.value, 10)) : target.value;


        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = InspectionDivisionAdapter.instance.addAsync(
            { item: values },
            {
                InspectionGroup: groupList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = InspectionDivisionAdapter.instance.updateAsync(
            { item: values },
            {
                InspectionGroup: groupList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        // ダイアログ表示
        dispatch(InspectionDivisionSlice.actions.toggleDialog(true));
    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = () => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(InspectionDivisionSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }

    /**
    * 入力内容のバリデーションを行います。
    * @returns 検証結果を返します。
    * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let inspectionGroup = "";
        let inspectionDivision = "";
        let weight = "";

        // 点検グループ
        if (!values.groupId) {
            inspectionGroup = "点検グループを選択してください";
            result = true;
        }

        // 重み
        if (values.weight == null) {
            weight = "No.を入力してください";
            result = true;
        }

        // 点検区分
        if (!values.name) {
            inspectionDivision = "点検区分を入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            inspectionGroup,
            inspectionDivision,
            weight,
        });

        // 結果
        return result;
    }

    // 表示
    return (
        <InspectionDivisionDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            groupList={groupList}
            onChange={onChange}
            validateError={validate}
        />
    );
};
