import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Paper, ListSubheader, makeStyles, Theme, createStyles, Chip, Button, ListItemIcon, TableContainer, Table, TableBody, TableRow, TableCell, Typography, Box, fade } from '@material-ui/core';
import { Cancel, Delete, CloudUploadOutlined, CloudUpload, NoteAddOutlined, Warning } from '@material-ui/icons';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';
import MuiColorButton from '../../../../../components/Buttons/ColorButton/MuiColorButton';
import { S3FileListItem } from '../../_types';
import clsx from 'clsx';

/** スタイル情報を提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dropzone: {
            marginBottom: theme.spacing(1),
        },
        dropzoneIcon: {
            fontSize: 80,
            color: 'gray'
        },
        paper: {
            minHeight: 250,
            maxHeight: 250,
        },
        list: {
            backgroundColor: theme.palette.background.paper,
        },
        newFile: {
            backgroundColor: `${theme.palette.background.paper}!important` //
        }, 
        updateFile: {
            backgroundColor: `${fade(theme.palette.warning.main, 0.3)}!important` //
        }, 
    }),
);

/** アップロードダイアログの Props を提供します。 */
export interface UploadDialogProps {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** アップロードボタンクリック時の処理を表します。 */
    onClickUpload: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, files: File[]) => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
    /** 既存ファイルリストを表します。 */
    fileList: string[],
};

/** ベーススタイルを表します。 */
const baseStyle: CSSProperties = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#9e9e9e",
    borderStyle: "dashed",
    backgroundColor: "#e0e0e0",
    color: "#424242",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "200px",
};

/** ドロップ可能状態のスタイルを表します。 */
const acceptStyle: CSSProperties = {
    borderColor: "#afeeee",
    backgroundColor: "#e0ffff"
};

/** ドロップ拒否状態のスタイルを表します。 */
const rejectStyle: CSSProperties = {
    borderColor: "#ff7f50",
    backgroundColor: "#ffe4e1"
};

/**
 * アップロードダイアログを表示します。
 * @param props
 */
export function UploadDialog(props: UploadDialogProps): JSX.Element {
    const classes = useStyles();

    /** アップロ―ドファイル */
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);

    /** 許可ファイルのドロップイベントを処理します。*/
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        setUploadFiles((prevState) => {
            const result = [...prevState];
            acceptedFiles.forEach((file, index, self) => {
                const fileIndex = result.findIndex((val, valIndex, valSelf) => val.name.toLowerCase() == file.name.toLowerCase());
                if (fileIndex < 0) {
                    result.push(file);
                } else {
                    result.splice(fileIndex, 1, file);
                }
            });
            return [...result.slice(0, 10)];
        });
    }, [uploadFiles]);

    /** ドロップゾーンのカスタムコンポーネントを表します。 */
    const {getRootProps,　getInputProps, isDragAccept,　isDragReject,　open} = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDropAccepted,
        accept: {
            //'image/jpeg': [],
            //'image/png': [],
            'application/pdf': [],
        },
        //maxFiles: 10,
        maxSize: 5000000, // 5MB
        multiple: true,
    });

    /** スタイル情報をメモ化します。 */
    const style = useMemo<CSSProperties>(() =>
        ({
            ...baseStyle,
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isDragReject, isDragAccept]
    );

    /** ファイルを削除します。 */
    const removeFile = (file: File) => {
        const newFiles = [...uploadFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setUploadFiles(newFiles)
    }

    /** 全てのファイルを削除します。*/
    const removeAllFiles = () => {
        setUploadFiles([]);
    }

    /** ダイアログを閉じるときの処理を行います。 */
    const onClose = () => {
        removeAllFiles();
        props.handleClose();
    };

    /** アップロードイベント処理を行います。 */
    const onUpload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.onClickUpload(event, uploadFiles);
        removeAllFiles();
    };

    /** ファイルリストを表示します。 */
    const renderFileList = React.useMemo(() => (
            <Table size="small">
                <TableBody>
                    {uploadFiles.map((file, index) => {
                        {/* 新規 または 更新 判定 */ }
                        const isUpdate = props.fileList.includes(file.name.toLowerCase());
                        return (
                            <TableRow key={index} hover={false} className={clsx(isUpdate ? classes.updateFile : classes.newFile )}>
                                <TableCell style={{ width: 80 }}>
                                    {isUpdate
                                        ? <Chip size="small" label="上書き" style={{ backgroundColor: '#FFC107' }} icon={<Warning />} />
                                        : <Chip size="small" label="新規" color="secondary" variant="outlined" icon={<NoteAddOutlined />} />}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {file.name}
                                </TableCell>
                                <TableCell style={{ width: 50 }}>
                                    <IconButton size="small" edge="end" aria-label="delete" onClick={() => removeFile(file)}>
                                        <Cancel fontSize="inherit" />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                            );
                    })}

                </TableBody>
            </Table>

        ), [removeFile, uploadFiles, props.fileList])

    return (
        <Dialog
            disableBackdropClick
            keepMounted
            fullWidth
            maxWidth="lg"
            aria-labelledby="upload-dialog-title"
            aria-describedby="upload-dialog-description"
            open={props.isOpen}
            onClose={props.handleClose}
        >
            <DialogTitle id="upload-dialog-title">ファイルアップロード</DialogTitle>
            <DialogContent>
                <DialogContentText id="upload-dialog-description">
                    アップロードするファイル選択します。
                    <br />
                    1ファイルあたり最大5MBまで、同時に最大10個までアップロードできます。
                    <br />
                    アップロード出来るファイルは<em>.pdf</em>です。
                </DialogContentText>

                <section className={classes.dropzone}>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <div style={{ textAlign: "center" }}>
                            <CloudUploadOutlined className={classes.dropzoneIcon} />
                            <h6>ここにファイルをドロップ
                            <br />または
                            </h6>
                            <Button variant="outlined" color="primary" onClick={open}>
                                ファイルを選択
                            </Button>
                        </div>
                    </div>
                </section>

                <Box fontSize="h6.fontSize">選択ファイル {uploadFiles.length}個</Box>
                <Typography color="error">既に同名ファイルが存在する場合は、上書き保存します。</Typography>
                <TableContainer component={Paper} className={classes.paper}>
                    {/* 選択ファイル一覧 */}
                    {renderFileList}
                </TableContainer>

            </DialogContent>

            {/* ボタンアクション */}
            <DialogActions>
                <MuiCancelButton
                    // className={classes.button}
                    onClick={onClose}
                />
                <MuiColorButton
                    overrideColor="warning"
                    variant="contained"
                    startIcon={<CloudUpload />}
                    disabled={(uploadFiles.length < 1)}
                    // onClick={(e) => props.onClickUpload(e, uploadFiles)}
                    onClick={(e) => onUpload(e)}
                >
                    アップロード
                </MuiColorButton>
            </DialogActions>

        </Dialog>
    );
}
