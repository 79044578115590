//react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeRecordPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { MachineStatusMonitoringSlice, onGetReloadAsync } from '../../../modules/slice/machine-status-monitoring/machine-status-monitoring-slice';
// components
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { MachineStatusMonitoringMainContainer } from './components/MainContent/MachineStausMonitoringMainContainer';
import { AppTitle } from '../../Shared/app-title';

/**
 * 施工機ステータス画面
 */
export const MachineStatus: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();


    // redux store
    const isLoading = useSelector((state: RootState) => state.machineStatusMonitoring.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.machineStatusMonitoring.isShowMessage);
    const message = useSelector((state: RootState) => state.machineStatusMonitoring.message);
    const machine = useSelector((state: RootState) => state.globalHeader.machine)

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(MachineStatusMonitoringSlice.actions.hideMessage());
    };

    useEffect(() => {
    }, [dispatch]);


    useEffect(() => {
        dispatch(changeRecordPageHeader(AppTitle.recordMachineStatus.title));

        if (machine) {
            // 初期表示時に実行
            // 定期的に施工機の一覧取得を行う。
            dispatch(onGetReloadAsync({ id: machine.item.id }))
        }

        // unmout 時にタイマークリア
        return () => {
            dispatch(MachineStatusMonitoringSlice.actions.stopReloadTimer());
        }

    }, [dispatch, machine]);

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />


            {/*メイン画面*/}
            <MachineStatusMonitoringMainContainer />
        </>
    );
};
