/**
 * アプリケーションの URL を提供します。
 *
 */
export class AppUrl {

    //#region フィールド

    /** ルートを表します。 */
    public static readonly root = new AppUrl("/");

    /** [Account] サインインを表します。 */
    public static readonly accountSignIn = new AppUrl("/signin");
    ///** [Account] サインアウトを表します。 */
    //public static readonly accountSignOut = new AppUrl("/Account/SignOut");

    /** [Home] 施工機管理状況一覧(Home)を表します。 */
    public static readonly machineMonitoring = new AppUrl("/construction-machine-monitor");

    /** [Home] その他報告を表します。 */
    public static readonly otherReport = new AppUrl("/other-report");

    /** [Home] ファイルアップロードを表します。 */
    public static readonly fileManage = new AppUrl("/file-manage");

    /** [Master] ルートを表します。 */
    public static readonly master = new AppUrl("/master");
    /** [Master] 施工機マスタを表します。 */
    public static readonly masterMachine = new AppUrl("/master/machine");
    /** [Master] 型式マスタを表します。 */
    public static readonly masterModel = new AppUrl("/master/model");
    /** [Master] メーカーマスタを表します。 */
    public static readonly masterManufacturer = new AppUrl("/master/manufacturer");
    /** [Master] 工法マスタを表します。 */
    public static readonly masterMethod = new AppUrl("/master/method");
    /** [Master] 整備区分マスタを表します。 */
    public static readonly masterMaintenanceDivisioin = new AppUrl("/master/maintenance-division");
    /** [Master] 整備項目マスタを表します。 */
    public static readonly masterMaintenanceItem = new AppUrl("/master/maintenance-items");
    /** [Master] 特記事項マスタを表します。 */
    public static readonly masterRemarks = new AppUrl("/master/remarks");
    /** [Master] 点検区分マスタを表します。 */
    public static readonly masterInspectionDivision = new AppUrl("/master/inspection-division");
    /** [Master] 点検項目マスタを表します。 */
    public static readonly masterInspectionItem = new AppUrl("/master/inspection-items");
    /** [Master] ユーザーマスタを表します。 */
    public static readonly masterUser = new AppUrl("/master/user");

    /** [Record] ルートを表します。 */
    public static readonly record = new AppUrl("/construction-machine-master");
    /** [Record] 整備記録を表します。 */
    public static readonly recordMaintenanceRecord = new AppUrl("/construction-machine-master/maintenance");
    /** [Record] 日常点検記録を表します。 */
    public static readonly recordDailyInspectionRecord = new AppUrl("/construction-machine-master/daily-inspection");
    /** [Record] 週間月例点検記録を表します。 */
    public static readonly recordWeeklyInspectionRecord = new AppUrl("/construction-machine-master/weekly-inspection");
    /** [Record] 現場完了時点検記録を表します。 */
    public static readonly recordCompletionInspectionRecord = new AppUrl("/construction-machine-master/completion-inspection");
    /** [Record] 施工記録を表します。 */
    public static readonly recordConstructionRecord = new AppUrl("/construction-machine-master/construction");
    /** [Record] 施工機ステータスを表します。 */
    public static readonly recordMachineStatus = new AppUrl("/construction-machine-master/status");
    /** [Record] 施工機メンテナンス設定を表します。*/
    public static readonly recordMachineMaintenanceSetting = new AppUrl("/construction-machine-master/machine-maintenance-setting");
    /** [Record] アラート記録を表します。 */
    public static readonly recordAlertRecord = new AppUrl("/construction-machine-master/alert");

    /** パスを表します。  */
    public readonly path: string = "" // UE.String.empty;

    //#endregion フィールド

    //#region メソッド

    /**
     * コンストラクター
     *
     * @constructor
     * @param path パスを指定します。
     * @throws {UE.ArgumentNullOrWhiteSpaceError} `path` が `null` (未定義) または空白の場合に発生します。
     */
    private constructor(path: string) {
        if (!path) {//if(UE.Validator.isNullOrWhitespace(path)) {
            throw new Error("path") // UE.ArgumentNullOrWhiteSpaceError("path");
        }
        this.path = path;
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
