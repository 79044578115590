// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeRecordPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { StatusEditSlice, onGetReloadAsync } from '../../../modules/slice/status-edit/status-edit-slice';
// 共通component
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
// component
import { StatusEditMainContainer } from './components/MainContent/StatusEditMainContainer';
import { EditDialogContainer } from './components/EditDialog/EditDialogContainer';
import { HistoryDialogContainer } from './components/HistoryDialog/HistoryDialogContainer';
import { AppTitle } from '../../Shared/app-title';

/**
* ステータス編集画面を表示します。
* */
export const StatusEditPage: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.statusEdit.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.statusEdit.isShowMessage);
    const message = useSelector((state: RootState) => state.statusEdit.message);
    const machine = useSelector((state: RootState) => state.globalHeader.machine)

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(StatusEditSlice.actions.hideMessage());
    };


    useEffect(() => {
        dispatch(changeRecordPageHeader(AppTitle.recordMachineMaintenanceSetting.title));

        if (!machine) {

            dispatch(StatusEditSlice.actions.showErrorMessage("施工機情報が指定されていません"));
        } else {

            dispatch(onGetReloadAsync({ id: machine.item.id }))
        }

    }, [dispatch, machine]);

    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* メイン */}
            <StatusEditMainContainer />

            {/* ダイアログ */}
            <EditDialogContainer />
            <HistoryDialogContainer />

        </>

    );
};
