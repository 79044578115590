// react
import * as React from 'react';
import clsx from 'clsx';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
// component
import MuiUpdateButton from '../../../components/Buttons/MuiUpdateButton';
import MuiAddButton from '../../../components/Buttons/MuiAddButton';
import MuiRemoveButton from '../../../components/Buttons/MuiRemoveButton';
import MuiCancelButton from '../../../components/Buttons/MuiCancelButton';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 更新時のボタンボックス
        updateBox: {
            display: "flex",
            flexWrap: "wrap",
        },
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },
        // 削除ボタン
        remove: {
            marginLeft: "auto",
        },
    }),
);

/** マスタ系　詳細項目のボタングループの Props を提供します。 */
interface Props {
    /** 新規作成状態かどうかを表します。 */
    isCreating: boolean,
    /** 追加ボタンのクリック処理を行います。 */
    onClickAdd: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 更新ボタンのクリック処理を行います。 */
    onClickUpdate: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 削除ボタンのクリック処理を行います。 */
    onClickRemove: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** キャンセルボタンのクリック処理を行います。 */
    onClickCancel: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
    /** 更新不可かどうかを表します。 */
    isNonEditabled?: boolean,
};

/** マスタ系　詳細項目のボタングループを表示します。 */
const MasterDetailButtonGroup: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <>
            {/* ボタングループ */}
            {
                props.isCreating
                    ? (
                        <Box>
                            {/* 追加 */}
                            <MuiAddButton
                                className={classes.button}
                                onClick={(event) => props.onClickAdd(event)}
                            />
                            {/* キャンセル */}
                            <MuiCancelButton
                                className={classes.button}
                                onClick={(event) => props.onClickCancel(event)}
                            />
                        </Box>
                    )
                    : (
                        <Box className={classes.updateBox}>
                            {/* 更新 */}
                            {props.isNonEditabled
                                ? null
                                : <MuiUpdateButton className={classes.button} onClick={(event) => props.onClickUpdate(event)} />
                            }

                            {/* 削除 */}
                            <MuiRemoveButton
                                className={clsx(classes.button, classes.remove)}
                                onClick={(event) => props.onClickRemove(event)}
                            />
                        </Box>
                    )
            }

        </>
    )
}


export default MasterDetailButtonGroup;
