// redux
import { Dispatch } from 'redux';
// shared
import { RecordsSliceState, createReportListSlice } from '../shared/create-records-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { changeError } from '../global-header/global-header-slice';
// type
import { CompletionInspectionRecordAdapter } from '../../adapter/completion-inspection-record/completion-inspection-record-adapter';
import { CompletionInspectionRecordGetRequest, CompletionInspectionRecordGetResult, CompletionInspectionRecordGetDocumentRequest, CompletionInspectionRecordGetDocumentResult } from '../../adapter/completion-inspection-record/types';
import { CompletionInspectionRecordListItem, CompletionInspectionReportItem } from '../../../pages/ConstructionMachineMaster/CompletionInspectionRecord/_types';
import { CompletionReportRequest, UpdateCompletionReportRequest } from '../../adapter/report/type';
import { ReportAdapter } from '../../adapter/report/report-adapter';


// State の初期値を設定します。
const initialState: RecordsSliceState<CompletionInspectionRecordListItem, CompletionInspectionReportItem> = {
    isLoading: false,
    isShowMessage: false,
    isOpenViewer: false,
    isUpdated: false,
    isReportLoading: false,
}

/** 現場完了時点検記録 Slice を 提供します。*/
export const CompletionInspectionRecordSlice = createReportListSlice({
    // State Reducer Action を生成
    name: 'CompletionInspectionRecord',
    initialState: initialState,
    reducers: {},
})

/**
 * 一覧取得処理を行います。
 * @param parameter 要求情報を指定します。
 */
export const onGetAsync = (parameter: CompletionInspectionRecordGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(CompletionInspectionRecordSlice.actions.fetchStart());

        // 通信処理
        const getResultWaiter = CompletionInspectionRecordAdapter.instance.getAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.item.machineId });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, machineErrorResultWaiter]);
        const getResult = promiseResult[0];
        const machineErrorResult = promiseResult[1];

        // 通信終了
        if (getResult.isError) {
            // 異常
            dispatch(CompletionInspectionRecordSlice.actions.fetchFailure());
            // メッセージ
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));

        } else if (machineErrorResult.isError) {
            // 異常
            // メッセージ
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(machineErrorResult.errorMessage ?? ""));

        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 正常
            dispatch(CompletionInspectionRecordSlice.actions.setItems(getResult.items));
            // メッセージ
            dispatch(CompletionInspectionRecordSlice.actions.showSuccessMessage());

        }

    } catch (error) {

        //例外
        dispatch(CompletionInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(CompletionInspectionRecordSlice.actions.fetchEnd());
    }
}

/**
 * 帳票取得処理を行います。
 * @param parameter パラメーターを指定します。
 */
export const onGetCompletionReportAsync = (parameter: CompletionReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(CompletionInspectionRecordSlice.actions.fetchStart());
        dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(true));

        // 通信処理
        const result = await ReportAdapter.instance.getCompletionReport(parameter);

        // 通信終了
        if (result.isError || result.stampItem == null) {
            // 異常
            // メッセージ
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // メッセージ
            dispatch(CompletionInspectionRecordSlice.actions.showSuccessMessage());

            // 正常
            dispatch(CompletionInspectionRecordSlice.actions.setReport(
                {
                    reportUrl: result.reportUrl,
                    stampItem: result.stampItem,
                }
            ));
            dispatch(CompletionInspectionRecordSlice.actions.toggleViewre(true));
        }

    } catch (error) {

        //例外
        dispatch(CompletionInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(`${error}`));
        dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(CompletionInspectionRecordSlice.actions.fetchEnd());
    }
}

/**
 * 検印更新処理を行います。
 * @param updateParam 更新パラメーターを指定します。
 * @param getParam 取得パラメーターを指定します。
 */
export const onUpdateCompletionInspectedStampAsync = (updateParam: UpdateCompletionReportRequest, getParam: CompletionReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(CompletionInspectionRecordSlice.actions.fetchStart());
        dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(true));

        // 更新フラグ OFF
        dispatch(CompletionInspectionRecordSlice.actions.changeUpdatedFlag(false));

        // URL クリア
        dispatch(CompletionInspectionRecordSlice.actions.clearReport());

        // 通信処理
        const result = await ReportAdapter.instance.updateCompletionInspectedStamp(updateParam);

        // 通信終了
        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));

            dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // 通信処理
            const getResult = await ReportAdapter.instance.getCompletionReport(getParam);

            // 通信終了
            if (getResult.isError || getResult.stampItem == null) {
                // 異常
                // メッセージ
                dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));

                dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(false));

            } else {
                // メッセージ
                dispatch(CompletionInspectionRecordSlice.actions.showSuccessMessage());

                // 正常
                dispatch(CompletionInspectionRecordSlice.actions.setReport(
                    {
                        reportUrl: getResult.reportUrl,
                        stampItem: getResult.stampItem,
                    }
                ));
                dispatch(CompletionInspectionRecordSlice.actions.changeUpdatedFlag(true));
            }
        }

    } catch (error) {

        //例外
        dispatch(CompletionInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(CompletionInspectionRecordSlice.actions.showErrorMessage(`${error}`));

        dispatch(CompletionInspectionRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(CompletionInspectionRecordSlice.actions.fetchEnd());
    }
}
