// react
import * as React from 'react';
// material-ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import amber from '@material-ui/core/colors/amber';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            backgroundColor: amber[500],
            color: '#fff',
            "&:hover": {
                backgroundColor: '#b28704',
            },
            "&:disabled": {
                backgroundColor: '#ffcd38',
            }
        }
    }),
);

/**
 *  ロック解除ボタンを表示します。
 * @param props
 */
const MuiUnlockButton: React.FC<ButtonProps> = (props) => {
    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (

        <Button
            variant="contained"
            classes={{ root: classes.root }}
            startIcon={<LockOpenIcon />}
            onClick={props.onClick}
            {...props}
        >
            ロック解除
        </Button>
    )
}

export default MuiUnlockButton;
