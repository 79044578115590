import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { onReset, onCloseDialog } from '../../../../../modules/slice/global-header/global-header-slice';
import { PageType } from '../../../../../modules/slice/global-header/global-header-slice';
import ResetDialog from './ResetDialog';

/**
 * リセットダイアログを表示します。
 * @param props
 */
export const ResetDialogContainer: React.FC<{}> = () => {
    const dispatch = useDispatch();

    // redux store
    const isOpenDialog = useSelector((state: RootState) => state.globalHeader.isOpenDialog);
    const machineIds = useSelector((state: RootState) => state.globalHeader.ctuErrorMachine.errorMachineIds);
    const pageType: PageType = useSelector((state: RootState) => state.globalHeader.pageType);
    const inputItem = useSelector((state: RootState) => state.machineMonitoring.item.inputItem);

    /**
     * リセットボタンクリック処理を行います。
     * */
    const onResetClick = () => {
        dispatch(onReset(pageType, { item: inputItem }));
    }

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        dispatch(onCloseDialog(pageType, { item: inputItem }));
    };

    return (
        <ResetDialog
            isOpen={isOpenDialog}
            machineIds={machineIds}
            handleClose={closeDialog}
            onResetClick={onResetClick}
        />
    );
}
