import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';
import ReplyIcon from '@material-ui/icons/Reply';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // チップ
        chip: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: deepOrange[700],
        },
        // アイコン
        icon: {
            color: theme.palette.secondary.contrastText,
        }
    })
);


/**
 * 差し戻しステータスのアイコンを表示します。
 */
const SendBackTag: React.FC<{}> = () => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Chip
            size="small"
            className={classes.chip}
            label={"差し戻し"}
            icon={
                <ReplyIcon className={classes.icon} />
            }
        />
    );
}

export default SendBackTag;
