// react
import * as React from 'react';
// redux
import { useDispatch } from 'react-redux';
import { onGetAsync, MachineMonitoringSlice } from '../../../../../modules/slice/machine-monitoring/machine-monitoring-slice';
// component
import { MachineMonitoringInputItem } from '../../_types';
import ConstructionMachineMonitorSearch from './ConstructionMachineMonitorSearch';

/** 施工機モニター画面　検索エリアを表示します。 */
export const ConstructionMachineMonitorSearchContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    /** 検索ボタンのクリック処理を行います。 */
    const onSearchClickAsync = async (inputItem: MachineMonitoringInputItem) => {
        dispatch(MachineMonitoringSlice.actions.changeInputItem(inputItem));
        // 一覧取得
        await dispatch(onGetAsync({ item: inputItem }));

    };

    return (
        <ConstructionMachineMonitorSearch
            onSearchClick={onSearchClickAsync}
        />
    );
};
