// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme, fade } from '@material-ui/core/styles';
import MaterialTable, { Column } from 'material-table';
// 共通component
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
import { MasterTableOptions } from '../../../Shared/MasterTableOptions';
import MasterTableFrame from '../../../Shared/MasterTableFrame';
// type
import { UserListItem } from '../../_types';
import { UserItem } from '../../../../../_types';
import SigninUserTag from './SigninUserTag';

/** ユーザー マスタテーブルの Props を提供します。*/
interface Props {
    /** データソースを表します。 */
    dataSource: UserListItem[],
    /** 行選択のクリックイベントを表します。 */
    onRowClick: (rowData: UserListItem) => void,
    /** 選択中の行データを表します。 */
    selectedRow?: UserListItem,
    /** サインインユーザー情報を表します。 */
    userItem?: UserItem,
}

/** ユーザーマスタテーブルの State を提供します。*/
interface State {
    /** 選択中の行データを表します。 */
    selectedRow?: UserListItem,
}


/** ユーザーマスタテーブルを提供します */
const UserResultTable: React.FC<Props> = (props) => {

    /** Theme を提供します。*/
    const theme = useTheme();

    /** ユーザーテーブルのカラムを表します。 */
    const columns: Column<UserListItem>[] = [
        {
            title: "",
            field: "",
            width: 50,
            render: (rowData: UserListItem) => (props.userItem?.id === rowData.id && <SigninUserTag />),
            sorting: false,
        },
        { title: "ユーザー id", field: "id" },
        { title: "ユーザー名", field: "user" },
        { title: "検印文字", field: "inspectorName" },

    ];
    /** State を提供します。 */
    const [values, setValues] = useState<State>({
        selectedRow: void 0,
    });

    useEffect(() => {
        setValues({ selectedRow: props.selectedRow });
    }, [props.selectedRow])

    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const handleRowClick = (rowData: UserListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを保持
        setValues({ ...values, selectedRow: rowData });

        // Prop 実行
        props.onRowClick(rowData);

    };

    return (
        <MasterTableFrame>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={{
                    ...MasterTableOptions,
                    rowStyle: (rowData, index) => {
                        let color = "";
                        if (values.selectedRow?.id === rowData.id) color = fade(theme.palette.secondary.main, 0.5); // 選択された行の背景色

                        return {
                            whiteSpace: "nowrap",
                            backgroundColor: color,
                        }
                    }
                }}
                columns={columns}
                data={props.dataSource}
                onRowClick={(event, selectedRow) => {
                    selectedRow && handleRowClick(selectedRow, event)
                }}
            />
        </MasterTableFrame>

    );

};

export default UserResultTable;
