import { AccountClient, SignInInput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { SignInRequest, SignInResult } from './types';

/**
 * `Account` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class AccountAdapter extends WebApiAdapter<AccountClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new AccountAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * サインインします。
     *
     * @param parameter パラメーターを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async signInAsync(parameter: SignInRequest): Promise<SignInResult> {
        function createErrorResult(message?: string): SignInResult {
            return {
                errorMessage: message == null ? WebApiAdapter.messageFailedCommunication : message,
            };
        }
        try {
            // サインイン
            const input = new SignInInput();
            input.init({
                userId: parameter.userId,
                password: parameter.password,
            });
            const signInOutput = await this.client.signIn(input, void 0);
            if (signInOutput == null) {
                return createErrorResult();
            }
            else if (signInOutput.errorMessage != null) {
                return createErrorResult(signInOutput.errorMessage);
            }

            // トークン取得
            const tokenOutput = await this.client.getToken();
            if (tokenOutput == null) {
                return createErrorResult();
            }
            else if (tokenOutput.isError === true) {
                return createErrorResult(tokenOutput.errorMessage);
            }

            // トークン保存
            if (tokenOutput.token != null) {
                localStorage.setItem(AccountClient.antiForgeryLocalStorageKey, tokenOutput.token);
            }

            // 正常
            const result: SignInResult = {
                returnUrl: signInOutput.returnUrl
            };
            return result;
        }
        catch {
            return createErrorResult();
        }
    }


    /**
     * サインアウトします。
     * */
    public async signOutAsync(): Promise<void> {
        // async/await対応
        return new Promise((resolve, reject) => {
            // トークン削除
            localStorage.removeItem(AccountClient.antiForgeryLocalStorageKey);
            resolve();
        });
    }


    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new AccountClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
