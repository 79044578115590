// react
import * as React from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
        },
        // IP アドレス情報
        ipAddress: {
            fontSize: "1.25rem",
            margin: "0 0.5rem",
        },
    }),
);

/** IP アドレス情報 の Props を提供します。 */
interface Props {
    /** IP アドレスを表します。 */
    ipAddress: string,
};

/** IPアドレス を表示します。 */
const IpAddressInformation: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography>
                IP Address：
            </Typography>
            <Typography className={classes.ipAddress}>
                {props.ipAddress}
            </Typography>
        </Box>
    );

};

export default IpAddressInformation;
