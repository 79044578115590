// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
// type
import { OtherReportListItem } from '../../_types';
// component
import { ReportImageGallery } from "../ReportImageGallery/ReportImageGallery";


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ヘッダー
        header: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        // タイトル
        title: {
            margin: 0,
            padding: theme.spacing(2),
        },
        // 閉じるボタンアイコン
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        // 項目名
        itemColumn: {
            width: 150,
        },
        // ダウンロードボタン
        button: {
            marginBottom: theme.spacing(1),
        }
    }),
);

/** その他報告詳細ダイアログの Props を提供します。*/
interface Props {
    /** 報告内容を表します。 */
    selectedRowItem: OtherReportListItem | null;
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** 閉じる処理を表します。 */
    handleClose: () => void;
    /** ダウンロード処理を表します。 */
    onDwonloadClick: () => void;
}


/**
 *  その他報告詳細ダイアログを表示します。
 * @param props
 */
const ReportDetailDialog: React.FC<Props> = (props: Props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** State */
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])


    return (
        <Dialog
            keepMounted
            disableBackdropClick
            fullWidth
            maxWidth="md"
            aria-labelledby="report-dialog-title"
            open={open}
            onClose={props.handleClose}
        >
            {/* タイトル*/}
            <DialogTitle disableTypography className={classes.title}>
                <Typography variant="h6">報告管理 No. {props.selectedRowItem?.no}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {/* コンテンツ */}
            <DialogContent dividers>

                {/* 日時 */}
                <Grid container spacing={3}>
                    <Grid item className={classes.itemColumn}>
                        <Typography >
                            日時
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {props.selectedRowItem?.reportAt}
                        </Typography>
                    </Grid>
                </Grid>

                {/* 報告者 */}
                <Grid container spacing={3}>
                    <Grid item className={classes.itemColumn}>
                        <Typography>
                            報告者
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {props.selectedRowItem?.reporter}
                        </Typography>
                    </Grid>
                </Grid>

                {/* 所属 */}
                <Grid container spacing={3}>
                    <Grid item className={classes.itemColumn}>
                        <Typography>
                            所属
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {props.selectedRowItem?.area}
                        </Typography>
                    </Grid>
                </Grid>

                {/* 作業場所 */}
                <Grid container spacing={3}>
                    <Grid item className={classes.itemColumn}>
                        <Typography>
                            作業場所
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {props.selectedRowItem?.site}
                        </Typography>
                    </Grid>
                </Grid>

                {/* 報告事項 */}
                <Grid container spacing={3}>
                    <Grid item className={classes.itemColumn}>
                        <Typography>
                            報告事項
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography component={"span"}>
                            {/* 改行を含む */}
                            {props.selectedRowItem?.item && props.selectedRowItem.item.split("\n").map((text, index) => {
                                return <div key={index}>{text}</div>
                            })}
                        </Typography>
                    </Grid>
                </Grid>

                {/* 画像 */}
                <Grid container spacing={3}>
                    <Grid item className={classes.itemColumn}>
                        <Typography>
                            添付画像
                        </Typography>
                    </Grid>
                    <Grid item xs>




                        {props.selectedRowItem?.imagePaths.length
                            ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<GetAppIcon />}
                                        onClick={props.onDwonloadClick}
                                        className={classes.button}
                                    >
                                        画像ダウンロード
                                    </Button>
                                    <ReportImageGallery
                                        imagePaths={props.selectedRowItem.imagePaths}
                                    />
                                </>
                            )
                            : (

                                <Typography>
                                    添付画像なし
                                </Typography>

                            )
                        }
                    </Grid>
                </Grid>

            </DialogContent>

        </Dialog>
    );
}
export default ReportDetailDialog;
