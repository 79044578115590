// react
import * as React from 'react';
import { useState } from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
// type
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import MuiKeybordDatePicker from '../../../../../components/MuiPickers/MuiKeybordDatePicker';
import { MuiDatePickerUtility } from '../../../../../components/MuiPickers/mui-date-picker-utility';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // 日付の間の '～'
        toFrom: {
            display: "flex",
            writingMode: "vertical-lr",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        // ボタン
        button: {
            marginTop: theme.spacing(2),
        },
    }),
);

/** 施工記録画面　検索エリアの State を表示します。 */
export interface ConstructionRecordSerachState {
    /** 開始日を表します。 */
    beginAt: Date | null;
    /** 終了日を表します。 */
    endAt: Date | null;
}

/** 施工記録画面　検索エリアの Props を表示します。 */
interface Props {
    /** 検索ボタンのクリック処理を表します。 */
    onSearchClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: ConstructionRecordSerachState) => void
}

/**
 * Stateの初期値を設定します
 * */
const initState = (): ConstructionRecordSerachState => {

    const beginAt = new Date();
    beginAt.setMonth(beginAt.getMonth() - 1);

    const result: ConstructionRecordSerachState = {
        beginAt: beginAt,
        endAt: new Date(),
    }

    return result;
}


/** 施工記録画面 検索エリアを提供します。*/
const ConstructionRecordSearch: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** useState を提供します。*/
    const [values, setValues] = useState<ConstructionRecordSerachState>(initState());

    /**
     *  開始日の変更時の処理を行います。
     * @param newValue
     */
    const onChangeBeginAt = (newValue: Date | null) => {
        // 時間を ゼロ(00:00:000) に変換
        if (newValue != null) newValue.setHours(0, 0, 0, 0);

        const compEndAt = values?.endAt?.getTime() ?? 0
        const compBeginAt = newValue?.getTime() ?? 0

        setValues(preValues => (
            {
                ...preValues,
                beginAt: newValue,
                endAt: (compBeginAt > compEndAt) ? null : values.endAt, // 入力済の終了日より後の場合は、終了日を null に設定
            }
        ))
    };

    /**
     *  終了日の変更時の処理を行います。
     * @param newValue
     */
    const onChangeEndAt = (newValue: Date | null) => {
        // 時間を ゼロ(00:00:000) に変換
        if (newValue != null) newValue.setHours(0, 0, 0, 0);

        const compBeginAt = values?.beginAt?.getTime() ?? 0
        const compEndAt = newValue?.getTime() ?? 0

        setValues(preValues => (
            {
                ...preValues,
                beginAt: (compEndAt !== 0 && compBeginAt > compEndAt) ? null : values.beginAt, // 入力済みの開始日より前の場合は、開始日を null に設定
                endAt: newValue,
            }
        ))
    };

    return (
        <>
            <FormControl component="fieldset">
                <Box fontWeight="fontWeightBold">
                    日付
                </Box>

                {/* 開始日 */}
                <MuiKeybordDatePicker
                    className={classes.inputItem}
                    label="開始日"
                    disableToolbar
                    format={MuiDatePickerUtility.dateFormat}
                    placeholder={MuiDatePickerUtility.dateFormat}
                    value={values.beginAt}
                    onChange={date => onChangeBeginAt(date)}
                />

                <div className={classes.toFrom}>～</div>

                {/* 終了日 */}
                <MuiKeybordDatePicker
                    className={classes.inputItem}
                    label="終了日"
                    color="secondary"
                    size="small"
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    format="yyyy 年 MM 月 dd 日"
                    placeholder="yyyy 年 MM 月 dd 日"
                    value={values.endAt}
                    onChange={date => onChangeEndAt(date)}
                />

                {/* 検索ボタン */}
                <MuiSearchButton
                    fullWidth
                    className={classes.button}
                    onClick={(event) => props.onSearchClick(event, values)}
                />
            </FormControl>
        </>
    );

};

export default ConstructionRecordSearch;
