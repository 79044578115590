import { SharedClient, KeyValuePairOfIntegerAndString, KeyValuePairOfIntegerAndIEnumerableOfKeyValuePairOfIntegerAndString, ErrorOutput, GetModelOutput, GetManufacturerOutput, GetMachineTypeOutput, GetMaintenanceDivisionOutput, GetInspectionGroupOutput, GetInspectionDivisionOutput, GetMachineErrorInput, GetMachineErrorOutput, CheckPasswordInput, GetCtuOutput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { KeyValueItemsResult, KeyValueItemsListResult, MachineErrorRequest, MachineErrorResult, CheckPasswordRequest, CheckPasswordResult, AllMachineErrorResult, AuthenticatedUserResult, CtuResult } from './types';
import { SharedUtility } from './shared-utility';

/** 要求種別の列挙値を提供します。 */
enum RequestType {
    /** 型式を表します。 */
    Model,
    /** メーカーを表します。 */
    Manufacturer,
    /** 施工機種別を表します。 */
    MachineType,
    /** 整備区分を表します。 */
    MaintenanceDivision,
    /** 点検グループを表します。 */
    InspectionGroup,
    /** 点検区分を表します。 */
    InspectionDivision,
    /** CTU 番号 */
    CtuId,
}

/**
 * `共通` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class SharedAdapter extends WebApiAdapter<SharedClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new SharedAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createErrorItemsResult(message?: string): KeyValueItemsResult {
        return {
            items: [],
            isError: true,
            errorMessage: message,
        };
    }

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createErrorItemsListResult(message?: string): KeyValueItemsListResult {
        return {
            items: [],
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createMachineErrorItemErrorResult(message?: string): MachineErrorResult {
        return {
            item: "",
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createAllMachineErrorItemErrorResult(message?: string): AllMachineErrorResult {
        return {
            errorMachineIds: [],
            isError: true,
            errorMessage: message,
        };
    }


    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createCheckPasswordItemErrorResult(message?: string): CheckPasswordResult {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createAuthenticatedUserErrorResult(message?: string): AuthenticatedUserResult {
        return {
            isError: true,
            errorMessage: message,
        };
    }

    /**
    * エラーを表す結果情報を作成します。
    *
    * @param message エラーメッセージを指定します。
    * @returns 結果情報を返します。
    */
    private createCtuErrorResult(message?: string): CtuResult {
        return {
            items: [],
            maintenanceItems: [],
            isError: true,
            errorMessage: message,
        };
    }


    /**
     * 正常を表す結果情報を作成します。
     *
     * @param items 項目一覧を指定します。
     * @returns 結果情報を返します。
     */
    private createItemsResult(items?: KeyValuePairOfIntegerAndString[]): KeyValueItemsResult {
        const result: KeyValueItemsResult = {
            isError: false,
            items: SharedUtility.toKeyValueItems(items),
        };
        return result;
    }

    /**
     * 正常を表す結果情報を作成します。
     *
     * @param items 項目一覧を指定します。
     * @returns 結果情報を返します。
     */
    private createItemsListResult(items?: KeyValuePairOfIntegerAndIEnumerableOfKeyValuePairOfIntegerAndString[]): KeyValueItemsListResult {
        const result: KeyValueItemsListResult = {
            isError: false,
            items: SharedUtility.toKeyValueItemsList(items),
        };
        return result;
    }

    /**
     * 一覧を取得します。
     *
     * @param requestType 要求種別を指定します。
     * @param createResultFunc 通信結果情報から戻り値を作成する処理を指定します。
     * @param createErrorResultFunc エラー結果情報を作成する処理を指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    private async getCoreAsync<TOutput extends ErrorOutput, TResult>(
        requestType: RequestType,
        createResultFunc: (res: TOutput) => TResult,
        createErrorResultFunc: (message?: string) => TResult,
    ): Promise<TResult> {

        let output: ErrorOutput;

        // 実行
        // 型式
        if (requestType === RequestType.Model) {
            output = await this.client.getModel();
        }
        // メーカー
        else if (requestType === RequestType.Manufacturer) {
            output = await this.client.getManufacturer();
        }
        // 施工機種別
        else if (requestType == RequestType.MachineType) {
            output = await this.client.getMachineType();
        }
        // CTU番号
        else if (requestType == RequestType.CtuId) {
            output = await this.client.getCtu();
        }
        // 整備区分
        else if (requestType == RequestType.MaintenanceDivision) {
            output = await this.client.getMaintenanceDivision();
        }
        // 点検グループ
        else if (requestType == RequestType.InspectionGroup) {
            output = await this.client.getInspectionGroup();
        }
        // 点検区分
        else if (requestType == RequestType.InspectionDivision) {
            output = await this.client.getInspectionDivision();
        }
        // 異常
        else {
            throw new Error("type");
        }

        // 結果チェック
        if (output == null) {
            return createErrorResultFunc(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return createErrorResultFunc(output.errorMessage);
        }

        // 正常
        const result = createResultFunc(output as TOutput);
        return result;
    }

    /**
     * 型式一覧を取得します。
     *
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getModelAsync(): Promise<KeyValueItemsResult> {
        const result = await this.getCoreAsync<GetModelOutput, KeyValueItemsResult>(
            RequestType.Model,
            res => this.createItemsResult(res.items),
            this.createErrorItemsResult,
        );
        return result;
    }

    /**
     * メーカー一覧を取得します。
     *
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getManufacturerAsync(): Promise<KeyValueItemsResult> {
        const result = await this.getCoreAsync<GetManufacturerOutput, KeyValueItemsResult>(
            RequestType.Manufacturer,
            res => this.createItemsResult(res.items),
            this.createErrorItemsResult,
        );
        return result;
    }

    /**
     * 施工機種別一覧を取得します。
     *
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getMachineTypeAsync(): Promise<KeyValueItemsResult> {
        const result = await this.getCoreAsync<GetMachineTypeOutput, KeyValueItemsResult>(
            RequestType.MachineType,
            res => this.createItemsResult(res.items),
            this.createErrorItemsResult,
        );
        return result;
    }

    /**
     * CTU一覧を取得します。
     *
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getCtuAsync(): Promise<CtuResult> {
        const output = await this.client.getCtu();
        if (output == null) {
            return this.createCtuErrorResult(WebApiAdapter.messageFailedCommunication);
        } else if (output.isError === true) {
            return this.createCtuErrorResult(output.errorMessage);
        }

        // 正常
        const result: CtuResult = {
            isError: false,
            items: SharedUtility.toKeyValueItems(output.items),
            maintenanceItems: SharedUtility.toKeyValueItems(output.maintenanceItems),
        }
        return result;
    }

    /**
     * 整備区分一覧を取得します。
     *
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getMaintenanceDivisionAsync(): Promise<KeyValueItemsListResult> {
        const result = await this.getCoreAsync<GetMaintenanceDivisionOutput, KeyValueItemsListResult>(
            RequestType.MaintenanceDivision,
            res => this.createItemsListResult(res.items),
            this.createErrorItemsListResult,
        );
        return result;
    }

    /**
     * 点検グループ一覧を取得します。
     *
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getInspectionGroupAsync(): Promise<KeyValueItemsResult> {
        const result = await this.getCoreAsync<GetInspectionGroupOutput, KeyValueItemsResult>(
            RequestType.InspectionGroup,
            res => this.createItemsResult(res.items),
            this.createErrorItemsResult,
        );
        return result;
    }

    /**
     * 点検区分一覧を取得します。
     *
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getInspectionDivisionAsync(): Promise<KeyValueItemsListResult> {
        const result = await this.getCoreAsync<GetInspectionDivisionOutput, KeyValueItemsListResult>(
            RequestType.InspectionDivision,
            res => this.createItemsListResult(res.items),
            this.createErrorItemsListResult,
        );
        return result;
    }

    /**
     * エラー情報を取得します。
     * @param parameter
     */
    public async getErrorAsync(parameter: MachineErrorRequest): Promise<MachineErrorResult> {

        // 実行
        const input = new GetMachineErrorInput();
        input.init({ machineId: parameter.machineId });

        const output = await this.client.getMachineError(input);
        if (output == null) {
            return this.createMachineErrorItemErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createMachineErrorItemErrorResult(output.errorMessage);
        }

        // 正常
        const result: MachineErrorResult = {
            isError: false,
            item: output.ctuError ?? "",
        }
        return result;
    }

    /**
     * 全エラー施工機情報を取得します。
     */
    public async getAllMachineErrorAsync(): Promise<AllMachineErrorResult> {

        const output = await this.client.getAllMachineError();
        if (output == null) {
            return this.createAllMachineErrorItemErrorResult(WebApiAdapter.messageFailedCommunication);
        } else if (output.isError === true) {
            return this.createAllMachineErrorItemErrorResult(output.errorMessage);
        }

        // 正常
        const result: AllMachineErrorResult = {
            isError: false,
            errorMachineIds: output.machineIds ?? [],
        }
        return result;
    }


    /**
    * パスワード認証を行います。
    * @param parameter
    */
    public async checkPasswordAsync(parameter: CheckPasswordRequest): Promise<CheckPasswordResult> {

        // 実行
        const input = new CheckPasswordInput();
        input.init({ password: parameter.item.password });

        const output = await this.client.checkPassword(input);
        if (output == null) {
            return this.createCheckPasswordItemErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createCheckPasswordItemErrorResult(output.errorMessage);
        }

        // 正常
        const result: CheckPasswordResult = {
            isError: false,
        }
        return result;
    }

    /**
    * 認証済みユーザー情報の取得を行います。
    * @param parameter
    */
    public async getAuthenticatedUserAsync(): Promise<AuthenticatedUserResult> {

        // 実行
        const output = await this.client.getAuthenticatedUser();
        if (output == null) {
            return this.createAuthenticatedUserErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createAuthenticatedUserErrorResult(output.errorMessage);

        } else if (output.userId === "" || output.userName === "") {
            return this.createAuthenticatedUserErrorResult("ユーザー情報がありません");

        }

        // 正常
        const result: AuthenticatedUserResult = {
            isError: false,
            user: {
                id: output.userId ?? "",
                name: output.userName ?? "",
                inspectorName: output.inspector ?? "",
            },
        }
        return result;
    }


    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new SharedClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
