import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import { Button, Popover, Typography, Divider, Card, CardContent, CardActions, Box } from '@material-ui/core';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // カード
        card: {
            minWidth: 300,
        },
        // カードコンテンツ
        cardContents: {
            maxHeight: 500,
            overflow: "auto",
        },
        // カードヘッダー
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(2, 2, 1),
        },
        // エラーテキストボタン
        button: {
            fontSize: "1.25rem",
            borderWidth: 2,
            color: theme.palette.error.contrastText,
            borderColor: theme.palette.error.contrastText,
        },
        // エラーアイコン
        errorIcon: {
            fontSize: "2rem!important",
        },
        hidden: {
            width: 200,
            visibility: "hidden",
        }
    }),
);

/** エラーポップボタン の Props を提供します。 */
interface Props {
    /** エラーかどうかを表します。 */
    isExistMachineError: boolean,
    /** 社番を表します。 */
    machineIds: string[],
    /** リセットボタンクリック処理を表します。*/
    onRestClick: () => void,
    /** CTUエラー施工機情報を表示するか画面かどうかを表します。 */
    isShowCtuError: boolean,
}

/** エラーポップボタンを表示します。 */
const ErrorPopover: React.FC<Props> = (props) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'error-popover' : void 0;
    const isShow = props.isShowCtuError && props.isExistMachineError;

    /**
     * エラーボタンクリック処理を行います。
     * @param event
     */
    const onErrorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /** ポップオーバーのクローズ処理を行います。 */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /** リセットボタンクリック時の処理を行います。*/
    const onResetClick = () => {
        handleClose();
        props.onRestClick();
    };

    return (
        <>
            {isShow &&
                <>
                    <div>
                        <Button
                            aria-describedby={id}
                            variant="outlined"
                            color="default"
                            size="small"
                            className={classes.button}
                            startIcon={<WarningOutlinedIcon className={classes.errorIcon} />}
                            onClick={onErrorClick}
                        >
                            CTUエラー
                        </Button>
                    </div>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        elevation={0}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Card className={classes.card}>
                            <Box className={classes.header}>
                                <Typography>
                                    エラー施工機
                            </Typography>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<NotificationsOffIcon />}
                                    style={{ minWidth: 0 }}
                                    onClick={onResetClick}
                                >
                                    表示リセット
                                </Button>
                            </Box>

                            <Divider />

                            <CardContent className={classes.cardContents}>

                                {props.machineIds.map((id, index) => (
                                    <Typography key={index}>{id}</Typography>
                                ))}

                            </CardContent>
                        </Card>
                    </Popover>
                </>
            }
        </>
    )
}
export default ErrorPopover;
