import * as React from 'react';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { EditItem } from '../../_types'


/** スタイルを提供します。 */
const useHistoryStyles = makeStyles((theme: Theme) =>
    createStyles({
        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
        },
        // タイトルアイコン
        icon: {
            marginRight: theme.spacing(1),

        },
        // 閉じるボタンアイコン
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        // サブタイトル
        subtitle: {
            marginBottom: theme.spacing(2),
        },
        // 名称
        name: {
            fontSize: "1.25rem",
            marginRight: theme.spacing(1),
        },
        // テーブル
        table: {
            marginBottom: theme.spacing(2),
        },
    }),
);

/** リセット履歴ダイアログの Props を表示します。 */
interface Props {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
    /** 表示する項目を表します。 */
    editItem: EditItem,
    /** リセット履歴テーブルを表します。 */
    table: JSX.Element,
}

/**
 * リセット履歴ダイアログを表示します。
 * @param props
 */
const HisrotyDialog: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useHistoryStyles();

    /** ダイアログ開閉状態を表します。 */
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])

    /** ダイアログの閉じる処理を行います。 */
    const onClose = () => {
        props.handleClose();
    }

    return (
        <Dialog
            keepMounted
            maxWidth="sm"
            open={open}
            onClose={onClose}
            aria-labelledby="reset-history-dialog"
        >
            {/* タイトル */}
            <DialogTitle>
                <Box className={classes.title}>
                    <InfoIcon fontSize="large" className={classes.icon} />
                    リセット履歴
                </Box>
                <IconButton className={classes.closeButton} onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {/* message */}
                <Typography className={classes.subtitle}>
                    <span className={classes.name}>
                        {`${props.editItem.name} [${props.editItem.unit}]`}
                    </span>
                </Typography>

                {/* リセット履歴テーブル */}
                <Box className={classes.table}>{props.table}</Box>
            </DialogContent>
        </Dialog>
    );
}

export default HisrotyDialog;
