import { MaintenanceItemClient, MaintenanceItemInput, MaintenanceItemOutput } from '../../swagger-clients';
import { WebApiAdapter } from '../../web-api-adapter';
import { MaintenanceItemRequest, MaintenanceItemResult, MaintenanceItemMasterSelectBoxItemState } from './types';
import { MaintenanceItemUtility } from './maintenance-item-utility';

/**
 * `整備項目マスタ` 関連の `Web API` を公開するサーバーと `HTTP` 通信する機能を提供します。
 */
export class MaintenanceItemAdapter extends WebApiAdapter<MaintenanceItemClient> {

    //#region フィールド

    /** 唯一のインスタンスを表します。 */
    public static readonly instance = new MaintenanceItemAdapter();

    //#endregion フィールド

    //#region メソッド

    /**
     * エラーを表す結果情報を作成します。
     *
     * @param message エラーメッセージを指定します。
     * @returns 結果情報を返します。
     */
    private createErrorResult(message?: string): MaintenanceItemResult {
        return {
            items: [],
            isError: true,
            errorMessage: message,
        };
    }

    /**
     * 一覧を取得します。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async getAsync(
        parameter: MaintenanceItemRequest,
        selectBoxItem: MaintenanceItemMasterSelectBoxItemState,
    ): Promise<MaintenanceItemResult> {

        // 実行
        const input = new MaintenanceItemInput();
        input.init(MaintenanceItemUtility.toInput(parameter));

        const output = await this.client.get(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MaintenanceItemResult = {
            isError: false,
            items: MaintenanceItemUtility.toListItem(selectBoxItem, output.items),
        };
        return result;
    }

    /**
     * 追加を行います。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async addAsync(
        parameter: MaintenanceItemRequest,
        selectBoxItem: MaintenanceItemMasterSelectBoxItemState,
    ): Promise<MaintenanceItemResult> {

        // 実行
        const input = new MaintenanceItemInput();
        input.init(MaintenanceItemUtility.toInput(parameter));

        const output = await this.client.add(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MaintenanceItemResult = {
            isError: false,
            items: MaintenanceItemUtility.toListItem(selectBoxItem, output.items),

        };
        return result;
    }

    /**
     * 更新を行います。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async updateAsync(
        parameter: MaintenanceItemRequest,
        selectBoxItem: MaintenanceItemMasterSelectBoxItemState,
    ): Promise<MaintenanceItemResult> {

        // チェック
        const errorItem = parameter.item.id == null ? "ID"
            : parameter.item.maintenanceDivisionId == null ? "整備区分 ID"
            : parameter.item.machineTypeId == null ? "施工機種別 ID"
            : parameter.item.weight == null ? "No"
            : parameter.item.lockVersion == null ? "LockVersion"
            : "";
        if (errorItem !== "") {
            throw new Error(`${errorItem} が指定されていません`);
        }

        // 実行
        const input = new MaintenanceItemInput();
        input.init(MaintenanceItemUtility.toInput(parameter));

        const output = await this.client.update(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MaintenanceItemResult = {
            isError: false,
            items: MaintenanceItemUtility.toListItem(selectBoxItem, output.items),

        };
        return result;
    }

    /**
     * 削除を行います。
     *
     * @param parameter パラメーターを指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 結果を戻す非同期操作を返します。
     */
    public async removeAsync(
        parameter: MaintenanceItemRequest,
        selectBoxItem: MaintenanceItemMasterSelectBoxItemState,
    ): Promise<MaintenanceItemResult> {

        // チェック
        const errorItem = parameter.item.id == null ? "ID"
            : parameter.item.lockVersion == null ? "LockVersion"
                : "";
        if (errorItem !== "") {
            throw new Error(`${errorItem} が指定されていません`);
        }

        // 実行
        const input = new MaintenanceItemInput();
        input.init(MaintenanceItemUtility.toInput(parameter));
        const output = await this.client.remove(input);
        if (output == null) {
            return this.createErrorResult(WebApiAdapter.messageFailedCommunication);
        }
        else if (output.isError === true) {
            return this.createErrorResult(output.errorMessage);
        }

        // 正常
        const result: MaintenanceItemResult = {
            isError: false,
            items: MaintenanceItemUtility.toListItem(selectBoxItem, output.items),
        };
        return result;
    }

    /**
     * コンストラクター
     *
     * @constructor
     */
    private constructor() {
        super(new MaintenanceItemClient());
        // 変更不可
        Object.seal(this);
    }

    //#endregion メソッド
}
