//react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    TextField,
    Box,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import MuiCreateButton from '../../../../../components/Buttons/MuiCreateButton';
import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';
import MuiColorButton from '../../../../../components/Buttons/ColorButton/MuiColorButton';
import { CreateNewFolder } from '@material-ui/icons';


/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            // アイコン
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
        },
        // サブタイトル
        subtitle: {
            marginBottom: theme.spacing(2),
        },
        // 名称
        name: {
            fontSize: "1.25rem",
            marginRight: theme.spacing(1),
        },
        // 入力項目
        inputItem: {
            marginBottom: theme.spacing(2),
        },
        // ボタン
        button: {
            minWidth: 120,
        },
        // ダイアログコンテンツ(ダイアログ内のTextfiledのlabelが全角4文字以上でスクロールが発生するで、無効にする)
        dialogContent: {
            overflowY: "visible"
        }
    }),
);

/** 入力項目を表します。 */
export interface CreateFolderDialogInputItem {
    /** フォルダ名を表します */
    name: string,
}

/** ダイアログの入力項目のバリデーション情報を表します。 */
interface ValidateInputItem {
    /** フォルダ名を表します。 */
    readonly name: string;
}

/** フォームダイアログの Props を表示します。 */
interface FormDialogProps {
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** OKボタンクリック時の処理を表します。 */
    onCreateClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, input: CreateFolderDialogInputItem) => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
}

/**
 * フォームダイアログを表示します。
 * @param props
 */
export function CreateFolderDialog(props: FormDialogProps): JSX.Element {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** State */
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])

    /** 入力内容 */
    const [inputItem, setInputItem] = useState<CreateFolderDialogInputItem>({ name: "" });

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<ValidateInputItem>({ name: "" });

    /**
     * 入力項目　内容変更時のイベント処理を行います。
     * */
    const onChange = (name: keyof CreateFolderDialogInputItem) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        // 入力項目更新
        setInputItem({ ...inputItem, [name]: value });
    };

    /** ダイアログを閉じるときの処理を行います。 */
    const onClose = () => {
        setInputItem({ name: "" });
        setValidate({ name: "" });
        props.handleClose();
    };

    /**
     * OKボタンのクリック処理を行います。
     * @param event
     */
    const onOkClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        const isError = onValidateInputItem()
        if (isError === false) {
            setInputItem({ name: "" });
            setValidate({ name: "" });
            props.onCreateClick(event, inputItem);
        };
    };

    /**
     * フォルダ名を検証します。
     * @param name
     */
    const checkNameFormat = (name: string) => {
        const pattern = /[\\\/:\*\?\"<>\|]/;
        return pattern.test(name);
    };


    /** 入力内容のバリデーションを行います。 */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let nameError = "";

        if (!inputItem.name) {
            nameError = "フォルダ名を入力してください";
            result = true;
        } else if (checkNameFormat(inputItem.name)) {
            nameError =  '\/:*?"<>| はフォルダ名に使用することが出来ません';
            result = true;
        };

        // エラー文字をセット
        setValidate({ name: nameError });
        return result;
    };

    /**
     * form 送信イベント処理を行います
     * @param e
     */
    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        // Enter key による submit を無効にする
        e.preventDefault();
    }

    return (
        <Dialog
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="remove-dialog-title"
            aria-describedby="remove-dialog-description"
            open={props.isOpen}
            onClose={onClose}
        >

            {/* タイトル */}
            <DialogTitle id="form-dialog-title">
                <Box className={classes.title}>
                    <InfoIcon fontSize="large" />フォルダ作成
                </Box>
            </DialogTitle>


            <DialogContent classes={{ root: classes.dialogContent }}>
                {/* message */}
                <Typography className={classes.subtitle}>
                    新しいフォルダを作成します。
                </Typography>
                <Typography className={classes.subtitle}>
                    フォルダ名を入力してください。
                </Typography>

                <form onSubmit={e => submitHandler(e)}>
                    {/* フォルダ名 */}
                    <TextField
                        fullWidth
                        autoComplete="off"
                        className={classes.inputItem}
                        label="フォルダ名"
                        value={inputItem.name}
                        onChange={onChange("name")}
                        required
                        error={!validate.name === false}
                        helperText={validate.name}
                    />

                </form>

            </DialogContent>

            {/* ボタンアクション */}
            <DialogActions>
                <MuiCancelButton
                    className={classes.button}
                    onClick={onClose}
                />
                <MuiColorButton
                    overrideColor="primary"
                    variant="contained"
                    startIcon={<CreateNewFolder />}
                    className={classes.button}
                    onClick={(event) => onOkClick(event)}
                >
                    フォルダ作成
                </MuiColorButton>
            </DialogActions>
        </Dialog>
    );
}
