// react
import * as React from 'react';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
// type
import { UserInputItem } from '../../_types';
// component
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import MuiCreateButton from '../../../../../components/Buttons/MuiCreateButton';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },

    }),
);

/** ユーザーマスタ　検索項目の Props を提供します。 */
interface Props {
    /** 検索ボタンのクリック処理を行います。 */
    onClickSearch: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: UserInputItem) => void),
    ///** 新規作成ボタンのクリック処理を行います。 */
    onClickCreate: (() => void),
};

/**
 * ユーザーマスタ　検索エリアを提供します。
 * */
const UserSearchCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<UserInputItem>({});

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (name: keyof UserInputItem) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? Number.parseInt(target.value, 10) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    // 表示
    return (

        <Box padding={1}>
            {/* ユーザーid */}
            <TextField
                label="ユーザー id"
                placeholder="ユーザー id"
                value={values.id ?? ""}
                onChange={onChange("id")}
            />
            {/* ユーザー名 */}
            <TextField
                label="ユーザー名 "
                placeholder="ユーザー名 "
                value={values.user ?? ""}
                onChange={onChange("user")}
            />
            {/* 検印文字 */}
            <TextField
                label="検印文字"
                placeholder="検印文字"
                value={values.inspectorName ?? ""}
                onChange={onChange("inspectorName")}
            />

            {/* ボタングループ */}
            <Box>
                {/* 検索ボタン */}
                <MuiSearchButton
                    className={classes.button}
                    onClick={(event) => props.onClickSearch(event, values)}
                />
                {/* 新規登録ボタン */}
                <MuiCreateButton
                    className={classes.button}
                    onClick={(event) => props.onClickCreate()}
                />
            </Box>

        </Box>

    );
};

export default UserSearchCondition;
