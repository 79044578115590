import * as React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';


/**
 * 線形進捗バーを表示します。
 */
const StyledLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 5,
        },
        colorPrimary: {
            backgroundColor: "#4B7762",
        },
        bar: {
            backgroundColor: "#9bf9cc",
        },
    }),
)(LinearProgress);

/**
 * 認証中のローディングを表示します。
 */
export const AuthorizeLoading: React.FC<{}> = () => {

    return (
        <StyledLinearProgress />
    );
}
