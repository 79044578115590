// react
import * as React from 'react';
import clsx from 'clsx';
// material-ui
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            // Over ride
            '& .MuiAccordion-root': { // Over ride
                marginBottom: "1rem",
            },
            '& .MuiTextField-root': {
                width: "250px",
                margin: theme.spacing(1),
            },
        },
        // 活性制御
        disabled: {
            pointerEvents: "none",
            opacity: 0.4,
        },
        // ボーダー
        border: {
            borderStyle: "solid",
            borderWidth: 4,
            borderColor: theme.palette.background.paper,
        },

        //青枠背景：新規作成状態
        boxBuleIn: {
            animation: `$boxBuleInEffect .2s ${theme.transitions.easing.easeIn} forwards`,
        },
        "@keyframes boxBuleInEffect": {
            "0%": {
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.background.paper,
            },
            "100%": {
                backgroundColor: fade(theme.palette.warning.main, 0.02),
                borderColor: theme.palette.warning.main,
            },
        },
        //黄枠背景：項目選択(更新)状態
        boxYellowIn: {
            animation: `$boxYellowInEffect .2s ${theme.transitions.easing.easeIn} forwards`,
        },
        "@keyframes boxYellowInEffect": {
            "0%": {
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.background.paper,
            },
            "100%": {
                backgroundColor: fade(theme.palette.secondary.main, 0.02),
                borderColor: theme.palette.secondary.main,
            },
        },
    }),
);

interface Props {
    // 上部コンテンツ(検索エリア)を表します。
    search: React.ReactNode,
    // 中部コンテンツ(テーブルエリア)を表します。
    table: React.ReactNode,
    // 下部コンテンツ(詳細エリア)を表します。
    detail: React.ReactNode,
    // 新規作成状態かどうかを表します。
    isCreating: boolean,
    // 行選択状態かどうかを表します。
    isSelected: boolean,
}

interface State {
    /** 上部コンテンツが展開されているかどうかを表します。 */
    isExpandedSearch: boolean,
    /** 中部コンテンツが展開されているかどうかを表します。 */
    isExpandedTable: boolean,
    /** 下部コンテンツが展開されているかどうかを表します。 */
    isExpandedDetail: boolean,
}

/**
 * マスタ系　3段 レイアウトを表示します。
 * @param props
 */
const MasterLayout: React.FC<Props> = (props) => {
    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** State を提供します。*/
    // 現状常に展開(開閉操作しない)
    const [expanded, isExpanded] = React.useState<State>({
        isExpandedSearch: true,
        isExpandedTable: true,
        isExpandedDetail: true,
    });

    return (
        <>
            <Box className={classes.root}>

                {/* 検索条件 */}
                <Collapse
                    in={expanded.isExpandedSearch}
                    collapsedHeight={0}
                    // 活性制御　新規作成の場合、非活性
                    className={clsx(props.isCreating && classes.disabled)}
                >

                    {/* 検索条件エリア */}
                    {props.search}

                </Collapse>

                <Divider />

                {/* 検索結果 */}
                <Collapse
                    in={expanded.isExpandedTable}
                    collapsedHeight={0}
                    // 活性制御　新規作成の場合、非活性
                    className={clsx(props.isCreating && classes.disabled)}
                >
                    <Box>
                        {/* テーブル */}
                        {props.table}

                    </Box>
                </Collapse>

                <Divider />

                {/* 詳細表示 */}
                <Collapse
                    in={expanded.isExpandedDetail}
                    collapsedHeight={0}
                    // 活性制御 新規作成または選択されていない場合、非活性
                    className={clsx(
                        classes.border,
                        (props.isSelected || props.isCreating) === false && classes.disabled,
                        props.isCreating && classes.boxBuleIn,
                        props.isSelected && classes.boxYellowIn,
                    )}
                >

                    {/* 詳細表示エリア */}
                    {props.detail}

                </Collapse>
            </Box >
        </>

    )
}

export default MasterLayout;
