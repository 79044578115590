import { createSlice, SliceCaseReducers, ValidateSliceCaseReducers, PayloadAction } from '@reduxjs/toolkit';
import { Message } from '../../../components/MuiSnackbar/types';
import { CreateSliceUtility } from './create-slice-utility'
import { ReportItem } from '../../../pages/ConstructionMachineMaster/_types';

/** 記録表 の State を提供します。 */
export interface RecordsSliceState<TList, TReport extends ReportItem> {
    /** テーブル項目の結果情報を表します。 */
    resultItems?: TList[],
    /** ロード中かどうかを表します。 */
    isLoading: boolean,
    /** メッセージを表示するかどうかを表します。 */
    isShowMessage: boolean,
    /** メッセージを表します。 */
    message?: Message,
    /** ビューワを表示するかどうか表します。 */
    isOpenViewer: boolean,
    /** 帳票情報を表します。 */
    report?: TReport,
    /** 選択された項目を表します。 */
    selectedItem?: TList,
    /** 帳票が更新されたかどうかを表します。 */
    isUpdated: boolean,
    /** 帳票が読み込まれたかどうかを表します。 */
    isReportLoading: boolean,
}

/**
 * slice オブジェクトを作成します。
 * @param name A string name for this slice of state. Generated action type constants will use this as a prefix.
 * @param initialState The initial state value for this slice of state.
 * @param reducers An object containing Redux "case reducer" functions (functions intended to handle a specific action type, equivalent to a single case statement in a switch).
 */
export const createReportListSlice = <TList, TReport extends ReportItem, Reducers extends SliceCaseReducers<RecordsSliceState<TList, TReport>>>
    ({
        name = '',
        initialState,
        reducers
    }: {
        name: string
        initialState: RecordsSliceState<TList, TReport>
        reducers: ValidateSliceCaseReducers<RecordsSliceState<TList, TReport>, Reducers>
    }) => {
    return createSlice({
        name,
        initialState,
        reducers: {

            /**
             * 通信開始処理を行います。
             * @param state
             * @param action
             */
            fetchStart(state) {
                state.isLoading = true;
                state.isShowMessage = false;
                state.message = void 0;
                state.isUpdated = false;
            },

            /**
             * If you want to write to values of the state that depend on the generic
             * (in this case: `state.data`, which is T), you might need to specify the
             * State type manually here, as it defaults to `Draft<GenericState<T>>`,
             * which can sometimes be problematic with yet-unresolved generics.
             * This is a general problem when working with immer's Draft type and generics.
             */

            /**
             * テーブル項目を設定します。
             * @param state
             * @param action
             */
            setItems(state: RecordsSliceState<TList, TReport>, action: PayloadAction<TList[]>) {
                state.resultItems = action.payload;
            },

            /**
             * 帳票情報を設定します。
             * @param state
             * @param action
             */
            setReport(state: RecordsSliceState<TList, TReport>, action: PayloadAction<TReport>) {
                state.report = action.payload;
            },

            /**
              * 帳票ビューワの表示状態を切り替えます。
              * @param state
              * @param action
              */
            clearReport(state: RecordsSliceState<TList, TReport>) {
                if (state.report != null) {
                    state.report.reportUrl = "";
                }
            },

            /**
             * 通信失敗処理を行います。
             * @param state
             * @param action
             */
            fetchFailure(state: RecordsSliceState<TList, TReport>) {
                state.resultItems = void 0;
            },

            /**
             * 通信終了時の処理を行います。
             * @param state
             */
            fetchEnd(state: RecordsSliceState<TList, TReport>,) {
                state.isLoading = false;
            },

            /**
             * メッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showMessage(state: RecordsSliceState<TList, TReport>, action: PayloadAction<Message>) {
                state.isShowMessage = true;
                state.message = action.payload;
            },

            /**
             * 通信成功時のメッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showSuccessMessage(state: RecordsSliceState<TList, TReport>, action: PayloadAction<string | undefined>) {
                state.isShowMessage = true;
                state.message = {
                    type: "success",
                    text: action.payload ?? CreateSliceUtility.DefualtSuccessMessage,
                }
            },

            /**
             * 通信失敗時のメッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showErrorMessage(state: RecordsSliceState<TList, TReport>, action: PayloadAction<string | undefined>) {
                state.isShowMessage = true;
                state.message = {
                    type: "error",
                    text: `Error : ${action.payload ?? CreateSliceUtility.DefualtErrorMessage}`,
                }
            },

            /**
             * メッセージの非表示処理を行います。
             * @param state
             * @param action
             */
            hideMessage(state: RecordsSliceState<TList, TReport>) {
                state.isShowMessage = false;
            },

            /**
             * 帳票ビューワの表示状態を切り替えます。
             * @param state
             * @param action
             */
            toggleViewre(state: RecordsSliceState<TList, TReport>, action: PayloadAction<boolean>) {
                state.isOpenViewer = action.payload;
            },

            /**
             * 選択項目を取得します。
             * @param state
             * @param action
             */
            selectItem(state: RecordsSliceState<TList, TReport>, action: PayloadAction<TList>) {
                state.selectedItem = action.payload;
            },

            /**
             * 帳票ビューワの更新フラグを切り替えます。
             * @param state
             * @param action
             */
            changeUpdatedFlag(state: RecordsSliceState<TList, TReport>, action: PayloadAction<boolean>) {
                state.isUpdated = action.payload;
            },

            /**
             * 帳票ビューワの読み込み完了フラグを切り替えます。
             * @param state
             * @param action
             */
            changeReportLoadingFlag(state: RecordsSliceState<TList, TReport>, action: PayloadAction<boolean>) {
                state.isReportLoading = action.payload;
            },
            ...reducers
        }
    })
}
