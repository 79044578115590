import * as React from 'react';
import { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { onGetAuthenticatedUserAsync, onSignOutAsync } from '../../../modules/slice/authentication/authentication-slice';
import { AuthorizePage } from './components/AuthorizePage/AuthorizePage';

/**
 * ユーザー認証 コンポーネントを表示します。
 * @param props
 */
export const AuthorizeRouter: React.FC<{}> = (props) => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isSignedIn: boolean = useSelector((state: RootState) => state.signin.result.isSignedIn);
    const isAuthorized: boolean = !!useSelector((state: RootState) => state.authentication.user);
    const isLoaded = useSelector((state: RootState) => state.authentication.isLoaded);
    const isError = useSelector((state: RootState) => state.authentication.isError);
    const message = useSelector((state: RootState) => state.authentication.message ?? "");


    useEffect(() => {

        // useEffect 内で非同期処理定義
        const funcAsync = async () => await dispatch(onGetAuthenticatedUserAsync());

        // 実行
        if (isSignedIn && !isAuthorized) funcAsync();


    }, [dispatch, isAuthorized, isSignedIn])


    /** 戻るボタンクリック時の処理を行います。 */
    const onReturnClickAsync = async () => {

        // サインアウト処理(Storeをクリアする。)
        await dispatch(onSignOutAsync());

    }

    return (

        <AuthorizePage
            isSigednin={isSignedIn}
            isAuthorized={isAuthorized}
            isLoaded={isLoaded}
            isError={isError}
            message={message}
            onReturnClick={onReturnClickAsync}
            children={props.children} />

    );
}
