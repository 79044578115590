// react
import * as React from 'react';
import { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// type
import { MaintenanceDivisionInputItem } from '../../_types';
import { KeyValueItem, MachineType } from '../../../../../_types';
// component
import MuiSearchButton from '../../../../../components/Buttons/MuiSearchButton';
import MuiCreateButton from '../../../../../components/Buttons/MuiCreateButton';
import { MaintenancePreviewDrawer } from '../../../Shared/components/ViewerDrawer/MaintenancePreviewDrawer';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ボタン
        button: {
            minWidth: "150px",
            margin: "1rem",
        },

        // ボタン グループ
        buttonGroup: {
            marginRight: "auto",
        },
    }),
);

/** 整備区分マスタ　検索項目の Props を提供します。 */
interface Props {
    /** 検索ボタンのクリック処理を行います。 */
    onClickSearch: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: MaintenanceDivisionInputItem) => void),
    ///** 新規作成ボタンのクリック処理を行います。 */
    onClickCreate: (() => void),
    /** 点検グループ一覧を表します。*/
    machineTypeList: KeyValueItem<number, string>[],
    /** プレビューボタンのクリック処理を行います。 */
    onClickPreview: ((type: MachineType) => void),
    /** 帳票 URL を表します。 */
    reportUrl: string,
    /** レポート読み込み終了時の処理を行います。 */
    loadedReport: () => void,
};

/**
 * 整備区分マスタ　検索エリアを提供します。
 * */
const MaintenanceDivisionSearchCondition: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<MaintenanceDivisionInputItem>({});

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (name: keyof MaintenanceDivisionInputItem) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? Number.parseInt(target.value, 10) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };

    // 表示
    return (

        <Box padding={1}>
            {/* 施工機種別 */}
            <TextField
                select
                SelectProps={{
                    MenuProps: {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }
                }}
                label="施工機種別"
                placeholder="施工機種別"
                value={values.machineTypeId ?? ""}
                onChange={onChange("machineTypeId")}

            >
                {/* Default */}

                <MenuItem key={0} value={0}>{"未指定"}</MenuItem>
                {/*　選択肢 */}
                {props.machineTypeList.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                )
                )}

            </TextField >

            {/* 整備区分 */}
            <TextField
                label="整備区分"
                placeholder="整備区分"
                value={values.name ?? ""}
                onChange={onChange("name")}
            />

            {/* ボタングループ */}
            <Box display="flex" alignItems="center">

                <div className={classes.buttonGroup}>
                    {/* 検索ボタン */}
                    <MuiSearchButton
                        className={classes.button}

                        onClick={(event) => props.onClickSearch(event, values)}
                    />
                    {/* 新規登録ボタン */}
                    <MuiCreateButton
                        className={classes.button}
                        onClick={(event) => props.onClickCreate()}
                    />
                </div>

                {/* プレビュー */}
                <MaintenancePreviewDrawer
                    reportUrl={props.reportUrl}
                    loadedReport={props.loadedReport}
                    onClickPreview={props.onClickPreview}
                />

            </Box>

        </Box>

    );
};

export default MaintenanceDivisionSearchCondition;
