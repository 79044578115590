import { InspectionItemListItem, InspectionItemInputItem } from '../../../pages/Master/InspectionItem/_types';
import { InspectionItemRequest, InspectionItemMasterSelectBoxItemState } from './types';
import { InspectionItemInput, InspectionItemItem } from '../../swagger-clients';

/**
 * `点検項目マスタ` 関連の ユーティリティ機能を提供します。
 */
export class InspectionItemUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @param selectBoxItem セレクトボックスリストを指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(
        selectBoxItem: InspectionItemMasterSelectBoxItemState,
        items?: InspectionItemItem[],
    ): InspectionItemListItem[] {
        const result: InspectionItemListItem[] = [];
        if (items == null) {
            return result;
        }

        for (const source of items) {
            // 異常値はスキップ
            if (source.id == null
                || source.groupTypeId == null
                || source.divisionId == null
                || source.weight == null
                || source.lockVersion == null) {
                continue;
            }

            // セレクトボックスの名称を取得
            const groupItem = selectBoxItem.InspectionGroup.find(_ => _.key === source.groupTypeId);
            const divisionList = selectBoxItem.InspectionDivision.find(_ => _.key === source.groupTypeId);
            const divisionItem = divisionList?.value.find(_ => _.key === source.divisionId) ?? void 0;

            const item: InspectionItemListItem = {
                id: source.id,
                name: source.name ?? "",
                inspectionDivisionId: source.divisionId,
                inspectionDivisionName: divisionItem?.value ?? "",
                inspectionGroupId: source.groupTypeId,
                inspectionGroupName: groupItem?.value ?? "",
                weight: source.weight,
                unit: source.unit ?? "",
                inputLowerLimit: source.inputLowerLimit ?? "",
                inputHigherLimit: source.inputHigherLimit ?? "",
                lightAlarmLowerLimit: source.lightAlarmLowerLimit ?? "",
                lightAlarmHigherLimit: source.lightAlarmHigherLimit ?? "",
                heavyAlarmLowerLimit: source.heavyAlarmLowerLimit ?? "",
                heavyAlarmHigherLimit: source.heavyAlarmHigherLimit ?? "",
                remarks: source.remarks ?? "",
                lockVersion: source.lockVersion,
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 入力情報に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInput(source: InspectionItemRequest): InspectionItemInput {
        const result = new InspectionItemInput();
        result.init({
            item: InspectionItemUtility.toInputItem(source.item),
        });
        return result;
    }

    /**
     * 入力項目に変換します。
     *
     * @param source 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toInputItem(source: InspectionItemInputItem): InspectionItemItem {
        const result = new InspectionItemItem();
        result.init({
            id: source.id,
            divisionId: source.inspectionDivisionId,
            groupTypeId: source.inspectionGroupId,
            name: source.name,
            weight: source.weight,
            unit: source.unit,
            inputLowerLimit: source.inputLowerLimit == null ? void 0 : source.inputLowerLimit.toString(),
            inputHigherLimit: source.inputHigherLimit == null ? void 0 : source.inputHigherLimit.toString(),
            lightAlarmLowerLimit: source.lightAlarmLowerLimit == null ? void 0 : source.lightAlarmLowerLimit.toString(),
            lightAlarmHigherLimit: source.lightAlarmHigherLimit == null ? void 0 : source.lightAlarmHigherLimit.toString(),
            heavyAlarmLowerLimit: source.heavyAlarmLowerLimit == null ? void 0 : source.heavyAlarmLowerLimit.toString(),
            heavyAlarmHigherLimit: source.heavyAlarmHigherLimit == null ? void 0 : source.heavyAlarmHigherLimit.toString(),
            remarks: source.remarks,
            lockVersion: source.lockVersion,
        });
        return result;
    }

    //#endregion メソッド
}
