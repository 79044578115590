// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { FileManageSlice, onDeleteFilesAsync } from '../../../../../modules/slice/file-manage/file-manage-slice';
import ConfirmDialog from '../../../../Master/Shared/components/ConfirmDialog/ConfirmDialog';
import { FileManageUtility } from '../../../../../modules/adapter/file-manage/file-manage-utility';


/** 削除ダイアログを表示します。 */
export function DeleteDialogContainer(): JSX.Element {

    const dispatch = useDispatch();

    const isOpen = useSelector((state: RootState) => state.fileManage.item.isOpenDeleteDialog);
    const selectedItems = useSelector((state: RootState) => state.fileManage.item.selectedRowItems);
    const directoryName = useSelector((state: RootState) => FileManageUtility.toDirectoryKey(state.fileManage.item.directoryItem));

    /**
     * 削除ボタンクリックイベントを処理します。
     * @param event
     * @param pwassword
     */
    const onRemoveClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, pwassword: string) => {
        // 非同期通信
        await dispatch(onDeleteFilesAsync({ keys: selectedItems.map(row => row.key), directoryName}, pwassword));
    };

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        dispatch(FileManageSlice.actions.toggleDeleteDialog(false));
    };

    return (<ConfirmDialog
        isOpen={isOpen}
        handleClose={closeDialog}
        onRemoveClick={onRemoveClick}
        title={"ファイル削除確認"}
    />
    );
}
