// react
import * as React from 'react';
import { useState, useEffect, useCallback, useMemo, useRef, ChangeEventHandler } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeReportPageHeader } from '../../../modules/slice/global-header/global-header-slice';

import { AppTitle } from '../../Shared/app-title';
// S3
import { FileManageSlice, onGetListFilesAsync } from '../../../modules/slice/file-manage/file-manage-slice';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
import { ControlButtonsContainer } from './components/HeaderContent/ControlButtonsContainer';
import { CreateFolderDialogContainer } from './components/DialogContents/CreateFolderDialogContainer';
import { HomeDirTypeToggleContainer } from './components/HeaderContent/HomeTypeToggleContainer';
import { DeleteDialogContainer } from './components/DialogContents/DeleteDialogContainer';
import { S3FileTableContainer } from './components/TableContent/S3FileTableContainer';
import { UploadDialogContainer } from './components/DialogContents/UploadDialogContainer';
import { UploadResultDialogContainer } from './components/DialogContents/UploadResultDialogContainer';
import { DeleteResultDialogContainer } from './components/DialogContents/DeleteResultDialogContainer';
import path from 'path';

const fileImage = new Image();
export const useHooks = () => {
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const [objectURL, setObjectURL] = useState("");
    const resetSelection = () => {
        fileImage.src = "";
        const imageContainer = imageContainerRef.current;
        if (imageContainer && fileImage.parentNode === imageContainer) {
            imageContainer.removeChild(fileImage);
        }
        if (objectURL) {
            window.URL.revokeObjectURL(objectURL);
            setObjectURL("");
        }
    };
    const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
        const files = event.currentTarget.files;
        resetSelection();
        if (!files || files?.length === 0) return;
        const file = files[0];
        if (!file.type.includes("image/")) {
            event.currentTarget.value = "";
            return;
        }
        const imageContainer = imageContainerRef.current;
        if (!imageContainer) return;
        const objectURL = window.URL.createObjectURL(file);
        fileImage.src = objectURL;
        imageContainer.appendChild(fileImage);
        setObjectURL(objectURL);
    };
    return { handleFiles, imageContainerRef };
};

/** S3ファイル管理画面 を表示します。*/
export const FileManagePage: React.FC<{}> = () => {
     /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.fileManage.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.fileManage.isShowMessage);
    const message = useSelector((state: RootState) => state.fileManage.message);
    const isUpdated = useSelector((state: RootState) => state.fileManage.item.isUpdated);
    const directoryName = useSelector((state: RootState) => path.join(state.fileManage.item.directoryItem.root, state.fileManage.item.directoryItem.home, ...state.fileManage.item.directoryItem.sub));

    useEffect(() => {
        if (isUpdated) {
            dispatch(onGetListFilesAsync({ key: directoryName }));
        }
    }, [isUpdated, directoryName, dispatch])

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(FileManageSlice.actions.hideMessage());
    };


    // Mount, Unmount時
    useEffect(() => {
        dispatch(changeReportPageHeader(AppTitle.fileManage.title));
    }, []);
  
    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            {/* ディレクトリ変更 */}
            <HomeDirTypeToggleContainer/>

            {/* 操作ボタン */}
            <ControlButtonsContainer />

            {/* ダイアログ */}
            <UploadDialogContainer />
            <UploadResultDialogContainer />
            <CreateFolderDialogContainer />
            <DeleteDialogContainer />
            <DeleteResultDialogContainer />

            {/* テーブル */}
            <S3FileTableContainer/>
            
        </>
    );
}
