// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { WeeklyInspectionRecordSlice, onUpdateWeeklyInspectedStampAsync } from '../../../../../modules/slice/weekly-inspection-record/weekly-inspection-record-slice';
import { RootState } from '../../../../../rootReducer';
import { KeyValueItem, WeekOfMonth } from '../../../../../_types';
import { InspectedStampItem, UpdateInspectedStampListItem } from '../../../_types';
import InspectionStampForm from './InspectionStampForm';
import { ConstructionMacineMasterUtility } from '../../../Shared/ConstructionMacineMasterUtility';

/** 検印エリアを表示します。 */
export const StampContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const machine = useSelector((state: RootState) => state.globalHeader.machine)
    const inspector = useSelector((state: RootState) => state.authentication?.user?.inspectorName ?? "");
    const inspectionStampItems: KeyValueItem<number, InspectedStampItem>[] = useSelector((state: RootState) => state.weeklyInspectionRecord?.report?.stampItems ?? []);
    const selectedItem = useSelector((state: RootState) => state.weeklyInspectionRecord?.selectedItem);
    const isUpdated: boolean = useSelector((state: RootState) => state.weeklyInspectionRecord?.isUpdated ?? false);

    /** 更新ボタンのクリック処理を行います。 */
    const onUpdateClick = async (inputItems: UpdateInspectedStampListItem<WeekOfMonth>[]) => {
        if (!machine) {
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage("施工機情報がありません。"));
        } else if (!inspector) {
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage("検印者情報がありません。"));
        } else if (!selectedItem) {
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage("選択項目の情報がありません。"));
        } else if (!inspectionStampItems.length) {
            dispatch(WeeklyInspectionRecordSlice.actions.showErrorMessage("帳票情報がありません。"));
        } else {
            // 検印更新
            dispatch(onUpdateWeeklyInspectedStampAsync(
                {
                    items: ConstructionMacineMasterUtility.toUpdateWeeklyInspectedStampItem(inputItems),
                },
                {
                    machineId: machine.item.id,
                    reportAt: selectedItem.completionDate
                }
            ))
        }
    };

    /** 戻るボタンのクリック処理を行います。 */
    const onBackClick = () => {
        // ビューワを閉じる
        dispatch(WeeklyInspectionRecordSlice.actions.toggleViewre(false));
    };

    return (
        <InspectionStampForm
            inspector={inspector}
            stampDatas={inspectionStampItems}
            onUpdateClick={onUpdateClick}
            onBackClick={onBackClick}
            isUpdated={isUpdated}
        />
    );

};
