// react
import * as React from 'react';
import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { changeRecordPageHeader } from '../../../modules/slice/global-header/global-header-slice';
import { MachineErrorHistorySlice, onGetAsync } from '../../../modules/slice//machine-error-history/machine-error-history-slice';
// 共通component
import TwoColumnLayout from '../../../components/Layout/TwoColumnLayout';
import MuiBackDropProgress from '../../../components/MuiBackDropProgress/MuiBackDropProgress';
import MuiSnackbar from '../../../components/MuiSnackbar/MuiSnackbar';
// component
import { AlertRecordMain } from './components/MainContent/AlertRecordMain'
import { AlertRecordSearchContainer } from './components/SearchContent/AlertRecordSearchContainer';
import { AlertRecordTableContainer } from './components/TableContent/AlertRecordTableContainer';
import { AlertRecordGraphContainer } from './components/GraphContent/AlertRecordGraphContainer'
import { AppTitle } from '../../Shared/app-title';

/**
 * アラート履歴画面を表示します。
 * */
export const AlertRecord: React.FC<{}> = () => {

    /** dispatch を提供します。*/
    const dispatch = useDispatch();

    // redux store
    const isLoading = useSelector((state: RootState) => state.machineErrorHistory.isLoading);
    const isShowMessage = useSelector((state: RootState) => state.machineErrorHistory.isShowMessage);
    const message = useSelector((state: RootState) => state.machineErrorHistory.message);
    const machine = useSelector((state: RootState) => state.globalHeader.machine)

    /** Snackbar の閉じる処理を行います。 */
    const onSnackbarClose = () => {
        dispatch(MachineErrorHistorySlice.actions.hideMessage());
    };

    useEffect(() => {
        // タイトル変更
        dispatch(changeRecordPageHeader((AppTitle.recordAlertRecord.title)));

        // 初回マウント時に直近30日分のデータ取得を行う。
        /** 初期表示時に入力値に入れる値は SearchContent で設定しているので注意する */
        const beginAt = new Date();
        beginAt.setMonth(beginAt.getMonth() - 1);
        beginAt.setHours(0, 0, 0, 0);

        const endAt = new Date();
        endAt.setHours(0, 0, 0, 0);

        if (!machine) {
            dispatch(MachineErrorHistorySlice.actions.showErrorMessage("施工機情報が指定されていません"));
        } else {
            dispatch(onGetAsync({
                item: {
                    machineId: machine.item.id,
                    beginAt: beginAt,
                    endAt: endAt,
                }
            }))
        }

    }, [dispatch, machine]);


    return (
        <>
            {/* ローディング表示 */}
            <MuiBackDropProgress
                isLoading={isLoading}
            />

            {/* Snackbar 表示 */}
            <MuiSnackbar
                isOpen={isShowMessage}
                message={message}
                onClose={onSnackbarClose}
            />

            <TwoColumnLayout
                // 検索エリア
                side={<AlertRecordSearchContainer />}
                // 検索結果
                main={<AlertRecordMain
                    table={<AlertRecordTableContainer />}
                    graph={<AlertRecordGraphContainer />}
                />}
            />
        </>

    );
};
