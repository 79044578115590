/**
 * テーマ 関連の ユーティリティ機能を提供します。
 * */
export class ThemeSliceUtility {

    //#region フィールド

    /** ストレージのテーマの Key を表します。*/
    static readonly KeyTheme = "Theme";

    //#endregion フィールド


    //#region メソッド

    /**
     * ストレージ にあるテーマ情報を取得します。
     * @returns テーマ情報を返します。
     * */
    public static getStorageTheme(): boolean {

        const data = localStorage.getItem(ThemeSliceUtility.KeyTheme) ?? "";

        const result = (Number.parseInt(data, 10) === 1);

        return result;
    }

    /**
     *  ストレージにテーマ情報を設定します。
     * @param value テーマ情報を指定します。
     */
    public static setStorageTheme(value: boolean): void {

        localStorage.setItem(ThemeSliceUtility.KeyTheme, value ? "1" : "0");
    }

    //#endregion メソッド

}
