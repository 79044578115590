// redux
import { Dispatch } from 'redux';
// shared
import { MasterGenericState, createMasterGenericSlice } from '../shared/create-master-slice';
// type
import { InspectionItemAdapter } from '../../adapter/inspection-item/inspection-item-adapter';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { InspectionItemResult, InspectionItemMasterSelectBoxItemState, InspectionItemRequest } from '../../adapter/inspection-item/types';
import { InspectionItemListItem } from '../../../pages/Master/InspectionItem/_types';
import { ReportAdapter } from '../../adapter/report/report-adapter';
import { KeyValueItem, InspectionType } from '../../../_types';
import { ReportResult } from '../../adapter/report/type';

// State の初期値を設定します。
const initialState: MasterGenericState<InspectionItemResult, InspectionItemListItem, InspectionItemMasterSelectBoxItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    isCreating: false,
    isSelected: false,
    selectedRowItem: void 0,
    selectBoxItem: {
        InspectionGroup: [],
        InspectionDivision: [],
    },
    isDialogOpen: false,

}

/** 取得 Slice を 作成します。*/
export const InspectionItemMasterSlice = createMasterGenericSlice({
    // State Reducer Action を生成
    name: 'InspectionItem',
    initialState: initialState,
    reducers: {},
})

/**
 * 非同期通信処理を行います。
 * @param action
 */
export const onExcuteAsync = (promise: Promise<InspectionItemResult>) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(InspectionItemMasterSlice.actions.fetchStart());

        // 通信処理
        const result = await promise;

        if (result.isError) {
            // 異常
            dispatch(InspectionItemMasterSlice.actions.fetchFailure(result));
            // メッセージ
            dispatch(InspectionItemMasterSlice.actions.showErrorMessage(result.errorMessage));
        } else {
            // 正常
            dispatch(InspectionItemMasterSlice.actions.fetchSuccess(result));

            // メッセージ
            dispatch(InspectionItemMasterSlice.actions.showSuccessMessage());

            // 新規作成状態解除
            dispatch(InspectionItemMasterSlice.actions.chageCreating(false));
        }

    } catch (error) {

        //例外
        dispatch(InspectionItemMasterSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(InspectionItemMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(InspectionItemMasterSlice.actions.fetchEnd());
    }
}

/**
 * 画面表示時の初回非同期通信処理を行います。
 * @param action
 */
export const onExcuteMountedAsync = () => async (dispatch: Dispatch) => {

    try {
        // state リセット
        dispatch(InspectionItemMasterSlice.actions.resetState());


        // 処理中 ON
        dispatch(InspectionItemMasterSlice.actions.fetchStart());

        // 点検グループ
        const inspectionGroupWaiter = SharedAdapter.instance.getInspectionGroupAsync();
        // 点検項目
        const inspectionDivisionWaiter = SharedAdapter.instance.getInspectionDivisionAsync();

        //まとめて取得
        const selectBoxresult = await Promise.all([inspectionGroupWaiter, inspectionDivisionWaiter]) // 直接配列に渡したらエラーなる
        const inspectionGroupResult = selectBoxresult[0];
        const inspectionDivisionResult = selectBoxresult[1];

        // 異常確認
        if (inspectionGroupResult.isError) {
            // 異常メッセージ
            dispatch(InspectionItemMasterSlice.actions.showErrorMessage(`点検グループ別取得エラー：${inspectionGroupResult.errorMessage}`));

        } else if (inspectionDivisionResult.isError) {
            // 異常メッセージ
            dispatch(InspectionItemMasterSlice.actions.showErrorMessage(`点検区分取得エラー：${inspectionDivisionResult.errorMessage}`));

        } else {
            // 正常
            // リストの項目を store に保存
            dispatch(InspectionItemMasterSlice.actions.getSelectBoxItem({
                InspectionGroup: inspectionGroupResult.items,
                InspectionDivision: inspectionDivisionResult.items,
            }));

            // データ取得
            const result = await InspectionItemAdapter.instance.getAsync(
                { item: {} },
                {
                    InspectionGroup: inspectionGroupResult.items,
                    InspectionDivision: inspectionDivisionResult.items,
                }
            );

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(InspectionItemMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(InspectionItemMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(InspectionItemMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(InspectionItemMasterSlice.actions.showSuccessMessage());
            }
        }

    } catch (error) {

        // メッセージ
        dispatch(InspectionItemMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(InspectionItemMasterSlice.actions.fetchEnd());
    }
}

/**
 * 削除処理を行います。
 * @param parameter
 * @param password
 */
export const onConfirmRemoveAsync = (
    parameter: InspectionItemRequest,
    selectBoxItem: InspectionItemMasterSelectBoxItemState,
    password: string,
) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(InspectionItemMasterSlice.actions.fetchStart());

        // パスワード認証
        const checkPasswordResult = await SharedAdapter.instance.checkPasswordAsync({ item: { password: password } });

        // 異常確認
        if (checkPasswordResult.isError) {
            // 異常メッセージ
            dispatch(InspectionItemMasterSlice.actions.showErrorMessage(checkPasswordResult.errorMessage));

        } else {
            // 正常
            // 削除実行
            const result = await InspectionItemAdapter.instance.removeAsync(parameter, selectBoxItem);

            if (result.isError) {
                // データ　異常メッセージ
                // 異常
                dispatch(InspectionItemMasterSlice.actions.fetchFailure(result));
                // メッセージ
                dispatch(InspectionItemMasterSlice.actions.showErrorMessage(result.errorMessage));
            } else {

                dispatch(InspectionItemMasterSlice.actions.fetchSuccess(result));

                // 正常メッセージ
                dispatch(InspectionItemMasterSlice.actions.showSuccessMessage("削除しました。"));

                // ダイアログを閉じる
                dispatch(InspectionItemMasterSlice.actions.toggleDialog(false));

                // 選択項目のクリア
                dispatch(InspectionItemMasterSlice.actions.clearSelectedItem());

            }
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(InspectionItemMasterSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(InspectionItemMasterSlice.actions.fetchEnd());
    }

}

/**
 * 点検票プレビュー情報を取得します。
 * @param type 点検種別を指定します。
 */
export const getIncspectionPreviewReportAsync = (type: InspectionType) => async (dispatch: Dispatch) => {
    try {
        // 処理中 ON
        dispatch(InspectionItemMasterSlice.actions.fetchStart());
        dispatch(InspectionItemMasterSlice.actions.changeReportLoadingFlag(true));
        dispatch(InspectionItemMasterSlice.actions.updatePreviewReport(""));

        let result: ReportResult;

        // 通信処理
        // 日常
        if (type === InspectionType.Daily) {
            result = await ReportAdapter.instance.getDailyPreviewReport();

        }
        // 週間月例
        else if (type === InspectionType.Weekly) {
            result = await ReportAdapter.instance.getWeeklyPreviewReport();

        }
        // 現場完了
        else if (type === InspectionType.Completion) {
            result = await ReportAdapter.instance.getCompletionPreviewReport();

        } else {
            // 例外
            throw new Error("type");
        }

        // 異常確認
        if (result.isError || !result.reportUrl) {
            // 異常メッセージ
            dispatch(InspectionItemMasterSlice.actions.showErrorMessage(`帳票プレビュー情報 取得エラー：${result.errorMessage}`));
            dispatch(InspectionItemMasterSlice.actions.changeReportLoadingFlag(false));

        } else {
            // 正常
            // メッセージ
            dispatch(InspectionItemMasterSlice.actions.showSuccessMessage());
            dispatch(InspectionItemMasterSlice.actions.updatePreviewReport(result.reportUrl));

        }

    } catch (error) {
        // メッセージ
        dispatch(InspectionItemMasterSlice.actions.showErrorMessage(`${error}`));
        dispatch(InspectionItemMasterSlice.actions.changeReportLoadingFlag(false));

    } finally {
        // 処理中 OFF
        dispatch(InspectionItemMasterSlice.actions.fetchEnd());

    }
}
