import * as React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { ThemeUtility } from '../../../theme/theme-utility';


/**
 * Material-ui の テーマを使用するフレームを提供します。
 * @param param0
 */
export const ThemeFrame: React.FC<{}> = ({ children }) => {

    // Global State
    const isDark = useSelector((state: RootState) => state.theme.isDark);

    return (
        <ThemeProvider theme={ThemeUtility.selectTheme(isDark)}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};
