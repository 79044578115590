// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MaintenanceDivisionSlice, onExcuteAsync, getMaintenancePreviewReportAsync } from '../../../../../modules/slice/maintenance-division/maintenance-division-slice';
import { MaintenanceDivisionAdapter } from '../../../../../modules/adapter/maintenance-division/maintenance-division-adapter'
// type
import { MaintenanceDivisionInputItem } from '../../_types';
import { KeyValueItem, MachineType } from '../../../../../_types';
// component
import MaintenanceDivisionSearchCondition from './MaintenanceDivisionSearchCondition';

/**
 * 整備区分マスタ　検索エリアを提供します。
 * */
export const MaintenanceDivisionSearchConditionContainer: React.FC<{}> = (props) => {
    const dispatch = useDispatch();

    // redux store
    const machineTypeList = useSelector((state: RootState) => state.maintenanceDivisionMaster.selectBoxItem.MachineType);
    const reportUrl: string = useSelector((state: RootState) => state.maintenanceDivisionMaster.reportUrl ?? "");

    /** 検索ボタンのクリック処理を行います。 */
    const onClickSearch = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputItem: MaintenanceDivisionInputItem) => {

        // 非同期通信
        const promise = MaintenanceDivisionAdapter.instance.getAsync(
            {
                // セレクトボックスの 0:未選択 を void 0 に変換
                item: {
                    ...inputItem,
                    machineTypeId: inputItem.machineTypeId === 0 ? void 0 : inputItem.machineTypeId,
                }
            },
            { MachineType: machineTypeList }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickCreate = () => {
        dispatch(MaintenanceDivisionSlice.actions.chageCreating(true))
    }

    /** 帳票ボタンのクリック処理を行います。 */
    const onClickPreview = (type: MachineType) => {
        dispatch(getMaintenancePreviewReportAsync(type));

    }

    /** レポート読み込み完了時の処理を行います。 */
    const loadedReport = () => {
        dispatch(MaintenanceDivisionSlice.actions.changeReportLoadingFlag(false));
    }

    // 表示
    return (
        <MaintenanceDivisionSearchCondition
            onClickSearch={onClickSearch}
            onClickCreate={onClickCreate}
            machineTypeList={machineTypeList}
            onClickPreview={onClickPreview}
            reportUrl={reportUrl}
            loadedReport={loadedReport}
        />
    );
};
