// redux
import { createSlice, SliceCaseReducers, ValidateSliceCaseReducers, PayloadAction } from '@reduxjs/toolkit';
// component
import { Message } from '../../../components/MuiSnackbar/types';
import { CreateSliceUtility } from './create-slice-utility'
import { KeyValueItem } from '../../../_types';

/** ジェネリック型 State のインターフェースを提供します。 */
export interface MasterGenericState<T, U, V> {
    /** 結果情報を表します。*/
    result: T,
    /** 行が選択された状態かどうかを表します。*/
    isSelected: boolean,
    /** アイテム情報を表します。*/
    selectedRowItem?: U,
    /** リストアイテム情報を表します。*/
    selectBoxItem: V
    /** 新規作成状態かどうかを表します。 */
    isCreating: boolean,
    /** ロード中かどうかを表します。*/
    isLoading: boolean,
    /** メッセージを表示するかどうかを表します。*/
    isShowMessage: boolean,
    /** メッセージを表します。*/
    message?: Message,
    /** ダイアログ表示状態かどうかを表します。 */
    isDialogOpen: boolean,
    /** ユーザー情報が更新されたかどうかを表します。 */
    isUpdated?: boolean,
    /** 帳票プレビューURLを表します。 */
    reportUrl?: string,
    /** 帳票を読み込み中かどうかを表します。 */
    isReportLoading?: boolean,
}

/**
 * slice オブジェクトを作成します。
 * @param name A string name for this slice of state. Generated action type constants will use this as a prefix.
 * @param initialState The initial state value for this slice of state.
 * @param reducers An object containing Redux "case reducer" functions (functions intended to handle a specific action type, equivalent to a single case statement in a switch).
 */
export const createMasterGenericSlice = <T, U, V, Reducers extends SliceCaseReducers<MasterGenericState<T, U, V>>>
    ({
        name = '',
        initialState,
        reducers
    }: {
        name: string
        initialState: MasterGenericState<T, U, V>
        reducers: ValidateSliceCaseReducers<MasterGenericState<T, U, V>, Reducers>
    }) => {
    return createSlice({
        name,
        initialState,
        reducers: {

            /**
             * Stateのリセットを行います。
             * @param state
             */
            resetState(state: MasterGenericState<T, U, V>) {

                return { ...initialState }
            },

            /**
             * 通信開始処理を行います。
             * @param state
             * @param action
             */
            fetchStart(state) {
                state.isLoading = true;
                state.isShowMessage = false;
                state.message = void 0;
            },

            /**
             * If you want to write to values of the state that depend on the generic
             * (in this case: `state.data`, which is T), you might need to specify the
             * State type manually here, as it defaults to `Draft<GenericState<T>>`,
             * which can sometimes be problematic with yet-unresolved generics.
             * This is a general problem when working with immer's Draft type and generics.
             */
            /**
             * 通信成功時の処理を行います。
             * @param state
             * @param action
             */
            fetchSuccess(state: MasterGenericState<T, U, V>, action: PayloadAction<T>) {
                state.isLoading = false;
                state.result = action.payload;
            },

            /**
             * 通信失敗処理を行います。
             * @param state
             * @param action
             */
            fetchFailure(state: MasterGenericState<T, U, V>, action: PayloadAction<T>) {
                state.isLoading = false;
                state.isSelected = false;
                state.selectedRowItem = void 0;
                state.result = action.payload;
            },

            /**
             * 通信終了時の処理を行います。
             * @param state
             */
            fetchEnd(state) {
                state.isLoading = false;
            },

            /**
             * メッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showMessage(state, action: PayloadAction<Message>) {
                state.isShowMessage = true;
                state.message = action.payload;
            },

            /**
             * 通信成功時のメッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showSuccessMessage(state, action: PayloadAction<string | undefined>) {
                state.isShowMessage = true;
                state.message = {
                    type: "success",
                    text: action.payload ?? CreateSliceUtility.DefualtSuccessMessage,
                }
            },

            /**
             * 通信失敗時のメッセージの表示処理を行います。
             * @param state
             * @param action
             */
            showErrorMessage(state, action: PayloadAction<string | undefined>) {
                state.isShowMessage = true;
                state.message = {
                    type: "error",
                    text: `Error : ${action.payload ?? CreateSliceUtility.DefualtErrorMessage}`,
                }
            },

            /**
             * メッセージの非表示処理を行います。
             * @param state
             * @param action
             */
            hideMessage(state) {
                state.isShowMessage = false;
            },

            /**
             * 選択された行データの更新を行います。
             * @param state
             * @param action
             */
            onSelectedItem(state: MasterGenericState<T, U, V>, action: PayloadAction<U>) {
                state.isSelected = true;
                state.selectedRowItem = action.payload;

            },

            /**
             * 選択された行データの更新を行います。
             * @param state
             * @param action
             */
            clearSelectedItem(state: MasterGenericState<T, U, V>) {
                state.isSelected = false;
                state.selectedRowItem = void 0;
            },


            /**
             * 新規作成状態を変更します
             * @param state
             * @param action
             */
            chageCreating(state, action: PayloadAction<boolean>) {
                state.isCreating = action.payload
                state.isSelected = false;
                state.selectedRowItem = void 0;
            },

            /**
             * セレクトボックスを更新します。
             * @param state
             * @param action
             */
            getSelectBoxItem(state: MasterGenericState<T, U, V>, action: PayloadAction<V>) {
                state.selectBoxItem = action.payload
            },

            /**
             * ダイアログの開閉状態を変更します。
             * @param state
             * @param action
             */
            toggleDialog(state, action: PayloadAction<boolean>) {
                state.isDialogOpen = action.payload;
            },

            /**
             * 帳票プレビュー情報を更新します。
             * @param state
             * @param action
             */
            updatePreviewReport(state: MasterGenericState<T, U, V>, action: PayloadAction<string>) {
                state.reportUrl = action.payload;
            },

            /**
             * 帳票ビューワの読み込み完了フラグを切り替えます。
             * @param state
             * @param action
             */
            changeReportLoadingFlag(state: MasterGenericState<T, U, V>, action: PayloadAction<boolean>) {
                state.isReportLoading = action.payload;
            },
            ...reducers
        }
    })
}
