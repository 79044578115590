//react
import * as React from 'react';
// material-ui
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {Paper,Typography,} from '@material-ui/core';
import {
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/lab';
//type
import { HomeDirectoryType } from '../../_types';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: 'flex',
            border: `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
            justifyContent: "center",
        },
        root: {
            "&.Mui-selected": {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            },
            "&.Mui-selected:hover": {
                backgroundColor: theme.palette.primary.main,
            }
        },
        grouped: {
            margin: theme.spacing(0.5),
            border: 'none',
            '&:not(:first-child)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-child': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }),
);

/** ホームフォルダ選択のトグルボタングループ の Props を提供します */
interface Props {
    /** ホームフォルダを表します。*/
    type: HomeDirectoryType,
    /** 選択時のイベント処理を表します */
    onChange: (event: React.MouseEvent<HTMLElement>, selectedType: HomeDirectoryType | null) => void,
}

/**
 * トグルボタングループを表示します。
 * */
export function HomeDirTypeToggle(props: Props): JSX.Element {

    const classes = useStyles();

    return (
        <div>
            <Paper elevation={0} className={classes.paper}>
                <ToggleButtonGroup
                    classes={{ grouped: classes.grouped }}
                    size="large"
                    value={props.type}
                    exclusive
                    onChange={(event, value) => props.onChange(event, value)}
                    aria-label="home-directory-type"
                >
                    <ToggleButton
                        classes={{ root: classes.root }}
                        value={HomeDirectoryType.Document}
                        aria-label={HomeDirectoryType.Document}>
                        <Typography>
                            ドキュメント
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        classes={{ root: classes.root }}
                        value={HomeDirectoryType.OnlineHelp}
                        aria-label={HomeDirectoryType.OnlineHelp}>
                        <Typography>
                            オンラインヘルプ
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Paper>
        </div>
    );
}
