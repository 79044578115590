import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import green from '@material-ui/core/colors/green';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // チップ
        chip: {
            marginLeft: theme.spacing(1),
            color: theme.palette.secondary.contrastText,
            backgroundColor: green[600],
        },
    })
);


/**
 * サインインユーザーのアイコンを表示します。
 */
const SigninUserTag: React.FC<{}> = () => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Chip
            size="small"
            className={classes.chip}
            label={<HowToRegIcon />}
        />
    );
}

export default SigninUserTag;
