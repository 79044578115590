// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { InspectionItemMasterSlice } from '../../../../../modules/slice/inspection-item/inspection-item-slice';
// type
import { InspectionItemListItem } from '../../_types';
// component
import InspectionItemResultTable from './InspectionItemResultTable'

/** 点検項目マスタテーブルを提供します */
export const InspectionItemResultTableContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const data: InspectionItemListItem[] = useSelector((state: RootState) => state.inspectionItemMaster.result.items ?? []);
    const selectedRow = useSelector((state: RootState) => state.inspectionItemMaster.selectedRowItem);
    /**
    * 行選択時のイベント処理を行います。
    * @param event
    * @param rowData
    */
    const onRowClick = (rowData: InspectionItemListItem, event?: React.MouseEvent<Element, MouseEvent>) => {
        // 選択された行データを store に保持
        dispatch(InspectionItemMasterSlice.actions.onSelectedItem({ ...rowData }))
    };

    return (
        <InspectionItemResultTable
            dataSource={data.map(item => ({ ...item }))} // storeにあるデータをそのまま渡すとErrorになるので複製・参照を切る
            onRowClick={onRowClick}
            selectedRow={selectedRow}
        />

    );

};
