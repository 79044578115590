// redux
import { Dispatch } from 'redux';
// shared
import { RecordsSliceState, createReportListSlice } from '../shared/create-records-slice';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { changeError } from '../global-header/global-header-slice';
// type
import { DailyInspectionRecordAdapter } from '../../adapter/daily-inspection-record/daily-inspection-record-adapter';
import { DailyInspectionRecordGetRequest, DailyInspectionRecordGetResult, DailyInspectionRecordGetDocumentRequest, DailyInspectionRecordGetDocumentResult } from '../../adapter/daily-inspection-record/types';
import { DailyInspectionRecordListItem, DailyInspectionReportItem } from '../../../pages/ConstructionMachineMaster/DailyInspectionRecord/_types';
import { ReportAdapter } from '../../adapter/report/report-adapter';
import { DailyReportRequest, UpdateDailyReportRequest } from '../../adapter/report/type';


// State の初期値を設定します。
const initialState: RecordsSliceState<DailyInspectionRecordListItem, DailyInspectionReportItem> = {
    isLoading: false,
    isShowMessage: false,
    isOpenViewer: false,
    isUpdated: false,
    isReportLoading: false,
}

/** 日常点検記録 Slice を 提供します。*/
export const DailyInspectionRecordSlice = createReportListSlice({
    // State Reducer Action を生成
    name: 'DailyInspectionRecord',
    initialState: initialState,
    reducers: {},
})

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetAsync = (parameter: DailyInspectionRecordGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(DailyInspectionRecordSlice.actions.fetchStart());

        // 通信処理
        const getResultWaiter = DailyInspectionRecordAdapter.instance.getAsync(parameter);
        const machineErrorResultWaiter = SharedAdapter.instance.getErrorAsync({ machineId: parameter.item.machineId });

        // まとめて取得
        const promiseResult = await Promise.all([getResultWaiter, machineErrorResultWaiter]);
        const getResult = promiseResult[0];
        const machineErrorResult = promiseResult[1];

        // 通信終了
        if (getResult.isError) {
            // 異常
            dispatch(DailyInspectionRecordSlice.actions.fetchFailure());

            // メッセージ
            dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));

        } else if (machineErrorResult.isError) {
            // 異常
            // メッセージ
            dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(machineErrorResult.errorMessage ?? ""));

        } else {
            const isCtuEror = !machineErrorResult.item ? false : true; // 空文字 -> 正常(false)
            // ヘッダ　エラー情報
            dispatch(changeError(isCtuEror))

            // 正常
            dispatch(DailyInspectionRecordSlice.actions.setItems(getResult.items));
            // メッセージ
            dispatch(DailyInspectionRecordSlice.actions.showSuccessMessage());

        }

    } catch (error) {

        //例外
        dispatch(DailyInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(`${error}`));

    } finally {

        // 処理中 OFF
        dispatch(DailyInspectionRecordSlice.actions.fetchEnd());
    }
}

/**
 * 帳票取得処理を行います。
 * @param parameter パラメーターを指定します。
 */
export const onGetDailyReportAsync = (parameter: DailyReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(DailyInspectionRecordSlice.actions.fetchStart());
        dispatch(DailyInspectionRecordSlice.actions.changeReportLoadingFlag(true));

        // 通信処理
        const result = await ReportAdapter.instance.getDailyReport(parameter);

        // 通信終了
        if (result.isError || result.stampItems == null) {
            // 異常
            // メッセージ
            dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            dispatch(DailyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // メッセージ
            dispatch(DailyInspectionRecordSlice.actions.showSuccessMessage());

            // 正常
            dispatch(DailyInspectionRecordSlice.actions.setReport(
                {
                    reportUrl: result.reportUrl,
                    stampItems: result.stampItems,
                }
            ));
            dispatch(DailyInspectionRecordSlice.actions.toggleViewre(true));
        }

    } catch (error) {

        //例外
        dispatch(DailyInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(`${error}`));
        dispatch(DailyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(DailyInspectionRecordSlice.actions.fetchEnd());
    }
}


/**
 * 検印更新処理を行います。
 * @param updateParam 更新パラメーターを指定します。
 * @param getParam 取得パラメーターを指定します。
 */
export const onUpdateDailyInspectedStampAsync = (updateParam: UpdateDailyReportRequest, getParam: DailyReportRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(DailyInspectionRecordSlice.actions.fetchStart());
        dispatch(DailyInspectionRecordSlice.actions.changeReportLoadingFlag(true));

        // 更新フラグ OFF
        dispatch(DailyInspectionRecordSlice.actions.changeUpdatedFlag(false));

        // URL クリア
        dispatch(DailyInspectionRecordSlice.actions.clearReport());

        // 通信処理
        const result = await ReportAdapter.instance.updateDailyInspectedStamp(updateParam);

        // 通信終了
        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(result.errorMessage ?? ""));
            dispatch(DailyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

        } else {
            // 更新完了

            // 通信処理
            const getResult = await ReportAdapter.instance.getDailyReport(getParam);

            // 通信終了
            if (getResult.isError || getResult.stampItems == null) {
                // 異常
                // メッセージ
                dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(getResult.errorMessage ?? ""));
                dispatch(DailyInspectionRecordSlice.actions.changeReportLoadingFlag(false));
            } else {
                // メッセージ
                dispatch(DailyInspectionRecordSlice.actions.showSuccessMessage());

                // 正常
                dispatch(DailyInspectionRecordSlice.actions.setReport(
                    {
                        reportUrl: getResult.reportUrl,
                        stampItems: getResult.stampItems,
                    }
                ));
                dispatch(DailyInspectionRecordSlice.actions.changeUpdatedFlag(true));
            }
        }

    } catch (error) {

        //例外
        dispatch(DailyInspectionRecordSlice.actions.fetchFailure());

        // メッセージ
        dispatch(DailyInspectionRecordSlice.actions.showErrorMessage(`${error}`));

        dispatch(DailyInspectionRecordSlice.actions.changeReportLoadingFlag(false));

    } finally {

        // 処理中 OFF
        dispatch(DailyInspectionRecordSlice.actions.fetchEnd());
    }
}
