// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// material-ui
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { MachineMonitoringListItem } from '../../_types';

import MuiCancelButton from '../../../../../components/Buttons/MuiCancelButton';
import MuiUnlockButton from '../../../../../components/Buttons/MuiUnlockButton';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ヘッダー
        header: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },

        // タイトル
        title: {
            alignItems: 'center',
            display: 'inline-flex',
            // アイコン
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
        },
        // 施工機Box
        machinBox: {
            backgroundColor: fade(theme.palette.secondary.main, 0.3),
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
        // 施工機タイトル
        machineTitle: {
            fontWeight: "bolder",
        },
        // 施工機情報項目
        machineItem: {
            marginLeft: theme.spacing(1),
        },
        // ボタン
        button: {
            minWidth: 120,
        },

    }),
);

/** アンロック確認ダイアログを表示します。 */
interface Props {
    /** 選択された項目を表します。  */
    selectedRowItem: MachineMonitoringListItem,
    /** ダイアログが表示されているかどうかを表します。 */
    isOpen: boolean,
    /** Yesボタンクリック時の処理を表します。 */
    onUnlockClick: () => void,
    /** ダイアログの閉じる処理を表します。 */
    handleClose: () => void,
}

/**
 *  アンロック確認ダイアログを表示します。
 * @param props
 */
const UnlockDialog: React.FC<Props> = (props: Props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** State */
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.isOpen)
    }, [props.isOpen])

    return (
        <>
            {props.selectedRowItem &&
                <Dialog
                    keepMounted
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="unlock-dialog-title"
                    aria-describedby="unlock-dialog-description"
                    open={open}
                    onClose={() => props.handleClose()}
                >

                    {/* タイトル */}
                    <DialogTitle disableTypography id="alert-dialog-title">
                        <Typography variant="h6" className={classes.title}>
                            <InfoIcon fontSize="large" />施工機ロック解除
                    </Typography>
                    </DialogTitle >
                    <DialogContent>
                        {/* message */}
                        <Typography id="alert-dialog-description">
                            以下の施工機のロックを解除します。よろしいですか？
                    </Typography>

                        {/* 各施工機 */}
                        <Paper className={classes.machinBox} >

                            {/* ベースマシン */}
                            <Box>
                                <Typography className={classes.machineTitle}>
                                    ベースマシン
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>社番</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.baseMachine.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>メーカー</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.baseMachine.manufacturer}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>型式</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.baseMachine.model}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Divider />

                            {/* オーガー */}
                            <Box>
                                <Typography className={classes.machineTitle}>

                                    オーガー
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>社番</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.auger.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>メーカー</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.auger.manufacturer}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>型式</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.auger.model}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Divider />

                            {/* 昇降装置 */}
                            <Box>
                                <Typography className={classes.machineTitle}>

                                    昇降装置
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>社番</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.lift.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>メーカー</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.lift.manufacturer}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.machineItem}>型式</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="subtitle1">{props.selectedRowItem.lift.model}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Paper>

                    </DialogContent>

                    <DialogActions>
                        <MuiCancelButton
                            className={classes.button}
                            onClick={() => props.handleClose()}
                        />
                        <MuiUnlockButton
                            className={classes.button}
                            onClick={() => props.onUnlockClick()}
                        />
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}
export default UnlockDialog
