// react
import * as React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';

import SignoutDialog from './SignoutDialog';
import { UserAdapter } from '../../../../../modules/adapter/user/user-adapter';
import { onSignOutAsync } from '../../../../../modules/slice/authentication/authentication-slice';
import { UserRequest } from '../../../../../modules/adapter/user/types';
import { UserMasterSlice } from '../../../../../modules/slice/user/user-slice';


/**
 * サインアウトダイアログを表示します。
 * @param props
 */
export const SingoutDialogContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const isOpen = useSelector((state: RootState) => state.userMaster.isUpdated ?? false);

    /** ボタンのクリック処理を行います。 */
    const onClick = async () => {

        // 非同期通信
        await dispatch(onSignOutAsync());

    }

    /**
　　 * ダイアログを非表示にします。
　　 * @param isOpne
　　 */
    const closeDialog = () => {
        dispatch(UserMasterSlice.actions.toggleDialog(false));
    };

    return (

        <SignoutDialog
            isOpen={isOpen}
            onClick={onClick}
            handleClose={closeDialog}
        />
    );
}
