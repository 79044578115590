// react
import * as React from 'react';
// material-ui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FaRegArrowAltCircleDown, FaRegArrowAltCircleUp, FaRegStopCircle } from 'react-icons/fa';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// component
import { MachineStatusMonitoringUpDownState } from '../_types/';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // ルート
        root: {
            minWidth: "300px",
            minHeight: "136px",

        },
        // ボディ
        body: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        // タイトル
        title: {
            fontSize: "1.25rem",
        },
        // テキスト
        text: {
            fontSize: "1.5rem",

        },
        // アイコン
        icon: {
            '& svg': {
                fontSize: "4rem",
                marginRight: "0.5rem",
            },
        },
        iconGreen: {
            color: theme.palette.success.main,
        },
        iconRed: {
            color: theme.palette.error.main,
        },
        iconBlue: {
            color: theme.palette.info.main,
        },
        iconGray: {
            color: theme.palette.text.disabled,
        },
    }),
);

/** 昇降状態カードの Props を提供します。 */
interface Props {
    /** 昇降状態を表します。 */
    status?: MachineStatusMonitoringUpDownState,

};

/** 昇降状態カードを提供します。 */
const LiftingStatusCard: React.FC<Props> = (props) => {

    /** スタイルクラスを表します。 */
    const classes = useStyles();

    /** 昇降状態を表示します。 */
    function renderStatusIcon(status: MachineStatusMonitoringUpDownState | undefined): JSX.Element {


        let icon: JSX.Element | null = null;
        let text: string = "";

        switch (status) {
            case MachineStatusMonitoringUpDownState.NotAvailable:
                icon = <FaRegStopCircle />;
                text = "---";
                break;
            case MachineStatusMonitoringUpDownState.Stop:
                icon = <FaRegStopCircle className={classes.iconGreen} />;
                text = "停止中";
                break;
            case MachineStatusMonitoringUpDownState.Up:
                icon = <FaRegArrowAltCircleUp className={classes.iconRed} />;
                text = "上昇中";
                break;
            case MachineStatusMonitoringUpDownState.Down:
                icon = <FaRegArrowAltCircleDown className={classes.iconBlue} />;
                text = "下降中";
                break;
            default:
                // エラー
                icon = <></>;
                text = "";
                break;
        };


        return (
            <>
                <span className={classes.icon} >
                    {icon}
                </span>
                <div className={classes.text}>
                    <div>{text}</div>
                </div>
            </>
        );

    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography component="p" variant="subtitle1" className={classes.title}>
                    昇降状態
                </Typography>

                <div className={classes.body}>
                    {/* 状態表示 */}
                    {
                        // CTU接続状態を確認
                        props.status === void 0
                            ? (
                                //CTU未接続の場合
                                <>
                                    <span className={classes.icon} >
                                        <RemoveCircleIcon className={classes.iconGray} />

                                    </span>
                                    <div className={classes.text}>
                                        <div>CTU未接続</div>
                                    </div>
                                </>
                            )
                            : (
                                // CTU接続中の場合
                                renderStatusIcon(props.status)
                            )
                    }
                </div>
            </CardContent>
        </Card>
    );
};

export default LiftingStatusCard;
