// react
import * as React from 'react';
import { useState, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { InspectionItemMasterSlice, onExcuteAsync } from '../../../../../modules/slice/inspection-item/inspection-item-slice';
import { InspectionItemAdapter } from '../../../../../modules/adapter/inspection-item/inspection-item-adapter'
// type
import { InspectionItemInputItem, InspectionItemValidateInputItem } from '../../_types';
import { KeyValueItem } from '../../../../../_types';
// component
import InspectionItemDetailCondition from './InspectionItemDetailCondition'
import { MuiTextFieldUtility } from '../../../../../components/MuiTextField/mui-text-field-utility';

/**
 * 点検項目マスタ　詳細エリアを提供します。
 * */
export const InspectionItemDetailConditionContainer: React.FC<{}> = (props) => {

    const dispatch = useDispatch();

    // redux store
    const isCreating = useSelector((state: RootState) => state.inspectionItemMaster.isCreating);
    const selectedItem = useSelector((state: RootState) => state.inspectionItemMaster.selectedRowItem);
    const inspectionGroupList = useSelector((state: RootState) => state.inspectionItemMaster.selectBoxItem.InspectionGroup);
    const inspectionDivisionList = useSelector((state: RootState) => state.inspectionItemMaster.selectBoxItem.InspectionDivision);

    const initialValues: InspectionItemInputItem = {
        id: selectedItem?.id,
        name: selectedItem?.name,
        inspectionDivisionId: selectedItem?.inspectionDivisionId,
        inspectionGroupId: selectedItem?.inspectionGroupId,
        weight: selectedItem?.weight,
        unit: selectedItem?.unit,
        inputLowerLimit: selectedItem?.inputLowerLimit ? Number.parseInt(selectedItem.inputLowerLimit, 10) : void 0,
        inputHigherLimit: selectedItem?.inputHigherLimit ? Number.parseInt(selectedItem.inputHigherLimit, 10) : void 0,
        lightAlarmLowerLimit: selectedItem?.lightAlarmLowerLimit ? Number.parseInt(selectedItem.lightAlarmLowerLimit, 10) : void 0,
        lightAlarmHigherLimit: selectedItem?.lightAlarmHigherLimit ? Number.parseInt(selectedItem.lightAlarmHigherLimit, 10) : void 0,
        heavyAlarmLowerLimit: selectedItem?.heavyAlarmLowerLimit ? Number.parseInt(selectedItem.heavyAlarmLowerLimit, 10) : void 0,
        heavyAlarmHigherLimit: selectedItem?.heavyAlarmHigherLimit ? Number.parseInt(selectedItem.heavyAlarmHigherLimit, 10) : void 0,
        remarks: selectedItem?.remarks,
        lockVersion: selectedItem?.lockVersion,
    }

    /** 点検区分セレクトボックス値を表します。 */
    const [selectData, setSelectData] = useState<KeyValueItem<number, string>[]>([]);

    /** バリデーション情報を表します。 */
    const [validate, setValidate] = useState<InspectionItemValidateInputItem>({});

    /** 詳細項目の入力値を表します。 */
    const [values, setValues] = useState<InspectionItemInputItem>(initialValues);

    // 行選択時の入力項目更新
    useEffect(() => {

        setValues((preValue) => ({
            id: selectedItem?.id,
            name: selectedItem?.name,
            inspectionDivisionId: selectedItem?.inspectionDivisionId,
            inspectionGroupId: selectedItem?.inspectionGroupId,
            weight: selectedItem?.weight,
            unit: selectedItem?.unit,
            inputLowerLimit: selectedItem?.inputLowerLimit ? Number.parseInt(selectedItem.inputLowerLimit, 10) : void 0,
            inputHigherLimit: selectedItem?.inputHigherLimit ? Number.parseInt(selectedItem.inputHigherLimit, 10) : void 0,
            lightAlarmLowerLimit: selectedItem?.lightAlarmLowerLimit ? Number.parseInt(selectedItem.lightAlarmLowerLimit, 10) : void 0,
            lightAlarmHigherLimit: selectedItem?.lightAlarmHigherLimit ? Number.parseInt(selectedItem.lightAlarmHigherLimit, 10) : void 0,
            heavyAlarmLowerLimit: selectedItem?.heavyAlarmLowerLimit ? Number.parseInt(selectedItem.heavyAlarmLowerLimit, 10) : void 0,
            heavyAlarmHigherLimit: selectedItem?.heavyAlarmHigherLimit ? Number.parseInt(selectedItem.heavyAlarmHigherLimit, 10) : void 0,
            remarks: selectedItem?.remarks,
            lockVersion: selectedItem?.lockVersion,
        }));

        // バリデーション情報クリア
        clearValidate();


    }, [selectedItem]);

    //行選択時の入力項目更新
    useEffect(() => {
        const option = inspectionDivisionList.find(item => item.key === selectedItem?.inspectionGroupId);

        setSelectData((preValue) => (option?.value ?? []))

    }, [selectedItem, inspectionDivisionList]);

    /**
    * 連動リストボックス値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChangeSelectBox = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof InspectionItemInputItem) => {

        // 値を選別
        const target = event.target;
        const value = target.value;

        const index = Number.parseInt(value, 10);
        const option = inspectionDivisionList.find(item => item.key === index);

        // 入力項目更新
        setValues({
            ...values,
            [name]: value,
            inspectionDivisionId: void 0,
        });

        // 連動したセレクトボックス更新
        setSelectData(option?.value ?? []);
    }

    /**
    * 入力値の変更処理を行います。
    * @param name　入力項目を指定します。
    */
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: keyof InspectionItemInputItem) => {
        // 値を選別
        const target = event.target;
        const value = target.type === "number" ? MuiTextFieldUtility.LimitationNumber(Number.parseInt(target.value, 10)) : target.value;

        // 入力項目更新
        setValues({ ...values, [name]: value });
    };


    /** 新規作成ボタンのクリック処理を行います。 */
    const onClickAdd = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = InspectionItemAdapter.instance.addAsync(
            { item: values },
            {
                InspectionGroup: inspectionGroupList,
                InspectionDivision: inspectionDivisionList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 更新ボタンのクリック処理を行います。 */
    const onClickUpdate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション
        if (onValidateInputItem()) return;

        // 非同期通信
        const promise = InspectionItemAdapter.instance.updateAsync(
            { item: values },
            {
                InspectionGroup: inspectionGroupList,
                InspectionDivision: inspectionDivisionList,
            }
        );
        await dispatch(onExcuteAsync(promise));
    }

    /** 削除ボタンのクリック処理を行います。 */
    const onClickRemove = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        // 確認ダイアログ表示
        dispatch(InspectionItemMasterSlice.actions.toggleDialog(true));

    }

    /** キャンセルボタンのクリック処理を行います。 */
    const onClickCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーション情報クリア
        clearValidate();

        dispatch(InspectionItemMasterSlice.actions.chageCreating(false))
    }

    /**
    * バリデーション情報のクリアを行います。
    * @returns 検証結果を返します。
    * */
    const clearValidate = () => {
        setValidate(preValue => ({}))
    }

    /**
    * 入力内容のバリデーションを行います。
    * @returns 検証結果を返します。
    * */
    const onValidateInputItem = (): boolean => {
        let result = false;
        let inspectionGroup = "";
        let inspectionDivision = "";
        let weight = "";
        let inspectionItem = "";

        // 点検グループ
        if (!values.inspectionGroupId) {
            inspectionGroup = "点検グループを選択してください";
            result = true;
        }

        // 点検区分
        if (!values.inspectionDivisionId) {
            inspectionDivision = "点検区分を選択してください";
            result = true;
        }

        // 重み
        if (values.weight == null) {
            weight = "No.を入力してください";
            result = true;
        }

        // 点検項目
        if (!values.name) {
            inspectionItem = "点検項目名を入力してください";
            result = true;
        }

        // エラー文字をセット
        setValidate({
            inspectionGroup,
            inspectionDivision,
            weight,
            inspectionItem,
        });

        // 結果
        return result;
    }

    // 表示
    return (
        <InspectionItemDetailCondition
            initialValues={values}
            isCreating={isCreating}
            onClickAdd={onClickAdd}
            onClickCancel={onClickCancel}
            onClickRemove={onClickRemove}
            onClickUpdate={onClickUpdate}
            inspectionGroupList={inspectionGroupList}
            inspectionDivisionList={selectData}
            onChange={onChange}
            onChangeSelectBox={onChangeSelectBox}
            validateError={validate}
        />
    );
};
