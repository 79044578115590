// react
import * as React from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { MachineMonitoringSlice, onUnlockAsync, onCloseDialog } from '../../../../../modules/slice/machine-monitoring/machine-monitoring-slice';

// component
import { MachineMonitoringListItem } from '../../_types';
import UnlockDialog from './UnlockDialog';

/**
 *  アンロック確認ダイアログを表示します。
 * @param props
 */
export const UnlockDialogContainer: React.FC<{}> = () => {

    const dispatch = useDispatch();

    // redux store
    const isOpenDialog = useSelector((state: RootState) => state.machineMonitoring.item.isOpenDialog);
    const selectedRowItem = useSelector((state: RootState) => state.machineMonitoring.item.selectedRowItem);
    const inputItem = useSelector((state: RootState) => state.machineMonitoring.item.inputItem);

    /** ロック解除クリック処理を行います。 */
    const onUnlockClickAsync = async () => {

        if (!selectedRowItem || !selectedRowItem.tabletId) {
            dispatch(MachineMonitoringSlice.actions.showErrorMessage("タブレットIDが指定されていません"))
            return;
        }

        // ロック解除
        await dispatch(onUnlockAsync({ tabletId: selectedRowItem.tabletId }, { item: inputItem }));
    }

    /**
     * ダイアログを非表示にします。
     */
    const closeDialog = () => {
        dispatch(onCloseDialog({ item: inputItem }));
    };

    return (
        <>
            {selectedRowItem && (
                <UnlockDialog
                    isOpen={isOpenDialog}
                    selectedRowItem={selectedRowItem}
                    onUnlockClick={onUnlockClickAsync}
                    handleClose={closeDialog}
                />
            )}
        </>
    )
}
