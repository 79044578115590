// react
import * as React from 'react';
// material-ui
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

/** スタイルを提供します。 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // テーブルソートヘッダー
        sortHeader: {
            // ソートされているカラム ヘッダー
            "& .MuiTableSortLabel-active": {
                color: theme.palette.text.primary,
            },
            // ソートアイコンカラー
            "& .MuiTableSortLabel-active .MuiTableSortLabel-icon": {
                color: `${theme.palette.text.primary}!important` as string,
            },
            // material-table スクロールバーをデフォルトに上書き
            "& > div > div > div > div": {
                overflowY: "visible !important",
            }
        },
    })
);

/** マスタ系のテーブルのフレームを提供します */
const MasterTableFrame: React.FC<{}> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    return (
        <Box component="span" className={classes.sortHeader}>
            {/* 各テーブルが挿入される */}
            {props.children}
        </Box>

    );

};

export default MasterTableFrame;
