import { MachineErrorHistoryListItem, MachineErrorHistoryGraphItem } from '../../../pages/ConstructionMachineMaster/AlertRecord/_types';
import { MachineErrorHistoryItem, KeyValuePairOfDateTimeAndInteger } from '../../swagger-clients';
import { SharedUtility } from '../../shared/shared-utility';

/**
 * `アラート履歴` 関連の ユーティリティ機能を提供します。
 */
export class MachineErrorHistoryUtility {

    //#region フィールド

    //#endregion フィールド

    //#region メソッド

    /**
     * 項目を一覧用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toListItem(item?: MachineErrorHistoryItem[]): MachineErrorHistoryListItem[] {
        const result: MachineErrorHistoryListItem[] = [];
        if (item == null) {
            return result;
        }

        for (const source of item) {
            // 不正値は無視
            if (source.errorOccurredAt == null) {
                continue;
            }

            const item: MachineErrorHistoryListItem = {
                occurredAt: SharedUtility.toDateTimeString(new Date(source.errorOccurredAt)),
                detail: source.errorContent ?? "",
            };
            result.push(item);
        }
        return result;
    }

    /**
     * 項目をグラフ用の項目に変換します。
     *
     * @param items 変換元の値を指定します。
     * @returns 変換結果を返します。
     */
    public static toGraphItem(beginAt: Date | null, endAt: Date | null, item?: KeyValuePairOfDateTimeAndInteger[]): MachineErrorHistoryGraphItem[] {
        const result: MachineErrorHistoryGraphItem[] = [];
        if (item == null) {
            return result;
        }

        for (const source of item) {
            // 不正値は無視
            if (source.key == null) {
                continue;
            }

            const item: MachineErrorHistoryGraphItem = {
                dateAt: SharedUtility.toDateTimeString(new Date(source.key)),
                count: source.value,
            };
            result.push(item);
        }
        return result;
    }

    //#endregion メソッド
}
