// react
import * as React from 'react';
import { useState } from 'react';
// materiali-table
import MaterialTable, { Column } from 'material-table';
import Box from '@material-ui/core/Box';
import { MuiTableIcons, MuiLocalizationJapanese } from '../../../../../components/MuiTable';
// type
import { MachineErrorHistoryListItem } from '../../_types';
// 共通 component
import OverflowTooltip from '../../../../../components/OverflowTooltip';
import { ConstructionMchineMasterOptions } from '../../../Shared/ConstructionMchineMasterTableOptions';
import { useStyles } from '../../../Shared/ConstructionMchineMasterTableStyles';

/** アラート履歴テーブル項目の Props を提供します。 */
interface Props {
    /** データソースを表します。 */
    dataSource: MachineErrorHistoryListItem[],
};

/** アラート履歴テーブル項目の State を提供します。 */
interface State {
    /** テーブルのカラムを表します。 */
    columns: Column<any>[]
}

/** アラート履歴テーブルのカラムを表します。 */
const columnData: Column<any>[] = [
    {
        title: "発生日時",
        field: "occurredAt",
        width: 300,
    },
    {
        title: "詳細",
        field: "detail",
        render: (rowData: MachineErrorHistoryListItem) => (
            <OverflowTooltip title={rowData.detail}>
                <>{rowData.detail}</>
            </OverflowTooltip>
        ),
    },
];

/** アラート履歴テーブルを提供します */
const AlertRecordTable: React.FC<Props> = (props) => {

    /** スタイルクラスを提供します。 */
    const classes = useStyles();

    /** カラム情報を提供します。 */
    const [values] = useState<State>({
        columns: columnData,
    });

    return (
        <Box component="span" className={classes.sortHeader}>
            <MaterialTable
                icons={MuiTableIcons}
                localization={MuiLocalizationJapanese}
                options={{
                    ...ConstructionMchineMasterOptions,
                    tableLayout: "fixed",
                    maxBodyHeight: "35vh", // 固定
                    minBodyHeight: "35vh", // 固定
                }}
                columns={values.columns}
                data={props.dataSource}
            />
        </Box>
    );

};

export default AlertRecordTable;
