// redux
import { Dispatch } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
// shared
import { ExtendGenericState, createExtendGenericSlice } from '../shared/create-extend-generic-slice';
import { updateCtuMachineError, resetCtuMachineError, setCtuMachineErrorOn, } from '../global-header/global-header-slice';
// type
import { MachineMonitoringAdapter } from '../../adapter/machine-monitoring/machine-monitoring-adapter';
import { SharedAdapter } from '../../adapter/shared/shared-adapter';
import { MachineMonitoringResult, MachineMonitoringUnlockRequest, MachineMonitoringGetRequest } from '../../adapter/machine-monitoring/types';
import { MachineMonitoringListItem, MachineMonitoringInputItem, MachineMonitoringSearchType } from '../../../pages/ConstructionMachineMonitor/MainMonitor/_types';

/** 施工機管理状況一覧 アイテム情報 State の インターフェイスを提供します。 */
interface MahcineMonitoringItemState {
    /** 選択された項目を表します。  */
    selectedRowItem?: MachineMonitoringListItem,
    /** ロック解除のダイアログが表示されているかどうかを表します。 */
    isOpenDialog: boolean,
    /** タイマーIDを表します。 */
    timerId: number;
    /** 入力項目を表します。 */
    inputItem: MachineMonitoringInputItem,
}

/** リロードのインターバル時間[ms]を表します。 */
const ReloadTimerInterval = 60000;

// State の初期値を設定します。
const initialState: ExtendGenericState<MachineMonitoringResult, MahcineMonitoringItemState> = {
    isLoading: false,
    isShowMessage: false,
    result: {
        items: [],
        isError: false,
    },
    item: {
        isOpenDialog: false,
        timerId: 0,
        inputItem: {
            type: MachineMonitoringSearchType.None,
            machineKeyword: "",
            siteKeyword: "",
            reportKeyword: "",
        }
    }
}

/** 取得 Slice を 作成します。*/
export const MachineMonitoringSlice = createExtendGenericSlice({
    // State Reducer Action を生成
    name: 'MachineMonitoring',
    initialState: initialState,
    reducers: {
        /**
         * ダイアログの開閉処理を行います。
         * @param state
         * @param action
        */
        toggleDialog(state: ExtendGenericState<MachineMonitoringResult, MahcineMonitoringItemState>, action: PayloadAction<boolean>) {
            state.item.isOpenDialog = action.payload;
        },

        /**
         * 解除アクション(Ctrl + Click)入力時の処理を行います。
         * @param state
         * @param action
        */
        selectedRow(state: ExtendGenericState<MachineMonitoringResult, MahcineMonitoringItemState>, action: PayloadAction<MachineMonitoringListItem>) {
            state.item.selectedRowItem = action.payload;
        },

        /**
         * 更新タイマーを停止します。
         * @param state
         */
        stopReloadTimer(state: ExtendGenericState<MachineMonitoringResult, MahcineMonitoringItemState>) {
            clearTimeout(state.item.timerId);
            state.item.timerId = 0;
        },

        /**
         * 更新タイマーを開始します。
         * @param state
         */
        startReloadTimer(state: ExtendGenericState<MachineMonitoringResult, MahcineMonitoringItemState>, action: PayloadAction<number>) {
            clearTimeout(state.item.timerId);
            state.item.timerId = action.payload;
        },

        /**
         * 入力項目を変更します。
         * @param state 現在の State 情報を指定します。
         * @param action  Action を指定します。
         */
        changeInputItem(state: ExtendGenericState<MachineMonitoringResult, MahcineMonitoringItemState>, action: PayloadAction<MachineMonitoringInputItem>) {
            state.item.inputItem = action.payload;
        },

        /**
         * 入力項目をクリアします。
         * @param state
         */
        clearInputItem(state: ExtendGenericState<MachineMonitoringResult, MahcineMonitoringItemState>) {
            state.item.inputItem = {
                type: MachineMonitoringSearchType.None,
                machineKeyword: "",
                siteKeyword: "",
                reportKeyword: "",
            }

        },


    },
})

/**
 * 一覧取得処理を行います。
 * @param parameter
 */
export const onGetAsync = (parameter: MachineMonitoringGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineMonitoringSlice.actions.fetchStart());
        // タイマー停止
        dispatch<any>(stopReloadTimer());

        // 通信処理
        const machineWaiter = MachineMonitoringAdapter.instance.getAsync(parameter);
        const errorMachineWaiter = SharedAdapter.instance.getAllMachineErrorAsync();

        //まとめて取得
        const result = await Promise.all([machineWaiter, errorMachineWaiter]) // 直接配列に渡したらエラーなる
        const machineResult = result[0];
        const errorMachineResult = result[1];


        if (machineResult.isError) {
            // 異常
            dispatch(MachineMonitoringSlice.actions.fetchFailure(machineResult));

            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showErrorMessage(machineResult.errorMessage ?? ""));

        } else if (errorMachineResult.isError) {
            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showErrorMessage(errorMachineResult.errorMessage ?? ""));

        } else {
            // 正常
            dispatch(MachineMonitoringSlice.actions.fetchSuccess(machineResult));
            dispatch(updateCtuMachineError(errorMachineResult.errorMachineIds));

            // エラーの施工機があればリスト保存
            if (errorMachineResult.errorMachineIds.length) dispatch(setCtuMachineErrorOn());


            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showSuccessMessage());
        }

    } catch (error) {

        //例外
        dispatch(MachineMonitoringSlice.actions.fetchFailure({
            items: [],
            isError: true,
            errorMessage: `${error}`,
        }));

        // メッセージ
        dispatch(MachineMonitoringSlice.actions.showErrorMessage(`${error}`));
    } finally {

        // タイマー開始
        dispatch<any>(startReloadTimer(parameter));
        // 処理中 OFF
        dispatch(MachineMonitoringSlice.actions.fetchEnd());
    }
}

/**
 * ロック解除処理を行います。
 * @param parameter
 */
export const onUnlockAsync = (parameter: MachineMonitoringUnlockRequest, inputItem: MachineMonitoringGetRequest) => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineMonitoringSlice.actions.fetchStart());
        // タイマー停止
        dispatch<any>(stopReloadTimer());

        // 通信処理
        const machineWaiter = MachineMonitoringAdapter.instance.unlockAsync(parameter);
        const errorMachineWaiter = SharedAdapter.instance.getAllMachineErrorAsync();

        //まとめて取得
        const result = await Promise.all([machineWaiter, errorMachineWaiter]) // 直接配列に渡したらエラーなる
        const machineResult = result[0];
        const errorMachineResult = result[1];



        if (machineResult.isError) {
            // 異常
            dispatch(MachineMonitoringSlice.actions.fetchFailure(machineResult));
            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showErrorMessage(machineResult.errorMessage ?? ""));

        } else if (errorMachineResult.isError) {
            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showErrorMessage(errorMachineResult.errorMessage ?? ""));

        } else {
            // 正常
            dispatch(MachineMonitoringSlice.actions.fetchSuccess(machineResult));

            dispatch(updateCtuMachineError(errorMachineResult.errorMachineIds));

            // エラーの施工機があればリスト保存
            if (errorMachineResult.errorMachineIds.length) dispatch(setCtuMachineErrorOn());

            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showSuccessMessage("施工機のロックを解除しました"));

            // ダイアログを閉じる
            dispatch(MachineMonitoringSlice.actions.toggleDialog(false));

            // タイマー開始
            dispatch<any>(startReloadTimer(inputItem));
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(MachineMonitoringSlice.actions.showErrorMessage(`${error}`));


    } finally {

        // 処理中 OFF
        dispatch(MachineMonitoringSlice.actions.fetchEnd());
    }
}


/**
 * 解除アクション(Ctrl + Click)入力時の処理を行います。
 * @param parameter
 */
export const onRowUnlockClick = (parameter: MachineMonitoringListItem) => (dispatch: Dispatch) => {

    // タイマー停止
    dispatch<any>(stopReloadTimer());

    // 行データ取得
    dispatch(MachineMonitoringSlice.actions.selectedRow(parameter));

    // ダイアログを開く
    dispatch(MachineMonitoringSlice.actions.toggleDialog(true));

}

/** ダイアログのクローズ処理を行います。 */
export const onCloseDialog = (parameter: MachineMonitoringGetRequest) => (dispatch: Dispatch) => {

    // ダイアログを閉じる
    dispatch(MachineMonitoringSlice.actions.toggleDialog(false));

    // タイマー開始
    dispatch<any>(startReloadTimer(parameter));
}


/**
 * 全エラー施工機一覧得処理を行います。
 */
export const onGetAllMachineErrorAsync = () => async (dispatch: Dispatch) => {

    try {
        // 処理中 ON
        dispatch(MachineMonitoringSlice.actions.fetchStart());

        // 通信処理
        const result = await SharedAdapter.instance.getAllMachineErrorAsync();

        if (result.isError) {
            // 異常
            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showErrorMessage(result.errorMessage ?? ""));

        } else {

            // 正常
            dispatch(updateCtuMachineError(result.errorMachineIds));

            // エラーの施工機があればリスト保存
            if (result.errorMachineIds.length) dispatch(setCtuMachineErrorOn());


            // メッセージ
            dispatch(MachineMonitoringSlice.actions.showSuccessMessage());
        }

    } catch (error) {

        //例外
        // メッセージ
        dispatch(MachineMonitoringSlice.actions.showErrorMessage(`${error}`));
    } finally {

        // 処理中 OFF
        dispatch(MachineMonitoringSlice.actions.fetchEnd());
    }
}


/** 一覧定期取得処理を行います。 */
export const onGetReloadAsync = (parameter: MachineMonitoringGetRequest) => async (dispatch: Dispatch) => {

    try {
        // タイマー停止
        dispatch<any>(stopReloadTimer());

        // 実行
        await dispatch<any>(onGetAsync(parameter));

    } catch (e) {
        //何もしない

    } finally {
        // タイマー開始
        dispatch<any>(startReloadTimer(parameter));
    }
}

/** リロードタイマーを停止します。 */
export const stopReloadTimer = () => (dispatch: Dispatch) => {

    // タイマー停止
    dispatch(MachineMonitoringSlice.actions.stopReloadTimer());
}


/** リロードタイマーを開始します。  */
export const startReloadTimer = (parameter: MachineMonitoringGetRequest) => (dispatch: Dispatch) => {

    // タイマー開始
    const timer = window.setTimeout(() => dispatch<any>(onGetReloadAsync(parameter)), ReloadTimerInterval)
    dispatch(MachineMonitoringSlice.actions.startReloadTimer(timer));
}
