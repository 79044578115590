import { GraphType } from '../../../pages/ConstructionMachineMaster/ConstructionRecord/_types'

/**
 * `施工記録記録` Redux 関連の ユーティリティ機能を提供します。
 */
export class MachineOperationHistorySliceUtility {

    //#region フィールド

    /** 最大表示数を表します。 */
    public static readonly MaxDisplayCount = 2

    //#endregion フィールド

    //#region メソッド

    /**
     * 配列に要素を追加します。
     * @param array
     * @param item
     */
    public static addItem(array: GraphType[], item: GraphType): GraphType[] {
        return Array.from(new Set([...array, item])); //重複データが入らないようにするための対応
    }

    /**
     * 配列の要素を削除します。
     * @param array
     * @param item
     */
    public static removeItem(array: GraphType[], item: GraphType): GraphType[] {
        return array.filter((value) => value !== item);
    }

    /**
     * 配列の先頭要素を削除します。
     * @param array
     * @param item
     */
    public static shiftArray(array: GraphType[]): GraphType[] {
        return array.slice(1);
    }

    //#endregion メソッド
}
